import React, { useState } from 'react'
import { Text, View } from 'react-native'

import styles from './styles'
import SwitchElement from 'src/components/SwitchElement'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { SchoolTypes } from 'src/graphql/School'

interface Props extends SchoolTypes {
  isExcluded: boolean
  isSending: boolean
  toggleSchool: (schoolId: string) => void
}

const SchoolRow = ({ name, isExcluded, id, toggleSchool, isSending, homeAddress }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleMenuAction = () => {
    toggleSchool(id)
    setIsLoading(true)
  }

  return (
    <View style={styles.rowContainer}>
      <View style={styles.nameContainer}>
        <Text ellipsizeMode="tail" numberOfLines={1} style={isExcluded ? styles.schoolNameExcluded : styles.schoolName}>
          {name || ''}
        </Text>
        <Text ellipsizeMode="tail" numberOfLines={1} style={isExcluded ? styles.schoolCityExcluded : styles.schoolCity}>
          {homeAddress?.city || ''}
        </Text>
      </View>
      <View style={styles.switchContainer}>
        {isLoading ? (
          <LoadingIndicator size="small" />
        ) : (
          <SwitchElement
            disabled={isSending}
            isSwitchOn={!isExcluded}
            onToggle={handleMenuAction}
            testID="switch-school-row"
          />
        )}
      </View>
    </View>
  )
}

export default SchoolRow
