import { View, Text, StyleSheet, TouchableWithoutFeedback, Platform } from 'react-native'
import React from 'react'
import Animated from 'react-native-reanimated'
import HyperLink from 'react-native-hyperlink'

import { ChevronRightIcon } from 'src/icons'
import styles from './styles'

export type ArticleProps = {
  answer: string
  isExpanded: boolean
  onPress: () => void
  question: string
}

interface ArticleContent extends Omit<ArticleProps, 'isExpanded'> {
  chevronStyle: object
  expandedSectionStyle: object
  onLayoutEffect: (event: any) => void
}

const ArticleContent = ({
  answer,
  chevronStyle,
  expandedSectionStyle,
  onLayoutEffect,
  onPress,
  question,
}: ArticleContent) => {
  const AnimatedElement = Platform.OS === 'web' ? View : Animated.View

  return (
    <View style={styles.shadow}>
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={onPress}>
          <View style={styles.userInfoContainer}>
            <Text style={styles.question}>{question}</Text>
            <View style={StyleSheet.flatten([styles.iconContainer])}>
              <AnimatedElement style={chevronStyle}>
                <ChevronRightIcon size={24} fill={styles.icon.color} />
              </AnimatedElement>
            </View>
          </View>
        </TouchableWithoutFeedback>
        <AnimatedElement style={expandedSectionStyle}>
          <View style={styles.expandedContainer}>
            <HyperLink linkDefault={true} linkStyle={styles.link}>
              <Text style={styles.expandedText} onLayout={onLayoutEffect}>
                {answer}
              </Text>
            </HyperLink>
          </View>
        </AnimatedElement>
      </View>
    </View>
  )
}

export default ArticleContent
