import { Platform } from 'react-native'
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache } from '@apollo/client/cache'
import { NOTIFICATION_TOKEN_KEY } from 'src/utils/webAdapters/DotEnvAdapter'
import { MMKV } from 'react-native-mmkv'

// @ts-ignore
import OfflineLink from 'src/utils/apolloOffline/index'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import translations, { translate } from 'src/utils/translations/translations'
import navigationService from 'src/utils/navigationService'
import { API_URL } from 'src/utils/ApolloClient'
import { appVersion } from 'src/utils/apiConfig'

export const apolloStorage = new MMKV()

let httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
})
const cache = new InMemoryCache()

export const offlineLink = new OfflineLink({
  storage: apolloStorage,
})

export const createOfflineClient = (url = API_URL) => {
  const errorLinkWithoutToken = onError(({ graphQLErrors, networkError }) => {
    if (networkError && networkError?.message === 'Network request failed') {
      bugsnagActionBreadcrumb('errorLink on ApolloClient - no network (1)')
      return
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const { message, locations, path } = error

        if (!message) {
          return
        }

        navigationService.navigate('Modal', {
          error: `Login failed with ${message}`,
          description: translate(translations.reportError),
          title: translate(translations.somethingWentWrong),
        })
        if (__DEV__) {
          console.error(
            `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
          )
        }
      })
    }
  })

  const authLink = () =>
    setContext((operation, { headers }) => {
      return {
        headers: {
          ...headers,
          'Student-Origin': `MOBILE_APP_${Platform.OS.toUpperCase()}`,
          Authorization: `Bearer ${NOTIFICATION_TOKEN_KEY}`,
        },
      }
    })

  httpLink = createHttpLink({
    uri: `${url}/graphql?appVersion=${appVersion}`,
  })

  const createLink = () => ApolloLink.from([offlineLink, authLink(), errorLinkWithoutToken, httpLink])

  const offlineClient = new ApolloClient({
    connectToDevTools: true,
    link: createLink(),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })

  offlineLink.setup(offlineClient)

  return offlineClient
}
