import translations, { translate } from 'src/utils/translations/translations'

export const getQualificationType = () => [
  { value: 'gcse_type', label: translate(translations.gsa) },
  { value: 'a_level_type', label: translate(translations.alevel) },
  { value: 'nvq_type', label: translate(translations.nvq) },
  { value: 'btec_type', label: translate(translations.btec) },
  {
    value: 'undergraduate_degree_type',
    label: translate(translations.undergraduate_degree),
  },
  {
    value: 'post_graduate_degree_type',
    label: translate(translations.post_graduate_degree),
  },
  { value: 'masters_degree_type', label: translate(translations.masters_degree) },
  { value: 'phd_type', label: translate(translations.phd) },
  { value: 'other', label: translate(translations.other) },
]

export const getQualificationLabel = (qualification: string) =>
  (qualification && getQualificationType().find(q => q.value === qualification)?.label) || ''
