import React from 'react'
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SpilledCupIcon = ({ size, ...props }: IconTypes) => (
  <Svg fill="none" height={size} viewBox="0 0 251 251" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G clipPath="url(#a)">
      <Path
        d="m105.255 195.65 39.686-148.111c26.219 7.025 67.069 39.74 78.033 56.602 6.779 10.426 12.218 20.415 5.552 45.292-6.666 24.877-16.307 30.574-27.391 36.214-17.927 9.122-69.661 17.028-95.88 10.003Z"
        fill="#224D3D"
      />
      <Path
        d="M159.304 130.76c-5.297 19.766-14.317 36.253-24.355 46.969-10.215 10.905-20.398 14.834-28.479 12.668-8.081-2.165-14.936-10.659-18.33-25.21-3.335-14.3-2.903-33.088 2.393-52.854 5.297-19.767 14.317-36.254 24.355-46.97 10.215-10.905 20.398-14.833 28.479-12.668 8.081 2.165 14.936 10.66 18.33 25.21 3.335 14.3 2.903 33.088-2.393 52.855Z"
        fill="#F7FCFB"
        stroke="#224D3D"
        strokeWidth={10.776}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.758 94.277a28.056 28.056 0 0 0 2.557-6.336c4.417-16.484-6.861-33.828-25.189-38.74-11.311-3.03-22.664-.617-30.738 5.55l8.433 6.244c5.307-3.083 12.281-4.253 19.748-2.252 14.036 3.76 20.961 16.353 18.204 26.642a17.459 17.459 0 0 1-1.073 2.924l8.058 5.968Z"
        fill="#224D3D"
      />
      <Path
        d="M113.961 185.24c5.738-1.08 11.552-5.829 13.714-7.896-11.925-9.045-30.075 13.293-38.575 14.484-12.132 1.699-59.466-7.52-59.466 0 0 6.016 6.155 5.546 11.707 5.546 10.159 0 25.352-1.061 25.352 5.366s-12.957 5.34-14.222 5.34c-11.13 0-31.583 1.192-31.583 11.298 0 10.107 19.501 11.083 29.477 11.083l35.792 2.174c12.522 0 15.342-3.085 15.342-5.894 0-4.091-2.638-3.95-16.014-3.95-7.257 0-13.012.662-13.012-3.91s4.308-3.894 19.89-3.894h57.254c5.851.338 17.552-.57 17.552-6.907 0-6.336-11.701-7.329-17.552-7.033h-43.088c-17.806 0 2.006-14.785 7.432-15.807Z"
        fill="#1C1C1C"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(.878 .505)" d="M0 0h250v250H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default SpilledCupIcon
