export const iconButtonSize = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
} as const

export const iconButtonVariant = {
  empty: 'empty',
  filled: 'filled',
  outlined: 'outlined',
} as const
