import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.sm,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLinkContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: paddingNew.sm,
  },
  container: {
    marginHorizontal: paddingNew.sm,
  },
  divider: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginVertical: paddingNew.md,
  },
  fileInfo: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: paddingNew.xxs,
    marginBottom: paddingNew.sm,
    marginTop: -paddingNew.xs,
  },
  fileInfoText: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  infoContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    paddingTop: paddingNew.md,
  },
  mainInfo: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  sectionContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
    paddingTop: paddingNew.sm,
  },
  subInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
  subInfoSpacing: {
    marginTop: paddingNew.xs,
    marginBottom: paddingNew.sm,
  },
  taskInfo: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.md,
  },
})

export default styles
