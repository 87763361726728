import { createRef } from 'react'
import { NavigationContainerRef } from '@react-navigation/core/src/types'
import { NavigationState } from '@react-navigation/routers'

export const navigationRef = createRef<NavigationContainerRef<any>>()

type NavigateParamsT = {
  closeButtonText?: string
  danger?: true
  description?: string
  error?: string
  migrationEstimatedTime?: string
  notificationsModal?: boolean
  onConfirm?: () => any
  preferencesModal?: boolean
  serverMaintenanceModal?: boolean
  title?: string
}

export function navigate(name: string, params?: NavigateParamsT) {
  if (navigationRef.current) {
    navigationRef.current.navigate(name, params)
  }
}

export function reset(name: NavigationState) {
  navigationRef.current && navigationRef.current.reset(name)
}

export function goBack() {
  navigationRef.current && navigationRef.current.goBack()
}

export function resetNavigation() {
  navigationRef.current?.resetRoot({
    index: 0,
    routes: [{ name: 'Main', key: 'main' }],
  })
}

//for mocksForTests
export default {
  navigate,
  goBack,
  reset,
  resetNavigation,
}
