type Links = { [region: string]: string }

export const calendlyLinks: Links = {
  Dalarna: 'https://calendly.com/humly/intervju-dalarna',
  Göteborg: 'https://bit.ly/3wxdWL9',
  Katrineholm: 'https://calendly.com/humly/intervju-kholm',
  Skåne: 'https://bit.ly/3QIGhVX',
  Stockholm: 'https://bit.ly/4dAC0xw',
  Umeå: 'https://bit.ly/3UXcwmz',
  'Milton Keynes': 'https://bit.ly/3wxbrsg',
  'North East': 'https://bit.ly/4dA05o9',
  'North West - Midlands': 'https://bit.ly/3wxbrsg',
  'North West': 'https://bit.ly/4euxQaO',
  'South West': 'https://bit.ly/3wxbrsg',
  Birmingham: 'https://bit.ly/3wxbrsg',
  Hampshire: 'https://bit.ly/3R5rLrx',
}

export const defaultCalendlyLink = 'https://calendly.com/humly'
