import React, { ReactNode } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { StyleSheet, Text, TextStyle, TouchableNativeFeedback, TouchableOpacity, View } from 'react-native'

import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import HeaderButton from 'src/components/HeaderButton'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { themes } from 'src/context/SafeAreaContext'
import { NUMBER_OF_STEPS } from './constants'
import colorsNew from 'src/styles/colorsNew'
import { PropsT } from './types'

const Touchable = global.isIOS ? TouchableOpacity : TouchableNativeFeedback

type Props = PropsT & {
  children: ReactNode
  index: number
  onPageChange: (index: number) => any
}

const Introduction = ({ route, navigation, children, index, onPageChange }: Props) => {
  const isIntro = get(route, 'params.isIntro')

  const finishGuide = () => {
    AsyncStorage.setItem('introductionShowed', 'true')
    isIntro
      ? navigation.reset({
          index: 0,
          // @ts-ignore
          routes: [{ name: 'App' }],
        })
      : navigation.goBack()
  }

  const isFirst = index === 0
  const isLast = index === NUMBER_OF_STEPS - 1

  const firstBgr = isFirst && { backgroundColor: themes.dark.background }
  const headerTitleStylesColors = isFirst ? colorsNew.brand.secondaryLight : colorsNew.grey._900

  const submitButtonText = () => {
    if (isLast) {
      return translate(translations.finishGuide)
    } else if (isFirst) {
      return translate(translations.okGo)
    }
    return translate(translations.next)
  }

  return (
    <View style={StyleSheet.flatten([styles.wrapper, firstBgr])}>
      <View style={styles.absoluteContainerButtons}>
        {index <= NUMBER_OF_STEPS + 1 && index > 0 ? (
          <HeaderButton
            backButton
            iconColor={headerTitleStylesColors}
            iconName="disclosure-arrow"
            onPress={onPageChange(index - 1)}
            style={styles.headerButtonStyles}
            testID="closeButton"
            title={translate(translations.back)}
          />
        ) : (
          <View style={styles.buttonContainer} />
        )}

        {/*// @ts-ignore*/}
        <Touchable onPress={finishGuide} style={styles.skipButton} testID="finishGuideButton">
          <Text style={StyleSheet.flatten([styles.skipText, { color: headerTitleStylesColors }]) as TextStyle}>
            {translate(translations.quitGuide)}
          </Text>
        </Touchable>
      </View>

      {children}

      <View style={StyleSheet.flatten([styles.footerButtonContainer, firstBgr])}>
        <ButtonNew
          onPress={isLast ? finishGuide : onPageChange(index + 1)}
          size={buttonSize.lg}
          style={styles.buttonStyles}
          testID="intro-next-button"
          title={submitButtonText()}
          variant={buttonVariants.containedDefault}
        />
      </View>
    </View>
  )
}

Introduction.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  navigation: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
}

export default Introduction
