import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import TimePicker from 'rc-time-picker'
import { useSelector } from 'react-redux'

import 'rc-time-picker/assets/index.css'
import './stylesCss.css'

import translations, { translate } from 'src/utils/translations/translations'
import { momentWithTimezone } from 'src/global/utils'
import { isGBSelector } from 'src/store/app/selectors'

import styles from './styles'
import {
  getDefaultEndTime,
  getDefaultStartTime,
  getMaxEndTime,
  getMinStartTime,
} from 'src/scenes/Main/Schedule/availabilityContext/dateHelpers'

const hours = Array.from(Array(24).keys())
const minutes = [0, 15, 30, 45]

type Props = {
  error?: any
  formEndTime: string | null
  formStartTime: string | null
  isSubmitting: boolean
  updateTime: (name: 'startTime' | 'endTime', value: string) => void
}

const IntervalInput = ({ isSubmitting, formStartTime, formEndTime, updateTime, error }: Props) => {
  const isGB = useSelector(isGBSelector)

  const today = new Date().toISOString()
  const defaultStartTime = formStartTime || getDefaultStartTime(today)
  const defaultEndTime = formEndTime || getDefaultEndTime(today)

  const [startTime, setStartTime] = useState(defaultStartTime)
  const [endTime, setEndTime] = useState(defaultEndTime)

  useEffect(() => {
    setStartTime(defaultStartTime)
  }, [defaultStartTime])

  useEffect(() => {
    setEndTime(defaultEndTime)
  }, [defaultEndTime])

  const textStyle = [styles.dateTextGrey]
  const minTime = getMinStartTime(startTime)
  const maxTime = getMaxEndTime(endTime)

  const startTimeLimits = () => {
    const lowLimit = momentWithTimezone(minTime).hour()
    const upLimit = momentWithTimezone(endTime).hour()
    return hours.filter(hour => hour < lowLimit || hour > upLimit)
  }
  const endTimeLimits = () => {
    const lowLimit = momentWithTimezone(startTime).hour()
    const upLimit = momentWithTimezone(maxTime).hour()
    return hours.filter(hour => hour < lowLimit || hour > upLimit)
  }

  const startTimeLimitsMinutes = (hour: number) => {
    if (hour === momentWithTimezone(endTime).hour()) {
      return minutes.filter(minute => minute >= momentWithTimezone(endTime).minute())
    }
    return []
  }

  return (
    <>
      <View style={StyleSheet.flatten([styles.intervalInput, error && styles.intervalInputError])}>
        <View style={styles.datePickerContainer}>
          <TimePicker
            className="scheduletimepicker"
            disabled={isSubmitting}
            disabledHours={startTimeLimits}
            disabledMinutes={startTimeLimitsMinutes}
            hideDisabledOptions
            minuteStep={15}
            onChange={time => setStartTime(time.toISOString())}
            onClose={() => updateTime('startTime', startTime)}
            showSecond={false}
            use12Hours={isGB}
            value={momentWithTimezone(startTime)}
          />
        </View>

        <Text style={textStyle}>{translate(translations.to)}</Text>

        <View style={styles.datePickerContainer}>
          <TimePicker
            disabled={isSubmitting}
            className="scheduletimepicker"
            value={momentWithTimezone(endTime)}
            disabledHours={endTimeLimits}
            hideDisabledOptions
            minuteStep={15}
            onChange={time => setEndTime(time.toISOString())}
            onClose={() => updateTime('endTime', endTime)}
            showSecond={false}
            use12Hours={isGB}
          />
        </View>
      </View>

      {error ? <Text style={styles.errorText}>{translate(translations.errorDateAvailability)}</Text> : null}
    </>
  )
}

IntervalInput.propTypes = {
  endTime: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  startTime: PropTypes.string.isRequired,
}

export default IntervalInput
