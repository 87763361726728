import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderRadius: 8,
    height: 120,
    justifyContent: 'space-between',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.xs,
  },
  containerActive: {
    backgroundColor: colorsNew.redesign.primary.shades12p,
  },
  timeText: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.secondary,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  bottomRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  locationContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  salaryText: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.secondary,
    flex: 1,
  },
  locationText: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginLeft: paddingNew.xxs,
  },
  locationRow: {
    flexDirection: 'row',
  },
})

export default styles
