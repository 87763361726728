import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#CB1928'

const CloseFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg height={size} viewBox="0 0 54 54" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.012 46.185c10.807 0 19.569-8.761 19.569-19.57 0-10.807-8.762-19.568-19.57-19.568-10.807 0-19.569 8.761-19.569 19.569s8.762 19.57 19.57 19.57Zm7.687-27.257c.607.607.607 1.59 0 2.197l-5.49 5.49 5.49 5.492a1.553 1.553 0 1 1-2.196 2.196l-5.491-5.49-5.492 5.49a1.553 1.553 0 1 1-2.196-2.196l5.491-5.491-5.49-5.491a1.553 1.553 0 0 1 2.195-2.197l5.492 5.491 5.49-5.49a1.553 1.553 0 0 1 2.197 0Z"
      fill={fill?.length ? fill : defaultFill}
    />
  </Svg>
)

export default CloseFilledIcon
