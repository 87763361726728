import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 8,
    flexDirection: 'row',
    marginVertical: paddingNew.xs,
  },
  container: {
    marginLeft: paddingNew.xs,
    flex: 1,
  },
  title: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginRight: paddingNew.md,
  },
  subtitle: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
