import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import { BookingListItemType } from 'src/graphql/Booking/types'
import { ExpressBookingsTypes } from 'src/graphql/ExpressBookings'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'

const formatTitle = (date: string) => {
  const keyFormatted = formatter.fullDay(date)

  // @ts-ignore
  return formatter.shortDate(date) === formatter.shortDate()
    ? `${translate(translations.today)} - ${keyFormatted}`
    : keyFormatted
}

export const getJobsSections = (bookingsList: BookingListItemType[], expressList: ExpressBookingsTypes[]) => {
  const nodes = sortBy(
    [...bookingsList, ...expressList],
    item => (item as BookingListItemType).startTime || (item as ExpressBookingsTypes).date,
  )
  const groupedBookings = groupBy(nodes, item =>
    formatTitle((item as BookingListItemType).startTime || (item as ExpressBookingsTypes).date),
  )
  return Object.entries(groupedBookings).map(([title, data]) => ({
    data,
    title,
  }))
}
