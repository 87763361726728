import { ApolloError } from '@apollo/client'
import merge from 'lodash/merge'

import { FlexibleAvailabilityRequestTypes, DayAvailability } from 'src/graphql/Availability'
import Api, { responseErrorCallback } from 'src/utils/api'
import { bugsnagNotifyWithData } from 'src/utils/bugsnag'

export type AvailabilityScheduleType = Partial<Pick<DayAvailability, 'endTime' | 'startTime' | 'timeScheduleType'>>

export type ConvertedAvailabilityDay = {
  bookings: string[]
  inactive: boolean
  isExpressBooking: boolean
  marked: boolean
  scheduleType: AvailabilityScheduleType
}

export type ConvertedAvailabilityList = {
  [key: string]: ConvertedAvailabilityDay
}

type SubmitDataType = {
  date: string
  dayScheduleEntries: Partial<DayAvailability>[]
}

const getScheduleType = (intervals: DayAvailability[]) => {
  const checkScheduleType = (type: string) =>
    intervals.some(interval => interval.timeScheduleType === type && !!interval.available)

  if (intervals.length === 0) {
    return { timeScheduleType: 'full_day' } as Partial<DayAvailability>
  }
  if (checkScheduleType('am')) {
    return { timeScheduleType: 'am' } as Partial<DayAvailability>
  }
  if (checkScheduleType('pm')) {
    return { timeScheduleType: 'pm' } as Partial<DayAvailability>
  }
  if (checkScheduleType('full_day')) {
    return { timeScheduleType: 'full_day' } as Partial<DayAvailability>
  }
  if (checkScheduleType('hourly')) {
    const hourlyInterval = intervals.find(interval => interval.timeScheduleType === 'hourly' && !!interval.available)
    return {
      endTime: hourlyInterval?.endTime,
      startTime: hourlyInterval?.startTime,
      timeScheduleType: 'hourly',
    } as Partial<DayAvailability>
  }

  const availableInterval = intervals.find(interval => !!interval.available)

  return availableInterval
    ? ({
        endTime: availableInterval.endTime,
        startTime: availableInterval.startTime,
        timeScheduleType: 'hourly',
      } as Partial<DayAvailability>)
    : null
}

export const isInactive = (intervals: DayAvailability[]) =>
  intervals.some(interval => interval.timeScheduleType === 'full_day' && !interval.available) ||
  (intervals.length === 1 && !intervals[0].available)

export const getConvertedAvailabilityList = (data: FlexibleAvailabilityRequestTypes) => {
  const availabilityData = data?.flexibleAvailability?.nodes || []
  const convertedData = availabilityData.reduce((acc, item) => {
    const day = item.date
    const bookings =
      item.dayScheduleEntries
        .filter(entry => entry.bookingId !== null || entry.expressId !== null)
        .map(entry => entry.bookingId || entry.expressId) || []

    const noBooking = bookings.length === 0
    const isExpressBooking = !noBooking && item.dayScheduleEntries.some(entry => entry.expressId !== null)
    const scheduleType = noBooking ? getScheduleType(item.dayScheduleEntries) : null
    const marked = bookings.length > 0
    const inactive = noBooking && isInactive(item.dayScheduleEntries)

    return merge(acc, { [day]: { marked, inactive, scheduleType, bookings, isExpressBooking } })
  }, {})

  return convertedData as ConvertedAvailabilityList
}

export const submitDayAvailability = (
  userId: string,
  availabilityData: SubmitDataType,
  onSuccessCallback: () => void,
  onErrorCallback: () => void,
) =>
  Api.post(`users/${userId}/flexible_availability`, {
    data: JSON.stringify({ flexibleAvailability: [availabilityData] }),
    onSuccess: () => {
      onSuccessCallback()
    },
    onError: (error: ApolloError) => {
      bugsnagNotifyWithData('Update day availability failed', { availabilityData })
      responseErrorCallback('post', `users/${userId}/flexible_availability`, error)
      onErrorCallback()
    },
  })
