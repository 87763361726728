import React, { useCallback, useState } from 'react'
import { Keyboard, StyleSheet, Text } from 'react-native'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import Animated, { CurvedTransition } from 'react-native-reanimated'

import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import ResetPasswordForm from '../ResetPasswordForm'
import ResetPasswordSuccess from '../ResetPasswordSuccess'
import styles from 'src/scenes/Welcome/ResetPassword/styles'
import translations, { translate } from 'src/utils/translations/translations'
import { ArrowLeftIcon } from 'src/icons'
import { LoginRoutesScreensProps } from 'src/utils/types/navigationTypes'

const ResetPasswordContent = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(true)
  const [isErrorMessage, setIsErrorMessage] = useState(false)
  const [isSuccessMessage, setSuccessMessage] = useState(false)

  const navigation = useNavigation<LoginRoutesScreensProps<'Login'>['navigation']>()

  const hideButton = () => setIsButtonVisible(false)
  const showButton = () => setIsButtonVisible(true)

  const handleBackButtonPress = () => (navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Login'))

  useFocusEffect(
    useCallback(() => {
      const passwordScreenKeyboardDidShowListener = Keyboard.addListener('keyboardDidShow', hideButton)

      const passwordScreenKeyboardDidHideListener = Keyboard.addListener('keyboardDidHide', showButton)

      return () => {
        passwordScreenKeyboardDidShowListener.remove()
        passwordScreenKeyboardDidHideListener.remove()
      }
    }, []),
  )

  const onSubmitSuccess = () => {
    setSuccessMessage(true)
    setIsErrorMessage(false)
  }

  const onSubmitError = () => {
    setSuccessMessage(false)
    setIsErrorMessage(true)
  }

  const onSubmit = () => {
    setIsErrorMessage(false)
    setSuccessMessage(false)
  }

  return (
    <>
      {isSuccessMessage ? (
        <ResetPasswordSuccess />
      ) : (
        <>
          <Text style={StyleSheet.flatten([styles.header, global.isSM && styles.headerSmall])}>
            {translate(translations.resetPasswordHeader)}
          </Text>
          <Text style={styles.info}>{translate(translations.resetPasswordInfo)}</Text>
          <ResetPasswordForm
            isError={isErrorMessage}
            onSubmitCallback={onSubmit}
            onSubmitError={onSubmitError}
            onSubmitSuccess={onSubmitSuccess}
            removeError={() => setIsErrorMessage(false)}
          />
        </>
      )}
      {isButtonVisible ? (
        <Animated.View layout={CurvedTransition} style={styles.backButtonContainer}>
          <ButtonNew
            LeftIcon={ArrowLeftIcon}
            onPress={handleBackButtonPress}
            testID="backToLoginButton"
            title={translate(translations.resetPasswordBackButton)}
            variant={buttonVariants.textDefault}
          />
        </Animated.View>
      ) : null}
    </>
  )
}

export default ResetPasswordContent
