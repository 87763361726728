import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import { NotificationsParamList } from 'src/utils/types/navigationTypes'
import Booking from '../Jobs/Details/ShortTermOfferDetails/Booking'
import CampaignDetails from './CampaignDetails'
import LongTermOffer from '../Jobs/Details/LongTermOffer'
import mainHeaderConfig from 'src/utils/headerConfig/mainHeaderConfig'
import NotificationsList from './NotificationsList'
import Order from '../Jobs/Details/ShortTermOfferDetails/Order'
import stackNavigatorConfig from 'src/utils/headerConfig/stackNavigatorConfig'
import translations, { translate } from 'src/utils/translations/translations'

const Stack = createStackNavigator<NotificationsParamList>()

const Inbox = () => (
  <Stack.Navigator {...(stackNavigatorConfig() as { screenOptions: object })}>
    <Stack.Screen
      name="NotificationsList"
      options={{
        headerShown: false,
        title: translate(translations.notifications),
      }}
      component={NotificationsList}
    />
    <Stack.Screen
      name="CampaignDetails"
      options={params => mainHeaderConfig(null, params) as object}
      component={CampaignDetails}
    />
    <Stack.Screen
      component={Booking}
      name="BookingNotification"
      options={params => mainHeaderConfig(translations.assignment, params)}
    />
    <Stack.Screen
      component={Order}
      name="OrderNotification"
      options={params => mainHeaderConfig(translations.orderTitle, params)}
    />
    <Stack.Screen
      component={LongTermOffer}
      name="ProposalNotification"
      options={params => mainHeaderConfig(translations.longTermOfferApplicationHeader, params)}
    />
  </Stack.Navigator>
)
export default Inbox
