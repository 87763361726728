import React from 'react'
import { ScrollView, Text, View } from 'react-native'

import { MenuParamList, RouteNavigatorStackComponent } from 'src/utils/types/navigationTypes'
import JobDetailsList from 'src/scenes/Main/Salary/History/components/JobDetailsList'
import Summary from 'src/scenes/Main/Salary/History/components/Summary'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const JobsCompletedInMonth: RouteNavigatorStackComponent<MenuParamList, 'JobsCompletedInMonth'> = ({ route }) => {
  const { data, maxDate, minDate, title, totalTime } = route.params

  return (
    <ScrollView contentContainerStyle={globalStyles.containerSize} testID="scrollView">
      <View style={styles.container}>
        <View style={styles.containerPadding}>
          <Summary isMonthlySummary={true} mainTitle={title} maxDateValue={maxDate} minDateValue={minDate} />
        </View>
        <JobDetailsList data={data} />
        <View style={styles.containerPadding}>
          <View style={styles.totalContainer}>
            <Text style={styles.totalTitle}>{translate(translations.total)}:</Text>
            <Text style={styles.totalSubtitle}>{totalTime}</Text>
          </View>
          <View style={styles.divider} />
          <Text style={styles.info}>{translate(translations.jobsCompletedInMonthInfo)}</Text>
        </View>
      </View>
    </ScrollView>
  )
}

export default JobsCompletedInMonth
