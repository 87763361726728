import gql from 'graphql-tag'

import { IntervalTypes } from './types'

export type { IntervalTypes }

export default gql`
  fragment IntervalFields on Interval {
    id
    startTime
    endTime
    available
  }
`
