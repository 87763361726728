import { View, Text } from 'react-native'
import React from 'react'

import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { ModalBaseProps } from './types'
import styles from './styles'

const ModalBase = ({ description, onCancel, onCancelLabel, onConfirm, onConfirmLabel = '', title }: ModalBaseProps) => {
  const singleButton = !onCancel || !onCancelLabel

  return (
    <View style={styles.modalContainer}>
      <View>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
      {singleButton ? (
        <View style={styles.buttonContainer}>
          <ButtonNew variant={buttonVariants.containedError} onPress={onConfirm} title={onConfirmLabel} />
        </View>
      ) : (
        <View style={styles.buttonsContainer}>
          <ButtonNew variant={buttonVariants.outlinedDefault} onPress={onCancel} title={onCancelLabel} />
          <ButtonNew variant={buttonVariants.containedError} onPress={onConfirm} title={onConfirmLabel} />
        </View>
      )}
    </View>
  )
}

export default ModalBase
