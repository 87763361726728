import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { TaskRowProps } from '../TaskRow'
import { getStatusInfo } from '../utils'
import translations, { translate } from 'src/utils/translations/translations'
import styles from '../styles'

const EnabledTaskBody = ({
  status,
  task,
  timeToComplete,
}: Pick<TaskRowProps, 'status' | 'timeToComplete' | 'task'>) => {
  const statusSettings = useMemo(() => getStatusInfo(status, timeToComplete), [status, timeToComplete])

  return (
    <View style={styles.enabledContainer}>
      <Text style={[styles.title, styles.textEnabled]}>{translate(translations[`taskTitle_${task}`])}</Text>
      <View
        style={StyleSheet.flatten([
          styles.status,
          statusSettings?.badgeColor ? styles.badge : {},
          { backgroundColor: statusSettings?.badgeColor },
        ])}
      >
        <Text style={StyleSheet.flatten([styles.statusText, { color: statusSettings?.textColor }])}>
          {statusSettings?.text}
        </Text>
      </View>
    </View>
  )
}
export default EnabledTaskBody
