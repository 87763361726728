import React from 'react'
import { View } from 'react-native'

import RowTitle from './RowTitle'
import { InputSelectModal } from 'src/components/Form'
import styles from './styles'

type Props = {
  isExpanded: boolean
  label: string
  setTime: (value: string) => void
  time: number
  toggleSection: () => void
}

const times = Array(25)
  .fill(undefined)
  .map((_, index) => ({
    value: index * 5,
    label: `${index * 5} min`,
  }))

const BreakRow = ({ toggleSection, isExpanded, time, setTime, label }: Props) => (
  <View style={styles.rowContainer}>
    <RowTitle label={label} buttonText={`${time} min`} onToggle={toggleSection} />
    {isExpanded ? (
      <View style={styles.container}>
        <InputSelectModal
          field={{ name: 'breakTime', value: time }}
          form={{
            errors: {},
            // @ts-ignore
            setFieldValue: (name: string, value: string) => setTime(value),
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setFieldTouched: () => {},
          }}
          // @ts-ignore
          values={times}
          isSearchable={false}
        />
      </View>
    ) : null}
  </View>
)

export default BreakRow
