import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

export default StyleSheet.create({
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  loader: {
    marginTop: paddingNew.lg,
  },
  emptyMessage: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
})
