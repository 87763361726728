import React, { useEffect } from 'react'
import { Text, View } from 'react-native'
import { Snackbar as SnackbarComponent } from 'react-native-paper'

import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import Icon from '../Icon'
import { setSnackbarProps } from 'src/store/app/actions'
import { colors } from 'src/styles'
import styles from './styles'
import usePrevious from 'src/hooks/usePrevious'

const Snackbar = () => {
  const dispatch = useAppDispatch()
  const snackbarProps = useAppSelector(state => state.app?.snackbarProps)
  const previousVisible = usePrevious(snackbarProps)

  const type = snackbarProps?.type || 'success'
  const duration = snackbarProps?.duration || 3000
  const message = snackbarProps?.message || ''
  const isVisible = snackbarProps?.visible || false
  const isAutoHide = snackbarProps?.autoHide

  useEffect(() => {
    let timeout = null as NodeJS.Timeout | null
    if (isAutoHide !== false && isVisible) {
      timeout = setTimeout(() => dispatch(setSnackbarProps({ visible: false })), duration)
    }
    return () => {
      if (timeout) clearTimeout(timeout as NodeJS.Timeout)
    }
  }, [isVisible, isAutoHide, duration, previousVisible, dispatch])

  if (!isVisible) {
    return null
  }
  return (
    <SnackbarComponent
      duration={duration}
      onDismiss={() => null}
      style={[styles.container, type === 'success' ? styles.success : styles.warning]}
      visible={isVisible}
    >
      <View style={styles.textWrapper}>
        <Text style={styles.message}>{message}</Text>
        {type === 'success' ? <Icon color={colors.white} name="tada" size={20} /> : null}
      </View>
    </SnackbarComponent>
  )
}

export default Snackbar
