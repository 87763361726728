import { Dimensions, Platform, Text, TextInput } from 'react-native'
import moment from 'moment'
// @ts-ignore
import momentTimezone from 'moment-timezone'
import DeviceInfo from 'src/utils/webAdapters/DeviceInfoAdapter'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import { setBugsnagStart } from 'src/utils/bugsnag'
import I18n from 'src/utils/translations/i18n'

const { width } = Dimensions.get('window')
global.isIOS = Platform.OS === 'ios' || Platform.OS === 'web'
global.isIphoneX = DeviceInfo.hasNotch()
global.screenWidth = width
global.isSM = Dimensions.get('window').height < 780
global.isSmall = Dimensions.get('window').height < 641

setBugsnagStart()

// @ts-ignore
Text.defaultProps = {
  allowFontScaling: false,
}

// @ts-ignore
TextInput.defaultProps = {
  allowFontScaling: false,
}

export const setMomentLocale = () => {
  const shortLocaleString = I18n.locale.toLowerCase().substr(0, 2)
  const localeCode = shortLocaleString === 'sv' ? 'sv' : 'en-gb'

  moment.locale(localeCode)
  // @ts-ignore
  momentTimezone.updateLocale(localeCode, moment.localeData()._config)
  momentTimezone.locale(localeCode)
}
