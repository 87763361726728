import { StyleSheet, Dimensions } from 'react-native'

import { colorsNew, fontSizesNew, paddingNew } from 'src/styles'

export const MAX_HEIGHT = Dimensions.get('window').height * 0.8

export default StyleSheet.create({
  modalContainer: {
    justifyContent: 'space-between',
    paddingBottom: paddingNew.base,
    paddingHorizontal: paddingNew.lg - 4,
    paddingTop: paddingNew.lg,
  },
  modalWrapper: {
    backgroundColor: colorsNew.white,
    borderRadius: 25,
    minHeight: 200,
    maxHeight: MAX_HEIGHT,
  },
  modalWrapperWeb: {
    maxWidth: 500,
    width: '90%',
  },
  description: {
    color: colorsNew.brand.secondaryDark,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
    lineHeight: fontSizesNew.xs.lh,
    marginBottom: paddingNew.lg,
  },
  title: {
    color: colorsNew.brand.secondaryDark,
    fontSize: fontSizesNew.md.size,
    fontWeight: '700',
    marginBottom: paddingNew.sm,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  buttonContainer: {
    marginBottom: paddingNew.base,
    paddingHorizontal: paddingNew.sm,
  },
  webContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.translucentBlack,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 5,
  },
})
