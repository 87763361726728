import React, { useContext, useState } from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { ignoreProposal } from 'src/store/longTermBookings/actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import CustomModal from 'src/components/Modal/CustomModal'
import ReportErrorModal from 'src/components/ModalTypes/ReportErrorModal'
import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'

type Props = {
  closeModal: () => void
  goBack: () => void
  showModal: boolean
  proposalId: string
}

const IgnoreProposalModal = ({ closeModal, showModal, proposalId, goBack }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [shouldGoBack, setShouldGoBack] = useState(false)
  const { mixpanel } = useContext(MixpanelContext)

  const onRequestFailed = (responseError: string) => {
    setIsSubmitting(false)
    setError(responseError)
  }

  const onRequestSuccess = () => {
    setIsSubmitting(false)
    setShouldGoBack(true)
    closeModal()
  }

  const rejectProposal = () => {
    setIsSubmitting(true)
    ignoreProposal(proposalId, onRequestSuccess, onRequestFailed)
    mixpanel?.track('Reject Proposal', { proposalId })
  }

  const onErrorModalClose = () => {
    setError(null)
    closeModal()
  }

  if (error) {
    return (
      <ReportErrorModal
        description={translate(translations.errorReportApplicationFailed)}
        error={error}
        onModalClose={onErrorModalClose}
        showModal={showModal}
        title={translate(translations.errorReportTitle)}
      />
    )
  }

  return (
    <CustomModal
      buttons={[
        {
          onPress: closeModal,
          testID: 'ignore-proposal-modal-cancel',
          title: translate(translations.cancel),
          variant: buttonVariants.outlinedDefault,
        },
        {
          isSubmitting,
          onPress: rejectProposal,
          testID: 'ignore-proposal-modal-remove',
          title: translate(translations.ignoreConfirmationButton),
          variant: buttonVariants.outlinedWarning,
        },
      ]}
      colorSchema={customModalColorSchema.warning}
      description={translate(translations.ignoreOfferDescription)}
      isVisible={showModal}
      modalPlace="Main/Jobs/Details/LongTermOffer/ProposalActions/IgnoreProposalModal/IgnoreProposalModal"
      onClose={closeModal}
      onModalHide={shouldGoBack ? goBack : undefined}
      testID={showModal ? 'ignore-proposal-modal-visible' : 'ignore-proposal-modal-invisible'}
      title={translate(translations.ignoreOfferTitle)}
      variant={customModalVariants.standard}
    />
  )
}
export default IgnoreProposalModal
