import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const HistoryIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M13.26 2.99958C8.17003 2.85958 4.00003 6.94958 4.00003 11.9996H2.21003C1.76003 11.9996 1.54003 12.5396 1.86003 12.8496L4.65003 15.6496C4.85003 15.8496 5.16003 15.8496 5.36003 15.6496L8.15003 12.8496C8.46003 12.5396 8.24003 11.9996 7.79003 11.9996H6.00003C6.00003 8.09958 9.18003 4.94958 13.1 4.99958C16.82 5.04958 19.95 8.17958 20 11.8996C20.05 15.8096 16.9 18.9996 13 18.9996C11.39 18.9996 9.90003 18.4496 8.72003 17.5196C8.32003 17.2096 7.76003 17.2396 7.40003 17.5996C6.98003 18.0196 7.01003 18.7296 7.48003 19.0896C9.00003 20.2896 10.91 20.9996 13 20.9996C18.05 20.9996 22.14 16.8296 22 11.7396C21.87 7.04958 17.95 3.12958 13.26 2.99958ZM12.75 7.99958C12.34 7.99958 12 8.33958 12 8.74958V12.4296C12 12.7796 12.19 13.1096 12.49 13.2896L15.61 15.1396C15.97 15.3496 16.43 15.2296 16.64 14.8796C16.85 14.5196 16.73 14.0596 16.38 13.8496L13.5 12.1396V8.73958C13.5 8.33958 13.16 7.99958 12.75 7.99958Z" />
  </Svg>
)

export default HistoryIcon
