import { Text, ScrollView, View } from 'react-native'
import React, { useContext } from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { ChevronRightIcon } from 'src/icons'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew from 'src/components/Buttons/ButtonNew/ButtonNew'
import StatusInfo from '../components/StatusInfo/StatusInfo'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'Contract'>

const Contract = ({ navigation, route }: Props) => {
  const { status, timeToComplete } = route.params

  useBugsnagView('RequiredTask - Contract')

  const contractSentAt = useAppSelector(store => store.user.attributes?.contractSentAt)
  const contractUrl = useAppSelector(store => store.user.attributes?.contractUrl)
  const contractSignedAt = useAppSelector(store => store.user.attributes?.contractSignedAt)

  const { mixpanel } = useContext(MixpanelContext)

  const isContractSent = !contractSignedAt && !!contractSentAt
  const isContractSigned = !!contractSignedAt
  const isInProgress = !contractSignedAt && !contractSentAt

  const openContract = () => {
    if (!contractUrl) return

    mixpanel?.track('Recruitment: Open Contract SE')
    navigation.navigate('WebView', { url: contractUrl, headerTitle: translate(translations.taskTitle_contract) })
  }

  return (
    <ScrollView
      contentContainerStyle={globalStyles.containerSize}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <View style={styles.container}>
        <StatusInfo status={status} timeToComplete={timeToComplete} />
        {isInProgress ? (
          <>
            <Text style={styles.mainInfo}>{translate(translations.contractInfo)}</Text>
            <View style={styles.alertWrapper}>
              <AlertCard
                colorSchema={alertColorScheme.info}
                description={translate(translations.contractAlert)}
                variant={alertVariants.outlined}
              />
            </View>
          </>
        ) : null}

        {isContractSent ? (
          <>
            <Text style={styles.title}>{translate(translations.contractSentTitle)}</Text>
            <Text style={styles.mainInfo}>{translate(translations.contractInfoSent)}</Text>
            <View style={styles.buttonContainer}>
              <ButtonNew
                isShort
                onPress={openContract}
                RightIcon={ChevronRightIcon}
                title={translate(translations.contractOpen)}
                variant={buttonVariants.containedDefault}
              />
            </View>
            <Text style={styles.subInfo}>{translate(translations.contractDocumentInfo)}</Text>
            <View style={styles.alertWrapper}>
              <AlertCard
                colorSchema={alertColorScheme.info}
                description={translate(translations.contractAlert)}
                variant={alertVariants.outlined}
              />
            </View>
          </>
        ) : null}

        {isContractSigned ? (
          <>
            <Text style={styles.title}>{translate(translations.contractSignedTitle)}</Text>
            <Text style={styles.mainInfo}>{translate(translations.contractInfoSigned)}</Text>
            <View style={styles.buttonContainer}>
              <ButtonNew
                disabled={!contractUrl}
                isShort
                onPress={openContract}
                RightIcon={ChevronRightIcon}
                title={translate(translations.contractOpen)}
                variant={buttonVariants.outlinedDefault}
              />
            </View>
            {!contractUrl ? (
              <AlertCard
                colorSchema={alertColorScheme.info}
                description={translate(translations.contractUrlNotAvailable)}
                variant={alertVariants.outlined}
              />
            ) : null}
          </>
        ) : null}
      </View>
    </ScrollView>
  )
}

export default Contract
