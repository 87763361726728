import { AxiosError, AxiosResponse } from 'axios'

import { ErrorResponseT, OnSuccessT } from 'src/utils/types'
import Api, { responseErrorCallback } from 'src/utils/api'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { workPermitInvalidStatusCode } from 'src/global/constants'

type AcceptDataType = {
  date: string
  expressBookingPool: string
}

export const acceptOnCall = (
  data: AcceptDataType,
  onSuccess: OnSuccessT,
  onValidationError: (details: string, isWorkPermitInvalid: boolean) => void,
) => {
  bugsnagActionBreadcrumb('acceptOnCall')

  return Api.post('student/oncalls', {
    data: { oncall: data },
    onSuccess: () => onSuccess(),
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      if (response?.status === 422) {
        const errorDetails = response?.data?.errors[0]?.detail
        const errorTitle = response?.data?.errors?.[0]?.title
        const isWorkPermitInvalid = errorTitle === workPermitInvalidStatusCode
        onValidationError(errorDetails, isWorkPermitInvalid)
      } else {
        bugsnagNotifyWithData('acceptOnCall', response)
        responseErrorCallback('post', 'student/oncalls', response as AxiosResponse)
      }
    },
  })
}

export const ignoreOnCall = (
  data: Omit<AcceptDataType, 'expressBookingPool'>,
  onSuccess: OnSuccessT,
  onValidationError: (details: string) => void,
) => {
  bugsnagActionBreadcrumb('ignoreOnCall')

  return Api.put('student/oncalls/ignore', {
    data: { oncall: data },
    onSuccess: () => onSuccess(),
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      if (response?.status === 422) {
        const errorDetails = response?.data?.errors[0]?.detail
        onValidationError(errorDetails)
      } else {
        bugsnagNotifyWithData('ignoreOnCall', response)
        responseErrorCallback('put', 'student/oncalls/ignore', response as AxiosResponse)
      }
    },
  })
}
