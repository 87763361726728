import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginTop: paddingNew.md,
    marginBottom: paddingNew.sm,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
})
