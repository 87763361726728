import React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

import { colorsNew } from 'src/styles'
import Card from 'src/scenes/Main/Salary/History/components/Card'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import { capitalizeFirstLetter } from 'src/global/utils'

type Props = {
  countOfBookings: number
  countOfClients: number
  countOfHours: number
  style?: ViewStyle
}

const Totals = ({ countOfBookings, countOfClients, countOfHours, style }: Props) => (
  <View style={StyleSheet.flatten([styles.container, style])}>
    <Card
      style={{ backgroundColor: colorsNew.redesign.primary.shades12p }}
      subTitle={countOfClients > 1 ? translate(translations.schools) : translate(translations.school)}
      title={`${countOfClients}`}
    />
    <Card
      style={{ backgroundColor: colorsNew.redesign.primary.shades30p }}
      subTitle={countOfBookings > 1 ? translate(translations.bookings) : translate(translations.booking)}
      title={`${countOfBookings}`}
    />
    <Card
      style={{ backgroundColor: colorsNew.redesign.primary.shades50p }}
      subTitle={capitalizeFirstLetter(countOfHours > 1 ? translate(translations.hours) : translate(translations.hour))}
      title={`${countOfHours}`}
    />
  </View>
)

export default Totals
