import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginTop: paddingNew.xs,
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: paddingNew.xs,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
    paddingBottom: paddingNew.md,
  },
  infoText: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginTop: paddingNew.sm,
  },
  sectionContainer: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.sm,
    paddingBottom: paddingNew.base,
  },
  sectionTitleGB: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.base,
  },
  sectionTitleSv: {
    ...typography.overline,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.sm,
  },
  taskHeader: {
    ...typography.caption,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
