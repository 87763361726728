import flatten from 'lodash/flatten'
import filter from 'lodash/filter'
import get from 'lodash/get'
import moment from 'moment'

import { isReported } from 'src/scenes/Main/Jobs/Rows/utils'
import translations, { translate } from 'src/utils/translations/translations'
import { BookingsRequestTypes, BookingTypes } from 'src/graphql/Booking'
import { ExpressBookingsRequestTypes, ExpressBookingsTypes } from 'src/graphql/ExpressBookings'
import { ReportedItemT, UnreportedItemT } from './types'

export const sections = (data = {} as BookingsRequestTypes, expressBookings = {} as ExpressBookingsRequestTypes) => {
  // TODO need clean up this
  const months = {} as { [key: string]: BookingTypes[] }

  const bookings = get(data, 'bookings.nodes', []).filter(({ type }: { type: string }) =>
    ['once', 'multiDay', 'recurrent'].includes(type),
  ) as BookingTypes[]

  const expresses = get(expressBookings, 'expressBookings.nodes', []).filter(
    ({ status }: { status: string }) => !['absent', 'sick', 'on leave', 'VAB'].includes(status),
  )

  const filteredExpresses = []

  for (let i = 0; i < expresses.length; i++) {
    const startTime = moment((expresses[i] as ExpressBookingsTypes).date)
    const bookingOnTheSameDate = bookings.find(
      booking => moment(booking.startTime).format('YYYY-MM-DD') === startTime.format('YYYY-MM-DD'),
    )

    if (bookingOnTheSameDate) {
      if (bookingOnTheSameDate._durationMinutes >= 300) {
        continue
      } else {
        bookingOnTheSameDate.durationMinutesToCount = 300
        continue
      }
    }

    startTime.hour(6)
    startTime.minute(30)

    filteredExpresses.push({ ...(expresses[i] as ExpressBookingsTypes), startTime, _durationMinutes: 300 })
  }

  for (let i = 11; i >= 0; i--) {
    const currentMoment = moment()
    // @ts-ignore
    months[currentMoment.subtract(i, 'months').format('YYYY MMM')] = filter(
      [...filteredExpresses, ...bookings],
      booking => {
        return (
          moment(booking.startTime || booking.date).format('YYYY MMM') ===
          moment().subtract(i, 'months').format('YYYY MMM')
        )
      },
    ).sort(
      (a, b) => Number(moment(b.startTime || b.date).format('DD')) - Number(moment(a.startTime || a.date).format('DD')),
    )
  }

  return months
}

export const flattenSectionList = (sectionsToFlat: { [key: string]: BookingTypes[] }) =>
  flatten(
    Object.entries(sectionsToFlat)
      .reverse()
      .map(([, item]) => item),
  )

export const renderSectionsWithTitles = (section: { reported?: ReportedItemT[]; unreported?: UnreportedItemT[] }) =>
  Object.entries(section).map(([title, data]) => {
    if (data.length && data?.[0]?.__typename === 'empty') {
      // mock item for unreported placeholder
      return {
        title: '',
        data,
      }
    }

    return {
      title: translate(translations[title as 'reported' | 'unreported']),
      data,
    }
  })

export const itemSections = (
  items: ReportedItemT[],
): { reported?: ReportedItemT[]; unreported?: UnreportedItemT[] } => {
  const reported = [] as ReportedItemT[]
  const unreported = [] as ReportedItemT[]

  items.forEach(item => {
    if (isReported(item)) {
      reported.push(item)
    } else {
      unreported.push(item)
    }
  })

  return {
    unreported: unreported.length > 0 ? unreported : [{ __typename: 'empty', title: '', id: '0' }],
    ...(reported.length > 0 && { reported }),
  }
}
