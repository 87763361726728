import { StackNavigationOptions } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'

export default (): StackNavigationOptions => ({
  headerShown: false,
  headerTitle: '',
  headerTransparent: true,
  headerStyle: {
    borderBottomWidth: 0,
  },
  headerTitleAlign: 'center',
  headerLeft: () => <View />,
  headerRight: () => <View />,
})
