import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  section: {
    marginHorizontal: paddingNew.sm,
    marginBottom: paddingNew.lg,
  },
  loader: { marginTop: paddingNew.xs },
})

export default styles
