import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { DOCUMENT_TYPES } from '../TeachingCertificates'
import { OnFilesChangeCallbackArguments } from 'src/components/FileUploader/types'
import { UserDocuments } from 'src/store/user/selectors'
import FileUploader from 'src/components/FileUploader/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  certificates: UserDocuments[]
  disabled: boolean
  onFilesErrorCallback?: () => void
  setAllDocumentsUploaded: () => void
  setIsFilePicked: (status: boolean) => void
  shouldSaveDocument: boolean
}

const countTrueItems = (args: boolean[]) => {
  let count = 0
  args.forEach(arg => arg === true && count++)
  return count
}

const MultipleFileUploader = ({
  certificates,
  disabled,
  onFilesErrorCallback,
  setAllDocumentsUploaded,
  setIsFilePicked,
  shouldSaveDocument,
}: Props) => {
  const [isError, setIsError] = useState(false)
  const [inductionDocUploaded, setInductionDocUploaded] = useState(false)
  const [qtsDocUploaded, setQtsDocUploaded] = useState(false)
  const [teachingDocUploaded, setTeachingDocUploaded] = useState(false)
  const [uploadedSectionsQty, setUploadedSectionsQty] = useState<null | number>(null)
  useEffect(() => {
    if (qtsDocUploaded || teachingDocUploaded || inductionDocUploaded) {
      setIsFilePicked(true)
    }
    if (!qtsDocUploaded && !teachingDocUploaded && !inductionDocUploaded) {
      setIsFilePicked(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtsDocUploaded, teachingDocUploaded, inductionDocUploaded])

  useEffect(() => {
    const limit = countTrueItems([inductionDocUploaded, qtsDocUploaded, teachingDocUploaded])
    if (uploadedSectionsQty !== null && uploadedSectionsQty === limit) {
      setAllDocumentsUploaded()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedSectionsQty])

  useEffect(() => {
    if (isError && onFilesErrorCallback) onFilesErrorCallback()
  }, [isError, onFilesErrorCallback])

  const onFilesChangeCallback = (doc: string) => {
    switch (doc) {
      case DOCUMENT_TYPES[0]:
        return setQtsDocUploaded
      case DOCUMENT_TYPES[1]:
        return setTeachingDocUploaded
      case DOCUMENT_TYPES[2]:
        return setInductionDocUploaded
      default:
        return null as never
    }
  }

  return (
    <>
      {DOCUMENT_TYPES.map((document, index) => (
        <View style={styles.section} key={index}>
          <Text
            style={StyleSheet.flatten([styles.sectionTitle, disabled && !certificates.length && styles.textDisabled])}
          >
            {translate(translations[`teachingCertificatesSection${index + 1}`])}
          </Text>
          <FileUploader
            arrayDocuments={certificates.filter(doc => doc.documentType === document)}
            buttonDisabled={disabled}
            deletingFilesDisabled={disabled}
            documentType={document}
            onFileSaveCallback={() => setUploadedSectionsQty(prev => (prev === null ? 1 : prev + 1))}
            onFilesErrorCallback={() => setIsError(true)}
            onFilesChangeCallback={({ uploadedFiles }: OnFilesChangeCallbackArguments) =>
              onFilesChangeCallback(document)(!!uploadedFiles?.length)
            }
            saveOnUpload={shouldSaveDocument}
          />
        </View>
      ))}
    </>
  )
}

export default MultipleFileUploader
