import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  animation: { bottom: 0, height: 40, position: 'absolute', width: 48 },
  give: {
    left: '55%',
    transform: [{ translateX: -45 }],
  },
  split: { right: 0 },
  take: { right: 4 },
})

export default styles
