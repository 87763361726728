import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    gap: 40,
  },
  itemContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innerItemContainer: {
    gap: 16,
  },
})

export default styles
