import { Platform } from 'react-native'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import get from 'lodash/get'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import { BUILD_MODE, NOTIFICATION_TOKEN_KEY } from 'src/utils/webAdapters/DotEnvAdapter'

import { setAppIsLoading } from 'src/store/app/actions'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import I18n from 'src/utils/translations/i18n'
import navigationService from 'src/utils/navigationService'
import storage from 'src/mmkvStorage'
import store from 'src/store'
import translations, { translate } from 'src/utils/translations/translations'
import { apiConfig, appVersion } from './apiConfig'
import { ErrorResponseT } from 'src/utils/types'
import { getRefreshToken, getToken, setRefreshToken, setToken } from 'src/utils/apolloStorage'
import { AxiosOffline } from 'src/utils/axiosOffline'
import { logoutUserWithoutInternet, logoutUserWithoutMutations } from 'src/store/user/reusableActions'

let isNetworkLogout = false

type ConfigType = {
  data?: object | string
  onSuccess?: (data?: any) => any
  onError?: (param?: AxiosError | AxiosError<ErrorResponseT> | AxiosResponse | any) => void
}

// @ts-ignore
const baseURL: string = __DEV__
  ? apiConfig.staging.se.url
  : // @ts-ignore
    get(apiConfig, [[BUILD_MODE || __BUILD_MODE__], 'se', 'url']) || apiConfig.production.se.url

const axiosConfig = {
  headers: {
    'Accept-Language': I18n.locale.substr(0, 2),
    'Content-Type': 'application/json; charset=utf-8',
    'Student-Origin': `MOBILE_APP_${Platform.OS.toUpperCase()}`,
  },
  timeout: 31000,
}

const isNetworkError = (error: AxiosError<ErrorResponseT>) => String(error).toLowerCase().includes('network error')

const isPasswordError = (error: AxiosError<ErrorResponseT>) =>
  String(error).toLowerCase().includes('password is incorrect')

const urlWithAppVersion = (url: string) => {
  const divider = url.includes('?') ? '&' : '?'
  return `api/v1${url[0] === '/' ? url : `/${url}`}${divider}appVersion=${appVersion}`
}

export let axiosInstance = axios.create({
  baseURL,
  ...axiosConfig,
})

const refreshInterceptor = async (error: any) => {
  const originalRequest = error.config
  if (error && isNetworkError(error)) {
    originalRequest._retry = false
    if (!isNetworkLogout) {
      isNetworkLogout = true
      showNetworkErrorModal()

      if (store?.dispatch) store?.dispatch(logoutUserWithoutInternet())
    }
  } else {
    const refreshToken = getRefreshToken()
    if (error?.response?.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true

        const result = await fetch(`${axiosInstance?.defaults?.baseURL}/graphql`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${refreshToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `mutation RefreshAccessToken {
              refreshAccessToken {
                accessToken
                refreshToken
              }
            }`,
          }),
        }).catch(refreshTokenError => {
          bugsnagActionBreadcrumb('TOKEN error')

          if ((refreshTokenError && isNetworkError(refreshTokenError)) || !store?.getState?.()?.app?.isConnected) {
            if (store?.dispatch) {
              store?.dispatch(logoutUserWithoutInternet())
            }
          } else if (store?.dispatch) {
            store?.dispatch(logoutUserWithoutMutations())
          }
        })

        const response = await result?.json()
        const newToken = response?.data?.refreshAccessToken?.accessToken
        const newRefreshToken = response?.data?.refreshAccessToken?.refreshToken

        if (newToken && newRefreshToken) {
          setToken(newToken)
          setRefreshToken(newRefreshToken)

          originalRequest.headers.Authorization = `Bearer ${newToken}`
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`
        } else {
          originalRequest.headers.Authorization = `Bearer ${getToken()}`
        }
        return axiosInstance(originalRequest)
      } catch (tryError) {
        bugsnagActionBreadcrumb('TOKEN error')
        if ((tryError && isNetworkError(tryError)) || !store?.getState?.()?.app?.isConnected) {
          if (store?.dispatch) {
            store?.dispatch(logoutUserWithoutInternet())
          }
        } else if (store?.dispatch) {
          store?.dispatch(logoutUserWithoutMutations())
        }
      }
    }
    return Promise.reject(error)
  }

  return Promise.reject(error)
}

const errorConfig = (
  url: string,
  requestMethod: Method,
  error: AxiosError<ErrorResponseT>,
  errorCallback?: (someParam?: any) => void,
) => {
  store.getState().app.isLoading && store.dispatch(setAppIsLoading(false))
  errorCallback ? errorCallback(error) : customErrorHandler(url, requestMethod, error)
}

export const showErrorModal = (error: string) => {
  navigationService.navigate('Modal', {
    error,
    danger: true,
    description: translate(translations.reportError),
    title: translate(translations.somethingWentWrong),
  })
  if (__DEV__) {
    console.error(error)
  }
}

export const showErrorModalToken = () => {
  navigationService.navigate('Modal', {
    closeButtonText: translate(translations.close),
    danger: false,
    description: translate(translations.reportTokenOutdatedDescription),
    title: translate(translations.reportTokenOutdatedTitle),
  })
}

export const showNetworkErrorModal = () => {
  navigationService.navigate('Modal', {
    danger: true,
    isNetworkError: true,
    description: translate(translations.connectionLostDescription),
    title: translate(translations.connectionLost),
  })
}

const customErrorHandler = (url: string, requestMethod: Method, error: AxiosError<ErrorResponseT>) => {
  if (error && !isNetworkError(error)) {
    bugsnagNotifyWithData('Error request', error)
    showErrorModal(`${requestMethod} request with url - ${url}\n failed with error: \n ${JSON.stringify(error)}`)
  }
  if (error && isNetworkError(error)) {
    showNetworkErrorModal(JSON.stringify(error))
    if (store?.dispatch) store?.dispatch(logoutUserWithoutInternet())
  }
}

export const responseErrorCallback = (
  requestMethod: Method,
  url: string,
  response: AxiosResponse | AxiosError<ErrorResponseT> | AxiosError['response'],
) => {
  const isConnected = store?.getState?.()?.app?.isConnected

  try {
    if (isNetworkError(response) || !isConnected) {
      if (!isNetworkLogout) {
        isNetworkLogout = true
        showNetworkErrorModal(JSON.stringify(response))
      }
    } else {
      const errors = get(response, 'data.errors', []) as { detail: string }[]
      const detail = head(errors) ? head(errors)?.detail : ''

      if (!isEmpty(detail)) {
        if (!isPasswordError) showErrorModal(detail as string)
      } else {
        showErrorModal(`${requestMethod} with url ${url} failed with response: ${JSON.stringify(response)}`)
      }
    }
  } catch (error) {
    if (isNetworkError(error) || !isConnected) {
      if (!isNetworkLogout) {
        isNetworkLogout = true
        showNetworkErrorModal(JSON.stringify(error))
      }
    } else {
      navigationService.navigate('Modal', {
        description: translate(translations.internalErrorDescription),
        title: translate(translations.internalError),
      })
    }
  }
}

axiosInstance?.interceptors?.response?.use(res => res, refreshInterceptor)

export const AxiosOfflineAdapter = new AxiosOffline({
  axiosInstance: axiosInstance,
  storageInstance: storage,
})

export let axiosOfflineInstance = axios.create({
  baseURL,
  ...axiosConfig,
  adapter: AxiosOfflineAdapter.adapter,
})

export const changeUrl = (url: string) => {
  axiosInstance = axios.create({
    baseURL: url,
    ...axiosConfig,
  })
  axiosInstance?.interceptors?.response?.use(res => res, refreshInterceptor)

  axiosOfflineInstance = axios.create({
    baseURL: url,
    ...axiosConfig,
    adapter: AxiosOfflineAdapter.adapter,
  })
}

const makeRequest = async (
  url: string,
  config: ConfigType,
  request: <T = string, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: D,
    extraParams?: AxiosRequestConfig<D>,
  ) => Promise<R>,
  requestType: Method,
) => {
  if (!axiosInstance.defaults.headers.common.Authorization) {
    const token = getToken()
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  const isGet = requestType === 'get'

  // @ts-ignore
  return request(urlWithAppVersion(url), isGet ? config : config.data, isGet ? undefined : config)
    .then(config.onSuccess)
    .catch((error: AxiosError<ErrorResponseT>) =>
      errorConfig(urlWithAppVersion(url), requestType, error, config.onError),
    )
}

const makeRequestForOffline = async (
  url: string,
  config: ConfigType,
  request: <T = string, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: D,
    extraParams?: AxiosRequestConfig<D>,
  ) => Promise<R>,
  requestType: Method,
) => {
  axiosOfflineInstance.defaults.headers.common.Authorization = `Bearer ${NOTIFICATION_TOKEN_KEY}`

  // @ts-ignore
  return request(urlWithAppVersion(url), config.data, config)
    .then(config.onSuccess)
    .catch((error: AxiosError<ErrorResponseT>) =>
      errorConfig(urlWithAppVersion(url), requestType, error, config.onError),
    )
}

export default {
  changeUrl,
  get: (url: string, config: ConfigType) => makeRequest(url, config, axiosInstance.get, 'get'),
  post: (url: string, config: ConfigType = {}) => makeRequest(url, config, axiosInstance.post, 'post'),
  put: (url: string, config: ConfigType = {}) => makeRequest(url, config, axiosInstance.put, 'put'),
  postOffline: (url: string, config: ConfigType = {}) =>
    makeRequestForOffline(url, config, axiosOfflineInstance.post, 'post'),
  putOffline: (url: string, config: ConfigType = {}) =>
    makeRequestForOffline(url, config, axiosOfflineInstance.put, 'put'),
  delete: (url: string, config: ConfigType = {}) => makeRequest(url, config, axiosInstance.delete, 'delete'),
}
