import { View, Text } from 'react-native'
import React from 'react'

import BadgeItem from 'src/components/BadgeItem'
import { JobDetailsBookingProps } from 'src/scenes/Main/Jobs/Details/components/JobDetails/types'
import styles from './styles'

type Props = Pick<JobDetailsBookingProps, 'competences' | 'jobRoles' | 'jobLevel' | 'jobTitle' | 'jobGrade'>

const OfferInfo = ({ competences, jobRoles, jobLevel, jobTitle, jobGrade }: Props) => (
  <>
    <Text style={styles.headerTitle}>{jobTitle}</Text>
    <View style={styles.badgesContainer}>
      {jobLevel
        ? jobLevel.map(({ title, id }) => <BadgeItem title={title} key={id} type="standard" testID="level" />)
        : null}
      {competences
        ? competences.map(({ name, id }) => <BadgeItem title={name} key={id} type="standard" testID="role" />)
        : null}
      {jobRoles
        ? jobRoles.map(({ title, id }) => <BadgeItem title={title} key={id} type="light" testID="subject" />)
        : null}
      {jobGrade
        ? jobGrade.map((grade, index) => <BadgeItem title={grade} key={index} type="light" testID="grade" />)
        : null}
    </View>
  </>
)

export default OfferInfo
