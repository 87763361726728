import { Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const isIOS = Platform.OS === 'ios'

export default StyleSheet.create({
  container: {
    ...Platform.select({
      ios: {
        justifyContent: 'center',
      },
      android: {
        backgroundColor: colorsNew.white,
      },
      web: {
        backgroundColor: colorsNew.white,
        justifyContent: 'center',
        marginTop: -5,
        minHeight: 34,
      },
    }),
  },
  containerPadding: {
    paddingTop: paddingNew.md,
  },
  smallContainerPadding: {
    paddingTop: paddingNew.sm,
  },
  title: {
    ...typography.overline,
    color: colorsNew.redesign.text.primary,
    paddingBottom: isIOS ? paddingNew.xs : paddingNew.xxs,
  },
})
