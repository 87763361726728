export const alertColorScheme = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
} as const

export const alertVariants = {
  filled: 'filled',
  outlined: 'outlined',
  standard: 'standard',
} as const

type ValueOf<T> = T[keyof T]

export type AlertVariants = ValueOf<typeof alertVariants>
export type AlertColorScheme = ValueOf<typeof alertColorScheme>
