import moment from 'moment-timezone'

import formatter from 'src/utils/formatter'
import { getTimezone } from 'src/global/utils'

export const getDefaultStartTime = (date: string) =>
  moment.tz(`${formatter.apiFormatWithoutTimezone(date)} 08:00`, getTimezone()).toISOString()

export const getDefaultEndTime = (date: string) =>
  moment.tz(`${formatter.apiFormatWithoutTimezone(date)} 17:00`, getTimezone()).toISOString()

export const getMinStartTime = (date: string) =>
  moment.tz(`${formatter.apiFormatWithoutTimezone(date)} 06:00`, getTimezone()).toISOString()

export const getMaxEndTime = (date: string) =>
  moment.tz(`${formatter.apiFormatWithoutTimezone(date)} 23:00`, getTimezone()).toISOString()
