import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const defaultStyles = { borderRadius: 50, height: 14 }

export default StyleSheet.create({
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    ...typography.overline,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.xs,
    lineHeight: 15.6,
  },
  successStyle: {
    ...defaultStyles,
    backgroundColor: colorsNew.redesign.secondary.shades8p,
  },
})
