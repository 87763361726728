import { StyleSheet, Platform } from 'react-native'

import { typography, colorsNew, paddingNew } from 'src/styles'

const isWeb = Platform.OS === 'web'

const styles = StyleSheet.create({
  container: {
    marginBottom: paddingNew.lg,
    marginHorizontal: 24,
    marginTop: paddingNew.base,
  },
  header: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  description: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.md,
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.other.divider,
    borderRadius: 2,
    height: 4,
    marginBottom: paddingNew.md,
    width: 80,
  },
  buttonsDivider: {
    width: paddingNew.xs,
  },
  buttonsContainer: {
    flexDirection: 'row',
    marginTop: paddingNew.lg,
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: !isWeb
    ? {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }
    : {},
})

export default styles
