import React from 'react'
import Svg, { Path, Circle } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const mailFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 50 50" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Circle cx="25" cy="25" r="25" fill={fill} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5833 18.0413C11.5833 16.6146 12.7398 15.458 14.1666 15.458H34.8332C36.26 15.458 37.4166 16.6146 37.4166 18.0413V30.958C37.4166 32.3847 36.26 33.5413 34.8332 33.5413H14.1666C12.7399 33.5413 11.5833 32.3847 11.5833 30.958V18.0413ZM14.1666 19.5155V29.6663C14.1666 30.3797 14.7449 30.958 15.4583 30.958H33.5416C34.2549 30.958 34.8332 30.3797 34.8332 29.6663V19.5155L25.2846 26.8174C24.8214 27.1716 24.1785 27.1716 23.7153 26.8174L14.1666 19.5155ZM32.5083 18.0413H16.4916L24.5 24.1653L32.5083 18.0413Z"
      fill="#F7FCFB"
    />
  </Svg>
)

export default mailFilledIcon
