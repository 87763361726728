import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.md - 4,
  },
  timesheetButton: {
    marginVertical: paddingNew.sm - 4,
  },
  helpText: {
    ...typography.helperText,
    color: colorsNew.redesign.text.disabled,
    textAlign: 'center',
  },
})
export default styles
