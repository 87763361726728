import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import {
  filterChipColor,
  filterChipSize,
  filterChipState,
} from 'src/scenes/Main/Redesign/components/FilterChip/variants'
import Chip from 'src/scenes/Main/Redesign/components/FilterChip'
import translations, { translate } from 'src/utils/translations/translations'

import styles from './styles'
import { getTimeFrameOptions } from './options'

export interface Props {
  onPress: (selectedOption: { value: string; key: string }) => void
  value: string
}

const TimeFrameSelector = ({ onPress, value }: Props) => {
  const timeFrameOptions = getTimeFrameOptions()
  const [selectedValue, setSelectedValue] = useState(value)

  const checkState = (valueProp: string) =>
    selectedValue === valueProp ? filterChipState.active : filterChipState.enabled

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  return (
    <View style={styles.container}>
      <Chip
        color={filterChipColor.primary}
        onPress={() => onPress(timeFrameOptions[0])}
        size={filterChipSize.small}
        state={checkState(translations.thisYear)}
        text={translate(translations.thisYear)}
      />
      <Chip
        color={filterChipColor.primary}
        onPress={() => onPress(timeFrameOptions[1])}
        size={filterChipSize.small}
        state={checkState(translations.lastYear)}
        text={translate(translations.lastYear)}
      />
      <Chip
        color={filterChipColor.primary}
        onPress={() => onPress(timeFrameOptions[2])}
        size={filterChipSize.small}
        state={checkState(translations.sinceStarting)}
        text={translate(translations.sinceStarting)}
      />
    </View>
  )
}

export default TimeFrameSelector
