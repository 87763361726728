import React from 'react'
import { View } from 'react-native'
import { GiftedChatProps, Send } from 'react-native-gifted-chat'

import Icon from 'src/components/Icon'

import styles from './styles'

const CustomSendButton = (props: GiftedChatProps) => (
  <Send {...props}>
    <View style={styles.sendIconContainer}>
      <Icon name="arrow-back" size={15} style={styles.sendIcon} />
    </View>
  </Send>
)

export default CustomSendButton
