import React from 'react'

import { compliancesStatus } from 'src/global/constants'
import { VerifiedIcon, WarningAmberIcon, WarningIcon } from 'src/icons'
import translations, { translate } from 'src/utils/translations/translations'
import type { CompliancesStatus } from 'src/global/types'
import { colorsNew } from 'src/styles'

export const getComplianceStatusInfo = (status: CompliancesStatus | null) => {
  switch (status) {
    case compliancesStatus.completed:
      return {
        StatusIcon: <VerifiedIcon size={16} fill={colorsNew.redesign.success.shades50p} />,
        text: translate(translations.complianceFulfilled),
      }
    case compliancesStatus.warning:
      return {
        StatusIcon: <WarningAmberIcon size={16} fill={colorsNew.redesign.warning.shades50p} />,
        text: translate(translations.complianceWarning),
      }
    case compliancesStatus.expired:
      return {
        StatusIcon: <WarningIcon size={16} fill={colorsNew.redesign.warning.shades50p} />,
        text: translate(translations.complianceExpired),
      }
    default:
      return null
  }
}
