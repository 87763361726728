import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  schoolInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.other.divider,
    height: 1,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.sm,
    width: '100%',
  },
  moreText: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    paddingTop: paddingNew.xs,
  },
})
