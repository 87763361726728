import translations, { translate } from 'src/utils/translations/translations'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'

export const desiredYearGroup = [
  {
    id: 'Nursery',
    title: translate((translations as TranslationGBT).yearGroupNursery),
  },
  {
    id: 'Reception',
    title: translate((translations as TranslationGBT).yearGroupReception),
  },
  {
    id: 'Key Stage 1',
    title: translate((translations as TranslationGBT).yearGroupKeyStage, { number: '1' }),
  },
  {
    id: 'Key Stage 2',
    title: translate((translations as TranslationGBT).yearGroupKeyStage, { number: '2' }),
  },
  {
    id: 'Key Stage 3',
    title: translate((translations as TranslationGBT).yearGroupKeyStage, { number: '3' }),
  },
  {
    id: 'Key Stage 4',
    title: translate((translations as TranslationGBT).yearGroupKeyStage, { number: '4' }),
  },
  {
    id: 'Key Stage 5',
    title: translate((translations as TranslationGBT).yearGroupKeyStage, { number: '5' }),
  },
]
