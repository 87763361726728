import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  headerTitle: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
    marginVertical: paddingNew.sm,
  },
  badgesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})
export default styles
