import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { bugsnagActionBreadcrumb, bugsnagNotifyWithData, useBugsnagView } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { ErrorAlert, errorTypes, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getYotiUrl } from './utils'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'DigitalRightToWork'>

const YotiStatus = ({ route }: Props) => {
  const { status, timeToComplete } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - YotiStatus')

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [yotiUrl, setYotiUrl] = useState<string | null>(null)

  const { mixpanel } = useContext(MixpanelContext)

  const onSuccess = useCallback(url => {
    bugsnagActionBreadcrumb('Set Yoti URL', { url })
    setYotiUrl(url)
    setIsError(false)
    setIsLoading(false)
  }, [])

  const onError = useCallback(() => {
    bugsnagNotifyWithData('Yoti url error')
    setIsError(true)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    getYotiUrl(onSuccess, onError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLinkButton = () => {
    mixpanel?.track('Recruitment: ID Verification UK')
    openURLIfCan(yotiUrl)
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      {isTaskCompleted ? <Text style={styles.header}>{translate(translations.yotiCompleted)}</Text> : null}
      <Text style={styles.info}>{translate(translations.yotiIdentity)}</Text>
      <Text style={styles.info}>{translate(translations.yotiUpload)}</Text>
      {isError ? <ErrorAlert hideError={() => setIsError(false)} type={errorTypes.yoti} /> : null}
      <View style={styles.buttonContainer}>
        <ButtonNew
          containerStyle={styles.button}
          disabled={isTaskCompleted || isLoading || isError}
          isLong
          isSubmitting={isLoading}
          onPress={handleLinkButton}
          RightIcon={ChevronRightIcon}
          size={buttonSize.lg}
          title={translate(translations.digitalRightToWorkButton)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.subInfo}>{translate(translations.yotiHint)}</Text>
      <View style={styles.alertWrapper}>
        <AlertCard
          colorSchema={alertColorScheme.info}
          description={translate(translations.yotiResults)}
          variant={alertVariants.outlined}
        />
      </View>
    </ScrollView>
  )
}

export default YotiStatus
