import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.background.paper,
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: paddingNew.xs,
    paddingHorizontal: paddingNew.sm,
  },
  title: {
    ...typography.h7,
  },
  subTitle: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
  rightContainer: {
    alignItems: 'center',
    columnGap: paddingNew.xxs,
    flexDirection: 'row',
  },
})
