import React, { ReactNode, useMemo } from 'react'
import { Text } from 'react-native'
import { ApolloProvider } from '@apollo/client'

import { createClient } from 'src/utils/ApolloClient'
import { getApiUrl } from 'src/utils/apiConfig'
import { countryCodeSelector } from 'src/store/app/selectors'
import { useAppSelector } from '../hooks/reduxHooks'
import { getToken } from 'src/utils/apolloStorage'
import translations, { translate } from 'src/utils/translations/translations'

const ApolloWrapper = ({ children }: { children: ReactNode }) => {
  const authToken = getToken()
  const countryCode = useAppSelector(countryCodeSelector)

  const client = useMemo(() => {
    const url = getApiUrl(countryCode)
    return createClient(authToken, url)
  }, [countryCode, authToken])

  if (client === undefined) return <Text>{translate(translations.loading)}</Text>

  return (
    <ApolloProvider client={client} key="mainNavigator">
      {children}
    </ApolloProvider>
  )
}

export default ApolloWrapper
