import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  leftContainer: {
    flex: 1,
  },
  mainContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.background.paper,
    columnGap: paddingNew.sm,
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.xs,
  },
  rightContainer: {
    alignItems: 'center',
    columnGap: paddingNew.xxs,
    flexDirection: 'row',
  },
  subTitleContainer: {
    columnGap: paddingNew.xs,
    flexDirection: 'row',
  },
  subTitle: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    textTransform: 'capitalize',
  },
  title: {
    ...typography.subtitle1,
  },
})
