import React from 'react'

import LoadingIndicator from 'src/components/LoadingIndicator'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import { paddingNew } from 'src/styles'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'

type Props = {
  isLoading: boolean
}

const Placeholder = ({ isLoading }: Props) => {
  if (isLoading) {
    return <LoadingIndicator fullScreen={false} style={styles.loader} size="large" />
  }

  return (
    <EmptyPlaceholder
      containerStyles={styles.container}
      description={translate(translations.emptyAllOffersListDescription)}
      emptyImageStyles={{ marginBottom: paddingNew.xs }}
      title={translate(translations.emptyAllOffersListTitle)}
      type={placeholderTypes.loupe}
    />
  )
}

export default Placeholder
