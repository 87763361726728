import React, { ComponentType } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { DayProps } from 'react-native-calendars/src/calendar/day'
import { DateData } from 'react-native-calendars'

import styles from './styles'

interface Props extends Omit<DayProps, 'date'> {
  date: DateData
}

const Day = ({ date, state, marking, onPress }: Props) => {
  const isDisabled = state === 'disabled'

  return (
    <View style={styles.dayContainer}>
      <TouchableOpacity
        //@ts-ignore
        onPress={() => onPress(date)}
        style={StyleSheet.flatten([
          styles.dayCircle,
          marking?.selected && styles.dayCircleSelected,
          isDisabled && styles.dayCircleDisabled,
        ])}
        testID="day-button"
      >
        <Text
          style={StyleSheet.flatten([
            styles.dayText,
            marking?.selected && styles.dayTextSelected,
            isDisabled && styles.dayTextDisabled,
          ])}
          testID="day-text"
        >
          {date.day}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default Day as ComponentType<DayProps & { date?: DateData | undefined }>
