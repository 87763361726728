import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertContainer: {
    marginVertical: paddingNew.sm,
  },
  backgroundContainer: {
    paddingVertical: paddingNew.sm,
    position: 'relative',
  },
  backgroundContainerFill: {
    backgroundColor: colorsNew.redesign.primary.shades12p,
    bottom: 0,
    left: -16,
    position: 'absolute',
    right: -16,
    top: 0,
  },
  buttonContainer: {
    alignItems: 'center',
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginVertical: paddingNew.md,
    paddingBottom: paddingNew.md,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  hyperlink: {
    textDecorationLine: 'underline',
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  mainText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  medicalNote: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.md,
    paddingBottom: paddingNew.md,
  },
  secondaryText: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
  subTitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.sm,
  },
  subInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginTop: paddingNew.sm,
  },
})

export default styles
