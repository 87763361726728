import React, { useState } from 'react'
import Animated, { ZoomIn, ZoomOut } from 'react-native-reanimated'
import { Pressable, StyleSheet, Text, TextStyle, View } from 'react-native'

import styles from './styles'
import { colorsNew } from 'src/styles'
import { CheckboxBlankIcon, CheckboxIcon } from 'src/icons'

type Props = {
  color?: 'dark' | 'light'
  customLabelStyles?: TextStyle
  disabled?: boolean
  label: string
  onPress: () => void
  selected: boolean
  testID?: string
  variant?: 'sm' | 'lg'
}

const Checkbox = ({
  color = 'dark',
  customLabelStyles,
  disabled,
  label,
  onPress,
  selected,
  testID,
  variant = 'lg',
}: Props) => {
  const [isPressed, setIsPressed] = useState(false)
  const [wasPressed, setWasPressed] = useState(false)

  const elementSize = variant === 'sm' ? 38 : 42
  const iconSize = variant === 'sm' ? 20 : 24

  const checkboxSize = { width: elementSize, height: elementSize }

  const viewTestID = () => {
    const newTestId = testID ? `${testID}-` : ''
    return selected ? `${newTestId}selected` : `${newTestId}not-selected`
  }

  return (
    <Pressable
      onPressIn={() => {
        setIsPressed(true)
      }}
      onPressOut={() => {
        setIsPressed(false)
        setWasPressed(true)
      }}
      disabled={disabled}
      onPress={onPress}
      testID={testID}
    >
      <View style={styles.container} testID={viewTestID()}>
        {isPressed ? (
          <Animated.View
            entering={ZoomIn.duration(200)}
            exiting={ZoomOut.delay(100)}
            style={StyleSheet.flatten([styles.shadow, checkboxSize])}
          />
        ) : null}

        {selected ? (
          <>
            <Animated.View
              entering={wasPressed ? ZoomIn.duration(200).springify() : undefined}
              exiting={ZoomOut}
              style={StyleSheet.flatten([styles.iconContainer, checkboxSize])}
            >
              <CheckboxIcon
                size={iconSize}
                fill={
                  disabled
                    ? colorsNew.redesign.action.disabled
                    : color === 'dark'
                    ? colorsNew.redesign.secondary.main
                    : colorsNew.redesign.primary.main
                }
              />
            </Animated.View>
          </>
        ) : (
          <View style={StyleSheet.flatten([styles.iconContainer, checkboxSize])}>
            <CheckboxBlankIcon
              size={iconSize}
              fill={disabled ? colorsNew.redesign.action.disabled : colorsNew.redesign.secondary.light}
            />
          </View>
        )}
        <Text style={StyleSheet.flatten([styles.label, customLabelStyles, disabled && styles.labelDisabled])}>
          {label}
        </Text>
      </View>
    </Pressable>
  )
}
export default Checkbox
