import { StyleSheet } from 'react-native'

import { typography } from './typography'
import { padding } from './spacing'
import { fonts } from './fonts'
import colors from './colors'
import colorsNew from './colorsNew'
import fontSizes from './fontSizes'

export default StyleSheet.create({
  availabilityBorder: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    borderWidth: 5,
    borderColor: colors.transparent,
  },
  centerSelf: {
    alignSelf: 'center',
  },
  flex: {
    flex: 1,
  },
  sectionTitle: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizes.md,
  },
  smallText: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsRegular,
    fontSize: fontSizes.xs,
    lineHeight: 20,
  },
  longText: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.muktaRegular,
    fontSize: fontSizes.xs,
    lineHeight: 20,
  },
  mediumText: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
  bigText: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizes.xl,
  },
  onboardingText: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
  onboardingBoldText: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginBottom: padding.sm,
  },
  shadow: {
    shadowColor: colors.shadow,
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  whiteBackground: {
    backgroundColor: colors.white,
  },
  blueBackground: {
    backgroundColor: colors.blue._100,
  },
  scrollView: { backgroundColor: colors.white, flex: 1 },
  listLoader: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
  navTab: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  navTitle: {
    color: colors.blue._400,
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: 5,
    marginBottom: 10,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizes.xxs,
  },
  activeNavTitle: {
    color: colorsNew.redesign.text.primary,
  },
  fullHeight: {
    height: '100%',
  },
  column: { flexDirection: 'column' },
  buttonWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})
