import React from 'react'
import { Text, View } from 'react-native'

import styles from './styles'

type ChipT = {
  text: string
}

const Chip = ({ text }: ChipT) => (
  <View style={styles.constainer}>
    <Text style={styles.text}>{text}</Text>
  </View>
)

export default Chip
