import React from 'react'
import Svg, { Path, Circle, Defs, ClipPath, G } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const TimeEditableIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 200 200" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M100 163.602c35.198 0 63.731-28.533 63.731-63.731S135.198 36.139 100 36.139 36.268 64.672 36.268 99.87c0 35.198 28.534 63.731 63.732 63.731Z"
      fill="#224D3D"
    />
    <Path
      d="M102.681 154.243c30.844 0 55.848-25.004 55.848-55.848 0-30.844-25.004-55.847-55.848-55.847-30.844 0-55.848 25.003-55.848 55.847s25.004 55.848 55.848 55.848Z"
      fill="#F7FCFB"
    />
    <Path
      d="m96.792 51.705-.224.03a.411.411 0 0 1-.461-.357l-.602-4.688a.411.411 0 0 1 .358-.461l.224-.03c.227-.03.431.13.461.358l.602 4.687a.418.418 0 0 1-.358.461ZM109.34 149.299l-.224.03a.411.411 0 0 1-.461-.358l-.602-4.687a.411.411 0 0 1 .358-.462l.224-.03a.411.411 0 0 1 .461.358l.602 4.687a.417.417 0 0 1-.358.462ZM56.541 103.571l.03.224a.411.411 0 0 1-.357.462l-4.688.601a.41.41 0 0 1-.461-.357l-.03-.224a.411.411 0 0 1 .358-.462l4.687-.601a.415.415 0 0 1 .461.357ZM154.135 91.023l.03.225a.412.412 0 0 1-.358.46l-4.687.603a.411.411 0 0 1-.462-.358l-.03-.224a.412.412 0 0 1 .358-.461l4.687-.602a.416.416 0 0 1 .462.358ZM74.138 134.441l.177.137a.41.41 0 0 1 .073.578l-2.888 3.742a.41.41 0 0 1-.579.073l-.177-.137a.41.41 0 0 1-.073-.578l2.888-3.742a.41.41 0 0 1 .579-.073ZM134.275 56.557l.177.137c.181.14.214.398.074.578l-2.889 3.741a.41.41 0 0 1-.578.074l-.177-.137a.411.411 0 0 1-.074-.579l2.889-3.74a.41.41 0 0 1 .578-.074ZM139.273 126.226l.138-.177a.41.41 0 0 1 .578-.074l3.741 2.889a.412.412 0 0 1 .074.579l-.137.177a.412.412 0 0 1-.579.073l-3.741-2.888a.418.418 0 0 1-.074-.579ZM61.393 66.089l.137-.178a.411.411 0 0 1 .578-.073l3.742 2.889a.41.41 0 0 1 .073.578l-.137.177a.411.411 0 0 1-.579.074l-3.74-2.889a.41.41 0 0 1-.074-.578Z"
      fill="#1C1C1C"
    />
    <Path d="M110.009 98.914a5.059 5.059 0 1 0 0-10.118 5.059 5.059 0 0 0 0 10.118Z" fill="#00B58B" />
    <Path d="m150.471 113.581-54.684-28.98-1.338 2.59 55.283 27.814c1.18.559 1.876-.785.739-1.424Z" fill="#00B58B" />
    <Circle cx={139.878} cy={46.195} fill="#00B58B" r={23.854} />
    <G clipPath="url(#a)">
      <Path
        d="M150.64 36.292a2.57 2.57 0 0 1 0 3.638l-1.378 1.382-4.501-4.5 1.382-1.381a2.572 2.572 0 0 1 3.638 0l.859.86Zm-14.602 9.246 7.685-7.687 4.501 4.5-7.689 7.684a2.555 2.555 0 0 1-1.006.625l-4.079 1.36c-.437.128-.832.027-1.13-.308-.294-.257-.395-.694-.267-1.089l1.36-4.079a2.55 2.55 0 0 1 .625-1.006Zm.9-8.204c.813 0 1.47.7 1.47 1.47 0 .855-.657 1.47-1.47 1.47h-4.41c-.812 0-1.47.698-1.47 1.47v11.8c0 .814.658 1.47 1.47 1.47h11.76c.813 0 1.469-.656 1.469-1.47v-4.45c0-.772.657-1.47 1.47-1.47s1.47.698 1.47 1.47v4.45a4.41 4.41 0 0 1-4.409 4.41h-11.76a4.41 4.41 0 0 1-4.41-4.41v-11.8a4.41 4.41 0 0 1 4.41-4.41h4.41Z"
        fill="#F7FCFB"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(128.118 34.436)" d="M0 0h23.519v23.519H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default TimeEditableIcon
