import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colors, colorsNew, fonts, fontSizes, paddingNew, typography } from 'src/styles'
import { BAR_HEIGHT } from 'src/styles/variables'
import globalStyles from 'src/global/globalStyles'

const { width: SCREEN_WIDTH } = Dimensions.get('window')
const LEFT_SECTION_WIDTH = 50

const styles = StyleSheet.create({
  cardStyle: {
    backgroundColor: colors.transparent,
  },
  header: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderColor: colorsNew.grey._200,
    height: 50,
    elevation: 0,
    ...Platform.select({
      android: {
        height: BAR_HEIGHT,
      },
    }),
  },
  headerBackground: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    height: 62,
    ...globalStyles.containerSize,
  },
  onboardingHeaderTitle: {
    ...typography.h7,
    alignItems: 'center',
    color: colorsNew.redesign.text.primary,
    justifyContent: 'center',
    maxWidth: SCREEN_WIDTH - 2 * LEFT_SECTION_WIDTH,
    textAlign: 'center',
  },
  onboardingHeaderButtonLeft: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    marginLeft: paddingNew.xs,
    width: 40,
  },
  headerTitle: {
    ...typography.h7,
    alignSelf: 'stretch',
    borderBottomWidth: 0,
    color: colorsNew.redesign.text.primary,
    textAlign: 'center',
    textTransform: 'none',
    ...Platform.select({
      ios: {
        textAlign: 'center',
      },
      android: { textAlign: 'left' },
    }),
  },
  headerTitleOld: {
    borderBottomWidth: 0,
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizes.lg,
    fontWeight: '400',
    alignSelf: 'stretch',
    ...Platform.select({
      ios: {
        textAlign: 'center',
      },
      android: { textAlign: 'left' },
    }),
  },
})

export default styles
