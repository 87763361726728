import { View, Text } from 'react-native'
import React, { useMemo, useState } from 'react'

import { ChevronRightIcon, DownloadIcon } from 'src/icons'
import { ErrorAlert, errorTypes } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getPolicyDocument } from 'src/store/user/actions'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  disabled?: boolean
  selected: boolean
  toggleStatus: () => void
  type: 'safeguarding_policy' | 'keeping_children_safe_policy'
}

const getSafeguardingPolicyData = () => ({
  buttonLabel: translate(translations.safeguardingPolicy1Button),
  checkboxLabel: translate(translations.safeguardingPolicy1Checkbox),
  description: translate(translations.safeguardingPolicy1Info),
  LeftIcon: DownloadIcon,
  RightIcon: undefined,
  title: translate(translations.safeguardingPolicy1Title),
})

const getKcsPolicyData = () => ({
  buttonLabel: translate(translations.safeguardingPolicy2Button),
  checkboxLabel: translate(translations.safeguardingPolicy2Checkbox),
  description: translate(translations.safeguardingPolicy2Info),
  LeftIcon: undefined,
  RightIcon: ChevronRightIcon,
  title: translate(translations.safeguardingPolicy2Title),
})

const SafeguardingPolicy = ({ disabled, selected, toggleStatus, type }: Props) => {
  const [isError, setIsError] = useState(false)

  const policy = useMemo(() => {
    if (type === 'safeguarding_policy') return getSafeguardingPolicyData()
    return getKcsPolicyData()
  }, [type])

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{policy.title}</Text>
      <Text style={styles.info}>{policy.description}</Text>
      <View style={styles.buttonSection}>
        {isError ? (
          <ErrorAlert
            hideError={() => setIsError(false)}
            type={type === 'keeping_children_safe_policy' ? errorTypes.legislation : errorTypes.file}
          />
        ) : null}
        <View style={styles.buttonWrapper}>
          <ButtonNew
            isShort
            LeftIcon={policy.LeftIcon}
            onPress={() => getPolicyDocument(type, () => setIsError(true))}
            RightIcon={policy.RightIcon}
            title={policy.buttonLabel}
            variant={buttonVariants.outlinedDefault}
          />
        </View>
      </View>
      <Checkbox label={policy.checkboxLabel} selected={selected} onPress={toggleStatus} disabled={disabled} />
    </View>
  )
}

export default SafeguardingPolicy
