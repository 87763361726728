import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  button: {
    marginBottom: paddingNew.md,
    marginTop: 0,
  },
  container: {
    alignItems: 'center',
    marginVertical: paddingNew.md,
    paddingHorizontal: paddingNew.sm,
  },
  text: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
})
