import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import { jobAppointmentType } from 'src/global/constants'
import { setApplicationNotifications, setUnreadNotifications } from 'src/store/inbox/actions'
import NotificationQuery, { NotificationQueryType } from 'src/graphql/Notifications/Notifications'
import usePrevious from 'src/hooks/usePrevious'
import formatter from 'src/utils/formatter'
import { getToken } from 'src/utils/apolloStorage'
import { useAppSelector } from 'src/hooks/reduxHooks'

const useNotificationsCount = () => {
  const userId = useAppSelector(state => state?.user?.id)
  const authToken = getToken()
  const minDate = formatter.apiFormat(moment().subtract(7, 'days'))
  const dispatch = useDispatch()

  const { data } = useQuery<NotificationQueryType>(NotificationQuery, {
    variables: {
      createdAtMin: minDate,
      userId,
    },
    skip: !authToken || !userId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000,
  })

  const userMessagesList = data?.messages?.nodes || []
  const userActivitiesList = data?.userActivities?.nodes || []

  const unreadActivities = filter(userActivitiesList, { read: false })
  const unreadMessages = filter(userMessagesList, { read: false })

  const unreadNotificationsCount = unreadMessages.length + unreadActivities.length

  const filteredApplications = filter(
    unreadActivities,
    activity => activity.resourceAppointmentType === jobAppointmentType.application,
  ).map(item => item.id)

  const prevFilteredApplications = usePrevious(filteredApplications)
  const prevUnreadActivities = usePrevious(unreadNotificationsCount)

  useEffect(() => {
    if (!isEqual(prevFilteredApplications, filteredApplications)) {
      dispatch(setApplicationNotifications(filteredApplications))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredApplications])

  useEffect(() => {
    if (prevUnreadActivities !== unreadNotificationsCount) {
      dispatch(setUnreadNotifications(unreadNotificationsCount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotificationsCount])

  return { unreadNotificationsCount }
}

export default useNotificationsCount
