import React from 'react'
import { View, ViewStyle } from 'react-native'

import EmptyView from 'src/components/EmptyView'
import { placeholderImageProps, placeholderTypes } from './placeholders'
import { Props as EmptyViewProps } from 'src/components/EmptyView/EmptyView'

type Props = EmptyViewProps & {
  containerStyles?: ViewStyle
  onButtonClick?: () => any
  type: keyof typeof placeholderTypes
}

export const EmptyPlaceholder = ({
  title,
  description,
  containerStyles,
  onButtonClick,
  withButton,
  type,
  ...props
}: Props) => (
  <View style={containerStyles}>
    <EmptyView
      description={description}
      onButtonClick={onButtonClick}
      title={title}
      withButton={withButton}
      {...placeholderImageProps(type)}
      {...props}
    />
  </View>
)

export { placeholderImageProps, placeholderTypes }
export default EmptyPlaceholder
