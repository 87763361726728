import { StyleSheet } from 'react-native'

import { colorsNew, padding, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {
    backgroundColor: colorsNew.white,
    paddingBottom: global.isIOS ? padding.xxl : paddingNew.base,
    paddingHorizontal: paddingNew.sm,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm - 5,
    marginTop: paddingNew.sm,
  },
  emptyContainer: {
    paddingTop: 5,
  },
})

export default styles
