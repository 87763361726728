import React, { useState } from 'react'
import { Text, StyleSheet } from 'react-native'

import HourlyReport from './HourlyReport/HourlyReport'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import ModalBase from 'src/components/Modal/NewModal/ModalBase'
import ShiftReport from './ShiftReport/ShiftReport'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import {
  reportInitialFixedValuesTypes,
  reportInitialHoursValuesTypes,
  reportInitialValuesTypes,
} from 'src/hooks/useTimesheetReport'
import { navigateToAppScreen } from 'src/utils/navigateToScreen'
import { bugsnagNotifyWithData } from 'src/utils/bugsnag'

type Props = {
  bookingEndTime: string
  bookingStartTime: string
  bookingId: string
  bookingScheduleType: string
  closeModal: (isDirty?: boolean) => void
  initialValues: reportInitialValuesTypes
  isHidden?: boolean
  isHourly: boolean
  onReportSuccess: () => void
  showModal: boolean
}

const goToSupportHandler = () => navigateToAppScreen(['MainTabNavigator', 'MenuNavigation', 'Support'])

const ReportModal = ({
  bookingEndTime,
  bookingStartTime,
  bookingId,
  bookingScheduleType,
  closeModal,
  initialValues,
  isHourly,
  showModal,
  onReportSuccess,
}: Props) => {
  const [showBackConfirmation, setBackConfirmation] = useState(false)
  const [timeError, setTimeError] = useState<string | null>(null)

  const forceCloseModal = () => {
    setBackConfirmation(false)
    closeModal()
  }

  const onCloseModal = (isEdited?: boolean) => (isEdited ? setBackConfirmation(true) : closeModal())
  const onReportError = (error: string) => setTimeError(error)

  const shouldHideReport = !!timeError || !!showBackConfirmation

  const onErrorModalClose = () => {
    bugsnagNotifyWithData('Report error with timeError', timeError)

    setTimeError(null)
    goToSupportHandler()
  }

  return (
    <NewModalWrapper containerStyle={!shouldHideReport ? styles.container : {}} isVisible={showModal}>
      <Text style={StyleSheet.flatten([styles.title, shouldHideReport && styles.hidden])}>
        {translate(translations.reportTimeTitle)}{' '}
      </Text>

      {isHourly ? (
        <HourlyReport
          bookingEndTime={bookingEndTime}
          bookingStartTime={bookingStartTime}
          bookingId={bookingId}
          closeModal={onCloseModal}
          initialValues={initialValues as reportInitialHoursValuesTypes}
          isHidden={shouldHideReport}
          onReportSuccess={onReportSuccess}
          onReportError={onReportError}
        />
      ) : (
        <ShiftReport
          bookingId={bookingId}
          bookingScheduleType={bookingScheduleType}
          closeModal={onCloseModal}
          initialValues={initialValues as reportInitialFixedValuesTypes}
          isHidden={shouldHideReport}
          onReportSuccess={onReportSuccess}
          onReportError={onReportError}
        />
      )}

      {showBackConfirmation ? (
        <ModalBase
          description={translate(translations.timesheetBackModalDescription)}
          onCancel={() => setBackConfirmation(false)}
          onCancelLabel={translate(translations.backModalCancel)}
          onConfirm={forceCloseModal}
          onConfirmLabel={translate(translations.backModalLeave)}
          title={translate(translations.timesheetBackModalTitle)}
        />
      ) : null}

      {timeError ? (
        <ModalBase
          description={translate(translations.reportError)}
          onCancel={() => setTimeError(null)}
          onCancelLabel={translate(translations.cancel)}
          onConfirm={onErrorModalClose}
          onConfirmLabel={translate(translations.contactSupport)}
          title={translate(translations.somethingWentWrong)}
        />
      ) : null}
    </NewModalWrapper>
  )
}

export default ReportModal
