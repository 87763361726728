import React, { RefObject } from 'react'
import { Text, View } from 'react-native'
import LottieView from 'lottie-react-native'

import styles from 'src/scenes/Main/Salary/SuccessAnimation/styles'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const animationData = require('src/assets/animations/timesheetAnimation.json')

type Props = {
  onAnimationFinish: () => void
  refAnimation: RefObject<any>
  successText: string
}

const SuccessAnimationRender = ({ onAnimationFinish, refAnimation, successText }: Props) => (
  <View style={styles.screen}>
    <View style={styles.animationContainer}>
      <LottieView
        loop={false}
        onAnimationFinish={onAnimationFinish}
        ref={refAnimation}
        source={animationData}
        style={styles.animation}
      />
    </View>
    <Text style={styles.title}>{successText}</Text>
  </View>
)

export default SuccessAnimationRender
