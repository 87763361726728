import { configureFonts, MD2LightTheme } from 'react-native-paper'

import { colorsNew } from '../styles'

const newTheme = { ...MD2LightTheme }
newTheme.colors.tooltip = colorsNew.white

const theme = {
  ...newTheme,
  fonts: configureFonts({ isV3: false }),
}

export default theme
