import React, { ElementType, useMemo, useState } from 'react'
import { View, Pressable, StyleSheet, ActivityIndicator } from 'react-native'

import { IconTypes } from 'src/icons/iconTypes'
import { iconButtonSize, iconButtonVariant } from './variants'
import { IconButtonSize, IconButtonVariant } from './types'
import { colorsNew } from 'src/styles'
import styles, { getButtonSize, getColorsSettings } from './styles'

type Props = {
  disabled?: boolean
  isSubmitting?: boolean
  Icon: ElementType<IconTypes>
  onPress: () => void
  size: IconButtonSize
  testID?: string
  variant: IconButtonVariant
}

const IconButton = ({ Icon, onPress, testID, size, disabled, variant, isSubmitting }: Props) => {
  const [isPressed, setIsPressed] = useState(false)

  const isFilled = variant === iconButtonVariant.filled
  const isEmpty = variant === iconButtonVariant.empty
  const isSmallIcon = size === iconButtonSize.sm

  const colorsSettings = useMemo(
    () => getColorsSettings({ disabled, isPressed, isFilled }),
    [disabled, isPressed, isFilled],
  )

  const buttonSize = useMemo(() => getButtonSize(size), [size])

  const iconSize = () => (isSmallIcon ? 20 : 24)

  return (
    <View style={{ width: buttonSize, height: buttonSize }}>
      <Pressable
        disabled={isSubmitting || disabled}
        onPress={onPress}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}
        testID={testID}
        style={StyleSheet.flatten([
          styles.button,
          {
            backgroundColor: colorsSettings.backgroundColor,
            borderColor: colorsSettings?.borderColor,
            height: buttonSize,
            width: buttonSize,
          },
          isFilled && !disabled && (isPressed ? styles.buttonShadowPressed : styles.buttonShadow),
          isEmpty && styles.buttonEmpty,
        ])}
      >
        {isSubmitting ? (
          <ActivityIndicator color={colorsNew.redesign.primary.main} size={iconSize()} />
        ) : (
          <Icon fill={colorsSettings.iconColor} size={iconSize()} />
        )}
      </Pressable>
    </View>
  )
}

export default IconButton
