import { Platform, StyleSheet } from 'react-native'
import { common, padding } from 'src/styles'

export default StyleSheet.create({
  secureButton: {
    justifyContent: 'flex-end',
    marginBottom: padding.xs,
    ...Platform.select({
      android: {
        alignItems: 'center',
        height: 48,
        width: 48,
      },
    }),
  },
  secureButtonCentered: {
    justifyContent: 'center',
  },
  secureButtonTitle: StyleSheet.flatten([
    common.mediumText,
    {
      marginLeft: padding.sm,
    },
  ]),
  loginSecureButton: { marginBottom: 0 },
  loginSecureView: { marginBottom: 0, position: 'absolute', right: padding.md },
})
