import { Platform } from 'react-native'
import {
  ANDROID_CERT_FINGERPRINT,
  ANDROID_PACKAGE_NAME,
  BUILD_MODE,
  GOOGLE_MAPS_API_KEY_ANDROID_DEV,
  GOOGLE_MAPS_API_KEY_ANDROID_PROD,
  GOOGLE_MAPS_API_KEY_IOS_DEV,
  GOOGLE_MAPS_API_KEY_IOS_PROD,
  GOOGLE_MAPS_API_KEY_WEB_DEV,
  GOOGLE_MAPS_API_KEY_WEB_PROD,
  IOS_BUNDLE_ID,
} from 'src/utils/webAdapters/DotEnvAdapter'

import { bugsnagNotifyWithData } from 'src/utils/bugsnag'

import translations, { translate } from 'src/utils/translations/translations'
import { OnErrorT, OnSuccessT } from 'src/utils/types'

type ValueOf<T> = T[keyof T]

type AddressComponentType = {
  long_name: string
  short_name: string
  types: string[]
}

export const headers:
  | { 'X-Android-Package': string; 'X-Android-Cert': string }
  | { 'x-ios-bundle-identifier': string } =
  Platform.OS === 'ios'
    ? {
        'x-ios-bundle-identifier': IOS_BUNDLE_ID,
      }
    : {
        'X-Android-Package': ANDROID_PACKAGE_NAME,
        'X-Android-Cert': ANDROID_CERT_FINGERPRINT,
      }

const isDevelopment = process.env.NODE_ENV === 'development'

const googlePlacesUrl = 'https://maps.googleapis.com/maps/api/place'

export const googleMapsApiKey = Platform.select({
  ios: () => {
    if (isDevelopment) return GOOGLE_MAPS_API_KEY_IOS_DEV
    return GOOGLE_MAPS_API_KEY_IOS_PROD
  },
  android: () => {
    if (isDevelopment) return GOOGLE_MAPS_API_KEY_ANDROID_DEV
    return GOOGLE_MAPS_API_KEY_ANDROID_PROD
  },
  default: () => {
    if (isDevelopment || (Platform.OS === 'web' && ['staging', 'development'].includes(BUILD_MODE)))
      return GOOGLE_MAPS_API_KEY_WEB_DEV
    return GOOGLE_MAPS_API_KEY_WEB_PROD
  },
})()

export const noSuggestions = {
  label: translate(translations.noSuggestions),
  value: translate(translations.noSuggestions),
  isReadOnly: true,
}

export const error = {
  label: translate(translations.somethingWentWrong),
  value: translate(translations.somethingWentWrong),
  isReadOnly: true,
}

export const AutocompleteTypes = {
  locality: 'locality',
  postalCode: 'postal_code',
  routeOrPremise: 'street_address|premise',
} as const

export const getAutocompleteResponse = async (
  input: string,
  isGB: boolean,
  onError: OnErrorT,
  onSuccess: OnSuccessT,
  types: ValueOf<typeof AutocompleteTypes>,
) => {
  try {
    const response = await fetch(
      `${googlePlacesUrl}/autocomplete/json?input=${input}&key=${googleMapsApiKey}&types=${types}&language=${
        isGB ? 'en-GB' : 'sv'
      }`,
      {
        headers: { ...headers },
      },
    )
    if (!response.ok) {
      bugsnagNotifyWithData('fetchGoogleAutocomplete', response)
      throw new Error('Error during fetching autocomplete')
    }
    onSuccess(await response.json())
  } catch (error) {
    onError()
  }
}

export const mapAutocompleteResponse = (
  predictions: google.maps.places.AutocompletePrediction[],
  status: `${google.maps.places.PlacesServiceStatus}` | '',
  setPlacesIds?: React.Dispatch<React.SetStateAction<any>>,
) => {
  if (status === 'ZERO_RESULTS') return [noSuggestions]

  if (status !== 'OK' && status !== '') return [error]

  setPlacesIds &&
    setPlacesIds(
      predictions.map((prediction: any) => ({
        label: prediction.description,
        placeId: prediction.place_id,
      })),
    )

  return predictions.map((prediction: any) => ({
    isReadOnly: false,
    label: prediction.description,
    value: `${prediction.structured_formatting.main_text}`,
  }))
}

export const getDetails = async (placeId: string, isGB: boolean, onError: OnErrorT, onSuccess: OnSuccessT) => {
  try {
    const response = await fetch(
      `${googlePlacesUrl}/details/json?place_id=${placeId}&key=${googleMapsApiKey}&language=${isGB ? 'en-GB' : 'sv'}`,
      {
        headers: { ...headers },
      },
    )
    if (!response.ok) {
      bugsnagNotifyWithData('fetchPlaceDetails', response)
      throw new Error('Error during fetching details')
    }
    onSuccess(await response.json())
  } catch (error) {
    onError()
  }
}

export const handleDetailsResponse = async ({ address_components }: any) => {
  const zip = address_components.find((addressComponent: AddressComponentType) =>
    addressComponent.types.find((type: string) => type === 'postal_code'),
  )?.long_name
  const city =
    address_components.find((addressComponent: AddressComponentType) =>
      addressComponent.types.find((type: string) => type === 'locality'),
    )?.long_name ||
    address_components.find((addressComponent: AddressComponentType) =>
      addressComponent.types.find((type: string) => type === 'postal_town'),
    )?.long_name
  return { zip, city }
}
