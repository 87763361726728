import { StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, paddingNew } from 'src/styles'

const ICON_SIZE = 30

export const iconStyle = { size: ICON_SIZE, fill: colorsNew.brand.secondary }

const styles = StyleSheet.create({
  title: {
    color: colorsNew.black,
    fontFamily: fonts.poppinsSemiBold,
    fontSize: fontSizesNew.sm.size,
    lineHeight: fontSizesNew.sm.lh,
    textAlign: 'center',
  },
  subtitle: {
    color: colorsNew.grey._600,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizesNew.xs.size,
    marginBottom: paddingNew.base,
    marginTop: paddingNew.xxs,
    textAlign: 'center',
  },
  infoRowComment: {
    color: colorsNew.grey._500,
    fontFamily: fonts.poppinsRegular,
    fontSize: fontSizesNew.xxxs.size,
    lineHeight: fontSizesNew.xxxs.lh,
    paddingLeft: paddingNew.base + ICON_SIZE,
    top: -paddingNew.xs,
  },
  iconContainer: {
    alignItems: 'center',
  },
})

export default styles
