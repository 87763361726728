import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  wrapperContainer: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
    overflow: 'auto',
  },
  wrapper: {
    flex: 1,
    flexBasis: 1,
  },
})

export default styles
