const fontSizes = {
  xxxs: global.isSmall ? 8 : 10,
  xxs: global.isSmall ? 10 : 12,
  xs: global.isSmall ? 12 : 14,
  sm: global.isSmall ? 14 : 16,
  md: global.isSmall ? 16 : 18,
  lg: global.isSmall ? 17 : 19,
  xl: global.isSmall ? 20 : 22,
  xxl: global.isSmall ? 22 : 24,
  smallIconDescription: 8,
  bookingTitle: global.isSmall ? 27 : 29,
  button: global.isSmall ? 14 : 18,
}

export default fontSizes
