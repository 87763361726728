import { TypedDispatch } from 'src/store'
import { CategoriesConfig } from './types'
import { fetchUser } from 'src/store/user/actions'
import { ErrorType } from './RequiredTasks/components'

export const categoriesConfig: CategoriesConfig = {
  se: ['profileInfo', 'qualifications', 'references', 'getToKnowHumly', 'profileVerification', 'contract'],
  gb: [
    'profileInfo',
    'qualifications',
    'references',
    'getToKnowHumly',
    'profileVerification',
    'declarations',
    'finalAgreements',
  ],
}

export const successActions = async (
  userId: string,
  dispatch: TypedDispatch,
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  formAction: () => void,
) => {
  await dispatch(fetchUser(userId))
  setIsSubmitting(false)
  formAction()
}

export const errorActions = (
  error: React.SetStateAction<ErrorType | null>,
  setFormError: React.Dispatch<React.SetStateAction<ErrorType | null>>,
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setFormError(error)
  setIsSubmitting(false)
}

export const successRemoveActions = async (
  userId: string,
  dispatch: TypedDispatch,
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>,
  formAction: () => void,
) => {
  await dispatch(fetchUser(userId))
  setIsDeleting(false)
  formAction()
}

export const errorRemoveActions = (
  error: React.SetStateAction<ErrorType | null>,
  setFormError: React.Dispatch<React.SetStateAction<ErrorType | null>>,
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setFormError(error)
  setIsDeleting(false)
}
