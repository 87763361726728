import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const FeedbackIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M19.9998 2H4.00977C2.90977 2 2.00977 2.9 2.00977 4V22L5.99977 18H19.9998C21.0998 18 21.9998 17.1 21.9998 16V4C21.9998 2.9 21.0998 2 19.9998 2ZM12.9998 14H10.9998V12H12.9998V14ZM12.9998 9C12.9998 9.55 12.5498 10 11.9998 10C11.4498 10 10.9998 9.55 10.9998 9V7C10.9998 6.45 11.4498 6 11.9998 6C12.5498 6 12.9998 6.45 12.9998 7V9Z" />
  </Svg>
)

export default FeedbackIcon
