import React, { ReactElement, useState } from 'react'
import { Platform, RefreshControl, SectionList, SectionListData, SectionListRenderItemInfo, View } from 'react-native'

import { BookingListItemType, BookingTypes } from 'src/graphql/Booking/types'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { colors, colorsNew } from 'src/styles'
import { ExpressBookingsTypes } from 'src/graphql/ExpressBookings'
import { OrderListItemType } from 'src/graphql/Order/types'
import { ProposalListItemType } from 'src/graphql/Proposal'
import { useAppSelector } from 'src/hooks/reduxHooks'
import SectionHeader from './SectionHeader'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { UnreportedItemT } from 'src/scenes/Main/Salary/Timesheet/types'
import globalStyles from 'src/global/globalStyles'

const isWeb = Platform.OS === 'web'

export type FakeEmptyItemType = {
  id: 'empty'
  text: string
}

export type JobItem =
  | BookingListItemType
  | OrderListItemType
  | ExpressBookingsTypes
  | ProposalListItemType
  | BookingTypes
  | UnreportedItemT
  | FakeEmptyItemType
export type AllJobItems = BookingListItemType | OrderListItemType | ExpressBookingsTypes | ProposalListItemType
export type JobExpressItem = ExpressBookingsTypes | FakeEmptyItemType
export type RenderListItemInfo = SectionListRenderItemInfo<JobItem, JobListSectionType>
export type RenderExpressListItemInfo = SectionListRenderItemInfo<JobExpressItem, JobListSectionType>

export type JobListSectionType = {
  data: JobItem[]
  isFirstSection?: boolean
  showTitle?: boolean
  title: string
  withDivider?: boolean
  withSmallTopSpacing?: boolean
}

type Props = {
  ListEmptyComponent?: ReactElement
  ListHeader?: ReactElement
  refreshFunction?: () => Promise<unknown>
  renderJobListItem: RenderListItemInfo | RenderExpressListItemInfo
  sections: JobListSectionType[]
  withFirstSectionSpacing?: boolean
}

type SectionType = { section: SectionListData<JobItem, JobListSectionType> }

const JobsSectionList = ({
  ListEmptyComponent,
  ListHeader,
  refreshFunction,
  renderJobListItem,
  sections,
  withFirstSectionSpacing = true,
}: Props) => {
  const [loading, setLoading] = useState(false)

  const isConnected = useAppSelector(state => state.app.isConnected)

  const onRefresh = () => {
    if (!refreshFunction) return
    setLoading(true)
    bugsnagActionBreadcrumb('refresh list', { isConnected })
    refreshFunction().then(() => setLoading(false))
  }

  const renderSectionTitle = ({ section }: SectionType) => {
    if (section.showTitle !== false)
      return <SectionHeader title={section.title} withSmallTopSpacing={section.withSmallTopSpacing} />
    return withFirstSectionSpacing && section.isFirstSection ? <View style={styles.listMargin} /> : null
  }

  const renderSectionDivider = ({ section }: SectionType) =>
    section.withDivider ? <View style={styles.sectionDivider} /> : null

  return (
    <SectionList
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      keyExtractor={({ id }) => id}
      ListHeaderComponent={ListHeader}
      ListEmptyComponent={ListEmptyComponent}
      refreshControl={
        <RefreshControl
          colors={[colors.blue._400, colorsNew.redesign.text.primary]}
          enabled={!!refreshFunction}
          onRefresh={onRefresh}
          refreshing={loading}
          tintColor={colorsNew.redesign.text.primary}
          title={translate(translations.loading)}
          titleColor={colors.blue._400}
        />
      }
      renderItem={renderJobListItem}
      renderSectionFooter={renderSectionDivider}
      renderSectionHeader={renderSectionTitle}
      sections={sections}
      showsVerticalScrollIndicator={isWeb}
      stickySectionHeadersEnabled
      style={styles.list}
      testID="scrollView"
    />
  )
}

export default JobsSectionList
