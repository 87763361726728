import React, { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { ScrollView } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import CalendarPicker from './Calendar'
import DayAvailability from './DayAvailability'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { AvailabilityProvider, useAvailabilitySchedule } from './availabilityContext/availabilityContext'
import { useAppState } from 'src/utils/AppStateEvent'
import styles from 'src/scenes/Main/Profile/ProfileDetails/styles'
import globalStyles from 'src/global/globalStyles'

const ScheduleScreen = () => {
  const { availabilityFetchingError, state, resetState } = useAvailabilitySchedule()

  useAppState({
    onForeground: resetState,
  })

  if (availabilityFetchingError) {
    return <FetchingDataErrorModal />
  }

  if (isEmpty(state.availabilityList)) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView contentContainerStyle={globalStyles.containerSize} testID="scrollView">
      <CalendarPicker />
      <DayAvailability />
    </ScrollView>
  )
}

const Schedule = () => (
  <AvailabilityProvider>
    <ScheduleScreen />
  </AvailabilityProvider>
)

export default Schedule
