import { StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const container = {
  flexDirection: 'row',
  marginBottom: paddingNew.xs + 2,
} as ViewStyle

export default StyleSheet.create({
  container,
  lastContainer: {
    ...container,
    marginBottom: 0,
  },
  iconContainer: {
    alignItems: 'center',
    minHeight: 40,
    justifyContent: 'center',
    marginRight: paddingNew.xs,
    width: 40,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  subTitle: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
  icon: {
    color: colorsNew.redesign.text.primary,
  },
})
