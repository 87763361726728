import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const isWeb = Platform.OS === 'web'
const HEIGHT = Dimensions.get('window').height

const MODAL_HEIGHT = HEIGHT * 0.95
const MAIN_CONTAINER_HEIGHT = HEIGHT * 0.85

const styles = StyleSheet.create({
  beforeTaxText: {
    color: colorsNew.black,
    fontWeight: '700',
  },
  button: {
    width: isWeb ? '50%' : '100%',
  },
  buttonRow: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'space-between',
  },
  buttonSpace: {
    marginTop: 10,
  },
  container: {
    display: 'flex',
    height: MAIN_CONTAINER_HEIGHT,
    marginBottom: paddingNew.lg,
    marginHorizontal: 24,
    marginTop: paddingNew.sm,
    maxHeight: '100%',
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.other.divider,
    borderRadius: 2,
    height: 4,
    marginBottom: paddingNew.md,
    width: 48,
  },
  dotTextContainer: {
    marginBottom: 0,
    marginTop: 0,
  },
  innerContainer: {
    gap: 20,
    marginRight: 10,
  },
  infoText: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
  mainContent: {
    flex: 1,
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: !isWeb
    ? {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        maxHeight: MODAL_HEIGHT,
      }
    : { maxHeight: MODAL_HEIGHT },

  title: {
    ...typography.h7,
    color: colorsNew.black,
    marginBottom: paddingNew.xs,
  },
  text: {
    ...typography.body2,
  },
})

export default styles
