import { StyleSheet, Dimensions } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const THUMB_SHADOW_OFFSET_WIDTH = 0
const THUMB_SHADOW_OFFSET_HEIGHT = 2
const THUMB_SHADOW_OPACITY = 0.23
const THUMB_SHADOW_RADIUS = 2.62
const THUMB_ELEVATION = 4

export default StyleSheet.create({
  sliderContainer: {
    flex: 1,
    marginLeft: paddingNew.sm,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  sliderBack: {
    height: 4,
    backgroundColor: colorsNew.redesign.action.active,
    borderRadius: 20,
    width: '100%',
  },
  sliderFront: {
    height: 4,
    backgroundColor: colorsNew.redesign.primary.dark,
    borderRadius: 20,
    position: 'absolute',
  },
  thumb: {
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: colorsNew.redesign.secondary.contrast,
    shadowColor: colorsNew.black,
    shadowOffset: {
      width: THUMB_SHADOW_OFFSET_WIDTH,
      height: THUMB_SHADOW_OFFSET_HEIGHT,
    },
    shadowOpacity: THUMB_SHADOW_OPACITY,
    shadowRadius: THUMB_SHADOW_RADIUS,

    elevation: THUMB_ELEVATION,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 32,
    justifyContent: 'center',
    marginTop: paddingNew.base,
    paddingBottom: paddingNew.sm,
  },
  label: {
    ...typography.helperText,
    width: 38,
    alignSelf: 'center',
    marginLeft: paddingNew.xs,
    marginRight: paddingNew.sm,
  },
})
