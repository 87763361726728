import { StyleSheet } from 'react-native'
import { Theme } from 'react-native-calendars/src/types'

import { colorsNew, fonts, paddingNew, utils } from 'src/styles'

export const themeStyle = {
  arrowColor: colorsNew.redesign.action.active,
  indicatorColor: colorsNew.redesign.primary.shades30p,
  monthTextColor: colorsNew.redesign.text.primary,
  textDayHeaderFontFamily: fonts.muktaRegular,
  textDayHeaderFontSize: 12,
  textMonthFontFamily: fonts.poppinsSemiBold,
  textMonthFontSize: 20,
  textMonthFontWeight: '600',
  textSectionTitleColor: colorsNew.redesign.text.primary,
  weekVerticalMargin: 0,
} as Theme

export default StyleSheet.create({
  container: {
    borderRadius: 8,
    color: colorsNew.redesign.text.primary,
    margin: paddingNew.sm,
    paddingVertical: paddingNew.sm,
    ...utils.shadow('selected', colorsNew.grey._600),
  },
  dayText: {
    color: colorsNew.redesign.text.primary,
    textAlign: 'center',
  },
  dayTextSelected: {
    color: colorsNew.redesign.secondary.contrast,
  },
  dayTextDisabled: {
    color: colorsNew.grey._500,
  },
  dayCircle: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.success.shades12p,
    borderRadius: paddingNew.sm,
    height: paddingNew.md,
    justifyContent: 'center',
    width: paddingNew.md,
  },
  dayCircleDisabled: {
    backgroundColor: colorsNew.white,
  },
  dayCircleInactive: {
    backgroundColor: colorsNew.redesign.error.shades12p,
  },
  dayCircleSelected: {
    backgroundColor: colorsNew.redesign.secondary.dark,
  },
  dayCircleToday: {
    borderWidth: 1,
    borderColor: colorsNew.redesign.secondary.dark,
  },

  dayContainer: { alignItems: 'center' },
  dot: {
    backgroundColor: colorsNew.transparent,
    borderRadius: 3,
    height: 6,
    marginBottom: 12,
    marginTop: 2,
    width: 6,
  },
  activeDot: {
    backgroundColor: colorsNew.redesign.text.secondary,
  },
  diagonalLine: {
    position: 'absolute',
    top: 4,
  },
})
