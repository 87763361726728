import React from 'react'
import Svg, { Path, Rect, Circle } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#000'

const SalaryIcon = ({ fill, size, ...props }: IconTypes) => {
  const fillColor = fill?.length ? fill : defaultFill

  return (
    <Svg fill="none" height={size} viewBox="0 0 65 65" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect
        x={10.948}
        y={17.976}
        width={38.091}
        height={22.874}
        rx={1.992}
        fill="#fff"
        stroke={fillColor}
        strokeWidth={3.984}
      />
      <Circle cx={29.994} cy={29.413} r={6.011} fill={fillColor} />
      <Path
        d="M54.949 23.992v20.882c0 1.1-.892 1.992-1.993 1.992H20.633"
        stroke={fillColor}
        strokeWidth={3.984}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SalaryIcon
