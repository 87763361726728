import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { ChevronRightIcon } from 'src/icons'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DotTextRow } from 'src/components/DotTextRow'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan, regexes } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew from 'src/components/Buttons/ButtonNew/ButtonNew'
import FileUploader from 'src/components/FileUploader/FileUploader'
import TextInputNew from 'src/components/Form/TextInputNew/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'NiNumber'>

const acceptedDocumentsList = Array.from({ length: 4 }, (_, i) => i + 1)

const DOCUMENT_TYPE = 'id_ni_number'
const URL_LINK = 'https://www.gov.uk/national-insurance/your-national-insurance-number'

const NiNumber = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - NiNumber')

  const userId = useAppSelector(state => state.user.id) as string
  const nationalInsuranceNumber = useAppSelector(state => state.user.attributes?.nationalInsuranceNumber)
  const userNiDocument = useAppSelector(getUserDocumentUK(DOCUMENT_TYPE))

  const dispatch = useAppDispatch()

  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitError, setIsSubmitError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [niError, setNiError] = useState(false)
  const [niNumber, setNiNumber] = useState(nationalInsuranceNumber || '')
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked || (!!niNumber && niNumber !== nationalInsuranceNumber)
    }
  }, [isFilePicked, niNumber, nationalInsuranceNumber])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsSubmitError(true)
  }
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const isButtonEnabled = useMemo(() => {
    const docUploaded = !!userNiDocument.length || isFilePicked
    return !isCompleted && niNumber && docUploaded
  }, [isCompleted, niNumber, isFilePicked, userNiDocument])

  const validateNiNumber = (value: string) =>
    value?.replace(/\s/g, '').match(regexes.niNumber) ? setNiError(false) : setNiError(true)

  const updateTaskStatus = () => {
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)
  }

  const completeTask = () => {
    setIsSubmitError(false)
    const niNormalized = niNumber.replace(/\s/g, '')
    if (!niNormalized.match(regexes.niNumber)) {
      return setNiError(true)
    }

    setIsSubmitting(true)
    dispatch(
      saveUser({
        id: userId,
        onError: handleError,
        onSuccess: isFilePicked ? () => setShouldSaveDocument(true) : updateTaskStatus,
        user: { nationalInsuranceNumber: niNormalized },
      }),
    )

    bugsnagActionBreadcrumb('save ni number', { id: userId })
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainInfo}>{translate(translations.niNumberTaskDescription)}</Text>
      <Text style={styles.subInfo}>{translate(translations.niNumberSubInfo)}</Text>
      <Text style={styles.title}>{translate(translations.niNumberTitle1)}</Text>
      <TextInputNew
        disabled={isCompleted}
        errorMessage={translate(translations.niNumberInputInfo)}
        helperText={translate(translations.niNumberInputInfo)}
        label={translate(translations.niNumberInputLabel)}
        isError={niError}
        onChangeText={(value: string) => {
          setNiNumber(value)
          niError && validateNiNumber(value)
        }}
        required
        size={TextInputSizeVariants.medium}
        value={niNumber}
      />

      <Text style={styles.title}>{translate(translations.niNumberTitle2)}</Text>
      <FileUploader
        arrayDocuments={userNiDocument}
        buttonDisabled={isCompleted}
        deletingFilesDisabled={isCompleted}
        documentType={DOCUMENT_TYPE}
        onFileSaveCallback={!isCompleted ? updateTaskStatus : handleUploadFinish}
        onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
        onFilesErrorCallback={handleError}
        saveOnUpload={shouldSaveDocument}
      />
      <View style={styles.sectionContainer}>
        {isSubmitError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsSubmitError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonContainer}>
          <ButtonNew
            disabled={!isButtonEnabled}
            isSubmitting={isSubmitting}
            onPress={completeTask}
            size={buttonSize.lg}
            title={translate(translations.closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>

      <Text style={styles.subInfo}>{translate(translations.niNumberTaskInfo)}</Text>

      <View style={styles.infoContainer}>
        <Text style={styles.subHeader}>{translate(translations.niNumberSubHeader)}</Text>
        {acceptedDocumentsList.map(doc => (
          <DotTextRow
            containerStyle={styles.textWithDotContainer}
            key={doc}
            text={translate(translations[`niNumberAcceptedDoc${doc}`])}
            textStyle={styles.textWithDot}
          />
        ))}
        <View style={styles.buttonLinkContainer}>
          <ButtonNew
            onPress={() => openURLIfCan(URL_LINK)}
            RightIcon={ChevronRightIcon}
            size={buttonSize.sm}
            title={translate(translations.niNumberMoreButton)}
            variant={buttonVariants.outlinedDefault}
          />
        </View>
      </View>
    </ScrollView>
  )
}

export default NiNumber
