import React, { ElementType } from 'react'
import { Pressable, Text, View } from 'react-native'

import { FilterChipColor, FilterChipSize, FilterChipState } from './types'
import { filterChipColor, filterChipSize, filterChipState } from './variants'
import { IconTypes } from 'src/icons/iconTypes'
import styles, { ICON_LARGE, ICON_SMALL } from './styles'

export interface Props {
  color: FilterChipColor
  disabled?: boolean
  onPress?: () => void
  RightIcon?: ElementType<IconTypes>
  size: FilterChipSize
  state: FilterChipState
  text: string
}

const FilterChip = ({ color, disabled = false, onPress, RightIcon, size, state, text }: Props) => {
  const isEnabled = state === filterChipState.enabled
  const isSecondary = color === filterChipColor.secondary
  const isSmall = size === filterChipSize.small

  return (
    <Pressable
      style={styles(disabled, isEnabled, isSecondary, isSmall).container}
      onPress={onPress}
      disabled={disabled}
    >
      <Text style={styles(disabled, isEnabled, isSecondary, isSmall).text}>{text}</Text>
      {RightIcon ? (
        <View>
          <RightIcon
            fill={styles(disabled, isEnabled, isSecondary, isSmall).text.color}
            size={isSmall ? ICON_SMALL : ICON_LARGE}
          />
        </View>
      ) : null}
    </Pressable>
  )
}

export default FilterChip
