import gql from 'graphql-tag'

import BookingFields from './Fields'

export default gql`
  query Show($id: ID!) {
    booking(id: $id) {
      ...BookingFields
    }
  }

  ${BookingFields}
`
