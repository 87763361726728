import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, View, Text } from 'react-native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { momentWithTimezone } from 'src/global/utils'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch } from 'src/hooks/reduxHooks'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import formatter from 'src/utils/formatter'
import RadioButton from 'src/components/Form/InputRadioGroup/RadioButton/RadioButton'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'DisqualificationDeclaration'>

const LINK =
  'https://www.gov.uk/government/publications/disqualification-under-the-childcare-act-2006/disqualification-under-the-childcare-act-2006'

const DisqualificationDeclaration = ({ navigation, route }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed
  const isTaskExpired = [profileTaskStatus.expired, profileTaskStatus.expiresSoon].includes(status)

  useBugsnagView('RequiredTask - DisqualificationDeclaration')

  const dispatch = useAppDispatch()
  const userId = useAppSelector(state => state.user.id) as string
  const disqualificationDeclaration = useAppSelector(state => state.user.attributes?.disqualificationDeclaration)
  const disqualificationDeclarationNote = useAppSelector(
    state => state.user.attributes?.disqualificationDeclarationNote || '',
  )

  const [disqualificationNote, setDisqualificationNote] = useState(disqualificationDeclarationNote)
  const [isDisqualified, setIsDisqualified] = useState<boolean | null>(disqualificationDeclaration)
  const [isError, setIsError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isTaskInCompletedState, setIsTaskInCompletedState] = useState(isTaskExpired || isTaskCompleted)

  const { mixpanel } = useContext(MixpanelContext)

  const formRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    const isDirty =
      isDisqualified !== disqualificationDeclaration || disqualificationNote !== disqualificationDeclarationNote
    if (formRef.current) formRef.current.dirty = isDirty
  }, [disqualificationDeclaration, disqualificationDeclarationNote, disqualificationNote, isDisqualified])

  const closeScreen = useBackModal(navigation, formRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }

  const redoTheTask = () => {
    setIsTaskInCompletedState(false)
    setDisqualificationNote('')
    setIsDisqualified(null)
  }

  const updateTaskStatus = () => {
    markTaskAsCompleted(
      userId,
      task,
      false,
      mixpanel,
      () => {
        setIsSubmitting(false)
        closeScreen()
      },
      handleError,
    )
  }

  const completeTask = () => {
    bugsnagActionBreadcrumb('Complete disqualification declaration task', { id: userId })
    setIsSubmitting(true)
    setIsError(false)

    const newValues = {
      disqualificationDeclaration: isDisqualified,
      disqualificationDeclarationDeclaredOn: formatter.apiFormat(momentWithTimezone()),
      disqualificationDeclarationNote: disqualificationNote,
    }

    dispatch(
      saveUser({
        id: userId,
        onError: handleError,
        onSuccess: updateTaskStatus,
        user: newValues,
      }),
    )
  }

  const handleLinkButton = () => openURLIfCan(LINK)

  return (
    <ScrollView
      contentContainerStyle={globalStyles.containerSize}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <View style={styles.container}>
        <StatusInfo timeToComplete={timeToComplete} status={status} />
        {isTaskExpired ? (
          <View style={styles.redoTaskButtonWrapper}>
            <ButtonNew
              disabled={!isTaskInCompletedState}
              isShort
              onPress={redoTheTask}
              title={translate(translations.redoTask)}
              variant={buttonVariants.containedDefault}
            />
          </View>
        ) : null}
        <Text style={styles.header}>{translate(translations.disqualificationDeclarationAbout)}</Text>
        <Text style={styles.info}>{translate(translations.disqualificationDeclarationGuidance1)}</Text>
        <Text style={styles.info}>{translate(translations.disqualificationDeclarationGuidance2)}</Text>
        <AlertCard
          colorSchema={alertColorScheme.info}
          description={translate(translations.disqualificationDeclarationGuidanceAlert)}
          variant={alertVariants.outlined}
        />
        <Text style={styles.subInfo}>{translate(translations.disqualificationDeclarationOpenGuidanceInfo)}</Text>
        <View style={styles.linkButton}>
          <ButtonNew
            containerStyle={styles.button}
            isShort
            onPress={handleLinkButton}
            RightIcon={ChevronRightIcon}
            title={translate(translations.disqualificationDeclarationOpenGuidanceButton)}
            variant={buttonVariants.outlinedDefault}
          />
        </View>
        <Text style={styles.helperInfo}>
          {translate(translations.disqualificationDeclarationOpenGuidanceHelperInfo)}
        </Text>
        <View style={styles.backgroundContainer}>
          <View style={styles.backgroundContainerFill} />
          <Text style={styles.subInfo}>{translate(translations.disqualificationDeclarationGuidance3)}</Text>
          <Text style={styles.subInfo}>{translate(translations.disqualificationDeclarationGuidance4)}</Text>
        </View>
        <Text style={styles.header}>{translate(translations.discloser)}</Text>
        <Text style={styles.formInfo}>{translate(translations.disqualificationDeclarationQuestion)}</Text>
        <View style={styles.radioButtons}>
          <RadioButton
            disabled={isTaskInCompletedState}
            isLarge
            label={translate(translations.yes)}
            onPress={() => setIsDisqualified(true)}
            selected={isDisqualified === true}
          />
          <RadioButton
            disabled={isTaskInCompletedState}
            isLarge
            label={translate(translations.no)}
            onPress={() => setIsDisqualified(false)}
            selected={isDisqualified === false}
          />
        </View>
        {isDisqualified ? (
          <>
            <Text style={styles.formInfo}>{translate(translations.disqualificationDeclarationNoteInfo)}</Text>
            <View style={styles.inputWrapper}>
              <TextInputNew
                disabled={isTaskInCompletedState}
                label={translate(translations.disqualificationDeclarationPlaceholder)}
                multiline
                onChangeText={text => setDisqualificationNote(text)}
                size={TextInputSizeVariants.big}
                value={disqualificationNote}
              />
            </View>
          </>
        ) : null}
        <Text style={styles.subHeader}>{translate(translations.disqualificationDeclarationNotifyHumly)}</Text>
        <AlertCard
          colorSchema={alertColorScheme.info}
          description={translate(translations.disqualificationDeclarationConvictions)}
          title={translate(translations.note)}
          variant={alertVariants.outlined}
        />
        <View style={styles.sectionContainer}>
          {isError ? (
            <View style={styles.alertWrapper}>
              <CompleteTaskError hideError={() => setIsError(false)} />
            </View>
          ) : null}
          <View style={styles.buttonWrapper}>
            <ButtonNew
              containerStyle={styles.button}
              disabled={isTaskInCompletedState || (isDisqualified !== false && !disqualificationNote)}
              isShort
              isSubmitting={isSubmitting}
              onPress={completeTask}
              title={translate(translations.closeAndSubmitTask)}
              variant={buttonVariants.containedDefault}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  )
}

export default DisqualificationDeclaration
