import React from 'react'
import { Text, View } from 'react-native'

import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  onEditPress: () => void
  onReportPress: () => void
}

const ReportActions = ({ onEditPress, onReportPress }: Props) => (
  <View style={styles.container}>
    <Text style={styles.title}> {translate(translations.completeYourTimesheet)}</Text>
    <Text style={styles.description}>{translate(translations.completeTimesheetDescription)}</Text>

    <ButtonNew
      onPress={onReportPress}
      title={translate(translations.submitTimesheet)}
      variant={buttonVariants.containedDefault}
      style={styles.button}
    />

    <ButtonNew
      onPress={onEditPress}
      title={translate(translations.makeChanges)}
      variant={buttonVariants.outlinedDefault}
      style={styles.button}
    />
  </View>
)

export default ReportActions
