import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colors, colorsNew, paddingNew, typography } from 'src/styles'

const { width } = Dimensions.get('window')
const isWeb = Platform.OS === 'web'

export const MODAL_ROW_HEIGHT = 36
const MODAL_CONTAINER_SHADOW_OFFSET_WIDTH = 0
const MODAL_CONTAINER_SHADOW_OFFSET_HEIGHT = 4
const MODAL_CONTAINER_SHADOW_OPACITY = 0.3
const MODAL_CONTAINER_SHADOW_RADIUS = 4.65
const MODAL_CONTAINER_ELEVATION = 8

const styles = StyleSheet.create({
  autoCompleteContainer: {
    paddingHorizontal: paddingNew.sm,
  },
  disabledInput: {
    color: colorsNew.redesign.text.disabled,
  },
  googleLogo: {
    alignSelf: 'flex-end',
    height: 18,
    marginRight: paddingNew.base,
    width: 59,
  },
  input: {
    flex: 1,
  },
  modalContainer: {
    height: '100%',
  },
  rightIcon: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: paddingNew.sm,
  },
  modalListFooter: {
    height: 100,
    alignItems: 'flex-end',
    paddingRight: paddingNew.base,
  },
  modalListHeader: {
    borderBottomColor: colors.grey._500,
    borderBottomWidth: 1,
    marginBottom: 10,
    paddingBottom: 8,
  },
  modalRow: {
    ...typography.body1,
    alignItems: 'center',
    flexDirection: 'row',
    height: MODAL_ROW_HEIGHT,
    paddingLeft: paddingNew.base,
    width: '100%',
  },
  modalRowSelected: {
    backgroundColor: colorsNew.redesign.primary.shades8p,
  },
  modalRowLabel: {
    color: colorsNew.redesign.text.primary,
    maxWidth: width,
  },
  textInputContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  textInput: {
    color: colorsNew.redesign.text.disabled,
  },
  modalListWrapper: {
    ...(!isWeb ? {} : { maxWidth: 452, maxHeight: 5 * MODAL_ROW_HEIGHT, overflow: 'scroll' }),
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.background.paper,
    borderRadius: paddingNew.xs,
    elevation: MODAL_CONTAINER_ELEVATION,
    position: 'absolute',
    shadowColor: colorsNew.black,
    shadowOffset: {
      width: MODAL_CONTAINER_SHADOW_OFFSET_WIDTH,
      height: MODAL_CONTAINER_SHADOW_OFFSET_HEIGHT,
    },
    shadowOpacity: MODAL_CONTAINER_SHADOW_OPACITY,
    shadowRadius: MODAL_CONTAINER_SHADOW_RADIUS,
    width: '100%',
    zIndex: 101,
  },
})

export default styles
