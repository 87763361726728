import RNCalendarEvents from 'src/utils/webAdapters/reactNativeCalendarEvents'
// @ts-ignore
import moment from 'moment-timezone'

import { BookingTypes } from 'src/graphql/Booking'
import translations, { translate } from 'src/utils/translations/translations'
import { bugsnagActionBreadcrumb } from '../bugsnag'
import isArray from 'lodash/isArray'

export type CalendarBooking = Pick<
  BookingTypes,
  'startTime' | 'endTime' | 'school' | 'displayInfo' | 'rejected' | 'cancelled'
>

const getEventData = (calID: string, { startTime, endTime, school, displayInfo }: CalendarBooking) => {
  const location = `${school.homeAddress.city}, ${school.homeAddress.street}, ${school.homeAddress.zip}`
  const eventDetails = {
    calendarId: calID,
    description: displayInfo || '',
    endDate: moment(endTime).toISOString(),
    location: location || school?.address || '',
    notes: displayInfo || '',
    startDate: moment(startTime).toISOString(),
  }
  const eventTitle = translate(translations.calendarEventTitle, {
    schoolName: school?.name,
  })
  return { eventTitle, eventDetails }
}

export const addCalendarItems = async (bookings: CalendarBooking[], calID: string): Promise<void> => {
  bugsnagActionBreadcrumb('addCalendarItems')

  const itemsToAdd = bookings
    .filter(booking => !(booking.rejected || booking.cancelled))
    .map(booking => getEventData(calID, booking))

  const fnArray = itemsToAdd.map(({ eventDetails, eventTitle }) => RNCalendarEvents.saveEvent(eventTitle, eventDetails))
  await Promise.all(fnArray)

  return Promise.resolve()
}

export const addCalendarItem = async (booking: CalendarBooking, calID: string): Promise<void> => {
  bugsnagActionBreadcrumb('addCalendarItem')

  const eventInfo = getEventData(calID, booking)
  await RNCalendarEvents.saveEvent(eventInfo.eventTitle, eventInfo.eventDetails)

  return Promise.resolve()
}

export const getCalendarId = async () => {
  const calendars = await RNCalendarEvents.findCalendars()
  const humlyCalendar = calendars.find(calendar => calendar.title === 'Humly')
  return humlyCalendar?.id
}

export const synchronizeCalendar = async (booking: CalendarBooking | CalendarBooking[]) => {
  const permissionStatus = await RNCalendarEvents.checkPermissions()
  if (permissionStatus !== 'authorized') {
    return
  }

  const calendarID = await getCalendarId()
  if (!calendarID) {
    return
  }

  isArray(booking) ? addCalendarItems(booking, calendarID) : addCalendarItem(booking, calendarID)
}
