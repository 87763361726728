import { StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const touchContainer = {
  alignItems: 'center',
  flexDirection: 'row',
  paddingHorizontal: paddingNew.sm,
  paddingVertical: paddingNew.xs,
}

export default StyleSheet.create({
  container: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
  },
  touchPressed: {
    ...touchContainer,
    backgroundColor: colorsNew.redesign.action.pressed,
  } as ViewStyle,
  touchUnpressed: {
    ...touchContainer,
  } as ViewStyle,
  textIconWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  textContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: paddingNew.xxs + 1,
  },
  text: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  iconColor: {
    color: colorsNew.redesign.secondary.main,
  },
  iconInVisible: {
    transform: [{ rotate: `90deg` }],
  },
  iconVisible: {
    transform: [{ rotate: `-90deg` }],
  },
  description: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginLeft: paddingNew.sm,
    marginRight: paddingNew.lg - 3,
    marginVertical: paddingNew.xs,
  },
})
