import { View, Text } from 'react-native'
import HyperLink from 'react-native-hyperlink'
import React from 'react'

import { useAppSelector } from 'src/hooks/reduxHooks'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  expireDate?: string | null
  showForm: () => void
}

const MedicalQuestionnaireInfo = ({ showForm, expireDate }: Props) => {
  const publicMedicalNotes = useAppSelector(state => state.user?.attributes?.publicMedicalNotes)

  return (
    <>
      {publicMedicalNotes ? (
        <View style={styles.medicalNote}>
          <Text style={styles.title}>{translate(translations.medicalQuestionnaireMedicalNotes)}</Text>
          <Text style={styles.mainText}>{publicMedicalNotes}</Text>
          <Text style={styles.subInfo}>{translate(translations.medicalQuestionnaireMedicalNotesInfo)}</Text>
        </View>
      ) : null}

      <Text style={styles.title}>{translate(translations.medicalQuestionnaireAboutTitle)}</Text>
      <Text style={styles.mainText}>{translate(translations.medicalQuestionnaireAbout)}</Text>
      <View style={styles.alertContainer}>
        <AlertCard
          colorSchema={alertColorScheme.info}
          description={translate(translations.medicalQuestionnaireNoteAlertInfo)}
          title={translate(translations.medicalQuestionnaireNoteAlertTitle)}
          variant={alertVariants.outlined}
        />
      </View>
      <View style={styles.backgroundContainer}>
        <View style={styles.backgroundContainerFill} />
        <Text style={styles.secondaryText}>{translate(translations.medicalQuestionnaireInfo)}</Text>
        <Text style={styles.subInfo}>{translate(translations.medicalQuestionnaireInfo2)}</Text>
      </View>

      {expireDate ? (
        <>
          <Text style={styles.subTitle}>{translate(translations.medicalQuestionnaireStatus)}</Text>
          <Text style={styles.mainText}>{formatter.longDate(expireDate)}</Text>
        </>
      ) : (
        <Text style={styles.subTitle}>{translate(translations.medicalQuestionnaireStatusNew)}</Text>
      )}

      <View style={styles.buttonContainer}>
        <ButtonNew
          isShort
          onPress={showForm}
          title={translate(translations.medicalQuestionnaireStartButton)}
          variant={buttonVariants.containedDefault}
        />
      </View>

      <Text style={styles.secondaryText}>{translate(translations.medicalQuestionnaireInfo3)}</Text>
      <HyperLink linkDefault linkStyle={styles.hyperlink}>
        <Text style={styles.subInfo}>{translate(translations.medicalQuestionnaireInfo4)}</Text>
      </HyperLink>
    </>
  )
}

export default MedicalQuestionnaireInfo
