import React from 'react'
import { View, Text, StyleSheet, ViewStyle, TextStyle } from 'react-native'

import styles from './styles'

type Props = {
  bulletStyle?: TextStyle
  containerStyle?: ViewStyle
  index?: number
  numeric?: boolean
  text: string
  textStyle?: TextStyle
}

const DotTextRow = ({ bulletStyle, containerStyle, index, numeric, text, textStyle }: Props) => (
  <View style={StyleSheet.flatten([styles.row, containerStyle])}>
    {numeric ? (
      <Text style={StyleSheet.flatten([styles.paragraph, styles.index, textStyle])}>{index}. </Text>
    ) : (
      <Text style={StyleSheet.flatten([styles.bullet, bulletStyle])}>{'\u2022 '}</Text>
    )}
    <View style={styles.bulletText}>
      <Text style={StyleSheet.flatten([styles.paragraph, textStyle])}>{text}</Text>
    </View>
  </View>
)

export default DotTextRow
