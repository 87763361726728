import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const NotificationIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 59 59" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.775 7.72a3.36 3.36 0 0 0-3.359 3.359v1.65c-5.17 1.463-8.958 6.216-8.958 11.854v14.834h-2.24a2.4 2.4 0 1 0 0 4.799h29.114a2.4 2.4 0 0 0 0-4.799h-2.24V24.583c0-5.638-3.788-10.391-8.957-11.853v-1.651a3.36 3.36 0 0 0-3.36-3.36Z"
    />
    <Path d="M26.417 46.363h6.718v1.187a3.36 3.36 0 0 1-6.718 0v-1.187Z" />
  </Svg>
)

export default NotificationIcon
