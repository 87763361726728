import React, { useCallback, useMemo, useState } from 'react'
import { Platform, RefreshControl, SectionList, Text, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import sortBy from 'lodash/sortBy'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { colors, colorsNew } from 'src/styles'
import { JobsNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AllProposals, { ProposalsRequestTypes, ProposalListItemType } from 'src/graphql/Proposal'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import LoadingIndicator from 'src/components/LoadingIndicator'
import LongTermOfferItem from 'src/scenes/Main/Jobs/Rows/LongTermOfferItem'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { CalendarMonthIcon, EventAvailableIcon, PersonSearchIcon } from 'src/icons'
import InfoTileHeader from 'src/components/InfoTileHeader'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'
import globalStyles from 'src/global/globalStyles'

const isWeb = Platform.OS === 'web'

const LongTermOfferList = ({ navigation }: JobsNavigatorScreensProps<'LongTermOfferList'>) => {
  useBugsnagView('LongTermOfferList')

  const regionId = useAppSelector(state => state.user.attributes?.regionId)
  const isConnected = useAppSelector(state => state.app.isConnected)

  const { data, loading, error, refetch } = useQuery<ProposalsRequestTypes>(AllProposals, {
    fetchPolicy: 'cache-and-network',
    variables: {
      matching: true,
      appliedByMe: false,
      region: regionId,
    },
  })

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const [refreshing, setRefreshing] = useState(false)

  const renderListHeader = useCallback(() => {
    const tileProps = {
      tiles: [
        {
          title: translate(translations.longTermFactText1),
          subHeader: translate(translations.longTermFactSubtext1),
          Icon: CalendarMonthIcon,
        },
        {
          title: translate(translations.longTermFactText2),
          subHeader: translate(translations.longTermFactSubtext2),
          Icon: PersonSearchIcon,
        },
        {
          title: translate(translations.longTermFactText3),
          subHeader: translate(translations.longTermFactSubtext3),
          Icon: EventAvailableIcon,
        },
      ],
      title: translate(translations.longTermFactTitle),
    }

    return (
      <View>
        <InfoTileHeader {...tileProps} />
        <Text style={styles.header}>{translate(translations.longTermTitle)}</Text>
      </View>
    )
  }, [])

  const offerList = useMemo(() => {
    const list = data?.proposals?.nodes || []
    const groups = []
    const sorted = sortBy(list, item => item.startDate)

    if (sorted.length) {
      const date = 'startDate' in sorted[0] && sorted[0]?.startDate

      if (date) {
        groups.push({
          data: [...sorted],
          isFirstSection: false,
          showTitle: false,
          title: date,
          withDivider: false,
        })
      }
    }
    return groups
  }, [data?.proposals?.nodes])

  if (error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  if (loading && !data) {
    return <LoadingIndicator />
  }

  const refreshFunction = async () => {
    bugsnagActionBreadcrumb('refresh long-term list', { isConnected })

    if (!isConnected) {
      return Promise.resolve()
    }
    return Promise.all([refetch()])
  }

  const onRefresh = async () => {
    setRefreshing(true)
    await refreshFunction()
    setRefreshing(false)
  }

  const renderOffer = (proposal: ProposalListItemType) => {
    const onPress = () =>
      navigation.navigate('Proposal', {
        id: proposal.id,
      })

    return (
      <LongTermOfferItem
        hours={proposal.hours || proposal.hoursPerWeek}
        location={proposal.location || proposal.region?.name}
        onPress={onPress}
        salary={proposal.payRate}
        title={proposal.jobTitle || proposal.title}
      />
    )
  }

  const noLongTermOffer = () => (
    <EmptyPlaceholder
      containerStyles={styles.emptyContainer}
      description={translate(translations.noAvailableProposalsDescription)}
      title={translate(translations.noAvailableProposals)}
      type={placeholderTypes.cactus}
    />
  )

  return (
    <>
      <SectionList
        contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
        keyExtractor={({ id }) => id}
        ListHeaderComponent={renderListHeader()}
        ListEmptyComponent={noLongTermOffer}
        refreshControl={
          <RefreshControl
            colors={[colors.blue._400, colorsNew.redesign.text.primary]}
            enabled={true}
            onRefresh={onRefresh}
            refreshing={refreshing}
            tintColor={colorsNew.redesign.text.primary}
            title={translate(translations.loading)}
            titleColor={colors.blue._400}
          />
        }
        renderItem={({ item }) => renderOffer(item)}
        sections={offerList}
        showsVerticalScrollIndicator={isWeb}
        stickySectionHeadersEnabled
        style={styles.page}
        testID="scrollView"
      />
    </>
  )
}

export default LongTermOfferList
