import { StyleSheet } from 'react-native'
import { colorsNew, fontSizesNew, fonts, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  frame: {
    alignItems: 'center',
    borderRadius: 16,
    borderWidth: 1,
    flexDirection: 'row',
    height: 30,
    justifyContent: 'center',
    marginHorizontal: paddingNew.xs,
    width: 80,
  },
  framePositive: {
    borderColor: colorsNew.redesign.success.main,
    backgroundColor: colorsNew.redesign.success.main,
  },
  frameNegative: {
    backgroundColor: colorsNew.redesign.error.main,
    borderColor: colorsNew.redesign.error.main,
  },
  frameDisabled: {
    borderColor: colorsNew.redesign.text.secondary,
  },
  text: {
    ...typography.buttonSmall,
    paddingRight: paddingNew.xxs,
  },
  textPositive: {
    color: colorsNew.white,
  },
  textNegative: {
    color: colorsNew.white,
  },
  textDisabled: {
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles
