import { AxiosError, AxiosResponse } from 'axios'
import { BUILD_MODE } from 'src/utils/webAdapters/DotEnvAdapter'

import Api, { responseErrorCallback } from 'src/utils/api'
import { bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { northpassCourses } from 'src/global/constants'
import { ErrorResponseT } from 'src/utils/types'

type TrainingKeys = keyof typeof northpassCourses

type Props = {
  onError: (error?: string) => void
  onSuccess: (url: string) => void
}

interface TrainingProps extends Props {
  trainingType: typeof northpassCourses[TrainingKeys]
}

const isStaging = BUILD_MODE === 'staging'

type ResolveRequestTypes = { resolve: (sth: string) => void; onSuccess: (sth: string) => void }

const resolveRequest = ({ resolve, onSuccess }: ResolveRequestTypes) =>
  setTimeout(() => {
    onSuccess('')
    resolve('done')
  }, 1000)

export const getNorthpassTrainingUrl = ({ onError, onSuccess, trainingType }: TrainingProps) => {
  // @TODO temporarily comment on staging
  if (isStaging) {
    return new Promise(resolve => resolveRequest({ resolve, onSuccess }))
  }

  return Api.get(`/integrations/northpass/course?ref=${trainingType}`, {
    onSuccess: (data: AxiosResponse) => onSuccess(data.data?.url),
    onError: (response: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData(`Getting user's Northpass training url failed`, { trainingType, response })
      responseErrorCallback('put', `/integrations/northpass/course?ref=${trainingType}`, response)
      onError && onError()
    },
  })
}

export const getNorthpassLoginUrl = ({ onError, onSuccess }: Props) => {
  // @TODO temporarily comment on staging
  if (isStaging) {
    return new Promise(resolve => resolveRequest({ resolve, onSuccess }))
  }

  return Api.post('/integrations/northpass/login', {
    onSuccess: (data: AxiosResponse) => onSuccess(data.data?.url),
    onError: (response: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData(`Getting user's Northpass login url failed`, { response })
      responseErrorCallback('post', '/integrations/northpass/login', response)
      onError && onError()
    },
  })
}
