import React, { FunctionComponent, SVGAttributes } from 'react'
import { Text, View, StyleProp, TextStyle } from 'react-native'

import styles from './styles'
import ResponsibleSVG, { Props as ResponsibleSVGT } from 'src/utils/ResponsibleSVG'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import translations, { translate } from 'src/utils/translations/translations'
import { navigateToAppScreen } from 'src/utils/navigateToScreen'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
type ResponsibleSVGTWithOptional = Optional<ResponsibleSVGT, 'Image' | 'originalImageHeight' | 'originalImageWidth'>

export interface Props extends ResponsibleSVGTWithOptional {
  description: string
  Image?: FunctionComponent<SVGAttributes<SVGElement>>
  onButtonClick?: () => any
  originalImageHeight?: number
  originalImageWidth?: number
  title: string
  withButton?: boolean
}

const openFindJob = () => navigateToAppScreen(['MainTabNavigator', 'JobsNavigator', 'Requests'])

const EmptyView = ({
  description,
  emptyImageStyles,
  Image,
  maxWidthPercentage = 0.45,
  onButtonClick,
  originalImageHeight,
  originalImageWidth,
  title,
  withButton,
}: Props) => (
  <View style={styles.container}>
    {Image ? (
      <View style={styles.imageWrapper}>
        <ResponsibleSVG
          emptyImageStyles={{ ...styles.emptyImageAlign, ...(emptyImageStyles ? emptyImageStyles : styles.emptyImage) }}
          Image={Image}
          maxWidthPercentage={maxWidthPercentage}
          originalImageHeight={originalImageHeight as ResponsibleSVGT['originalImageHeight']}
          originalImageWidth={originalImageWidth as ResponsibleSVGT['originalImageWidth']}
        />
      </View>
    ) : null}

    <Text style={styles.emptyTitle as StyleProp<TextStyle>}>{title}</Text>
    <Text style={withButton ? styles.emptyDescriptionWithButton : styles.emptyDescription}>{description}</Text>

    {withButton ? (
      <ButtonNew
        isShort
        onPress={onButtonClick ? onButtonClick : openFindJob}
        size={buttonSize.lg}
        style={styles.emptyButton}
        title={translate(translations.findMyNextJob)}
        variant={buttonVariants.outlinedDefault}
      />
    ) : null}
  </View>
)

export default EmptyView
