import { Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const isWeb = Platform.OS === 'web'

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: paddingNew.sm,
    backgroundColor: colorsNew.white,
    paddingBottom: isWeb ? paddingNew.lg : 0,
  },
  headerContainer: {
    alignItems: 'center',
    marginBottom: paddingNew.lg,
    marginTop: paddingNew.md,
  },
  headerText: {
    ...typography.h6,
  },
  topSection: {},
  rows: {
    paddingHorizontal: paddingNew.xxs,
    marginTop: paddingNew.sm,
  },
  footer: {
    marginVertical: paddingNew.xl,
    alignItems: 'center',
  },
  footerTitle: {
    ...typography.h7,
  },
  footerSubtitle: {
    ...typography.body1,
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginVertical: paddingNew.sm,
  },
  sectionTitle: {
    ...typography.subtitle2,
  },
})

export default styles
