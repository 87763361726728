import React, { useState } from 'react'
import { GestureResponderEvent, Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import styles from './styles'
import { isGBSelector } from 'src/store/app/selectors'

type Props = {
  min: number
  max: number
  step: number
  value: number
  onValueChanged: (value: number) => void
  onStart: () => void
  onEnd: () => void
}

const Slider = ({ min, max, step, value, onValueChanged, onStart, onEnd }: Props) => {
  const [sliderWidth, setSliderWidth] = useState(0)
  const [position, setPosition] = useState(0)
  const [isStartMoving, setIsStartMoving] = useState(false)
  const isGB = useSelector(isGBSelector)

  const gestureHandler = ({ nativeEvent }: GestureResponderEvent) => {
    if (!isStartMoving) {
      setIsStartMoving(true)
      onStart()
    }

    if (position + nativeEvent.locationX < 0) {
      setPosition(0)
      return
    }
    if (position + nativeEvent.locationX > sliderWidth) {
      setPosition(sliderWidth - 32)
      return
    }

    if (nativeEvent.locationX <= 32 && nativeEvent.locationX > 0) setPosition(position)
    else if (nativeEvent.locationX > 32) setPosition(position + nativeEvent.locationX - 32)
    else setPosition(position + nativeEvent.locationX)
  }

  return (
    <View style={styles.container}>
      <View
        onLayout={({ nativeEvent }) => {
          setPosition(((nativeEvent.layout.width - 32) / ((max - min) / step)) * (value - min))
          setSliderWidth(nativeEvent.layout.width)
        }}
        style={styles.sliderContainer}
      >
        <View style={styles.sliderBack} />
        <View style={[styles.sliderFront, { width: position }]} />
        <View
          onMoveShouldSetResponder={() => true}
          onResponderStart={onStart}
          onResponderMove={gestureHandler}
          onResponderRelease={() => {
            onValueChanged(min + Math.floor(position / ((sliderWidth - 32) / ((max - min) / step))) * step)
            setIsStartMoving(false)
            onEnd()
          }}
          style={[styles.thumb, { transform: [{ translateX: position }] }]}
        />
      </View>
      <Text style={styles.label}>
        {min + Math.floor(position / ((sliderWidth - 32) / ((max - min) / step))) * step} {isGB ? 'mi' : 'km'}
      </Text>
    </View>
  )
}

export default Slider
