import { applicationStatus, ApplicationStatus } from '../applicationTypes'
import { OrderTypes } from 'src/graphql/Order'
import formatter from 'src/utils/formatter'
import { mixpanelBookingDays } from 'src/scenes/Main/Jobs/Details/helpers'
import { OrderBooking } from 'src/graphql/Order/types'

type OfferStatusData = Pick<
  OrderTypes,
  | 'appointed'
  | 'appointmentType'
  | 'appointmentUnconfirmed'
  | 'cancelled'
  | 'rejected'
  | 'requestedSubs'
  | 'studentApplications'
  | 'student'
>
export const getOrderStatus = (order: OfferStatusData, userId?: string): ApplicationStatus => {
  const {
    appointed,
    appointmentType,
    appointmentUnconfirmed,
    cancelled,
    rejected,
    requestedSubs,
    studentApplications,
    student,
  } = order

  const studentId = student?.id
  const appointedByCurrentUser = appointed && studentId === userId
  const appointedByOtherUser = appointed && studentId !== userId
  const isDirect = appointmentType === 'direct'
  const isApplication = appointmentType === 'application'

  const isUserApplyforJob = studentApplications.some(application => application.studentId === userId)
  const isRequestedBySchool = requestedSubs.some(item => item.id === userId)
  if (rejected || cancelled) {
    return applicationStatus.offerCancelled
  }
  if (appointedByOtherUser) {
    return applicationStatus.offerAppointToOtherTeacher
  }
  if (appointedByCurrentUser && appointmentUnconfirmed && isDirect) {
    return applicationStatus.unconfirmedExpress
  }
  if (appointedByCurrentUser && appointmentUnconfirmed && isApplication) {
    return applicationStatus.userAssigned
  }
  if (appointedByCurrentUser) {
    return applicationStatus.userAppointed
  }
  if (isUserApplyforJob) {
    return applicationStatus.userApplied
  }
  if (isRequestedBySchool) {
    return applicationStatus.userRequested
  }

  return appointmentType === 'direct' ? applicationStatus.directOffer : applicationStatus.applicationOffer
}

export const getMixpanelOrderDay = (bookingDates?: OrderBooking[]) => {
  const dates = bookingDates?.map(booking => booking.startTime)

  if (!dates || !dates.length) {
    return ''
  }

  if (dates.some(date => formatter.apiFormat(date) === formatter.apiFormat())) {
    return mixpanelBookingDays.today
  } else if (dates.some(date => formatter.apiFormat(date) < formatter.apiFormat())) {
    return mixpanelBookingDays.past
  }
  return mixpanelBookingDays.future
}
