import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const LineDiagonal = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0303 4.46967C21.3232 4.76256 21.3232 5.23744 21.0303 5.53033L6.03033 20.5303C5.73744 20.8232 5.26256 20.8232 4.96967 20.5303C4.67678 20.2374 4.67678 19.7626 4.96967 19.4697L19.9697 4.46967C20.2626 4.17678 20.7374 4.17678 21.0303 4.46967Z"
    />
  </Svg>
)

export default LineDiagonal
