import { StyleSheet, Platform } from 'react-native'

import { colors, padding, paddingNew } from 'src/styles'
import { BAR_HEIGHT, HEADER_TITLE_HEIGHT } from 'src/styles/variables'
import globalStyles from 'src/global/globalStyles'

const isWeb = Platform.OS === 'web'

const styles = StyleSheet.create({
  chatContainer: {
    height: isWeb ? window.innerHeight - BAR_HEIGHT - HEADER_TITLE_HEIGHT : '100%',
    paddingBottom: padding.xl,
    width: '100%',
  },
  scrollToBottomStyle: {
    backgroundColor: colors.secondaryLight,
  },
  messagesContainerStyle: {
    flex: 1,
    height: 'auto',
    paddingBottom: padding.sm,
  },
  smallLoadingSpinner: {
    justifyContent: 'center',
    padding: paddingNew.xs,
  },
  scrollToBottomComponent: {
    ...(isWeb
      ? {
          alignItems: 'center',
          display: 'flex',
          height: 40,
          justifyContent: 'center',
          width: 40,
        }
      : {}),
  },
  containerExtraStyles: {
    ...(isWeb
      ? {
          width: '100%',
        }
      : {}),
  },
})

export default styles
