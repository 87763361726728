import { UserExperience } from 'src/store/user/selectors'

export const employmentHistoryTemplate: UserExperience = {
  duties: '',
  employer: '',
  endDate: '',
  expCategory: null,
  id: '',
  isOngoing: false,
  leavingReason: '',
  role: '',
  startDate: '',
  workload: '',
}

export const sectionsSV: UserExperience['expCategory'][] = [
  `working_with_children_outside_school`,
  'substitute_teacher',
  'other_professions',
]

export const sectionsGB: UserExperience['expCategory'][] = ['substitute_teacher']
