import React from 'react'
import { AppRegistry } from 'react-native'
import { Provider as PaperProvider } from 'react-native-paper'
import { Provider } from 'react-redux'

import store from 'src/store'
import substerIcons from 'src/assets/fonts/subster-icons.ttf'
import theme from 'src/utils/reactNativePaperTheme'
import MuktaRegular from 'src/assets/fonts/Mukta-Regular.ttf'
import MuktaMedium from 'src/assets/fonts/Mukta-Medium.ttf'
import MuktaSemiBold from 'src/assets/fonts/Mukta-SemiBold.ttf'
import PoppinsRegular from 'src/assets/fonts/Poppins-Regular.ttf'
import PoppinsMedium from 'src/assets/fonts/Poppins-Medium.ttf'
import PoppinsSemiBold from 'src/assets/fonts/Poppins-SemiBold.ttf'
import PoppinsBold from 'src/assets/fonts/Poppins-Bold.ttf'

import AppContainer from 'src'

const iconFontStyles = `
@font-face {
  src: url(${substerIcons});
  font-family: subster-icons;
}
@font-face {
  src: url(${MuktaRegular});
  font-family: Mukta-Regular;
}
@font-face {
  src: url(${MuktaMedium});
  font-family: Mukta-Medium;
}
@font-face {
  src: url(${MuktaSemiBold});
  font-family: Mukta-SemiBold;
}
@font-face {
  src: url(${PoppinsRegular});
  font-family: Poppins-Regular;
}
@font-face {
  src: url(${PoppinsMedium});
  font-family: Poppins-Medium;
}
@font-face {
  src: url(${PoppinsSemiBold});
  font-family: Poppins-SemiBold;
}
@font-face {
  src: url(${PoppinsBold});
  font-family: Poppins-Bold;
}

`

const style = document.createElement('style')
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

document.head.appendChild(style)

const App = () => {
  return (
    <Provider store={store}>
      <PaperProvider theme={theme}>
        <AppContainer />
      </PaperProvider>
    </Provider>
  )
}

AppRegistry.registerComponent('App', () => App)

AppRegistry.runApplication('App', {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
})
