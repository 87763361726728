import React from 'react'
import CustomModal from 'src/components/Modal/CustomModal'

import { useBugsnagView } from 'src/utils/bugsnag'
import translations, { translate } from 'src/utils/translations/translations'
import { navigateToAppScreen } from 'src/utils/navigateToScreen'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'

type TProps = {
  description: string
  title: string
}

const PreferencesModalScreen = ({ description, title }: TProps) => {
  useBugsnagView('PreferencesModalScreen', { title })

  const goToPreferencesHandler = () => navigateToAppScreen(['MainTabNavigator', 'MenuNavigation', 'Preferences'])

  return (
    <CustomModal
      buttons={[
        {
          onPress: goToPreferencesHandler,
          title: translate(translations.toPreferences),
          variant: 'outlined-error',
        },
      ]}
      colorSchema={customModalColorSchema.error}
      description={description}
      isModalScreen
      isVisible
      modalPlace="ModalTypes/PreferencesModalScreen/PreferencesModalScreen"
      title={title}
      variant={customModalVariants.standard}
    />
  )
}

export default PreferencesModalScreen
