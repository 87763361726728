import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

export default StyleSheet.create({
  radioButtonsContainer: {
    flexDirection: 'row',
    height: 38,
    justifyContent: 'space-between',
    marginTop: paddingNew.xs,
    marginHorizontal: paddingNew.base,
  },
  radioButtonsContainerSE: {
    justifyContent: 'flex-start',
  },
})
