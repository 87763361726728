import React, { useState } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { Formik, yupToFormErrors, FormikProps, FormikValues, FormikHelpers } from 'formik'

import Buttons from '../components/Buttons'
import LoadingIndicator from 'src/components/LoadingIndicator'
import OvertimeSection from '../components/OvertimeSection'
import styles from '../styles'
import { reportInitialFixedValuesTypes } from 'src/hooks/useTimesheetReport'
import { reportWorkingTime } from 'src/store/timelog/actions'
import { ShiftRow } from '../components/FormComponents'
import { validationSchema } from './validation'
import { workingTimeList } from 'src/global/constants'

type Props = {
  bookingId: string
  bookingScheduleType: string
  closeModal: (isDirty?: boolean) => void
  initialValues: reportInitialFixedValuesTypes
  isHidden?: boolean
  onReportSuccess: () => void
  onReportError: (error: string) => void
}

const ShiftReport = ({
  bookingId,
  bookingScheduleType,
  closeModal,
  isHidden,
  initialValues,
  onReportSuccess,
  onReportError,
}: Props) => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null)

  const toggleSection = (section: string) =>
    section === expandedSection ? setExpandedSection(null) : setExpandedSection(section)

  const sendWorkingTime = (
    values: reportInitialFixedValuesTypes,
    form: FormikHelpers<reportInitialFixedValuesTypes>,
  ) => {
    form.setSubmitting(true)
    const onError = (error: string) => {
      onReportError(error)
      form.setSubmitting(false)
    }
    reportWorkingTime(bookingId, values, onReportSuccess, onError)
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={async values => {
        try {
          await validationSchema.validate(values, {
            context: { bookingScheduleType },
            abortEarly: false,
          })
        } catch (err) {
          return yupToFormErrors(err)
        }
      }}
      onSubmit={sendWorkingTime}
    >
      {form => {
        const { values, setFieldValue, submitForm, dirty, isSubmitting } = form
        const isOvertime = bookingScheduleType !== values.reportedTimeScheduleType

        return (
          <>
            <ScrollView
              contentContainerStyle={StyleSheet.flatten([isHidden && styles.hidden])}
              showsVerticalScrollIndicator={false}
            >
              <View>
                {workingTimeList.map(shift => (
                  <ShiftRow
                    isChecked={values.reportedTimeScheduleType === shift.value}
                    key={shift.value}
                    label={shift.label}
                    onValueChange={() => setFieldValue('reportedTimeScheduleType', shift.value)}
                    testID={`switch-${shift.value}`}
                  />
                ))}
              </View>

              {isOvertime ? (
                <OvertimeSection
                  expandedSection={expandedSection}
                  form={form as FormikProps<FormikValues>}
                  initialValues={initialValues}
                  isShift
                  toggleSection={toggleSection}
                />
              ) : null}
              {isSubmitting && <LoadingIndicator withMask />}
            </ScrollView>
            {!isHidden ? (
              <Buttons closeModal={() => closeModal(dirty)} submitForm={submitForm} disabled={isSubmitting} />
            ) : null}
          </>
        )
      }}
    </Formik>
  )
}

export default ShiftReport
