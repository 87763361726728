import React from 'react'
import { View } from 'react-native'

import AlertCard, { AlertCardProps, alertColorScheme, alertVariants } from 'src/components/AlertCard'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import { TranslationSVT } from 'src/utils/translations/i18nTypes'

type Props = {
  isUserNotAvailable: boolean
  showConfirmationAlert: boolean
  showOfferAlert: boolean
  showUnbookedAlert: boolean
  userComplianceAlertData: null | AlertCardProps
}

const ExpressAlert = ({
  isUserNotAvailable,
  showConfirmationAlert,
  showOfferAlert,
  showUnbookedAlert,
  userComplianceAlertData,
}: Props) => {
  if (userComplianceAlertData)
    return (
      <View style={styles.container} testID="alert-message">
        <AlertCard {...userComplianceAlertData} />
      </View>
    )

  if (isUserNotAvailable) {
    return (
      <View style={styles.container} testID="alert-message">
        <AlertCard
          colorSchema={alertColorScheme.info}
          description={translate(translations.bookingAlertUnavailableDescription)}
          testId="express-not-available-alert"
          title={translate(translations.bookingAlertUnavailableTitle)}
          variant={alertVariants.standard}
        />
      </View>
    )
  }

  return (
    <>
      {showOfferAlert ? (
        <View style={styles.container} testID="alert-message">
          <AlertCard
            colorSchema={alertColorScheme.info}
            description={translate((translations as TranslationSVT).expressAlertDescription)}
            testId="express-alert"
            title={translate((translations as TranslationSVT).expressAlertTitle)}
            variant={alertVariants.standard}
          />
        </View>
      ) : null}

      {showUnbookedAlert ? (
        <View style={styles.container} testID="alert-message">
          <AlertCard
            colorSchema={alertColorScheme.info}
            description={translate(translations.expressAlertUnbookedDescription)}
            testId="express-unbooked-alert"
            title={translate(translations.expressAlertUnbookedTitle)}
            variant={alertVariants.standard}
          />
        </View>
      ) : null}

      {showConfirmationAlert ? (
        <View style={styles.container} testID="alert-message">
          <AlertCard
            colorSchema={alertColorScheme.success}
            description={translate((translations as TranslationSVT).expressAlertConfirmedDescription)}
            testId="express-confirmation-alert"
            title={translate((translations as TranslationSVT).expressAlertConfirmedTitle)}
            variant={alertVariants.standard}
          />
        </View>
      ) : null}
    </>
  )
}

export default ExpressAlert
