import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  section: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.md,
    paddingBottom: paddingNew.md,
  },
  sectionTitle: {
    ...typography.overline,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.base,
  },

  textDisabled: {
    color: colorsNew.redesign.text.disabled,
  },
})

export default styles
