import React, { useState, useEffect, useRef } from 'react'
import { View } from 'react-native'
import LottieView from 'lottie-react-native'

import styles from './styles'
import SuccessAnimationRender from 'src/scenes/Main/Salary/SuccessAnimation/SuccessAnimationRender'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  onFinish: () => void
  shouldPlay: boolean
}

const SuccessAnimation = ({ shouldPlay, onFinish }: Props) => {
  const [playing, setPlaying] = useState(false)
  const refAnimation = useRef<LottieView | null>(null)

  useEffect(() => {
    setPlaying(!shouldPlay)
    if (shouldPlay && refAnimation) {
      refAnimation?.current?.play()
    }
  }, [shouldPlay])

  const onAnimationFinish = () => {
    refAnimation?.current?.reset()
    setPlaying(false)
    onFinish && onFinish()
  }

  return (
    <View style={shouldPlay && !playing ? styles.animationContainer : styles.animationHidden}>
      <SuccessAnimationRender
        onAnimationFinish={onAnimationFinish}
        refAnimation={refAnimation}
        successText={translate(translations.feedbackSubmitted)}
      />
    </View>
  )
}

export default SuccessAnimation
