import React from 'react'
import { Text, View } from 'react-native'

import styles from './styles'

type InfoSectionT = {
  heading: string
  icon?: JSX.Element
  textSections: string[]
}

const InfoSection = ({ heading, icon, textSections }: InfoSectionT) => (
  <View style={styles.infoContainer}>
    {icon}
    <View>
      <Text style={styles.infoTitle}>{heading}</Text>
      {textSections.map((text, index) => (
        <Text key={index} style={styles.infoText}>
          {text}
        </Text>
      ))}
    </View>
  </View>
)

export default InfoSection
