import { Mixpanel } from 'mixpanel-react-native'
import { StackNavigationOptions } from '@react-navigation/stack'
import React from 'react'

import { ArrowBackIosIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { getTaskStatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components/StatusInfo/utils'
import HeaderButton from 'src/components/HeaderButton'
import I18n from 'src/utils/translations/i18n'
import styles from './styles'
import mainHeaderConfig from './mainHeaderConfig'

//@ts-ignore
export default (params, mixpanel: Mixpanel | null) => {
  const {
    navigation: { goBack },
    route,
  } = params

  const headerTitle =
    route?.name === 'TasksList' ? `categoryTitle_${route?.params?.category}` : `taskTitle_${route?.params?.task}` || ''
  const status = route?.params?.status
  const timeToComplete = route?.params?.timeToComplete

  return {
    ...mainHeaderConfig(headerTitle, params),
    headerLeft: () => (
      <HeaderButton
        iconColor={colorsNew.redesign.action.active}
        iconName={ArrowBackIosIcon}
        onPress={() => {
          const taskStatus = getTaskStatusInfo(status, timeToComplete)
          if (route?.name !== 'TasksList')
            mixpanel?.track('Exit Recruitment Task', {
              TaskTitle: I18n.t(headerTitle),
              TaskStatus: taskStatus.title,
            })
          goBack()
        }}
        size={24}
        style={styles.onboardingHeaderButtonLeft}
      />
    ),
  } as StackNavigationOptions
}
