import React, { useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'

import { CloseIcon } from 'src/icons'
import { deleteWorkReference, fetchUser, updateWorkReferences } from 'src/store/user/actions'
import { ErrorType, errorTypes } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { newReferenceTemplate } from '../utils'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { UserReferences } from 'src/store/user/selectors'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ConfirmationAlertModal from 'src/scenes/Main/Profile/RequiredTasks/components/ConfirmationAlertModal/ConfirmationAlertModal'
import FormBody from './FormBody'
import getValidationSchema from './validation'
import translations, { translate } from 'src/utils/translations/translations'
import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  closeForm: () => void
  isDisabled: boolean
  reference: UserReferences | null
}

type RecursiveNonNullable<T> = { [K in keyof T]-?: RecursiveNonNullable<NonNullable<T[K]>> }

export type FormValues = Pick<
  RecursiveNonNullable<UserReferences>,
  | 'email'
  | 'employer'
  | 'endedOn'
  | 'isOngoing'
  | 'name'
  | 'occupation'
  | 'phone'
  | 'position'
  | 'reasonForLeaving'
  | 'startedOn'
>

const ReferenceForm = ({ closeForm, isDisabled, reference }: Props) => {
  const userId = useAppSelector(state => state.user.id) as string

  const dispatch = useAppDispatch()

  const [formError, setFormError] = useState<ErrorType | null>(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteReference = () => {
    if (!reference?.id) {
      return closeForm()
    }
    setIsDeleting(true)
    setFormError(null)
    deleteWorkReference({
      referenceId: reference?.id,
      onSuccess: async () => {
        await dispatch(fetchUser(userId))
        closeForm()
      },
      onError: () => {
        setIsDeleting(false)
        setFormError(errorTypes.delete)
      },
    })
  }

  const onReferenceSaveSuccess = (form: FormikHelpers<FormValues>) => {
    form.setSubmitting(false)
    closeForm()
  }

  const saveReference = (values: FormValues, form: FormikHelpers<FormValues>) => {
    const isEdited = !!reference?.id
    const workReferences = { workReferences: isEdited ? [{ ...values, id: reference.id }] : [values] }

    setFormError(null)
    dispatch(
      updateWorkReferences(
        workReferences,
        userId,
        () => onReferenceSaveSuccess(form),
        () => {
          form.setSubmitting(false)
          setFormError(errorTypes.save)
        },
      ),
    )
  }

  const initialValues: FormValues = reference
    ? Object.assign({ ...newReferenceTemplate }, reference)
    : newReferenceTemplate

  return (
    <Formik initialValues={initialValues} onSubmit={saveReference} validationSchema={getValidationSchema()}>
      {form => {
        if (showDeleteConfirmation) {
          return (
            <ConfirmationAlertModal
              description={translate(translations.referenceDeleteModalInfo)}
              isDeleting={isDeleting}
              onCancel={() => setShowDeleteConfirmation(false)}
              onDelete={deleteReference}
              title={translate(translations.referenceDeleteModalTitle)}
            />
          )
        }

        return (
          <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.formHeader}>
              <View style={styles.formHeaderInfo}>
                {reference?.name ? (
                  <>
                    <Text style={styles.formTitle}>{reference?.name}</Text>
                    <Text style={styles.formSubtitle}>{reference?.employer}</Text>
                  </>
                ) : (
                  <Text style={styles.formTitle}>{translate(translations.referenceFormTitle)}</Text>
                )}
              </View>

              <TouchableOpacity onPress={closeForm}>
                <CloseIcon fill={colorsNew.redesign.text.primary} size={24} />
              </TouchableOpacity>
            </View>

            {isDisabled ? (
              <View style={styles.alertContainer}>
                <AlertCard
                  colorSchema={alertColorScheme.info}
                  description={translate(translations.experienceFormAlertInfo)}
                  title={translate(translations.experienceFormAlertTitle)}
                  variant={alertVariants.standard}
                />
              </View>
            ) : null}
            <FormBody
              closeFormError={() => setFormError(null)}
              form={form}
              formError={formError}
              isDeleting={isDeleting}
              isDisabled={isDisabled}
              onDelete={() => setShowDeleteConfirmation(true)}
            />
          </ScrollView>
        )
      }}
    </Formik>
  )
}

export default ReferenceForm
