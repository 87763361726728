import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  viewWrapper: {
    flex: 1,
    flexBasis: 1,
    justifyContent: 'center',
  },
})
