import React from 'react'
import { Platform, StyleSheet, Text, TouchableOpacity } from 'react-native'

import { colors, colorsNew } from 'src/styles'
import { VisibilityIcon, VisibilityOffIcon } from 'src/icons'
import Icon from 'src/components/Icon'
import translations, { translate } from 'src/utils/translations/translations'

import styles from './styles'

type Props = {
  centered?: boolean
  loginView?: boolean
  onSecureButtonPress?: () => void
  secureTextEntryDisabled?: boolean
}

const SecureButton = ({ centered, loginView, onSecureButtonPress, secureTextEntryDisabled }: Props) => {
  const isAndroid = Platform.OS === 'android'

  return (
    <TouchableOpacity
      onPress={onSecureButtonPress}
      style={StyleSheet.flatten([
        styles.secureButton,
        centered && styles.secureButtonCentered,
        loginView && styles.loginSecureButton,
        loginView && styles.loginSecureView,
      ])}
      testID="secureButton"
    >
      {loginView ? (
        <>
          {secureTextEntryDisabled ? (
            <VisibilityIcon size={24} fill={colors.grey._300} />
          ) : (
            <VisibilityOffIcon size={24} fill={colors.grey._300} />
          )}
        </>
      ) : isAndroid ? (
        <Icon
          color={colorsNew.redesign.text.primary}
          name={secureTextEntryDisabled ? 'secure-disabled' : 'secure-enabled'}
          size={24}
        />
      ) : (
        <Text style={styles.secureButtonTitle}>
          {translate(secureTextEntryDisabled ? translations.hide : translations.show)}
        </Text>
      )}
    </TouchableOpacity>
  )
}

export default SecureButton
