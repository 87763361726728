import React from 'react'
import { View, StyleSheet } from 'react-native'
import { StackNavigationOptions } from '@react-navigation/stack'

import styles from './styles'

export const screenOptions = (): StackNavigationOptions => ({
  headerBackground: () => <View style={[StyleSheet.absoluteFill, styles.header]} />,
  headerStyle: styles.header,
  headerTitleStyle: styles.headerTitle,
  headerTintColor: 'white',
})

export default () => ({
  screenOptions: screenOptions(),
})
