import { StyleSheet, Platform } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  screen: {
    backgroundColor: colorsNew.redesign.background.paper,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: paddingNew.sm,
    ...Platform.select({
      web: {
        justifyContent: 'flex-start',
        marginTop: '50%',
      },
    }),
  },
  text: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
})
