import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  flexible: {
    flex: 1,
  },
  text: {
    alignSelf: 'flex-start',
  },
})
