import React from 'react'
import Svg, { Rect, Path, Mask } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const CalendarCardIcon = ({ size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/Svg" {...props}>
    <Rect width={size} height={size} fill="white" />
    <Mask id="path-2-inside-1_2288_16504" fill="white">
      <Path d="M3 7C3 4.79086 4.79086 3 7 3H33C35.2091 3 37 4.79086 37 7V8H3V7Z" />
    </Mask>
    <Path d="M3 7C3 4.79086 4.79086 3 7 3H33C35.2091 3 37 4.79086 37 7V8H3V7Z" fill="#E99797" />
    <Path
      d="M2.86485 7C2.86485 4.71622 4.71622 2.86485 7 2.86485H33C35.2838 2.86485 37.1352 4.71622 37.1352 7H36.8648C36.8648 4.8655 35.1345 3.13515 33 3.13515H7C4.8655 3.13515 3.13515 4.8655 3.13515 7H2.86485ZM37 8H3H37ZM2.86485 8V7C2.86485 4.71622 4.71622 2.86485 7 2.86485V3.13515C4.8655 3.13515 3.13515 4.8655 3.13515 7V8H2.86485ZM33 2.86485C35.2838 2.86485 37.1352 4.71622 37.1352 7V8H36.8648V7C36.8648 4.8655 35.1345 3.13515 33 3.13515V2.86485Z"
      fill="black"
      fill-opacity="0.38"
      mask="url(#path-2-inside-1_2288_16504)"
    />
    <Mask id="path-4-inside-2_2288_16504" fill="white">
      <Path d="M3 8H37V33C37 35.2091 35.2091 37 33 37H7C4.79086 37 3 35.2091 3 33V8Z" />
    </Mask>
    <Path d="M3 8H37V33C37 35.2091 35.2091 37 33 37H7C4.79086 37 3 35.2091 3 33V8Z" fill="white" />
    <Path
      d="M3 8H37H3ZM37.3 33C37.3 35.3748 35.3748 37.3 33 37.3H7C4.62518 37.3 2.7 35.3748 2.7 33H3.3C3.3 35.0435 4.95655 36.7 7 36.7H33C35.0435 36.7 36.7 35.0435 36.7 33H37.3ZM7 37.3C4.62518 37.3 2.7 35.3748 2.7 33V8H3.3V33C3.3 35.0435 4.95655 36.7 7 36.7V37.3ZM37.3 8V33C37.3 35.3748 35.3748 37.3 33 37.3V36.7C35.0435 36.7 36.7 35.0435 36.7 33V8H37.3Z"
      fill="black"
      fill-opacity="0.38"
      mask="url(#path-4-inside-2_2288_16504)"
    />
  </Svg>
)

export default CalendarCardIcon
