import { View, Text, StyleSheet } from 'react-native'
import { FormikProps } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'

import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { ErrorAlert, errorTypes } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getNorthpassTrainingUrl } from 'src/utils/northpassUrl'
import { InputDate } from 'src/components/Form'
import { northpassCourses } from 'src/global/constants'
import { openURLIfCan } from 'src/global/utils'
import { SafeguardingInitialValuesType } from '../SafeguardingDeclaration'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useFocusEffect } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import { UserRequestType } from 'src/graphql/User/types'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import FileUploader from 'src/components/FileUploader'
import LoadingIndicator from 'src/components/LoadingIndicator'
import RadioButton from 'src/components/Form/InputRadioGroup/RadioButton/RadioButton'
import translations, { translate } from 'src/utils/translations/translations'
import User from 'src/graphql/User/Show'
import styles from './styles'

type Props = {
  form: Pick<
    FormikProps<SafeguardingInitialValuesType>,
    'errors' | 'setFieldTouched' | 'setFieldValue' | 'touched' | 'values'
  >
  isEditState: boolean
  isTaskCompleted: boolean
  onFileSaveCallback: () => void
  onFilesErrorCallback: () => void
  setIsFilePicked: (status: boolean) => void
  shouldSaveDocument: boolean
}

const SafeguardingTraining = ({
  form,
  isEditState,
  isTaskCompleted,
  onFileSaveCallback,
  onFilesErrorCallback,
  setIsFilePicked,
  shouldSaveDocument,
}: Props) => {
  const userId = useAppSelector(state => state.user?.id) as string

  const [safeguardingTrainingUrl, setSafeguardingTrainingUrl] = useState('')
  const [isNorthpassUrlError, setIsNorthpassUrlError] = useState(false)
  const [isNorthpassUrlLoading, setIsNorthpassUrlLoading] = useState(true)

  const onSuccess = useCallback((url: string) => {
    bugsnagActionBreadcrumb(`Set user's Northpass safeguarding training url`, { url })
    setSafeguardingTrainingUrl(url)
    setIsNorthpassUrlError(false)
    setIsNorthpassUrlLoading(false)
  }, [])

  const onError = useCallback(() => {
    setIsNorthpassUrlError(true)
    setIsNorthpassUrlLoading(false)
  }, [])

  useEffect(() => {
    getNorthpassTrainingUrl({ onError, onSuccess, trainingType: northpassCourses.safeguardingTraining })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, refetch, loading } = useQuery<UserRequestType>(User, {
    skip: !userId,
    variables: {
      id: userId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      const safeguardingTrainingOn = res?.user.safeguardingTrainingOn
      if (safeguardingTrainingOn) {
        form.setFieldValue('trainingDate', safeguardingTrainingOn)
        form.setFieldValue('trainingStatus', true)
      }
    },
  })

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  if (loading) {
    return <LoadingIndicator />
  }

  const openSafeguardingCourse = () => openURLIfCan(safeguardingTrainingUrl)

  const trainingCompletionDate = data?.user.safeguardingTrainingOn

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{translate(translations.safeguardingTrainingTitle)}</Text>
      {!trainingCompletionDate || isEditState ? (
        <>
          <Text style={styles.label}>{translate(translations.safeguardingTrainingQuestion1)}</Text>
          <View style={styles.radioButtons}>
            <RadioButton
              isLarge
              label={translate(translations.yes)}
              onPress={() => form.setFieldValue('trainingStatus', true)}
              selected={form.values.trainingStatus === true}
            />
            <RadioButton
              isLarge
              label={translate(translations.no)}
              onPress={() => form.setFieldValue('trainingStatus', false)}
              selected={form.values.trainingStatus === false}
            />
          </View>
        </>
      ) : null}

      {(!trainingCompletionDate || isEditState) && form.values.trainingStatus === null ? (
        <Text style={styles.subInfo}>{translate(translations.safeguardingTrainingTipInfo)}</Text>
      ) : null}

      {(!trainingCompletionDate || isEditState) && form.values.trainingStatus === true ? (
        <>
          <Text style={StyleSheet.flatten([styles.label, styles.extraTopSpace])}>
            {translate(translations.safeguardingTrainingQuestion2)}
          </Text>
          <InputDate
            field={{ name: 'trainingDate', value: form.values.trainingDate }}
            form={form}
            label={translate(translations.safeguardingTrainingDateSelect)}
            required
            maximumDate={new Date()}
            useNew
          />
          <Text style={StyleSheet.flatten([styles.mainText, styles.extraBottomSpace])}>
            {translate(translations.safeguardingTrainingCertificateUpload)}
          </Text>

          <FileUploader
            arrayDocuments={[]}
            documentType={'cv_safeguarding_certificate'}
            onFileSaveCallback={onFileSaveCallback}
            onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
            onFilesErrorCallback={onFilesErrorCallback}
            saveOnUpload={shouldSaveDocument}
          />

          <Text style={StyleSheet.flatten([styles.subInfo, styles.extraTopSpace])}>
            {translate(translations.safeguardingTrainingCertificateUploadInfo)}
          </Text>
        </>
      ) : null}

      {form.values.trainingStatus === false || (trainingCompletionDate && !isEditState) ? (
        <>
          {form.values.trainingStatus === false ? (
            <Text style={styles.subTitle}>{translate(translations.safeguardingTrainingAcademyInfo)}</Text>
          ) : (
            <>
              <Text style={styles.subTitle}>{translate(translations.safeguardingTrainingDateInfo)}</Text>
              <Text style={styles.mainText}>{trainingCompletionDate}</Text>
            </>
          )}
          <View style={styles.buttonSection}>
            {isNorthpassUrlError ? (
              <ErrorAlert hideError={() => setIsNorthpassUrlError(false)} type={errorTypes.training} />
            ) : null}
            <View style={styles.buttonWrapper}>
              <ButtonNew
                disabled={isNorthpassUrlError || isNorthpassUrlLoading}
                isShort
                onPress={openSafeguardingCourse}
                RightIcon={ChevronRightIcon}
                title={translate(translations.safeguardingTrainingAcademyOpen)}
                variant={buttonVariants.outlinedDefault}
              />
            </View>
          </View>
          <Text style={styles.subInfo}>{translate(translations.safeguardingTrainingAcademyTime)}</Text>
          {!isTaskCompleted ? (
            <View style={styles.alertContainer}>
              <AlertCard
                colorSchema={alertColorScheme.info}
                description={translate(translations.safeguardingTrainingAcademyAlertInfo)}
                title={translate(translations.safeguardingTrainingAcademyAlertTitle)}
                variant={alertVariants.outlined}
              />
            </View>
          ) : null}
        </>
      ) : null}
    </View>
  )
}

export default SafeguardingTraining
