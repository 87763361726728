import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const isWeb = Platform.OS === 'web'
const deviceWidth = isWeb ? 500 : Math.round(Dimensions.get('window').width)
const scale = deviceWidth * 0.15
const size = scale < 150 ? scale : 150 || 150

const styles = StyleSheet.create({
  screen: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  animationContainer: {
    alignItems: 'center',
    height: size,
    justifyContent: 'center',
    width: size,
  },
  animation: {
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.sm,
    textAlign: 'center',
  },
})

export default styles
