import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  badge: {
    backgroundColor: colorsNew.redesign.primary.shades30p,
    borderRadius: 50,
    marginBottom: paddingNew.xs,
    marginRight: paddingNew.xxs,
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  title: {
    ...typography.chipTextSmall,
    color: colorsNew.redesign.primary.contrast,
  },
  lightBadge: {
    backgroundColor: colorsNew.redesign.primary.shades12p,
  },
})

export default styles
