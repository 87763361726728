import gql from 'graphql-tag'

import { RelativeTypes } from './types'

export type { RelativeTypes }

export default gql`
  fragment RelativeFields on Relative {
    id
    name
    phone
    relationship
  }
`
