import gql from 'graphql-tag'

import { PolicyDocumentAcceptancesRequestTypes } from './types'

export type { PolicyDocumentAcceptancesRequestTypes }

export default gql`
  query PolicyDocumentAcceptances($userId: String, $documentTypes: [String]) {
    policyDocumentAcceptances(filter: { userId: $userId, documentTypes: $documentTypes }) {
      nodes {
        id
        policyDocument {
          id
          url
          type
        }
      }
    }
    policyDocuments {
      nodes {
        id
        url
        type
      }
    }
  }
`
