import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    backgroundColor: colorsNew.redesign.background.paper,
    flex: 1,
    paddingVertical: paddingNew.sm,
  },
  containerPadding: {
    paddingHorizontal: paddingNew.sm,
  },
})
