import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { BookingRequestType } from 'src/graphql/Booking/types'
import { countryCodeSelector, isGBSelector, isSESelector } from 'src/store/app/selectors'
import { useAppSelector } from './reduxHooks'
import BookingQuery from 'src/graphql/Booking/Show'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'
import { JobDetailsData } from 'src/scenes/Main/Jobs/Details/components/JobDetails/types'
import { getBookingStatus } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Booking/utils'
import { getJobAlertData } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/utils'
import { CalendarBooking } from 'src/utils/calendarIntegration/calendarActions'
import { applicationStatus } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/applicationTypes'
import { ApplicationOfferDataType } from 'src/scenes/Main/Jobs/Details/components/ApplicationModal'
import { convertClassGrade, getBookingAppointment } from 'src/scenes/Main/Jobs/Details/helpers'
import { getJobInformation } from 'src/utils/getJobInformation'

const useBookingData = (bookingId: string) => {
  const countryCode = useAppSelector(countryCodeSelector)
  const isSE = useAppSelector(isSESelector)
  const isGB = useAppSelector(isGBSelector)

  const userId = useAppSelector(state => state.user.id)

  const { data, loading, error, refetch } = useQuery<BookingRequestType>(BookingQuery, {
    variables: {
      id: bookingId,
    },
    fetchPolicy: 'network-only',
  })

  const bookingStatus = useMemo(() => {
    if (!data) return
    return getBookingStatus(data?.booking, userId)
  }, [data, userId])

  const isSickLeaves = (data?.booking?.sickleaves || []).length > 0

  const bookingAlertData = useMemo(() => {
    const schoolName = data?.booking.school?.name
    const startTime = data && formatter.time(data.booking.startTime)
    return bookingStatus
      ? getJobAlertData({ offerStatus: bookingStatus, schoolName, time: startTime, id: bookingId, isSickLeaves })
      : null
  }, [bookingStatus, data])

  const jobHeader = useMemo(() => {
    return {
      startDate: formatter.apiFormat(data?.booking.startTime) || '',
      title: formatter.dayOfWeekLong(data?.booking.startTime) || '',
      hours: formatter.convertMinutesToHoursAndMinutes(data?.booking._durationMinutes || 0),
      details: `${formatter.time(data?.booking.startTime)} - ${formatter.time(data?.booking.endTime)}` || '',
    }
  }, [data])

  const jobTitle = data?.booking?.school?.name || ''
  const jobInformation = getJobInformation(data?.booking?.assignmentInfo || '', data?.booking?.school?.bookingInfo)
  const payRate = data?.booking?.salaryRate
    ? formatter.getSalarySubtitle({
        countryCode,
        holidayRate: data?.booking.holidayPayBaseRate,
        salary: data?.booking.salaryRate,
        scheduleType: data?.booking.timeScheduleType,
      })
    : undefined

  const jobConfirmationData: ApplicationOfferDataType = {
    date: formatter.fullDay(data?.booking.startTime),
    hours: formatter.convertMinutesToHoursAndMinutes(data?.booking._durationMinutes || 0),
    title: data?.booking.school.name || '',
    time: `${formatter.time(data?.booking.startTime)} - ${formatter.time(data?.booking.endTime)}` || '',
    type: bookingStatus === applicationStatus.userRequested ? 'accept' : 'apply',
  }

  const lunchPrice =
    (isSE &&
      data?.booking.lunchPrice &&
      translate(translations.lunchPriceText, {
        lunchPrice: formatter.formatPrice(data?.booking.lunchPrice),
      })) ||
    undefined
  const unpaidBreakTime = data?.booking.unpaidTime ? `${data?.booking.unpaidTime} min` : undefined

  const isPreschool = data?.booking.level?.group === 'preschool'
  const isGBPreschool = isGB && isPreschool

  const schoolData = data?.booking.school
  const originalTeacher = data?.booking.originalTeacher
  const room = isPreschool ? data?.booking.department : undefined
  const jobRoles = (!isGBPreschool && data?.booking.topics) || []
  const competences = (data?.booking.competences || []).filter(competence =>
    isGB ? competence?.type === 'role' : competence,
  )
  const SENSkills = isGB
    ? (data?.booking.competences || [])
        .filter(competence => competence?.type === 'general')
        .map(skill => skill.name)
        .join(', ')
    : undefined

  const level = data?.booking.level
  const jobLevel = !isGBPreschool && level ? [level] : []
  const healthInformation = (data?.booking.hasHealthRisks && data?.booking.healthRisksDescription) || undefined

  const jobGrade = useMemo(() => {
    const gradeString = data?.booking.klassName
    const gradesArray = gradeString ? gradeString.split(', ') : []
    const convertedClassGrade = isSE ? convertClassGrade(gradesArray) : gradesArray
    return convertedClassGrade
  }, [data, isSE])

  const jobFiles = data?.booking.files || []
  const pupilInfo =
    bookingStatus === applicationStatus.userAppointed && !!data?.booking?.pupil?.firstName
      ? `${data?.booking.pupil.firstName} ${data?.booking.pupil.lastName}`
      : undefined
  const pupilFiles = data?.booking?.pupil?.files || []

  const applicationId = (data?.booking.studentApplications || []).find(
    application => application.studentId === userId,
  )?.id

  const bookingCalendarData: CalendarBooking | undefined = useMemo(() => {
    if (!data) return
    return {
      cancelled: data.booking.cancelled,
      displayInfo: data?.booking.displayInfo,
      endTime: data?.booking.endTime,
      rejected: data.booking.rejected,
      school: data?.booking.school,
      startTime: data?.booking.startTime,
    }
  }, [data])

  const type = {
    typeName: data?.booking.__typename,
    type: data?.booking.type,
  }

  const appointedBy = getBookingAppointment(userId, data?.booking?.appointedById)

  const bookingDetails: JobDetailsData = {
    competences,
    healthInformation,
    isPreschool,
    isSickLeaves,
    jobFiles,
    jobGrade,
    jobHeader,
    jobInformation,
    jobLevel,
    jobRoles,
    jobTitle,
    level,
    lunchPrice,
    originalTeacher,
    payRate,
    pupilFiles,
    pupilInfo,
    room,
    schoolData,
    SENSkills,
    type,
    unpaidBreakTime,
  }
  return {
    appointedBy,
    applicationId,
    bookingCalendarData,
    bookingAlertData,
    bookingDetails,
    bookingStatus,
    error,
    jobConfirmationData,
    loading,
    refetch,
  }
}

export default useBookingData
