import { View, ActivityIndicator } from 'react-native'
import React from 'react'

import { colorsNew } from 'src/styles'
import styles from './styles'

const Loader = () => {
  return (
    <View style={styles.loading}>
      <ActivityIndicator color={colorsNew.redesign.primary.main} size="large" testID="updating-indicator" />
    </View>
  )
}

export default Loader
