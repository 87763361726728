import { StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const row = {
  alignItems: 'center',
  borderRadius: 4,
  flexDirection: 'row',
  marginVertical: 4,
  paddingVertical: paddingNew.xxs,
} as ViewStyle

const styles = StyleSheet.create({
  row: {
    ...row,
  },
  rowPressed: {
    ...row,
    backgroundColor: colorsNew.redesign.action.pressed,
  },
  documentContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  documentName: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    flex: 1,
    marginHorizontal: paddingNew.xxs,
  },
  documentNameLight: {
    ...typography.body2,
    textDecorationLine: 'underline',
    marginTop: 2,
  },
  iconButton: {
    alignItems: 'center',
    borderColor: colorsNew.redesign.action.active,
    borderRadius: 14,
    borderWidth: 1,
    height: 28,
    justifyContent: 'center',
    width: 28,
  },
})

export default styles
