import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ArrowDropUp = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M8.71005 12.2902L11.3001 9.70021C11.6901 9.31021 12.3201 9.31021 12.7101 9.70021L15.3001 12.2902C15.9301 12.9202 15.4801 14.0002 14.5901 14.0002H9.41005C8.52005 14.0002 8.08005 12.9202 8.71005 12.2902Z" />
  </Svg>
)

export default ArrowDropUp
