import { StyleSheet, Text, View } from 'react-native'
import Api from 'src/utils/api'
import axios, { AxiosResponse } from 'axios'
import HyperLink from 'react-native-hyperlink'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { bugsnagActionBreadcrumb, bugsnagNotify } from 'src/utils/bugsnag'
import { FileUploaderProps, NewUploadedFile, WebFile } from './types'
import { getFileName } from './utils'
import { isGBSelector } from 'src/store/app/selectors'
import {
  fetchUser,
  removeDocumentSv,
  removeDocumentUK,
  removeOtherSvDocument,
  uploadDocumentSv,
  uploadDocumentUK,
} from 'src/store/user/actions'
import { UploadIcon } from 'src/icons'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { UserDocuments } from 'src/store/user/selectors'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import FileCard from './FileCard'
import LoadingIndicator from 'src/components/LoadingIndicator'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

export const FileUploader = ({
  arrayDocuments,
  buttonDisabled,
  deletingFilesDisabled,
  description,
  disabled,
  documentName,
  documentType,
  field,
  fileInfo,
  multiple = false,
  onFileSaveCallback,
  onFilesChangeCallback,
  onFilesErrorCallback,
  onOtherDocumentsCallback,
  saveOnUpload = true,
}: FileUploaderProps) => {
  const dispatch = useAppDispatch()
  const applyAssetId = useAppSelector(state => state.user?.attributes?.applyAssetId)
  const userId = useAppSelector(state => state.user?.id || '')
  const isGB = useAppSelector(isGBSelector)

  const [documents, setDocuments] = useState<(UserDocuments | string | NewUploadedFile)[]>(arrayDocuments)
  const [filesToUpload, setFilesToUpload] = useState<WebFile[]>([])
  const [loading, setLoading] = useState<boolean>()

  const handleError = () => onFilesErrorCallback && onFilesErrorCallback()
  const handleSuccess = () => onFileSaveCallback && onFileSaveCallback()

  const handleFilesSave = useCallback(() => {
    filesToUpload.filter(Boolean).forEach(file => {
      //@ts-ignore
      const myNewFile = new File([file], encodeURIComponent(file.name), { type: file.type })
      const { type, name } = myNewFile
      let signedUrl = ''
      bugsnagActionBreadcrumb('handleFilesSave', { newFileType: type, type: file?.type })

      Api.get(`/presigned_url?objectName=${name}&contentType=${type}&type=user-apply&id=${applyAssetId}`, {
        onSuccess: (response: AxiosResponse) => response,
        onError: handleError,
      })
        .then(response => {
          const options = {
            headers: {
              acl: 'public-read',
              'Content-Disposition': `inline; filename="${name}"`,
              'Content-Type': file?.type,
            },
          }
          signedUrl = response.data.signedUrl
          return axios.put(signedUrl, myNewFile, options)
        })
        .then(async () => {
          const url = signedUrl.split('?')[0]
          const res = isGB
            ? //@ts-ignore
              await uploadDocumentUK({ url, documentType })
            : onOtherDocumentsCallback
            ? onOtherDocumentsCallback()
            : await dispatch(
                uploadDocumentSv({
                  field,
                  onError: handleError,
                  onSuccess: handleSuccess,
                  url,
                  userId,
                }),
              )
          const id = res?.data?.data?.id

          if (isGB) {
            if (id) {
              dispatch(fetchUser(userId))
              handleSuccess()
              setFilesToUpload([])
              setDocuments(prevState => [...prevState, { name, url, id }])
            } else {
              handleError()
            }
          } else field !== 'others' ? setDocuments(prevState => [...prevState, url]) : undefined
        })
        .catch(error => {
          bugsnagNotify(error)
          console.error(error)
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyAssetId, documentType, field, filesToUpload, isGB])

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      bugsnagActionBreadcrumb('select file')
      setLoading(true)
      try {
        const files = event.target.files
        const file = files && (files[0] as WebFile)
        if (file) {
          setFilesToUpload(prevState => [...prevState, file])
          saveOnUpload && handleFilesSave()
        }
      } catch (e) {
        bugsnagNotify(e)
      } finally {
        setLoading(false)
      }
    },
    [handleFilesSave, saveOnUpload],
  )

  useEffect(() => {
    saveOnUpload && filesToUpload && handleFilesSave()
  }, [filesToUpload, handleFilesSave, saveOnUpload])

  useEffect(() => {
    if (onFilesChangeCallback) {
      onFilesChangeCallback({ uploadedFiles: filesToUpload })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload])

  const removeFile = (fileToDelete: string | UserDocuments | NewUploadedFile) => {
    bugsnagActionBreadcrumb('removeFIle', { fileToDelete })

    // @ts-ignore
    if (window.confirm(translate(translations.areYouSureDeleteFile))) {
      if (isGB && typeof fileToDelete === 'object' && fileToDelete?.id) {
        removeDocumentUK(fileToDelete.id).then(async () => await dispatch(fetchUser(userId)))
      } else if (typeof fileToDelete === 'string') {
        field === 'others'
          ? removeOtherSvDocument(fileToDelete, onOtherDocumentsCallback)
          : dispatch(removeDocumentSv({ field, url: fileToDelete, userId }))
      }
      setDocuments(prevState => [
        ...prevState.filter(document =>
          isGB && typeof fileToDelete === 'object' && typeof document === 'object'
            ? document.id !== fileToDelete.id
            : document !== fileToDelete,
        ),
      ])
    }
  }

  const renderFileOptions = useMemo(() => {
    return (
      <>
        {filesToUpload?.filter(Boolean)?.map(file => (
          <FileCard
            fileName={getFileName(file)}
            key={file.name}
            showRemoveDocumentDialog={() =>
              setFilesToUpload(prevState => prevState.filter(({ name }) => file.name !== name))
            }
          />
        ))}
        {documents?.filter(Boolean).map((document, index) => (
          <FileCard
            disabled={
              (typeof document === 'object' && 'verifiedAt' in document && !!document?.verifiedAt) ||
              deletingFilesDisabled
            }
            fileName={getFileName(document)}
            key={index}
            showRemoveDocumentDialog={() => removeFile(document)}
          />
        ))}
        {fileInfo}
        {loading ? (
          <View style={styles.spinner}>
            <LoadingIndicator style={styles.spinner} />
          </View>
        ) : (
          <>
            <View style={styles.uploadButtonWrapper}>
              <ButtonNew
                disabled={buttonDisabled}
                isShort
                isWebUploader
                LeftIcon={UploadIcon}
                onPress={handleFileChange}
                title={multiple ? translate(translations.uploadFiles) : translate(translations.uploadFile)}
                variant={buttonVariants.outlinedDefault}
              />
            </View>
          </>
        )}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonDisabled, disabled, documents, fileInfo, filesToUpload, handleFileChange, isGB, loading])

  return (
    <>
      {documentName ? <Text style={styles.fileTitle}>{documentName}</Text> : null}
      {description && (
        <HyperLink linkDefault linkStyle={styles.hyperlink}>
          <Text style={StyleSheet.flatten([styles.fileDescription, disabled ? styles.disabled : {}])}>
            {description}
          </Text>
        </HyperLink>
      )}
      {renderFileOptions}
    </>
  )
}

export default FileUploader
