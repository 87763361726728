import { createContext } from 'react'

import { initialFeatureFlagState } from './state'
import type { FeatureFlagStateT } from './types'

export interface IFeatureFlagsContext extends FeatureFlagStateT {
  fetchFeatureFlags: (regionId: string) => Promise<void>
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component.')
}

const initialFeatureFlagsContext: IFeatureFlagsContext = {
  ...initialFeatureFlagState,
  fetchFeatureFlags: stub,
}

export const FeatureFlagsContext = createContext<IFeatureFlagsContext>(initialFeatureFlagsContext)
