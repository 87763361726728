import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import TimePicker from 'rc-time-picker'
import { useSelector } from 'react-redux'
import moment from 'moment'

import 'rc-time-picker/assets/index.css'
import './stylesCss.css'

import translations, { translate } from 'src/utils/translations/translations'
import { momentWithTimezone } from 'src/global/utils'
import {
  getDefaultEndTime,
  getDefaultStartTime,
  getMaxEndTime,
  getMinStartTime,
} from '../../availabilityContext/dateHelpers'
import { AvailabilityScheduleType } from '../../availabilityContext/helpers'
import { isGBSelector } from 'src/store/app/selectors'
import { useAvailabilitySchedule } from '../../availabilityContext/availabilityContext'

import styles from './styles'

const hours = Array.from(Array(24).keys())
const minutes = [0, 15, 30, 45]

const IntervalInput = () => {
  const isGB = useSelector(isGBSelector)

  const { state, selectedDay, updateDayWorkingHours, isSubmitting } = useAvailabilitySchedule()

  const defaultStartTime =
    state.availabilityList[selectedDay].scheduleType.startTime || getDefaultStartTime(selectedDay)
  const defaultEndTime = state.availabilityList[selectedDay].scheduleType.endTime || getDefaultEndTime(selectedDay)

  const [startTime, setStartTime] = useState(defaultStartTime)
  const [endTime, setEndTime] = useState(defaultEndTime)

  useEffect(() => {
    setStartTime(defaultStartTime)
  }, [defaultStartTime])

  useEffect(() => {
    setEndTime(defaultEndTime)
  }, [defaultEndTime])

  const [showError, setShowError] = useState(false)

  const getErrorMessage = () =>
    moment(endTime).diff(moment(startTime), 'hours') >= 0
      ? translate(translations.availabilityWarning)
      : translate(translations.errorDateAvailability)

  const textStyle = [styles.dateTextGrey]
  const minTime = getMinStartTime(startTime)
  const maxTime = getMaxEndTime(endTime)

  const startTimeLimits = () => {
    const lowLimit = momentWithTimezone(minTime).hour()
    const upLimit = momentWithTimezone(endTime).hour() - 1
    return hours.filter(hour => hour < lowLimit || hour > upLimit)
  }
  const endTimeLimits = () => {
    const lowLimit = momentWithTimezone(startTime).hour() + 1
    const upLimit = momentWithTimezone(maxTime).hour()
    return hours.filter(hour => hour < lowLimit || hour > upLimit)
  }

  const startTimeLimitsMinutes = (hour: number) => {
    if (hour === momentWithTimezone(endTime).hour()) {
      return minutes.filter(minute => minute >= momentWithTimezone(endTime).minute())
    }
    return []
  }

  const updateTime = () => {
    setShowError(false)
    const isValid = moment(endTime).diff(moment(startTime), 'hours') >= 1

    const scheduleType = {
      timeScheduleType: 'hourly',
      startTime: startTime,
      endTime: endTime,
    } as AvailabilityScheduleType

    isValid ? updateDayWorkingHours(scheduleType) : setShowError(true)
  }

  return (
    <>
      <View style={StyleSheet.flatten([styles.intervalInput, showError && styles.intervalInputError])}>
        <View style={styles.datePickerContainer}>
          <TimePicker
            className="scheduletimepicker"
            disabled={isSubmitting}
            disabledHours={startTimeLimits}
            disabledMinutes={startTimeLimitsMinutes}
            hideDisabledOptions
            minuteStep={15}
            onChange={time => setStartTime(time.toISOString())}
            onClose={updateTime}
            showSecond={false}
            use12Hours={isGB}
            value={momentWithTimezone(startTime)}
          />
        </View>

        <Text style={textStyle}>{translate(translations.to)}</Text>

        <View style={styles.datePickerContainer}>
          <TimePicker
            disabled={isSubmitting}
            className="scheduletimepicker"
            value={momentWithTimezone(endTime)}
            disabledHours={endTimeLimits}
            hideDisabledOptions
            minuteStep={15}
            onChange={time => setEndTime(time.toISOString())}
            onClose={updateTime}
            showSecond={false}
            use12Hours={isGB}
          />
        </View>
      </View>

      {showError ? <Text style={styles.errorText}>{getErrorMessage()}</Text> : null}
    </>
  )
}

IntervalInput.propTypes = {
  endTime: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  startTime: PropTypes.string.isRequired,
}

export default IntervalInput
