import React, { forwardRef, Ref, useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Animated, ActivityIndicator } from 'react-native'
import Swipeable from 'react-native-gesture-handler/Swipeable'
import { RectButton, TouchableWithoutFeedback } from 'react-native-gesture-handler'

import { EllipsisVerticalIcon, DeleteFilledIcon } from 'src/icons'
import Formatter from 'src/utils/formatter'
import { colorsNew } from 'src/styles'
import styles, { DELETE_BUTTON_WIDTH } from './styles'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  createdAt: string
  deleteNotification: () => void
  icon: JSX.Element
  isDeleted: boolean
  isDeleting: boolean
  isRead: boolean
  navigateToDetails: () => void
  setShowModal: () => void
  subTitle: string
  testID: string
  title: string
}

const NotificationCard = forwardRef((props: Props, ref?: Ref<any>) => {
  const {
    createdAt,
    deleteNotification,
    icon,
    isDeleted,
    isDeleting,
    isRead,
    navigateToDetails,
    setShowModal,
    subTitle,
    testID,
    title,
  } = props
  const [isPressed, setIsPressed] = useState(false)

  const renderDeleteAction = (progress: ReturnType<Animated.Value['interpolate']>) => {
    const trans = progress.interpolate({
      inputRange: [0, 1],
      outputRange: [DELETE_BUTTON_WIDTH, 0],
    })

    const pressHandler = () => {
      deleteNotification()
    }

    return (
      <Animated.View style={[styles.deleteButtonContainer, { transform: [{ translateX: trans }] }]}>
        <RectButton style={styles.deleteButton} onPress={pressHandler}>
          {isDeleting ? (
            <ActivityIndicator color={colorsNew.redesign.secondary.contrast} size="large" />
          ) : (
            <>
              <DeleteFilledIcon fill={colorsNew.redesign.secondary.contrast} size={32} />
              <Text style={styles.deleteText}>{translate(translations.deleteNotification)}</Text>
            </>
          )}
        </RectButton>
      </Animated.View>
    )
  }

  const getCardBackground = () => {
    if (isPressed) {
      return colorsNew.translucentHighlight
    }
    return isRead ? colorsNew.white : colorsNew.translucentHighlightLight
  }

  if (isDeleted) {
    return null
  }

  return (
    <Swipeable renderRightActions={renderDeleteAction} rightThreshold={50}>
      <View style={StyleSheet.flatten([styles.row, { backgroundColor: getCardBackground() }])} testID={testID}>
        <View style={styles.icon}>{icon}</View>
        <View style={styles.middleSection}>
          <TouchableWithoutFeedback
            onPress={navigateToDetails}
            onPressIn={() => setIsPressed(true)}
            onPressOut={() => setIsPressed(false)}
          >
            <Text style={styles.title} numberOfLines={1}>
              {title}
            </Text>
            <Text style={styles.info} numberOfLines={3}>
              {subTitle}
            </Text>
          </TouchableWithoutFeedback>
        </View>
        <TouchableOpacity onPress={setShowModal} style={styles.readMore} ref={ref}>
          <Text style={styles.time}>{Formatter.getTimeAgo(createdAt)}</Text>
          <View style={styles.ellipsis}>
            <EllipsisVerticalIcon fill={colorsNew.black} size={16} testID="options-icon" />
          </View>
        </TouchableOpacity>
      </View>
    </Swipeable>
  )
})
export default NotificationCard
