import { BookingSummaryTypes } from 'src/graphql/Booking/types'

export const countHours = (data: BookingSummaryTypes[]) =>
  Math.floor(
    data
      .map(booking => booking._durationMinutes / 60)
      .reduce((firstNumber, secondNumber) => firstNumber + secondNumber, 0),
  )

export const countMinutesLeft = (data: BookingSummaryTypes[], hours: number) =>
  Math.floor(
    data.map(booking => booking._durationMinutes).reduce((firstNumber, secondNumber) => firstNumber + secondNumber, 0) -
      hours * 60,
  )
