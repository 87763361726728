export const onboardingSv = {
  accepted: 'Välkommen!',
  acceptedDescription:
    'Du har blivit intervjuad, gått igenom Loops, signerat avtal och visat upp ditt belastningsregister. Du kommer inom kort, här i appen, kunna se och ta uppdrag på skolor och förskolor.',
  address: 'Adress',
  academyButton: 'Starta utbildningen',
  academyButtonInfo: 'Utbildningen öppnas i webbläsaren.',
  academyCompletedInfo: 'Du är nu ett steg närmare ditt första uppdrag som vikarie för Humly!',
  academyCompletedTitle: 'Grymt jobbat med introduktionen!',
  academyInfoText:
    'Du är här för att du gjorde ett grymt jobb på intervjun och vi tror att du kommer att göra ett fantastiskt jobb som vikarie hos Humly. \n\nInnan du får tillgång till uppdragen vill vi att du genomför vår introduktionsutbildning. Här kommer du få gå igenom ett antal kortare delmoment för att du ska känna dig trygg & redo inför ditt första uppdrag. Du behöver inte göra klart alla delar vid ett och samma tillfälle, vi sparar dina framsteg.',
  academyTaskDescription:
    'Uppgiften kommer automatiskt rapporteras som slutförd efter att hela kursen genomförts via länken. Observera att det kan ta upp till 24 timmar från det att du slutfört utbildningen tills dess att uppgiftens status uppdateras här.',
  academyTitle: 'Vi är så glada att du tagit dig hit!⭐✌ ',
  areYouSureDeleteFile: 'Är du säker på att du vill radera denna fil?',
  bookInterview: 'Boka intervju',
  bookInterviewCompletedInfo1:
    'Under intervjun kommer vi vilja veta mer om dina erfarenheter och kompetenser.  Därför kan du, innan din intervju, fundera på vilka av dina tidigare erfarenheter samt kompetenser som skulle kunna vara meriterande för att jobba i skola eller förskola. ',
  bookInterviewCompletedInfo2:
    'Kompetenser som vi anser är viktiga i rollen som vikarie är bland annat pedagogisk förmåga, ledarskap och social förmåga. \n\nDu kanske har erfarenhet av att lära ut? Eller, är du en van talare som är trygg i att tala inför stora grupper? Möjligen har du även erfarenhet av att inta en ansvarsroll och är en grym ledare?✌️⭐',
  bookInterviewCompletedInfo3:
    'Vid eventuell ombokning/avbokning av intervju är det viktigt att du hör av dig i god tid genom att mejla oss på support@humly.io. Du hittar mer information i intervjubekräftelsen som du mottog via e-post.',
  bookInterviewCompletedSubTitle: 'Vilka kompetenser är viktiga som vikarie? 🧐',
  bookInterviewCompletedSubTitle2: 'Boka om eller avboka',
  bookInterviewCompletedTitle: 'Vi ser fram emot att träffa dig!',
  bookInterviewConfirmationCheckbox: 'Jag har bokat min intervjutid via länken, markera uppgiften som klar!',
  bookInterviewConfirmationInfo:
    'Du kommer även få ett bekräftelsemail för din bokade intervjutid med länk till mötet.',
  bookInterviewConfirmationModalTitle: 'Bekräfta att du bokat intervjutid',
  bookInterviewConfirmationModalDescription: 'Jag bekräftar att jag bokat en intervjutid.',
  bookInterviewTaskInfo:
    'Du som bokar en intervju och går vidare i processen förväntas ta ett uppdrag inom 30 dagar från det att intervjun har genomförts. Vi ser gärna att du som söker dig till rollen som vikarie är villig att jobba minst två uppdrag i månaden. Vi vill att du bedriver ett kontinuerligt arbete som vikarie.',
  bookInterviewDescription:
    'Du bokar en tid genom att trycka på knappen nedan. På hemsidan som öppnas kan du se våra rekryterares lediga tider och boka en intervjutid som passar dig. Intervjun tar ungefär 30 minuter. Då anmälan är bindande är det viktigt att du väljer ett datum där du på förhand säkerställt att du verkligen kan delta.',
  bookInterviewInfo:
    'Fanns det ingen ledig tid som passade dig?\nKolla gärna in igen imorgon. Vi publicerar regelbundet nya tider.',
  bookInterviewTitle: 'Dags att boka tid för videointervju!',
  birthDate: 'Födelsedatum',
  categoryTitle_profileInfo: 'Min profil',
  categoryTitle_qualifications: 'Utbildning & Erfarenheter',
  categoryTitle_references: 'Referenser',
  categoryTitle_getToKnowHumly: 'Lär känna Humly',
  categoryTitle_profileVerification: 'Belastningsregister & ID',
  categoryTitle_finalAgreements: 'Signera avtal',
  categoryTitle_contract: 'Signera avtal',
  categoryStatusCompleted: 'Klar',
  categoryStatusExpired: 'Förnyelse krävs',
  categoryStatusExpiresSoon: 'Går ut snart',
  categoryStatusEnabled: '%{numberOfCompletedTasks} av %{numberOfTasks} uppgifter',
  city: 'Ort',
  closeTask: 'Slutför uppgift',
  confirmationError:
    'Vi kunde inte ansluta till vår server för att spara din bekräftelse. Försök igen om ett par minuter.\n\nOm problemet kvarstår, kontakta vår Support för hjälp.',
  confirmationErrorTitle: 'Misslyckades att bekräfta',
  contractAlert: 'Din rekryterare kommer markera den här uppgiften som klar när du signerat avtalet.',
  contractDocumentInfo:
    'OBS: Avtalet har begränsad giltighet och måste signeras inom 7 dagar. Kontakta oss om du inte hann signera i tid och avtalet inte längre är giltigt så skickar vi ett nytt.',
  contractInfo:
    'Vi använder oss av ett digitalt system för avtal. I avtalet ska du fylla i person-, och bankuppgifter samt signera med Bank-ID. \n\nDu kommer få mer information om detta på mejl när det är aktuellt.',
  contractInfoSent:
    'Vi använder oss av ett digitalt system för avtal. I avtalet ska du fylla i person-, och bankuppgifter samt signera med Bank-ID. \n\nDu kan komma åt avtalet genom mejlet vi skickade till dig eller genom knappen nedan 👇',
  contractInfoSigned:
    'Vi har mottagit ditt signerade avtal. \n\nDu kan komma åt avtalet genom mejlet vi skickade till dig eller genom knappen nedan 👇',
  contractOpen: 'Öppna avtal',
  contractUrlNotAvailable:
    'Vi stötte på problem när vi skulle hämta ditt avtal från servern. Kontakta oss om du vill ha tillgång till ditt avtal så hjälper vi dig!',
  contractSentTitle: 'Nu är du snart redo för jobb!',
  contractSignedTitle: 'Avtal signerat!',
  complete_loops: 'Introduktionsutbildning',
  complete_loopsDescription:
    'Innan du får tillgång till alla uppdrag behöver du slutföra introduktionsutbildningen i vår akademi. Vi hoppas att utbildningen ger dig många värdefulla tips och verktyg inför din framtida roll som utbildare. När du är klar med utbildningen och alla andra steg i processen låses din användare upp och du kan börja arbeta på förskolor och skolor via appen.',
  contactDetails: 'Kontaktuppgifter',
  contract_sent: 'Signera avtal',
  contract_sentDescription:
    'I detta steg erbjuds du ett avtal. Avtalet är digitalt och fylls i online. Du når avtalet genom ett mejl som vi skickar till dig, eller via knappen nedan, som blir klickbar när vi har skickat avtalet till dig.\n\nI avtalet kommer du få skriva in dina person- och bankuppgifter, samt signera med bank-ID. Avtalet är giltigt och kan signeras i sju dagar. Om avtalet inte längre är giltigt så kontakta oss för att få ett nytt skickat till dig.',
  delete: 'Radera',
  deleteFile: 'Radera fil',
  email: 'Email',
  educationFormAlertInfo:
    'Den här informationen låstes när du skickade in den här uppgiften. Kontakta oss om du behöver göra ändringar.',
  educationFormAlertTitle: 'Ändringar har inaktiverats',
  educationFormCreditsLabel: 'Avklarade högskolepoäng',
  educationFormDeleteButton: 'Radera utbildning',
  educationFormDeleteConfirmationDetails:
    'Du kan inte ångra den här åtgärden. Informationen kommer tas bort permanent. \nÄr du säker på att du vill radera denna utbildning?',
  educationFormDeleteConfirmationTitle: 'Radera denna utbildning?',
  educationFormSchoolLabel: 'Namn på Universitet/Högskola',
  educationFormSchoolSelectLabel: 'Universitet/Högskola',
  educationFormSubjectLabel: 'Namn på Program eller Kurs',
  educationFormTitle: 'Lägg till utbildning',
  educationFormUniversityLabel: 'Universitet/Högskola',
  educationFormYearLabel: 'Examensår/Förväntat examensår',
  educationNewButton: 'Lägg till utbildning',
  educationUniversityInfo:
    'Jag intygar och har kontrollerat att jag lämnat sanningsenliga och korrekta uppgifter och när jag slutför uppgiften är jag medveten om att jag inte kan göra ändringar.',
  educationUniversityLabel:
    'Har du genomfört eller påbörjat en utbildning på universitet/högskola eller annan eftergymnasial utbildning? *',
  emergencyContactDetailsInfo:
    'Det är bra om vi har kontaktuppgifter till någon av dina anhöriga. Vi kommer endast kontakta personen om du skulle råka ut för något. Uppgifterna du anger kommer att lagras i vårt personalsystem. Det är därför viktigt att du har stämt av med din närmaste anhöriga att det är okej att vi sparar hens uppgifter, innan du fyller i detta. Om du inte vill ange någon anhörig kan du slutföra uppgiften utan att fylla i något.',
  emergencyContactName: 'Fullständigt namn',
  emergencyContactPhone: 'Telefonnummer till anhörig',
  emergencyContactRelationship: 'Anhörigs relation till dig',
  emergencyNameError: 'Ange personens fullständiga namn',
  emergencyRelationError: 'Ange personens relation till dig, t.ex. Förälder, Sambo etc',
  errorLettersOnly: 'Endast bokstäver (A-Ö), mellanslag, samt bindestreck (-) tillåtna',
  employmentHistoryTitle:
    'Vänligen lägg till dina erfarenheter under respektive kategori. När du lagt till allt slutför du uppgiften längst ned.',
  endDate: 'Slutdatum:',
  endDateOngoing: 'Pågående anställning',
  enterDuties: 'Arbetsuppgifter',
  enterEmployer: 'Arbetsgivare',
  enterLeavingReason: 'Anledning till att du slutade',
  enterRole: 'Roll',
  experienceFormTitle: 'Lägg till arbetslivserfarenhet',
  experienceSchoolButton: 'Lägg till arbete inom skola/förskola',
  experienceSchoolLabel: 'Arbete inom skola eller förskola',
  experienceOutsideSchoolButton: 'Lägg till annat arbete med barn/unga',
  experienceOutsideSchoolLabel: 'Arbete med barn/unga utanför skolan',
  experienceOtherButton: 'Lägg till arbete inom annat yrke',
  experienceOtherLabel: 'Övriga arbeten',
  experienceFormAlertTitle: 'Ändringar har inaktiverats',
  experienceFormAlertInfo:
    'Den här informationen låstes när du skickade in den här uppgiften. Kontakta oss om du behöver göra ändringar.',
  experienceDelete: 'Radera erfarenhet',
  experienceDeleteConfirmationTitle: 'Radera denna erfarenhet?',
  experienceDeleteConfirmationInfo:
    'Du kan inte ångra den här åtgärden. Informationen kommer tas bort permanent. \nÄr du säker på att du vill radera denna erfarenhet?',
  extraJob: 'Extrajobb',
  firstName: 'Förnamn',
  formDeleteError:
    'Vi kunde inte ansluta till vår server för att radera dessa uppgifter. Försök igen om ett par minuter.\n\nOm problemet kvarstår, kontakta vår Support för hjälp.',
  formDeleteErrorTitle: 'Misslyckades att radera',
  formSaveError:
    'Vi kunde inte ansluta till vår server för att spara dessa uppgifter. Försök igen om ett par minuter.\n\nOm problemet kvarstår, kontakta vår Support för hjälp.',
  formSaveErrorTitle: 'Misslyckades att spara',
  fullTime: 'Heltid',
  goBack: 'Gå tillbaka',
  highSchoolInfo:
    'Jag intygar och har kontrollerat att jag lämnat sanningsenliga och korrekta uppgifter och när jag slutför uppgiften är jag medveten om att jag inte kan göra ändringar.',
  highSchoolName: 'Gymnasieskola',
  highSchoolProgram: 'Gymnasieprogram',
  houseNumberAndStreet: 'Gatuadress',
  idVerificationAcceptedDocuments: 'Vi godkänner följande id-handlingar för att du ska kunna styrka din identitet:',
  idVerificationAcceptedDocumentsButton: 'Läs mer om godkända id-handlingar',
  idVerificationDescription2:
    'Vänligen ladda upp en bild på din ID-handling genom att använda knappen nedan. Tänk på att hela ID-handlingen ska synas tydligt på bilden samt att all text ska kunna utläsas tydligt',
  idVerificationDescription:
    'Eftersom vi inte har möjlighet att träffas fysiskt under rekryteringsprocessen behöver vi be dig att skicka in en giltig legitimation som vi kan koppla till ditt ramavtal och ditt utdrag ur Polisens belastningsregister. Bilden kommer att lagras säkert hos oss under den tid du arbetar på Humly, för att sedan raderas. Ingen obehörig kommer att få tillgång till dina uppgifter. Utöver att skicka in en ID-handling till oss, kommer du även behöva legitimera dig när du är ute på uppdrag.',
  idVerificationInputText: 'Personnummer',
  idVerificationInputTextError: 'Måste vara 12 siffror, ÅÅÅÅMMDDXXXX',
  idVerificationInputTextHelper: 'Format: ÅÅÅÅMMDDXXXX',
  idVerificationItem1: 'id-kort utfärdat av Skatteverket.',
  idVerificationItem2: 'svenskt pass med vinröd pärm.',
  idVerificationItem3: 'svenskt nationellt id-kort.',
  idVerificationItem4: 'svenskt körkort.',
  idVerificationItem5: 'svenskt SIS-märkt id-kort utfärdat av till exempel en bank, ett företag eller en myndighet.',
  idVerificationItem6: 'svenskt tjänstekort utfärdat av en statlig myndighet.',
  idVerificationTaskInfo:
    'Jag intygar och har kontrollerat att jag lämnat sanningsenliga och korrekta uppgifter och när jag slutför uppgiften är jag medveten om att jag inte kan göra ändringar.',
  idVerificationTitle1: '1. Ange ditt personnummer',
  idVerificationTitle2: '2. Ladda upp en bild på godkänd ID-handling',
  interview_booked: 'Intervju',
  interview_bookedDescription:
    'Din intervjutid är bokad! En av våra rekryterare har nu reserverat tid för dig så tänk på att va ute i god tid innan intervjun så du vet att uppkopplingen fungerar och du kan förbereda dig i lugn och ro. Vi ser verkligen fram emot att träffa dig!',
  interviewAlertInfo: 'Din rekryterare kommer markera den här uppgiften som klar om du går vidare från intervjun.',
  interviewCompletedInfo:
    'Vi tror du passar toppenbra för rollen som vikarie. Det som återstår innan du kan börja arbeta är att slutföra resterande uppgifter.\n\nSå snart alla uppgifter är klara kommer vårt Teacher Success team göra en sista kontroll av uppgifterna och sedan är du redo för uppdrag! ',
  interviewCompletedResponseInfo:
    'Vi utvärderar nu din ansökan och låter dig inom kort veta om du går vidare i processen.',
  interviewCompletedResponseTitle: 'Tack för din tid',
  interviewCompletedTitle: 'Tack för en bra intervju!',
  interviewInfo:
    'Intervjun genomförs online. Länk till videomötet hittar du i bekräftelsemejlet du fick när du bokade din tid.',
  interviewTip1: 'Var redo i god tid! På så vis har du tid på dig att lösa eventuella teknikstrul.',
  interviewTip2: 'Hitta en lugn och trygg plats där du och rekryteraren kan prata ostört.',
  interviewTip3:
    'Våra rekryterare har ett väldigt pressat schema, så skulle något krångla är det jätteviktigt att du ringer till vår support direkt. Annars finns risken att intervjun avbryts om du inte dyker upp inom utsagd tid.',
  interviewTip4: 'Våga sälj in dig själv! Vad gör dig till en grym vikarie?',
  interviewTip5:
    'Känn dig trygg med att ställa frågor till rekryteraren. Intervjun är ämnad till att vi ska lära känna varandra och det finns inga dumma frågor.',
  interviewTip6:
    'På Humly behöver man kunna arbeta minst två pass i månaden. Hur ofta vill du arbeta och när är du sugen på att börja? Dessa frågor kan vara bra att fundera på redan innan intervjun.',
  interviewTip7:
    'Det är helt naturligt att vara nervös inför en intervju, våra rekryterare är dock världens snällaste och syftet med intervjun är enbart att lära känna varandra. Så slappna av och försök att inte tänka för mycket på vad som är rätt/fel svar.',
  interviewTipTitle: 'Tips inför intervjun:',
  invited_loops: 'Introduktion till Humly',
  invited_loopsDescription:
    'Vi har valt ut din ansökan och vill träffa dig för en intervju så snart som möjligt. Du bokar en intervjutid genom att trycka på knappen nedan och sedan välja kursen “Boka din intervjutid!”. Knappen nedan öppnas när vi har valt att gå vidare med din ansökan.',
  lastName: 'Efternamn',
  lockedTask_completeInterview: 'Låses upp om du går vidare från intervjun',
  lockedTask_completeInterviewModalInfo: `Vi kommer låsa upp denna uppgift efter att vi träffat dig under intervjun.\n\nHar du inte bokat din intervju än? Ta reda på hur i kategorin "Lär känna Humly"`,
  lockedTask_modalTitle: 'Den här uppgiften är låst',
  lockedTask_qualificationsAndExperience: 'Låses upp när vi kontrollerat dina erfarenheter',
  lockedTask_qualificationsAndExperienceModalInfo:
    'Vänligen slutför uppgifterna i kategorin Utbildning & Erfarenheter för att kunna gå vidare i processen. \n\nHar du redan slutfört uppgifterna?\nSnyggt, vi kommer granska dina uppgifter och återkomma så snart vi kan.\n\nTips:\nJu fler uppgifter du slutför, desto enklare är det för oss att få en tydlig och korrekt uppfattning av Dig och din profil som utbildare.',
  new: 'Ansökan mottagen',
  nextState: 'NÄSTA',
  onlyNumbersAllowed: 'Endast siffror',
  otherDocumentsCheckbox: 'Jag har inga övriga dokument att ladda upp.',
  otherDocumentsDescription:
    'Om du har övriga dokument som inte passar i någon av de andra kategorierna kan du ladda upp dessa nedan. Har du inga övriga dokument kan du kryssa i detta för att kunna slutföra uppgiften.',
  otherDocumentsSubinfo:
    'Jag intygar och har kontrollerat att jag lämnat sanningsenliga och korrekta uppgifter och när jag slutför uppgiften är jag medveten om att jag inte kan göra ändringar.',
  phoneNumber: 'Telefonnummer',
  uploadFile: 'Ladda upp filer',
  uploadFiles: 'Ladda upp filer',
  partTime: 'Deltid',
  policesCheckbox: 'Jag intygar att jag läst och godkänner Humlys Policys',
  policesDownloadButton: 'Ladda ner policys',
  policesInfo:
    'För att förbereda dig så bra som möjligt har vi, förutom vår onlineutbildning i Akademin, även sammanställt de viktigaste policys och riktlinjer som du behöver känna till som vikarie på Humly.\n\nVänligen läs igenom dessa noggrant och markera sedan att du har tagit till dig och godkänner innehållet.',
  prevState: 'FÖRRA',
  profileTaskStatusCompleted: 'Klar',
  profileTaskStatusCompletedDescription: 'Allt klart här!',
  profileTaskStatusExpired: 'Förnyelse krävs',
  profileTaskStatusExpiredDescription: 'Uppdatering krävs!',
  profileTaskStatusExpiredShort: 'Inaktuell',
  profileTaskStatusExpiresSoon: 'Går ut snart',
  profileTaskStatusToDo: 'Att göra',
  profileTaskStatusToDoDescription: '%{timeToComplete} kvar',
  progressDone: '%{numberOfTasks} Avklarat',
  progressTasks: '%{numberOfTasks} uppgifter',
  proofOfEducationForDig: 'För dig som:\n',
  proofOfEducationInfo:
    'Jag intygar och har kontrollerat att jag lämnat sanningsenliga och korrekta uppgifter och när jag slutför uppgiften är jag medveten om att jag inte kan göra ändringar.',
  proofOfEducationIsQualifiedChildtaker: 'Jag är behörig barnskötare och har licens/examensbevis.',
  proofOfEducationIsQualifiedTeacher: 'Jag är examinerad lärare eller förskollärare och har lärarlegitimation.',
  proofOfEducationMainCheckbox: 'Jag har inga studieintyg att ladda upp.',
  proofOfEducationMainCheckboxText:
    'Inte studerar/studerat på högskola, eller har en slutförd pedagogisk utbildning på gymnasienivå:',
  proofOfEducationMainInfo:
    'Tänk på att du som har påbörjade/slutförda högskolestudier och/eller slutfört en pedagogisk gymnasial utbildning, t.ex. som Barnskötare eller Elevassistent, måste ladda upp ditt studieintyg eller examensbevis.',
  proofOfEducationMainTitle:
    'Här vill vi att du laddar upp studieintyg som styrker de utbildningar du angett. All akademisk bakgrund är meriterande, inte endast pedagogisk utbildning. Vänligen se till att du har inkommit med samtliga dina intyg.',
  proofOfEducationTeacherEducation: 'Har du slutfört en pedagogisk utbildning?',
  proofOfEducationUploader: 'Har studieintyg, lärarlegitimation, eller andra licenser/examensbevis att ladda upp:',
  proofOfEducationUploaderAwaitingUpload:
    'Jag har studieintyg, lärarlegitimation, eller andra licenser/examensbevis att ladda upp:',
  proofOfPoliceReportExpired: 'Utdraget upphörde att gälla %{date}',
  proofOfPoliceReportSoonToExpire: 'Utdraget upphör att gälla %{date}',
  proofOfPoliceReportStep1Button: 'Beställ utdrag',
  proofOfPoliceReportStep1Info:
    'För att få arbeta i den svenska skolan krävs att du uppvisar ett utdrag ur belastningsregistret. Utdrag för arbete inom skola eller förskola beställs på polisens hemsida. Har du inte ett utdrag redan är det hög tid att beställa detta. Använd knappen nedan 👇',
  proofOfPoliceReportStep1SubInfo: 'Tänk på att det kan ta upp till två veckor att få utdraget hemskickat.',
  proofOfPoliceReportStep1Title: 'Steg 1: Beställ utdrag',
  proofOfPoliceReportStep2Info:
    'När du fått hem ditt utdrag från Polisen ska du fotografera detta och ladda upp bilden. Tänk på att hela dokumentet ska synas i bild samt att text och vattenstämpel ska kunna utläsas tydligt.',
  proofOfPoliceReportStep2SubInfo: 'OBS: Utdraget får vara max 8 månader gammalt.',
  proofOfPoliceReportStep2Title: 'Steg 2: Ladda upp utdrag',
  proofOfPoliceReportTaskInfo:
    'Jag intygar och har kontrollerat att jag lämnat sanningsenliga och korrekta uppgifter och när jag slutför uppgiften är jag medveten om att jag inte kan göra ändringar.',

  referencesCompletedHeader: 'Tack för att du uppgav dina referenser!',
  referencesCompleted1:
    'Vi har nu kontrollerat svaren från dina referenser. Baserat på svaren från dessa vill vi gärna gå vidare med dig i rekryteringsprocessen ✌️',
  referencesCompleted2:
    'Har du uppgifter kvar ber vi dig slutföra dessa så snart som möjligt. Så fort alla uppgifter är klara kommer vi göra en sista kontroll av din profil och sedan är det fritt fram att börja söka uppdrag!',
  referencesInvitedHeader: 'Bjud in dina referenser!',
  referencesInvitedInfo:
    'Har du inte fått något mejl?\nFörst och främst,  prova att söka efter “Refensa” i din mejl. Kontrollera också din mejl-skräpkorg, ibland hamnar mejlet där. Kan du fortfarande inte hitta det kan du kontakta oss så skickar vi ett nytt!',
  referencesUncompletedAlert:
    'Din rekryterare kommer markera den här uppgiften som klar när referenstagningen är genomförd och godkänd.',
  referencesUncompleted1:
    'Som en del i rekryteringsprocessen kommer du behöva uppge referenser. Om du har erfarenhet av arbete inom skola eller förskola ska referensen vara därifrån. Om du inte har erfarenhet av arbete inom skola/förskola men har annan relevant erfarenhet av pedagogiskt arbete fungerar det som ett andrahandsval.',
  referencesUncompleted2:
    'Tänk på att det är viktigt att referenspersonen har haft en arbetsledande position, till exempel arbetsledare eller chef.  När du lämnar kontaktuppgifter till din referensperson ska mejladressen kunna kopplas till den arbetsplats referensen representerar (lämna inga privata mailadresser såsom @gmail, @hotmail etc.). Det är också viktigt att du arbetat med dina referenser på någon av dina senaste arbetsplatser.',
  referencesUncompletedInvited:
    'Som en del i rekryteringsprocessen behöver du nu uppge referenser.  Vi har mejlat information till dig som beskriver hur du ska göra.\n\nOm du har erfarenhet av arbete inom skola eller förskola ska referensen vara därifrån. Om du inte har erfarenhet av arbete inom skola/förskola men har annan relevant erfarenhet av pedagogiskt arbete fungerar det som ett andrahandsval.\n\nTänk på att det är viktigt att referenspersonen har haft en arbetsledande position, till exempel arbetsledare eller chef.  När du lämnar kontaktuppgifter till din referensperson ska mejladressen kunna kopplas till den arbetsplats referensen representerar (lämna inga privata mailadresser såsom @gmail, @hotmail etc.). Det är också viktigt att du arbetat med dina referenser på någon av dina senaste arbetsplatser.',
  response: 'Feedback efter intervju',
  saveChanges: 'Spara ändringar',
  selectWorkload: 'Omfattning',
  serverError:
    'Vi kunde inte ansluta till vår server för att skicka in den här uppgiften. Försök igen om ett par minuter.\n\nOm problemet kvarstår, kontakta vår Support för hjälp.',
  serverErrorTitle: 'Problem med att ansluta till servern',
  startDate: 'Startdatum:',
  stateContactTitle: 'Några frågor? Tveka inte att kontakta oss!',
  stepsDescription: 'Steg till att börja ta uppdrag:',
  street: 'Gatuadress',
  summerJob: 'Sommarjobb',
  taskConfirmationExperience: 'Jag har lagt till alla relevanta arbetslivserfarenheter.',
  taskConfirmationEducation: 'Jag har lagt till alla relevanta utbildningar.',
  taskInfoDeclaraction:
    'Jag intygar och har kontrollerat att jag lämnat sanningsenliga och korrekta uppgifter och när jag slutför uppgiften är jag medveten om att jag inte kan göra ändringar.',
  taskTitle_addressVerification: 'Verify Address',
  taskTitle_bookInterview: 'Boka intervju',
  taskTitle_childCareCertificate: '',
  taskTitle_contract: 'Signera avtal',
  taskTitle_contractAgreement: '',
  taskTitle_cvDocument: 'CV',
  taskTitle_dbsCertificate: '',
  taskTitle_dbsDeclaration: '',
  taskTitle_disqualificationDeclaration: '',
  taskTitle_emergencyContact: 'Kontaktuppgifter till anhörig',
  taskTitle_employmentHistory: 'Arbetslivserfarenheter',
  taskTitle_highSchoolEducation: 'Gymnasieutbildning',
  taskTitle_humlyAcademy: 'Introduktionsutbildning',
  taskTitle_idVerification: 'Verifiera ID',
  taskTitle_interview: 'Intervju',
  taskTitle_medicalQuestionnaire: '',
  taskTitle_niNumber: '',
  taskTitle_otherDocuments: 'Övriga dokument',
  taskTitle_payrollDetails: '',
  taskTitle_personalDetails: 'Personuppgifter',
  taskTitle_personalProfile: '',
  taskTitle_policies: 'Humly Policys',
  taskTitle_policyAgreements: '',
  taskTitle_proofOfEducations: 'Studieintyg',
  taskTitle_proofOfPoliceCheck: '',
  taskTitle_proofOfPoliceReport: 'Utdrag ur belastningsregistret',
  taskTitle_proofOfRightToWork: '',
  taskTitle_qualifications: '',
  taskTitle_references: 'Referenser',
  taskTitle_safeguardingDeclarations: '',
  taskTitle_teacherReferenceNumber: '',
  taskTitle_teachingCertificates: '',
  taskTitle_universityEducation: 'Eftergymnasial utbildning',
  taskTitle_yotiStatus: 'ID verification - YOTI ',
  tasksCompleted: 'Avklarade',
  tasksTodo: 'Att göra',
  universityGraduation: 'När slutfördes studierna?',
  waiting_police_report: 'Belastningsregister',
  zip: 'Postnummer',
}
