import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  divider: {
    width: paddingNew.md,
  },
  error: {
    ...typography.helperText,
    color: colorsNew.redesign.error.main,
    top: -paddingNew.md,
  },
  input: {
    flex: 1,
  },
  inputContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  inputContainerWeb: {
    marginBottom: paddingNew.sm,
  },
  title: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
