import React, { useContext, useState } from 'react'
import { View, Text, Keyboard, Platform } from 'react-native'
import { TouchableWithoutFeedback } from 'react-native-gesture-handler'

import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import ButtonNew from 'src/components/Buttons/ButtonNew'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import ReportErrorModal from 'src/components/ModalTypes/ReportErrorModal'
import { acceptProposal } from 'src/store/longTermBookings/actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'

const isWeb = Platform.OS === 'web'

type Props = {
  applicationContent: string
  closeModal: () => void
  onRequestSuccess: () => void
  proposalId: string
  setApplicationContent: (text: string) => void
  showApplicationForm: boolean
}

const ApplicationForm = ({
  applicationContent,
  closeModal,
  onRequestSuccess,
  proposalId,
  setApplicationContent,
  showApplicationForm,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const { mixpanel } = useContext(MixpanelContext)

  const onRequestFailed = (responseError: string) => {
    setIsSubmitting(false)
    setError(responseError)
  }

  const sendApplication = () => {
    setIsSubmitting(true)
    acceptProposal(proposalId, applicationContent, onRequestSuccess, onRequestFailed)
    mixpanel?.track('Apply Proposal', { proposalId })
  }

  if (error) {
    return (
      <ReportErrorModal
        description={translate(translations.errorReportApplicationFailed)}
        error={error}
        onModalClose={() => setError(null)}
        showModal={showApplicationForm}
        title={translate(translations.errorReportTitle)}
      />
    )
  }

  return (
    <NewModalWrapper
      backdropOpacity={0.25}
      containerStyle={styles.modalContent}
      isVisible={showApplicationForm}
      onBackdropPress={Keyboard.dismiss}
      onSwipeComplete={closeModal}
      propagateSwipe
      style={styles.modal}
      swipeDirection={['down']}
    >
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.divider} />
          <Text style={styles.header}>{translate(translations.applicationFormHeader)}</Text>
          <Text style={styles.description}>{translate(translations.applicationFormDescription)}</Text>
        </TouchableWithoutFeedback>
        <TextInputNew
          blurOnSubmit={isWeb ? undefined : true}
          label={translate(translations.applicationFormPlaceholder)}
          multiline
          onChangeText={setApplicationContent}
          size={TextInputSizeVariants.vBig}
          testID="application-input"
          value={applicationContent}
        />
        <View style={styles.buttonsContainer}>
          <ButtonNew isLong onPress={closeModal} title={translate(translations.cancel)} variant="outlined-default" />
          <View style={styles.buttonsDivider} />
          <ButtonNew
            disabled={!applicationContent}
            isSubmitting={isSubmitting}
            isLong
            onPress={sendApplication}
            title={translate(translations.applicationFormSubmit)}
            variant="contained-default"
            testID="apply-button"
          />
        </View>
      </View>
    </NewModalWrapper>
  )
}

export default ApplicationForm
