import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import Animated, { ZoomIn } from 'react-native-reanimated'

import { MessageIcon } from 'src/icons'
import { isGBSelector } from 'src/store/app/selectors'
import styles from 'src/scenes/Welcome/ResetPassword/styles'
import translations, { translate } from 'src/utils/translations/translations'
import { colorsNew } from 'src/styles'

const ResetPasswordSuccess = () => {
  const isGB = useSelector(isGBSelector)

  return (
    <Animated.View entering={ZoomIn} style={styles.successMessageWrapper}>
      <View style={styles.iconWrapper}>
        <MessageIcon size={84} fill={colorsNew.redesign.secondary.main} />
      </View>
      <Text style={styles.header}>{translate(translations.resetPasswordConfirmationHeader)}</Text>
      <Text style={StyleSheet.flatten([styles.confirmationInfo, isGB && styles.confirmationInfoShort])}>
        {translate(translations.resetPasswordConfirmation)}
      </Text>
    </Animated.View>
  )
}

export default ResetPasswordSuccess
