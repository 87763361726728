import React from 'react'
import Svg, { Path, Circle } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const QuestionIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Circle cx="12" cy="12" r="10" fill={fill} />
    <Path
      d="M9.507 9.38947C9.04649 9.21053 8.85077 8.68947 9.12708 8.31053C9.68545 7.55263 10.618 7 11.8499 7C13.2026 7 14.1294 7.56316 14.6014 8.26842C15.0044 8.87368 15.2404 10.0053 14.6187 10.8474C13.9279 11.7789 13.2659 12.0632 12.909 12.6632C12.8227 12.8053 12.7709 12.9211 12.7363 13.1579C12.6845 13.5421 12.3391 13.8421 11.9132 13.8421C11.4124 13.8421 11.0037 13.4474 11.0612 12.9895C11.0958 12.7211 11.1648 12.4421 11.326 12.1789C11.7693 11.4474 12.6212 11.0158 13.1163 10.3684C13.6401 9.68947 13.3465 8.42105 11.8614 8.42105C11.1879 8.42105 10.7504 8.74211 10.4798 9.12632C10.2784 9.42632 9.85814 9.52105 9.507 9.38947ZM13.0069 15.9474C13.0069 16.5263 12.4888 17 11.8556 17C11.2224 17 10.7043 16.5263 10.7043 15.9474C10.7043 15.3684 11.2224 14.8947 11.8556 14.8947C12.4888 14.8947 13.0069 15.3684 13.0069 15.9474Z"
      fill="white"
    />
  </Svg>
)

export default QuestionIcon
