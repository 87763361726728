import gql from 'graphql-tag'

export default gql`
  query OrderList(
    $limit: Int
    $maxDate: String
    $minDate: String
    $offset: Int
    $region: String
    $school: String
    $sort: String
    $student: String
    $multiDay: Boolean
    $appliedByMe: Boolean
    $matching: Boolean
  ) {
    orders(
      limit: $limit
      offset: $offset
      sort: $sort
      filter: {
        appliedByMe: $appliedByMe
        matching: $matching
        maxDate: $maxDate
        minDate: $minDate
        multiDay: $multiDay
        region: $region
        school: $school
        student: $student
      }
    ) {
      nodes {
        appointed
        appointmentUnconfirmed
        appointmentType
        bookings {
          _durationMinutes
          cancelled
          endTime
          id
          startTime
          type
        }
        cancelled
        competences {
          id
          name
          ref
        }
        confirmed
        endDate
        hoursPerWeek
        id
        isMatching
        jobTitle
        multiDay
        region {
          countryCode
          id
          name
        }
        rejected
        requestedSubs {
          id
        }
        school {
          name
        }
        startDate
        student {
          id
          name
        }
        studentApplications {
          claimedAt
          studentId
        }
        topics {
          title
        }
      }
    }
  }
`
