import { Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography, utils } from 'src/styles'

export const ICON_SMALL = 18
export const ICON_LARGE = 24

export default StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 25,
    borderWidth: 1,
    flexDirection: 'row',
    height: 40,
    justifyContent: 'center',
    marginVertical: paddingNew.xxs,
    padding: 0,
    paddingHorizontal: paddingNew.sm,
    ...Platform.select({
      web: { cursor: 'pointer' },
    }),
  },
  label: {
    ...typography.buttonLarge,
    textAlign: 'center',
  },
  long: {
    flex: 1,
  },
  short: {
    flexDirection: 'row',
    flex: 0,
  },
  smallButton: {
    height: 30,
  },
  smallLabel: {
    ...typography.buttonSmall,
  },
  transparent: {
    backgroundColor: colorsNew.transparent,
  },
  rightIcon: {
    paddingLeft: paddingNew.xs,
  },
  leftIcon: {
    paddingRight: paddingNew.xs,
  },
  submittingIcon: {
    paddingRight: paddingNew.md,
  },
  webInputUploader: {
    cursor: 'pointer',
    height: '100%',
    opacity: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: '99',
  },
})

const disabledStyles = {
  contained: {
    button: {
      backgroundColor: colorsNew.redesign.action.disabledBackground,
      borderColor: colorsNew.transparent,
    },
    label: {
      color: colorsNew.redesign.action.disabled,
    },
  },
  outlined: {
    button: {
      backgroundColor: colorsNew.redesign.background.paper,
      borderColor: colorsNew.redesign.action.disabledBackground,
    },
    label: {
      color: colorsNew.redesign.action.disabled,
    },
  },
  text: {
    button: {
      backgroundColor: colorsNew.redesign.background.paper,
      borderColor: colorsNew.transparent,
      borderRadius: 4,
    },
    label: {
      color: colorsNew.redesign.action.disabled,
    },
  },
}

// ----- contained buttons -----

export const containedDefaultStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.primary.main,
    borderColor: colorsNew.redesign.primary.main,
    ...utils.shadow('button', colorsNew.black),
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.primary.dark,
    borderColor: colorsNew.redesign.primary.dark,
  },
  label: {
    color: colorsNew.redesign.primary.contrast,
  },
  buttonDisabled: disabledStyles.contained.button,
  labelDisabled: disabledStyles.contained.label,
})

export const containedErrorStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.error.main,
    borderColor: colorsNew.redesign.error.main,
    ...utils.shadow('button', colorsNew.black),
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.error.dark,
    borderColor: colorsNew.redesign.error.dark,
  },
  label: {
    color: colorsNew.redesign.error.contrast,
  },
  buttonDisabled: disabledStyles.contained.button,
  labelDisabled: disabledStyles.contained.label,
})

export const containedInfoStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.info.main,
    borderColor: colorsNew.redesign.info.main,
    ...utils.shadow('button', colorsNew.black),
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.info.dark,
    borderColor: colorsNew.redesign.info.dark,
  },
  label: {
    color: colorsNew.redesign.info.contrast,
  },
  buttonDisabled: disabledStyles.contained.button,
  labelDisabled: disabledStyles.contained.label,
})

export const containedSuccessStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.success.main,
    borderColor: colorsNew.redesign.success.main,
    ...utils.shadow('button', colorsNew.black),
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.success.dark,
    borderColor: colorsNew.redesign.success.dark,
  },
  label: {
    color: colorsNew.redesign.success.contrast,
  },
  buttonDisabled: disabledStyles.contained.button,
  labelDisabled: disabledStyles.contained.label,
})

export const containedWarningStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.warning.main,
    borderColor: colorsNew.redesign.warning.main,
    ...utils.shadow('button', colorsNew.black),
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.warning.dark,
    borderColor: colorsNew.redesign.warning.dark,
  },
  label: {
    color: colorsNew.redesign.warning.contrast,
  },
  buttonDisabled: disabledStyles.contained.button,
  labelDisabled: disabledStyles.contained.label,
})

// ----- outlined buttons -----

export const outlinedDefaultStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.secondary.shades50p,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.primary.shades8p,
    borderColor: colorsNew.redesign.secondary.shades50p,
  },
  label: {
    color: colorsNew.redesign.secondary.main,
  },
  buttonDisabled: disabledStyles.outlined.button,
  labelDisabled: disabledStyles.outlined.label,
})

export const outlinedErrorStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.error.dark,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.error.shades12p,
    borderColor: colorsNew.redesign.error.dark,
  },
  label: {
    color: colorsNew.redesign.error.dark,
  },
  buttonDisabled: disabledStyles.outlined.button,
  labelDisabled: disabledStyles.outlined.label,
})

export const outlinedInfoStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.info.dark,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.info.shades12p,
    borderColor: colorsNew.redesign.info.dark,
  },
  label: {
    color: colorsNew.redesign.info.dark,
  },
  buttonDisabled: disabledStyles.outlined.button,
  labelDisabled: disabledStyles.outlined.label,
})

export const outlinedSuccessStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.success.dark,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.success.shades12p,
    borderColor: colorsNew.redesign.success.dark,
  },
  label: {
    color: colorsNew.redesign.success.dark,
  },
  buttonDisabled: disabledStyles.outlined.button,
  labelDisabled: disabledStyles.outlined.label,
})

export const outlinedWarningStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.warning.dark,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.warning.shades12p,
    borderColor: colorsNew.redesign.warning.dark,
  },
  label: {
    color: colorsNew.redesign.warning.dark,
  },
  buttonDisabled: disabledStyles.outlined.button,
  labelDisabled: disabledStyles.outlined.label,
})

// ----- text buttons -----

export const textDefaultStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.primary.shades8p,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  label: {
    color: colorsNew.redesign.secondary.main,
  },
  buttonDisabled: disabledStyles.text.button,
  labelDisabled: disabledStyles.text.label,
})

export const textErrorStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.error.shades12p,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  label: {
    color: colorsNew.redesign.error.dark,
  },
  buttonDisabled: disabledStyles.text.button,
  labelDisabled: disabledStyles.text.label,
})

export const textInfoStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.info.shades12p,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  label: {
    color: colorsNew.redesign.info.dark,
  },
  buttonDisabled: disabledStyles.text.button,
  labelDisabled: disabledStyles.text.label,
})

export const textSuccessStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.success.shades12p,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  label: {
    color: colorsNew.redesign.success.dark,
  },
  buttonDisabled: disabledStyles.text.button,
  labelDisabled: disabledStyles.text.label,
})

export const textWarningStyles = StyleSheet.create({
  button: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.warning.shades12p,
    borderColor: colorsNew.redesign.background.paper,
    borderRadius: 4,
  },
  label: {
    color: colorsNew.redesign.warning.dark,
  },
  buttonDisabled: disabledStyles.text.button,
  labelDisabled: disabledStyles.text.label,
})
