import React from 'react'
import { Text, View } from 'react-native'

import { EditLocationIcon } from 'src/icons'
import { iconButtonSize, iconButtonVariant } from 'src/components/Buttons/IconButton/variants'
import IconButton from 'src/components/Buttons/IconButton'

import styles from './styles'

type Props = {
  city: string
  navigateToEdit: () => void
  street: string
  zip: string
}

const AddressRow = ({ city, zip, street, navigateToEdit }: Props) => (
  <View style={styles.addressRowContainer}>
    <Text style={styles.addressInfo}>
      {street}, {zip}, {city}
    </Text>
    <IconButton
      Icon={EditLocationIcon}
      onPress={navigateToEdit}
      size={iconButtonSize.lg}
      variant={iconButtonVariant.filled}
    />
  </View>
)

export default AddressRow
