import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import { AppScreenProps } from 'src/utils/types/navigationTypes'
import { BookingTypes } from 'src/graphql/Booking/types'
import { isGBSelector } from 'src/store/app/selectors'
import { jobOfferItemData } from 'src/scenes/Main/Jobs/Lists/utils'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ShortTermOfferItem from 'src/scenes/Main/Jobs/Rows/ShortTermOfferItem'

type Props = {
  item: BookingTypes
}

export const ShortBookingRow = ({ item }: Props) => {
  const isConnected = useAppSelector(state => state?.app?.isConnected)
  const isGB = useSelector(isGBSelector)
  const navigation = useNavigation<AppScreenProps<'ReportTime'>['navigation']>()

  const { _durationMinutes, durationMinutesToCount } = item

  const onPress = () => {
    if (isConnected === undefined || isConnected === true) {
      const extraData =
        (durationMinutesToCount as number) > _durationMinutes
          ? { durationMinutesToCount: durationMinutesToCount }
          : null
      navigation.navigate('ReportTime', { id: item.id, ...extraData })
    }
  }

  return <ShortTermOfferItem {...jobOfferItemData(item, isGB, true)} onPress={onPress} />
}

export default ShortBookingRow
