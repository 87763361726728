import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  button: {
    marginBottom: paddingNew.xs,
    width: '100%',
  },
  container: {
    alignItems: 'center',
    paddingHorizontal: paddingNew.sm,
  },
  description: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.md,
  },
  title: {
    ...typography.h7,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
  },
})

export default styles
