import React, { ElementType } from 'react'
import { Pressable, Text, View } from 'react-native'

import { ChevronRightIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { IconTypes } from 'src/icons/iconTypes'

import styles from './styles'

type TProps = {
  Icon: ElementType<IconTypes>
  onPress: () => void
  subtitle?: string
  testId?: string
  title: string
}

const RedirectRow = ({ Icon, onPress, subtitle, testId, title }: TProps) => (
  <Pressable
    onPress={onPress}
    testID={testId}
    style={({ pressed }) => (pressed ? styles.buttonPressed : styles.detailsButton)}
  >
    <>
      <View style={styles.iconContainer}>
        <Icon fill={colorsNew.redesign.text.primary} size={24} />
      </View>
      <View style={styles.rowContainer}>
        <Text style={styles.title} numberOfLines={1}>
          {title}
        </Text>
        {subtitle ? <Text style={styles.subtitle}>{subtitle}</Text> : null}
      </View>
      <ChevronRightIcon size={24} fill={colorsNew.redesign.action.active} />
    </>
  </Pressable>
)

export default RedirectRow
