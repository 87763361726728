import { StyleSheet } from 'react-native'
import { colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderColor: colorsNew.redesign.action.active,
    borderRadius: 50,
    borderWidth: 1,
    height: 48,
    justifyContent: 'center',
    width: 48,
  },
})

export default styles
