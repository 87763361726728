import { TTypes } from './types'
import { buttonVariants } from './variants'
import {
  containedDefaultStyles,
  containedErrorStyles,
  containedInfoStyles,
  containedSuccessStyles,
  containedWarningStyles,
  outlinedDefaultStyles,
  outlinedErrorStyles,
  outlinedInfoStyles,
  outlinedSuccessStyles,
  outlinedWarningStyles,
  textDefaultStyles,
  textErrorStyles,
  textInfoStyles,
  textSuccessStyles,
  textWarningStyles,
} from './styles'

export const getButtonStyles = (variant: TTypes) => {
  switch (variant) {
    case buttonVariants.containedError:
      return containedErrorStyles
    case buttonVariants.containedDefault:
      return containedDefaultStyles
    case buttonVariants.containedInfo:
      return containedInfoStyles
    case buttonVariants.containedSuccess:
      return containedSuccessStyles
    case buttonVariants.containedWarning:
      return containedWarningStyles
    case buttonVariants.outlinedError:
      return outlinedErrorStyles
    case buttonVariants.outlinedInfo:
      return outlinedInfoStyles
    case buttonVariants.outlinedDefault:
      return outlinedDefaultStyles
    case buttonVariants.outlinedSuccess:
      return outlinedSuccessStyles
    case buttonVariants.outlinedWarning:
      return outlinedWarningStyles
    case buttonVariants.textError:
      return textErrorStyles
    case buttonVariants.textInfo:
      return textInfoStyles
    case buttonVariants.textDefault:
      return textDefaultStyles
    case buttonVariants.textSuccess:
      return textSuccessStyles
    case buttonVariants.textWarning:
      return textWarningStyles
    default:
      return undefined as never
  }
}
