import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const EllipsisVerticalIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="-3 0 13 16" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M-8.74228e-08 14C-3.93402e-08 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12C0.9 12 -1.35505e-07 12.9 -8.74228e-08 14ZM-6.11959e-07 2C-5.63877e-07 3.1 0.899999 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 -1.35505e-07 2 -8.74228e-08C0.899999 -3.93403e-08 -6.60042e-07 0.9 -6.11959e-07 2ZM-3.49691e-07 8C-3.01609e-07 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6C0.9 6 -3.97774e-07 6.9 -3.49691e-07 8Z" />
  </Svg>
)

export default EllipsisVerticalIcon
