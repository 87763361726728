import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ThumbsUpIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M14.26 20.072h-3.99c-.677 0-1.226.548-1.226 1.224l-.017 14.599c-.001.678.548 1.228 1.226 1.228h4.397c.26 0 .511.082.74.206.793.43 2.737 1.268 6.336 2.346 5.39 1.615 6.72 1.522 9.825 1.522 2.741 0 3.82-2.47 3.434-3.841.381-.089 3.059-.825 3.514-2.904.303-1.383-.135-3.173-.969-3.315.381-.089 2.152-1.336 2.152-3.462 0-2.125-1.669-3.21-2.503-3.352.38-.09 1.142-.798 1.142-2.923 0-2.126-1.668-2.94-2.503-3.082h-9.305c.562-1.612 1.687-5.043 1.687-5.872 0-1.036-.463-2.657-1.687-3.852-.658-.642-1.802-.937-2.758-1.054-.658-.08-1.187.465-1.187 1.129v1.711a4.905 4.905 0 0 1-1.479 3.51l-5.973 5.832c-.229.224-.537.35-.857.35Z" />
  </Svg>
)

export default ThumbsUpIcon
