import React, { useEffect, useRef, useState, useCallback } from 'react'
import omit from 'lodash/omit'
import { Formik, FormikHelpers } from 'formik'
import { View, ScrollView } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import AvailabilityForm from './AvailabilityForm'
import ButtonNew from 'src/components/Buttons/ButtonNew'
import styles from './styles'
import SuccessAnimation from 'src/components/SuccessAnimation'
import translations, { translate } from 'src/utils/translations/translations'
import { getInitialData, updateLongTermAvailability } from './utils'
import { validationSchema } from './validationSchema'
import { useFocusEffect } from '@react-navigation/native'
import { useAppState } from 'src/utils/AppStateEvent'
import { TimeScheduleTypes } from 'src/graphql/sharableTypes'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import globalStyles from 'src/global/globalStyles'

export type InitialValuesTypes = {
  applyTo: 'selected' | 'all_days'
  available: boolean
  endDate?: string
  endTime: null | string
  selectedDays: number[]
  startDate?: string
  startTime: null | string
  timeScheduleType: TimeScheduleTypes
}

const REDIRECT_TIME = 2000

const Availability = () => {
  const timer = useRef<NodeJS.Timeout>()

  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false)
  const [showAlert, setShowAlert] = useState<boolean | null>(false)
  const [initialValues, setInitialValues] = useState(getInitialData())

  const updateInitialState = () => {
    const updatedInitialData = getInitialData()
    setInitialValues(updatedInitialData)
  }

  const isAlertShowed = async () => {
    const isShowed = await AsyncStorage.getItem('isAvailabilityTipShowed')
    setShowAlert(!isShowed)
  }

  const setCloseAlert = async () => {
    await AsyncStorage.setItem('isAvailabilityTipShowed', 'true')
    setShowAlert(false)
  }

  useFocusEffect(
    useCallback(() => {
      updateInitialState()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setInitialValues]),
  )

  useAppState({
    onForeground: updateInitialState,
  })

  useEffect(() => {
    isAlertShowed()

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  const closeModal = () => {
    timer.current = setTimeout(() => setShowSuccessAnimation(false), REDIRECT_TIME)
  }

  const onSuccess = (actions: FormikHelpers<InitialValuesTypes>) => {
    setShowSuccessAnimation(true)
    actions.resetForm()
    closeModal()
  }

  const submitAvailability = (values: InitialValuesTypes, actions: FormikHelpers<InitialValuesTypes>) => {
    const valuesToBeOmitted = values.timeScheduleType !== 'hourly' ? ['startTime', 'endTime'] : []

    updateLongTermAvailability(
      omit(values, [...valuesToBeOmitted, 'applyTo']) as Partial<InitialValuesTypes>,
      () => onSuccess(actions),
      () => actions.setSubmitting(false),
    )
  }

  if (showSuccessAnimation) {
    return (
      <SuccessAnimation
        animationInfo={translate(translations.availabilityUpdated)}
        onAnimationEnd={() => setShowSuccessAnimation(false)}
        showAnimation={showSuccessAnimation}
      />
    )
  }

  return (
    <ScrollView contentContainerStyle={globalStyles.containerSize} testID="scrollView">
      <View style={styles.container}>
        {showAlert && (
          <View style={styles.section}>
            <AlertCard
              colorSchema={alertColorScheme.info}
              description={translate(translations.availabilityHeader2)}
              onClose={setCloseAlert}
              title={translate(translations.availabilityHeader1)}
              variant={alertVariants.standard}
            />
          </View>
        )}
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={submitAvailability}
          validationSchema={validationSchema}
        >
          {({ errors, setFieldValue, values, isSubmitting, handleSubmit, isValid, dirty }) => (
            <>
              <AvailabilityForm
                errors={errors}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                values={values}
              />

              <ButtonNew
                disabled={isSubmitting || !dirty || !isValid}
                isSubmitting={isSubmitting}
                onPress={handleSubmit}
                testID="availability-submit-button"
                title={translate(translations.availabilityConfirm)}
                variant="contained-default"
              />
            </>
          )}
        </Formik>
      </View>
    </ScrollView>
  )
}

export default Availability
