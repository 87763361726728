import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().nullable(),
  password: Yup.string().nullable(),
  passwordConfirmation: Yup.string()
    .nullable()
    .test({
      name: 'passwordConfirmation',
      test: function test() {
        return this.parent.password === this.parent.passwordConfirmation
      },
      message: () => translate(translations.equalPasswords),
    }),
})

export default validationSchema
