import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SearchFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.1037 14.1313H14.5915L14.41 13.9563C15.1879 13.0487 15.5899 11.8105 15.3695 10.4945C15.0648 8.69237 13.5608 7.25323 11.7457 7.03282C9.00353 6.69572 6.69572 9.00353 7.03282 11.7457C7.25323 13.5608 8.69237 15.0648 10.4945 15.3695C11.8105 15.5899 13.0487 15.1879 13.9563 14.41L14.1313 14.5915V15.1037L16.0341 17C16.2999 17.2658 16.7342 17.2658 17 17C17.2658 16.7342 17.2658 16.2999 17 16.0341L15.1037 14.1313ZM8.29712 11.2142C8.29712 12.8283 9.60012 14.1314 11.2143 14.1314C12.8285 14.1314 14.1315 12.8283 14.1315 11.2142C14.1315 9.6 12.8285 8.297 11.2143 8.297C9.60012 8.297 8.29712 9.6 8.29712 11.2142Z"
    />
  </Svg>
)

export default SearchFilledIcon
