import { StyleSheet } from 'react-native'
import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginTop: paddingNew.md,
    paddingVertical: paddingNew.md,
  },
  button: { marginVertical: 0 },
  buttonContainer: {
    alignItems: 'center',
    marginVertical: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  info: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  subInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles
