import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DotTextRow } from 'src/components/DotTextRow'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { updateReadDocuments } from 'src/store/user/actions'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'DbsDeclaration'>

const DBS_LINK = 'https://www.gov.uk/government/publications/dbs-privacy-policies'
const POLICY_REF = 'disqualification_declaration_policy'
const rows = Array.from({ length: 5 }, (_, i) => i + 1)

const DbsDeclaration = ({ navigation, route }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - DbsDeclaration')

  const userId = useAppSelector(state => state.user.id) as string

  const [hasAuthorized, setHasAuthorized] = useState(isTaskCompleted)
  const [hasRead, setHasRead] = useState(isTaskCompleted)
  const [isError, setIsError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const formRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (formRef.current) {
      formRef.current.dirty = hasAuthorized !== isTaskCompleted || hasRead !== isTaskCompleted
    }
  }, [hasAuthorized, hasRead, isTaskCompleted])

  const closeScreen = useBackModal(navigation, formRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }

  const updateTaskStatus = () =>
    markTaskAsCompleted(
      userId,
      task,
      false,
      mixpanel,
      () => {
        setIsSubmitting(false)
        closeScreen()
      },
      handleError,
    )

  const completeTask = () => {
    const newPolicies = [POLICY_REF]
    setIsSubmitting(true)
    setIsError(false)
    bugsnagActionBreadcrumb('update user read documents', { user: userId, policies: newPolicies })
    updateReadDocuments(userId, newPolicies, updateTaskStatus, handleError)
  }

  const handleLinkButton = () => openURLIfCan(DBS_LINK)

  return (
    <ScrollView
      contentContainerStyle={globalStyles.containerSize}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <View style={styles.container}>
        <StatusInfo timeToComplete={timeToComplete} status={status} />
        <Text style={styles.header}>{translate(translations.dbsDeclarationService)}</Text>
        <Text style={styles.info}>{translate(translations.dbsDeclarationServiceInfo1)}</Text>
        <View style={styles.spacing} />
        <Text style={styles.info}>{translate(translations.dbsDeclarationServiceInfo2)}</Text>
        <Text style={styles.helperInfo}>{translate(translations.dbsDeclarationServiceHelperInfo)}</Text>
        <View style={styles.divider} />
        <Text style={styles.subHeader}>{translate(translations.dbsAboutPolicyHeader)}</Text>
        <Text style={styles.helperInfo}>{translate(translations.dbsAboutPolicyHelperInfo)}</Text>
      </View>
      {rows.map(row => (
        <DotTextRow
          containerStyle={StyleSheet.flatten([styles.textWithDotContainer, row % 2 === 0 && styles.textWithDotColor])}
          key={row}
          text={translate(translations[`dbsAboutPolicyInfo${row}`])}
          textStyle={styles.textWithDot}
        />
      ))}
      <View style={styles.container}>
        <View style={styles.divider} />
        <Text style={styles.header}>{translate(translations.agreements)}</Text>
        <Text style={styles.subInfo}>{translate(translations.dbsAgreementsInfo1)}</Text>
        <Text style={styles.subInfo}>{translate(translations.dbsAgreementsInfo2)}</Text>
        <View style={styles.checkboxWrapper}>
          <DotTextRow
            index={1}
            numeric
            text={translate(translations.dbsAgreementsQuestion)}
            textStyle={styles.question}
          />
          <Checkbox
            disabled={isTaskCompleted}
            label={translate(translations.dbsAgreementsAnswer)}
            onPress={() => setHasAuthorized(prev => !prev)}
            selected={hasAuthorized}
          />
        </View>
        <Text style={styles.subInfo}>{translate(translations.dbsReadPolicy)}</Text>
        <View style={styles.buttonWrapper}>
          <ButtonNew
            containerStyle={styles.button}
            isLong
            onPress={handleLinkButton}
            RightIcon={ChevronRightIcon}
            size={buttonSize.sm}
            title={translate(translations.dbsReadPolicyButton)}
            variant={buttonVariants.outlinedDefault}
          />
        </View>
        <View style={styles.checkboxWrapper}>
          <DotTextRow
            index={2}
            numeric
            text={translate(translations.dbsReadPolicyQuestion)}
            textStyle={styles.question}
          />
          <Checkbox
            disabled={isTaskCompleted}
            label={translate(translations.dbsReadPolicyAnswer)}
            onPress={() => setHasRead(prev => !prev)}
            selected={hasRead}
          />
        </View>
      </View>
      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonWrapper}>
          <ButtonNew
            containerStyle={styles.button}
            disabled={isTaskCompleted || !hasAuthorized || !hasRead}
            isLong
            isSubmitting={isSubmitting}
            onPress={completeTask}
            size={buttonSize.lg}
            title={translate(translations.submitAndComplete)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>
      <View style={styles.spacing} />
    </ScrollView>
  )
}

export default DbsDeclaration
