import { Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  screenContainer: {
    paddingBottom: paddingNew.lg,
  },
  headerTitle: {
    ...typography.h7,
    alignSelf: 'stretch',
    borderBottomWidth: 0,
    color: colorsNew.redesign.primary,
    textAlign: 'center',
    ...Platform.select({
      android: { textAlign: 'left' },
    }),
  },
})

export default styles
