import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginTop: paddingNew.sm,
  },
  buttonWrapper: {
    alignItems: 'center',
  },
  buttonExtraSpace: {
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  documentSection: {
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.base,
  },
  mainText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  section: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.md,
    paddingBottom: paddingNew.md,
  },
  title: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  textWithDot: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  textWithDotContainer: {
    marginVertical: 0,
  },
})

export default styles
