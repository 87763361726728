import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  additionalInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.md,
  },
  alertWrapper: {
    marginBottom: paddingNew.xs,
  },
  backgroundContainer: {
    marginBottom: paddingNew.sm,
    paddingVertical: paddingNew.sm,
    position: 'relative',
  },
  backgroundContainerFill: {
    backgroundColor: colorsNew.redesign.primary.shades12p,
    bottom: 0,
    left: -16,
    position: 'absolute',
    right: -16,
    top: 0,
  },
  buttonContainer: {
    alignItems: 'center',
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  employeeStatementSection: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginBottom: paddingNew.md,
    marginTop: paddingNew.sm,
    paddingTop: paddingNew.md,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  helperInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.sm,
  },
  info: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  listItemBullet: {
    color: colorsNew.redesign.text.secondary,
  },
  listItemContainer: {
    marginVertical: 0,
  },
  listItemInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    marginBottom: 0,
  },
  radioButtonListItemLarge: {
    marginBottom: paddingNew.md,
  },
  radioButtonListItemSmall: {
    marginBottom: paddingNew.sm,
  },
  radioButtonList: {
    paddingRight: paddingNew.sm,
  },
  radioButtonWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: paddingNew.md,
    width: '75%',
  },
  section: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.md,
  },
  submitBankDetailsButton: {
    paddingVertical: paddingNew.sm,
  },
  submitSection: {
    marginVertical: paddingNew.md,
  },
  spacing: {
    height: paddingNew.sm,
  },
  subHeader: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
    marginTop: paddingNew.sm,
  },
  subInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginBottom: 0,
  },
  question: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
