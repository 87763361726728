import React, { useMemo, useState } from 'react'
import { Formik } from 'formik'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'
import isEmpty from 'lodash/isEmpty'

import { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { CloseIcon } from 'src/icons'
import { ErrorAlert, ErrorType } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { InputSelectNew } from 'src/components/Form'
import { isGBSelector } from 'src/store/app/selectors'
import { shortDatePickerVariants } from 'src/components/Form/DatePickerShort/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { UserExperience } from 'src/store/user/selectors'
import AlertCard from 'src/components/AlertCard/AlertCard'
import ButtonNew from 'src/components/Buttons/ButtonNew/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import ConfirmationAlertModal from 'src/scenes/Main/Profile/RequiredTasks/components/ConfirmationAlertModal/ConfirmationAlertModal'
import DatePickerShort from 'src/components/Form/DatePickerShort/DatePickerShort'
import getValidationSchema from '../validation'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import { colorsNew } from 'src/styles'
import styles from './styles'

const getWorkloadOptions = () => [
  { value: 'full_time', label: translate(translations.fullTime) },
  { value: 'part_time', label: translate(translations.partTime) },
  { value: 'extra_jobs', label: translate(translations.extraJob) },
  { value: 'summer_jobs', label: translate(translations.summerJob) },
]

type Props = {
  closeFormError: () => void
  closeFormWithoutSaving: () => void
  deleteExperience: (experienceId: string) => void
  experience: UserExperience
  formError: ErrorType | null
  isDeleting: boolean
  isDisabled: boolean
  isSubmitting: boolean
  updateExperienceList: (experience: UserExperience, editedExperienceId?: string) => void
}

type RecursiveNonNullable<T> = { [K in keyof T]-?: RecursiveNonNullable<NonNullable<T[K]>> }

const Form = ({
  closeFormError,
  closeFormWithoutSaving,
  deleteExperience,
  experience,
  formError,
  isDeleting,
  isDisabled,
  isSubmitting,
  updateExperienceList,
}: Props) => {
  const isGB = useAppSelector(isGBSelector)
  const workloads = useMemo(() => getWorkloadOptions(), [])

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const initialValues: RecursiveNonNullable<UserExperience> = {
    duties: experience.duties || '',
    employer: experience.employer || '',
    endDate: experience.endDate || '',
    expCategory: experience.expCategory || 'other_professions',
    id: experience.id || '',
    isOngoing: !!experience.isOngoing,
    leavingReason: experience.leavingReason || '',
    role: experience.role || '',
    startDate: experience.startDate || '',
    workload: experience.workload || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => updateExperienceList(values, experience.id)}
      validationSchema={getValidationSchema(isGB)}
    >
      {({ dirty, errors, handleSubmit, isValid, setFieldTouched, setFieldValue, setValues, touched, values }) => {
        if (showDeleteConfirmation) {
          return (
            <ConfirmationAlertModal
              title={translate(translations.experienceDeleteConfirmationTitle)}
              description={translate(translations.experienceDeleteConfirmationInfo)}
              onCancel={() => setShowDeleteConfirmation(false)}
              onDelete={() => deleteExperience(experience.id)}
              isDeleting={isDeleting}
            />
          )
        }

        const handleIsOngoingPress = () => {
          setValues(
            {
              ...values,
              endDate: '',
              isOngoing: !values.isOngoing,
              leavingReason: '',
            },
            true,
          )
        }

        return (
          <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.formHeader}>
              <View style={styles.formHeaderInfo}>
                {experience.role ? (
                  <>
                    <Text style={styles.formTitle}>{experience.role}</Text>
                    <Text style={styles.formSubtitle}>{experience.employer}</Text>
                  </>
                ) : (
                  <Text style={styles.formTitle}>{translate(translations.experienceFormTitle)}</Text>
                )}
              </View>
              <TouchableOpacity onPress={closeFormWithoutSaving}>
                <CloseIcon fill={colorsNew.redesign.text.primary} size={24} />
              </TouchableOpacity>
            </View>

            {isDisabled ? (
              <View style={styles.alertContainer}>
                <AlertCard
                  colorSchema={alertColorScheme.info}
                  variant={alertVariants.standard}
                  title={translate(translations.experienceFormAlertTitle)}
                  description={translate(translations.experienceFormAlertInfo)}
                />
              </View>
            ) : null}

            <TextInputNew
              errorMessage={errors.employer}
              isError={!isEmpty(errors.employer) && touched.employer}
              disabled={isDisabled}
              label={translate(translations.enterEmployer)}
              onBlur={() => setFieldTouched('employer', true)}
              onChangeText={text => setFieldValue('employer', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="employer-input"
              value={values.employer}
            />
            <TextInputNew
              errorMessage={errors.role}
              isError={!isEmpty(errors.role) && touched.role}
              disabled={isDisabled}
              label={translate(translations.enterRole)}
              onBlur={() => setFieldTouched('role', true)}
              onChangeText={text => setFieldValue('role', text)}
              required
              size={TextInputSizeVariants.medium}
              testID="role-input"
              value={values.role}
            />
            <InputSelectNew
              disabled={isDisabled}
              errorMessage={errors.workload}
              isError={!isEmpty(errors.workload) && touched.workload}
              label={translate(translations.selectWorkload)}
              onBlur={() => setFieldTouched('workload', true)}
              onValueChange={value => setFieldValue('workload', value)}
              required
              testID="workload-input"
              value={values.workload}
              values={workloads}
            />
            <TextInputNew
              disabled={isDisabled}
              errorMessage={errors.duties}
              isError={!isEmpty(errors.duties) && touched.duties}
              label={translate(translations.enterDuties)}
              multiline
              onBlur={() => setFieldTouched('duties', true)}
              onChangeText={text => setFieldValue('duties', text)}
              required={isGB}
              size={TextInputSizeVariants.big}
              testID="duties-input"
              value={values.duties}
            />
            <DatePickerShort
              date={values.startDate}
              disabled={isDisabled}
              errorMessage={errors.startDate}
              isError={!isEmpty(errors.startDate) && touched.startDate}
              numberOfYearsInFuture={0}
              numberOfYearsInPast={51}
              onlyPastDateAllowed
              required
              setFieldTouched={() => setFieldTouched('startDate', true)}
              setFieldValue={date => {
                setFieldValue('startDate', date)
                setFieldTouched('startDate', true, false)
              }}
              testID="startDate-input"
              variant={shortDatePickerVariants.startDate}
              zIndex={2}
            />
            <DatePickerShort
              date={values.endDate}
              disabled={isDisabled || !!values.isOngoing}
              errorMessage={errors.endDate}
              isError={!isEmpty(errors.endDate) && touched.endDate}
              numberOfYearsInFuture={0}
              numberOfYearsInPast={51}
              onlyPastDateAllowed
              required
              setFieldTouched={() => setFieldTouched('endDate', true)}
              setFieldValue={date => {
                setFieldValue('endDate', date)
                setFieldTouched('endDate', true, false)
              }}
              testID="endDate-input"
              variant={shortDatePickerVariants.endDate}
              zIndex={1}
            />
            <View style={styles.checkboxContainer}>
              <Checkbox
                disabled={isDisabled}
                label={translate(translations.endDateOngoing)}
                onPress={handleIsOngoingPress}
                selected={!!values.isOngoing}
                testID="isOngoing-checkbox"
              />
            </View>
            <TextInputNew
              disabled={isDisabled || !!values.isOngoing}
              errorMessage={errors.leavingReason}
              isError={!isEmpty(errors.leavingReason) && touched.leavingReason}
              label={translate(translations.enterLeavingReason)}
              onBlur={() => setFieldTouched('leavingReason', true)}
              onChangeText={text => setFieldValue('leavingReason', text)}
              required={isGB}
              size={TextInputSizeVariants.medium}
              testID="leavingReason-input"
              value={values.leavingReason}
            />
            <View style={isGB ? styles.buttonsGB : styles.buttonsSv}>
              {formError ? <ErrorAlert hideError={closeFormError} type={formError} /> : null}
              <ButtonNew
                disabled={!isValid || !dirty || isDeleting || isDisabled}
                isSubmitting={isSubmitting}
                onPress={handleSubmit}
                size={buttonSize.lg}
                testID="submit-employment-form"
                title={translate(translations.saveChanges)}
                variant={buttonVariants.containedDefault}
              />
              <View style={styles.buttonsDivider} />
              <ButtonNew
                disabled={isSubmitting || isDisabled}
                isSubmitting={isDeleting}
                onPress={() => setShowDeleteConfirmation(true)}
                size={buttonSize.lg}
                title={translate(translations.experienceDelete)}
                variant={buttonVariants.outlinedError}
              />
            </View>
          </ScrollView>
        )
      }}
    </Formik>
  )
}

export default Form
