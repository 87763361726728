import React, { useCallback, useRef, useState } from 'react'
import { NativeSyntheticEvent, Platform, Text, TextLayoutEventData, TouchableOpacity, View } from 'react-native'
import HyperLink from 'react-native-hyperlink'

import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { ExpandLessIcon } from 'src/icons'

const isWeb = Platform.OS === 'web'

type ExpandableTextProps = {
  fullText: string
  maxLines: number
}

const ExpandableText = ({ maxLines = 3, fullText }: ExpandableTextProps) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [isFixed, setIsFixed] = useState(true)
  const [shortText, setShortText] = useState('')
  const [lastLine, setLastLine] = useState('')

  const isTextPrepared = useRef(false)

  const onTextLayout = useCallback(
    (e: NativeSyntheticEvent<TextLayoutEventData>) => {
      if (isTextPrepared.current) return

      const textToBeDisplayed = e.nativeEvent.lines

      if (textToBeDisplayed.length > maxLines) {
        const visibleText = textToBeDisplayed
          .slice(0, maxLines - 1)
          .map(({ text }) => text)
          .join('')
          .replace(/(\r\n|\n\n|\r)(?!.*\1)/gm, '')

        setShortText(visibleText)
        setLastLine(textToBeDisplayed[maxLines - 1].text.replace(/(\r\n|\n|\r)/gm, ''))
        isTextPrepared.current = true
        setIsExpanded(false)
        setIsFixed(false)
      } else {
        isTextPrepared.current = true
        setIsFixed(true)
      }
    },
    [maxLines],
  )

  return (
    <TouchableOpacity
      onPress={() => setIsExpanded(prev => !prev)}
      disabled={isFixed || isWeb || isExpanded}
      testID="expandable-area"
    >
      {isExpanded || isWeb ? (
        <>
          <HyperLink linkDefault={true} linkStyle={styles.link}>
            <Text style={styles.text} onTextLayout={onTextLayout} selectable={true}>
              {fullText}
            </Text>
          </HyperLink>
          <View style={styles.hideTextContainer}>
            {isFixed === false ? (
              <ButtonNew
                onPress={() => setIsExpanded(prev => !prev)}
                testID="show-less-button"
                RightIcon={ExpandLessIcon}
                title={translate(translations.showLess)}
                variant={buttonVariants.textDefault}
              />
            ) : null}
          </View>
        </>
      ) : (
        <>
          <Text style={styles.text}>{shortText}</Text>
          <View style={styles.lastRowContainer}>
            <Text style={[styles.text, styles.lastRowText]} numberOfLines={1}>
              {lastLine}
            </Text>
            <Text style={styles.expandText}>{translate(translations.more)}</Text>
          </View>
        </>
      )}
    </TouchableOpacity>
  )
}

export default ExpandableText
