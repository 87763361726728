import { StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  arrow: {
    color: colorsNew.brand.secondaryDark,
  },
  errorInput: {
    borderColor: colorsNew.error,
  },
  disabledInput: {
    opacity: 0.6,
  },
  fieldValue: {
    color: colorsNew.brand.secondaryDark,
    fontFamily: fonts.muktaSemiBold,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
  },
  icon: { marginRight: paddingNew.xs },
  iconWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  placeholder: {
    color: colorsNew.grey._500,
  },
  textInput: {
    alignItems: 'center',
    backgroundColor: colorsNew.white,
    borderColor: colorsNew.grey._300,
    borderRadius: 6,
    borderWidth: 1,
    flexDirection: 'row',
    height: 42,
    justifyContent: 'space-between',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.xs,
    width: '100%',
  },
})

export default styles
