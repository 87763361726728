import { AxiosError, AxiosResponse } from 'axios'

import Api, { responseErrorCallback } from 'src/utils/api'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { ErrorResponseT, OnErrorT, OnSuccessT } from 'src/utils/types'
import { workPermitInvalidStatusCode } from 'src/global/constants'

export const acceptOrder = (
  id: string,
  onSuccess: OnSuccessT,
  onError: OnErrorT,
  showCustomErrorModal?: (error: string) => void,
) => {
  bugsnagActionBreadcrumb('acceptOrder')

  return Api.put(`/student/orders/${id}/appoint`, {
    onSuccess: () => onSuccess(),

    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      onError(response)

      const errorCode = response?.data?.errors?.[0]?.code
      const isWorkPermitInvalid = errorCode === workPermitInvalidStatusCode

      if (response?.status === 422 && !isWorkPermitInvalid) {
        const errorDetails = response?.data?.errors[0]?.detail
        showCustomErrorModal && errorDetails && showCustomErrorModal(errorDetails)
      } else {
        bugsnagNotifyWithData('acceptOrder', response)
        responseErrorCallback('put', `student/orders/${id}/appoint`, response as AxiosResponse)
      }
    },
  })
}

export const ignoreOrder = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('ignoreOrder')

  return Api.put(`/student/orders/${id}/ignore`, {
    onSuccess: () => onSuccess(),
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      onError()
      bugsnagNotifyWithData('ignoreOrder', response)
      responseErrorCallback('put', `/student/orders/${id}/ignore`, response as AxiosResponse)
    },
  })
}

export const appointBooking = (
  id: string,
  onSuccess: OnSuccessT,
  onError: OnErrorT,
  showCustomErrorModal?: (error: string) => void,
) => {
  bugsnagActionBreadcrumb('appointBooking', { id })

  return Api.put(`student/bookings/${id}/appoint`, {
    data: {},
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      onError && onError(response)

      const errorCode = response?.data?.errors?.[0]?.code
      const isWorkPermitInvalid = errorCode === workPermitInvalidStatusCode

      if (response?.status === 422 && !isWorkPermitInvalid) {
        const errorDetails = response?.data?.errors[0]?.detail
        showCustomErrorModal && errorDetails && showCustomErrorModal(errorDetails)
      } else {
        bugsnagNotifyWithData('createApplication', response)
        responseErrorCallback('post', '/student/student_application', response as AxiosResponse)
      }
    },
  })
}

export const ignoreBooking = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('ignoreBooking', { id })

  return Api.put(`student/bookings/${id}/ignore`, {
    data: {},
    onSuccess: () => onSuccess(),
    onError: (e: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData('ignoreBooking', e?.response)
      onError()
    },
  })
}

export const createApplication = (
  id: string,
  onSuccess: OnSuccessT,
  onError: OnErrorT,
  showCustomErrorModal?: (error: string) => void,
) => {
  bugsnagActionBreadcrumb('createApplication')

  return Api.post('/student/student_applications', {
    data: { bookableResourceId: id },
    onSuccess: () => onSuccess(),
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData('createApplication', response)
      onError && onError(response)

      const errorCode = response?.data?.errors?.[0]?.code
      const isWorkPermitInvalid = errorCode === workPermitInvalidStatusCode

      if (response?.status === 422 && !isWorkPermitInvalid) {
        const errorDetails = response?.data?.errors[0]?.detail
        showCustomErrorModal && errorDetails && showCustomErrorModal(errorDetails)
      } else {
        responseErrorCallback('post', '/student/student_application', response as AxiosResponse)
      }
    },
  })
}

export const removeApplication = (id: string, applicationId: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('removeApplication')

  return Api.delete(`/student/student_applications/${applicationId}?bookableResourceId=${id}`, {
    onSuccess: () => onSuccess(),

    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      onError()
      bugsnagNotifyWithData('removeApplication', response)
      responseErrorCallback('delete', '/student/student_application', response as AxiosResponse)
    },
  })
}

export const lockBooking = (id: string, onSuccess: OnSuccessT) => {
  bugsnagActionBreadcrumb('lockBooking', { id })

  return Api.put(`/student/bookings/${id}/lock`, {
    onSuccess: () => onSuccess(),
    onError: ({ response }: AxiosError<ErrorResponseT>) => bugsnagNotifyWithData('lockBooking', response),
  })
}

export const lockOrder = (id: string, onSuccess: OnSuccessT) => {
  bugsnagActionBreadcrumb('lockOrder', { id })

  return Api.put(`/student/order_locks/${id}`, {
    onSuccess: () => onSuccess(),
    onError: ({ response }: AxiosError<ErrorResponseT>) => bugsnagNotifyWithData('lockBooking', response),
  })
}
