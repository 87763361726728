import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    paddingBottom: paddingNew.md,
    paddingHorizontal: paddingNew.sm,
  },
})

export default styles
