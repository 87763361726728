import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.xs,
  },
  cardInfo: {
    flex: 1,
    paddingRight: paddingNew.sm,
  },
  cardTitle: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  cardSubtitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
