import { StyleSheet } from 'react-native'

import { colors, colorsNew, padding, fontSizes, fonts } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    marginBottom: padding.xs,
  },
  multilineContainer: {
    height: 140,
  },
  errorInput: {
    borderColor: colors.danger,
  },
  multiline: {
    height: 100,
    paddingTop: 12,
    textAlignVertical: 'top',
  },
  textInput: {
    borderWidth: 1,
    borderColor: colors.grey._300,
    borderRadius: 6,
    color: colorsNew.redesign.text.primary,
    fontSize: 14,
    height: 42,
    paddingHorizontal: 16,
    paddingVertical: 8,
    flex: 1,
  },
  touchInput: {
    borderColor: colors.primary,
  },
  label: {
    marginBottom: padding.sm,
    color: colorsNew.redesign.text.primary,
    fontSize: fontSizes.sm,
    fontFamily: fonts.poppinsMedium,
  },
})

export default styles
