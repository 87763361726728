import { ScrollView, Text, View } from 'react-native'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { ChevronRightIcon } from 'src/icons'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import { WarningAmberIcon, WarningIcon } from 'src/icons'
import ButtonNew from 'src/components/Buttons/ButtonNew/ButtonNew'
import FileUploader from 'src/components/FileUploader/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import { colorsNew } from 'src/styles'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'ProofOfPoliceReport'>

const URL_LINK = 'https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/'

const ProofOfPoliceReport = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const taskStatus = {
    isCompleted: status === profileTaskStatus.completed,
    isEnabled: status === profileTaskStatus.enabled,
    isExpired: status === profileTaskStatus.expired,
    isSoonToExpire: status === profileTaskStatus.expiresSoon,
  }

  useBugsnagView('RequiredTask - ProofOfPoliceReport')

  const policeReportCreatedAt = useAppSelector(state => state.user.attributes?.policeReportCreatedAt)
  const policeReportDocs = useAppSelector(state => state.user.attributes?.proofOfPoliceReportUrls || [])
  const userId = useAppSelector(state => state.user.id) as string

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked
    }
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => setIsError(true)

  const policeReportExpirationDate = moment(policeReportCreatedAt).add(1, 'year').format('YYYY-MM-DD')
  const additionalDocumentInfo = taskStatus.isExpired
    ? {
        icon: <WarningIcon size={16} fill={colorsNew.redesign.warning.shades50p} />,
        text: translate(translations.proofOfPoliceReportExpired, { date: policeReportExpirationDate }),
      }
    : taskStatus.isSoonToExpire
    ? {
        icon: <WarningAmberIcon size={16} fill={colorsNew.redesign.warning.shades50p} />,
        text: translate(translations.proofOfPoliceReportSoonToExpire, { date: policeReportExpirationDate }),
      }
    : {}

  const isDocumentUploaded = (taskStatus.isEnabled && !!policeReportDocs?.length) || isFilePicked

  const updateTaskStatus = () => {
    markTaskAsCompleted(
      userId,
      task,
      isFilePicked,
      mixpanel,
      () => {
        setIsSubmitting(false)
        closeScreen()
      },
      handleError,
    )
  }

  const completeTask = () => {
    setIsSubmitting(true)
    setIsError(false)
    isFilePicked ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  const fileInfo = (
    <View style={styles.fileInfo}>
      {additionalDocumentInfo.icon}
      <Text style={styles.fileInfoText}>{additionalDocumentInfo.text}</Text>
    </View>
  )

  return (
    <ScrollView
      contentContainerStyle={globalStyles.containerSize}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <View style={styles.container}>
        <StatusInfo timeToComplete={timeToComplete} status={status} />
        <Text style={styles.header}>{translate(translations.proofOfPoliceReportStep1Title)}</Text>
        <Text style={styles.mainInfo}>{translate(translations.proofOfPoliceReportStep1Info)}</Text>
        <View style={styles.buttonLinkContainer}>
          <ButtonNew
            onPress={() => openURLIfCan(URL_LINK)}
            RightIcon={ChevronRightIcon}
            size={buttonSize.sm}
            title={translate(translations.proofOfPoliceReportStep1Button)}
            variant={buttonVariants.containedDefault}
          />
        </View>
        <Text style={styles.subInfo}>{translate(translations.proofOfPoliceReportStep1SubInfo)}</Text>
        <View style={styles.divider} />
        <Text style={styles.header}>{translate(translations.proofOfPoliceReportStep2Title)}</Text>
        <Text style={styles.mainInfo}>{translate(translations.proofOfPoliceReportStep2Info)}</Text>
        <Text style={[styles.subInfo, styles.subInfoSpacing]}>
          {translate(translations.proofOfPoliceReportStep2SubInfo)}
        </Text>
        <FileUploader
          arrayDocuments={policeReportDocs}
          buttonDisabled={taskStatus.isCompleted}
          deletingFilesDisabled={!taskStatus.isEnabled}
          field="proofOfPoliceReportUrls"
          fileInfo={fileInfo}
          onFileSaveCallback={updateTaskStatus}
          onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
          onFilesErrorCallback={handleError}
          saveOnUpload={shouldSaveDocument}
        />
        <View style={styles.sectionContainer}>
          {isError ? (
            <View style={styles.alertWrapper}>
              <CompleteTaskError hideError={() => setIsError(false)} />
            </View>
          ) : null}
          <View style={styles.buttonContainer}>
            <ButtonNew
              disabled={taskStatus.isCompleted || !isDocumentUploaded || isSubmitting}
              isSubmitting={isSubmitting}
              onPress={completeTask}
              size={buttonSize.lg}
              title={translate(translations.closeTask)}
              variant={buttonVariants.containedDefault}
            />
          </View>
        </View>
        <Text style={styles.taskInfo}>{translate(translations.proofOfPoliceReportTaskInfo)}</Text>
      </View>
    </ScrollView>
  )
}

export default ProofOfPoliceReport
