import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const LARGE_WIDTH = 140
const SMALL_WIDTH = 80
const TEXT_LINE_HEIGHT = 24

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.primary.shades50p,
    borderRadius: paddingNew.xs,
    paddingHorizontal: paddingNew.xs,
    paddingVertical: paddingNew.xxs,
    width: LARGE_WIDTH,
  },
  outlineText: {
    ...typography.subtitle2,
  },
  outline: {
    backgroundColor: colorsNew.redesign.background.paper,
    borderColor: colorsNew.redesign.secondary.shades50p,
    borderWidth: 1,
  },
  small: {
    width: SMALL_WIDTH,
  },
  text: {
    ...typography.body1,
    lineHeight: TEXT_LINE_HEIGHT,
  },
})
