import React, { useContext, useCallback, useLayoutEffect, useMemo } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { View, Text, ScrollView, StyleSheet } from 'react-native'

import { colorsNew } from 'src/styles'
import { isSESelector } from 'src/store/app/selectors'
import {
  JobsNavigatorScreensProps,
  MenuNavigatorScreensProps,
  NotificationsScreensProps,
} from 'src/utils/types/navigationTypes'
import { LocationOnIcon } from 'src/icons'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import ApplicationInfo from './ApplicationInfo'
import BadgeItem from 'src/components/BadgeItem'
import ContactDetails from './ContactDetails/ContactDetails'
import ExpandableText from 'src/components/ExpandableText'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import headerStyles from 'src/utils/headerConfig/styles'
import LoadingIndicator from 'src/components/LoadingIndicator'
import ProposalActions from './ProposalActions'
import ProposalDetails, { ProposalDetailsRequestTypes } from 'src/graphql/Proposal/ProposalDetails'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

export const TypeOfProposalOptions = [
  { label: translations.temporaryLongTermBooking, value: 'long_term' },
  { label: translations.permanentVacancy, value: 'permanent' },
]

type LongTermScreenProps =
  | JobsNavigatorScreensProps<'Proposal'>
  | MenuNavigatorScreensProps<'ProposalMenu'>
  | NotificationsScreensProps<'ProposalNotification'>

const LongTermOffer = ({ route, navigation }: LongTermScreenProps) => {
  useBugsnagView('LongTermOffer (proposal details)')

  const { mixpanel } = useContext(MixpanelContext)

  useFocusEffect(
    useCallback(() => {
      mixpanel?.track('Proposal Details', { proposalId })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const proposalId = route.params.id as string
  const userId = useAppSelector(state => state.user.id)
  const isSE = useSelector(isSESelector)

  const { data, loading, error, refetch } = useQuery<ProposalDetailsRequestTypes>(ProposalDetails, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: proposalId,
    },
  })

  const isApplicant = userId && data?.proposal?.interestedStudentIds?.includes(userId)
  const isNotAvailable = data?.proposal?.studentId !== userId && data?.proposal?.closed

  const location = data?.proposal?.location || data?.proposal?.region?.name
  const proposalTitle = data?.proposal?.jobTitle || data?.proposal?.title || ''
  const jobRoles = data?.proposal?.topics || []
  const competences = data?.proposal?.competences || []
  const proposalDescription = data?.proposal?.description || ''
  const salary = data?.proposal?.payRate
  const workingHours = data?.proposal?.hours || data?.proposal?.hoursPerWeek
  const duration = data?.proposal?.duration
  const type = data?.proposal?.type

  const emailPlaceholder = isSE ? 'support@humly.io' : ''
  const emailFromOffer = data?.proposal?.contactPerson
    ?.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
    ?.toString()

  const email = emailFromOffer || emailPlaceholder
  const contactPersonName = emailFromOffer ? data?.proposal?.contactPerson?.match(/[^|,]+/)?.toString() : null

  const isLongTermBooking = type === TypeOfProposalOptions[0].value

  const typeOfContract = useMemo(() => {
    const typeOfProposal = TypeOfProposalOptions.find(
      TypeOfProposalOption => TypeOfProposalOption.value === type,
    )?.label
    return typeOfProposal ? translate(typeOfProposal) : ''
  }, [type])

  const headerTitle = useCallback(
    () => (
      <Text numberOfLines={2} style={headerStyles.onboardingHeaderTitle}>
        {isNotAvailable
          ? translate(translations.offerNotAvailableTitle)
          : translate(translations.longTermOfferPendingHeader)}
      </Text>
    ),
    [isNotAvailable],
  )

  useLayoutEffect(() => {
    if (isNotAvailable || isApplicant) {
      navigation.setOptions({
        headerTitle: headerTitle,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotAvailable, isApplicant])

  if (error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <ScrollView contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }} testID="scrollView">
        {isApplicant && !isNotAvailable ? <ApplicationInfo /> : null}
        {isNotAvailable ? <ApplicationInfo notAvailable /> : null}
        <View style={isNotAvailable ? styles.mask : undefined}>
          <View style={styles.locationContainer}>
            <LocationOnIcon size={18} fill={colorsNew.redesign.text.secondary} />
            <Text style={styles.location}>{location}</Text>
          </View>
          <Text style={styles.headerTitle}>{proposalTitle}</Text>
          <View style={styles.badgesContainer}>
            {competences.map(({ name, id }) => (
              <BadgeItem title={name} key={id} type="standard" testID="role" />
            ))}
            {jobRoles.map(({ title, id }) => (
              <BadgeItem title={title} key={id} type="light" testID="subject" />
            ))}
          </View>
          <View style={styles.divider} />
          <Text style={styles.header}>{translate(translations.jobDescription)}</Text>
          <View style={styles.descriptionContainer}>
            <ExpandableText maxLines={4} fullText={proposalDescription} />
          </View>
          <View style={styles.divider} />
          <View style={styles.infoRow}>
            <Text style={styles.rowTitle}>{translate(translations.duration)}</Text>
            <Text style={styles.rowContent}>{duration || translate(translations.toBeDecided)}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.rowTitle}>{translate(translations.hoursLabel)}</Text>
            <Text style={styles.rowContent}>{workingHours || translate(translations.toBeDecided)}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.rowTitle}>
              {isLongTermBooking ? translate(translations.payRate) : translate(translations.salaryLabel)}
            </Text>
            <Text style={styles.currency}>{translate(translations.currency)}</Text>
            <Text style={styles.rowContent}>{salary || translate(translations.negotiable)}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.rowTitle}>{translate(translations.contract)}</Text>
            <Text style={styles.rowContent}>{typeOfContract}</Text>
          </View>
          <View style={styles.divider} />
          <Text style={StyleSheet.flatten([styles.header, styles.headerWithMargin])}>
            {translate(translations.anyQuestionLabel)}
          </Text>
          <View style={styles.contactInfo}>
            {email ? (
              <View style={styles.contactContent}>
                <Text style={styles.contactHeader}>{translate(translations.contactHumly)}</Text>
                {contactPersonName ? <Text style={styles.rowContent}>{contactPersonName}</Text> : null}
                <Text style={styles.rowContent}>{email}</Text>
              </View>
            ) : (
              <Text style={styles.contactPlaceholder}>{translate(translations.contactHumlyPlaceholder)}</Text>
            )}
            {email ? <ContactDetails email={email} /> : null}
          </View>
        </View>
      </ScrollView>
      {!isApplicant && !isNotAvailable ? <ProposalActions proposalId={proposalId} refetchProposal={refetch} /> : null}
    </>
  )
}

export default LongTermOffer
