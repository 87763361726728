import gql from 'graphql-tag'

import { ProposalsRequestTypes, ProposalListItemType } from './types'

export type { ProposalsRequestTypes, ProposalListItemType }

export default gql`
  query Proposals($limit: Int, $offset: Int, $region: String, $matching: Boolean, $appliedByMe: Boolean) {
    proposals(
      limit: $limit
      offset: $offset
      filter: { region: $region, matching: $matching, appliedByMe: $appliedByMe }
    ) {
      totalCount
      nodes {
        closed
        endDate
        hours
        hoursPerWeek
        id
        interestedStudentIds
        jobTitle
        location
        payRate
        region {
          id
          name
        }
        startDate
        studentId
        title
      }
    }
  }
`
