import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.xs,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxContainer: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.sm,
    paddingBottom: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  helper: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.md,
  },
  hyperlink: {
    textDecorationLine: 'underline',
  },
  mainText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  sectionContainer: {
    marginBottom: paddingNew.sm,
  },
  subInfo: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.sm,
  },
  textDisabled: {
    color: colorsNew.redesign.text.disabled,
  },
})

export default styles
