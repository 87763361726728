import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { TouchableHighlight } from 'react-native'

import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  buttonText: string
  error?: string
  isRequired?: boolean
  label: string
  onToggle: () => void
}

const RowTitle = ({ label, buttonText, onToggle, isRequired, error }: Props) => (
  <>
    <View style={styles.labelRow}>
      <Text style={styles.label}>
        {label}
        {isRequired ? <Text style={styles.required}>*</Text> : null}
      </Text>
      <TouchableHighlight
        activeOpacity={1}
        onPress={onToggle}
        style={styles.toggleButton}
        underlayColor={colorsNew.grey._200}
      >
        <Text testID="toggle-text" style={StyleSheet.flatten([styles.toggleText, error ? styles.toggleTextError : {}])}>
          {buttonText}
        </Text>
      </TouchableHighlight>
    </View>
    {error ? (
      <Text style={styles.errorText} testID="timesheet-error">
        {error}
      </Text>
    ) : null}
  </>
)

export default RowTitle
