import { TextInput } from 'react-native'
import React, { useState } from 'react'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { colorsNew } from 'src/styles'
import { sendFeedback } from 'src/store/user/actions'
import CustomModal from 'src/components/Modal/CustomModal'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'

type Props = {
  closeModal: () => void
  showModal: boolean
}

const OpinionModal = ({ showModal, closeModal }: Props) => {
  const [feedbackText, setFeedbackText] = useState('')
  useBugsnagView('OpinionModal')

  const sendAppFeedback = () => {
    bugsnagActionBreadcrumb('sendAppFeedback')
    return sendFeedback(feedbackText, closeModal)
  }

  const input = (
    <TextInput
      multiline
      onChangeText={setFeedbackText}
      selectionColor={colorsNew.brand.secondary}
      style={styles.input}
      testID="feedback-input"
      underlineColorAndroid="transparent"
      value={feedbackText}
    />
  )

  return (
    <CustomModal
      buttons={[
        {
          onPress: closeModal,
          title: translate(translations.cancel),
          variant: buttonVariants.outlinedDefault,
        },
        {
          onPress: sendAppFeedback,
          testID: 'opinion-submit-button',
          title: translate(translations.saveChanges),
          variant: buttonVariants.containedDefault,
        },
      ]}
      children={input}
      colorSchema={customModalColorSchema.default}
      description={translate(translations.feedbackIdeasDescriptionModal)}
      isVisible={showModal}
      modalPlace="Main/Support/Support/components/OpinionModal/OpinionModal"
      onClose={closeModal}
      title={translate(translations.feedbackIdeas)}
      variant={customModalVariants.filled}
    />
  )
}

export default OpinionModal
