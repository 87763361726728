import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: paddingNew.md,
    marginTop: paddingNew.xs,
  },
  customInput: {
    marginBottom: -15,
  },
  divider: {
    height: paddingNew.sm,
  },
  listContainer: {
    paddingHorizontal: paddingNew.sm,
  },
})

export default styles
