import React, { ReactElement } from 'react'
import { StyleProp, StyleSheet } from 'react-native'

import colorPallet from 'src/styles/colors'

type Props = { children: ReactElement; colors: keyof typeof colorPallet; style: StyleProp<'div'> }

export const LinearGradient = ({ children, colors, style }: Props) => (
  <div style={StyleSheet.flatten([{ background: `linear-gradient(${colors})` }, style])}>{children}</div>
)

export default LinearGradient
