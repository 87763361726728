import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  divider: {
    width: paddingNew.xs,
  },
})

export default styles
