import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { countryCodeSelector, isGBSelector, isSESelector } from 'src/store/app/selectors'
import { useAppSelector } from './reduxHooks'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'
import { JobDetailsData } from 'src/scenes/Main/Jobs/Details/components/JobDetails/types'
import { OrderRequestTypes } from 'src/graphql/Order/types'
import OrderQuery from 'src/graphql/Order/Show'
import { getOrderStatus } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Order/utils'
import { getJobAlertData } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/utils'
import { CalendarBooking } from 'src/utils/calendarIntegration/calendarActions'
import { ApplicationOfferDataType } from 'src/scenes/Main/Jobs/Details/components/ApplicationModal'
import { applicationStatus } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/applicationTypes'
import { convertClassGrade, getBookingAppointment } from 'src/scenes/Main/Jobs/Details/helpers'
import { TimeScheduleTypes } from 'src/graphql/sharableTypes'
import { getJobInformation } from 'src/utils/getJobInformation'

const useOrderDetails = (orderId: string) => {
  const isGB = useAppSelector(isGBSelector)
  const isSE = useAppSelector(isSESelector)
  const countryCode = useAppSelector(countryCodeSelector)

  const userId = useAppSelector(state => state.user.id)

  const { data, loading, error, refetch } = useQuery<OrderRequestTypes>(OrderQuery, {
    variables: {
      id: orderId,
    },
    fetchPolicy: 'network-only',
  })

  const orderStatus = useMemo(() => {
    if (!data) return
    return getOrderStatus(data?.order, userId)
  }, [data, userId])

  const orderAlertData = useMemo(() => {
    const schoolName = data?.order?.school?.name
    return orderStatus ? getJobAlertData({ offerStatus: orderStatus, schoolName, id: orderId }) : null
  }, [orderStatus, data])

  const bookingsList = useMemo(() => data?.order.bookings || [], [data])
  const isOneDayOrder = bookingsList.length === 1

  const orderHours = useMemo(() => {
    const orderTimeInMinutes = bookingsList.reduce((acc, curr) => (acc = acc + curr._durationMinutes), 0)
    return formatter.convertMinutesToHoursAndMinutes(orderTimeInMinutes)
  }, [bookingsList])

  const jobHeader = isOneDayOrder
    ? {
        details:
          `${formatter.time(data?.order?.bookings[0]?.startTime)} - ${formatter.time(
            data?.order?.bookings[0]?.endTime,
          )}` || '',
        hours: orderHours,
        startDate: formatter.apiFormat(data?.order?.bookings[0]?.startTime) || '',
        title: formatter.dayOfWeekLong(data?.order?.bookings[0]?.startTime) || '',
      }
    : {
        details: `${bookingsList[0] ? formatter.weekdayWithShortDateWithoutTimezone(bookingsList[0].startTime) : ''}, ${
          bookingsList[1] ? formatter.weekdayWithShortDateWithoutTimezone(bookingsList[1].startTime) : ''
        }${bookingsList[2] ? '...' : ''}`,
        hours: orderHours,
        isMultiday: true,
        startDate: data?.order.startDate?.toString() || '',
        title: translate(translations.jobOfferWorkingDays, { days: bookingsList.length || 0 }),
      }

  const jobConfirmationData: ApplicationOfferDataType = {
    date:
      `${formatter.weekdayAndMonthWithoutTimezone(data?.order.startDate)} - ${formatter.weekdayAndMonthWithoutTimezone(
        data?.order.endDate,
      )}` || '',
    hours: orderHours,
    title: data?.order?.school?.name || '',
    time: translate(translations.jobOfferWorkingDays, { days: bookingsList.length || 0 }),
    type: orderStatus === applicationStatus.userRequested ? 'accept' : 'apply',
  }

  const payRate = useMemo(() => {
    if (!bookingsList.length) return ''
    if (bookingsList[0].timeScheduleType === 'hourly') {
      return formatter.getSalarySubtitle({
        countryCode,
        holidayRate: bookingsList[0].holidayPayBaseRate,
        salary: bookingsList[0].salaryRate,
        scheduleType: bookingsList[0].timeScheduleType,
      })
    }

    const getShiftSalary = (timeScheduleType: TimeScheduleTypes) => {
      const booking = bookingsList.find(orderBooking => orderBooking.timeScheduleType === timeScheduleType)
      const salary = booking?.salaryRate
      const holidayRate = booking?.holidayPayBaseRate
      if (!booking || !salary) {
        return undefined
      }
      return formatter.getSalarySubtitle({
        countryCode,
        holidayRate,
        salary,
        scheduleType: timeScheduleType,
        isMultiday: true,
      })
    }
    const halfDaySalary = getShiftSalary('am') || getShiftSalary('pm')
    const fullDaySalary = getShiftSalary('full_day')

    const salaryLine1 = fullDaySalary ? translate(translations.orderSalaryFullDay, { salary: fullDaySalary }) : ''
    const salaryLine2 = halfDaySalary ? translate(translations.orderSalaryAM, { salary: halfDaySalary }) : ''

    if (!salaryLine1 && !salaryLine2) return undefined

    return !!salaryLine1 && !!salaryLine2 ? `${salaryLine1}\n${salaryLine2}` : `${salaryLine1}${salaryLine2}`
  }, [bookingsList, countryCode])

  const isPreschool = data?.order?.level?.group === 'preschool'
  const isGBPreschool = isGB && isPreschool

  const jobTitle = data?.order?.school?.name || ''
  const jobInformation = getJobInformation(data?.order?.assignmentInfo || '', data?.order?.school?.bookingInfo)
  const originalTeacher = data?.order.originalTeacher
  const room = isPreschool ? data?.order.department : undefined
  const jobRoles = (!isGBPreschool && data?.order.topics) || []

  const competences = (data?.order.competences || []).filter(competence =>
    isGB ? competence?.type === 'role' : competence,
  )

  const SENSkills = isGB
    ? (data?.order.competences || [])
        .filter(competence => competence?.type === 'general')
        .map(skill => skill.name)
        .join(', ')
    : undefined

  const level = data?.order.level
  const jobLevel = !isGBPreschool && level ? [level] : []
  const schoolData = data?.order?.school
  const healthInformation = (data?.order.hasHealthRisks && data?.order.healthRisksDescription) || undefined

  const jobGrade = useMemo(() => {
    const gradeString = data?.order.klassName
    const gradesArray = gradeString ? gradeString.split(', ') : []
    const convertedClassGrade = isSE ? convertClassGrade(gradesArray) : gradesArray
    return convertedClassGrade
  }, [data, isSE])

  const applicationId = (data?.order.studentApplications || []).find(
    application => application.studentId === userId,
  )?.id

  const bookingCalendarData: CalendarBooking[] | undefined = useMemo(() => {
    if (!data) return
    return data.order.bookings?.map(booking => ({
      cancelled: booking?.cancelled,
      displayInfo: booking?.displayInfo,
      endTime: booking?.endTime,
      rejected: booking?.rejected,
      school: data.order.school,
      startTime: booking?.startTime,
    }))
  }, [data])

  const jobFiles = data?.order?.files || []

  const appointedBy = getBookingAppointment(userId, data?.order?.appointedById)

  const orderDetails: JobDetailsData = {
    competences,
    healthInformation,
    jobFiles,
    jobGrade,
    jobHeader,
    jobInformation,
    jobLevel,
    jobRoles,
    jobTitle,
    level,
    originalTeacher,
    payRate,
    room,
    schoolData,
    SENSkills,
  }

  return {
    appointedBy,
    applicationId,
    bookingCalendarData,
    bookingsList: isOneDayOrder ? undefined : bookingsList,
    endDate: data?.order.endDate,
    error,
    jobConfirmationData,
    loading,
    orderAlertData,
    orderDetails,
    orderStatus,
    refetch,
  }
}

export default useOrderDetails
