import { SET_AUTH_TOKEN, SET_USER } from './types'
import get from 'lodash/get'

import { UserT } from './userTypes'
import { ActionTypes } from './reusableActions'

type StateType = Partial<UserT> & {
  authToken: null | string
}

const initialState: StateType = {
  authToken: null,
}

export default (state = initialState, { type, payload }: ActionTypes) => {
  switch (type) {
    case SET_USER:
      return {
        _persist: {
          version: -1,
          rehydrated: true,
        },
        ...state,
        ...payload,
        userState: get(payload, 'attributes.state'),
      }

    case SET_AUTH_TOKEN:
      return { ...state, authToken: payload }

    default:
      return state
  }
}
