import React, { memo, useState } from 'react'
import { Pressable, Text, View } from 'react-native'

import { LegalNoticeModifiedT } from '../types'
import styles from './styles'
import { ChevronRightIcon } from 'src/icons'

const renderTextWithSeparator = (text: string, size: number, index: number) => (index < size - 1 ? `${text}-` : text)

export const removeLastEmptyLine = (text: string) =>
  text[text.length - 1] === '\n' ? text.slice(0, text.length - 1) : text

const CollapsibleItem = ({ item }: { item: LegalNoticeModifiedT }) => {
  const [isVisible, setVisibility] = useState(false)
  const toggleVisibility = () => setVisibility(previous => !previous)

  const renderPlainText = (text: string, index: number) => (
    <Text key={index} style={styles.text}>
      {text}
    </Text>
  )

  const renderText = () => {
    const splitText = item.title.split('-')
    return splitText.map((text, index) =>
      renderPlainText(renderTextWithSeparator(text, splitText.length, index), index),
    )
  }

  return (
    <View style={styles.container}>
      <Pressable
        onPress={toggleVisibility}
        style={({ pressed }) => (pressed ? styles.touchPressed : styles.touchUnpressed)}
        testID="collapse-touchable"
      >
        <View style={styles.textIconWrapper}>
          <View style={styles.textContainer}>{renderText()}</View>
          <ChevronRightIcon
            fill={styles.iconColor.color}
            size={24}
            style={isVisible ? styles.iconVisible : styles.iconInVisible}
          />
        </View>
      </Pressable>

      {isVisible ? (
        <View testID="collapse-description">
          <Text style={styles.description}>{removeLastEmptyLine(item.licenseText)}</Text>
        </View>
      ) : null}
    </View>
  )
}

const checkIfPropsEqual = (
  prevProps: Readonly<{ item: LegalNoticeModifiedT }>,
  nextProps: Readonly<{ item: LegalNoticeModifiedT }>,
) => prevProps.item.title === nextProps.item.title

export default memo(CollapsibleItem, checkIfPropsEqual)
