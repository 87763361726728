type UniversitiesListItem = {
  value: string
  label: string
}

export const universitiesList: UniversitiesListItem[] = [
  {
    value: 'other',
    label: 'Annat universitet',
  },
  {
    value: 'Chalmers tekniska högskola',
    label: 'Chalmers tekniska högskola',
  },
  {
    value: 'Göteborgs universitet',
    label: 'Göteborgs universitet',
  },
  {
    value: 'Handelshögskolan i Stockholm',
    label: 'Handelshögskolan i Stockholm',
  },
  {
    value: 'Karolinska Institutet',
    label: 'Karolinska Institutet',
  },
  {
    value: 'Kungliga Tekniska högskolan',
    label: 'Kungliga Tekniska högskolan',
  },
  {
    value: 'Lunds universitet',
    label: 'Lunds universitet',
  },
  {
    value: 'Stockholms universitet',
    label: 'Stockholms universitet',
  },
  {
    value: 'Högskolan i Kristianstad',
    label: 'Högskolan i Kristianstad',
  },
  {
    value: 'Högskolan i Halmstad',
    label: 'Högskolan i Halmstad',
  },
  {
    value: 'Malmö Universitet',
    label: 'Malmö Universitet',
  },
  {
    value: 'Uppsala universitet',
    label: 'Uppsala universitet',
  },
  {
    value: 'Högskolan i Borås',
    label: 'Högskolan i Borås',
  },
  {
    value: 'Högskolan Väst',
    label: 'Högskolan Väst',
  },
  {
    value: 'Lunds Tekniska Högskola',
    label: 'Lunds Tekniska Högskola',
  },
  {
    value: 'Södertörns Högskola',
    label: 'Södertörns Högskola',
  },
  {
    value: 'Umeå Universitet',
    label: 'Umeå Universitet',
  },
]
