import { Platform } from 'react-native'
import { UAParser } from 'ua-parser-js'
import { useMutation } from '@apollo/client'
import DeviceInfo from 'src/utils/webAdapters/DeviceInfoAdapter'

import { SessionOrigin } from 'src/graphql/mutations/updateSessionInfo'
import { UpdateSessionInfo, UpdateSessionInfoDto } from 'src/graphql/mutations'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../package.json')

const useSessionInfo = () => {
  const appVersion = version
  const isWeb = Platform.OS === 'web'
  const [updateSessionInfo] = useMutation<UpdateSessionInfoDto>(UpdateSessionInfo)

  // Native app information
  let brand = ''
  let model = ''
  let osVersion = ''

  // Web browser information
  let browserName = ''
  let browserVersion = ''

  // Shared information
  let os = ''

  if (isWeb) {
    const parser = new UAParser()
    const result = parser.getResult()
    browserName = result.browser.name || ''
    browserVersion = result.browser.version || ''
    os = result.os.name || ''
  } else {
    brand = DeviceInfo.getBrand() || ''
    model = DeviceInfo.getModel() || ''
    osVersion = DeviceInfo.getSystemVersion() || ''
    os = DeviceInfo.getSystemName() || ''
  }

  return async function handleUpdateSessionInfo({ userId }: { userId: string }) {
    const newSessionInfo = isWeb
      ? {
          appVersion,
          browserName,
          browserVersion,
          os,
          sessionOrigin: SessionOrigin.EDUCATOR_PORTAL,
          userId,
        }
      : {
          appVersion,
          brand,
          model,
          os,
          osVersion,
          sessionOrigin: SessionOrigin.EDUCATOR_APP,
          userId,
        }

    await updateSessionInfo({
      variables: {
        update: newSessionInfo,
      },
    })
  }
}

export default useSessionInfo
