import translations, { translate } from 'src/utils/translations/translations'

const bankDetailsFields = {
  bankName: 'bankName',
  bankAccountHolderName: 'bankAccountHolderName',
  bankAccountNumber: 'bankAccountNumber',
  bankSortCode: 'bankSortCode',
  nationalInsuranceNumber: 'nationalInsuranceNumber',
} as const

const ukTaxCodeOptions = [
  {
    label: translate(translations.ukTaxCodeOptionsA),
    value: 'A',
  },
  {
    label: translate(translations.ukTaxCodeOptionsB),
    value: 'B',
  },
  {
    label: translate(translations.ukTaxCodeOptionsC),
    value: 'C',
  },
]

const studentLoanPlans = [
  {
    header: translate(translations.studentLoanInfoPlan1),
    options: [translate(translations.studentLoanInfoPlan1Option1), translate(translations.studentLoanInfoPlan1Option2)],
  },
  {
    header: translate(translations.studentLoanInfoPlan2),
    options: [translate(translations.studentLoanInfoPlan2Option1)],
  },
  {
    header: translate(translations.studentLoanInfoPlan4),
    options: [translate(translations.studentLoanInfoPlan4Option1)],
  },
]

const studentLoanPlanOptions = [1, 2, 4].map(id => ({
  label: translate(translations.plan, { number: id }),
  value: `plan${id}`,
}))

export { bankDetailsFields, studentLoanPlanOptions, studentLoanPlans, ukTaxCodeOptions }
