import React, { useMemo, useEffect, useState } from 'react'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import { View, Text, ScrollView, Platform } from 'react-native'
import { RouteProp, useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import { isGBSelector } from 'src/store/app/selectors'
import { ProfileAndRecruitmentParamList } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from '../types'
import { progressBarVariants } from 'src/components/ProgressBar'
import { sortTasksByStatus } from './utils'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import LoadingIndicator from 'src/components/LoadingIndicator'
import ProgressBar from 'src/components/ProgressBar/ProgressBar'
import TaskRow from './TaskRow/TaskRow'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'
import { navigationRef } from 'src/utils/navigationService'
import { waitForSomethingToBeTrue } from 'src/utils/waitForSomethingToBeTrue'
import { CategoriesList } from 'src/scenes/Main/Profile/CategoriesList/useGetCompletedTasks'
import { TaskItem } from 'src/graphql/RequiredTasks'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'

const isWeb = Platform.OS === 'web'

type Props = {
  categoriesData: CategoriesList
  dataPerRef: { [key: string]: TaskItem }
  isOpeningView: boolean
  route: RouteProp<ProfileAndRecruitmentParamList, 'TasksList'>
  setIsOpeningView: (isOpeningView: boolean) => any
}

const TaskListLoaded = ({ categoriesData, dataPerRef, isOpeningView, route, setIsOpeningView }: Props) => {
  const [category, setCategory] = useState(route.params?.category)
  const openView = route.params?.openView
  const taskName = route.params?.task
  const isGB = useSelector(isGBSelector)
  const navigation = useNavigation()

  const openViewAndNavigate = async () => {
    setIsOpeningView(true)
    const taskByRef = dataPerRef[taskName as string]
    setCategory(taskByRef.category)
    await waitForSomethingToBeTrue(() => navigationRef?.current)

    if (navigationRef?.current?.isReady()) {
      navigation.navigate(openView, {
        expiresAt: taskByRef.expiresAt,
        status: taskByRef.status,
        task: taskName,
        timeToComplete: taskByRef.timeToComplete || '',
      })
    }
  }

  useEffect(() => {
    if (openView && !isOpeningView) {
      openViewAndNavigate()
    }
    return () => {
      setIsOpeningView(false)
    }
  }, [])

  useEffect(() => {
    // Update category on route param change, so opening a second tasks page
    // directly after a first one using deep linking works
    const categoryParam = route.params?.category
    if (!categoryParam) return

    setCategory(categoryParam)
  }, [route.params?.category])

  const numberOfCompletedTasks = category && categoriesData[category]?.numberOfCompletedTasks
  const numberOfTasks = category && categoriesData[category]?.numberOfTasks

  const groupedTasks = useMemo(() => {
    if (isEmpty(categoriesData) || !category) {
      return {}
    }

    const tasksList = categoriesData[category].tasksList
    if (numberOfCompletedTasks === numberOfTasks) {
      return { completed: tasksList, toDo: [] }
    }
    const sortedList = sortTasksByStatus(tasksList)

    return groupBy(sortedList, task => (task.status === profileTaskStatus.completed ? 'completed' : 'toDo'))
  }, [categoriesData, category, numberOfTasks, numberOfCompletedTasks])

  if (isOpeningView) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.page, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={isWeb}
      testID="scrollView"
    >
      <View style={styles.categoryHeader}>
        <ProgressBar
          progress={numberOfTasks ? (numberOfCompletedTasks as number) / numberOfTasks : 0}
          title={translate(translations.progressTasks, { numberOfTasks })}
          variant={progressBarVariants.success}
          withProgress
        />
      </View>
      {groupedTasks.toDo?.length > 0 ? (
        <>
          <Text style={styles.sectionHeader}>{translate(translations.tasksTodo)}</Text>
          {groupedTasks.toDo.map(task => (
            <TaskRow
              expiresAt={task.expiresAt}
              key={task.ref}
              status={task.status}
              task={task.ref}
              testID="task-toDo"
              timeToComplete={task.timeToComplete || ''}
            />
          ))}
        </>
      ) : null}

      {groupedTasks.completed?.length > 0 ? (
        <>
          <Text style={styles.sectionHeader}>{translate(translations.tasksCompleted)}</Text>
          {groupedTasks.completed.map(task => (
            <TaskRow
              expiresAt={task.expiresAt}
              key={task.ref}
              status={task.status}
              task={task.ref}
              testID="task-completed"
              timeToComplete={task.timeToComplete || ''}
            />
          ))}
        </>
      ) : null}

      {category === 'getToKnowHumly' && isGB && (
        <View style={styles.alert}>
          <AlertCard
            colorSchema={alertColorScheme.info}
            description={translate((translations as TranslationGBT).introductionAlertInfo)}
            title={translate((translations as TranslationGBT).introductionAlertTitle)}
            variant={alertVariants.standard}
          />
        </View>
      )}
    </ScrollView>
  )
}

export default TaskListLoaded
