import React, { useCallback, useMemo, useState } from 'react'
import { ImageSourcePropType, View } from 'react-native'
import { useSelector } from 'react-redux'

import images from 'src/global/images'
import imagesGB from 'src/global/imagesGB'
import { useBugsnagView } from 'src/utils/bugsnag'
import IntroductionWrapper from './Introduction'
import styles from './styles'
import { isGBSelector } from 'src/store/app/selectors'
import { renderImage } from './utils'
import { PropsT } from './types'

const Introduction = (props: PropsT) => {
  const isGB = useSelector(isGBSelector)
  const [index, setIndex] = useState(0)

  useBugsnagView('Introduction')

  const introImages = useMemo(() => (isGB ? imagesGB : images), [isGB]) as keyof ImageSourcePropType

  const onPageChange = useCallback(
    (newIndex: number) => () => {
      setIndex(newIndex)
    },
    [],
  )

  return (
    <IntroductionWrapper {...props} index={index} onPageChange={onPageChange}>
      <View style={styles.container}>{renderImage(introImages[`step${index + 1}`])}</View>
    </IntroductionWrapper>
  )
}

export default Introduction
