import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { LogoTypes } from './types'

const DEFAULT_WIDTH = 147
const DEFAULT_COLOR = '#000000'

const Logo = ({ fill = DEFAULT_COLOR, width = DEFAULT_WIDTH, ...props }: LogoTypes) => {
  const height = Math.floor((width * 69) / 147)

  return (
    <Svg width={width} height={height} fill={fill} viewBox="0 0 147 69" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M36.6562 41.8019C36.6562 42.4984 37.3604 43.0198 38.0684 43.0198H40.7055C41.3965 43.0198 41.9559 42.4686 41.9559 41.7898V32.2117C41.9559 27.1154 39.3008 25.6395 35.5241 25.6395C31.7473 25.6395 30.8093 28.3082 30.8093 28.3082V18.3447C30.8093 17.6472 30.1893 17.041 29.4841 17.041H26.9521C26.2469 17.041 25.6752 17.6062 25.6752 18.3037L25.6411 34.3078C25.6411 35.0052 26.2128 35.5704 26.918 35.5704H29.4841C30.2489 35.5704 30.7146 35.198 30.8093 34.2668C30.9324 33.0591 30.8812 31.7415 32.0171 30.6241C33.6263 29.0411 36.6552 29.7785 36.6552 33.1382" />
      <Path d="M28.302 43.2993C29.8965 43.2993 31.189 42.0278 31.189 40.4592C31.189 38.8907 29.8965 37.6191 28.302 37.6191C26.7076 37.6191 25.415 38.8907 25.415 40.4592C25.415 42.0278 26.7076 43.2993 28.302 43.2993Z" />
      <Path d="M62.2651 27.2578C62.2651 26.5613 61.6906 25.9961 60.9826 25.9961H58.2773C57.5693 25.9961 56.9947 26.5613 56.9947 27.2578C56.9947 30.8363 56.9824 31.8467 56.9824 33.6159C56.9824 36.5025 56.3198 37.4337 55.3733 38.1786C54.2648 39.0512 51.1138 38.9236 51.1138 35.5714C51.1138 33.6159 51.0608 28.6555 51.0835 27.2746C51.0835 26.5781 50.5089 26.0129 49.8009 26.0129H47.1969C46.4889 26.0129 45.9143 26.5781 45.9143 27.2746C45.9143 27.2746 45.9077 35.8507 45.9077 37.1544C45.9077 41.624 49.9779 43.3616 52.2989 43.3616C56.2252 43.3616 57.0278 40.7366 57.0278 40.7366C57.0089 41.7925 56.8915 42.9369 58.3104 43.0496H60.9485C61.6565 43.0496 62.2311 42.4844 62.2311 41.7879C62.2311 40.879 62.2623 31.4714 62.2651 27.2578Z" />
      <Path d="M91.1531 27.9353C89.8838 25.9892 87.1776 24.9556 83.911 26.0683C82.1703 26.6615 80.9758 28.5909 80.9758 28.5909C80.9758 28.5909 80.9758 28.6244 80.9417 28.6244V28.5909C80.1835 27.4716 79.5398 26.8012 78.563 26.2601C77.0485 25.4221 74.8714 25.4984 73.4516 26.2601C72.4104 26.8189 71.9371 27.3776 71.3266 28.3255C71.4638 28.1123 71.319 27.2863 71.2934 27.088C71.1051 26.479 70.6536 26.0395 69.9787 26.0255H67.4145C66.856 26.0255 66.1641 26.3914 66.1641 26.911V41.9029C66.1641 42.5548 66.7632 43.0269 67.4741 43.0269H70.0393C70.6886 43.0306 71.3701 42.5194 71.3701 41.9029C71.3701 40.1337 71.3701 35.3847 71.3701 33.988C71.3701 32.5912 71.514 31.9096 72.0327 31.1944C72.9793 29.8908 74.967 30.077 75.9136 31.1944C76.5989 32.0036 76.5762 32.8706 76.5762 33.8948V41.9029C76.5762 42.6479 77.3334 43.0203 77.8067 43.0203H80.741C81.3089 43.0203 81.9715 42.741 81.9715 41.9029V33.988C81.9715 32.2187 82.2555 31.66 82.6341 31.1944C83.8164 29.7399 85.7578 30.2633 86.515 31.1944C87.3508 32.2225 87.1776 33.4134 87.1776 33.988C87.1776 35.7572 87.223 40.9457 87.2173 41.6422C87.2798 42.4142 87.7739 42.9989 88.5662 43.0269H91.1313C91.8394 43.0269 92.4139 42.4616 92.4139 41.7651C92.3874 41.1105 92.3846 37.2471 92.3846 33.988C92.3846 31.2363 92.5076 30.0109 91.1541 27.9353H91.1531Z" />
      <Path d="M100.274 17.041H97.4347C96.8074 17.041 96.2988 17.5413 96.2988 18.1584V41.9034C96.2988 42.5205 96.8074 43.0208 97.4347 43.0208H100.274C100.902 43.0208 101.41 42.5205 101.41 41.9034V18.1584C101.41 17.5413 100.902 17.041 100.274 17.041Z" />
      <Path d="M104.79 29.8162C104.432 28.9232 104.175 28.2872 103.982 27.8114C103.638 26.9677 104.217 26.0263 105.077 26.0263L107.902 26.0282C108.526 26.0366 108.809 26.2507 109.065 26.9919C109.437 28.0712 109.597 28.6345 109.975 29.6747L112.408 36.2078L114.762 29.7873C115.264 28.4669 115.52 27.6503 115.768 26.9854C115.978 26.4267 116.319 26.0254 116.862 26.0254H119.763C120.626 26.0254 121.205 26.9733 120.856 27.8179C120.675 28.2574 120.448 28.8245 120.054 29.8153L113.997 44.7774C112.938 47.4359 111.034 48.652 108.019 48.652C107.469 48.652 106.954 48.6203 106.411 48.5514C105.297 48.4099 104.505 47.3195 104.66 46.1304C104.801 45.039 105.756 44.2261 106.785 44.2885C106.868 44.2932 106.953 44.296 107.04 44.296C108.046 44.296 108.786 43.8155 109.182 42.9662C109.534 42.1952 109.551 41.2976 109.225 40.5126L104.791 29.8143L104.79 29.8162Z" />
    </Svg>
  )
}
export default Logo
