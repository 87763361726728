import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ExpandLessIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M11.2902 8.71047L6.70021 13.3005C6.31021 13.6905 6.31021 14.3205 6.70021 14.7105C7.09021 15.1005 7.72021 15.1005 8.11021 14.7105L12.0002 10.8305L15.8802 14.7105C16.2702 15.1005 16.9002 15.1005 17.2902 14.7105C17.6802 14.3205 17.6802 13.6905 17.2902 13.3005L12.7002 8.71047C12.3202 8.32047 11.6802 8.32047 11.2902 8.71047Z" />
  </Svg>
)

export default ExpandLessIcon
