import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const BellOffIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.89 22 12 22ZM18 11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C10.26 4.74 10.03 4.83 9.81 4.91L18 13.1V11ZM5.41 3.35L4 4.76L6.81 7.57C6.29 8.57 6 9.73 6 11V16L4.71 17.29C4.08 17.92 4.52 19 5.41 19H18.24L19.98 20.74L21.39 19.33L5.41 3.35Z" />
  </Svg>
)

export default BellOffIcon
