import React from 'react'
import { BlurView } from 'src/utils/webAdapters/@react-native-community/blur'
import { View, Platform, StyleSheet } from 'react-native'

import SelectableText from 'src/components/SelectableText'
import styles from './styles'

const isIOS = Platform.OS === 'ios'

type SectionHeaderProps = {
  title: string
  withSmallTopSpacing?: boolean
}

const SectionHeader = ({ title, withSmallTopSpacing = false }: SectionHeaderProps) => {
  const renderContent = () => (
    <SelectableText numberOfLines={1} style={styles.title}>
      {title}
    </SelectableText>
  )

  const containerStyles = StyleSheet.flatten([
    styles.container,
    withSmallTopSpacing ? styles.smallContainerPadding : styles.containerPadding,
  ])

  return isIOS ? (
    <BlurView blurAmount={3} blurType="light" style={containerStyles}>
      {renderContent()}
    </BlurView>
  ) : (
    <View style={containerStyles}>{renderContent()}</View>
  )
}

export default SectionHeader
