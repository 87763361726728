import Api from 'src/utils/api'

import { OnErrorT, OnSuccessT } from 'src/utils/types'
import { SEQualificationT, UKQualificationT } from '../../types'

type StudentEducationT = UKQualificationT | SEQualificationT

export const deleteStudentEducation = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) =>
  Api.delete(`/student/student_educations/${id}`, {
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })

export const updateStudentEducation = (
  id: string,
  params: StudentEducationT,
  onSuccess: OnSuccessT,
  onError: OnErrorT,
) =>
  Api.put(`/student/student_educations/${id}`, {
    data: { student_education: params },
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })

export const createStudentEducation = (params: StudentEducationT, onSuccess: OnSuccessT, onError: OnErrorT) =>
  Api.post('/student/student_educations', {
    data: { student_education: params },
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })
