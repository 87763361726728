import { StyleSheet } from 'react-native'

import { padding, colorsNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    backgroundColor: colorsNew.white,
    paddingBottom: global.isIOS ? padding.xxl : paddingNew.base,
    paddingHorizontal: paddingNew.sm,
  },
  list: {
    backgroundColor: colorsNew.white,
  },
  sectionDivider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginVertical: paddingNew.sm,
  },
  listMargin: {
    paddingBottom: paddingNew.md,
  },
  emptyContainer: {
    paddingTop: paddingNew.lg,
  },
})
