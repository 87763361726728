import React from 'react'
import { useEffect, useRef, useState, useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Text } from 'react-native'

import { renderHeaderButton } from 'src/utils/headerConfig/renderHeaderButton'
import { setTabOverlay } from 'src/store/app/actions'
import { useAppDispatch } from './reduxHooks'
import { navigateToAppScreen } from '../utils/navigateToScreen'
import styles from 'src/utils/headerConfig/styles'

const useTabBarOverlay = (showOverlay: boolean, headerTitle?: string, navigationLocked?: boolean) => {
  const [isCustomOverlay, setIsCustomOverlay] = useState(showOverlay)

  useEffect(() => {
    showOverlay && setIsCustomOverlay(true)
  }, [showOverlay])

  const timer = useRef<NodeJS.Timeout>()

  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  //@ts-ignore
  const onBackButtonClick = () => {
    navigation.canGoBack()
      ? navigation.goBack()
      : navigateToAppScreen(['MainTabNavigator', 'JobsNavigator', 'Requests'])
  }

  const showBottomTabBar = () => {
    setIsCustomOverlay(false)
    dispatch(setTabOverlay(false))
  }

  const goBack = () => {
    showBottomTabBar()
    timer.current = setTimeout(() => onBackButtonClick(), 0)
  }

  const onBackArrowPress = useCallback(() => {
    if (navigationLocked) return renderHeaderButton('inactive', () => null)
    return isCustomOverlay ? renderHeaderButton('back', goBack) : renderHeaderButton('back', onBackButtonClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationLocked, isCustomOverlay])

  useEffect(
    () =>
      navigation.addListener('beforeRemove', e => {
        if (!navigationLocked) {
          dispatch(setTabOverlay(false))
          return
        }

        e.preventDefault()
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigation, navigationLocked],
  )

  useEffect(
    () => {
      isCustomOverlay && dispatch(setTabOverlay(true))
      navigation.setOptions({
        headerLeft: onBackArrowPress(),
        ...(headerTitle && {
          headerTitle: () => (
            <Text numberOfLines={2} style={styles.onboardingHeaderTitle}>
              {headerTitle}
            </Text>
          ),
        }),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCustomOverlay, headerTitle, navigationLocked],
  )

  return { isCustomOverlay, showBottomTabBar, goBack }
}

export default useTabBarOverlay
