import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const pointTitle = {
  ...typography.h7,
  marginBottom: paddingNew.sm,
}

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.sm,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLinkContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: paddingNew.md,
    marginTop: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  infoContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    paddingTop: paddingNew.md,
  },
  mainInfo: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.md,
  },
  sectionContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
    paddingTop: paddingNew.sm,
  },
  subInfo: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.md,
  },
  subtitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  textWithDot: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
  textWithDotContainer: {
    marginVertical: 0,
  },
  pointTitle: pointTitle,
  pointTitleSecond: {
    ...pointTitle,
    marginTop: paddingNew.sm,
  },
})

export default styles
