import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SuitcaseIcon = ({ fill = '#212121', size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M14 6.35864V4.35864H10V6.35864H14ZM4 9.35864V18.3586C4 18.9086 4.45 19.3586 5 19.3586H19C19.55 19.3586 20 18.9086 20 18.3586V9.35864C20 8.80864 19.55 8.35864 19 8.35864H5C4.45 8.35864 4 8.80864 4 9.35864ZM20 6.35864C21.11 6.35864 22 7.24864 22 8.35864V19.3586C22 20.4686 21.11 21.3586 20 21.3586H4C2.89 21.3586 2 20.4686 2 19.3586L2.01 8.35864C2.01 7.24864 2.89 6.35864 4 6.35864H8V4.35864C8 3.24864 8.89 2.35864 10 2.35864H14C15.11 2.35864 16 3.24864 16 4.35864V6.35864H20Z"
      fill={fill}
    />
  </Svg>
)

export default SuitcaseIcon
