import React, { useCallback, useContext, useState } from 'react'
import { Text, ScrollView, View } from 'react-native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { getNorthpassLoginUrl } from 'src/utils/northpassUrl'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import StatusInfo from 'src/scenes/Main/Profile/RequiredTasks/components/StatusInfo/StatusInfo'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'AcademyIntroduction'>

const AcademyIntroduction = ({ route }: Props) => {
  const { status, timeToComplete } = route.params
  const taskStatusCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - AcademyIntroduction')

  const [urlError, setUrlError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const goToAcademy = (url: string) => url && openURLIfCan(url)

  const onSuccess = useCallback((url: string) => {
    setUrlError(false)
    setIsLoading(false)
    goToAcademy(url)
  }, [])

  const onError = useCallback(() => {
    setUrlError(true)
    setIsLoading(false)
  }, [])

  const onAcademyPress = () => {
    bugsnagActionBreadcrumb("Set user's Northpass academy introduction")

    mixpanel?.track('Recruitment: Begin Training SE')

    if (!isLoading) {
      setIsLoading(true)
      getNorthpassLoginUrl({ onError, onSuccess })
    }
  }

  if (taskStatusCompleted) {
    return (
      <ScrollView
        contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
        showsVerticalScrollIndicator={false}
        testID="scrollView"
      >
        <StatusInfo status={status} timeToComplete={timeToComplete} />
        <Text style={styles.title}>{translate(translations.academyCompletedTitle)}</Text>
        <Text style={styles.description}>{translate(translations.academyCompletedInfo)}</Text>
      </ScrollView>
    )
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo status={status} timeToComplete={timeToComplete} />
      <Text style={styles.title}>{translate(translations.academyTitle)}</Text>
      <Text style={styles.description}>{translate(translations.academyInfoText)}</Text>
      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={urlError || isLoading}
          isSubmitting={isLoading}
          onPress={onAcademyPress}
          RightIcon={ChevronRightIcon}
          testID="academy-introduction-button"
          title={translate(translations.academyButton)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      {urlError ? (
        <Text style={styles.errorText}>{translate(translations.academyUrlError)}</Text>
      ) : (
        <Text style={styles.infoText}>{translate(translations.academyButtonInfo)}</Text>
      )}
      <View style={styles.infoContainer}>
        <Text style={styles.infoText}>{translate(translations.academyTaskDescription)}</Text>
      </View>
    </ScrollView>
  )
}

export default AcademyIntroduction
