import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  radioButtonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: paddingNew.sm,
  },
  radioButtonText: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
  },
  radioButtonDisabledText: {
    color: colorsNew.redesign.action.disabled,
  },
  radioButtonFullText: {
    flex: 1,
  },
  disabledButton: {
    borderColor: colorsNew.redesign.action.disabled,
  },
  disabledButtonIcon: {
    backgroundColor: colorsNew.redesign.action.disabled,
  },
  disabledLabel: {
    color: colorsNew.redesign.text.disabled,
  },
})
