import React from 'react'
import { Text, View, ViewStyle } from 'react-native'
import { ProgressBar as ProgressBarComponent } from 'react-native-paper'

import { colorsNew } from 'src/styles'
import { TTypes } from './types'
import style from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { progressBarVariants } from './variants'

type Props = {
  progress: number // 0 - 1
  styles?: ViewStyle
  title?: string
  variant: TTypes
  withProgress?: boolean
}

const getStyles = ({ variant, styles }: { variant: TTypes; styles: object }) => {
  const componentStyles = { color: '', style: styles }

  if (variant === progressBarVariants.success) {
    componentStyles.style = [style.successStyle, styles]
    componentStyles.color = colorsNew.redesign.success.shades50p
  }

  return componentStyles
}

const ProgressBar = ({ title, withProgress, variant, styles = {}, progress = 0 }: Props) => {
  const getProgress = `${(progress * 100).toFixed(0)}%`
  const componentStyles = getStyles({ variant, styles })

  return (
    <View>
      <View style={style.textContainer}>
        {title && (
          <Text style={style.text} testID="progressBarTitle">
            {title}
          </Text>
        )}
        {withProgress && (
          <Text style={style.text} testID="progressBarProgress">
            {translate(translations.progressDone, { numberOfTasks: getProgress })}
          </Text>
        )}
      </View>
      <ProgressBarComponent progress={progress} color={componentStyles.color} style={componentStyles.style} />
    </View>
  )
}

export default ProgressBar
