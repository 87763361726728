import React, { useContext, useCallback, useState } from 'react'
import { AxiosError } from 'axios'
import { Platform, View } from 'react-native'

import {
  applicationStatus,
  ApplicationStatus,
} from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/applicationTypes'
import {
  acceptOrder,
  createApplication,
  ignoreOrder,
  lockOrder,
} from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/actions'
import { CalendarBooking, synchronizeCalendar } from 'src/utils/calendarIntegration/calendarActions'
import { ChevronRightIcon } from 'src/icons'
import { ComplianceStatusContext } from 'src/context/ComplianceStatusContext'
import { directApplications } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/utils'
import { ErrorResponseT } from 'src/utils/types'
import { isSESelector } from 'src/store/app/selectors'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { workPermitInvalidStatusCode } from 'src/global/constants'
import ActionsOverlay from 'src/scenes/Main/Jobs/Details/components/ActionsOverlay'
import ApplicationModal, { ApplicationOfferDataType } from 'src/scenes/Main/Jobs/Details/components/ApplicationModal'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import ErrorModal from 'src/scenes/Main/Jobs/Details/components/ErrorModal'
import IgnoreOfferModal from 'src/scenes/Main/Jobs/Details/components/IgnoreOfferModal'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import WorkPermitWarningModal from 'src/scenes/Main/Jobs/Details/components/WorkPermitWarningModal/WorkPermitWarningModal'

type Props = {
  bookingCalendarData?: CalendarBooking[]
  goBack: () => void
  isUpdating: boolean
  isUserWorkPermitInvalid: boolean
  jobConfirmationData: ApplicationOfferDataType
  orderId: string
  orderStatus?: ApplicationStatus
  refetch: () => Promise<unknown>
  setIsUpdating: (value: boolean) => void
  setShowJobConfirmation: () => void
  workPermitExpiresOn: string
}

const isWeb = Platform.OS === 'web'

const AcceptOverlay = ({
  bookingCalendarData,
  goBack,
  isUpdating,
  isUserWorkPermitInvalid,
  jobConfirmationData,
  orderId,
  orderStatus,
  refetch,
  setIsUpdating,
  setShowJobConfirmation,
  workPermitExpiresOn,
}: Props) => {
  const { isUserCompliant } = useContext(ComplianceStatusContext)
  const isSE = useAppSelector(isSESelector)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [showWorkPermitModal, setShowWorkPermitModal] = useState(false)

  const isUserRequested = orderStatus === applicationStatus.userRequested
  const isDirect = orderStatus ? directApplications.includes(orderStatus) : false
  const isBookingUnconfirmed = orderStatus === applicationStatus.unconfirmedExpress
  const isConfirmation = orderStatus === applicationStatus.userAssigned

  const { mixpanel } = useContext(MixpanelContext)

  const onConfirmSuccess = useCallback(() => {
    if (refetch) refetch()
    setIsUpdating(false)
    setShowJobConfirmation()

    const shouldUpdateCalendar = !isWeb && (isDirect || isConfirmation) && bookingCalendarData
    shouldUpdateCalendar && synchronizeCalendar(bookingCalendarData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onApplicationSuccess = useCallback(() => {
    setIsUpdating(false)
    if (refetch) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRequestError = useCallback((errorResponse: AxiosError<ErrorResponseT>) => {
    if (refetch) refetch()

    const errorCode = errorResponse?.data?.errors?.[0].code
    const isWorkPermitInvalid = errorCode === workPermitInvalidStatusCode

    if (isWorkPermitInvalid) setShowWorkPermitModal(true)

    setIsUpdating(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onConfirm = () => {
    setIsUpdating(true)
    setShowConfirmationModal(false)

    if (isBookingUnconfirmed) {
      const confirmApplication = () => acceptOrder(orderId, onConfirmSuccess, onRequestError, setErrorMessage)
      mixpanel?.track('Confirm Express Order', { orderId })
      return lockOrder(orderId, confirmApplication)
    }
    if (isDirect || isConfirmation) {
      mixpanel?.track('Confirm Order', { orderId })
      return acceptOrder(orderId, onConfirmSuccess, onRequestError, setErrorMessage)
    }
    mixpanel?.track('Apply Order', { orderId })
    return createApplication(orderId, onApplicationSuccess, onRequestError, setErrorMessage)
  }

  const onIgnore = () => {
    setIsUpdating(true)
    setShowRejectionModal(false)
    ignoreOrder(orderId, goBack, onRequestError)
    mixpanel?.track('Reject Order', { orderId })
  }

  return (
    <>
      <ActionsOverlay>
        {isConfirmation || isBookingUnconfirmed ? (
          <ButtonNew
            disabled={isUpdating || !isUserCompliant || isUserWorkPermitInvalid}
            isLong
            onPress={onConfirm}
            testID="confirm-button"
            title={
              isBookingUnconfirmed ? translate(translations.confirmExpressButton) : translate(translations.confirm)
            }
            variant={buttonVariants.containedDefault}
          />
        ) : (
          <>
            <ButtonNew
              disabled={isUpdating || !isUserCompliant}
              isLong
              onPress={() => setShowRejectionModal(true)}
              testID="ignore-button"
              title={translate(translations.jobOfferIgnore)}
              variant={buttonVariants.outlinedDefault}
            />
            <View style={styles.divider} />

            <ButtonNew
              disabled={isUpdating || !isUserCompliant || isUserWorkPermitInvalid}
              isLong={!isSE}
              flexBasis={isSE ? '60%' : 'auto'}
              onPress={() => setShowConfirmationModal(true)}
              RightIcon={!isUserRequested ? ChevronRightIcon : undefined}
              testID="accept-button"
              title={isUserRequested ? translate(translations.accept) : translate(translations.applyForJob)}
              variant={buttonVariants.containedDefault}
            />
          </>
        )}
      </ActionsOverlay>

      {!errorMessage ? (
        <ApplicationModal
          closeApplicationModal={() => setShowConfirmationModal(false)}
          isVisible={showConfirmationModal}
          onConfirm={onConfirm}
          {...jobConfirmationData}
        />
      ) : (
        <ErrorModal
          closeErrorModal={() => setErrorMessage(null)}
          description={errorMessage}
          isVisible={!!errorMessage}
        />
      )}

      <IgnoreOfferModal
        closeIgnoreModal={() => setShowRejectionModal(false)}
        isVisible={showRejectionModal}
        onIgnore={onIgnore}
      />

      <WorkPermitWarningModal
        closeWarningModal={() => setShowWorkPermitModal(false)}
        isVisible={showWorkPermitModal}
        workPermitExpiresOn={workPermitExpiresOn}
      />
    </>
  )
}

export default AcceptOverlay
