import gql from 'graphql-tag'

import ExpressBookingFields from './Fields'
import {
  ExpressBookingsTypes,
  ExpressBookingRequestTypes,
  ExpressBookingsRequestTypes,
  ExpressBookingsTimesheetTypes,
  ExpressBookingsTimesheetRequestTypes,
} from './types'

export type {
  ExpressBookingsTypes,
  ExpressBookingRequestTypes,
  ExpressBookingsRequestTypes,
  ExpressBookingsTimesheetTypes,
  ExpressBookingsTimesheetRequestTypes,
}

export default gql`
  query Index(
    $maxDate: Date
    $minDate: Date
    $student: String
    $include_appointed: Boolean
    $include_available: Boolean
    $includeAppointedBookingExpress: Boolean
    $only_matching: Boolean
  ) {
    expressBookings(
      filter: {
        maxDate: $maxDate
        minDate: $minDate
        student: $student
        include_appointed: $include_appointed
        include_available: $include_available
        includeAppointedBookingExpress: $includeAppointedBookingExpress
        only_matching: $only_matching
      }
    ) {
      nodes {
        ...ExpressBookingFields
      }
    }
  }
  ${ExpressBookingFields}
`
