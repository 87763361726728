import React, { useCallback, useEffect, useState } from 'react'
import { RESULTS, checkNotifications } from 'src/utils/webAdapters/RNPermissionsAdapter'
import { StyleSheet, Text, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import { NotificationsResponse } from 'react-native-permissions/src/types'

import { BellOnIcon, BellOffIcon } from 'src/icons'
import { resetNotifications } from 'src/utils/pushNotifications/useSetupNotifications'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { useAppState } from 'src/utils/AppStateEvent'
import { useBugsnagView } from 'src/utils/bugsnag'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import LoadingIndicator from 'src/components/LoadingIndicator'
import translations, { translate } from 'src/utils/translations/translations'
import type { UserTypes } from 'src/graphql/User/types'
import UserQuery from 'src/graphql/User/Show'
import { colorsNew } from 'src/styles'
import styles from './styles'
import { pushNotificationButton } from 'src/scenes/Main/Profile/PushNotifications/utils'

const status = {
  error: {
    badgeColor: colorsNew.redesign.error.shades12p,
    badgeInfo: 'chipError',
    badgeInfoColor: colorsNew.redesign.error.text,
    Icon: <BellOffIcon fill={colorsNew.redesign.error.main} size={18} />,
    info: 'pushNotificationsError',
  },
  notPermitted: {
    badgeColor: colorsNew.redesign.warning.shades12p,
    badgeInfo: 'chipNotPermitted',
    badgeInfoColor: colorsNew.redesign.warning.text,
    Icon: <BellOffIcon fill={colorsNew.redesign.warning.main} size={18} />,
    info: 'pushNotificationsNotPermitted',
  },
  permitted: {
    badgeColor: colorsNew.redesign.success.shades12p,
    badgeInfo: 'chipPermitted',
    badgeInfoColor: colorsNew.redesign.success.text,
    Icon: <BellOnIcon fill={colorsNew.redesign.success.main} size={18} />,
    info: 'pushNotificationsPermitted',
  },
}

const PushNotifications = () => {
  const [hasPermissions, setHasPermissions] = useState(false)
  const [isResetting, setIsResetting] = useState(false)

  const dispatch = useAppDispatch()
  const userId = useAppSelector(state => state.user.id) as string

  useBugsnagView('Profile - PushNotifications')

  const { refetch, loading, data } = useQuery<UserTypes>(UserQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !userId,
    variables: {
      id: userId,
    },
  })

  const checkPermissions = async () =>
    checkNotifications().then((result: NotificationsResponse) =>
      setHasPermissions([RESULTS.GRANTED, RESULTS.LIMITED].includes(result.status)),
    )

  useEffect(() => {
    pushNotificationButton.isClicked = false
  }, [])

  useAppState({
    onForeground: checkPermissions,
  })

  useFocusEffect(
    useCallback(() => {
      checkPermissions()
      refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const resetDeviceToken = async () => {
    setIsResetting(true)
    pushNotificationButton.isClicked = true
    await resetNotifications({ userId, dispatch })
    setIsResetting(false)
  }

  const activeInstallation = data?.user?.activeInstallation
  const currentNotificationsStatus = !activeInstallation ? 'error' : hasPermissions ? 'permitted' : 'notPermitted'
  const isPermitted = currentNotificationsStatus === 'permitted'

  const { badgeColor, badgeInfo, badgeInfoColor, Icon, info } = status[currentNotificationsStatus]

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      <View style={styles.statusWrapper}>
        <Text style={styles.statusHeader}>{translate(translations.pushNotificationsStatus)}</Text>
        <View style={StyleSheet.flatten([styles.badge, { backgroundColor: badgeColor }])}>
          {Icon}
          <Text style={StyleSheet.flatten([styles.badgeText, { color: badgeInfoColor }])}>
            {translate(translations[badgeInfo])}
          </Text>
        </View>
      </View>
      <Text style={styles.mainInfo}>{translate(translations[info])}</Text>
      <View style={styles.buttonWrapper}>
        <ButtonNew
          containerStyle={styles.button}
          disabled={isResetting}
          isLong
          onPress={resetDeviceToken}
          size={!isPermitted ? buttonSize.lg : buttonSize.sm}
          title={translate(translations.resetPushToken)}
          variant={!isPermitted ? buttonVariants.containedDefault : buttonVariants.outlinedDefault}
        />
      </View>
    </View>
  )
}

export default PushNotifications
