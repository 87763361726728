import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    borderRadius: 50,
    flexDirection: 'row',
    paddingLeft: paddingNew.xs,
    paddingRight: 14,
    paddingVertical: paddingNew.xxs,
  },
  badgeText: {
    ...typography.chipTextSmall,
    marginLeft: 6,
  },
  button: {
    marginVertical: paddingNew.md,
  },
  buttonWrapper: {
    alignItems: 'center',
  },
  container: {
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.md,
  },
  mainInfo: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.sm,
  },
  statusHeader: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginRight: paddingNew.xs,
  },
  statusWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default styles
