import React, { useEffect, useRef } from 'react'
import { InteractionManager } from 'react-native'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import LottieView from 'lottie-react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import SuccessAnimationRender from 'src/scenes/Main/Salary/SuccessAnimation/SuccessAnimationRender'
import translations, { translate } from 'src/utils/translations/translations'

type NavigationTypes = {
  ReportTime: { id?: string }
  Feedback: { bookingId?: string }
}

const SuccessAnimation = () => {
  const refAnimation = useRef<LottieView | null>(null)
  const navigation = useNavigation<StackNavigationProp<NavigationTypes>>()
  const { params } = useRoute<RouteProp<{ params: { isFeedbackSubmitted?: boolean; id: string } }>>()

  useBugsnagView('SuccessAnimation')

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (refAnimation?.current) {
        refAnimation.current.play()
      }
    })
  }, [])

  const goToNextScreen = () => {
    bugsnagActionBreadcrumb('goToNextScreen on success animation', { isFeedbackSubmitted: params?.isFeedbackSubmitted })

    return params?.isFeedbackSubmitted
      ? navigation.navigate('ReportTime', { id: params?.id })
      : navigation.navigate('Feedback', { bookingId: params?.id })
  }

  return (
    <SuccessAnimationRender
      onAnimationFinish={goToNextScreen}
      refAnimation={refAnimation}
      successText={translate(translations.completeTimesheetSuccessTitle)}
    />
  )
}

export default SuccessAnimation
