import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default styles
