import React, { useContext } from 'react'
import { Text, View } from 'react-native'

import { ComplianceStatusContext } from 'src/context/ComplianceStatusContext'
import { getComplianceStatusInfo } from './utils'
import styles from './styles'

const ComplianceStatus = () => {
  const { complianceStatus } = useContext(ComplianceStatusContext)
  const complianceStatusInfo = getComplianceStatusInfo(complianceStatus)

  if (!complianceStatusInfo) return null

  return (
    <View style={styles.container}>
      <Text style={styles.info} testID="complianceStatus">
        {complianceStatusInfo.text}
      </Text>
      {complianceStatusInfo.StatusIcon}
    </View>
  )
}

export default ComplianceStatus
