import React from 'react'
import moment from 'moment'
import { StyleSheet, View, Text } from 'react-native'

import { CalendarCardIcon, CalendarCardMultidayIcon } from 'src/icons'
import ShadowWrapper from 'src/components/ShadowWrapper'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { TimeScheduleTypes } from 'src/graphql/sharableTypes'
import WarningIcon from 'src/icons/redesign/warning'
import VerifiedIcon from 'src/icons/redesign/verified'

export type ShortTermOfferData = {
  isReported?: boolean
  isTimesheet?: boolean
  numberOfDays?: number
  startDate: string | Date
  subtitle: string
  timeScheduleType?: TimeScheduleTypes | string
  title: string
  workingTime?: string
}
type Props = ShortTermOfferData & {
  onPress: () => void
}

const titleContent = (title: string, isTimesheet?: boolean, isReported?: boolean) => {
  if (isTimesheet) {
    return (
      <View style={styles.timesheetTitleContainer}>
        <Text numberOfLines={1} style={styles.title}>
          {title}
        </Text>
        <View style={styles.timesheetStatusContainer}>
          <Text style={styles.timesheetStatus}>
            {isReported ? translate(translations.timesheetReported) : translate(translations.timesheetReport)}
          </Text>
          {isReported ? (
            <VerifiedIcon size={16} fill={styles.timesheetStatusIconReported.color} />
          ) : (
            <WarningIcon size={16} fill={styles.timesheetStatusIcon.color} />
          )}
        </View>
      </View>
    )
  }

  return (
    <Text numberOfLines={1} style={styles.title}>
      {title}
    </Text>
  )
}

const ShortTermOfferItem = ({
  isReported,
  isTimesheet,
  numberOfDays,
  onPress,
  startDate,
  subtitle,
  title,
  workingTime,
}: Props) => {
  const isMultiDay = numberOfDays && numberOfDays > 0

  const day = moment(startDate).format('D') || ''
  const month = moment(startDate).format('MMM') || ''

  return (
    <ShadowWrapper borderRadius={4} onPress={onPress} testID="short-term-offer-item">
      <View style={styles.card}>
        <View>
          <View style={styles.calendarCard}>
            {isMultiDay ? <CalendarCardMultidayIcon size={40} /> : <CalendarCardIcon size={40} />}
            <View style={StyleSheet.flatten([styles.body, isMultiDay ? styles.bodyMultiDay : styles.bodySingleDay])}>
              <Text style={styles.day}>{day}</Text>
              <Text style={styles.month}>{month}</Text>
            </View>
          </View>
          {isMultiDay ? (
            <Text style={styles.numberOfDays}>{translate(translations.days, { days: numberOfDays })}</Text>
          ) : null}
        </View>
        <View style={styles.cardContent}>
          {titleContent(title, isTimesheet, isReported)}
          <View style={styles.detailsContainer}>
            <Text numberOfLines={1} style={styles.subtitle}>
              {subtitle}
            </Text>
            <Text style={styles.workingTime} testID="column-dates-time">
              {workingTime}
            </Text>
          </View>
        </View>
      </View>
    </ShadowWrapper>
  )
}

export default ShortTermOfferItem
