import { Platform } from 'react-native'
import NetInfo, { NetInfoSubscription, NetInfoState } from '@react-native-community/netinfo'
import { isObject, get } from 'lodash'

import makeHttpRequest from './makeHttpRequest'
import store from 'src/store'
import translations, { translate } from 'src/utils/translations/translations'
import { DEFAULT_HTTP_METHOD, DEFAULT_PING_SERVER_URL, DEFAULT_TIMEOUT } from './constans'
import { setConnectionStatus } from 'src/store/app/actions'
import { SnackBarProps } from 'src/store/app'

let unsubscribe: NetInfoSubscription

export const addConnectionChangeListener = async () => {
  await checkConnection()

  removeConnectionChangeListener()

  unsubscribe = NetInfo.addEventListener(state => {
    handleConnectivityChange(state)
  })
}

const createSnackbarProps = (isConnected: boolean): SnackBarProps =>
  isConnected
    ? { visible: false, type: 'danger' }
    : {
        autoHide: false,
        message: translate(translations.snackbarDisconnectedMessage),
        type: 'danger',
        visible: true,
      }

const handleConnectivityChange = (connectionInfo: NetInfoState) => {
  const isConnected = connectionInfo.type !== 'none'
  const snackbarProps = createSnackbarProps(isConnected)
  store.dispatch(setConnectionStatus({ isConnected, snackbarProps }))
}

export const checkConnection = async () => {
  const isConnected = await checkInternetConnection()

  const snackbarProps = createSnackbarProps(isConnected)
  store.dispatch(setConnectionStatus({ isConnected, snackbarProps }))
}

export const removeConnectionChangeListener = () => unsubscribe && unsubscribe()

const getIsConnection = (connection: NetInfoState | boolean) =>
  isObject(connection) ? get(connection, 'details.isConnected', false) : connection || false

const checkInternetConnection = async (url = DEFAULT_PING_SERVER_URL, timeout = DEFAULT_TIMEOUT, shouldPing = true) =>
  NetInfo.fetch().then(async (isConnected: NetInfoState) => {
    const isWeb = Platform.OS === 'web'
    if (isWeb) {
      return true
    } else if (shouldPing) {
      const hasInternetAccess = (await checkInternetAccess({ timeout, url })) as boolean
      return getIsConnection(hasInternetAccess)
    }

    return getIsConnection(isConnected as NetInfoState)
  })

export default function checkInternetAccess({
  method = DEFAULT_HTTP_METHOD,
  timeout = DEFAULT_TIMEOUT,
  url = DEFAULT_PING_SERVER_URL,
} = {}) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    try {
      await makeHttpRequest({
        method,
        url,
        timeout,
      })
      resolve(true)
    } catch (e) {
      resolve(false)
    }
  })
}
