import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Api from 'src/utils/api'
import { countryCodeSelector } from 'src/store/app/selectors'
import { getApiUrl } from 'src/utils/apiConfig'
import { fetchUser, logoutUser } from 'src/store/user/actions'
import { bugsnagNotifyWithData, bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { useAppDispatch } from 'src/hooks/reduxHooks'

const useInitUrlApp = () => {
  const userId = useSelector(state => state?.user?.id)
  const countryCode = useSelector(countryCodeSelector)
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(true)

  const init = async () => {
    try {
      const url = getApiUrl(countryCode)
      Api.changeUrl(url)
    } catch (e) {
      bugsnagNotifyWithData('User logout on init')
      dispatch(logoutUser())
    }

    if (userId) {
      await dispatch(fetchUser(userId))
      setIsLoading(false)
    } else {
      bugsnagActionBreadcrumb('User logout on init #2')
      dispatch(logoutUser())
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoading }
}

export default useInitUrlApp
