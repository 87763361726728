import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginVertical: paddingNew.xs,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxContainer: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.sm,
    paddingBottom: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
    paddingBottom: paddingNew.md,
  },
  helper: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
  },
  mainText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  sectionContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.base,
    paddingTop: paddingNew.xs,
  },
  textDisabled: {
    color: colorsNew.redesign.text.disabled,
  },
})

export default styles
