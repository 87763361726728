import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'
import globalStyles from 'src/global/globalStyles'

const styles = StyleSheet.create({
  page: {
    backgroundColor: colorsNew.white,
    flex: 1,
    paddingHorizontal: paddingNew.sm,
    ...globalStyles.containerSize,
  },
  header: {
    ...typography.body1,
    paddingVertical: paddingNew.md,
  },
  alertContainer: {
    marginTop: paddingNew.lg,
  },
  bodyContainer: {
    marginLeft: paddingNew.sm,
  },
  infoTitle: {
    ...typography.body1,
    color: colorsNew.redesign.info.text,
    paddingBottom: paddingNew.xxs,
  },
  infoBody: {
    ...typography.body2,
    color: colorsNew.redesign.info.text,
  },
})

export default styles
