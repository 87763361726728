import React from 'react'
import Svg, { Path, Rect } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#000'

const JobsIcon = ({ fill, size, ...props }: IconTypes) => {
  const fillColor = fill?.length ? fill : defaultFill

  return (
    <Svg height={size} viewBox="0 0 75 75" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect
        x={16.294}
        y={12.307}
        width={41.712}
        height={50.981}
        rx={2.317}
        fill="#fff"
        stroke={fillColor}
        strokeWidth={4.635}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.246 32.004c0-.64.519-1.159 1.159-1.159h25.49a1.159 1.159 0 0 1 0 2.318h-25.49c-.64 0-1.159-.52-1.159-1.159Zm0 6.952c0-.64.519-1.159 1.159-1.159h25.49a1.159 1.159 0 1 1 0 2.318h-25.49c-.64 0-1.159-.519-1.159-1.159Zm1.159 5.793a1.159 1.159 0 1 0 0 2.318h25.49a1.159 1.159 0 1 0 0-2.318h-25.49Z"
        fill={fillColor}
      />
      <Rect
        x={23.246}
        y={7.672}
        width={27.808}
        height={16.221}
        rx={4.635}
        fill="#fff"
        stroke={fillColor}
        strokeWidth={4.635}
      />
    </Svg>
  )
}

export default JobsIcon
