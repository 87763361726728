import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const HEIGHT = Dimensions.get('window').height
const isWeb = Platform.OS === 'web'

const MODAL_HEIGHT = HEIGHT * 0.9
const MAIN_CONTAINER_HEIGHT = HEIGHT * 0.8

export default StyleSheet.create({
  button: {
    marginBottom: paddingNew.md,
  },
  buttonContainer: {
    marginVertical: 0,
  },
  modalContent: !isWeb
    ? {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        maxHeight: MODAL_HEIGHT,
      }
    : { maxHeight: MODAL_HEIGHT },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    height: MAIN_CONTAINER_HEIGHT,
    display: 'flex',
    marginBottom: paddingNew.lg,
    marginHorizontal: 24,
    marginTop: paddingNew.sm,
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.other.divider,
    borderRadius: 2,
    height: 4,
    marginBottom: paddingNew.md,
    width: 48,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  subTitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  mainText: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  list: {
    marginTop: paddingNew.sm,
  },
  smallList: {
    marginTop: paddingNew.xs,
  },
  listTitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  listTextContainer: {
    flexDirection: 'row',
  },
  dot: {
    minWidth: 22,
    textAlign: 'center',
    width: 22,
  },
  listText: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    flexShrink: 1,
  },
  infoText: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    marginTop: paddingNew.sm,
  },
  buttonWrapper: {
    flexDirection: 'row',
    marginTop: paddingNew.md,
  },
})
