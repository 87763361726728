import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, padding, paddingNew } from 'src/styles'
import globalStyles from 'src/global/globalStyles'

const isWeb = Platform.OS === 'web'

const { height, width } = Dimensions.get('window')

const styles = StyleSheet.create({
  backButtonContainer: {
    marginBottom: paddingNew.md,
    alignItems: 'center',
  },

  backgroundImage: {
    alignSelf: 'flex-end',
    resizeMode: 'cover',
  },
  backgroundImageContainer: {
    ...(isWeb
      ? {
          paddingBottom: paddingNew.xl,
          paddingHorizontal: paddingNew.base,
          paddingTop: paddingNew.lg,
        }
      : {
          bottom: 0,
          height,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          width,
          zIndex: -1,
        }),
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: padding.lg,
  },
  confirmationInfo: {
    color: colorsNew.grey._700,
    fontFamily: fonts.muktaRegular,
    fontSize: fontSizesNew.xxs.size,
    lineHeight: fontSizesNew.xxs.lh,
    marginBottom: paddingNew.sm,
    textAlign: 'center',
  },
  confirmationInfoShort: {
    width: '80%',
  },
  content: {
    height: '110%',
    justifyContent: 'flex-start',
    maxWidth: 800,
  },
  errorInput: { borderColor: colorsNew.error },
  errorMessage: { color: colorsNew.error },
  errorText: {
    color: colorsNew.error,
    fontFamily: fonts.muktaRegular,
    fontSize: fontSizesNew.xxs.size,
    lineHeight: fontSizesNew.xxs.lh,
    marginVertical: paddingNew.sm,
    padding: paddingNew.xs,
    textAlign: 'left',
    marginBottom: paddingNew.sm,
  },
  errorTextSmall: {
    marginVertical: paddingNew.xs,
    marginBottom: 0,
  },
  header: {
    color: colorsNew.black,
    fontSize: fontSizesNew.lg.size,
    fontWeight: '700',
    lineHeight: fontSizesNew.lg.lh,
    marginBottom: paddingNew.sm,
  },
  headerSmall: {
    fontSize: fontSizesNew.md.size,
    lineHeight: fontSizesNew.md.lh,
  },
  icon: {
    color: colorsNew.grey._600,
    marginRight: paddingNew.base / 2,
  },
  iconWrapper: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.primary.shades30p,
    borderRadius: 17,
    height: 118,
    justifyContent: 'center',
    marginBottom: paddingNew.lg,
    width: 118,
  },
  info: {
    color: colorsNew.grey._700,
    fontFamily: fonts.muktaSemiBold,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
    lineHeight: fontSizesNew.xs.lh,
    marginBottom: paddingNew.base,
  },
  input: {
    backgroundColor: colorsNew.white,
    color: colorsNew.brand.secondaryDark,
    flex: 0,
    fontFamily: fonts.muktaSemiBold,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
  },
  resetPasswordButton: {
    height: 48,
  },
  successMessageInfo: { margin: 0, padding: 0, width: '80%' },
  successMessageWrapper: {
    alignItems: 'center',
    flex: 1,
    marginTop: paddingNew.base,
  },
  mainContainer: {
    flex: 1,
    marginTop: paddingNew.base,
  },
  mainContainerSmall: {
    marginTop: paddingNew.sm,
  },
  wrapperViewSpacing: {
    flex: 1,
    ...(isWeb ? { backgroundColor: colorsNew.loginBackground } : { padding: paddingNew.base }),
  },
  page: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: paddingNew.base,
    ...globalStyles.containerSize,
  },
})

export default styles
