import { View, Text, StyleSheet } from 'react-native'
import React from 'react'

import AvailabilityForm from './AvailabilityForm/AvailabilityForm'
import BookingsList from './BookingsList'
import SwitchElement from 'src/components/SwitchElement'
import formatter from 'src/utils/formatter'
import { useAvailabilitySchedule } from '../availabilityContext/availabilityContext'
import styles from './styles'

const DayAvailability = () => {
  const { state, selectedDay, updateDayAvailability, isSubmitting } = useAvailabilitySchedule()

  const bookings = state.availabilityList[selectedDay]?.bookings || []
  const showBookingSection = bookings.length > 0
  const isExpress = showBookingSection && state.availabilityList[selectedDay]?.isExpressBooking
  const isSwitchOn = !state.availabilityList[selectedDay]?.inactive

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={StyleSheet.flatten([styles.headerText, !isSwitchOn && styles.headerInactive])}>
          {formatter.longWeekdayAndMonth(selectedDay)}
        </Text>
        {!showBookingSection ? (
          <SwitchElement
            disabled={isSubmitting}
            isSwitchOn={isSwitchOn}
            onToggle={updateDayAvailability}
            testID="switch"
          />
        ) : null}
      </View>
      {!showBookingSection && isSwitchOn ? <AvailabilityForm /> : null}
      {showBookingSection ? <BookingsList bookings={bookings} isExpress={isExpress} /> : null}
    </View>
  )
}

export default DayAvailability
