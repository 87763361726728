import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ArrowLeftIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M20 11.5H7.83L13.42 5.91L12 4.5L4 12.5L12 20.5L13.41 19.09L7.83 13.5H20V11.5Z" />
  </Svg>
)

export default ArrowLeftIcon
