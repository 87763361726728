import React, { useState, useLayoutEffect } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { ScrollView, StyleSheet } from 'react-native'

import { AppScreenProps } from 'src/utils/types/navigationTypes'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { renderHeaderButton } from 'src/utils/headerConfig/renderHeaderButton'
import FeedbackGeneral from './FeedbackForm/FeedbackGeneral'
import FeedbackTutor from './FeedbackForm/FeedbackTutor'
import styles from './styles'
import SuccessAnimation from './SuccessAnimation'
import translations, { translate } from 'src/utils/translations/translations'
import useTimesheetReport, { reportTypes } from 'src/hooks/useTimesheetReport'
import globalStyles from 'src/global/globalStyles'

const Feedback = () => {
  const navigation = useNavigation<AppScreenProps<'Feedback'>['navigation']>()
  const { params } = useRoute<AppScreenProps<'Feedback'>['route']>()

  const [showAnimation, setShowAnimation] = useState(false)

  const { refetch, isTutor, pupil } = useTimesheetReport(params?.bookingId) as reportTypes

  const navigateToJobSummary = () => {
    bugsnagActionBreadcrumb('go to reportTime', { id: params?.bookingId })
    return navigation.navigate('ReportTime', { id: params?.bookingId })
  }

  useBugsnagView('Feedback')

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: isTutor
        ? renderHeaderButton('inactive', () => null)
        : renderHeaderButton('back', navigateToJobSummary),
      headerTitle: isTutor ? translate(translations.tutoringReport) : translate(translations.howWasYourDay),
    })
  })

  const onSubmitSuccess = () => {
    refetch()
    setShowAnimation(true)
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={StyleSheet.flatten([
          isTutor ? styles.screenTutorContainer : styles.screenContainer,
          globalStyles.containerSize,
        ])}
        showsVerticalScrollIndicator={false}
      >
        {isTutor ? (
          <FeedbackTutor onSubmitSuccess={onSubmitSuccess} bookingId={params?.bookingId} pupil={pupil} />
        ) : (
          <>
            <FeedbackGeneral
              navigateToJobSummary={navigateToJobSummary}
              onSubmitSuccess={onSubmitSuccess}
              bookingId={params?.bookingId}
              schoolType={params?.schoolType}
            />
          </>
        )}
      </ScrollView>
      <SuccessAnimation shouldPlay={showAnimation} onFinish={navigateToJobSummary} />
    </>
  )
}

export default Feedback
