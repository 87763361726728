/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect } from 'react'
import { Platform } from 'react-native'
import Bugsnag from '@bugsnag/react-native'
import { BUGSNAG_API_KEY } from 'src/utils/webAdapters/DotEnvAdapter'
import { NotifiableError, OnErrorCallback, Stackframe } from '@bugsnag/core/types/common'

import store from 'src/store'

const isNotDev = !__DEV__

const isWeb = Platform.OS === 'web'

const isBugsnagMethod = (stackTraces: Stackframe[]) =>
  stackTraces.filter(
    stack =>
      !['bugsnagViewBreadcrumb', 'bugsnagActionBreadcrumb', 'bugsnagNotifyWithData', 'bugsnagNotify'].includes(
        stack.method as string,
      ),
  )

export const setBugsnagStart = () => {
  if (isNotDev) {
    const { codeBundleId } = require('../../package.json')

    if (isWeb) {
      const BugsnagPluginReact = require('@bugsnag/plugin-react')
      Bugsnag.start({
        // @ts-ignore
        apiKey: BUGSNAG_API_KEY,
        codeBundleId,
        maxBreadcrumbs: 75, // set in AndroidManifest an Info.plist
        plugins: [new BugsnagPluginReact()],
      })
    } else {
      if (codeBundleId.includes('v1')) {
        Bugsnag.start({
          onError: event => {
            event.errors[0].stacktrace = isBugsnagMethod(event.errors[0].stacktrace)
          },
        })
      } else {
        Bugsnag.start({
          codeBundleId,
          onError: event => {
            event.errors[0].stacktrace = isBugsnagMethod(event.errors[0].stacktrace)
          },
        })
      }
    }
  }
}

export const setBugsnagUser = (userId: string) => {
  if (isNotDev) {
    Bugsnag.setUser(userId)
  }
}

export const bugsnagNotify = (event: NotifiableError, onError?: OnErrorCallback) => {
  if (isNotDev && store?.getState?.()?.app?.isConnected) {
    Bugsnag.notify(event, onError)
  }
}

export const bugsnagNotifyWithData = (eventText: string, data?: any) => {
  if (data) {
    bugsnagNotify(new Error(eventText), function (event) {
      event.addMetadata('extraParams', data)
    })
  } else {
    bugsnagNotify(new Error(eventText))
  }
}

export const bugsnagViewBreadcrumb = (bugsnagText: string, extraParams: { [key: string]: any } = {}) => {
  if (isNotDev) {
    Bugsnag.leaveBreadcrumb(bugsnagText, { type: 'view', ...extraParams })
  }
}

export const bugsnagActionBreadcrumb = (bugsnagText: string, extraParams: { [key: string]: any } = {}) => {
  if (isNotDev) {
    Bugsnag.leaveBreadcrumb(bugsnagText, { type: 'action', ...extraParams })
  }
}

export const useBugsnagView = (bugsnagText: string, extraParams: { [key: string]: any } = {}) => {
  useEffect(() => {
    bugsnagViewBreadcrumb(bugsnagText, extraParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
