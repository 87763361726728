import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#00B58B'

const StarIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg
    fill={fill?.length ? fill : defaultFill}
    height={size}
    viewBox="0 0 59 56"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path d="M27.846 1.548c.6-1.843 3.206-1.843 3.805 0l5.506 16.948a2 2 0 0 0 1.903 1.382h17.82c1.938 0 2.743 2.48 1.176 3.618L43.639 33.971a2 2 0 0 0-.727 2.236l5.507 16.948c.599 1.843-1.51 3.375-3.078 2.236L30.924 44.917a2 2 0 0 0-2.351 0L14.156 55.39c-1.568 1.14-3.677-.393-3.078-2.236l5.507-16.948a2 2 0 0 0-.727-2.236L1.441 23.496c-1.567-1.138-.762-3.618 1.176-3.618h17.82a2 2 0 0 0 1.903-1.382l5.506-16.948Z" />
  </Svg>
)

export default StarIcon
