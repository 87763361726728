import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginTop: paddingNew.base,
  },
  loader: {
    marginTop: paddingNew.lg,
  },
})
