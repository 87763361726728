import { StyleSheet, Dimensions, Platform } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const { width } = Dimensions.get('window')
const isWeb = Platform.OS === 'web'

const styles = StyleSheet.create({
  infoRowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: paddingNew.xs,
    marginVertical: paddingNew.xs,
  },
  infoRowBody: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  infoRowDescription: {
    ...typography.subtitle2,
    paddingLeft: paddingNew.sm,
  },
  infoRowComment: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.secondary,
    paddingLeft: paddingNew.xs,
  },
  section: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginHorizontal: paddingNew.sm,
    paddingBottom: paddingNew.sm,
    zIndex: 1,
  },
  title: {
    ...typography.h7,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
  },
  backgroundImage: {
    height: 150,
    resizeMode: 'cover',
    width: isWeb ? '100%' : width,
  },
  loader: {
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
})

export default styles
