import { useEffect, useState } from 'react'
import { Platform } from 'react-native'

import { bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { googleMapsApiKey, headers } from 'src/scenes/Main/Matching/Area/helpers/autocomplete'

export type TMapDetails = {
  address: string
  height: number
  marker: string
  path?: string
  width: number
  zoom: number
}

const useStaticMap = ({ address, height, marker, path, width, zoom }: TMapDetails) => {
  const [staticMapUrl, setStaticMapUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const getStaticMap = async () => {
    setIsLoading(true)
    try {
      const link = `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=${zoom}&size=${width}x${height}&markers=${marker}|${address}&maptype=roadmap&key=${googleMapsApiKey}`
      const response = await fetch(path?.length ? `${link}&path=${path}` : link, {
        headers: Platform.OS === 'ios' || Platform.OS === 'android' ? { ...headers } : {},
      })

      if (!response.ok) {
        bugsnagNotifyWithData('fetchStaticMap', response)
        throw new Error('Error during fetching static map')
      }
      setStaticMapUrl(response.url)
    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getStaticMap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { staticMapUrl, isLoading, isError }
}

export default useStaticMap
