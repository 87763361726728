import gql from 'graphql-tag'
import { TaskItem, UserTasksResponseType } from './types'

export type { TaskItem, UserTasksResponseType }

export default gql`
  query UserTasks($countryCode: String) {
    userTasks(filter: { countryCode: $countryCode }) {
      nodes {
        category
        expiresAt
        ref
        status
        timeToComplete
      }
    }
  }
`
