import { Platform, StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'
import { colorWithOpacity } from 'src/styles/utils'

const BLUR_IMITATION = {
  backgroundColor: colorWithOpacity('#e6e6e6', 0.6),
} as ViewStyle

const button = {
  alignItems: 'center',
  borderRadius: 8,
  flexDirection: 'row',
  marginVertical: paddingNew.xs,
} as ViewStyle

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: paddingNew.xs,
    alignItems: 'center',
    flexDirection: 'row',
  },
  row: {
    marginRight: paddingNew.md,
    marginLeft: paddingNew.xs,
  },
  title: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  button: {
    borderRadius: 8,
    bottom: paddingNew.base,
    height: 30,
    paddingHorizontal: paddingNew.xs,
    paddingVertical: paddingNew.xxs,
    position: 'absolute',
    right: paddingNew.xs,
    width: 100,
    ...Platform.select({
      ios: {
        backgroundColor: colorsNew.redesign.secondary.shades12p,
      },
      android: {
        ...BLUR_IMITATION,
        alignItems: 'center',
        justifyContent: 'center',
      },
      web: {
        ...BLUR_IMITATION,
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
  },
  buttonText: {
    ...typography.body2,
    color: colorsNew.redesign.secondary.main,
    textAlign: 'center',
    ...Platform.select({
      android: {
        marginTop: 1,
      },
      ios: {
        lineHeight: 30,
      },
    }),
  },
  blur: {
    borderRadius: 8,
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  detailsButton: button,
  buttonPressed: {
    ...button,
    backgroundColor: colorsNew.redesign.action.pressed,
  },
  rowContainer: {
    marginLeft: paddingNew.xs,
    flex: 1,
  },
  iconContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.primary.shades30p,
    borderRadius: 6,
    height: 40,
    justifyContent: 'center',
    width: 40,
  },
  subtitle: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
