type PropT = () => any

export const waitForSomethingToBeTrue = (something: PropT) => {
  if (something()) {
    return new Promise(resolve => resolve())
  }

  return new Promise((resolve, reject) => {
    const start = new Date().getTime()
    const intervalId = setInterval(() => {
      if (something()) {
        clearInterval(intervalId)
        resolve()
      } else {
        const timer = new Date().getTime() - start
        if (timer > 30000) {
          clearInterval(intervalId)
          reject('took too long')
        }
      }
    }, 100)
  })
}
