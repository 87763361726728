import React, { useEffect } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { colorsNew } from 'src/styles'
import getStyles from './styles'
import PersonRounded from 'src/icons/redesign/personRounded'

type TProps = {
  onMountCallback?: () => void
  style?: StyleProp<ViewStyle>
  wrapperSize: number
}

const EmptyProfilePicturePlaceholder = ({ onMountCallback, style = {}, wrapperSize }: TProps) => {
  const styles = getStyles({ size: wrapperSize })
  const iconSize = wrapperSize / 1.61

  useEffect(() => {
    onMountCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View style={StyleSheet.flatten([styles.profilePlaceholder, style])}>
      <PersonRounded fill={colorsNew.redesign.action.active} size={iconSize} />
    </View>
  )
}

export default EmptyProfilePicturePlaceholder
