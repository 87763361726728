import * as Yup from 'yup'

import { regexes } from 'src/global/utils'
import translations, { translate } from 'src/utils/translations/translations'

const getValidationSchema = () =>
  Yup.object().shape({
    credits: Yup.string()
      .required(translate(translations.blank))
      .matches(regexes.numbersWithZero, translate(translations.onlyNumbersAllowed)),
    graduationYear: Yup.string().required(translate(translations.blank)),
    namePreselect: Yup.string().required(translate(translations.blank)),
    name: Yup.string().when('namePreselect', {
      is: namePreselect => namePreselect === 'other',
      then: Yup.string().required(translate(translations.blank)),
    }),
    subject: Yup.string().required(translate(translations.blank)),
  })

export default getValidationSchema
