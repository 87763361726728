import { StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  extraLabelSpace: {
    marginTop: paddingNew.md,
  },
  extraContainerSpace: {
    marginVertical: paddingNew.sm,
  },
  radioGroupContainer: {
    marginBottom: 0,
    marginTop: 0,
    position: 'relative',
  },
  spacing: {
    marginVertical: paddingNew.xs,
  },
  radioGroupLabel: {
    fontFamily: fonts.poppinsMedium,
  },
  rowSelected: {
    backgroundColor: colorsNew.redesign.primary.shades8p,
  },
  label: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  error: {
    bottom: -12,
    color: colorsNew.redesign.error.main,
    fontSize: fontSizesNew.xxs.size,
    height: 18,
    left: 0,
    position: 'absolute',
  },
})
