import React from 'react'
import Svg, { Path, Rect } from 'react-native-svg'
import { colorsNew } from 'src/styles'

import { LogoIconTypes } from './types'

const LogoIcon = ({ variant, size, ...props }: LogoIconTypes) => {
  const backgroundColor = variant === 'black' ? colorsNew.redesign.primary.main : colorsNew.black
  const iconColor = variant === 'black' ? colorsNew.black : colorsNew.white

  return (
    <Svg height={size} width={size} viewBox="0 0 411 410" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect x="0.5" width="410" height="410" rx="43" fill={backgroundColor} />
      <Path
        d="M236.885 329.915C236.885 337.395 244.325 342.995 251.805 342.995H279.665C286.965 342.995 292.875 337.075 292.875 329.785V226.925C292.875 172.195 264.825 156.345 224.925 156.345C185.025 156.345 175.115 185.005 175.115 185.005V78.005C175.115 70.515 168.565 64.005 161.115 64.005H134.365C126.915 64.005 120.875 70.075 120.875 77.565L120.515 249.435C120.515 256.925 126.555 262.995 134.005 262.995H161.115C169.195 262.995 174.115 258.995 175.115 248.995C176.415 236.025 175.875 221.875 187.875 209.875C204.875 192.875 236.875 200.795 236.875 236.875"
        fill={iconColor}
      />
      <Path
        d="M148.625 345.995C165.47 345.995 179.125 332.34 179.125 315.495C179.125 298.65 165.47 284.995 148.625 284.995C131.78 284.995 118.125 298.65 118.125 315.495C118.125 332.34 131.78 345.995 148.625 345.995Z"
        fill={iconColor}
      />
    </Svg>
  )
}
export default LogoIcon
