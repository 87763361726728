import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Api from 'src/utils/api'
import { getLanguageByLocation } from 'src/utils/translations/i18n'
import { countryCodeSelector } from 'src/store/app/selectors'

export type ArticleType = {
  attributes: {
    answer: string
    question: string
  }
  id: string
  type: string
}

type ResponseType = {
  data: {
    data: ArticleType[]
  }
}
const useGetArticles = () => {
  const countryCode = useSelector(countryCodeSelector)

  const [articles, setArticles] = useState<ArticleType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const getArticles = () => {
    const locale = getLanguageByLocation(countryCode).substring(0, 2)
    Api.get(`faq?locale=${locale}`, {
      onSuccess: ({ data: { data } }: ResponseType) => {
        setArticles(data)
        setIsLoading(false)
      },
      onError: () => {
        setIsLoading(false)
        setIsError(true)
      },
    })
  }

  useEffect(() => {
    setIsLoading(true)
    getArticles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { articles, isError, isLoading }
}

export default useGetArticles
