import React, { useState } from 'react'
import { View } from 'react-native'

import AlertCard, { AlertCardProps } from 'src/components/AlertCard'
import {
  ApplicationStatus,
  applicationStatus,
} from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/applicationTypes'
import { getHiddenBookingAlertIds } from 'src/store/app/selectors'
import { pendingApplications } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/utils'
import { setHiddenBookingAlertId } from 'src/store/app/actions'
import { useAppDispatch } from 'src/hooks/reduxHooks'
import { useAppSelector } from 'src/hooks/reduxHooks'
import styles from './styles'

type Props = {
  alertData?: null | AlertCardProps
  offerStatus?: ApplicationStatus
  showJobConfirmation: boolean
  userComplianceAlertData: null | AlertCardProps
}

const AlertMessage = ({ alertData, offerStatus, showJobConfirmation, userComplianceAlertData }: Props) => {
  const shouldShowUserAlert = offerStatus ? pendingApplications.includes(offerStatus) : false
  const hiddenBookingAlertIds = useAppSelector(getHiddenBookingAlertIds)
  const [showAlert, setShowAlert] = useState(true)
  const dispatch = useAppDispatch()

  if (userComplianceAlertData && shouldShowUserAlert)
    return (
      <View style={styles.container} testID="alert-message">
        <AlertCard {...userComplianceAlertData} />
      </View>
    )

  if (!alertData) return null

  const showConfirmationAlert = offerStatus === applicationStatus.userAppointed && showJobConfirmation
  const showOfferAlert = offerStatus !== applicationStatus.userAppointed && showAlert
  const canBeClosed = offerStatus === applicationStatus.userApplied
  const alertIncludesId = alertData.id && hiddenBookingAlertIds ? !hiddenBookingAlertIds.includes(alertData.id) : true

  const canDisplayAlert = () => {
    if (showConfirmationAlert || showOfferAlert) {
      return canBeClosed ? alertIncludesId : true
    }

    return false
  }

  return canDisplayAlert() ? (
    <View style={styles.container} testID="alert-message">
      <AlertCard
        {...alertData}
        onClose={
          canBeClosed
            ? () => {
                setShowAlert(false)
                if (alertData.id) {
                  dispatch(setHiddenBookingAlertId(alertData.id))
                }
              }
            : undefined
        }
        testId={`${offerStatus}-alert`}
      />
    </View>
  ) : null
}

export default AlertMessage
