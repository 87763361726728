import gql from 'graphql-tag'
import UserFields from './Fields'

export default gql`
  query Show($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
  ${UserFields}
`
