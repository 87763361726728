import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const VisibilityOffIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M3.02 2.934c-.382.179-.669.654-.6.997.074.368.093.393 1.187 1.492l1.078 1.084-.193.155c-.395.319-1.314 1.255-1.689 1.72a11.539 11.539 0 0 0-1.586 2.578l-.221.494.089.223c.049.123.205.457.347.743a11.848 11.848 0 0 0 7.646 6.18c.997.254 1.851.36 2.899.36 1.369 0 2.413-.172 3.798-.626l.554-.182 1.466 1.457c1.575 1.567 1.556 1.552 1.97 1.551.369-.001.803-.323.909-.674a1.06 1.06 0 0 0-.05-.746C20.486 19.453 4.072 3.044 3.8 2.922a.94.94 0 0 0-.78.012m8.238 1.067c-.825.049-1.564.172-2.431.406l-.473.127 1.087 1.087 1.086 1.086.386-.099c.515-.131 1.557-.149 2.085-.035a4.903 4.903 0 0 1 2.457 1.307c1.302 1.259 1.821 3.036 1.384 4.743l-.08.315 1.529 1.53 1.528 1.529.262-.249a12.235 12.235 0 0 0 1.842-2.195 14 14 0 0 0 .977-1.837l.11-.264-.23-.51a11.938 11.938 0 0 0-5.531-5.738C15.82 4.492 14.336 4.102 12.669 4c-.697-.042-.688-.042-1.411.001m2.382 5.815c.715.717 1.306 1.286 1.314 1.263.049-.147-.216-.876-.446-1.23-.442-.677-1.238-1.207-1.969-1.309l-.199-.028 1.3 1.304m-5.35.294.749.75-.023.44c-.05.926.276 1.73.964 2.372.627.586 1.619.911 2.359.772l.24-.045.78.782c.624.623.769.789.721.819-.131.084-.761.293-1.12.371-.505.111-1.389.118-1.88.015-1.05-.22-1.877-.66-2.601-1.385a4.96 4.96 0 0 1-1.383-2.563c-.109-.51-.099-1.489.02-2.01.117-.515.322-1.068.396-1.068.016 0 .366.338.778.75"
      fillRule="evenodd"
    />
  </Svg>
)

export default VisibilityOffIcon
