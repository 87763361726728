import React from 'react'
import { Text, View } from 'react-native'

import { BookingSummaryTypes } from 'src/graphql/Booking/types'
import { useBugsnagView } from 'src/utils/bugsnag'
import JobDetails from 'src/scenes/Main/Salary/History/components/ListItem/JobDetails/JobDetails'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  data: BookingSummaryTypes[]
}

const JobDetailsList = ({ data }: Props) => {
  useBugsnagView('job details list')

  return (
    <View style={styles.container}>
      <View style={styles.containerPadding}>
        <Text style={styles.title}>{translate(translations.jobDetails)}</Text>
      </View>
      {data &&
        data.map((booking, index) => <JobDetails {...booking} title={booking.school.name} index={index} key={index} />)}
    </View>
  )
}

export default JobDetailsList
