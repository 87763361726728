import { View } from 'react-native'
import React from 'react'

import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  notAvailable?: boolean
}

const ApplicationInfo = ({ notAvailable }: Props) => (
  <View style={styles.container}>
    <AlertCard
      colorSchema={notAvailable ? alertColorScheme.error : alertColorScheme.info}
      description={
        notAvailable
          ? translate(translations.offerNotAvailableDetails)
          : translate(translations.thankYouForApplyingDescription)
      }
      title={notAvailable ? translate(translations.offerNotAvailable) : translate(translations.alertUserAppliedTitle)}
      variant={alertVariants.standard}
    />
  </View>
)

export default ApplicationInfo
