import { AxiosResponse } from 'axios'

import Api, { responseErrorCallback } from 'src/utils/api'
import { ConfigurationResponseData } from 'src/global/types'

export type onSuccessType = (url: string) => void
export type onErrorType = () => void

export const getPolicyUrl = (onSuccess: onSuccessType, onError: onErrorType) => {
  return Api.get(`/configuration`, {
    onSuccess: (data: AxiosResponse<ConfigurationResponseData>) => {
      onSuccess(data.data.metadata?.policy_url || '')
    },
    onError: (response: AxiosResponse) => {
      responseErrorCallback('get', `/configuration`, response)
      onError()
    },
  })
}
