import { Mixpanel } from 'mixpanel-react-native'

import { ProfileTaskType } from '../types'
import Api from 'src/utils/api'
import I18n from 'src/utils/translations/i18n'

export const markTaskAsCompleted = (
  userId: string,
  taskRef: ProfileTaskType,
  isFileUploaded: boolean,
  mixpanel?: Mixpanel | null,
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
) =>
  Api.post(`student/${userId}/user_tasks`, {
    params: {
      ref: taskRef,
      status: 'completed',
    },
    onSuccess: () => {
      mixpanel &&
        mixpanel.track('Recruitment Task Completed', {
          TaskTitle: I18n.t(`taskTitle_${taskRef}`),
          FileUploaded: isFileUploaded,
        })
      onSuccessCallback && onSuccessCallback()
    },
    onError: () => onErrorCallback && onErrorCallback(),
  })
