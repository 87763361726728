import React, { RefObject } from 'react'
import { StyleSheet } from 'react-native'
import LottieView from 'lottie-react-native'

import { TReferAnimationVariant } from './types'
import styles from './styles'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const giveAnimationData = require('src/assets/animations/referGiveAnimation.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const splitAnimationData = require('src/assets/animations/referSplitAnimation.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const takeAnimationData = require('src/assets/animations/referTakeAnimation.json')

const animationSources = {
  give: giveAnimationData,
  split: splitAnimationData,
  take: takeAnimationData,
}

type Props = {
  animationRef: RefObject<any>
  variant: TReferAnimationVariant
}

const ReferAnimationRender = ({ animationRef, variant }: Props) => (
  <LottieView
    loop={false}
    ref={animationRef}
    source={animationSources[variant]}
    style={StyleSheet.flatten([styles.animation, styles[variant]])}
  />
)

export default ReferAnimationRender
