import React from 'react'
import { View } from 'react-native'

import CategoryCard from './CategoryCard/CategoryCard'
import type { CategoriesList as CategoriesListT } from 'src/scenes/Main/Profile/CategoriesList/useGetCompletedTasks'
import type { ProfileCategories } from 'src/scenes/Main/Profile/types'
import styles from './styles'

type Props = {
  categoriesData: CategoriesListT
  categoriesList: ProfileCategories[]
}

const CategoriesList = ({ categoriesData, categoriesList }: Props) => (
  <View style={styles.categories} testID="categoriesList">
    {categoriesList.map((category, index) => (
      <CategoryCard
        category={category}
        key={index}
        numberOfCompletedTasks={categoriesData[category].numberOfCompletedTasks}
        numberOfTasks={categoriesData[category].numberOfTasks}
        status={categoriesData[category].categoryStatus}
      />
    ))}
  </View>
)

export default CategoriesList
