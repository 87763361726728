import React, { useMemo, useRef, useState } from 'react'
import Clipboard from '@react-native-clipboard/clipboard'
import { ActionSheetIOS, Platform, TouchableHighlight, UIManager, findNodeHandle } from 'react-native'

import ActionsModal from 'src/components/ActionsModal'
import { colorsNew } from 'src/styles'
import { bugsnagActionBreadcrumb, bugsnagNotify } from 'src/utils/bugsnag'
import { MessageIcon } from 'src/icons'
import { openURLIfCan } from 'src/global/utils'
import { setSnackbarProps } from 'src/store/app/actions'
import { useAppDispatch } from 'src/hooks/reduxHooks'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  email: string
}

const ContactDetails = ({ email }: Props) => {
  const dispatch = useAppDispatch()
  const emailIcon = useRef<TouchableHighlight | null>(null)

  const isIOS = Platform.OS === 'ios'
  const isAndroid = Platform.OS === 'android'

  const [showModal, setShowModal] = useState(false)

  const copyEmail = () => {
    Clipboard.setString(email)
    dispatch(
      setSnackbarProps({
        message: translate(translations.emailCopied),
        visible: true,
      }),
    )
  }

  const sendMessage = () => openURLIfCan(`mailto:${email}`)

  const mailOptions = [
    translate(translations.emailSend),
    translate(translations.emailCopy),
    translate(translations.cancel),
  ]

  const modalActions = useMemo(
    () => [
      {
        label: translate(translations.emailSend),
        onPress: () => {
          sendMessage()
          setShowModal(false)
        },
      },
      {
        label: translate(translations.emailCopy),
        onPress: () => {
          copyEmail()
          setShowModal(false)
        },
      },
      {
        label: translate(translations.cancel),
        onPress: () => setShowModal(false),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const showIOSMenu = () => {
    ActionSheetIOS.showActionSheetWithOptions(
      {
        cancelButtonIndex: 2,
        options: mailOptions,
        userInterfaceStyle: 'dark',
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          sendMessage()
        } else if (buttonIndex === 1) {
          copyEmail()
        }
      },
    )
  }

  const showAndroidMenu = () => {
    UIManager.showPopupMenu(
      //@ts-ignore
      findNodeHandle(emailIcon.current),
      mailOptions,
      () => {
        console.error('something went wrong with the popup menu')
        bugsnagNotify('something went wrong with the android popup menu')
      },
      (e, buttonIndex) => {
        switch (buttonIndex) {
          case 0: {
            sendMessage()
            break
          }
          case 1: {
            copyEmail()
            break
          }
          case 2:
            break
        }
      },
    )
  }

  const showActionsModal = () => {
    bugsnagActionBreadcrumb(`show proposal contact detail`)
    if (isAndroid) {
      showAndroidMenu()
      return
    } else if (isIOS) {
      showIOSMenu()
      return
    } else {
      setShowModal(true)
    }
  }

  return (
    <>
      <TouchableHighlight
        onPress={showActionsModal}
        style={styles.button}
        underlayColor={colorsNew.redesign.action.pressed}
        ref={emailIcon}
      >
        <MessageIcon size={24} fill={colorsNew.redesign.action.active} />
      </TouchableHighlight>
      <ActionsModal onClose={() => setShowModal(false)} actions={modalActions} isVisible={showModal} />
    </>
  )
}

export default ContactDetails
