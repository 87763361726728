import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const isWeb = Platform.OS === 'web'
const deviceWidth = Dimensions.get('window').width
const screenSize = isWeb && deviceWidth > 500 ? 500 : deviceWidth

export const CATEGORIES_SCREEN_PADDING = paddingNew.sm
export const CARD_SIZE = (screenSize - 3 * CATEGORIES_SCREEN_PADDING) / 2

export default StyleSheet.create({
  cardContainer: {
    marginVertical: paddingNew.xxs,
    width: CARD_SIZE,
  },
  card: {
    height: 132,
    paddingHorizontal: paddingNew.xs,
    paddingVertical: paddingNew.sm,
  },
  iconContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.primary.contrast,
    borderRadius: 4,
    height: 28,
    justifyContent: 'center',
    marginLeft: paddingNew.xxs,
    width: 28,
  },
  statusContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: paddingNew.xs,
  },
  statusInfo: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    marginRight: paddingNew.xxs,
  },
  title: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
})
