import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  constainer: {
    backgroundColor: colorsNew.black,
    borderRadius: 50,
    paddingHorizontal: paddingNew.sm,
    paddingVertical: 3,
  },
  text: {
    color: colorsNew.white,
    ...typography.chipTextSmall,
  },
})
