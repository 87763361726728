import React, { useState, useEffect, useCallback } from 'react'
import { IMessage } from 'react-native-gifted-chat'
import { useFocusEffect } from '@react-navigation/native'
import { useQuery } from '@apollo/client'

import LoadingIndicator from 'src/components/LoadingIndicator'
import ChatQuery, { ChatMessages } from 'src/graphql/Chat/index'
import useChatMessage from 'src/hooks/useChatMessage'
import { useBugsnagView } from 'src/utils/bugsnag'
import { convertMessage } from './helpers'
import { setMessagesRefetchStatus, updateNewMessageText } from 'src/store/inbox/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import GiftedChat from './GiftedChat'

export interface ConvertedChatMessage extends IMessage {
  origin?: string
}

const REFRESH_INTERVAL = 30

const Chat = () => {
  const [messages, setMessages] = useState<ConvertedChatMessage[]>([])
  const [sending, setSending] = useState(false)

  const id = useAppSelector(state => state?.user?.id) as string

  const dispatch = useAppDispatch()

  useBugsnagView('Chat')

  const { refetch: hookRefetch } = useChatMessage()

  const { data, loading, refetch } = useQuery<ChatMessages>(ChatQuery, {
    skip: !id,
    variables: {
      userId: id,
    },
    onCompleted: messagesData => {
      const messagesList = messagesData?.messages?.nodes || []
      const convertedMessages = messagesList.map(convertMessage) || []

      if (convertedMessages[0]?._id !== messages[0]?._id) setMessages(convertedMessages)
      sending && setSending(false)
    },
    pollInterval: REFRESH_INTERVAL * 1000,
  })

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
    }, [refetch]),
  )

  useEffect(() => {
    dispatch(setMessagesRefetchStatus(false))
    dispatch(updateNewMessageText(null))

    return () => {
      dispatch(setMessagesRefetchStatus(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading && !sending) return <LoadingIndicator />

  return (
    <GiftedChat
      hookRefetch={hookRefetch}
      data={data}
      isLoading={loading}
      isLoadingIndicator={loading && !sending}
      messages={messages}
      refetch={refetch}
      setMessages={setMessages}
      setSending={setSending}
      shouldShowLoadingOnRefetch={false}
    />
  )
}

export default Chat
