import React, { useMemo, useState } from 'react'
import { Platform, View } from 'react-native'
import { Calendar, LocaleConfig } from 'react-native-calendars'
import { Direction } from 'react-native-calendars/src/types'
import { useSelector } from 'react-redux'
import merge from 'lodash/merge'
import moment from 'moment'
import { useFocusEffect } from '@react-navigation/native'

import { calendarLocale } from 'src/locales/calendarLocaleSV'
import { ChevronLeftIcon, ChevronRightIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { isGBSelector } from 'src/store/app/selectors'
import { useAvailabilitySchedule } from '../availabilityContext/availabilityContext'
import { getLocale } from 'src/global/utils'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import Day from './Day'
import formatter from 'src/utils/formatter'
import LoadingIndicator from 'src/components/LoadingIndicator'
import styles, { themeStyle } from './styles'

LocaleConfig.locales.en = LocaleConfig.locales['']
LocaleConfig.locales.sv = calendarLocale

const CalendarPicker = () => {
  const isGB = useSelector(isGBSelector)
  const isAndroid = Platform.OS === 'android'

  LocaleConfig.defaultLocale = getLocale(isGB)

  const today = useMemo(() => formatter.apiFormat(), [])

  const { state, getMonthData, loadingAvailability, selectedDay, setSelectedDay } = useAvailabilitySchedule()
  const [selectedMonthDay, setSelectedMonthDay] = useState(today)

  const isCurrentMonth = useMemo(() => moment().isSame(selectedMonthDay, 'month'), [selectedMonthDay])

  const calendarData = useMemo(() => {
    const selected = { [selectedDay]: { selected: true } }
    const isToday = { [today]: { today: true } }

    return merge(selected, state.availabilityList, isToday)
  }, [selectedDay, today, state])

  useFocusEffect(
    React.useCallback(() => {
      const current = formatter.apiFormat()
      getMonthData(current)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const selectMonth = (monthDay: string) => {
    bugsnagActionBreadcrumb(`change availability month`)
    getMonthData(monthDay)
    setSelectedMonthDay(monthDay)
  }

  const renderArrow = (direction: Direction) =>
    direction === 'left' ? (
      <ChevronLeftIcon
        fill={isCurrentMonth ? colorsNew.redesign.action.disabled : colorsNew.redesign.action.active}
        size={24}
      />
    ) : (
      <ChevronRightIcon size={24} fill={colorsNew.redesign.action.active} />
    )

  return (
    <View>
      <Calendar
        dayComponent={Day}
        disableArrowLeft={isCurrentMonth}
        displayLoadingIndicator={isAndroid && loadingAvailability}
        firstDay={1}
        current={today}
        hideExtraDays={true}
        markedDates={isAndroid && loadingAvailability ? {} : calendarData}
        minDate={today}
        numberOfDays={5}
        onDayPress={day => setSelectedDay(day.dateString)}
        onMonthChange={date => selectMonth(date.dateString)}
        renderArrow={renderArrow}
        style={styles.container}
        testID="availability"
        theme={themeStyle}
      />
      {loadingAvailability ? <LoadingIndicator /> : null}
    </View>
  )
}

export default CalendarPicker
