import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alerWrapper: {
    marginBottom: paddingNew.sm,
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: -paddingNew.xxs,
  },
  checkboxLabel: {
    marginTop: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  disabled: { color: colorsNew.redesign.text.disabled },
  fileUploaderWrapper: {
    marginBottom: paddingNew.md,
    marginTop: paddingNew.sm,
  },
  info: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  sectionContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginTop: paddingNew.sm,
    paddingTop: paddingNew.sm,
  },
  subInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.md,
    marginTop: paddingNew.xs,
  },
})

export default styles
