import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.xs,
  },
  container: {
    paddingVertical: paddingNew.md,
  },
  containerPadding: {
    paddingHorizontal: paddingNew.sm,
  },
  info: {
    ...typography.caption,
    textAlign: 'center',
  },
  loading: {
    backgroundColor: colorsNew.redesign.background.paper,
  },
  loadingContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  showMoreMonths: {
    alignItems: 'center',
    paddingTop: paddingNew.xs,
  },
  title: {
    ...typography.overline,
    textAlign: 'center',
  },
  emptyTitle: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
    textAlign: 'center',
  },
  emptyDescription: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.md + 8,
    textAlign: 'center',
  },
  emptyButton: {
    justifyContent: 'center',
  },
})
