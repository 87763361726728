import React, { ElementType } from 'react'
import { Pressable, Text, View } from 'react-native'

import { colorsNew } from 'src/styles'
import { IconTypes } from 'src/icons/iconTypes'
import styles from './styles'

type TProps = {
  Icon: ElementType<IconTypes>
  info: string
  onPress: () => void
  title: string
}

const ContactDetailsRow = ({ Icon, info, onPress, title }: TProps) => (
  <Pressable onPress={onPress} style={({ pressed }) => (pressed ? styles.buttonPressed : null)}>
    <View style={styles.container}>
      <View>
        <Text style={styles.infoTitle}>{title}</Text>
        <Text style={styles.info}>{info}</Text>
      </View>
      <View style={styles.iconContainer}>
        <Icon fill={colorsNew.redesign.action.active} size={24} />
      </View>
    </View>
  </Pressable>
)

export default ContactDetailsRow
