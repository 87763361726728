import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import sumBy from 'lodash/sumBy'
import { useQuery } from '@apollo/client'

import { countryCodeSelector } from 'src/store/app/selectors'
import { categoriesConfig } from '../utils'
import { ProfileCategories, ProfileCategoryStatus } from '../types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import UserTasks, { TaskItem, UserTasksResponseType } from 'src/graphql/RequiredTasks'

export type CategoriesList = {
  [category in ProfileCategories]: {
    categoryStatus: ProfileCategoryStatus
    numberOfCompletedTasks: number
    numberOfTasks: number
    tasksList: TaskItem[]
  }
}

const useGetCompletedTasks = () => {
  const countryCode = useAppSelector(countryCodeSelector)

  const { refetch, loading, data, error } = useQuery<UserTasksResponseType>(UserTasks, { variables: { countryCode } })

  const requiredTasks = useMemo(() => data?.userTasks?.nodes || [], [data?.userTasks?.nodes])

  const numberOfAllTasks = requiredTasks.length
  const numberOfAllCompletedTasks = sumBy(requiredTasks, task => (task.status === 'completed' ? 1 : 0))

  const dataPerRef = useMemo(() => {
    const dataRef = {}
    requiredTasks.forEach(item => (dataRef[item.ref] = item))
    return dataRef
  }, [requiredTasks])

  const categoriesData = useMemo(() => {
    const groupedByCategories = {} as CategoriesList

    requiredTasks.forEach(task => {
      const category = task.category

      if (!groupedByCategories[category]) {
        groupedByCategories[category] = {
          numberOfTasks: 0,
          numberOfCompletedTasks: 0,
          categoryStatus: 'enabled',
          tasksList: [],
        }
      }

      groupedByCategories[category].numberOfTasks++
      groupedByCategories[category].tasksList.push(task)

      if (task.status === 'completed') {
        groupedByCategories[category].numberOfCompletedTasks++
      } else if (task.status === 'expired') {
        groupedByCategories[category].categoryStatus = 'expired'
      } else if (task.status === 'expiresSoon' && groupedByCategories[category].categoryStatus !== 'expired') {
        groupedByCategories[category].categoryStatus = 'expiresSoon'
      }
    })

    const categories = Object.keys(groupedByCategories) as ProfileCategories[]

    categories.forEach(category => {
      if (groupedByCategories[category].numberOfCompletedTasks === groupedByCategories[category].numberOfTasks) {
        groupedByCategories[category].categoryStatus = 'completed'
      }
    })

    return groupedByCategories
  }, [requiredTasks])

  const categoriesList = categoriesConfig[countryCode].filter(category => categoriesData[category])
  const isLoading = loading || !categoriesList.length || isEmpty(categoriesData)

  return {
    categoriesData,
    categoriesList,
    dataPerRef,
    error,
    isLoading,
    numberOfAllCompletedTasks,
    numberOfAllTasks,
    refetchTasks: refetch,
  }
}

export default useGetCompletedTasks
