import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const UserIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 64 64" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6 43.324c-1.641 2.872-1.952 6.279-1.952 8.054a2.627 2.627 0 1 1-5.253 0c0-2.165.345-6.638 2.644-10.66 2.417-4.23 6.88-7.726 14.429-7.726s12.01 3.495 14.428 7.726c2.299 4.022 2.645 8.495 2.645 10.66a2.627 2.627 0 1 1-5.254 0c0-1.775-.31-5.182-1.952-8.054-1.522-2.665-4.284-5.08-9.867-5.08-5.584 0-8.346 2.415-9.868 5.08ZM32.467 26.425a5.253 5.253 0 1 0 0-10.506 5.253 5.253 0 0 0 0 10.506Zm0 5.253c5.803 0 10.507-4.704 10.507-10.506 0-5.803-4.704-10.507-10.507-10.507-5.802 0-10.506 4.704-10.506 10.507 0 5.802 4.704 10.506 10.506 10.506Z"
    />
  </Svg>
)

export default UserIcon
