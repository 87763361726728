import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ContactMailIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M21 8V7L18 9L15 7V8L17.72 9.82C17.89 9.93 18.11 9.93 18.27 9.82L21 8ZM22 3H2C0.9 3 0 3.9 0 5V19C0 20.1 0.9 21 2 21H22C23.1 21 23.99 20.1 23.99 19L24 5C24 3.9 23.1 3 22 3ZM8 6C9.66 6 11 7.34 11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6ZM14 18H2V17C2 15 6 13.9 8 13.9C10 13.9 14 15 14 17V18ZM21.5 12H14.5C14.22 12 14 11.78 14 11.5V6.5C14 6.22 14.22 6 14.5 6H21.5C21.78 6 22 6.22 22 6.5V11.5C22 11.78 21.78 12 21.5 12Z" />
  </Svg>
)

export default ContactMailIcon
