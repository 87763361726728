import { BUILD_MODE } from 'src/utils/webAdapters/DotEnvAdapter'
import DeviceInfo from 'src/utils/webAdapters/DeviceInfoAdapter'
import { Platform } from 'react-native'

import { LocaleT } from 'src/utils/translations/i18nTypes'

export const apiConfig = {
  development: {
    se: {
      url: 'http://localhost:3000',
    },
    gb: {
      url: 'http://localhost:3000',
    },
  },
  staging: {
    se: {
      url: 'https://humly-api-staging.humly.io',
    },
    gb: {
      url: 'https://humly-api-staging.humly.co.uk',
    },
  },
  production: {
    se: {
      url: 'https://humly-api.humly.io',
    },
    gb: {
      url: 'https://humly-api.humly.co.uk',
    },
  },
}

export const appVersion = `${Platform.OS}${Platform.OS !== 'web' ? '-' + DeviceInfo.getVersion() : ''}`

export const getUrl = (countryCode: LocaleT, buildMode: 'staging' | 'production' | 'development') => {
  const mode = __DEV__ ? buildMode : buildMode == 'staging' ? 'staging' : 'production'

  if (mode === 'production' || mode === 'staging') {
    return apiConfig[mode][countryCode].url
  }

  return apiConfig.development[countryCode].url
}

// @ts-ignore
export const getApiUrl = (countryCode: LocaleT) => getUrl(countryCode, BUILD_MODE || __BUILD_MODE__)
