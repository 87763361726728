import { Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginBottom: paddingNew.md,
    marginHorizontal: paddingNew.sm,
    marginTop: paddingNew.xs,
  },
  section: {
    marginTop: paddingNew.sm,
  },
  header: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
  },
})
