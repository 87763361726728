import React, { ReactElement } from 'react'
import { StyleSheet, View } from 'react-native'
import Animated, { SlideInDown, SlideOutDown } from 'react-native-reanimated'

import styles from './styles'

type OverlayProps = {
  children: ReactElement | ReactElement[]
  withText?: boolean
}

const ActionsOverlay = ({ children, withText }: OverlayProps) => (
  <View style={StyleSheet.flatten([styles.overlayContainer, withText && styles.overlayContainerLarge])}>
    <Animated.View entering={SlideInDown} exiting={SlideOutDown} style={styles.content}>
      {children}
    </Animated.View>
  </View>
)

export default ActionsOverlay
