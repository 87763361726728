import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ThumbsDownIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M34.448 28.085h3.99c.676 0 1.225-.548 1.226-1.225l.017-14.598c0-.678-.549-1.228-1.226-1.228h-4.397c-.26 0-.512-.083-.74-.206-.794-.43-2.737-1.268-6.336-2.346-5.39-1.615-6.721-1.522-9.825-1.522-2.742 0-3.82 2.47-3.435 3.84-.38.09-3.058.825-3.514 2.904-.303 1.384.135 3.174.97 3.316-.382.088-2.152 1.335-2.152 3.461s1.668 3.211 2.503 3.353c-.381.089-1.143.797-1.143 2.923 0 2.125 1.669 2.94 2.503 3.082h9.306c-.563 1.611-1.687 5.042-1.687 5.871 0 1.036.462 2.657 1.687 3.853.657.642 1.802.937 2.757 1.053.658.08 1.188-.465 1.188-1.128v-1.712c0-1.32.533-2.586 1.478-3.51l5.974-5.832c.229-.224.536-.349.856-.349Z" />
  </Svg>
)

export default ThumbsDownIcon
