import React, { useCallback, useMemo, useState } from 'react'
import { ScrollView } from 'react-native'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { useQuery } from '@apollo/client'
import { useFocusEffect } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { colors, common } from 'src/styles'
import { desiredYearGroup } from './utils'
import { isGBSelector } from 'src/store/app/selectors'
import { SubjectRequestTypes } from 'src/graphql/Subject/types'
import { updatePreferences } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { UserRequestType } from 'src/graphql/User/types'
import { getDesiredWorkloadItemsGb, getDesiredWorkloadItemsSv } from './components/DesiredWorkload/desiredWorkloadItems'
import LoadingIndicator from 'src/components/LoadingIndicator'
import PreferencesTable from './components/PreferencesTable'
import Subjects from 'src/graphql/Subject'
import translations, { translate } from 'src/utils/translations/translations'
import User from 'src/graphql/User/Show'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

export const Preferences = () => {
  const dispatch = useAppDispatch()
  const desiredWorkloads = useAppSelector(state => state.user?.attributes?.desiredWorkloads) || []
  const id = useAppSelector(state => state.user?.id)
  const isGB = useSelector(isGBSelector)
  const [isSending, setIsSending] = useState(false)
  const [isSendingWorkflow, setIsSendingWorkflow] = useState(false)

  useBugsnagView('Matching - Preferences')

  const subjectsQuery = useQuery<SubjectRequestTypes>(Subjects, {
    fetchPolicy: 'cache-and-network',
  })

  const userQuery = useQuery<UserRequestType>(User, {
    skip: !id,
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })

  useFocusEffect(
    React.useCallback(() => {
      refetchFunction()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const refetchFunction = () => {
    bugsnagActionBreadcrumb('refresh')
    return Promise.all([subjectsQuery.refetch(), userQuery.refetch()])
  }

  const permittedLevels = useMemo(
    () => userQuery.data?.user?.permittedLevels || [],
    [userQuery.data?.user?.permittedLevels],
  )

  const preferredLevelsIds = useMemo(() => {
    const preferredLevels = userQuery.data?.user?.preferredLevels || []
    return preferredLevels.map(level => level.id)
  }, [userQuery.data?.user?.preferredLevels])

  const desiredYearGroupIds = userQuery.data?.user?.preferredKlassNames || []

  const preferredSubjectsIds = useMemo(() => {
    const preferredSubjects = userQuery.data?.user?.preferredSubjects || []
    return preferredSubjects ? preferredSubjects.map(subject => subject.id) : []
  }, [userQuery.data?.user?.preferredSubjects])

  const workloads = useMemo(() => {
    const desiredWorkloadItems = isGB ? getDesiredWorkloadItemsGb() : getDesiredWorkloadItemsSv()
    return desiredWorkloadItems.map(workload => ({ id: workload.id, title: workload.title }))
  }, [isGB])

  const sendUpdate = useCallback(
    (type: string) => (ids: string[]) =>
      dispatch(updatePreferences({ [type]: ids })).finally(() => {
        setIsSending(false)
        setIsSendingWorkflow(false)
      }),
    [dispatch],
  )

  if (subjectsQuery.loading || userQuery.loading) {
    return <LoadingIndicator style={{ backgroundColor: colors.white }} />
  }

  const subjects = sortBy(get(subjectsQuery, 'data.subjects.nodes', []), 'title')

  return (
    <ScrollView style={common.whiteBackground} contentContainerStyle={globalStyles.containerSize} testID="scrollView">
      <PreferencesTable
        customContainerStyle={styles.workloadsContainer}
        isSending={isSending}
        isWorkload
        label={translate(translations.desiredWorkloads)}
        optionsList={workloads}
        preferredOptions={desiredWorkloads}
        setIsSending={setIsSending}
        updatePreferences={sendUpdate('desiredWorkloads')}
        isSendingWorkflow={isSendingWorkflow}
        setIsSendingWorkflow={setIsSendingWorkflow}
      />

      <PreferencesTable
        isSending={isSending}
        label={translate(translations.onWhichLevel)}
        optionsList={permittedLevels}
        preferredOptions={preferredLevelsIds}
        setIsSending={setIsSending}
        updatePreferences={sendUpdate('preferredLevelsIds')}
      />
      {isGB && (
        <PreferencesTable
          isSending={isSending}
          label={translate((translations as TranslationGBT).onWhichGroups)}
          optionsList={desiredYearGroup}
          preferredOptions={desiredYearGroupIds}
          setIsSending={setIsSending}
          updatePreferences={sendUpdate('preferredKlassNames')}
        />
      )}
      <PreferencesTable
        isSending={isSending}
        label={translate(translations.onWhichSubjects)}
        optionsList={subjects}
        preferredOptions={preferredSubjectsIds}
        setIsSending={setIsSending}
        updatePreferences={sendUpdate('preferredSubjectsIds')}
      />
    </ScrollView>
  )
}

export default Preferences
