import React, { useEffect } from 'react'
import { RefreshControl, ScrollView } from 'react-native'
import filter from 'lodash/filter'

import styles from './styles'
import NotificationItem from './components/NotificationItem'
import { colors, colorsNew } from 'src/styles'
import { sendActivitiesReadStatus, sendMessagesReadStatus } from './components/NotificationItem/actions'
import { setUnreadNotifications } from 'src/store/inbox/actions'
import { MessageTypes, UserActivityFieldTypes } from 'src/graphql/Notifications/types'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import translations, { translate } from 'src/utils/translations/translations'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'
import globalStyles from 'src/global/globalStyles'

type Props = {
  isLoading: boolean
  notificationsList: (MessageTypes | UserActivityFieldTypes)[]
  refetch: () => void
}

const NotificationListRender = ({ notificationsList, isLoading, refetch }: Props) => {
  const isConnected = useAppSelector(state => state?.app?.isConnected)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const unReadNotifications = filter(notificationsList, { read: false })

    if (unReadNotifications.length) {
      const messages = [] as MessageTypes[]
      const activities = [] as UserActivityFieldTypes[]

      unReadNotifications.forEach(notification => {
        if (notification.__typename === 'Message') {
          messages.push(notification)
        } else {
          activities.push(notification)
        }
      })

      const promises = []

      if (messages.length) {
        promises.push(sendMessagesReadStatus(messages.map(item => item.id)))
      }

      if (activities.length) {
        promises.push(sendActivitiesReadStatus(activities.map(item => item.id)))
      }

      Promise.all(promises).then(() => {
        dispatch(setUnreadNotifications(0))
        if (refetch) refetch()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const noNotifications = () => (
    <EmptyPlaceholder
      containerStyles={styles.emptyContainer}
      description={translate(translations.noNotificationsDescription)}
      title={translate(translations.noNotificationsTitle)}
      type={placeholderTypes.hands}
    />
  )

  return (
    <ScrollView
      refreshControl={
        <RefreshControl
          colors={[colors.blue._400, colorsNew.redesign.text.primary]}
          enabled={isConnected}
          onRefresh={refetch}
          refreshing={isLoading}
          tintColor={colorsNew.redesign.text.primary}
          titleColor={colors.blue._400}
        />
      }
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      style={styles.list}
      testID="scrollView"
    >
      {!notificationsList.length && noNotifications()}
      {notificationsList?.map(item => (
        <NotificationItem key={item?.id} notification={item} refetch={refetch} testID="notification" />
      ))}
    </ScrollView>
  )
}

export default NotificationListRender
