import gql from 'graphql-tag'

import { FlexibleAvailability, FlexibleAvailabilityRequestTypes, DayAvailability } from './types'

export type { FlexibleAvailability, FlexibleAvailabilityRequestTypes, DayAvailability }

export default gql`
  query ($startDate: String, $endDate: String, $userId: String, $confirmed: Boolean) {
    flexibleAvailability(filter: { userId: $userId, startDate: $startDate, endDate: $endDate, confirmed: $confirmed }) {
      nodes {
        date
        dayScheduleEntries {
          available
          bookedSchoolName
          bookingId
          endTime
          expressId
          expressPoolName
          startTime
          timeScheduleType
        }
      }
    }
  }
`
