import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'

import styles from './styles'
import { colorsNew } from 'src/styles'
import { StarIcon } from 'src/icons'

import { FieldInputProps, FormikProps } from 'formik/dist/types'

const ratingScope = Array(5)
  .fill(undefined)
  .map((_, i) => i + 1)

type Props = {
  label: string
  form: FormikProps<any>
  field: FieldInputProps<any>
}
const RatingRow = ({ label, form, field }: Props) => {
  const error = (form.touched[field.name] && form.errors[field.name]) || ''
  const setRate = (rate: number) => form.setFieldValue(field.name, rate)
  const rate = form.values[field.name]

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.inputRow}>
        {ratingScope.map((star, index) => (
          <TouchableOpacity key={index} onPress={() => setRate(index + 1)} style={styles.icon}>
            <StarIcon
              fill={rate > index ? colorsNew.redesign.success.main : colorsNew.redesign.action.disabled}
              size={29}
              testID={`star-${index + 1}`}
            />
          </TouchableOpacity>
        ))}
      </View>
      {error && <Text style={styles.error}>{error}</Text>}
    </View>
  )
}

export default RatingRow
