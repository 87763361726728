import { TaskItem } from 'src/graphql/RequiredTasks'

export const sortTasksByStatus = (taskStatuses: TaskItem[]) => {
  const statusOrder = {
    enabled: 1,
    expired: 2,
    expiresSoon: 3,
    locked: 4,
    completed: 5,
  }

  taskStatuses.sort((a, b) => {
    const statusA = a.status
    const statusB = b.status

    const orderA = statusOrder[statusA] || 0
    const orderB = statusOrder[statusB] || 0

    if (orderA < orderB) {
      return -1
    } else if (orderA > orderB) {
      return 1
    } else {
      return 0
    }
  })

  return taskStatuses
}
