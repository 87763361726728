import { BookingTypes } from 'src/graphql/Booking'
import { OrderTypes } from 'src/graphql/Order'

type JobInfoT = {
  assignmentInfo: BookingTypes['assignmentInfo'] | OrderTypes['assignmentInfo']
  bookingInfo?: BookingTypes['school']['bookingInfo'] | OrderTypes['school']['bookingInfo']
}

export const getJobInformation = (
  assignmentInfo: JobInfoT['assignmentInfo'] = '',
  bookingInfo: JobInfoT['bookingInfo'] = '',
) => {
  let text = assignmentInfo || bookingInfo

  if (assignmentInfo?.length && bookingInfo?.length) {
    text = `${assignmentInfo}\n\n${bookingInfo}`
  }

  return text
}
