import { StyleSheet } from 'react-native'
import { Theme } from 'react-native-calendars/src/types'

import { colorsNew, fonts, paddingNew, typography, utils } from 'src/styles'

export const themeStyle = {
  arrowColor: colorsNew.redesign.action.active,
  monthTextColor: colorsNew.redesign.text.primary,
  textDayHeaderFontFamily: fonts.muktaRegular,
  textDayHeaderFontSize: 12,
  textMonthFontFamily: fonts.poppinsSemiBold,
  textMonthFontSize: 20,
  textMonthFontWeight: '600',
  textSectionTitleColor: colorsNew.redesign.text.primary,
  weekVerticalMargin: 0,
} as Theme

export default StyleSheet.create({
  textInput: {
    backgroundColor: colorsNew.redesign.secondary.contrast,
    marginVertical: paddingNew.xs,
  },

  modalContainer: {
    ...utils.shadow('selected', colorsNew.black),
    backgroundColor: colorsNew.redesign.secondary.contrast,
    borderRadius: 4,
    padding: paddingNew.sm,
  },
  modalButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: paddingNew.base,
  },
  buttonLabel: {
    ...typography.buttonLarge,
    color: colorsNew.redesign.text.primary,
    textTransform: 'uppercase',
  },
  buttonConfirm: {
    paddingHorizontal: paddingNew.md,
  },
  buttonLabelConfirm: {
    color: colorsNew.redesign.primary.dark,
  },
  dayText: {
    color: colorsNew.redesign.text.primary,
    textAlign: 'center',
  },
  dayTextSelected: {
    color: colorsNew.redesign.secondary.contrast,
  },
  dayTextDisabled: {
    color: colorsNew.grey._500,
  },
  dayCircle: {
    alignItems: 'center',
    backgroundColor: colorsNew.white,
    borderRadius: paddingNew.sm,
    height: paddingNew.md,
    justifyContent: 'center',
    width: paddingNew.md,
  },
  dayCircleDisabled: {
    backgroundColor: colorsNew.white,
  },

  dayCircleSelected: {
    backgroundColor: colorsNew.redesign.secondary.dark,
  },

  dayContainer: { alignItems: 'center' },
})
