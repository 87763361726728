import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const CalendarIcon = ({ fill = '#212121', size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M20 3.35864H19V2.35864C19 1.80864 18.55 1.35864 18 1.35864C17.45 1.35864 17 1.80864 17 2.35864V3.35864H7V2.35864C7 1.80864 6.55 1.35864 6 1.35864C5.45 1.35864 5 1.80864 5 2.35864V3.35864H4C2.9 3.35864 2 4.25864 2 5.35864V21.3586C2 22.4586 2.9 23.3586 4 23.3586H20C21.1 23.3586 22 22.4586 22 21.3586V5.35864C22 4.25864 21.1 3.35864 20 3.35864ZM19 21.3586H5C4.45 21.3586 4 20.9086 4 20.3586V8.35864H20V20.3586C20 20.9086 19.55 21.3586 19 21.3586Z"
      fill={fill}
    />
  </Svg>
)

export default CalendarIcon
