const xsBase = 8
const base = xsBase * 3

export const padding = {
  xxs: xsBase / 2, //4
  xs: xsBase, // 8
  sm: xsBase * 2, // 16
  base: xsBase * 3, // 24
  md: xsBase * 4, // 32
  lg: base * 2, // 48
  xl: base * 3, // 72
  xll: base * 4, // 96
}
