export const errorTypes = {
  confirmation: 'confirmation',
  delete: 'delete',
  file: 'file',
  legislation: 'legislation',
  save: 'save',
  training: 'training',
  yoti: 'yoti',
} as const

type ValueOf<T> = T[keyof T]

export type ErrorType = ValueOf<typeof errorTypes>
