import { StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    marginBottom: paddingNew.base + 4,
  },
  inputRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    paddingBottom: paddingNew.xs - 2,
    paddingTop: paddingNew.sm,
    textAlign: 'center',
  },
  icon: {
    marginHorizontal: paddingNew.xs,
  },
  error: {
    color: colorsNew.error,
    fontSize: fontSizesNew.xxxs.size,
    textAlign: 'center',
    fontFamily: fonts.poppinsMedium,
    marginTop: paddingNew.xs,
  },
})

export default styles
