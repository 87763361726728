const FirebaseAdapter = () => ({
  deleteToken: () => {},
  getInitialNotification: () => {},
  getToken: () => {},
  onMessage: () => {},
  onNotificationOpenedApp: () => {},
  onTokenRefresh: () => {},
  registerDeviceForRemoteMessages: () => {},
  unregisterDeviceForRemoteMessages: () => {},
})

export type FirebaseMessagingTypes = {
  SendErrorEvent: any
}

export default FirebaseAdapter
