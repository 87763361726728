const fontSizes = {
  xxxxs: {
    size: 10,
    lh: 16,
  },
  xxxs: {
    size: 12,
    lh: 17,
  },
  xxs: {
    size: 14,
    lh: 22,
  },
  xs: {
    size: 16,
    lh: 20,
  },
  sm: {
    size: 18,
    lh: 24,
  },
  md: {
    size: 24,
    lh: 32,
  },
  lg: {
    size: 32,
    lh: 42,
  },
  xl: {
    size: 40,
    lh: 48,
  },
  xxl: {
    size: 48,
    lh: 48,
  },
  xxxl: {
    size: 72,
    lh: 80,
  },
}

export default fontSizes
