import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { BUILD_MODE, MIXPANEL_API_KEY_PROD } from 'src/utils/webAdapters/DotEnvAdapter'
import MixpanelWeb from 'mixpanel-browser'

import { getCountryCode } from 'src/store/app/selectors'
import { isUserApplicant } from 'src/store/user/selectors'
import { useAppSelector } from 'src/hooks/reduxHooks'
import translations, { translate } from 'src/utils/translations/translations'

const isStaging = BUILD_MODE === 'staging'

const apiKey = __DEV__ || isStaging ? null : MIXPANEL_API_KEY_PROD

type MixpanelContextType = { mixpanel: typeof MixpanelWeb | null; mixpanelUserSet: () => void }

type TProps = {
  children: ReactNode
}

export const MixpanelContext = createContext<MixpanelContextType>({
  mixpanel: null,
  mixpanelUserSet: () => null,
})

export const MixpanelProvider = ({ children }: TProps) => {
  const [mixpanel, setMixpanel] = useState<typeof MixpanelWeb | null>(null)

  const company =
    useAppSelector(state => state?.user?.included?.find(x => x.type === 'companies')?.attributes?.name) ||
    translate(translations.noCompany)
  const countryCode = useAppSelector(getCountryCode)
  const isApplicant = useAppSelector(isUserApplicant)
  const region = useAppSelector(state => state?.user?.region?.attributes?.name) || translate(translations.noRegion)
  const createdAt = useAppSelector(state => state?.user?.attributes?.createdAt) || ''
  const userAcceptedAt = useAppSelector(state => state.user?.attributes?.acceptedAt) || ''

  const mixpanelUserSet = () => {
    mixpanel?.people?.set('user state', isApplicant ? 'applicant' : 'student')
    mixpanel?.people?.set('company', company)
    mixpanel?.people?.set('countryCode', countryCode)
    mixpanel?.people?.set('createdAt', createdAt)
    mixpanel?.people?.set('region', region)
    mixpanel?.people?.set('acceptedAt', userAcceptedAt)
  }

  useEffect(() => {
    const initMixpanel = async () => {
      MixpanelWeb.init(apiKey)
      setMixpanel(MixpanelWeb)
    }
    if (apiKey) {
      initMixpanel()
    }
  }, [])

  return <MixpanelContext.Provider value={{ mixpanel, mixpanelUserSet }}>{children}</MixpanelContext.Provider>
}
