import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginTop: paddingNew.md,
  },
  buttonContainer: {
    alignItems: 'center',
    marginVertical: paddingNew.md,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  infoText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  sectionContainer: {
    marginVertical: paddingNew.md,
  },
})

export default styles
