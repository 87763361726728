import React, { useContext, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import RowWithCheckBox from 'src/components/RowWithCheckBox'
import SwitchRow from '../SwitchRow'
import { MixpanelContext } from 'src/context/MixpanelContext'
import styles from '../../styles'
import { LevelTypes } from 'src/graphql/Level/types'
import LoadingIndicator from 'src/components/LoadingIndicator'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
type LevelTypesWithOptionalGroup = Optional<LevelTypes, 'group'>

type Props = {
  customContainerStyle?: object
  isSending?: boolean
  isSendingWorkflow?: boolean
  isWorkload?: boolean
  label: string
  optionsList: LevelTypesWithOptionalGroup[]
  preferredOptions: string[]
  setIsSending: (isSending: boolean) => any
  setIsSendingWorkflow?: (isSending: boolean) => any
  updatePreferences: (options: string[]) => any
}

const PreferencesTable = ({
  customContainerStyle = {},
  isSending = false,
  isSendingWorkflow = false,
  isWorkload = false,
  label,
  optionsList,
  preferredOptions,
  setIsSending,
  setIsSendingWorkflow,
  updatePreferences,
}: Props) => {
  const [checkedOptions, setCheckedOptions] = useState(preferredOptions)

  const { mixpanel } = useContext(MixpanelContext)

  const updatePreference = (preferenceId: string, isChecked: boolean, title: string, onSuccess?: () => any) => {
    if (isWorkload && setIsSendingWorkflow) setIsSendingWorkflow(true)
    if (setIsSending) setIsSending(true)

    mixpanel?.track('Job Preferences', {
      action: 'save',
      label,
      name: title,
      value: isChecked,
    })
    const updatedOptions = isChecked
      ? [...checkedOptions, preferenceId]
      : checkedOptions.filter(option => option !== preferenceId)

    setCheckedOptions(updatedOptions)
    updatePreferences(updatedOptions).finally(() => onSuccess && onSuccess())
  }

  const renderItems = () =>
    optionsList.map(({ id, title }) => {
      const isChecked = checkedOptions.includes(id)
      return !isWorkload ? (
        <SwitchRow
          disabled={isSending}
          isChecked={isChecked}
          key={id}
          onValueChange={(value, onSuccess) => updatePreference(id, value, title, onSuccess)}
          testID={id}
          title={title}
        />
      ) : (
        <RowWithCheckBox
          disabled={isSending}
          isChecked={isChecked}
          key={id}
          onValueChange={value => updatePreference(id, value, title)}
          testID={`checkbox-${id}`}
          title={title}
        />
      )
    })

  return (
    <View style={StyleSheet.flatten([customContainerStyle])}>
      {!isWorkload && <Text style={styles.description}>{label}</Text>}
      <View>{renderItems()}</View>

      {isWorkload && isSendingWorkflow && (
        <View style={styles.headerLoading}>
          <LoadingIndicator size="small" />
        </View>
      )}
    </View>
  )
}

export default PreferencesTable
