import React, { useCallback, useEffect, useState } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import LoadingIndicator from 'src/components/LoadingIndicator'
import useGetCompletedTasks from '../CategoriesList/useGetCompletedTasks'
import TaskListLoaded from './TaskListLoaded'

type Props = ProfileAndRecruitmentScreensProps<'TasksList'>

const TaskList = ({ route }: Props) => {
  const { categoriesData, isLoading, refetchTasks, dataPerRef } = useGetCompletedTasks()
  const [isOpeningView, setIsOpeningView] = useState(false)
  const navigation = useNavigation()

  useFocusEffect(
    useCallback(() => {
      refetchTasks && refetchTasks()
      setIsOpeningView(false)
      navigation?.getParent?.()?.setOptions({ headerShown: false })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )
  useEffect(() => {
    setIsOpeningView(false)
    navigation?.getParent?.()?.setOptions({ headerShown: false })
  }, [])

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <TaskListLoaded
      categoriesData={categoriesData}
      dataPerRef={dataPerRef}
      isOpeningView={isOpeningView}
      route={route}
      setIsOpeningView={setIsOpeningView}
    />
  )
}

export default TaskList
