import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  scrollContent: {
    paddingHorizontal: paddingNew.sm,
    paddingBottom: paddingNew.md,
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginVertical: paddingNew.xs,
  },
  jobTitle: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.xs,
  },
  sectionTitle: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.xs,
  },
  documentsList: {
    marginLeft: paddingNew.md,
  },
  mask: {
    opacity: 0.4,
  },
  alert: {
    marginTop: paddingNew.sm,
  },
})

export default styles
