import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.sm,
  },
  buttonContainer: {
    alignItems: 'center',
  },
  checkboxContainer: {
    marginBottom: paddingNew.sm,
  },
  checkboxDisabled: {
    marginBottom: paddingNew.md,
  },
  container: {
    marginHorizontal: paddingNew.sm,
    paddingBottom: paddingNew.md,
  },
  footer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginVertical: paddingNew.md,
    paddingTop: paddingNew.sm,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  mainInfo: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  sectionContainer: {
    marginVertical: paddingNew.md,
  },
  subInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
  subtitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.sm,
    marginBottom: paddingNew.xs,
  },
  taskInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles
