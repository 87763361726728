import { StyleSheet } from 'react-native'

import { colors, common, paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    height: 58,
    justifyContent: 'center',
    width: 50,
  },
  spacing: {
    paddingHorizontal: paddingNew.sm,
  },
  title: StyleSheet.flatten([
    common.mediumText,
    {
      color: colors.white,
    },
  ]),
  icon: {
    fontSize: 30,
    alignSelf: 'center',
    transform: [{ rotateY: '180deg' }],
  },
  androidIcon: {
    alignSelf: 'center',
    transform: [{ rotateY: '180deg' }],
  },
})
