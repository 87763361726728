import { StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, padding } from 'src/styles'

export default StyleSheet.create({
  radioGroupContainer: {
    marginVertical: padding.md,
    position: 'relative',
  },
  radioGroupLabel: {
    fontFamily: fonts.poppinsMedium,
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  error: {
    bottom: -12,
    color: colorsNew.redesign.error.main,
    fontSize: fontSizesNew.xxs.size,
    height: 18,
    left: 0,
    position: 'absolute',
  },
})
