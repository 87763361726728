import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

import styles from './styles'

type Props = {
  title: string
  type: 'light' | 'standard'
  testID?: string
}

const BadgeItem = ({ title, type, testID }: Props) => (
  <View style={StyleSheet.flatten([styles.badge, type === 'light' && styles.lightBadge])} testID={testID}>
    <Text style={styles.title}>{title}</Text>
  </View>
)

export default BadgeItem
