import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#000'

const PreferencesIcon = ({ fill, size, ...props }: IconTypes) => {
  const fillColor = fill?.length ? fill : defaultFill

  return (
    <Svg height={size} viewBox="0 0 25 25" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43 5.346a.88.88 0 1 1 1.76 0v14.782a.88.88 0 1 1-1.76 0V5.346Zm4.928 0a.88.88 0 0 1 1.76 0v14.782a.88.88 0 1 1-1.76 0V5.346Zm4.927 0a.88.88 0 1 1 1.76 0v14.782a.88.88 0 1 1-1.76 0V5.346Z"
        fill={fillColor}
      />
      <Path
        d="M12.238 10.45a1.936 1.936 0 1 0 0-3.872 1.936 1.936 0 0 0 0 3.872Zm4.927 7.743a1.936 1.936 0 1 0 0-3.872 1.936 1.936 0 0 0 0 3.872ZM7.31 15.377a1.936 1.936 0 1 0 0-3.871 1.936 1.936 0 0 0 0 3.871Z"
        fill="#fff"
        stroke={fillColor}
        strokeWidth={1.056}
      />
    </Svg>
  )
}

export default PreferencesIcon
