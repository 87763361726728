import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const WaitingIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 54 54" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="m46.09 12.238-6.406-5.324a2.08 2.08 0 0 0-2.93.27c-.73.875-.606 2.183.27 2.933l6.382 5.324a2.085 2.085 0 0 0 2.934-.273c.746-.871.62-2.184-.25-2.93ZM10.574 15.441l6.383-5.324c.895-.75 1.02-2.058.27-2.933-.727-.895-2.04-.997-2.91-.27L7.91 12.238c-.87.746-.996 2.059-.25 2.93.73.895 2.04 1 2.914.273ZM8.285 28.852c0-10.336 8.38-18.715 18.715-18.715 10.336 0 18.715 8.379 18.715 18.715 0 10.335-8.38 18.714-18.715 18.714-10.336 0-18.715-8.379-18.715-18.714Zm18.145.746-7.008 4.16c-.727.414-.953 1.351-.52 2.078.418.707 1.332.918 2.04.5l7.59-4.555a2.052 2.052 0 0 0 1.019-1.785V20.18c0-.832-.668-1.496-1.496-1.496h-.125c-.832 0-1.5.664-1.5 1.496Zm0 0" />
  </Svg>
)

export default WaitingIcon
