import React from 'react'
import { BlurView } from 'src/utils/webAdapters/@react-native-community/blur'
import { TouchableOpacity, View, Text, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import { InfoOutlinedIcon, LocationOnIcon } from 'src/icons'
import { ClientDetails, LevelTypes } from 'src/graphql/sharableTypes'
import { colorsNew } from 'src/styles'
import { isGBSelector } from 'src/store/app/selectors'
import { JobsNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { openMapUsingCoordinates, trimAddress } from 'src/global/utils'
import jobDetailsStyles from 'src/scenes/Main/Jobs/Details/components/JobDetails/styles'
import SchoolMap from '../SchoolMap'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import RedirectRow from 'src/components/RedirectRow'

const isIOS = Platform.OS === 'ios'

type NavigationTypes =
  | JobsNavigatorScreensProps<'Booking'>
  | JobsNavigatorScreensProps<'Order'>
  | JobsNavigatorScreensProps<'ExpressSub'>

type Props = {
  level: LevelTypes
  schoolData: ClientDetails
}

type GetSchoolAddressType = {
  isGB: boolean
  schoolData: ClientDetails
  withZip: boolean
}

const schoolAddressArray = ({ withZip, schoolData, isGB }: GetSchoolAddressType) =>
  isGB
    ? [schoolData?.homeAddress?.street, schoolData?.homeAddress?.city, withZip && schoolData?.homeAddress?.zip]
    : [schoolData?.homeAddress?.street, withZip && schoolData?.homeAddress?.zip, schoolData?.homeAddress?.city]

export const getSchoolAddress = ({ withZip, schoolData, isGB }: GetSchoolAddressType) => {
  const schoolHomeAddress = schoolAddressArray({ withZip, schoolData, isGB }).filter(Boolean).join(', ')
  return schoolData.homeAddress ? schoolHomeAddress : trimAddress(schoolData.address)
}

const SchoolNavigationRow = ({ schoolData, level }: Props) => {
  const navigation = useNavigation<NavigationTypes['navigation']>()
  const isGB = useSelector(isGBSelector)

  const schoolAddress = getSchoolAddress({ withZip: true, schoolData, isGB })
  const schoolAddressDisplay = getSchoolAddress({ withZip: false, schoolData, isGB })

  const navigateToSchool = () =>
    openMapUsingCoordinates({ coordinates: schoolData?.homeAddress?.coordinates, label: schoolAddressDisplay })
  const goToSchoolDetails = () => navigation.navigate('ClientDetails', { school: schoolData, level })

  return (
    <>
      <View style={jobDetailsStyles.divider} />

      <TouchableOpacity onPress={navigateToSchool}>
        <View style={styles.titleContainer}>
          <LocationOnIcon fill={colorsNew.redesign.text.primary} size={24} />
          <View style={styles.row}>
            <Text style={styles.title} numberOfLines={1}>
              {schoolAddress}
            </Text>
          </View>
        </View>
        <View>
          <SchoolMap schoolAddress={schoolAddress} />
          <View style={styles.button}>
            {isIOS ? (
              <BlurView blurAmount={3} blurType="light" style={styles.blur}>
                <Text style={styles.buttonText}>{translate(translations.openMap)}</Text>
              </BlurView>
            ) : (
              <Text style={styles.buttonText}>{translate(translations.openMap)}</Text>
            )}
          </View>
        </View>
      </TouchableOpacity>

      <View style={jobDetailsStyles.divider} />

      <RedirectRow
        Icon={InfoOutlinedIcon}
        onPress={goToSchoolDetails}
        subtitle={translate(translations.jobOfferRoutinesDescription)}
        title={schoolData.name}
      />
    </>
  )
}

export default SchoolNavigationRow
