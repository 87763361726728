import React, { useRef, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { ScrollView, TextInput, View } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import isEmpty from 'lodash/isEmpty'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { saveUser } from 'src/store/user/actions'
import { useAppSelector, useAppDispatch } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import PasswordInputNew from 'src/components/Form/PasswordInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import validationSchema from './validationSchema'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = {
  navigation: StackNavigationProp<any>
}

type FormInputRefType = TextInput | null

type InitialValuesType = {
  oldPassword: string
  password: string
  passwordConfirmation: string
}

const initialValues: InitialValuesType = {
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
}

const ChangePasword = ({ navigation }: Props) => {
  const [isWrongPasswordError, setIsWrongPasswordError] = useState(false)

  const dispatch = useAppDispatch()
  const userId = useAppSelector(state => state.user.id) as string

  const newPasswordInput = useRef<FormInputRefType>(null)
  const passwordConfirmationInput = useRef<FormInputRefType>(null)

  useBugsnagView('Profile - ChangePasword')

  const onSubmit = (values: InitialValuesType, form: FormikHelpers<InitialValuesType>) => {
    bugsnagActionBreadcrumb('submit form - change password', { id: userId, user: values })

    form.setSubmitting(true)
    dispatch(
      saveUser({
        id: userId,
        user: values,
        onError: error => {
          form.setSubmitting(false)
          const isWrongPassword = error?.data?.errors?.[0]?.code === 'incorrect' || false
          setIsWrongPasswordError(isWrongPassword)
        },
        onSuccess: () => {
          form.setSubmitting(false)
          navigation.goBack()
        },
      }),
    )
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => {
          const allValuesFilled = !!(values.oldPassword && values.password && values.passwordConfirmation)

          return (
            <>
              <View style={styles.inputsWrapper}>
                <PasswordInputNew
                  blurOnSubmit={false}
                  errorMessage={translate(translations.currentPasswordError)}
                  isError={isWrongPasswordError && touched.oldPassword}
                  label={translate(translations.oldPassword)}
                  onBlur={() => setFieldTouched('oldPassword', true)}
                  onChangeText={text => {
                    setIsWrongPasswordError(false)
                    setFieldValue('oldPassword', text)
                  }}
                  onSubmitEditing={newPasswordInput?.current?.focus}
                  returnKeyType="next"
                  testID="oldPassword-input"
                  value={values.oldPassword}
                />
                <PasswordInputNew
                  blurOnSubmit={false}
                  errorMessage={errors.password}
                  forwardRef={(ref: FormInputRefType) => (newPasswordInput.current = ref)}
                  isError={!isEmpty(errors.password) && touched.password}
                  label={translate(translations.newPassword)}
                  onBlur={() => setFieldTouched('password', true)}
                  onChangeText={text => setFieldValue('password', text)}
                  onSubmitEditing={passwordConfirmationInput?.current?.focus}
                  returnKeyType="next"
                  testID="password-input"
                  value={values.password}
                />
                <PasswordInputNew
                  errorMessage={errors.passwordConfirmation}
                  forwardRef={(ref: FormInputRefType) => (passwordConfirmationInput.current = ref)}
                  isError={!isEmpty(errors.passwordConfirmation) && touched.passwordConfirmation}
                  label={translate(translations.passwordConfirmation)}
                  onBlur={() => setFieldTouched('passwordConfirmation', true)}
                  onChangeText={text => setFieldValue('passwordConfirmation', text)}
                  onSubmitEditing={handleSubmit}
                  returnKeyType="send"
                  testID="passwordConfirmation-input"
                  value={values.passwordConfirmation}
                />
              </View>
              <View style={styles.buttonWrapper}>
                <ButtonNew
                  containerStyle={styles.button}
                  disabled={!allValuesFilled || isWrongPasswordError || !isValid}
                  isShort
                  isSubmitting={isSubmitting}
                  onPress={handleSubmit}
                  testID="submit-button"
                  title={translate(translations.changePassword)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </>
          )
        }}
      </Formik>
    </ScrollView>
  )
}

export default ChangePasword
