import React, { useContext } from 'react'
import { View, Pressable } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { ChevronRightIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { getLockedTaskDescription, tasksScreensConfig } from './utils'
import { getTaskStatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components/StatusInfo/utils'
import { isGBSelector } from 'src/store/app/selectors'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { ProfileTaskStatus, ProfileTaskType, profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import EnabledTaskBody from './components/EnabledTask'
import I18n from 'src/utils/translations/i18n'
import LockedTaskBody from './components/LockedTask'
import useLockedTaskModal from './hooks/useLockedTaskModal'
import styles from './styles'

export type TaskRowProps = {
  expiresAt: string | null
  status: ProfileTaskStatus
  task: ProfileTaskType
  testID?: string
  timeToComplete: number | string
}

const TaskRow = ({ status, task, testID, timeToComplete, expiresAt }: TaskRowProps) => {
  const isGB = useAppSelector(isGBSelector)
  const isLocked = status === profileTaskStatus.locked

  const { openModal } = useLockedTaskModal({ isGB, task })
  const { mixpanel } = useContext(MixpanelContext)
  const navigation = useNavigation<ProfileAndRecruitmentScreensProps<'TasksList'>['navigation']>()
  const screenName = tasksScreensConfig[task]

  const navigateToTaskDetails = () => {
    const taskStatus = getTaskStatusInfo(status, timeToComplete)
    mixpanel?.track('Enter Recruitment Task', { TaskStatus: taskStatus.title, TaskTitle: I18n.t(`taskTitle_${task}`) })
    //@ts-ignore
    navigation.navigate(screenName, { status, timeToComplete, task, expiresAt })
  }

  return (
    <Pressable
      onPress={!isLocked ? navigateToTaskDetails : openModal}
      style={({ pressed }) => (pressed ? styles.rowPressed : styles.row)}
      testID={testID}
    >
      <View style={styles.rowBody}>
        {isLocked ? (
          <LockedTaskBody task={task} lockInfo={getLockedTaskDescription(task, isGB)} />
        ) : (
          <EnabledTaskBody status={status} task={task} timeToComplete={timeToComplete} />
        )}
        <ChevronRightIcon
          fill={isLocked ? colorsNew.redesign.action.disabled : colorsNew.redesign.secondary.main}
          size={24}
        />
      </View>
    </Pressable>
  )
}

export default TaskRow
