import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#01579B'

const InfoOutlineIcon = ({ fill = defaultFill, size, ...props }: IconTypes) => (
  <Svg height={size} viewBox="0 0 20 20" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M9.083 5.417h1.833V7.25H9.083V5.417Zm0 3.667h1.833v5.5H9.083v-5.5ZM10 .834C4.94.833.833 4.94.833 10c0 5.06 4.107 9.167 9.167 9.167S19.166 15.06 19.166 10 15.06.833 10 .833Zm0 16.5c-4.043 0-7.334-3.291-7.334-7.334 0-4.042 3.291-7.333 7.334-7.333 4.042 0 7.333 3.29 7.333 7.333s-3.29 7.334-7.333 7.334Z"
      fill={fill}
    />
  </Svg>
)

export default InfoOutlineIcon
