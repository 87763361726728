import React, { createContext, useState } from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import CustomModal, { CustomModalTProps } from 'src/components/Modal/CustomModal'

const DEFAULT_PARAMS: CustomModalTProps = {
  buttons: [
    {
      title: '',
      variant: buttonVariants.outlinedDefault,
    },
  ],
  colorSchema: customModalColorSchema.default,
  title: '',
  variant: customModalVariants.standard,
}

type DisplayModalContextType = {
  display: boolean
  setDisplay: (isDisplay: boolean) => void
  params: CustomModalTProps
  setParams: (params: CustomModalTProps) => void
}

type Props = {
  children: JSX.Element
}

const DisplayModalContext = createContext<DisplayModalContextType>({
  display: false,
  params: DEFAULT_PARAMS,
  setDisplay: isDisplay => null,
  setParams: params => null,
})

const DisplayModalProvider = ({ children }: Props) => {
  const [display, setDisplay] = useState(false)
  const [params, setParams] = useState(DEFAULT_PARAMS)

  return (
    <DisplayModalContext.Provider value={{ display, setDisplay, params, setParams }}>
      <CustomModal {...params} isVisible={display} modalPlace="DispalyModalContext" />
      {children}
    </DisplayModalContext.Provider>
  )
}

export { DisplayModalProvider, DisplayModalContext, DEFAULT_PARAMS }
export type { DisplayModalContextType }
