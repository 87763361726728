import React from 'react'
import { Text, TouchableHighlight, View } from 'react-native'

import { colorsNew } from 'src/styles'
import { PhoneRoundedIcon } from 'src/icons'
import { openTelUrl } from 'src/global/utils'
import LogoIcon from 'src/icons/logo/logoIcon'
import translations, { translate } from 'src/utils/translations/translations'
import useSupportContact from 'src/hooks/useSupportContact'
import styles from './styles'

const ContactDetailsRow = () => {
  const contact = useSupportContact()

  const phone = contact?.phone
  const call = () => openTelUrl(phone)

  if (!phone) return null

  return (
    <>
      <Text style={styles.title}>{translate(translations.jobOfferContactTitle)}</Text>
      <View style={styles.container}>
        <View style={styles.contactInfo}>
          <LogoIcon variant="black" size={37} />
          <View style={styles.content}>
            <Text style={styles.description}>{translate(translations.jobOfferContactDescription)}</Text>
            <Text style={styles.phone}>{phone}</Text>
          </View>
        </View>
        <TouchableHighlight onPress={call} style={styles.button} underlayColor={colorsNew.redesign.action.pressed}>
          <PhoneRoundedIcon size={24} fill={colorsNew.redesign.action.active} />
        </TouchableHighlight>
      </View>
    </>
  )
}

export default ContactDetailsRow
