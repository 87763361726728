import background from 'src/assets/images/background.png'
import backgroundDark from 'src/assets/images/background-dark.png'
import backgroundLogin from 'src/assets/images/loginBackground.png'
import mapFallback from 'src/assets/images/mapFallback.png'
import preschool from 'src/assets/images/preschool.png'
import primary from 'src/assets/images/primary.png'
import referallAnnouncementSE from 'src/assets/images/referralAnnouncementSE.png'
import schoolMap from 'src/assets/images/schoolMap.png'
import schoolPlaceholder from 'src/assets/images/schoolPlaceholder.png'
import secondary from 'src/assets/images/secondary.png'
import sen from 'src/assets/images/SEN.png'
import googleLogo from 'src/assets/images/google_on_white.png'

import support from 'src/assets/images/support.jpg'
import step1 from 'src/assets/images/stepsGuide/step1.png'
import step2 from '../assets/images/stepsGuide/step2.png'
import step3 from '../assets/images/stepsGuide/step3.png'
import step4 from '../assets/images/stepsGuide/step4.png'
import step5 from '../assets/images/stepsGuide/step5.png'
import step6 from '../assets/images/stepsGuide/step6.png'
import step7 from '../assets/images/stepsGuide/step7.png'
import step8 from '../assets/images/stepsGuide/step8.png'
import step9 from '../assets/images/stepsGuide/step9.png'
import step10 from '../assets/images/stepsGuide/step10.png'
import step11 from '../assets/images/stepsGuide/step11.png'
import step12 from '../assets/images/stepsGuide/step12.png'
import step13 from '../assets/images/stepsGuide/step13.png'
import step14 from '../assets/images/stepsGuide/step14.png'
import step15 from '../assets/images/stepsGuide/step15.png'
import step16 from '../assets/images/stepsGuide/step16.png'
import step17 from '../assets/images/stepsGuide/step17.png'

import humlyCharacter from '../assets/images/stepsGuide/humly_character.png'

export default {
  background,
  backgroundDark,
  backgroundLogin,
  googleLogo,
  humlyCharacter,
  mapFallback,
  preschool,
  primary,
  referallAnnouncementSE,
  schoolMap,
  schoolPlaceholder,
  secondary,
  sen,
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  support,
}
