import * as Yup from 'yup'

import { regexes } from 'src/global/utils'
import translations, { translate } from 'src/utils/translations/translations'

const getValidationSchema = (isGB: boolean) =>
  Yup.object().shape({
    relative: Yup.object({
      name: Yup.string()
        .min(3, translate(translations.emergencyNameError))
        .matches(regexes.onlyLetters, translate(translations.errorLettersOnly))
        .optional(),
      relationship: Yup.string()
        .min(3, translate(translations.emergencyRelationError))
        .matches(regexes.onlyLetters, translate(translations.errorLettersOnly))
        .optional(),
      phone: Yup.string()
        .nullable()
        .test({
          name: 'phone',
          test: value => (!value ? true : isGB ? regexes.gbPhoneNumber.test(value) : regexes.svPhoneNumber.test(value)),
          message: () => translate(translations.phoneError),
        }),
    }),
  })

export default getValidationSchema
