import { StyleSheet } from 'react-native'

export const SIZE = 132

export default StyleSheet.create({
  profilePicture: {
    borderRadius: 100,
    height: SIZE,
    width: SIZE,
  },
  button: {
    position: 'absolute',
    right: 0,
  },
})
