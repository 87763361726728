import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const QuestionMarkIcon = ({ fill = '#212121', size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 11 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M0.919822 4.54C0.119822 4.2 -0.220178 3.21 0.259822 2.49C1.22982 1.05 2.84982 0 4.98982 0C7.33982 0 8.94982 1.07 9.76982 2.41C10.4698 3.56 10.8798 5.71 9.79982 7.31C8.59982 9.08 7.44982 9.62 6.82982 10.76C6.67982 11.03 6.58982 11.25 6.52982 11.7C6.43982 12.43 5.83982 13 5.09982 13C4.22982 13 3.51982 12.25 3.61982 11.38C3.67982 10.87 3.79982 10.34 4.07982 9.84C4.84982 8.45 6.32982 7.63 7.18982 6.4C8.09982 5.11 7.58982 2.7 5.00982 2.7C3.83982 2.7 3.07982 3.31 2.60982 4.04C2.25982 4.61 1.52982 4.79 0.919822 4.54ZM6.99982 17C6.99982 18.1 6.09982 19 4.99982 19C3.89982 19 2.99982 18.1 2.99982 17C2.99982 15.9 3.89982 15 4.99982 15C6.09982 15 6.99982 15.9 6.99982 17Z"
      fill={fill}
    />
  </Svg>
)

export default QuestionMarkIcon
