import { colorsNew, paddingNew, typography } from 'src/styles'

export const containerStyles = {
  alignItems: 'center',
  backgroundColor: colorsNew.redesign.primary.main,
  borderRadius: 20,
  color: colorsNew.black,
  cursor: 'pointer',
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  marginBottom: -paddingNew.sm,
  overflow: 'hidden',
  padding: paddingNew.xxs,
  position: 'relative',
  ...typography.buttonLarge,
}

export const inputStyles = {
  cursor: 'pointer',
  height: '100%',
  opacity: '0',
  position: 'absolute',
  right: '0',
  top: '0',
  zIndex: '99',
}
