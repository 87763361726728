import { Platform, StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const isWeb = Platform.OS === 'web'

export const textHeight = {
  height: '100%',
  ...(isWeb && {
    lineHeight: 40,
  }),
}

export const subTextButton = (isDisabled: boolean) => ({
  ...typography.caption,
  color: isDisabled ? colorsNew.redesign.text.disabled : colorsNew.redesign.text.secondary,
})

export const textButton = (isDisabled: boolean) => ({
  ...typography.inputText,
  color: isDisabled ? colorsNew.redesign.text.disabled : colorsNew.redesign.text.primary,
})

export const iconFill = {
  active: colorsNew.redesign.secondary.main,
  inActive: colorsNew.redesign.action.disabled,
}

const button = {
  alignItems: 'center',
  backgroundColor: colorsNew.redesign.background.paper,
  borderRadius: 4,
  flex: 1,
  flexDirection: 'row',
  height: 40,
  paddingHorizontal: 16,
  paddingVertical: 8,
  width: '100%',

  ...(isWeb && {
    minHeight: 40,
    paddingVertical: 0,
  }),
} as ViewStyle

const badge = {
  backgroundColor: colorsNew.error,
  borderRadius: 50,
  height: 8,
  width: 8,
} as ViewStyle

export default StyleSheet.create({
  view: {
    paddingHorizontal: 16,
    paddingTop: 56,
  },
  leftBadge: {
    ...badge,
    marginLeft: 4,
  },
  rightBadge: {
    ...badge,
    marginRight: 4,
  },
  button,
  buttonPressed: {
    ...button,
    backgroundColor: colorsNew.redesign.action.pressed,
  },
  profileAside: {
    flexShrink: 1,
  },
  profileFullNameContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  profileFullName: {
    ...typography.h5,
    color: colorsNew.redesign.text.primary,
  },
  icon: {
    alignSelf: 'center',
    marginRight: 8,
    marginTop: 0,
    padding: 0,
  },
  text: {
    ...typography.buttonSmall,
    color: colorsNew.redesign.text.secondary,
    paddingRight: paddingNew.xxs,
  },
  smallMT: {
    marginTop: paddingNew.base,
  },
  mediumMT: {
    marginTop: paddingNew.md,
  },
  logoutWrapper: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    paddingTop: paddingNew.xs,
    paddingBottom: isWeb ? 10 : 70,
  },
  profileContainer: {
    paddingBottom: paddingNew.sm,
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
  },
  profileTextContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  profileButton: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  profileImageLoader: {
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    marginTop: -20,
    marginLeft: -10,
    top: '50%',
    width: '100%',
    zIndex: 1,
  },
  profileImageContainer: {
    position: 'relative',
  },
  profileImagePlaceholder: {
    marginRight: paddingNew.sm,
  },
  profileImage: {
    alignSelf: 'center',
    backgroundColor: colorsNew.white,
    borderRadius: 100,
    height: 72,
    marginRight: 16,
    resizeMode: 'cover',
    width: 72,
  },
  menuItemLoadingContainer: {
    marginRight: 8,
    position: 'relative',
  },
  menuItemLoading: {
    top: -2,
  },
  menuItemWithSubText: {
    height: 60,

    ...(isWeb && {
      minHeight: 60,
    }),
  },
})
