import React, { useCallback, useContext } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { Text } from 'react-native'
import { useSelector } from 'react-redux'

import { isGBSelector } from 'src/store/app/selectors'
import { jobOfferItemData } from '../utils'
import { JobsNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { useAppState } from 'src/utils/AppStateEvent'
import { useBugsnagView } from 'src/utils/bugsnag'
import JobsSectionList, { FakeEmptyItemType, RenderExpressListItemInfo } from 'src/components/List/JobsSectionList'
import ShortTermOfferItem from '../../Rows/ShortTermOfferItem/ShortTermOfferItem'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useExpressOffersList from './useExpressOffersList'
import InfoTileHeader from 'src/components/InfoTileHeader'
import { AttachMoneyIcon, CheckIcon, LocationOnIcon } from 'src/icons'
import ExpressInfoModal from 'src/scenes/Main/Jobs/Lists/ExpressList/ExpressInfoModal'
import { paddingNew } from 'src/styles'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'
import LoadingIndicator from 'src/components/LoadingIndicator'

export const ExpressList = () => {
  const isGB = useSelector(isGBSelector)
  const navigation = useNavigation<JobsNavigatorScreensProps<'Requests'>['navigation']>()
  const { mixpanel } = useContext(MixpanelContext)
  const { isLoading, refreshFunction, shortTermOffers } = useExpressOffersList()

  useBugsnagView('Express List')
  //@ts-ignore

  useAppState({
    onForeground: refreshFunction,
  })

  useFocusEffect(
    useCallback(() => {
      refreshFunction()
      mixpanel?.track('Find Jobs', { action: 'open' })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const renderListHeader = useCallback(() => {
    const tileProps = {
      tiles: [
        {
          title: translate(translations.expressFactText1),
          subHeader: translate(translations.expressFactSubtext1),
          Icon: AttachMoneyIcon,
        },
        {
          title: translate(translations.expressFactText2),
          subHeader: translate(translations.expressFactSubtext2),
          Icon: CheckIcon,
        },
        {
          title: translate(translations.expressFactText3),
          subHeader: translate(translations.expressFactSubtext3),
          Icon: LocationOnIcon,
        },
      ],
      title: translate(translations.expressFactTitle),
    }

    return (
      <>
        <InfoTileHeader {...tileProps} />
        <ExpressInfoModal />
        <Text style={styles.header}>{translate(translations.expressJobTitle)}</Text>
      </>
    )
  }, [])

  const renderJobListItem = ({ item }: RenderExpressListItemInfo) => {
    if (item.id === 'empty') {
      return <Text style={styles.emptyMessage}>{(item as FakeEmptyItemType).text}</Text>
    }

    const navigateToDetails = () => navigation.navigate('ExpressSub', { item })
    return <ShortTermOfferItem {...jobOfferItemData(item, isGB)} onPress={navigateToDetails} />
  }

  const noExpressOffer = () => {
    if (isLoading) {
      return <LoadingIndicator fullScreen={false} style={styles.loader} size="large" />
    }

    return (
      <EmptyPlaceholder
        description={translate(translations.emptyExpressDescription)}
        emptyImageStyles={{ marginBottom: paddingNew.xs }}
        title={translate(translations.emptyExpressTitle)}
        type={placeholderTypes.loupe}
      />
    )
  }

  return (
    <JobsSectionList
      ListEmptyComponent={noExpressOffer()}
      ListHeader={renderListHeader()}
      refreshFunction={refreshFunction}
      renderJobListItem={renderJobListItem}
      sections={shortTermOffers}
      withFirstSectionSpacing={false}
    />
  )
}

export default ExpressList
