import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: paddingNew.md,
    marginBottom: paddingNew.md * 2,
  },
  logoContainerSmall: {
    marginBottom: paddingNew.lg,
  },
})

export default styles
