import { StyleSheet } from 'react-native'

import { colors } from 'src/styles'

const styles = StyleSheet.create({
  errorText: {
    color: colors.danger,
    height: 14,
    fontSize: 12,
  },
})

export default styles
