import uniq from 'lodash/uniq'

import I18n from './i18n'
import sv from 'src/locales/sv'
import enGB from 'src/locales/en-GB'
import { TranslationT } from './i18nTypes'

export const translate = (key: string | object, options?: { [key: string]: string | number }) => I18n.t(key, options)

const keys = {} as TranslationT

const allKeys = uniq(Object.keys({ ...sv, ...enGB }))

allKeys.forEach(key => {
  // @ts-ignore
  keys[key] = String(key)
})

export default keys
