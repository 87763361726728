import { StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const button = {
  alignItems: 'center',
  borderRadius: 8,
  flexDirection: 'row',
  marginVertical: paddingNew.xs,
} as ViewStyle

const styles = StyleSheet.create({
  title: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  detailsButton: button,
  buttonPressed: {
    ...button,
    backgroundColor: colorsNew.redesign.action.pressed,
  },
  rowContainer: {
    marginLeft: paddingNew.xs,
    flex: 1,
  },
  iconContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.primary.shades30p,
    borderRadius: 6,
    height: 40,
    justifyContent: 'center',
    width: 40,
  },
  subtitle: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
