import React from 'react'
import { View } from 'react-native'

import { ErrorType, errorTypes } from './types'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  hideError: () => void
  type: ErrorType
}

const errors = {
  [errorTypes.confirmation]: {
    description: translate(translations.confirmationError),
    testId: 'confirmation-error-alert',
    title: translate(translations.confirmationErrorTitle),
  },
  [errorTypes.delete]: {
    description: translate(translations.formDeleteError),
    testId: 'form-delete-error-alert',
    title: translate(translations.formDeleteErrorTitle),
  },
  [errorTypes.file]: {
    description: translate(translations.fileError),
    testId: 'file-error-alert',
    title: translate(translations.fileErrorTitle),
  },
  [errorTypes.legislation]: {
    description: translate(translations.legislationError),
    testId: 'legislation-error-alert',
    title: translate(translations.legislationErrorTitle),
  },
  [errorTypes.save]: {
    description: translate(translations.formSaveError),
    testId: 'form-save-error-alert',
    title: translate(translations.formSaveErrorTitle),
  },
  [errorTypes.training]: {
    description: translate(translations.trainingError),
    testId: 'training-error-alert',
    title: translate(translations.trainingErrorTitle),
  },
  [errorTypes.yoti]: {
    description: translate(translations.digitalRightToWorkUrlError),
    testId: 'yoti-error-alert',
    title: translate(translations.digitalRightToWorkUrlErrorTitle),
  },
}

const ErrorAlert = ({ hideError, type }: Props) => (
  <View style={styles.alertWrapper}>
    <AlertCard
      colorSchema={alertColorScheme.error}
      description={errors[type].description}
      onClose={hideError}
      testId={errors[type].testId}
      title={errors[type].title}
      variant={alertVariants.standard}
    />
  </View>
)

export default ErrorAlert
