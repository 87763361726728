import React, { useContext, useRef, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Text, ScrollView, View } from 'react-native'
import isEmpty from 'lodash/isEmpty'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import TextInputNew from 'src/components/Form/TextInputNew/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import validationSchema from './validationSchema'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'PersonalProfile'>
type FormikValues = {
  profileDescription: string
}

const PersonalProfile = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - PersonalProfile')

  const [isError, setIsError] = useState(false)

  const profileDescription = useAppSelector(store => store.user.attributes?.profileDescription)
  const userId = useAppSelector(store => store.user.id) as string

  const { mixpanel } = useContext(MixpanelContext)

  const dispatch = useAppDispatch()
  const formikRef = useRef<RefType>()

  const closeScreen = useBackModal(navigation, formikRef)
  const handleError = () => setIsError(true)

  const updateTaskStatus = () => markTaskAsCompleted(userId, task, false, mixpanel, closeScreen, handleError)

  const submitForm = (values: FormikValues, form: FormikHelpers<FormikValues>) => {
    bugsnagActionBreadcrumb('submit', { id: userId, user: values })
    form.setSubmitting(true)
    setIsError(false)
    dispatch(
      saveUser({
        id: userId,
        onError: () => {
          form.setSubmitting(false)
          handleError()
        },
        onSuccess: updateTaskStatus,
        user: values,
      }),
    )
  }

  return (
    <ScrollView
      contentContainerStyle={globalStyles.containerSize}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <View style={styles.container}>
        <StatusInfo status={status} timeToComplete={timeToComplete} />
        <Text style={styles.mainInfo}>{translate(translations.personalProfileInfo)}</Text>
        <Formik
          initialValues={{ profileDescription: profileDescription || '' }}
          onSubmit={submitForm}
          validationSchema={validationSchema()}
          innerRef={formikRef}
        >
          {({
            dirty,
            errors,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
          }) => (
            <>
              <TextInputNew
                disabled={isTaskCompleted}
                errorMessage={errors.profileDescription}
                isError={!isEmpty(errors.profileDescription) && touched.profileDescription}
                label={translate(translations.profileDescription)}
                multiline
                onBlur={() => setFieldTouched(`profileDescription`, true)}
                onChangeText={text => setFieldValue(`profileDescription`, text)}
                required
                size={TextInputSizeVariants.vBig}
                testID="profileDescription-input"
                value={values.profileDescription}
              />
              <Text style={styles.subInfo}>{translate(translations.personalProfileInfo2)}</Text>
              {isError ? (
                <View style={styles.alertWrapper}>
                  <CompleteTaskError hideError={() => setIsError(false)} />
                </View>
              ) : null}
              <View style={styles.buttonContainer}>
                <ButtonNew
                  disabled={!isValid || !dirty || isSubmitting}
                  isSubmitting={isSubmitting}
                  onPress={handleSubmit}
                  testID="submit-personal-profile-details"
                  title={translate(translations.saveChanges)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </>
          )}
        </Formik>
      </View>
    </ScrollView>
  )
}

export default PersonalProfile
