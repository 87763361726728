import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BookingsListTypes } from 'src/graphql/Booking/types'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { filterOutCanceledAndAppointedBookings, filterOutCanceledAndAppointedOrders } from './utils'
import { OrdersListTypes } from 'src/graphql/Order/types'
import { sortingOfferCriteria } from '../utils'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AllProposals, { ProposalsRequestTypes } from 'src/graphql/Proposal'
import BookingsList from 'src/graphql/Booking/BookingsList'
import OrderList from 'src/graphql/Order/OrderList'
import translations, { translate } from 'src/utils/translations/translations'
import { getToken } from 'src/utils/apolloStorage'

const useApplicationData = () => {
  const isConnected = useAppSelector(state => state.app?.isConnected)
  const authToken = getToken()
  const regionId = useAppSelector(state => state.user.attributes?.regionId)

  const refreshFunction = () => {
    bugsnagActionBreadcrumb('refresh', { isConnected })

    if (isConnected) {
      return Promise.all([bookings.refetch(), orders.refetch()])
    }
    return Promise.resolve()
  }

  const bookings = useQuery<BookingsListTypes>(BookingsList, {
    fetchPolicy: 'no-cache',
    skip: !authToken,
    variables: {
      region: regionId,
      appliedByMe: true,
    },
  })

  const orders = useQuery<OrdersListTypes>(OrderList, {
    fetchPolicy: 'no-cache',
    skip: !authToken,
    variables: {
      region: regionId,
      appliedByMe: true,
    },
  })

  const proposals = useQuery<ProposalsRequestTypes>(AllProposals, {
    fetchPolicy: 'cache-and-network',
    skip: !authToken,
    variables: {
      region: regionId,
      appliedByMe: true,
    },
  })

  const isLoading = some([orders.loading, bookings.loading, proposals.loading])

  const bookingsList = useMemo(
    () => filterOutCanceledAndAppointedBookings(bookings.data?.bookings.nodes || []),
    [bookings.data],
  )
  const ordersList = useMemo(() => filterOutCanceledAndAppointedOrders(orders.data?.orders.nodes || []), [orders.data])
  const proposalsList = useMemo(() => proposals?.data?.proposals?.nodes || [], [proposals.data])

  const applicationsList = useMemo(() => {
    if (isLoading) return []

    const sortedShortTermList = sortBy([...bookingsList, ...ordersList], sortingOfferCriteria)

    const shortTermSection = sortedShortTermList.length
      ? [{ title: translate(translations.shortTermPending), data: sortedShortTermList }]
      : []

    const longTermSection = proposalsList.length
      ? [{ title: translate(translations.longTermPending), data: proposalsList }]
      : []

    return [...shortTermSection, ...longTermSection]
  }, [bookingsList, ordersList, proposalsList, isLoading])

  return { isLoading, applicationsList, refreshFunction }
}

export default useApplicationData
