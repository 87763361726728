import { View, Text, ScrollView, StyleSheet } from 'react-native'
import React, { useContext, useMemo, useState } from 'react'
import omit from 'lodash/omit'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, ErrorType, errorTypes, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { createStudentEducation, deleteStudentEducation, updateStudentEducation } from '../Qualifications/actions'
import { errorActions, errorRemoveActions, successActions, successRemoveActions } from '../../utils'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { UserEducation, getUserEducation } from 'src/store/user/selectors'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import CustomModalWrapper from 'src/components/Modal/CustomModal/CustomModalWrapper'
import FormInfoCard from 'src/components/FormInfoCard/FormInfoCard'
import RadioButton from 'src/components/Form/InputRadioGroup/RadioButton'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import UniversityForm, { UniversityEducationFormValues } from './UniversityForm/UniversityForm'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'UniversityEducation'>

const UniversityEducation = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const taskStatusCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - UniversityEducation')

  const userEducation = useAppSelector(getUserEducation)
  const userId = useAppSelector(state => state.user.id) as string

  const dispatch = useAppDispatch()

  const [formError, setFormError] = useState<ErrorType | null>(null)
  const [isCompleteTaskError, setIsCompleteTaskError] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [editedUniversityValues, setEditedUniversityValues] = useState<UserEducation | Record<string, never> | null>(
    null,
  )
  const [isTaskCompleted, setIsTaskCompleted] = useState(false)
  const [isApplicable, setIsApplicable] = useState<boolean | null>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const universityEducationsList = useMemo(() => {
    const universityEducationList = userEducation.filter(education => education.level === 'university')
    if (universityEducationList.length > 0) {
      setIsApplicable(true)
      taskStatusCompleted && setIsTaskCompleted(true)
      return universityEducationList
    }
    taskStatusCompleted && setIsApplicable(false)
    return []
  }, [userEducation, taskStatusCompleted])

  const openEducationForm = (education?: UserEducation) => {
    setIsFormOpen(true)
    setEditedUniversityValues(education || {})
  }

  const closeFormWithoutSaving = () => {
    setIsFormOpen(false)
    setEditedUniversityValues(null)
  }

  const saveUpdatedEducation = (updatedEducation: UniversityEducationFormValues, editedEducationId?: string) => {
    const isEdit = !!editedEducationId
    const universityName =
      updatedEducation.namePreselect === 'other' ? updatedEducation.name : updatedEducation.namePreselect
    const educationToUpload = { ...omit(updatedEducation, 'namePreselect'), name: universityName }

    const data = isEdit ? educationToUpload : { ...educationToUpload, userId }

    bugsnagActionBreadcrumb('submit university education form', { id: userId, user: data })
    setIsSubmitting(true)
    setFormError(null)

    isEdit
      ? updateStudentEducation(
          editedEducationId,
          data,
          () => successActions(userId, dispatch, setIsSubmitting, closeFormWithoutSaving),
          () => errorActions(errorTypes.save, setFormError, setIsSubmitting),
        )
      : createStudentEducation(
          data,
          () => successActions(userId, dispatch, setIsSubmitting, closeFormWithoutSaving),
          () => errorActions(errorTypes.save, setFormError, setIsSubmitting),
        )
  }

  const removeEducation = (deletedExperienceId?: UserEducation['id']) => {
    if (!deletedExperienceId) return closeFormWithoutSaving()
    setIsDeleting(true)
    setFormError(null)
    deleteStudentEducation(
      deletedExperienceId,
      () => successRemoveActions(userId, dispatch, setIsDeleting, closeFormWithoutSaving),
      () => errorRemoveActions(errorTypes.delete, setFormError, setIsDeleting),
    )
  }

  const completeTask = () => {
    setIsCompleteTaskError(false)
    markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack, () => setIsCompleteTaskError(true))
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.radioLabel}>{translate(translations.educationUniversityLabel)}</Text>
      <View style={styles.radioButtons}>
        <RadioButton
          disabled={taskStatusCompleted}
          isLarge
          label={translate(translations.yes)}
          onPress={() => setIsApplicable(true)}
          selected={!!isApplicable}
        />
        <RadioButton
          disabled={taskStatusCompleted}
          isLarge
          label={translate(translations.no)}
          onPress={() => setIsApplicable(false)}
          selected={isApplicable === false}
        />
      </View>

      <Text style={StyleSheet.flatten([styles.taskDescription, !isApplicable && styles.textDisabled])}>
        {translate(translations.employmentHistoryTitle)}
      </Text>

      {universityEducationsList.map(education => (
        <FormInfoCard
          key={education.id}
          onPress={() => openEducationForm(education)}
          subtitle={education.name || ''}
          title={education.subject || ''}
        />
      ))}

      <View style={[styles.buttonContainer, styles.withDivider]}>
        <ButtonNew
          disabled={taskStatusCompleted || isApplicable !== true}
          onPress={() => openEducationForm()}
          size={buttonSize.sm}
          title={translate(translations.educationNewButton)}
          variant={buttonVariants.containedDefault}
        />
      </View>

      <Checkbox
        disabled={taskStatusCompleted || isApplicable !== true || !universityEducationsList.length}
        label={translate(translations.taskConfirmationEducation)}
        onPress={() => setIsTaskCompleted(prev => !prev)}
        selected={isTaskCompleted}
      />
      {isCompleteTaskError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsCompleteTaskError(false)} />
        </View>
      ) : null}
      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={taskStatusCompleted || (isApplicable !== false && !isTaskCompleted)}
          onPress={completeTask}
          size={buttonSize.lg}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.infoText}>{translate(translations.educationUniversityInfo)}</Text>
      <CustomModalWrapper
        isVisible={isFormOpen}
        modalPlace="Main/Profile/RequiredTasks/UniversityEducation/UniversityEducation"
      >
        {editedUniversityValues ? (
          <UniversityForm
            closeFormError={() => setFormError(null)}
            closeFormWithoutSaving={closeFormWithoutSaving}
            deleteEducation={removeEducation}
            education={editedUniversityValues}
            formError={formError}
            isDeleting={isDeleting}
            isDisabled={taskStatusCompleted}
            isSubmitting={isSubmitting}
            updateEducationList={saveUpdatedEducation}
          />
        ) : (
          <></>
        )}
      </CustomModalWrapper>
    </ScrollView>
  )
}

export default UniversityEducation
