import React, { useState } from 'react'
import { Formik, yupToFormErrors, FormikProps, FormikValues, FormikHelpers } from 'formik'
import { View, StyleSheet, ScrollView } from 'react-native'

import Buttons from '../components/Buttons'
import LoadingIndicator from 'src/components/LoadingIndicator'
import OvertimeSection from '../components/OvertimeSection'
import { TimeRow, BreakRow } from '../components/FormComponents'
import { reportWorkingTime } from 'src/store/timelog/actions'
import translations, { translate } from 'src/utils/translations/translations'
import formatter from 'src/utils/formatter'
import { validationSchema } from './validation'
import { reportInitialHoursValuesTypes } from 'src/hooks/useTimesheetReport'

import styles from '../styles'

type Props = {
  bookingEndTime: string
  bookingStartTime: string
  bookingId: string
  closeModal: (isDirty: boolean) => void
  initialValues: reportInitialHoursValuesTypes
  isHidden?: boolean
  onReportSuccess: () => void
  onReportError: (error: string) => void
}

const HourlyReport = ({
  bookingEndTime,
  bookingStartTime,
  bookingId,
  closeModal,
  isHidden,
  initialValues,
  onReportSuccess,
  onReportError,
}: Props) => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null)

  const toggleSection = (section: string) =>
    section === expandedSection ? setExpandedSection(null) : setExpandedSection(section)

  const sendWorkingTime = (
    values: reportInitialHoursValuesTypes,
    form: FormikHelpers<reportInitialHoursValuesTypes>,
  ) => {
    form.setSubmitting(true)
    const onError = (error: string) => {
      setExpandedSection(null)
      onReportError(error)
      form.setSubmitting(false)
    }
    reportWorkingTime(bookingId, values, onReportSuccess, onError)
  }

  const checkIfOvertime = (values: reportInitialHoursValuesTypes) =>
    formatter.getDifferenceInMinutes(bookingEndTime, values.departureTime) > 0 ||
    formatter.getDifferenceInMinutes(bookingStartTime, values.arrivalTime) < 0

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={sendWorkingTime}
      validate={async values => {
        try {
          await validationSchema.validate(values, {
            context: { isOvertime: checkIfOvertime(values) },
            abortEarly: false,
          })
        } catch (err) {
          return yupToFormErrors(err)
        }
      }}
    >
      {form => {
        const { values, setFieldValue, touched, errors, dirty, isSubmitting, handleSubmit } = form
        const isOvertime = checkIfOvertime(values)

        return (
          <>
            <ScrollView
              contentContainerStyle={StyleSheet.flatten([isHidden && styles.hidden])}
              showsVerticalScrollIndicator={false}
            >
              <View testID="time-section">
                <TimeRow
                  error={touched.arrivalTime ? errors.arrivalTime : ''}
                  isExpanded={expandedSection === 'startTime'}
                  label={translate(translations.reportTimeStart)}
                  setTime={time => setFieldValue('arrivalTime', time)}
                  time={values.arrivalTime}
                  toggleSection={() => toggleSection('startTime')}
                />
                <TimeRow
                  error={touched.departureTime ? errors.departureTime : ''}
                  isExpanded={expandedSection === 'endTime'}
                  label={translate(translations.reportTimeEnd)}
                  setTime={time => setFieldValue('departureTime', time)}
                  time={values.departureTime}
                  toggleSection={() => toggleSection('endTime')}
                />
              </View>

              {isOvertime ? (
                <OvertimeSection
                  form={form as FormikProps<FormikValues>}
                  expandedSection={expandedSection}
                  toggleSection={toggleSection}
                  initialValues={initialValues}
                />
              ) : null}

              <View testID="break-section">
                <BreakRow
                  isExpanded={expandedSection === 'breakTime'}
                  label={translate(translations.reportTimeBreak)}
                  setTime={time => setFieldValue('reportedUnpaidTime', time)}
                  time={values.reportedUnpaidTime || 0}
                  toggleSection={() => toggleSection('breakTime')}
                />
              </View>
              {isSubmitting && <LoadingIndicator withMask />}
            </ScrollView>

            {!isHidden ? (
              <Buttons
                closeModal={() => {
                  setExpandedSection(null)
                  closeModal(dirty)
                }}
                submitForm={handleSubmit}
                disabled={isSubmitting}
              />
            ) : null}
          </>
        )
      }}
    </Formik>
  )
}

export default HourlyReport
