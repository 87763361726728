import React, { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { jobOfferItemData } from '../utils'
import { MenuParamList, RouteNavigatorStackComponent } from 'src/utils/types/navigationTypes'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import JobsSectionList, { RenderListItemInfo } from 'src/components/List/JobsSectionList'
import listStyles from 'src/components/List/styles'
import LoadingIndicator from 'src/components/LoadingIndicator'
import ShortTermOfferItem from 'src/scenes/Main/Jobs/Rows/ShortTermOfferItem'
import translations, { translate } from 'src/utils/translations/translations'
import useJobsSections from 'src/hooks/useJobsSections'
import { isGBSelector } from 'src/store/app/selectors'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'

export const AppointedBookings: RouteNavigatorStackComponent<MenuParamList, 'AppointedBookings'> = ({ navigation }) => {
  const isGB = useAppSelector(isGBSelector)
  const { sections, loading, error, refetch } = useJobsSections()

  useBugsnagView('Confirmed Jobs')

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  if (error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  if (loading && sections?.length === 0) {
    return <LoadingIndicator />
  }

  const renderJobListItem = ({ item }: RenderListItemInfo) => {
    if (item.__typename === 'Proposal') return undefined as never

    const navigateToDetails = () => {
      if (item.__typename === 'ExpressBooking') {
        return navigation.navigate('ExpressSubMenu', { item })
      }
      return navigation.navigate(`${item.__typename}Menu`, { id: item.id })
    }
    return <ShortTermOfferItem {...jobOfferItemData(item, isGB)} onPress={navigateToDetails} />
  }

  const emptyView = () => (
    <EmptyPlaceholder
      containerStyles={listStyles.emptyContainer}
      description={translate(translations.noUpcomingBookingsDescription)}
      title={translate(translations.noUpcomingBookings)}
      type={placeholderTypes.calendar}
      withButton
    />
  )

  return (
    <JobsSectionList
      ListEmptyComponent={emptyView()}
      refreshFunction={refetch}
      renderJobListItem={renderJobListItem}
      sections={sections}
    />
  )
}

export default AppointedBookings
