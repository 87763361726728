import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import compact from 'lodash/compact'

import { BookingsListTypes, BookingListItemType } from 'src/graphql/Booking/types'
import { colorsNew } from 'src/styles'
import { ExpressBookingsTypes, ExpressBookingRequestTypes } from 'src/graphql/ExpressBookings'
import { getToken } from 'src/utils/apolloStorage'
import { isGBSelector } from 'src/store/app/selectors'
import { jobOfferItemData } from 'src/scenes/Main/Jobs/Lists/utils'
import { ScheduleNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { useAppSelector } from 'src/hooks/reduxHooks'
import Bookings from 'src/graphql/Booking'
import ExpressAvailabilityQuery from 'src/graphql/ExpressBookings/Availability'
import ShortTermOfferItem from 'src/scenes/Main/Jobs/Rows/ShortTermOfferItem'
import styles from './styles'

type Props = {
  bookings: string[]
  isExpress: boolean
}

const BookingsList = ({ bookings, isExpress }: Props) => {
  const authToken = getToken()
  const isGB = useAppSelector(isGBSelector)
  const navigation = useNavigation<ScheduleNavigatorScreensProps<'Schedule'>['navigation']>()

  const { loading: bookingsLoading, data: bookingsData } = useQuery<BookingsListTypes>(Bookings, {
    fetchPolicy: 'cache-and-network',
    skip: !authToken || isExpress,
    variables: {
      ids: bookings,
    },
  })

  const { loading: expressBookingsLoading, data: expressBookingsData } = useQuery<ExpressBookingRequestTypes>(
    ExpressAvailabilityQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !authToken || !isExpress,
      variables: {
        id: bookings[0],
      },
    },
  )

  const navigateToDetails = (item: BookingListItemType | ExpressBookingsTypes) => {
    if (item.__typename === 'ExpressBooking') {
      navigation.navigate('ExpressSchedule', { item })
    } else {
      navigation.navigate(`${item.__typename}Schedule`, { id: item.id })
    }
  }

  if (bookingsLoading || expressBookingsLoading) {
    return <ActivityIndicator style={styles.loader} color={colorsNew.redesign.primary.shades12p} size="large" />
  }

  const bookingsList = compact([...(bookingsData?.bookings?.nodes || []), expressBookingsData?.expressBooking])

  return (
    <View style={styles.section}>
      {bookingsList.map(item => (
        <ShortTermOfferItem {...jobOfferItemData(item, isGB)} onPress={() => navigateToDetails(item)} key={item.id} />
      ))}
    </View>
  )
}

export default BookingsList
