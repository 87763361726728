import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import Booking from '../Jobs/Details/ShortTermOfferDetails/Booking'
import ExpressSub from '../Jobs/Details/ShortTermOfferDetails/Express'
import mainHeaderConfig from 'src/utils/headerConfig/mainHeaderConfig'
import Schedule from './Schedule'
import stackNavigatorConfig from 'src/utils/headerConfig/stackNavigatorConfig'
import translations, { translate } from 'src/utils/translations/translations'

const Stack = createStackNavigator()

export const ScheduleNavigator = () => (
  <Stack.Navigator {...stackNavigatorConfig()}>
    <Stack.Screen
      name="Schedule"
      options={{
        headerShown: false,
        title: translate(translations.schedule),
      }}
      component={Schedule}
    />
    <Stack.Screen
      component={Booking}
      name="BookingSchedule"
      options={params => mainHeaderConfig(translations.assignment, params)}
    />
    <Stack.Screen
      component={ExpressSub}
      name="ExpressSchedule"
      options={params => mainHeaderConfig(translations.express, params)}
    />
  </Stack.Navigator>
)

export default Schedule
