import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import FileUploader from 'src/components/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'ProofOfPoliceCheck'>

const DOCUMENT_TYPE = 'background_check_overseas_police_check'

const ProofOfPoliceCheck = ({ navigation, route }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - ProofOfPoliceCheck')

  const proofOfPoliceCheckDocument = useAppSelector(getUserDocumentUK(DOCUMENT_TYPE))
  const userId = useAppSelector(state => state.user.id) as string
  const dbsLivedOutsideUk = useAppSelector(state => state.user?.attributes?.dbsLivedOutsideUk)

  const [hasLivedOutsideUk, setHasLivedOutsideUk] = useState(!dbsLivedOutsideUk)
  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const dispatch = useAppDispatch()

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) documentRef.current.dirty = isFilePicked
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const updateTaskStatus = () =>
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)

  const completeTask = () => {
    bugsnagActionBreadcrumb('Complete proof of police check task', { id: userId })
    setIsSubmitting(true)
    setIsError(false)

    dispatch(
      saveUser({
        id: userId,
        user: { dbsLivedOutsideUk: !hasLivedOutsideUk },
        onSuccess: isFilePicked ? () => setShouldSaveDocument(true) : updateTaskStatus,
        onError: handleError,
      }),
    )
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.header}>{translate(translations.policeCheckLivedOutsideUk)}</Text>
      <Text style={styles.info}>{translate(translations.policeCheckDocumentNeeded)}</Text>
      <Checkbox
        disabled={isTaskCompleted || isFilePicked}
        label={translate(translations.policeCheckCheckbox)}
        onPress={() => setHasLivedOutsideUk(prev => !prev)}
        selected={hasLivedOutsideUk}
      />
      <View style={styles.sectionContainer}>
        <FileUploader
          arrayDocuments={proofOfPoliceCheckDocument}
          buttonDisabled={isTaskCompleted || hasLivedOutsideUk}
          deletingFilesDisabled={isTaskCompleted}
          disabled={hasLivedOutsideUk}
          description={translate(translations.policeCheckUploadFile)}
          documentType={DOCUMENT_TYPE}
          key={DOCUMENT_TYPE}
          multiple
          onFileSaveCallback={updateTaskStatus}
          onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
          onFilesErrorCallback={handleError}
          saveOnUpload={shouldSaveDocument}
        />
        <View style={styles.divider} />
      </View>
      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonWrapper}>
          <ButtonNew
            containerStyle={styles.button}
            disabled={(!isFilePicked && !hasLivedOutsideUk && !proofOfPoliceCheckDocument.length) || isTaskCompleted}
            isLong
            isSubmitting={isSubmitting}
            onPress={completeTask}
            title={translate(translations.closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
        <Text style={styles.subInfo}>{translate(translations.taskInfoDeclaraction)}</Text>
      </View>
    </ScrollView>
  )
}

export default ProofOfPoliceCheck
