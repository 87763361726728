import PropTypes from 'prop-types'
import React, { createContext, ReactNode, useEffect, useMemo } from 'react'

import { createOfflineClient, offlineLink } from 'src/utils/apolloOfflineMutation'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { countryCodeSelector } from 'src/store/app/selectors'
import { getApiUrl } from 'src/utils/apiConfig'

type Props = {
  children: ReactNode
}

const NotificationContext = createContext<any>({})
const PassAccessNotificationProvider = {}

const NotificationProvider = ({ children }: Props) => {
  const countryCode = useAppSelector(countryCodeSelector)
  const isConnected = useAppSelector(state => state?.app?.isConnected)

  const offlineClient = useMemo(() => {
    const url = getApiUrl(countryCode)
    const client = createOfflineClient(url)
    // @ts-ignore
    PassAccessNotificationProvider.offlineClient = client
    return client
  }, [countryCode])

  useEffect(() => {
    if (isConnected) {
      offlineLink?.sync()
    }
  }, [isConnected])

  return <NotificationContext.Provider value={{ offlineClient }}>{children}</NotificationContext.Provider>
}

NotificationProvider.propTypes = {
  children: PropTypes.node,
}

export { NotificationProvider, NotificationContext, PassAccessNotificationProvider }
