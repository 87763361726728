import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

export default StyleSheet.create({
  categories: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: paddingNew.xs,
  },
})
