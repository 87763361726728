import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertContainer: {
    marginVertical: paddingNew.sm,
  },
  buttonWrapper: {
    alignItems: 'center',
  },
  buttonSection: {
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
  },
  container: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    paddingBottom: paddingNew.md,
  },
  error: {
    ...typography.helperText,
    color: colorsNew.redesign.error.main,
  },
  extraTopSpace: {
    marginTop: paddingNew.sm,
  },
  extraBottomSpace: {
    marginBottom: paddingNew.sm,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
    marginTop: paddingNew.sm,
  },
  subTitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  mainText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  label: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  radioButtons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: paddingNew.sm,
  },
  subInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles
