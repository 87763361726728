import { StyleSheet } from 'react-native'
import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    marginBottom: paddingNew.md,
    paddingTop: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  infoText: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    paddingBottom: paddingNew.sm,
  },
})

export default styles
