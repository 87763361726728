const DeviceInfoAdapter = {
  getManufacturer: () => '',
  getModel: () => '',
  getSystemName: () => '',
  getSystemVersion: () => '',
  getVersion: () => '',
  hasNotch: () => false,
  isEmulator: () => false,
}

export default DeviceInfoAdapter
