import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const HealingIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M17.73 12.0196L21.71 8.03957C22.1 7.64957 22.1 7.01957 21.71 6.62957L17.37 2.28957C16.98 1.89957 16.35 1.89957 15.96 2.28957L11.98 6.26957L8.00002 2.28957C7.80002 2.09957 7.55002 1.99957 7.29002 1.99957C7.04002 1.99957 6.78002 2.09957 6.59002 2.28957L2.25002 6.62957C1.86002 7.01957 1.86002 7.64957 2.25002 8.03957L6.23002 12.0196L2.25002 15.9996C1.86002 16.3896 1.86002 17.0196 2.25002 17.4096L6.59002 21.7496C6.98002 22.1396 7.61002 22.1396 8.00002 21.7496L11.98 17.7696L15.96 21.7496C16.16 21.9496 16.41 22.0396 16.67 22.0396C16.93 22.0396 17.18 21.9396 17.38 21.7496L21.72 17.4096C22.11 17.0196 22.11 16.3896 21.72 15.9996L17.73 12.0196ZM12 8.99957C12.55 8.99957 13 9.44957 13 9.99957C13 10.5496 12.55 10.9996 12 10.9996C11.45 10.9996 11 10.5496 11 9.99957C11 9.44957 11.45 8.99957 12 8.99957ZM7.29002 10.9596L3.66002 7.33957L7.29002 3.70957L10.91 7.32957L7.29002 10.9596ZM10 12.9996C9.45002 12.9996 9.00002 12.5496 9.00002 11.9996C9.00002 11.4496 9.45002 10.9996 10 10.9996C10.55 10.9996 11 11.4496 11 11.9996C11 12.5496 10.55 12.9996 10 12.9996ZM12 14.9996C11.45 14.9996 11 14.5496 11 13.9996C11 13.4496 11.45 12.9996 12 12.9996C12.55 12.9996 13 13.4496 13 13.9996C13 14.5496 12.55 14.9996 12 14.9996ZM14 10.9996C14.55 10.9996 15 11.4496 15 11.9996C15 12.5496 14.55 12.9996 14 12.9996C13.45 12.9996 13 12.5496 13 11.9996C13 11.4496 13.45 10.9996 14 10.9996ZM16.66 20.3396L13.03 16.7196L16.66 13.0896L20.28 16.7096L16.66 20.3396Z" />
  </Svg>
)

export default HealingIcon
