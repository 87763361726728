import React from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'

type Props = {
  closeErrorModal: () => void
  description: string | null
  isVisible: boolean
}

const ErrorModal = ({ isVisible, closeErrorModal, description }: Props) => (
  <CustomModal
    buttons={[
      {
        onPress: closeErrorModal,
        testID: 'close-error-modal-button',
        title: translate(translations.cancel),
        variant: buttonVariants.containedError,
      },
    ]}
    colorSchema={customModalColorSchema.error}
    description={description}
    isVisible={isVisible}
    modalPlace="Main/Jobs/Details/components/ErrorModal/ErrorModal"
    onClose={closeErrorModal}
    title={translate(translations.errorTitle)}
    variant={customModalVariants.filled}
  />
)

export default ErrorModal
