import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'

const validationSchema = Yup.object().shape({
  graduationYear: Yup.string().required(translate(translations.blank)),
  name: Yup.string().required(translate(translations.blank)),
  subject: Yup.string().required(translate(translations.blank)),
})

export default validationSchema
