import React, { useState } from 'react'
import { ANDROID_APP_ID, APPLE_STORE_ID } from 'src/utils/webAdapters/DotEnvAdapter'
import { useSelector } from 'react-redux'
import { View, Text, ScrollView, Platform, Linking } from 'react-native'
import DeviceInfo from 'src/utils/webAdapters/DeviceInfoAdapter'

import { ArticleIcon, EditIcon, EnvelopeIcon, QuestionMarkIcon, StarIcon } from 'src/icons'
import { isSESelector } from 'src/store/app/selectors'
import { MenuNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { useBugsnagView } from 'src/utils/bugsnag'
import OpinionModal from './components/OpinionModal'
import RedirectRow from 'src/components/RedirectRow'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

const Support = ({ navigation }: MenuNavigatorScreensProps<'Support'>) => {
  const isSE = useSelector(isSESelector)
  const isWeb = Platform.OS === 'web'

  const [showOpinionModal, setShowOpinionModal] = useState(false)

  useBugsnagView('Support')

  const openStore = () => {
    const locale = isSE ? 'sv' : 'en'

    return global.isIOS
      ? Linking.openURL(`https://itunes.apple.com/${locale}/app/humly/id${APPLE_STORE_ID}?mt=8`)
      : Linking.openURL(`market://details?id=${ANDROID_APP_ID}&hl=${locale}`)
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={{ ...styles.page, ...globalStyles.containerSize }}
        showsVerticalScrollIndicator={false}
        testID="scrollView"
      >
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>{translate(translations.supportTitle)}</Text>
        </View>
        <RedirectRow
          Icon={EnvelopeIcon}
          onPress={() => navigation.navigate('ContactDetails')}
          title={translate(translations.contactDetails)}
          subtitle={translate(translations.contactDetailsSubtitle)}
        />
        <View style={styles.divider} />
        <Text style={styles.sectionTitle}>{translate(translations.supportCommonQuestions)}</Text>
        <RedirectRow
          Icon={QuestionMarkIcon}
          onPress={() => navigation.navigate('FAQ')}
          title={translate(translations.faq)}
          subtitle={translate(translations.faqSubtitle)}
        />
        <View style={styles.divider} />
        <Text style={styles.sectionTitle}>{translate(translations.feedback)}</Text>
        <RedirectRow
          Icon={EditIcon}
          onPress={() => setShowOpinionModal(true)}
          title={translate(translations.feedbackIdeas)}
          subtitle={translate(translations.feedbackIdeasDescription)}
        />
        <RedirectRow
          Icon={StarIcon}
          onPress={openStore}
          title={translate(translations.rateUs)}
          subtitle={translate(translations.rateUsStore)}
        />

        <View style={styles.divider} />
        <Text style={styles.sectionTitle}>{translate(translations.legalNotices)}</Text>
        <RedirectRow
          Icon={ArticleIcon}
          onPress={() => navigation.navigate('LegalNotices')}
          title={translate(translations.creditsAcknowledgements)}
          subtitle={translate(translations.creditsAcknowledgementsDescription)}
        />

        {!isWeb ? (
          <View style={styles.footer}>
            <Text style={styles.footerTitle}>{translate(translations.version)}</Text>
            <Text style={styles.footerSubtitle}>{DeviceInfo.getVersion()}</Text>
          </View>
        ) : null}
      </ScrollView>
      <OpinionModal showModal={showOpinionModal} closeModal={() => setShowOpinionModal(false)} />
    </>
  )
}

export default Support
