import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useNavigation } from '@react-navigation/native'

import { jobOfferItemData } from 'src/scenes/Main/Jobs/Lists/utils'
import ShortTermOfferItem from 'src/scenes/Main/Jobs/Rows/ShortTermOfferItem'
import { countryCodeSelector, isGBSelector } from 'src/store/app/selectors'
import { expressStartTime } from 'src/global/constants'
import { AppScreenProps } from 'src/utils/types/navigationTypes'
import { ExpressBookingsTypes } from 'src/graphql/ExpressBookings'

type Props = {
  item: ExpressBookingsTypes
}

export const ExpressRow = ({ item }: Props) => {
  const countryCode = useSelector(countryCodeSelector)
  const isGB = useSelector(isGBSelector)
  const navigation = useNavigation<AppScreenProps<'ExpressSummary'>['navigation']>()

  const onPress = () => navigation.navigate('ExpressSummary', { id: item.id, type: item.__typename })

  const bookingTitle = get(item, 'expressBookingPool.title')
  const title = isEmpty(bookingTitle) ? 'Expressvikarie' : bookingTitle

  return (
    <ShortTermOfferItem
      {...jobOfferItemData(item, isGB, true)}
      onPress={onPress}
      workingTime={`${expressStartTime[countryCode]} - ?`}
      title={title}
    />
  )
}

export default ExpressRow
