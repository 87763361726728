import { momentWithTimezone } from 'src/global/utils'
import formatter from 'src/utils/formatter'
import { ExpressBookingsTypes } from 'src/graphql/ExpressBookings'
import { BookingListItemType, BookingTypes } from 'src/graphql/Booking/types'
import { LocaleT } from 'src/utils/translations/i18nTypes'
import { timesheetWorkingTime } from 'src/utils/timesheetWorkingTime'

const isTimeReportingEditable = (date: BookingListItemType['startTime'], countryCode: LocaleT) =>
  countryCode === 'gb'
    ? momentWithTimezone() < momentWithTimezone(date).endOf('week')
    : momentWithTimezone(date).isSame(momentWithTimezone(), 'day')

export const isReported = (item: BookingTypes | ExpressBookingsTypes | BookingListItemType) =>
  item.__typename === 'ExpressBooking' ||
  (!!item.arrivalTime && !!item.departureTime) ||
  !!item.reportedTimeScheduleType ||
  item?.cancelled ||
  (item as BookingTypes)?.rejected ||
  (__DEV__ && !isTimeReportingEditable(item?.startTime, item?.region?.countryCode))

export const timeSheetFields = ({
  item,
  isTimesheet = false,
}: {
  item: BookingTypes | ExpressBookingsTypes | BookingListItemType
  isTimesheet?: boolean
}) => {
  const isExpress = (item as ExpressBookingsTypes).__typename === 'ExpressBooking'

  const extraFields = { isReported: isReported(item), isTimesheet, workingTime: '' }

  if (!isExpress) {
    extraFields.workingTime = formatter.scheduleTimeText(
      (item as BookingTypes).timeScheduleType,
      `${formatter.time((item as BookingTypes).startTime)} - ${formatter.time((item as BookingTypes).endTime)}` || '',
    )

    if (isTimesheet && extraFields.isReported) {
      extraFields.workingTime = timesheetWorkingTime(item as BookingTypes).bookingTime
    }
  }

  return extraFields
}
