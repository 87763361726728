import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const EditFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.12 2.039c-1.804.181-3.413.768-4.84 1.767-3.542 2.48-5.102 6.953-3.858 11.059a9.762 9.762 0 0 0 2.517 4.196 9.792 9.792 0 0 0 3.333 2.217c3.362 1.332 7.041.802 9.928-1.433.437-.338 1.307-1.208 1.645-1.645C21.255 16.379 22 14.234 22 12c0-4.568-3.158-8.613-7.57-9.697-.984-.241-2.402-.355-3.31-.264m4.227 5.003c.15.056 1.526 1.423 1.597 1.587.137.315.034.505-.64 1.171l-.465.459L14.79 9.21l-1.049-1.049.459-.465c.652-.659.842-.768 1.147-.654M14.19 9.81c.555.555 1.01 1.03 1.01 1.054 0 .025-1.377 1.416-3.061 3.091L9.079 17h-.947c-.917 0-.95-.003-1.039-.087-.092-.086-.093-.095-.093-1.049v-.962l3.049-3.051c1.678-1.678 3.068-3.051 3.09-3.051.022 0 .495.454 1.051 1.01"
    />
  </Svg>
)
export default EditFilledIcon
