import colors from './colors'
import colorsNew from './colorsNew'
import { fonts } from './fonts'
import fontSizes from './fontSizes'
import fontSizesNew from './fontSizesNew'
import gradients from './gradients'
import { padding } from './spacing'
import { padding as paddingNew } from './spacingNew'
import * as variables from './variables'
import common from './common'
import * as bookingStyles from './bookingStyles'
import * as utils from './utils'
import { typography } from './typography'

export {
  bookingStyles,
  colors,
  colorsNew,
  common,
  fonts,
  fontSizes,
  fontSizesNew,
  gradients,
  padding,
  paddingNew,
  typography,
  utils,
  variables,
}
