import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.sm,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    paddingBottom: paddingNew.md,
    paddingHorizontal: paddingNew.sm,
  },
  info: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
  },
  submitSection: {
    marginBottom: paddingNew.lg,
    marginTop: paddingNew.md,
  },
})

export default styles
