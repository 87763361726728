import React from 'react'
import { Text, View } from 'react-native'

import IconButton from 'src/components/Buttons/IconButton/IconButton'
import { DeleteOutlineIcon, DescriptionIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  disabled?: boolean
  fileName: string
  showRemoveDocumentDialog?: () => void
}

const FileCard = ({ disabled, fileName, showRemoveDocumentDialog }: Props) => (
  <View style={styles.fileCard}>
    <>
      <DescriptionIcon fill={colorsNew.redesign.secondary.main} size={24} />
      <Text style={styles.fileName} numberOfLines={1}>
        {fileName}
      </Text>
      {showRemoveDocumentDialog && !disabled && (
        <IconButton Icon={DeleteOutlineIcon} onPress={showRemoveDocumentDialog} size="sm" variant="outlined" />
      )}
    </>
  </View>
)

export default FileCard
