import React from 'react'
import { View, Text, ScrollView } from 'react-native'
import { useSelector } from 'react-redux'
import indexOf from 'lodash/indexOf'

import { DotTextRow } from 'src/components/DotTextRow'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useBugsnagView } from 'src/utils/bugsnag'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import StatusInfo from '../components/StatusInfo/StatusInfo'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import { countryCodeSelector, isSESelector } from 'src/store/app/selectors'
import { LocaleT } from 'src/utils/translations/i18nTypes'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { UserAttributesT } from 'src/store/user/userTypes'
import { userState } from 'src/global/constants'
import globalStyles from 'src/global/globalStyles'

const tips = Array.from({ length: 7 }, (_, i) => i + 1)

type Props = ProfileAndRecruitmentScreensProps<'Interview'>

const isResponseState = ({
  appCountryCode,
  applicantState,
}: {
  appCountryCode: LocaleT
  applicantState: UserAttributesT['state']
}) => {
  const userStates = Object.values(userState[appCountryCode])
  const responseIndex = indexOf(userStates, 'response')
  const currentStateIndex = indexOf(userStates, applicantState)
  return currentStateIndex <= responseIndex
}

const TitleText = ({ title, subtitle }: { title: string; subtitle: string }) => (
  <>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.infoText}>{subtitle}</Text>
  </>
)

const Interview = ({ route }: Props) => {
  const { status, timeToComplete } = route.params

  const appCountryCode = useSelector(countryCodeSelector)
  const isSE = useAppSelector(isSESelector)
  const applicantState = useAppSelector(state => state.user.attributes?.state) as typeof userState.se

  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - Interview')

  const content = () => {
    if (isCompleted) {
      if (isSE && isResponseState({ appCountryCode, applicantState })) {
        return (
          <TitleText
            title={translate(translations.interviewCompletedResponseTitle)}
            subtitle={translate(translations.interviewCompletedResponseInfo)}
          />
        )
      }

      return (
        <TitleText
          title={translate(translations.interviewCompletedTitle)}
          subtitle={translate(translations.interviewCompletedInfo)}
        />
      )
    }

    return (
      <>
        <Text style={styles.infoText}>{translate(translations.interviewInfo)}</Text>
        <Text style={styles.title}>{translate(translations.interviewTipTitle)}</Text>
        {tips.map((tip, index) => (
          <DotTextRow
            containerStyle={styles.textWithDotContainer}
            key={index}
            text={translate(translations[`interviewTip${tip}`])}
            textStyle={styles.textWithDot}
          />
        ))}
        <View style={styles.alert}>
          <AlertCard
            colorSchema={alertColorScheme.info}
            description={translate(translations.interviewAlertInfo)}
            testId="express-alert"
            variant={alertVariants.outlined}
          />
        </View>
      </>
    )
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      {content()}
    </ScrollView>
  )
}

export default Interview
