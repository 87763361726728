import Api from 'src/utils/api'
import { AxiosResponse } from 'axios'

import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import {
  SET_APPLICATION_NOTIFICATIONS,
  SET_APPLICATION_NOTIFICATIONS_READ,
  SET_MESSAGES_REFETCHING,
  SET_NEW_MESSAGE,
  SET_NOTIFICATIONS_COUNT,
  SET_READ_NOTIFICATIONS,
} from './types'

export type MessageStatusResource = {
  id: string
  attributes: {
    read: boolean
  }
}

export const setUnreadNotifications = (count: number) =>
  ({
    type: SET_NOTIFICATIONS_COUNT,
    payload: count,
  } as const)

export const setNotificationsAsRead = (newReadNotifications: string[]) =>
  ({
    type: SET_READ_NOTIFICATIONS,
    payload: newReadNotifications,
  } as const)

export const setApplicationNotifications = (applicationNotifications: string[]) =>
  ({
    type: SET_APPLICATION_NOTIFICATIONS,
    payload: applicationNotifications,
  } as const)

export const setApplicationNotificationsRead = () =>
  ({
    type: SET_APPLICATION_NOTIFICATIONS_READ,
    payload: undefined,
  } as const)

export const sendMessage = (message: string, onSuccess: () => any) => {
  bugsnagActionBreadcrumb('send Chat message')

  return Api.post('/student/chat_messages', {
    data: JSON.stringify({ content: message }),
    onSuccess: () => {
      onSuccess && onSuccess()
    },
  })
}

export const setMessagesRead = async (messages: MessageStatusResource[]) => {
  bugsnagActionBreadcrumb('toggle Chat message status', { messages })

  return await Api.post('/student/bulk_chat_messages', {
    data: JSON.stringify({ messages }),
    onError: (response: AxiosResponse) => bugsnagNotifyWithData('setMessagesRead', response),
  })
}

export const updateNewMessageText = (newMessage: string | null) =>
  ({
    type: SET_NEW_MESSAGE,
    payload: newMessage,
  } as const)

export const setMessagesRefetchStatus = (shouldRefetchMessages: boolean) =>
  ({
    type: SET_MESSAGES_REFETCHING,
    payload: shouldRefetchMessages,
  } as const)

export type ActionTypes =
  | ReturnType<typeof setUnreadNotifications>
  | ReturnType<typeof setNotificationsAsRead>
  | ReturnType<typeof setApplicationNotifications>
  | ReturnType<typeof setApplicationNotificationsRead>
  | ReturnType<typeof updateNewMessageText>
  | ReturnType<typeof setMessagesRefetchStatus>
