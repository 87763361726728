import React, { useCallback, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { bugsnagActionBreadcrumb, bugsnagNotifyWithData, useBugsnagView } from 'src/utils/bugsnag'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { navigateToAppScreen } from 'src/utils/navigateToScreen'

type Props = {
  errorToReport?: ApolloError
  withBackEffect?: boolean
}

const goToSupportHandler = () => navigateToAppScreen(['MainTabNavigator', 'MenuNavigation', 'Support'])

const FetchingDataErrorModal = ({ errorToReport, withBackEffect = true }: Props) => {
  const { goBack } = useNavigation()
  const isConnected = useAppSelector(state => state.app.isConnected)
  const userId = useAppSelector(state => state.user?.id)
  const isErrorWithoutUser = errorToReport && !userId

  const [showModal, setModalShow] = useState(true)

  useBugsnagView('FetchingDataErrorModal')

  const onCancel = () => {
    if (errorToReport) {
      bugsnagNotifyWithData(`FetchingDataErrorModal`, { errorToReport })
    } else {
      bugsnagActionBreadcrumb(
        isConnected ? 'go back on FetchingDataErrorModal' : 'go back on FetchingDataErrorModal when no internet',
      )
    }

    setModalShow(false)
    withBackEffect && goBack()
  }

  useFocusEffect(
    useCallback(() => {
      setModalShow(true)
    }, []),
  )

  const onSubmit = () => {
    if (userId) {
      bugsnagNotifyWithData(`FetchingDataErrorModal`, { errorToReport })
      goToSupportHandler()
    } else {
      onCancel()
    }
  }

  const modalButtons = () => {
    if (!isConnected) {
      return [
        {
          onPress: onCancel,
          title: translate(translations.close),
          variant: buttonVariants.containedError,
        },
      ]
    }

    if (errorToReport) {
      const closeButton = {
        onPress: onCancel,
        title: translate(translations.close),
        variant: buttonVariants.outlinedDefault,
      }

      if (userId) {
        return [
          closeButton,
          {
            onPress: onSubmit,
            title: translate(translations.contactSupport),
            variant: buttonVariants.containedError,
          },
        ]
      }

      return [closeButton]
    }

    return [
      {
        onPress: onCancel,
        title: translate(translations.backModalCancel),
        variant: buttonVariants.containedError,
      },
    ]
  }

  const descriptionText = () => {
    if (isConnected) {
      if (isErrorWithoutUser) {
        return translate(translations.fetchingErrorWithoutUser)
      }
      return translate(translations.fetchingError)
    }
    return translate(translations.connectionLostDescription)
  }

  return (
    <CustomModal
      buttons={modalButtons()}
      colorSchema={customModalColorSchema.error}
      description={descriptionText()}
      isModalScreen
      isVisible={showModal}
      modalPlace="ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal"
      onClose={onCancel}
      title={isConnected ? translate(translations.error) : translate(translations.connectionLost)}
      variant={customModalVariants.filled}
    />
  )
}

export default FetchingDataErrorModal
