import { Platform, StyleSheet } from 'react-native'

import { paddingNew, typography } from 'src/styles'

const isWeb = Platform.OS === 'web'

export default StyleSheet.create({
  container: {
    position: 'relative',
  },
  headerText: {
    ...typography.h6,
  },
  headerTextContainer: {
    alignItems: 'center',
    flex: 1,
    gap: paddingNew.xs,
    justifyContent: 'center',
  },
  headerImage: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  textSectionGroup: {
    gap: paddingNew.md,
    paddingHorizontal: paddingNew.base,
    paddingBottom: paddingNew.base,
  },
  textSection: {
    alignItems: 'center',
    gap: paddingNew.base,
    justifyContent: 'center',
    marginTop: isWeb ? 118 : 84,
    zIndex: 2,
  },
})
