import { View } from 'react-native'
import React from 'react'
import { FormikProps, FormikValues } from 'formik'

import { OvertimeRow } from '../FormComponents'
import translations, { translate } from 'src/utils/translations/translations'
import { reportInitialValuesTypes } from 'src/hooks/useTimesheetReport'

type Props = {
  expandedSection: string | null
  form: FormikProps<FormikValues>
  initialValues: reportInitialValuesTypes
  isShift?: boolean
  toggleSection: (type: string) => void
}

const OvertimeSection = ({ form, expandedSection, initialValues, toggleSection, isShift }: Props) => {
  const renderOvertimeRow = ({ type, label }: { type: 'overtimeReason' | 'overtimeRequestedBy'; label: string }) => (
    <OvertimeRow
      description={form.values[type] || ''}
      error={form.touched[type] ? (form.errors[type] as string) : ''}
      isExpanded={!initialValues[type] || expandedSection === type}
      label={label}
      setDescription={(text: string) => form.setFieldValue(type, text)}
      toggleSection={() => toggleSection(type)}
    />
  )

  return (
    <View testID={'overtime-section'}>
      {renderOvertimeRow({
        label: translate(isShift ? translations.overtimeShiftLabel : translations.reportTimeOvertimeReason),
        type: 'overtimeReason',
      })}
      {renderOvertimeRow({
        label: translate(
          isShift ? translations.overtimeApprovingPersonLabel : translations.reportTimeOvertimeRequestBy,
        ),
        type: 'overtimeRequestedBy',
      })}
    </View>
  )
}

export default OvertimeSection
