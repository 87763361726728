import { View, StyleSheet } from 'react-native'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import styles from './styles'
import { NewModalProps } from './types'
import { bugsnagViewBreadcrumb } from 'src/utils/bugsnag'

const NewModalWrapper = (props: NewModalProps) => {
  const openModal = props.isVisible

  useEffect(() => {
    if (openModal) {
      bugsnagViewBreadcrumb('NewModal')
    }
  }, [openModal])

  return openModal
    ? ReactDOM.createPortal(
        <View style={styles.webContainer}>
          <View style={StyleSheet.flatten([styles.modalWrapper, styles.modalWrapperWeb, props.containerStyle])}>
            {props.children}
          </View>
        </View>,
        document.querySelector('#modal'),
      )
    : null
}

export default NewModalWrapper
