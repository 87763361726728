import React, { useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import ActionsOverlay from 'src/scenes/Main/Jobs/Details/components/ActionsOverlay'
import ApplicationForm from '../ApplicationForm'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import SuccessAnimation from 'src/components/SuccessAnimation'
import IgnoreProposalModal from './IgnoreProposalModal'
import translations, { translate } from 'src/utils/translations/translations'
import useTabBarOverlay from 'src/hooks/useTabBarOverlay'
import { isGBSelector } from 'src/store/app/selectors'
import { ChevronRightIcon } from 'src/icons'
import styles from './styles'

type Props = {
  proposalId: string
  refetchProposal: () => void
}

const ProposalActions = ({ proposalId, refetchProposal }: Props) => {
  const { isCustomOverlay, showBottomTabBar, goBack } = useTabBarOverlay(true)
  const isGB = useSelector(isGBSelector)

  const [showApplicationForm, setShowApplicationForm] = useState(false)
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false)
  const [showIgnoreConfirmationModal, setShowIgnoreConfirmationModal] = useState(false)
  const [applicationContent, setApplicationContent] = useState('')

  const onApplicationSuccess = () => {
    setShowApplicationForm(false)
    setShowSuccessAnimation(true)
  }

  const onAnimationEnd = async () => {
    showBottomTabBar()
    setShowSuccessAnimation(false)
    refetchProposal()
  }

  return (
    <>
      {isCustomOverlay && !showSuccessAnimation ? (
        <ActionsOverlay>
          <ButtonNew
            isLong={isGB}
            onPress={() => setShowIgnoreConfirmationModal(true)}
            testID="ignore-button"
            title={translate(translations.rejectProposal)}
            variant={buttonVariants.outlinedDefault}
          />
          <View style={styles.divider} />
          <ButtonNew
            isLong
            onPress={() => setShowApplicationForm(true)}
            RightIcon={ChevronRightIcon}
            testID="accept-button"
            title={translate(translations.applyForProposal)}
            variant={buttonVariants.containedDefault}
          />
        </ActionsOverlay>
      ) : null}

      <ApplicationForm
        onRequestSuccess={onApplicationSuccess}
        closeModal={() => setShowApplicationForm(false)}
        applicationContent={applicationContent}
        setApplicationContent={setApplicationContent}
        showApplicationForm={showApplicationForm}
        proposalId={proposalId}
      />

      <IgnoreProposalModal
        closeModal={() => setShowIgnoreConfirmationModal(false)}
        showModal={showIgnoreConfirmationModal}
        proposalId={proposalId}
        goBack={goBack}
      />

      <SuccessAnimation
        animationInfo={translate(translations.applicationFormSubmitted)}
        duration={1500}
        onAnimationEnd={onAnimationEnd}
        showAnimation={showSuccessAnimation}
      />
    </>
  )
}

export default ProposalActions
