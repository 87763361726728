import { View, Text } from 'react-native'
import React, { useState, useMemo } from 'react'

import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

export type ApplicationOfferDataType = {
  date: string
  hours: string
  title: string
  time: string
  type: 'apply' | 'accept' | 'express'
}

type Props = ApplicationOfferDataType & {
  closeApplicationModal: () => void
  isVisible: boolean
  onConfirm: () => void
}

const ApplicationModal = ({ isVisible, closeApplicationModal, title, hours, time, date, onConfirm, type }: Props) => {
  const [isAgreementSelected, setAgreementSelected] = useState(false)

  const text = useMemo(() => {
    switch (type) {
      case 'accept':
        return {
          header: translate(translations.jobAcceptTitle),
          checkbox: translate(translations.jobAcceptCheckboxLabel),
          button: translate(translations.accept),
        }
      case 'express':
        return {
          header: translate(translations.jobApplyTitle),
          checkbox: translate(translations.jobAcceptExpressCheckboxLabel),
          button: translate(translations.jobSubmitInterest),
        }
      case 'apply':
      default:
        return {
          header: translate(translations.jobApplyTitle),
          checkbox: translate(translations.jobApplyCheckboxLabel),
          button: translate(translations.jobSubmitInterest),
        }
    }
  }, [type])

  return (
    <NewModalWrapper
      backdropOpacity={0.25}
      containerStyle={styles.modalContent}
      isVisible={isVisible}
      onBackdropPress={closeApplicationModal}
      onSwipeComplete={closeApplicationModal}
      propagateSwipe
      style={styles.modal}
      swipeDirection={['down']}
    >
      <View style={styles.container}>
        <View style={styles.divider} />
        <Text style={styles.title}>{text.header}</Text>
        <View style={styles.jobDetails}>
          <Text style={styles.schoolName}>{title}</Text>
          <Text style={styles.jobDate}>{date}</Text>
          <Text style={styles.jobTime}>
            {time} • {hours}
          </Text>
        </View>
        <Checkbox
          label={text.checkbox}
          onPress={() => setAgreementSelected(prev => !prev)}
          testID="confirmation-checkbox"
          selected={isAgreementSelected}
        />
        <View style={styles.buttons}>
          <ButtonNew
            isLong
            onPress={closeApplicationModal}
            title={translate(translations.cancel)}
            variant={buttonVariants.outlinedDefault}
          />
          <View style={styles.buttonSpace} />
          <ButtonNew
            disabled={!isAgreementSelected}
            isLong
            onPress={onConfirm}
            testID="apply-button"
            title={text.button}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>
    </NewModalWrapper>
  )
}

export default ApplicationModal
