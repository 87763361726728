import React, { useRef, useState, useEffect, useContext } from 'react'
import { View, Text, ScrollView } from 'react-native'

import { ChevronRightIcon } from 'src/icons'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DotTextRow } from 'src/components/DotTextRow'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan, regexes } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import FileUploader from 'src/components/FileUploader/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import TextInputNew from 'src/components/Form/TextInputNew/TextInputNew'
import { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import { saveUser } from 'src/store/user/actions'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'IdVerification'>

const acceptedDocumentsList = Array.from({ length: 6 }, (_, i) => i + 1)
const URL_LINK =
  'https://skatteverket.se/privat/folkbokforing/idkort/villkorforattfaansokaomidkort/godkandaidhandlingar.4.76a43be412206334b89800035836.html'

const IdVerification = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - IdVerification')

  const idDocument = useAppSelector(state => state.user.attributes?.proofOfIdentityUrl)
  const userId = useAppSelector(state => state.user.id) as string
  const nationalInsuranceNumber = useAppSelector(state => state.user.attributes?.nationalInsuranceNumber)
  const dispatch = useAppDispatch()

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)
  const [niNumber, setNiNumber] = useState(nationalInsuranceNumber || '')
  const [niError, setNiError] = useState(false)

  const isDocumentUploaded = idDocument || isFilePicked

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked || (!!niNumber && niNumber !== nationalInsuranceNumber)
    }
  }, [isFilePicked])

  const validateNiNumber = (value: string) =>
    value?.replace(/\s/g, '').match(regexes.socialSecurityNumber) ? setNiError(false) : setNiError(true)

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }

  const updateTaskStatus = () => {
    markTaskAsCompleted(
      userId,
      task,
      isFilePicked,
      mixpanel,
      () => {
        setIsSubmitting(false)
        closeScreen()
      },
      handleError,
    )
  }

  const completeTask = () => {
    setIsError(false)
    const niNormalized = niNumber.replace(/\s/g, '')
    if (!niNormalized.match(regexes.socialSecurityNumber)) {
      return setNiError(true)
    }

    setIsSubmitting(true)

    bugsnagActionBreadcrumb('save ni number SE', { id: userId })

    dispatch(
      saveUser({
        id: userId,
        onError: handleError,
        onSuccess: isFilePicked ? () => setShouldSaveDocument(true) : updateTaskStatus,
        user: { nationalInsuranceNumber: niNormalized },
      }),
    )
  }

  const isDisabled = () => {
    const isFile = isFilePicked ? isFilePicked : isDocumentUploaded || idDocument

    if (isCompleted || !niNumber || !isFile) {
      return true
    }
    return false
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainInfo}>{translate(translations.idVerificationDescription)}</Text>

      <Text style={styles.pointTitle}>{translate(translations.idVerificationTitle1)}</Text>
      <TextInputNew
        disabled={isCompleted}
        errorMessage={translate(translations.idVerificationInputTextError)}
        helperText={translate(translations.idVerificationInputTextHelper)}
        label={translate(translations.idVerificationInputText)}
        isError={niError}
        onChangeText={(value: string) => {
          setNiNumber(value)
          niError && validateNiNumber(value)
        }}
        required
        size={TextInputSizeVariants.medium}
        value={niNumber}
      />

      <Text style={styles.pointTitleSecond}>{translate(translations.idVerificationTitle2)}</Text>

      <Text style={styles.mainInfo}>{translate(translations.idVerificationDescription2)}</Text>
      <FileUploader
        arrayDocuments={idDocument ? [idDocument] : []}
        buttonDisabled={isCompleted}
        deletingFilesDisabled={isCompleted}
        field="proofOfIdentityUrl"
        onFileSaveCallback={updateTaskStatus}
        onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
        onFilesErrorCallback={handleError}
        saveOnUpload={shouldSaveDocument}
      />

      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonContainer}>
          <ButtonNew
            disabled={isDisabled()}
            isSubmitting={isSubmitting}
            onPress={completeTask}
            size={buttonSize.lg}
            title={translate(translations.closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>
      <Text style={styles.subInfo}>{translate(translations.idVerificationTaskInfo)}</Text>
      <View style={styles.infoContainer}>
        <Text style={styles.subtitle}>{translate(translations.idVerificationAcceptedDocuments)}</Text>
        {acceptedDocumentsList.map(doc => (
          <DotTextRow
            containerStyle={styles.textWithDotContainer}
            key={doc}
            text={translate(translations[`idVerificationItem${doc}`])}
            textStyle={styles.textWithDot}
          />
        ))}
        <View style={styles.buttonLinkContainer}>
          <ButtonNew
            onPress={() => openURLIfCan(URL_LINK)}
            RightIcon={ChevronRightIcon}
            size={buttonSize.sm}
            title={translate(translations.idVerificationAcceptedDocumentsButton)}
            variant={buttonVariants.outlinedDefault}
          />
        </View>
      </View>
    </ScrollView>
  )
}

export default IdVerification
