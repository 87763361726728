import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'

import { bugsnagActionBreadcrumb, bugsnagNotifyWithData, useBugsnagView } from 'src/utils/bugsnag'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DownloadIcon } from 'src/icons'
import { getPolicyUrl } from './utils'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew from 'src/components/Buttons/ButtonNew/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'Polices'>

const Polices = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - Polices')

  const userId = useAppSelector(state => state.user.id) as string
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(isTaskCompleted)

  const [isError, setIsError] = useState(false)
  const [isSubmitError, setIsSubmitError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [policyUrl, setPolicyUrl] = useState<string | null>(null)

  const { mixpanel } = useContext(MixpanelContext)

  const onSuccess = useCallback(url => {
    bugsnagActionBreadcrumb('Set policy URL', { url })
    setPolicyUrl(url)
    setIsError(false)
    setIsLoading(false)
  }, [])

  const onError = useCallback(() => {
    bugsnagNotifyWithData('policy url error')
    setIsError(true)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    getPolicyUrl(onSuccess, onError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const completeTask = () => {
    setIsSubmitError(false)
    markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack, () => setIsSubmitError(true))
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.infoText}>{translate(translations.policesInfo)}</Text>
      <View style={styles.buttonContainer}>
        <ButtonNew
          LeftIcon={DownloadIcon}
          onPress={() => openURLIfCan(policyUrl)}
          title={translate(translations.policesDownloadButton)}
          variant={buttonVariants.outlinedDefault}
        />
      </View>
      <Checkbox
        disabled={isTaskCompleted}
        label={translate(translations.policesCheckbox)}
        onPress={() => setIsPolicyAccepted(prev => !prev)}
        selected={isPolicyAccepted}
      />

      {isSubmitError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsError(false)} />
        </View>
      ) : null}

      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={isTaskCompleted || !isPolicyAccepted || isLoading || isError}
          onPress={completeTask}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
    </ScrollView>
  )
}

export default Polices
