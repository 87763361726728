import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const HumlyIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2929 20.8088C14.8151 20.8088 14.3399 20.4511 14.3399 19.9733L14.3392 14.0304C14.3392 11.7257 12.2952 11.2199 11.2094 12.3057C10.5441 12.971 10.4822 13.7397 10.4232 14.4727C10.4142 14.5843 10.4053 14.6951 10.3943 14.8045C10.3304 15.4433 10.0162 15.6988 9.50006 15.6988H7.76841C7.29254 15.6988 6.90674 15.311 6.90674 14.8326L6.92973 3.85443C6.92973 3.376 7.31554 2.98828 7.79141 2.98828H9.50006C9.97593 2.98828 10.3943 3.40411 10.3943 3.88253V10.7172C10.3943 10.7172 11.0273 8.8865 13.5759 8.8865C16.1245 8.8865 17.9162 9.89892 17.9162 13.3948V19.965C17.9162 20.4306 17.5387 20.8088 17.0724 20.8088H15.2929ZM10.6498 19.0517C10.6498 20.1277 9.77756 20.9999 8.70161 20.9999C7.62565 20.9999 6.75342 20.1277 6.75342 19.0517C6.75342 17.9757 7.62565 17.1035 8.70161 17.1035C9.77756 17.1035 10.6498 17.9757 10.6498 19.0517Z"
    />
  </Svg>
)

export default HumlyIcon
