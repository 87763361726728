import { ScrollView, Text, View } from 'react-native'
import React, { useState } from 'react'

import Article from './Article'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import LoadingIndicator from 'src/components/LoadingIndicator'
import translations, { translate } from 'src/utils/translations/translations'

import styles from './styles'
import useGetArticles from 'src/hooks/useGetArticles'
import { useBugsnagView } from 'src/utils/bugsnag'
import globalStyles from 'src/global/globalStyles'

const Faq = () => {
  const { articles, isLoading, isError } = useGetArticles()
  const [expandedArticleId, setExpandedArticleId] = useState<string | null>()

  const toggleSection = (id: string) => setExpandedArticleId(prev => (prev === id ? null : id))

  useBugsnagView('Support - FAQ')

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (isError) {
    return <FetchingDataErrorModal />
  }

  return (
    <ScrollView contentContainerStyle={{ ...styles.page, ...globalStyles.containerSize }} testID="scrollView">
      <Text style={styles.title}>{translate(translations.frequentlyAskedQuestions)}</Text>
      <View style={styles.articlesContainer}>
        {articles.map(article => (
          <Article
            answer={article.attributes.answer}
            isExpanded={article.id === expandedArticleId}
            key={article.id}
            onPress={() => toggleSection(article.id)}
            question={article.attributes.question}
          />
        ))}
      </View>
    </ScrollView>
  )
}

export default Faq
