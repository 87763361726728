import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import CustomModal from 'src/components/Modal/CustomModal'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'

type TProps = {
  migrationEstimatedTime: string
}

const WarningServerMaintenanceScreen = ({ migrationEstimatedTime }: TProps) => {
  const { goBack } = useNavigation()
  const [showModal, setModalShow] = useState(true)

  useBugsnagView('WarningServerMaintenanceScreen')

  const onCancel = () => {
    bugsnagActionBreadcrumb('go back on WarningServerMaintenanceScreen')
    setModalShow(false)
    goBack()
  }

  const modalButtons = () => [
    {
      onPress: onCancel,
      title: translate(translations.backModalCancel),
      variant: buttonVariants.outlinedDefault,
    },
  ]

  return (
    <CustomModal
      buttons={modalButtons()}
      colorSchema={customModalColorSchema.warning}
      description={translate(translations.serverMaintenanceWarningDescription, {
        migrationEstimatedTime: formatter.longDateAndTime(migrationEstimatedTime),
      })}
      isModalScreen
      isVisible={showModal}
      modalPlace="ModalTypes/WarningServerMaintenanceScreen/WarningServerMaintenanceScreen"
      title={translate(translations.serverMaintenanceWarningTitle)}
      variant={customModalVariants.filled}
    />
  )
}

export default WarningServerMaintenanceScreen
