import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertContainer: {
    marginBottom: paddingNew.base,
    marginTop: paddingNew.xs,
  },
  buttons: {
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
  },
  buttonsDivider: {
    marginBottom: paddingNew.xs,
  },
  checkboxContainer: {
    top: -paddingNew.xs,
  },
  container: {
    padding: paddingNew.sm,
  },
  divider: {
    marginTop: paddingNew.md,
  },
  formHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: paddingNew.sm,
  },
  formHeaderInfo: {
    flex: 1,
  },
  formSubtitle: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
  },
  formTitle: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
