import PropTypes from 'prop-types'
import React, { createContext, ReactNode, useState } from 'react'
import { Platform, StatusBar, StyleSheet, StatusBarStyle } from 'react-native'
import { SafeAreaProvider as AreaProvider, SafeAreaView } from 'react-native-safe-area-context'

import { colors, colorsNew } from 'src/styles'

const isAndroid = Platform.OS === 'android'

const themes = {
  light: {
    background: colorsNew.transparent,
    barStyle: 'dark-content',
  },
  warning: {
    background: colors.yellow._100,
    barStyle: 'dark-content',
  },
  dark: isAndroid
    ? {
        background: colorsNew.transparent,
        barStyle: 'light-content',
      }
    : {
        background: colorsNew.white,
        barStyle: 'dark-content',
      },
}

type Keys = keyof typeof themes
type ValuesThemes = typeof themes[Keys]
type SafeAreaContextT = {
  theme: ValuesThemes
  setTheme: (styles: ValuesThemes) => any
}
type Props = {
  children: ReactNode
}

const SafeAreaContext = createContext<SafeAreaContextT>({
  theme: themes.dark,
  setTheme: (styles: ValuesThemes) => null,
})

const SafeAreaProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState<ValuesThemes>(themes.light)

  return (
    <SafeAreaContext.Provider value={{ theme, setTheme }}>
      <AreaProvider>
        <SafeAreaView
          edges={['top', 'left', 'right']}
          style={StyleSheet.flatten([styles.areaViewContainer, { backgroundColor: theme.background }])}
        >
          <StatusBar backgroundColor={colors.primary} barStyle={theme.barStyle as StatusBarStyle} />

          {children}
        </SafeAreaView>
      </AreaProvider>
    </SafeAreaContext.Provider>
  )
}

const styles = StyleSheet.create({
  areaViewContainer: { flex: 1 },
})

SafeAreaProvider.propTypes = {
  children: PropTypes.node,
}

export { SafeAreaProvider, SafeAreaContext, themes }
