import React, { useRef } from 'react'
import { LayoutChangeEvent } from 'react-native'

import ArticleContent, { ArticleProps } from './ArticleContent'

const Article = ({ question, answer, isExpanded, onPress }: ArticleProps) => {
  const expandedSectionHeight = useRef(0)

  const chevronStyle = {
    transform: [{ rotate: isExpanded ? '270deg' : '90deg' }],
  }
  const expandedStyle = isExpanded ? { height: expandedSectionHeight.current } : { height: 0 }
  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => (expandedSectionHeight.current = nativeEvent?.layout?.height)

  return (
    <ArticleContent
      answer={answer}
      chevronStyle={chevronStyle}
      expandedSectionStyle={expandedStyle}
      onLayoutEffect={onLayout}
      onPress={onPress}
      question={question}
    />
  )
}

export default Article
