import { Platform, StyleSheet } from 'react-native'

const isWeb = Platform.OS === 'web'

export default StyleSheet.create({
  containerSize: {
    ...(isWeb && {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 500,
      width: '100%',
    }),
  },
})
