import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.xs,
  },
  container: {
    paddingBottom: paddingNew.md,
    paddingHorizontal: paddingNew.sm,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginBottom: paddingNew.lg,
    paddingTop: paddingNew.sm,
  },
})

export default styles
