import React from 'react'
import { useNavigation } from '@react-navigation/native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import { navigateToAppScreen } from 'src/utils/navigateToScreen'
import { useAppSelector } from 'src/hooks/reduxHooks'

type TProps = {
  closeButtonText?: string
  confirmButtonText?: string
  danger?: boolean
  description: string
  error: string
  onConfirm?: () => any
  title: string
}

const goToSupportHandler = () => navigateToAppScreen(['MainTabNavigator', 'MenuNavigation', 'Support'])

const ErrorModalScreen = ({
  closeButtonText = translate(translations.cancel),
  confirmButtonText = translate(translations.contactSupport),
  onConfirm,
  danger,
  description,
  error,
  title,
}: TProps) => {
  useBugsnagView('ErrorModalScreen', { title })
  const userId = useAppSelector(state => state.user?.id)
  const isErrorWithoutUser = error && !userId

  const navigation = useNavigation()

  const closeModal = () => {
    bugsnagActionBreadcrumb('close modal')
    navigation.goBack()
  }

  const onConfirmCallback = () => {
    bugsnagActionBreadcrumb('close modal using onConfirm')

    onConfirm ? onConfirm() : navigation.goBack()
  }

  const goToSupport = () => {
    bugsnagNotifyWithData('Modal Wrapper error on accept', error)
    goToSupportHandler()
    closeModal()
  }

  const descriptionText = () => {
    if (isErrorWithoutUser) {
      return translate(translations.fetchingErrorWithoutUser)
    }
    return description
  }

  const modalButtons = () => {
    if (error) {
      const closeButton = {
        onPress: closeModal,
        title: translate(translations.close),
        variant: buttonVariants.outlinedDefault,
      }

      if (userId) {
        return [
          closeButton,
          {
            onPress: goToSupport,
            title: confirmButtonText,
            variant: danger ? buttonVariants.containedError : buttonVariants.outlinedError,
          },
        ]
      }
      return [closeButton]
    }

    return [
      {
        onPress: onConfirmCallback,
        title: closeButtonText ? closeButtonText : translate(translations.cancel),
        variant: danger ? buttonVariants.containedError : buttonVariants.outlinedError,
      },
    ]
  }

  return (
    <CustomModal
      buttons={modalButtons()}
      colorSchema={customModalColorSchema.error}
      description={descriptionText()}
      isModalScreen
      isVisible
      modalPlace="ModalTypes/ErrorModalScreen/ErrorModalScreen"
      onClose={closeModal}
      title={title}
      variant={danger ? customModalVariants.filled : customModalVariants.standard}
    />
  )
}

export default ErrorModalScreen
