import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, padding, paddingNew } from 'src/styles'

const { width } = Dimensions.get('window')
const isWeb = Platform.OS === 'web'

export const MODAL_ROW_HEIGHT = 56

const styles = StyleSheet.create({
  container: {
    marginBottom: padding.xs,
  },
  modalContainer: {
    height: '100%',
    width: '100%',
  },
  icon: { marginRight: paddingNew.xs },
  modalListWrapper: {
    ...(!isWeb ? {} : { maxWidth: 452 }),
    alignSelf: 'center',
    backgroundColor: colorsNew.white,
    borderRadius: 4,
    elevation: 5,
    paddingBottom: paddingNew.xxs,
    position: 'absolute',
    shadowColor: colorsNew.black,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    width: '88%',
  },
  modalRow: {
    alignItems: 'center',
    flexDirection: 'row',
    height: MODAL_ROW_HEIGHT,
    paddingHorizontal: 25,
    paddingVertical: paddingNew.sm,
    width: '100%',
  },
  modalRowLabel: {
    alignItems: 'center',
    color: colorsNew.brand.secondaryDark,
    flexDirection: 'row',
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizesNew.xs.size,
    fontWeight: 'normal',
    lineHeight: fontSizesNew.xs.lh,
    marginRight: paddingNew.xs,
    maxWidth: width,
  },
  modalRowSelected: {
    backgroundColor: colorsNew.successLight,
  },
})

export default styles
