import React, { useState } from 'react'
import { TextInput } from 'react-native-paper'

import { VisibilityIcon, VisibilityOffIcon } from 'src/icons'
import TextInputNew, { Props } from 'src/components/Form/TextInputNew'
import { colorsNew } from 'src/styles'
import styles from './styles'

const iconProps = {
  fill: colorsNew.redesign.action.active,
  size: 24,
}

const OnIcon = () => <VisibilityIcon {...iconProps} />
const OffIcon = () => <VisibilityOffIcon {...iconProps} />

const PasswordInputNew = ({ testID = 'password-input-new', value, ...props }: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const toggleVisibility = () => setIsVisible(prevState => !prevState)

  return (
    <TextInputNew
      right={
        value ? (
          <TextInput.Icon
            forceTextInputFocus={false}
            icon={isVisible ? OnIcon : OffIcon}
            onPress={toggleVisibility}
            style={styles.iconWrapper}
          />
        ) : null
      }
      secureTextEntry={!isVisible}
      testID={testID}
      {...props}
    />
  )
}

export default PasswordInputNew
