import React from 'react'
import { View, Text } from 'react-native'

import ShadowWrapper from '../ShadowWrapper'
import { EditIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  onPress: () => void
  subtitle: string
  title: string
}

const FormInfoCard = ({ onPress, title, subtitle }: Props) => (
  <ShadowWrapper borderRadius={8} withBaseShadow withVerticalSpacing onPress={onPress}>
    <View style={styles.cardContainer}>
      <View style={styles.cardInfo}>
        <Text style={styles.cardTitle} numberOfLines={1}>
          {title}
        </Text>
        <Text style={styles.cardSubtitle} numberOfLines={1}>
          {subtitle}
        </Text>
      </View>
      <EditIcon size={24} fill={colorsNew.redesign.action.active} />
    </View>
  </ShadowWrapper>
)

export default FormInfoCard
