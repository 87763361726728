import React from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SvIcon = ({ size, ...props }: IconTypes) => (
  <Svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G clipPath="url(#a)">
      <Path d="M3 3h18v18H3V3Z" fill="#005293" />
      <Path d="M7.71 3v7.2H3v3.6h4.71V21h3.6v-7.2H21v-3.6h-9.69V3h-3.6Z" fill="#FECB00" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Rect x={3} y={3} width={size - 6} height={size - 6} rx={9} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default SvIcon
