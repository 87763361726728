import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { BookingTimesheetTypes } from 'src/graphql/Booking'
import { ApolloError } from '@apollo/client/errors'

import { countryCodeSelector, isGBSelector } from 'src/store/app/selectors'
import TimesheetQuery from 'src/graphql/Booking/Timesheet'
import formatter from 'src/utils/formatter'
import { timesheetWorkingTime } from 'src/utils/timesheetWorkingTime'

export type reportTypesError = { error?: ApolloError; loading: boolean }

export type reportInitialHoursValuesTypes = {
  arrivalTime: string
  departureTime: string
  overtimeReason: string | null
  overtimeRequestedBy: string | null
  reportedUnpaidTime: number
}

export type reportInitialFixedValuesTypes = {
  overtimeReason: string | null
  overtimeRequestedBy: string | null
  reportedTimeScheduleType: string
}

export type reportInitialValuesTypes = reportInitialHoursValuesTypes | reportInitialFixedValuesTypes

export type reportTypes = {
  bookingDate: string
  bookingEndTime: BookingTimesheetTypes['endTime']
  bookingSalary: string
  bookingScheduleType: BookingTimesheetTypes['timeScheduleType']
  bookingStartTime: BookingTimesheetTypes['startTime']
  bookingTime: string
  breakTime: number
  cancelled: BookingTimesheetTypes['cancelled']
  error?: ApolloError
  feedbackSubmitted: BookingTimesheetTypes['feedbackSubmitted']
  isBreakTimeModified: boolean
  isHourly: boolean
  isSummary: boolean
  isTimeModified: boolean
  isTutor: boolean
  loading: boolean
  pupil: BookingTimesheetTypes['pupil']
  refetch: () => void
  reportInitialValues: reportInitialValuesTypes
  school: BookingTimesheetTypes['school']
  teacherRole: string
  timelogEditable: BookingTimesheetTypes['timelogEditable']
}

const useTimesheetReport = (id: string) => {
  const countryCode = useSelector(countryCodeSelector)
  const isGB = useSelector(isGBSelector)

  const { loading, data, error, refetch } = useQuery(TimesheetQuery, {
    variables: {
      id,
    },
  })

  if (error || loading) {
    return { error, loading } as reportTypesError
  }

  const {
    arrivalTime,
    cancelled,
    competences,
    departureTime,
    endTime,
    feedbackSubmitted,
    holidayPayBaseRate,
    jobTitle,
    overtimeReason,
    overtimeRequestedBy,
    pupil,
    reportedTimeScheduleType,
    reportedUnpaidTime,
    salaryRate,
    school,
    startTime,
    timelogEditable,
    timeScheduleType,
    topics,
    unpaidTime,
  } = data.booking as BookingTimesheetTypes

  const bookingDate = formatter.longWeekdayAndMonthWithTimezone(startTime)

  const teacherRole = isGB
    ? jobTitle || competences[0]?.name
    : topics.map((topic, index) => (index + 1 === topics.length ? topic.title : `${topic.title}, `)).join()

  const isTutor = ['Tutor', 'SEN Tutor'].includes(teacherRole)

  const bookingSalary = formatter.getSalarySubtitle({
    countryCode,
    holidayRate: holidayPayBaseRate,
    salary: salaryRate,
    scheduleType: timeScheduleType,
  })

  const { isHourly, bookingTime, originalBookingTime, reportedBookingTime } = timesheetWorkingTime(data.booking)
  const isTimeModified = !!reportedBookingTime && reportedBookingTime !== originalBookingTime

  const isBreakTimeModified = reportedUnpaidTime !== null && unpaidTime !== reportedUnpaidTime
  const breakTime = reportedUnpaidTime !== null ? reportedUnpaidTime : unpaidTime

  const reportInitialValues = isHourly
    ? {
        arrivalTime: arrivalTime || startTime,
        departureTime: departureTime || endTime,
        overtimeReason: overtimeReason,
        overtimeRequestedBy: overtimeRequestedBy,
        reportedUnpaidTime: reportedUnpaidTime || breakTime,
      }
    : {
        overtimeReason: overtimeReason,
        overtimeRequestedBy: overtimeRequestedBy,
        reportedTimeScheduleType: reportedTimeScheduleType || timeScheduleType,
      }

  const checkIfEditable = () => {
    if (timelogEditable) {
      return isHourly ? !!(arrivalTime && departureTime) : !!reportedTimeScheduleType
    }
    return true
  }
  const isSummary = checkIfEditable()

  return {
    bookingDate,
    bookingEndTime: endTime,
    bookingSalary,
    bookingScheduleType: timeScheduleType,
    bookingStartTime: startTime,
    bookingTime,
    breakTime,
    cancelled,
    error,
    feedbackSubmitted,
    isBreakTimeModified,
    isHourly,
    isSummary,
    isTimeModified,
    isTutor,
    loading,
    pupil,
    refetch,
    reportInitialValues,
    school,
    teacherRole,
    timelogEditable,
  } as reportTypes
}

export default useTimesheetReport
