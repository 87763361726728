const colors = {
  black: '#000000',
  blue: {
    _100: '#f5faff',
    _200: '#dcebf9',
    _300: '#9cf2f2',
    _400: 'rgba(0, 42, 94, 0.4)',
    _500: '#007ec7',
    _800: '#004889',
    transparent: 'rgba(123, 126, 225, 0.4)',
  },
  errorLight: '#FFEEF0',
  errorDark: '#D43B4D',
  violet: {
    _100: '#DBDCFF',
    _600: '#97a1ff',
    _700: '#6b70d1',
    _800: '#5e62b7',
  },
  danger: '#B33C86',
  green: {
    _100: '#EEFEF6',
    _200: '#2de2af',
    _400: '#0acc95',
    _700: '#6FADAD',
    _800: '#009971',
    transparent: 'rgba(0, 181, 139, 0.15)',
  },
  primary: '#00B58B',
  pink: {
    _100: '#F3E5FF',
    _200: '#FFD9DE',
    _300: '#f2aab2',
    _400: '#e58997',
    _500: '#e271c2',
    _600: '#cc58b1',
    _800: '#993477',
    _900: '#7F2B63',
    transparent: 'rgba(179,60,134, 0.4)',
  },

  secondary: '#002A5E',
  secondaryLight: '#E6F2FF',
  shadow: '#004889',
  success: { light: '#E6F8F3' },
  shadowBorder: 'hsla(218, 50%, 10%, 0.1)',
  yellow: {
    _100: '#FFF7DE',
    _200: '#fff4c0',
    _800: '#e2d07a',
  },
  transparent: 'transparent',
  translucentBlack: global.isIOS ? 'rgba(0, 0, 0, 0.87);' : 'rgba(0, 0, 0, 0.54);',
  transparentWhite: 'rgba(255, 255, 255, 0.7)',
  warning: {
    _100: '#E29D4C',
    _200: '#FFEEDB',
    _300: '#F08200',
  },
  white: '#FFFFFF',
  grey: {
    _100: '#FBFBFB',
    _200: '#EEEEEF',
    _300: '#DADADC',
    _400: '#BEBFC0',
    _500: '#9C9C9F',
    _700: '#5C5C61',
    transparent: 'rgba(195,195,195, 0.22)',
  },
  successLight: '#E6F8F3',
  brandSecondary: '#224D3D',
  brandSecondaryDark: '#1C1C1C',
}

export default colors
