import { DocumentPickerResponse } from 'react-native-document-picker'

import { NewUploadedFile, WebFile } from './types'
import { UserDocuments } from 'src/store/user/selectors'

export const getFileNameSv = (documentUrl: string) => {
  const slashIndex = documentUrl.lastIndexOf('/') + 1
  const fullFileName = documentUrl.substring(slashIndex, documentUrl.length)
  const dotIndex = fullFileName.lastIndexOf('.')
  const cutIndex = Math.max(dotIndex - 14, 0)
  return fullFileName.substring(0, cutIndex) + fullFileName.substring(dotIndex)
}

export const getFileName = (
  document: DocumentPickerResponse | UserDocuments | NewUploadedFile | WebFile | string,
): string => {
  if (typeof document === 'string') return getFileNameSv(document)
  else if ('name' in document) return document.name || ''
  else if ('url' in document) {
    const fileNameMatch = document.url && document.url.match(/(?!.*\/).*/)
    return fileNameMatch ? fileNameMatch[0] : ''
  }
  return ''
}
