import { StyleSheet } from 'react-native'

import { colorsNew, fontSizesNew, paddingNew } from 'src/styles'
import globalStyles from 'src/global/globalStyles'

export default StyleSheet.create({
  container: {
    backgroundColor: colorsNew.white,
    paddingHorizontal: paddingNew.md,
    paddingVertical: paddingNew.md,
    ...globalStyles.containerSize,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: paddingNew.xs,
  },
  info: {
    color: colorsNew.black,
    fontSize: fontSizesNew.xxxs.size,
    lineHeight: fontSizesNew.xxxs.lh,
  },
  title: {
    color: colorsNew.black,
    fontSize: fontSizesNew.sm.size,
    fontWeight: '700',
    lineHeight: fontSizesNew.sm.lh,
    marginBottom: paddingNew.xs,
  },
  content: {
    color: colorsNew.black,
    fontSize: fontSizesNew.xxs.size,
    lineHeight: fontSizesNew.xxs.lh,
    marginBottom: paddingNew.sm,
  },
})
