import React from 'react'
import TimePicker from 'rc-time-picker'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import formatter from 'src/utils/formatter'
import RowTitle from './RowTitle'
import { isGBSelector } from 'src/store/app/selectors'
import { momentWithTimezone } from 'src/global/utils'

import styles from './styles'

type Props = {
  error?: string
  isExpanded: boolean
  label: string
  setTime: (time: string) => void
  time: string
  toggleSection: () => void
}

const TimeRow = ({ toggleSection, isExpanded, time, setTime, label, error }: Props) => {
  const isGB = useSelector(isGBSelector)

  return (
    <View style={styles.rowContainer}>
      <RowTitle buttonText={formatter.time(time)} error={error} label={label} onToggle={toggleSection} />
      {isExpanded ? (
        <TimePicker
          className="customtimepicker"
          defaultValue={momentWithTimezone(time)}
          minuteStep={5}
          onChange={changedTime => setTime(changedTime.toISOString())}
          showSecond={false}
          use12Hours={isGB}
        />
      ) : null}
    </View>
  )
}

export default TimeRow
