import React from 'react'
import { Formik, Field } from 'formik'
import { useSelector } from 'react-redux'
import { View, Text } from 'react-native'

import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { default as inputStyle } from 'src/scenes/Main/Redesign/components/RatingRow/styles'
import { feedbackValidation } from './feedbackValidation'
import { getSum } from './utils'
import { isGBSelector } from 'src/store/app/selectors'
import { sendFeedback } from 'src/store/booking/actions'
import RatingRow from 'src/scenes/Main/Redesign/components/RatingRow'
import RatingThumb from 'src/scenes/Main/Redesign/components/RatingThumb'
import styles from '../styles'
import translations, { translate } from 'src/utils/translations/translations'
import TextInputNew from 'src/components/Form/TextInputNew'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'

const initialValues = {
  comment: '',
  continueWorking: null,
  introduction: 0,
  lesson: 0,
  structure: 0,
  treatment: 0,
}

type Props = {
  bookingId: string
  navigateToJobSummary: () => void
  onSubmitSuccess: () => void
  schoolType: string
}

export type FormikValuesTypes = {
  comment: string
  continueWorking: null
  introduction: number
  lesson: number
  structure: number
  treatment: number
}

const isFieldDisabled = (values: typeof initialValues, type: keyof typeof initialValues) =>
  values[type] === initialValues[type]

const FeedbackGeneral = ({ navigateToJobSummary, onSubmitSuccess, bookingId, schoolType }: Props) => {
  const isGB = useSelector(isGBSelector)
  const isPreschool = isGB && schoolType === 'preschool'

  const handleSubmit = (values: FormikValuesTypes) => {
    bugsnagActionBreadcrumb('submit feedback general', { values })
    const feedback = {
      ...values,
      booking: bookingId,
      rate: getSum(values) / 4,
    }
    sendFeedback(feedback, onSubmitSuccess)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={feedbackValidation(isPreschool)}>
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        const isTextAreaDisabled =
          isFieldDisabled(values, 'continueWorking') ||
          isFieldDisabled(values, 'introduction') ||
          isFieldDisabled(values, 'lesson') ||
          isFieldDisabled(values, 'structure') ||
          isFieldDisabled(values, 'treatment')

        return (
          <>
            <Text style={styles.description}>{translate(translations.feedbackDescriptionNew)}</Text>
            <Field name="lesson" label={translate(translations.feedbackQuestion1)} component={RatingRow} />
            <Field name="introduction" label={translate(translations.feedbackQuestion2)} component={RatingRow} />
            <Field name="structure" label={translate(translations.feedbackQuestion3)} component={RatingRow} />
            {!isPreschool && (
              <Field name="treatment" label={translate(translations.feedbackQuestion4)} component={RatingRow} />
            )}

            <View style={styles.feedbackThumbs}>
              <Text style={inputStyle.label}>{translate(translations.feedbackSummary)}</Text>
              <View style={styles.feedbackThumbsButtons}>
                <RatingThumb
                  disabled={values.continueWorking !== 'Yes'}
                  onPress={() => setFieldValue('continueWorking', 'Yes')}
                  variant="positive"
                />
                <RatingThumb
                  disabled={values.continueWorking !== 'No'}
                  onPress={() => setFieldValue('continueWorking', 'No')}
                  variant="negative"
                />
              </View>
              {touched.continueWorking && errors.continueWorking ? (
                <Text style={inputStyle.error}>{errors.continueWorking}</Text>
              ) : null}
            </View>
            <TextInputNew
              disabled={isTextAreaDisabled}
              errorMessage={errors.comment}
              helperText={translate(translations.feedbackComment)}
              isError={!!errors.comment}
              label={translate(translations.feedbackTitle)}
              multiline
              onChangeText={text => setFieldValue('comment', text)}
              value={values.comment}
            />

            <View style={styles.buttonContainer}>
              <ButtonNew
                onPress={submitForm}
                size={buttonSize.lg}
                title={translate(translations.submitFeedback)}
                variant={buttonVariants.containedDefault}
              />
            </View>
            <ButtonNew
              onPress={navigateToJobSummary}
              size={buttonSize.lg}
              style={styles.buttonText}
              title={translate(translations.skip)}
              variant={buttonVariants.textDefault}
            />
          </>
        )
      }}
    </Formik>
  )
}

export default FeedbackGeneral
