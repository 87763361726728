import gql from 'graphql-tag'

import BookingFields from './Fields'

import { BookingTypes, BookingTimesheetTypes, BookingsTimesheetRequestTypes, BookingsRequestTypes } from './types'

export type { BookingTypes, BookingTimesheetTypes, BookingsTimesheetRequestTypes, BookingsRequestTypes }

export default gql`
  query Index(
    $bookingType: String
    $limit: Int
    $maxDate: String
    $minDate: String
    $offset: Int
    $region: String
    $state: String
    $sort: String
    $appliedByMe: Boolean
    $ids: [String]
  ) {
    bookings(
      limit: $limit
      offset: $offset
      sort: $sort
      filter: {
        bookingType: $bookingType
        maxDate: $maxDate
        minDate: $minDate
        region: $region
        state: $state
        appliedByMe: $appliedByMe
        ids: $ids
      }
    ) {
      nodes {
        ...BookingFields
      }
    }
  }

  ${BookingFields}
`
