import React, { useState } from 'react'
import { Dimensions, Image, Platform, StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import { convertMilesToKm } from 'src/scenes/Main/Matching/Area/helpers/convertMilesToKm'
import { drawCirclePath } from 'src/scenes/Main/Matching/Area/helpers/drawCircleOnMap'
import { getCountryCode } from 'src/store/app/selectors'
import images from 'src/global/images'
import Slider from 'src/components/Slider'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useStaticMap from 'src/hooks/useStaticMap'
import LoadingIndicator from 'src/components/LoadingIndicator'

type Props = {
  lat: number
  lng: number
  radius: number
  nearbySchoolCount: number
  updateGeoSearchRadius: (
    geoSearchRadius: number,
    setIsLoadingNumberOfClients: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void
}

const WEB_DIMENSIONS = { width: 500, height: 340 }

const isWeb = Platform.OS === 'web'
const deviceWidth = isWeb ? WEB_DIMENSIONS.width : Math.round(Dimensions.get('window').width)

const AddressMap = ({ lat, lng, radius, nearbySchoolCount, updateGeoSearchRadius }: Props) => {
  const [isLoadingNumberOfClients, setIsLoadingNumberOfClients] = useState(false)

  const countryCode = useSelector(getCountryCode)
  const zoomForUK = radius > 4 ? (radius < 9 ? 10 : radius > 38 ? 7 : 8) : 11
  const zoomForSE = radius > 6 ? (radius > 12 ? 8 : 10) : 11

  const { staticMapUrl, isLoading, isError } = useStaticMap({
    address: `${lat},${lng}`,
    height: isWeb ? WEB_DIMENSIONS.height : styles.image.height,
    marker: 'color:0x98FFF2',
    path: `fillcolor:0x2121212D|color:0x212121${drawCirclePath(
      lat,
      lng,
      countryCode === 'se' ? radius : convertMilesToKm(radius),
    )}`,
    width: deviceWidth,
    zoom: countryCode === 'se' ? zoomForSE : zoomForUK,
  })

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (isError || !staticMapUrl) {
    return (
      <Image
        source={images.mapFallback}
        style={StyleSheet.flatten([styles.image, styles.backgroundFallback])}
        testID="fallback-image"
      />
    )
  }

  return (
    <>
      <Image source={{ uri: staticMapUrl }} style={styles.image} testID="static-map-image" />
      <View style={styles.numberOfClientsContainer}>
        {isLoadingNumberOfClients && <LoadingIndicator fullScreen={false} size="small" />}
        <Text style={styles.numberOfClientsInfo}>
          {translate(translations.numberOfClientsInArea, {
            quantity: isLoadingNumberOfClients ? '' : nearbySchoolCount,
          })}
        </Text>
      </View>
      <Text style={styles.numberOfClientsTooltip}>{translate(translations.numberOfClientsInAreaTooltip)}</Text>
      <Slider
        min={2}
        max={50}
        step={1}
        value={radius}
        onValueChanged={geoSearchRadius => updateGeoSearchRadius(geoSearchRadius, setIsLoadingNumberOfClients)}
        onStart={() => setIsLoadingNumberOfClients(true)}
      />
    </>
  )
}

export default AddressMap
