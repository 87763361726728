import { Platform, ScrollView, Text, View } from 'react-native'
import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback } from 'react'

import { useBugsnagView } from 'src/utils/bugsnag'
import { isUserApplicant } from 'src/store/user/selectors'
import { useAppSelector } from 'src/hooks/reduxHooks'
import CategoriesList from 'src/scenes/Main/Profile/CategoriesList'
import ComplianceStatus from 'src/components/ComplianceStatus'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import LoadingIndicator from 'src/components/LoadingIndicator'
import ProfilePictureUploader from 'src/scenes/Main/Profile/ProfilePictureUploader'
import ProgressBar from 'src/components/ProgressBar/ProgressBar'
import SettingsSection from './SettingsSection'
import translations, { translate } from 'src/utils/translations/translations'
import useGetCompletedTasks from 'src/scenes/Main/Profile/CategoriesList/useGetCompletedTasks'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = {
  navigation: StackNavigationProp<any>
}

const isWeb = Platform.OS === 'web'

const ProfileDetails = ({ navigation }: Props) => {
  const {
    categoriesData,
    categoriesList,
    error,
    isLoading,
    numberOfAllCompletedTasks,
    numberOfAllTasks,
    refetchTasks,
  } = useGetCompletedTasks()

  const { params } = useRoute<RouteProp<{ params: { isMenuTab?: boolean } }>>()

  const isApplicant = useAppSelector(isUserApplicant)
  const userFirstName = useAppSelector(state => state.user.attributes?.firstName)
  const userLastName = useAppSelector(state => state.user.attributes?.lastName)

  useBugsnagView('Profile - ProfileDetails')

  useFocusEffect(
    useCallback(() => {
      refetchTasks && refetchTasks()
      params?.isMenuTab && navigation?.getParent?.()?.setOptions({ headerShown: true })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.page, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={isWeb}
      testID="scrollView"
    >
      <View style={styles.profileInfo}>
        <ProfilePictureUploader />
        <Text style={styles.name}>
          {userFirstName} {userLastName}
        </Text>
      </View>
      {isApplicant ? (
        <ProgressBar
          progress={numberOfAllTasks ? numberOfAllCompletedTasks / numberOfAllTasks : 0}
          title={translate(translations.progressTasks, { numberOfTasks: numberOfAllTasks })}
          variant="success"
          withProgress
        />
      ) : (
        <ComplianceStatus />
      )}
      <CategoriesList categoriesData={categoriesData} categoriesList={categoriesList} />
      {params?.isMenuTab ? <SettingsSection /> : null}
    </ScrollView>
  )
}

export default ProfileDetails
