import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'

import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import FileUploader from 'src/components/FileUploader/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const DOCUMENT_TYPE = 'other'

type Props = ProfileAndRecruitmentScreensProps<'OtherDocuments'>

const OtherDocumentsGB = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - OtherDocumentsGB')

  const userId = useAppSelector(state => state.user.id) as string
  const otherDocumentsList = useAppSelector(getUserDocumentUK(DOCUMENT_TYPE))

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [noDocumentsToUpload, setNoDocumentsToUpload] = useState(isCompleted && !otherDocumentsList.length)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked
    }
    if (isCompleted) setNoDocumentsToUpload(!isFilePicked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const isDocumentUploaded = otherDocumentsList.length || isFilePicked
  const isButtonDisabled =
    (!noDocumentsToUpload && !isFilePicked && !otherDocumentsList.length) || isCompleted || isSubmitting

  const updateTaskStatus = () => {
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)
  }

  const completeTask = () => {
    setIsSubmitting(true)
    setIsError(false)
    isFilePicked ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainInfo}>{translate(translations.otherDocumentsDescription)}</Text>
      <FileUploader
        arrayDocuments={otherDocumentsList}
        buttonDisabled={isCompleted}
        deletingFilesDisabled={isCompleted}
        documentType={DOCUMENT_TYPE}
        onFileSaveCallback={!isCompleted ? updateTaskStatus : handleUploadFinish}
        onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
        onFilesErrorCallback={handleError}
        saveOnUpload={shouldSaveDocument}
      />
      <View style={styles.checkboxContainer}>
        <Checkbox
          disabled={!!isDocumentUploaded || isCompleted}
          label={translate(translations.otherDocumentsCheckbox)}
          onPress={() => setNoDocumentsToUpload(prev => !prev)}
          selected={noDocumentsToUpload}
        />
      </View>
      {isError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsError(false)} />
        </View>
      ) : null}
      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={isButtonDisabled}
          isSubmitting={isSubmitting}
          onPress={completeTask}
          size={buttonSize.lg}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.taskInfo}>{translate(translations.otherDocumentsSubinfo)}</Text>
    </ScrollView>
  )
}

export default OtherDocumentsGB
