import moment from 'moment'

const PRESCHOOL_GRADE = ['Förskola 1-2 år', 'Förskola 3-5 år']
const SCHOOL_GRADE = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'IM']

export const convertClassGrade = (gradesArray: string[]) =>
  gradesArray.map(grade => {
    if (PRESCHOOL_GRADE.includes(grade)) return grade.substring(9)
    if (SCHOOL_GRADE.includes(grade)) return `Åk ${grade}`
    return grade
  })

export const isExpressUnbooked = (expressDate: string) =>
  moment(expressDate).isSame(moment(), 'day') && moment().get('hour') >= 11

export const mixpanelBookingDays = {
  future: 'Future',
  past: 'Passed',
  today: 'Same day',
}

export const mixpanelBookingAppointment = {
  me: 'This User',
  notAppointed: 'Not Appointed',
  other: 'Other',
}

export const getBookingAppointment = (userId?: string, appointId?: string) => {
  if (userId && userId === appointId) {
    return mixpanelBookingAppointment.me
  } else if (userId && appointId && userId !== appointId) {
    return mixpanelBookingAppointment.other
  }
  return mixpanelBookingAppointment.notAppointed
}

export const getExpressBookingAppointment = (appointedAt?: string) => {
  if (appointedAt) {
    return mixpanelBookingAppointment.me
  }
  return mixpanelBookingAppointment.notAppointed
}
