import React, { useCallback, useState } from 'react'
import { View, Text } from 'react-native'

import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { ExpandLessIcon, ExpandMoreIcon } from 'src/icons'

const SchoolsHeader = () => {
  const [showText, setShowText] = useState(false)

  const moreButton = useCallback(() => {
    return (
      <View>
        {showText ? <Text style={styles.moreText}>{translate(translations.schoolListDescription)}</Text> : null}
        <ButtonNew
          isShort
          onPress={() => setShowText(prevState => !prevState)}
          RightIcon={showText ? ExpandLessIcon : ExpandMoreIcon}
          size={buttonSize.sm}
          testID={showText ? 'show-less-button' : 'show-more-button'}
          title={showText ? translate(translations.hide) : translate(translations.readMore)}
          variant={buttonVariants.textDefault}
        />
      </View>
    )
  }, [showText])
  return (
    <>
      <View>
        <Text style={styles.schoolInfo}>{translate(translations.schoolListInfo)}</Text>
        {moreButton()}
        <View style={styles.divider} />
      </View>
    </>
  )
}

export default SchoolsHeader
