import axios from 'axios'

import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { UriT, UriWebT } from './types'

export const uploadFileWithSignedUrl = (
  presignedUrl: string,
  uri: UriWebT,
  mimeType: string,
  resolve: (param: boolean) => void,
  fileName: string,
) => {
  bugsnagActionBreadcrumb('uploadFileWithSignedUrl', { fileName })

  const options = {
    headers: {
      acl: 'public-read',
      'Content-Disposition': `inline; filename="${encodeURIComponent(fileName)}"`,
      'Content-Type': uri.type,
    },
  }
  return axios
    .put(presignedUrl, uri, options)
    .then(() => {
      resolve(true)
    })
    .catch(e => {
      bugsnagNotifyWithData('uploadFileWithSignedUrl')
      resolve(false)
    })
}

export const getNewUri = (uri: UriT) => uri
