import gql from 'graphql-tag'

import OrderFields from './Fields'

export default gql`
  query Show($id: ID!) {
    order(id: $id) {
      ...OrderFields
    }
  }

  ${OrderFields}
`
