import { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { addConnectionChangeListener, removeConnectionChangeListener } from 'src/utils/networkStatus/listeners'

const NetInfoListener = () => {
  useFocusEffect(
    useCallback(() => {
      addConnectionChangeListener()

      return () => {
        removeConnectionChangeListener()
      }
    }, []),
  )
  return null
}

export default NetInfoListener
