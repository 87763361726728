import React from 'react'

import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import CustomModal from 'src/components/Modal/CustomModal'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { navigateToAppScreen } from 'src/utils/navigateToScreen'

type Props = {
  description?: string
  error?: string
  onModalClose: () => void
  showModal: boolean
  title?: string
}

const goToSupportHandler = () => navigateToAppScreen(['MainTabNavigator', 'MenuNavigation', 'Support'])

export const ReportErrorModalWithoutInternet = ({ showModal, onModalClose }: Props) => {
  const modalButtons = () => [
    {
      onPress: onModalClose,
      title: translate(translations.close),
      variant: buttonVariants.containedError,
    },
  ]

  return (
    <CustomModal
      buttons={modalButtons()}
      colorSchema={customModalColorSchema.error}
      description={translate(translations.connectionLostDescription)}
      isModalScreen
      isVisible={showModal}
      modalPlace="ModalTypes/ReportErrorModal/ReportErrorModal"
      onClose={onModalClose}
      title={translate(translations.connectionLost)}
      variant={customModalVariants.filled}
    />
  )
}

const ReportErrorModal = ({ showModal, onModalClose, error, description, title }: Props) => {
  const isConnected = useAppSelector(state => state.app.isConnected)
  const userId = useAppSelector(state => state.user.id)

  if (!isConnected) {
    return (
      <ReportErrorModalWithoutInternet
        description={description}
        error={error}
        onModalClose={onModalClose}
        showModal={showModal}
        title={title}
      />
    )
  }

  const gotoSupport = () => {
    if (userId) {
      goToSupportHandler()
    }
    onModalClose()
  }

  const modalButtons = () => {
    if (!isConnected) {
      return [
        {
          onPress: onModalClose,
          title: translate(translations.close),
          variant: buttonVariants.containedError,
        },
      ]
    }

    const closeButton = {
      onPress: onModalClose,
      title: translate(translations.backModalCancel),
      variant: buttonVariants.outlinedDefault,
    }

    if (userId) {
      return [
        closeButton,
        {
          onPress: gotoSupport,
          title: translate(translations.contactSupport),
          variant: buttonVariants.containedError,
        },
      ]
    }

    return [closeButton]
  }

  return (
    <CustomModal
      buttons={modalButtons()}
      colorSchema={customModalColorSchema.error}
      description={
        isConnected
          ? description || translate(translations.fetchingError)
          : translate(translations.connectionLostDescription)
      }
      isModalScreen
      isVisible={showModal}
      modalPlace="ModalTypes/ReportErrorModal/ReportErrorModal"
      onClose={onModalClose}
      title={isConnected ? title || translate(translations.error) : translate(translations.connectionLost)}
      variant={customModalVariants.filled}
    />
  )
}

export default ReportErrorModal
