import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  buttonSection: {
    paddingVertical: paddingNew.md,
  },
  buttonWrapper: {
    alignItems: 'center',
  },
  container: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    paddingBottom: paddingNew.md,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
    marginTop: paddingNew.sm,
  },
  info: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
