import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  containerMargin: {
    marginTop: paddingNew.lg,
  },
  container: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.md,
  },
  cardsWrapper: {
    height: 96,
  },
  cardsContainer: {
    flexDirection: 'row',
  },
  header: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
  },
  subheader: {
    ...typography.h7,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.xxs,
  },
  bottomSection: {
    marginBottom: paddingNew.sm,
  },
})

export default styles
