import { AxiosError } from 'axios'

import Api, { responseErrorCallback } from 'src/utils/api'
import formatter from 'src/utils/formatter'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { ErrorResponseT, OnErrorT, OnSuccessT } from 'src/utils/types'
import { InitialValuesTypes } from './Availability'
import moment from 'moment'

export const updateLongTermAvailability = (
  data: Partial<InitialValuesTypes>,
  onSuccess: OnSuccessT,
  onError: OnErrorT,
) => {
  bugsnagActionBreadcrumb('sendLongTermAvailability')

  return Api.post('/student/long_term_availability', {
    data: JSON.stringify(data),
    onSuccess: onSuccess,
    onError: (response: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData('sendLongTermAvailability', response)
      onError()
      responseErrorCallback('post', `/student/long_term_availability`, response)
    },
  })
}

export const allDaysSelected = [1, 2, 3, 4, 5]

export const getInitialData = () =>
  ({
    applyTo: 'all_days',
    available: true,
    endDate: '',
    endTime: '',
    selectedDays: allDaysSelected,
    // @ts-ignore
    startDate: formatter.apiFormat(),
    startTime: '',
    timeScheduleType: 'full_day',
  } as InitialValuesTypes)

export const weekDays = {
  gb: [
    {
      label: 'Mon',
      value: 1,
    },
    {
      label: 'Tue',
      value: 2,
    },
    {
      label: 'Wed',
      value: 3,
    },
    {
      label: 'Thu',
      value: 4,
    },
    {
      label: 'Fri',
      value: 5,
    },
  ],
  se: [
    {
      label: 'Mån',
      value: 1,
    },
    {
      label: 'Tis',
      value: 2,
    },
    {
      label: 'Ons',
      value: 3,
    },
    {
      label: 'Tor',
      value: 4,
    },
    {
      label: 'Fre',
      value: 5,
    },
  ],
}

export const checkIfWeekdayEnabled = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) {
    return []
  } else if (formatter.getDatesDifferenceInDays(endDate, startDate) >= 7) {
    return [1, 2, 3, 4, 5]
  } else {
    const enabled = [] as number[]
    const min = moment(startDate).day()
    const max = min + formatter.getDatesDifferenceInDays(endDate, startDate)
    for (let i = min; i <= max; i++) {
      enabled.push(i % 7)
    }
    return enabled
  }
}
