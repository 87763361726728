import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  checkBoxRow: {
    paddingLeft: paddingNew.sm,
    marginRight: paddingNew.sm,
  },
  label: StyleSheet.flatten([
    typography.subtitle2,
    {
      flex: 1,
      color: colorsNew.redesign.text.primary,
    },
  ]),
})
