import { View, Text, ScrollView } from 'react-native'
import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'

import useExpressSummary from 'src/hooks/useExpressSummary'
import translations, { translate } from 'src/utils/translations/translations'
import LoadingIndicator from 'src/components/LoadingIndicator'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import ExpressSummaryInfo from './components/ExpressSummaryInfo'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import { bugsnagNotifyWithData, useBugsnagView } from 'src/utils/bugsnag'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const ExpressSummary = () => {
  const { params } = useRoute<RouteProp<{ params: { id: string } }>>()
  const { goBack } = useNavigation()
  const { error, expressDate = '', expressSalary = '', loading } = useExpressSummary(params?.id)

  useBugsnagView('ExpressSummary')

  if (loading) {
    return <LoadingIndicator />
  }

  if (error) {
    bugsnagNotifyWithData('ExpressSummary error on fetching', error)
    return <FetchingDataErrorModal errorToReport={error} />
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={globalStyles.containerSize}
      testID="scrollView"
    >
      <ExpressSummaryInfo expressSalary={expressSalary} expressDate={expressDate} />
      <Text style={styles.reportText}>{translate(translations.expressSummaryReportTitle)}</Text>
      <Text style={styles.reportSubtitle}>{translate(translations.expressSummaryReportSubtitle)}</Text>
      <View style={styles.buttonContainer}>
        <ButtonNew onPress={goBack} title={translate(translations.gotIt)} variant={buttonVariants.containedDefault} />
      </View>
    </ScrollView>
  )
}

export default ExpressSummary
