import { Platform } from 'react-native'

import Api from 'src/utils/api'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { getNewUri, uploadFileWithSignedUrl } from 'src/utils/uploadingFiles/uploadFileWithSignedUrl'
import navigationService from 'src/utils/navigationService'
import translations, { translate } from 'src/utils/translations/translations'
import { UploadAvatarToS3ArgumentsT } from './actionTypes'

const showErrorModal = (errors = {}) => {
  bugsnagActionBreadcrumb('go to modal after avatar error', { ...errors })

  navigationService.navigate('Modal', {
    description: translate(translations.avatarError),
    title: translate(translations.pleaseTryAgain),
  })
}

export const uploadAvatarToS3 = async ({ uri, id, fileName }: UploadAvatarToS3ArgumentsT) => {
  if (!fileName || !uri) {
    return
  }

  bugsnagActionBreadcrumb('uploadAvatarToS3', { fileName })

  const cutName = fileName.split('.')
  const fileType = ['jpg', 'jpeg'].includes(cutName[cutName.length - 1].toLowerCase()) ? 'jpeg' : 'png'

  const mimeType = `image/${fileType}`

  try {
    const newUri = Platform.OS === 'web' ? uri : await getNewUri(uri as string)

    const { data }: { data: { signedUrl: string } } = await Api.get(
      `presigned_url?objectName=${fileName}&contentType=${mimeType}&id=${id}&type=users`,
      {
        onSuccess: response => response,
      },
    )

    return new Promise(resolve => {
      // @ts-ignore
      uploadFileWithSignedUrl(data.signedUrl, newUri, mimeType, resolve, fileName)
    })
      .then(response => {
        if (response) {
          return data.signedUrl.match(new RegExp(/^[^?]+/gm))?.[0]
        } else {
          showErrorModal({ fileName, response })
        }
      })
      .catch(err => {
        showErrorModal({ err })
      })
  } catch (_) {
    showErrorModal({ fileName })
  }
}
