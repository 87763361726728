import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  locationContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: paddingNew.xs,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.md,
  },
  location: {
    ...typography.body1,
    color: colorsNew.redesign.text.secondary,
    marginLeft: paddingNew.xxs,
    paddingTop: 3,
  },
  headerTitle: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  badgesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.base,
    marginTop: paddingNew.xs,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  headerWithMargin: {
    paddingBottom: paddingNew.xs,
  },
  descriptionContainer: {
    marginBottom: paddingNew.sm,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: paddingNew.xs,
  },
  rowTitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    width: 80,
  },
  rowContent: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    flex: 1,
  },
  currency: {
    ...typography.body1,
    color: colorsNew.redesign.text.secondary,
    marginRight: paddingNew.xs,
  },
  contactHeader: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  contactInfo: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contactContent: {
    flexShrink: 1,
  },
  contactPlaceholder: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  mask: {
    opacity: 0.4,
  },
})

export default styles
