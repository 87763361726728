import React from 'react'
import { View, Text } from 'react-native'

import SchoolsRow from '../SchoolsRow'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import { SchoolTypes } from 'src/graphql/School'
import { DislikedSchoolsIdsT } from 'src/store/user/actionTypes'

type Props = {
  isSending: boolean
  refetch: () => void
  schools: SchoolTypes[]
  setIsSending: (value: boolean) => any
  updateUserSchools: (schoolIds: DislikedSchoolsIdsT) => any
  userDislikedSchoolsIds: string[]
}

const SchoolsList = ({
  schools = [],
  isSending,
  setIsSending,
  refetch,
  updateUserSchools,
  userDislikedSchoolsIds,
}: Props) => {
  const isExcluded = (schoolId: string) => userDislikedSchoolsIds.includes(schoolId)
  const excludedSchools = schools.filter(school => isExcluded(school.id)) as SchoolTypes[]
  const activeSchools = schools.filter(school => !isExcluded(school.id)) as SchoolTypes[]

  const toggleSchool = (schoolId: string) => {
    setIsSending(true)
    const params = {} as DislikedSchoolsIdsT
    if (isExcluded(schoolId)) {
      params.dislikedSchoolIds = userDislikedSchoolsIds.filter(id => id !== schoolId)
    } else {
      params.dislikedSchoolIds = [...userDislikedSchoolsIds, schoolId]
    }

    return updateUserSchools(params).then(refetch)
  }

  const renderExcludedSchools = () => {
    const isNoneExcluded = excludedSchools.length === 0

    return (
      <View>
        {renderTitle(translate(translations.excludedSchoolsTitle))}
        {isNoneExcluded
          ? renderEmptyInfo(translate(translations.emptyExcludedSchoolsDescription))
          : excludedSchools.map(school => (
              <SchoolsRow {...school} isExcluded key={school.id} isSending={isSending} toggleSchool={toggleSchool} />
            ))}
      </View>
    )
  }

  const renderActiveSchools = () => {
    const isNoneActive = activeSchools.length === 0

    return (
      <View style={styles.activeSchool}>
        {renderTitle(translate(translations.schoolListKeepMatching))}
        {isNoneActive
          ? renderEmptyInfo(translate(translations.emptyActiveSchoolsDescription))
          : activeSchools.map(school => (
              <SchoolsRow
                {...school}
                isExcluded={false}
                isSending={isSending}
                key={school.id}
                toggleSchool={toggleSchool}
              />
            ))}
      </View>
    )
  }

  const renderTitle = (title: string) => <Text style={styles.title}>{title}</Text>
  const renderEmptyInfo = (description: string) => <Text style={styles.emptyDescription}>{description}</Text>

  return (
    <View style={styles.container}>
      {renderExcludedSchools()}
      {renderActiveSchools()}
    </View>
  )
}

export default SchoolsList
