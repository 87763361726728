import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  cardContainer: {
    flex: 1,
  },
  card: {
    height: 96,
  },
  iconContainer: {
    bottom: -5,
    justifyContent: 'center',
    position: 'absolute',
    right: -2,
  },
  iconExpress: {
    bottom: 12,
    right: 3,
  },
  statusContainer: {
    bottom: 0,
    padding: paddingNew.xs,
    position: 'absolute',
  },
  title: {
    ...typography.overline,
    color: colorsNew.redesign.text.primary,
    lineHeight: 16,
  },
  statusInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
  statusInfoDisabled: {
    color: colorsNew.redesign.text.disabled,
  },
})
