import { StyleSheet } from 'react-native'

import { colorsNew, fontSizesNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  page: {
    backgroundColor: colorsNew.white,
    paddingHorizontal: paddingNew.sm,
  },
  title: {
    color: colorsNew.brand.secondaryDark,
    fontSize: fontSizesNew.lg.size,
    fontWeight: '700',
    lineHeight: fontSizesNew.lg.lh,
    paddingVertical: paddingNew.base,
  },
  articlesContainer: {
    paddingBottom: paddingNew.xll,
    overflow: 'visible',
  },
})
