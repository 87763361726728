import { AlertColorScheme, AlertVariants, alertVariants, alertColorScheme } from './types'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { TTypes } from 'src/components/Buttons/ButtonNew/types'

export const getAdditionalActionButtonVariant = (colorSchema: AlertColorScheme, variant: AlertVariants): TTypes => {
  let buttonVariant: TTypes = buttonVariants.textDefault

  if (variant === alertVariants.outlined) {
    switch (colorSchema) {
      case alertColorScheme.error:
        buttonVariant = buttonVariants.containedError
        break
      case alertColorScheme.warning:
        buttonVariant = buttonVariants.containedWarning
        break
      case alertColorScheme.info:
        buttonVariant = buttonVariants.containedInfo
        break
      case alertColorScheme.success:
        buttonVariant = buttonVariants.containedSuccess
        break
    }
  } else if (variant === alertVariants.standard) {
    switch (colorSchema) {
      case alertColorScheme.error:
        buttonVariant = buttonVariants.outlinedError
        break
      case alertColorScheme.warning:
        buttonVariant = buttonVariants.outlinedWarning
        break
      case alertColorScheme.info:
        buttonVariant = buttonVariants.outlinedInfo
        break
      case alertColorScheme.success:
        buttonVariant = buttonVariants.outlinedSuccess
        break
    }
  }

  return buttonVariant
}
