import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const mailFullFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM6.1725 8.875C6.1725 8.30208 6.69167 7.83333 7.33333 7.83333H16.6667C17.3083 7.83333 17.8333 8.30208 17.8333 8.875V15.125C17.8333 15.6979 17.3083 16.1667 16.6667 16.1667H7.33333C6.69167 16.1667 6.16667 15.6979 6.16667 15.125L6.1725 8.875ZM12.3092 12.349L16.4333 10.0469C16.4918 10.0176 16.543 9.97795 16.5839 9.93044C16.6248 9.88294 16.6544 9.82853 16.671 9.77051C16.6876 9.71248 16.6909 9.65205 16.6806 9.59287C16.6703 9.53369 16.6466 9.47699 16.6111 9.4262C16.5755 9.37541 16.5288 9.33159 16.4737 9.29739C16.4187 9.2632 16.3564 9.23933 16.2907 9.22724C16.225 9.21515 16.1573 9.21509 16.0915 9.22706C16.0258 9.23903 15.9635 9.26278 15.9083 9.29688L12 11.4792L8.09167 9.29688C8.03652 9.26278 7.9742 9.23903 7.90847 9.22706C7.84275 9.21509 7.77499 9.21515 7.70929 9.22724C7.64359 9.23933 7.58132 9.2632 7.52626 9.29739C7.47119 9.33159 7.42447 9.37541 7.38891 9.4262C7.35336 9.47699 7.32972 9.53369 7.31942 9.59287C7.30913 9.65205 7.31239 9.71248 7.329 9.77051C7.34562 9.82853 7.37525 9.88294 7.4161 9.93044C7.45695 9.97795 7.50817 10.0176 7.56667 10.0469L11.6908 12.349C11.8775 12.4531 12.1225 12.4531 12.3092 12.349Z"
    />
  </Svg>
)

export default mailFullFilledIcon
