import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { View, Text, StyleSheet } from 'react-native'
import moment from 'moment'

import { allDaysSelected, weekDays, checkIfWeekdayEnabled } from '../utils'
import { countryCodeSelector } from 'src/store/app/selectors'
import { scheduleTypesList } from 'src/global/constants'
import { getDefaultEndTime, getDefaultStartTime } from 'src/scenes/Main/Schedule/availabilityContext/dateHelpers'
import { FormikErrors } from 'formik'
import { FormikActions } from 'src/utils/types/formikTypes'
import Checkbox from 'src/components/Form/Checkbox'
import InputDateNew from 'src/components/Form/InputDateNew/InputDateNew'
import IntervalInput from './IntervalInput/IntervalInput'
import RadioButton from 'src/components/Form/InputRadioGroup/RadioButton'
import translations, { translate } from 'src/utils/translations/translations'
import { InitialValuesTypes } from '../Availability'
import styles from './styles'
import { capitalizeFirstLetter } from 'src/global/utils'

type Props = {
  errors: FormikErrors<InitialValuesTypes>
  isSubmitting: boolean
  setFieldValue: FormikActions<InitialValuesTypes>['setFieldValue']
  values: InitialValuesTypes
}

const AvailabilityForm = ({ values, isSubmitting, setFieldValue, errors }: Props) => {
  const countryCode = useSelector(countryCodeSelector)

  const today = new Date().toISOString()

  const shouldValidateDates = !!values.startDate && !!values.endDate
  const shouldValidateTime = !!values.startTime && !!values.endTime

  const formatDate = (date?: string) => (date && moment(date).format('DD/MM/YY')) || ''

  const enabledDays = useMemo(
    () => checkIfWeekdayEnabled(values.startDate, values.endDate),
    [values.startDate, values.endDate],
  )

  return (
    <>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{translate(translations.availabilityDateRange)}</Text>
        <InputDateNew
          disabled={isSubmitting}
          formattedValue={formatDate(values.startDate)}
          isError={shouldValidateDates && !!errors.startDate}
          label={capitalizeFirstLetter(translate(translations.from))}
          setValue={date => setFieldValue('startDate', date)}
          testID="availability-form-start-date"
          value={values.startDate}
        />
        <InputDateNew
          disabled={isSubmitting}
          formattedValue={formatDate(values.endDate)}
          isError={shouldValidateDates && !!errors.endDate}
          label={capitalizeFirstLetter(translate(translations.to))}
          setValue={date => setFieldValue('endDate', date)}
          testID="availability-form-end-date"
          value={values.endDate}
        />
        {shouldValidateDates && (errors.endDate || errors.startDate) ? (
          <Text style={styles.errorText}>{errors.endDate || errors.startDate}</Text>
        ) : null}
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{translate(translations.availabilitySetAs)}</Text>
        <View style={styles.radioButtonsContainerInline}>
          <RadioButton
            disabled={isSubmitting}
            isLarge
            label={translate(translations.available)}
            onPress={() => setFieldValue('available', true)}
            selected={values.available}
            testID="available"
          />
          <RadioButton
            disabled={isSubmitting}
            isLarge
            label={translate(translations.noAvailable)}
            onPress={() => setFieldValue('available', false)}
            selected={!values.available}
            testID="not-available"
          />
        </View>

        {values.available ? (
          <View style={styles.radioButtonsContainerInline}>
            {scheduleTypesList[countryCode].map(type => (
              <RadioButton
                disabled={isSubmitting}
                key={`timeScheduleType-button-${type.label}`}
                label={type.label}
                onPress={() => {
                  setFieldValue('timeScheduleType', type.value)
                  if (type.value === 'hourly') {
                    setFieldValue('startTime', getDefaultStartTime(today))
                    setFieldValue('endTime', getDefaultEndTime(today))
                  }
                }}
                selected={values.timeScheduleType === type.value}
                testID={type.value}
              />
            ))}
          </View>
        ) : null}

        {values.available && values.timeScheduleType === 'hourly' ? (
          <>
            <IntervalInput
              formEndTime={values.endTime}
              formStartTime={values.startTime}
              isSubmitting={isSubmitting}
              updateTime={(name, value) => setFieldValue(name, value)}
            />
            {shouldValidateTime ? <Text style={styles.errorText}>{errors.startTime || errors.endTime}</Text> : null}
          </>
        ) : null}
      </View>

      <View style={StyleSheet.flatten([styles.section, styles.lastSection])}>
        <Text style={styles.sectionTitle}>{translate(translations.availabilityApplyTo)}</Text>
        <View style={styles.radioButtonsContainerInline}>
          <RadioButton
            disabled={isSubmitting}
            label={translate(translations.availabilityAllDays)}
            onPress={() => {
              setFieldValue('applyTo', 'all_days')
              setFieldValue('selectedDays', allDaysSelected)
            }}
            selected={values.applyTo === 'all_days'}
            isLarge
          />
          <RadioButton
            disabled={isSubmitting}
            label={translate(translations.availabilitySelected)}
            onPress={() => {
              setFieldValue('applyTo', 'selected')
            }}
            selected={values.applyTo === 'selected'}
            isLarge
          />
        </View>
        {values.applyTo === 'selected' ? (
          <>
            <View style={styles.checkboxContainerInline}>
              {weekDays[countryCode].map(day => (
                <View style={styles.checkboxItem} key={`selected-day-${day.value}`}>
                  <Checkbox
                    disabled={!enabledDays.includes(day.value)}
                    onPress={() => {
                      setFieldValue(
                        'selectedDays',
                        values.selectedDays.includes(day.value)
                          ? values.selectedDays.filter(selectedDay => selectedDay !== day.value)
                          : [...values.selectedDays, day.value],
                      )
                    }}
                    selected={values.selectedDays.includes(day.value) && enabledDays.includes(day.value)}
                    label={day.label}
                    testID={`selected-day-${day.value}`}
                    variant="sm"
                  />
                </View>
              ))}
            </View>
            {errors.selectedDays ? (
              <Text style={[styles.errorText, styles.errorWithMargin]}>{errors.selectedDays}</Text>
            ) : null}
          </>
        ) : null}
      </View>
    </>
  )
}

export default AvailabilityForm
