import gql from 'graphql-tag'

import { ChatMessage, ChatMessages } from './types'

export type { ChatMessage, ChatMessages }

export default gql`
  query Chat($userId: String) {
    messages(filter: { userId: $userId, type: "chat", latest: true }) {
      nodes {
        content
        createdAt
        id
        key
        origin
        read
        recipientId
        sender {
          firstName
          lastName
        }
        senderId
        type
      }
    }
  }
`
