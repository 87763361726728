import { Platform, Switch } from 'react-native'
import React from 'react'

import { colorsNew } from 'src/styles'

type Props = {
  disabled?: boolean
  isSwitchOn: boolean
  onToggle: (isChecked: boolean) => void
  testID?: string
}

const SwitchElement = ({ testID, onToggle, isSwitchOn, disabled }: Props) => {
  const isIOS = Platform.OS === 'ios'
  const isDisabledStyles = disabled ? { opacity: 0.7 } : {}

  return (
    <Switch
      disabled={disabled}
      ios_backgroundColor={colorsNew.redesign.secondary.shades50p}
      onValueChange={onToggle}
      style={isIOS ? { transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] } : isDisabledStyles}
      testID={testID}
      thumbColor={isSwitchOn && !isIOS ? colorsNew.redesign.primary.dark : colorsNew.white}
      trackColor={{
        false: colorsNew.redesign.secondary.shades50p,
        true: colorsNew.redesign.primary.main,
      }}
      value={isSwitchOn}
    />
  )
}

export default SwitchElement
