import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const BreakIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 65 65" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M20.948 13.446a2 2 0 0 0-2 2v4a2 2 0 0 0 4 0v-4a2 2 0 0 0-2-2Z" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.948 51.446a12.005 12.005 0 0 0 11.834-10h.166a8 8 0 0 0 0-16v-2h-36v16a12 12 0 0 0 12 12h12Zm-20-24v12a8 8 0 0 0 8 8h12a8 8 0 0 0 8-8v-12h-28Zm36 6a4 4 0 0 1-4 4v-8a4 4 0 0 1 4 4Z"
    />
    <Path d="M26.948 15.446a2 2 0 0 1 4 0v4a2 2 0 0 1-4 0v-4Zm10-2a2 2 0 0 0-2 2v4a2 2 0 0 0 4 0v-4a2 2 0 0 0-2-2Z" />
  </Svg>
)

export default BreakIcon
