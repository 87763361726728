import { IS_MAESTRO_E2E } from 'src/utils/webAdapters/DotEnvAdapter'

export const isMaestro = () => {
  try {
    if (__DEV__ && IS_MAESTRO_E2E) {
      return true
    }
  } catch (ignored) {
    return false
  }
}
