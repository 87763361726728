import { StyleSheet } from 'react-native'
import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  backgroundFallback: { resizeMode: 'cover' },
  image: {
    height: 340,
    resizeMode: 'contain',
    width: '100%',
  },
  loader: { height: 340, justifyContent: 'center' },
  numberOfClientsInfo: StyleSheet.flatten([
    typography.subtitle2,
    {
      color: colorsNew.redesign.text.primary,
    },
  ]),
  numberOfClientsTooltip: StyleSheet.flatten([
    typography.tooltip,
    {
      paddingLeft: paddingNew.sm,
      color: colorsNew.redesign.text.primary,
    },
  ]),
  numberOfClientsContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: paddingNew.sm,
    paddingTop: paddingNew.sm,
  },
})
