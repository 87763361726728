import React from 'react'
import Svg, { Path, Rect, Circle } from 'react-native-svg'

import { IconTypes } from './iconTypes'

const CancelledBookingIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 188 188" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Rect
      x={21.358}
      y={47.809}
      width={114.749}
      height={116.076}
      rx={8}
      transform="rotate(-6.563 21.358 47.809)"
      fill="#224D3D"
    />
    <Rect
      x={30.635}
      y={52.921}
      width={102.612}
      height={103.799}
      rx={6}
      transform="rotate(-6.563 30.635 52.92)"
      fill="#F7FCFB"
    />
    <Rect
      x={55.949}
      y={31.334}
      width={6.837}
      height={24.826}
      rx={3.418}
      transform="rotate(-6.563 55.95 31.334)"
      fill="#224D3D"
    />
    <Rect
      x={101.795}
      y={26.06}
      width={6.837}
      height={24.826}
      rx={3.418}
      transform="rotate(-6.563 101.795 26.06)"
      fill="#224D3D"
    />
    <Rect
      x={42.279}
      y={72.733}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 42.28 72.733)"
      fill="#00B58B"
    />
    <Rect
      x={44.88}
      y={95.335}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 44.88 95.335)"
      fill="#00B58B"
    />
    <Rect
      x={47.48}
      y={117.939}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 47.48 117.939)"
      fill="#00B58B"
    />
    <Rect
      x={64.882}
      y={70.132}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 64.882 70.132)"
      fill="#00B58B"
    />
    <Rect
      x={67.483}
      y={92.735}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 67.483 92.735)"
      fill="#00B58B"
    />
    <Rect
      x={70.083}
      y={115.338}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 70.083 115.338)"
      fill="#00B58B"
    />
    <Rect
      x={87.485}
      y={67.532}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 87.485 67.532)"
      fill="#00B58B"
    />
    <Rect
      x={90.086}
      y={90.135}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 90.086 90.135)"
      fill="#00B58B"
    />
    <Rect
      x={92.686}
      y={112.738}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 92.686 112.738)"
      fill="#00B58B"
    />
    <Rect
      x={110.089}
      y={64.931}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 110.089 64.931)"
      fill="#00B58B"
    />
    <Rect
      x={112.689}
      y={87.534}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 112.689 87.534)"
      fill="#00B58B"
    />
    <Rect
      x={115.289}
      y={110.137}
      width={16.269}
      height={16.269}
      rx={1}
      transform="rotate(-6.563 115.289 110.137)"
      fill="#00B58B"
    />
    <Circle cx={136.685} cy={135.67} r={27.991} transform="rotate(-1.212 136.685 135.67)" fill="#224D3D" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.067 123.26a2.312 2.312 0 0 0 .069 3.269l10.293 9.866-10.974 11.449a2.312 2.312 0 1 0 3.339 3.2l10.974-11.448 11.448 10.974a2.313 2.313 0 1 0 3.201-3.339l-11.449-10.974 9.866-10.293a2.312 2.312 0 1 0-3.339-3.2l-9.866 10.292-10.292-9.866a2.314 2.314 0 0 0-3.27.07Z"
      fill="#F7FCFB"
    />
  </Svg>
)

export default CancelledBookingIcon
