import React, { useMemo, useState } from 'react'
import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { CloseIcon } from 'src/icons'
import { ErrorAlert, ErrorType } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getQualificationLabel, getQualificationType } from '../utils'
import { InputSelectNew } from 'src/components/Form'
import { shortDatePickerVariants } from 'src/components/Form/DatePickerShort/types'
import { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import { UserEducation } from 'src/store/user/selectors'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import ConfirmationAlertModal from 'src/scenes/Main/Profile/RequiredTasks/components/ConfirmationAlertModal'
import DatePickerShort from 'src/components/Form/DatePickerShort/DatePickerShort'
import TextInputNew from 'src/components/Form/TextInputNew/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import validationSchema from './validation'
import { colorsNew } from 'src/styles'
import styles from './styles'

type RecursiveNonNullable<T> = { [K in keyof T]-?: RecursiveNonNullable<NonNullable<T[K]>> }

export type QualificationFormValues = Pick<
  RecursiveNonNullable<UserEducation>,
  'qualificationType' | 'name' | 'startDate' | 'endDate' | 'subject' | 'id' | 'isOngoing'
>

type Props = {
  closeFormError: () => void
  closeFormWithoutSaving: () => void
  deleteQualification: (qualificationId?: string) => void
  formError: ErrorType | null
  isDeleting: boolean
  isDisabled: boolean
  isSubmitting: boolean
  qualification: Partial<UserEducation>
  updateQualificationList: (qualification: QualificationFormValues, editedQualificationId?: string) => void
}

const UniversityForm = ({
  closeFormError,
  closeFormWithoutSaving,
  deleteQualification,
  formError,
  isDeleting,
  isDisabled,
  isSubmitting,
  qualification,
  updateQualificationList,
}: Props) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const initialValues: QualificationFormValues = {
    endDate: qualification.endDate || '',
    id: qualification.id || '',
    isOngoing: !!qualification.isOngoing,
    name: qualification.name || '',
    qualificationType: qualification.qualificationType || '',
    startDate: qualification.startDate || '',
    subject: qualification.subject || '',
  }

  const qualificationsVariants = useMemo(() => getQualificationType(), [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => updateQualificationList(values, qualification.id)}
      validationSchema={validationSchema}
    >
      {({ setFieldTouched, setFieldValue, dirty, errors, touched, values, isValid, handleSubmit }) => {
        if (showDeleteConfirmation) {
          return (
            <ConfirmationAlertModal
              description={translate(translations.qualificationFormDeleteInfo)}
              isDeleting={isDeleting}
              onCancel={() => setShowDeleteConfirmation(false)}
              onDelete={() => deleteQualification(qualification.id)}
              title={translate(translations.qualificationFormDeleteTile)}
            />
          )
        }

        return (
          <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.formHeader}>
              <View style={styles.formHeaderInfo}>
                {qualification.subject ? (
                  <>
                    <Text style={styles.formTitle}>
                      {getQualificationLabel(qualification?.qualificationType || '')}
                    </Text>
                    <Text style={styles.formSubtitle}>{qualification.name}</Text>
                  </>
                ) : (
                  <Text style={styles.formTitle}>{translate(translations.qualificationFormTitle)}</Text>
                )}
              </View>
              <TouchableOpacity onPress={closeFormWithoutSaving}>
                <CloseIcon fill={colorsNew.redesign.text.primary} size={24} />
              </TouchableOpacity>
            </View>

            {isDisabled ? (
              <View style={styles.alertContainer}>
                <AlertCard
                  colorSchema={alertColorScheme.info}
                  description={translate(translations.qualificationAlertInfo)}
                  title={translate(translations.qualificationAlertTitle)}
                  variant={alertVariants.standard}
                />
              </View>
            ) : null}
            <InputSelectNew
              disabled={isDisabled}
              errorMessage={errors.qualificationType}
              isError={!isEmpty(errors.qualificationType) && touched.qualificationType}
              label={translate(translations.qualificationFormTypesLabel)}
              onBlur={() => setFieldTouched('qualificationType', true)}
              onValueChange={value => setFieldValue('qualificationType', value)}
              required
              testID="qualificationType-input"
              value={values.qualificationType}
              values={qualificationsVariants}
            />
            <TextInputNew
              disabled={isDisabled}
              errorMessage={errors.name}
              isError={!isEmpty(errors.name) && touched.name}
              label={translate(translations.qualificationFormInstitutionLabel)}
              onBlur={() => setFieldTouched('name', true)}
              onChangeText={text => setFieldValue('name', text)}
              required
              testID="name-input"
              size={TextInputSizeVariants.medium}
              value={values.name}
            />
            <TextInputNew
              disabled={isDisabled}
              errorMessage={errors.subject}
              isError={!isEmpty(errors.subject) && touched.subject}
              label={translate(translations.qualificationFormSubjectLabel)}
              multiline
              onBlur={() => setFieldTouched('subject', true)}
              onChangeText={text => setFieldValue('subject', text)}
              required
              size={TextInputSizeVariants.big}
              testID="subject-input"
              value={values.subject}
            />
            <View style={styles.divider} />
            <DatePickerShort
              date={values.startDate}
              disabled={isDisabled}
              errorMessage={errors.startDate}
              isError={!isEmpty(errors.startDate) && touched.startDate}
              numberOfYearsInFuture={0}
              numberOfYearsInPast={51}
              onlyPastDateAllowed
              required
              setFieldTouched={() => setFieldTouched('startDate', true)}
              setFieldValue={date => {
                setFieldValue('startDate', date)
                setFieldTouched('startDate', true, false)
              }}
              testID="startDate-input"
              variant={shortDatePickerVariants.startDate}
              zIndex={2}
            />
            <DatePickerShort
              date={values.endDate}
              disabled={isDisabled || !!values.isOngoing}
              errorMessage={errors.endDate}
              isError={!isEmpty(errors.endDate) && touched.endDate}
              numberOfYearsInFuture={0}
              numberOfYearsInPast={51}
              onlyPastDateAllowed
              required
              setFieldTouched={() => setFieldTouched('endDate', true)}
              setFieldValue={date => {
                setFieldValue('endDate', date)
                setFieldTouched('endDate', true, false)
              }}
              testID="endDate-input"
              variant={shortDatePickerVariants.endDate}
              zIndex={1}
            />
            <View style={styles.checkboxContainer}>
              <Checkbox
                disabled={isDisabled}
                label={translate(translations.qualificationFormCheckboxOngoing)}
                onPress={() => setFieldValue('isOngoing', !values.isOngoing)}
                selected={values.isOngoing}
                testID="isOngoing-checkbox"
              />
            </View>
            <View style={styles.buttons}>
              {formError ? <ErrorAlert hideError={closeFormError} type={formError} /> : null}
              <ButtonNew
                disabled={!isValid || !dirty || isDeleting || isDisabled || isSubmitting}
                isSubmitting={isSubmitting}
                onPress={handleSubmit}
                size={buttonSize.lg}
                testID="submit-qualification-form"
                title={translate(translations.saveChanges)}
                variant={buttonVariants.containedDefault}
              />
              <View style={styles.buttonsDivider} />
              <ButtonNew
                disabled={isSubmitting || isDisabled || isDeleting}
                isSubmitting={isDeleting}
                onPress={() => setShowDeleteConfirmation(true)}
                size={buttonSize.lg}
                title={translate(translations.qualificationDeleteButton)}
                variant={buttonVariants.outlinedError}
              />
            </View>
          </ScrollView>
        )
      }}
    </Formik>
  )
}

export default UniversityForm
