import { StyleSheet } from 'react-native'
import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.sm,
  },
  button: { marginVertical: 0 },
  buttonWrapper: {
    alignItems: 'center',
    marginBottom: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  info: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  redoTaskButtonWrapper: {
    alignItems: 'center',
    marginBottom: paddingNew.base,
  },
  subInfo: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
  },
  uploaderWrapper: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.xs,
    paddingBottom: paddingNew.md,
  },
})

export default styles
