import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { momentWithTimezone } from 'src/global/utils'
import BookingsList from 'src/graphql/Booking/BookingsList'
import { BookingsListTypes } from 'src/graphql/Booking/types'
import ExpressBookings, { ExpressBookingsRequestTypes } from 'src/graphql/ExpressBookings/index'
import formatter from 'src/utils/formatter'
import { getJobsSections } from 'src/scenes/Main/Jobs/Lists/AppointedBookings/utils'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { useAppSelector } from './reduxHooks'
import { getToken } from 'src/utils/apolloStorage'

const useJobsSections = () => {
  const id = useAppSelector(state => state?.user?.id) as string
  const authToken = getToken()
  const isConnected = useAppSelector(state => state?.app?.isConnected)

  const bookings = useQuery<BookingsListTypes>(BookingsList, {
    fetchPolicy: 'cache-and-network',
    skip: !authToken,
    variables: {
      bookingType: 'appointed',
      skipCancelled: true,
    },
  })

  const expressBookings = useQuery<ExpressBookingsRequestTypes>(ExpressBookings, {
    fetchPolicy: 'cache-and-network',
    skip: !authToken,
    variables: {
      student: id,
      include_appointed: true,
      include_available: false,
      maxDate: formatter.apiFormat(momentWithTimezone().add(4, 'months')),
    },
  })

  const loading = bookings.loading || expressBookings.loading
  const error = bookings.error || expressBookings.error

  const refetch = () => {
    if (isConnected && bookings?.refetch && expressBookings?.refetch && !loading) {
      bugsnagActionBreadcrumb('refresh in useJobsSections')

      return Promise.all([bookings.refetch(), expressBookings.refetch()])
    } else {
      return Promise.resolve()
    }
  }

  const sections = useMemo(() => {
    const bookingsList = bookings?.data?.bookings?.nodes || []
    const expressList = expressBookings?.data?.expressBookings?.nodes || []

    return getJobsSections(bookingsList, expressList)
  }, [bookings?.data, expressBookings?.data])

  return { sections, loading, error, refetch }
}

export default useJobsSections
