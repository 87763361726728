import { FormikValuesTypes } from './FeedbackGeneral'
import translations, { translate } from 'src/utils/translations/translations'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'

export const deliveryMethodOptions = [
  {
    value: 'faceToface',
    label: translate((translations as TranslationGBT).deliveryMethodOption1),
  },
  {
    value: 'online',
    label: translate((translations as TranslationGBT).deliveryMethodOption2),
  },
  {
    value: 'delivered',
    label: translate((translations as TranslationGBT).deliveryMethodOption3),
  },
]

export const studentEngagementOptions = [
  {
    value: 'fully_engaged',
    label: translate((translations as TranslationGBT).studentEngagementOption1),
  },
  {
    value: 'partially_engaged',
    label: translate((translations as TranslationGBT).studentEngagementOption2),
  },
  {
    value: 'unengaged',
    label: translate((translations as TranslationGBT).studentEngagementOption3),
  },
]

export const safeguardingConcernsOptions = [
  {
    value: false,
    label: translate(translations.no),
  },
  {
    value: true,
    label: translate(translations.yes),
  },
]

export const initialValues = {
  attendance: '',
  comments: '',
  deliveryMethod: '',
  email: '',
  engagementMethod: '',
  resourcesUsed: '',
  safeguardingConcerns: false,
  subjects: '',
}

export const getSum = (values: FormikValuesTypes) =>
  Object.values(values).reduce(
    (acc = 0, item) => (typeof item === 'number' ? ((acc as number) += item) : acc),
    0,
  ) as number
