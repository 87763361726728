import { Platform } from 'react-native'

import colorsNew from './colorsNew'
import { padding as paddingNew } from './spacingNew'

const isWeb = Platform.OS === 'web'

export const colorWithOpacity = (color: string, opacity: number) => {
  const alpha256 = (opacity * 255).toFixed()
  const alphaBase16 = Number(alpha256).toString(16)
  const paddedAlpha = alphaBase16.length === 1 ? alphaBase16.padStart(1, '0') : alphaBase16
  return color.concat('', paddedAlpha)
}

type ShadowType = 'default' | 'hover' | 'selected' | 'button'

export const shadow = (type: ShadowType, color: string) => {
  if (isWeb) {
    switch (type) {
      case 'default':
        return {
          boxShadow: `0px 2px 4px ${colorWithOpacity(color, 0.25)}`,
        }
      case 'hover':
        return {
          boxShadow: `0px 3px 8px ${colorWithOpacity(color, 0.3)}`,
        }
      case 'selected':
        return {
          boxShadow: `0px 4px 10px ${colorWithOpacity(color, 0.35)}`,
        }
      case 'button':
        return {
          boxShadow: `0px 1px 2px ${colorWithOpacity(color, 0.25)}`,
        }

      default:
        return {}
    }
  }

  switch (type) {
    case 'default':
      return {
        elevation: 2,
        shadowColor: color,
        shadowOpacity: 0.2,
        shadowRadius: 1.41,
        shadowOffset: {
          width: 0,
          height: 1,
        },
      }
    case 'hover':
      return {
        elevation: 4,
        shadowColor: color,
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        shadowOffset: {
          width: 0,
          height: 2,
        },
      }
    case 'selected':
      return {
        elevation: 8,
        shadowColor: color,
        shadowOpacity: 0.3,
        shadowRadius: 4.65,
        shadowOffset: {
          width: 0,
          height: 4,
        },
      }
    case 'button':
      return {
        elevation: 2,
        shadowColor: color,
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        shadowOffset: {
          width: 0,
          height: 1,
        },
      }
    default:
      return {}
  }
}

export const noteBox = (withTitle: boolean) => ({
  borderColor: colorsNew.redesign.secondary.main,
  borderRadius: 8,
  borderWidth: 1,
  padding: paddingNew.base,
  ...(withTitle && { paddingTop: paddingNew.sm - 4 }),
})
