import React from 'react'
import { StyleSheet, Text, View, ViewStyle } from 'react-native'

import styles from './styles'

type Props = {
  style?: ViewStyle
  subTitle: string
  title: string
}

const Card = ({ style, subTitle, title }: Props) => (
  <View style={StyleSheet.flatten([styles.container, style])}>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.subTitle}>{subTitle}</Text>
  </View>
)

export default Card
