import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { TotalSize, TotalVariant } from './types'
import { totalSize, totalVariant } from './variants'
import styles from './styles'

type Props = {
  size: TotalSize
  text: string
  variant: TotalVariant
}

const Total = ({ size, text, variant }: Props) => {
  const isOutline = variant === totalVariant.outline
  const isSmall = size === totalSize.small
  const containerStyles = StyleSheet.flatten([styles.container, isOutline && styles.outline, isSmall && styles.small])
  const textStyle = StyleSheet.flatten([styles.text, isOutline && styles.outlineText])

  return (
    <View style={containerStyles}>
      <Text style={textStyle}>{text}</Text>
    </View>
  )
}

export default Total
