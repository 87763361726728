import React, { ElementType, useMemo, useState } from 'react'
import { View, Text, StyleSheet, TouchableWithoutFeedback, ViewStyle } from 'react-native'

import { TTypes, ButtonSize } from './types'
import { getButtonStyles } from './utils'
import { buttonSize } from './variants'
import styles, { ICON_SMALL, ICON_LARGE } from './styles'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { IconTypes } from 'src/icons/iconTypes'

export type ButtonNewProps = {
  backgroundTransparent?: boolean
  containerStyle?: ViewStyle
  disabled?: boolean
  flexBasis?: string
  isLong?: boolean
  isShort?: boolean
  isSubmitting?: boolean
  isWebUploader?: boolean
  LeftIcon?: ElementType<IconTypes>
  onPress?: () => void
  RightIcon?: ElementType<IconTypes>
  size?: ButtonSize
  style?: ViewStyle
  testID?: string
  title: string
  variant: TTypes
}

const ButtonNew = ({
  backgroundTransparent,
  containerStyle,
  disabled,
  flexBasis = 'auto',
  isLong,
  isShort,
  isSubmitting,
  isWebUploader,
  LeftIcon,
  onPress,
  RightIcon,
  size = buttonSize.lg,
  style,
  testID,
  title,
  variant,
}: ButtonNewProps) => {
  const [isPressed, setIsPressed] = useState(false)

  const buttonStyles = useMemo(() => getButtonStyles(variant), [variant])
  const isSmall = size === buttonSize.sm
  const textStyles = disabled && !isSubmitting ? buttonStyles.labelDisabled : buttonStyles.label
  const flattenTextStyles = StyleSheet.flatten([textStyles])

  const containerStyles = StyleSheet.flatten([
    styles.button,
    containerStyle,
    isSmall && styles.smallButton,
    disabled && !isSubmitting ? buttonStyles.buttonDisabled : buttonStyles.button,
    backgroundTransparent && styles.transparent,
    (isPressed || isSubmitting) && buttonStyles.buttonPressed,
  ])

  const content = (
    <>
      {!isSubmitting && LeftIcon ? (
        <View style={styles.leftIcon}>
          <LeftIcon fill={flattenTextStyles.color} size={isSmall ? ICON_SMALL : ICON_LARGE} />
        </View>
      ) : null}
      {isSubmitting && (
        <View style={styles.submittingIcon}>
          <LoadingIndicator size="small" spinnerColor={flattenTextStyles.color} />
        </View>
      )}
      <Text style={StyleSheet.flatten([styles.label, isSmall && styles.smallLabel, textStyles])}>{title}</Text>
      {RightIcon ? (
        <View style={styles.rightIcon}>
          <RightIcon fill={flattenTextStyles.color} size={isSmall ? ICON_SMALL : ICON_LARGE} />
        </View>
      ) : null}
    </>
  )

  const onHover = () => setIsPressed(true)
  const onBlur = () => setIsPressed(false)

  return (
    <View style={StyleSheet.flatten([style, isLong && styles.long, isShort && styles.short, { flexBasis: flexBasis }])}>
      {isWebUploader ? (
        <View style={containerStyles} testID={testID} onMouseOver={onHover} onMouseOut={onBlur}>
          {content}
          <input
            disabled={disabled || !onPress}
            onChange={onPress}
            // @ts-ignore
            style={StyleSheet.flatten([styles.webInputUploader])}
            type="file"
          />
        </View>
      ) : (
        <TouchableWithoutFeedback
          disabled={disabled || !onPress}
          onPress={onPress}
          onPressIn={onHover}
          onPressOut={onBlur}
          testID={testID}
        >
          <View style={containerStyles}>{content}</View>
        </TouchableWithoutFeedback>
      )}
    </View>
  )
}

export default ButtonNew
