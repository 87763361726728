import React, { useEffect, useMemo, useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'

import { getMonthOptions, getYearsOptions } from '../utils'
import { ShortDatePickerVariants } from './types'
import InputSelectNew from '../InputSelectNew/InputSelectNew'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  date?: string
  disabled?: boolean
  errorMessage?: string
  isError?: boolean
  numberOfYearsInFuture: number
  numberOfYearsInPast: number
  onlyPastDateAllowed?: boolean
  required?: boolean
  setFieldTouched: () => void
  setFieldValue: (date: string) => void
  testID?: string
  variant: ShortDatePickerVariants
  zIndex?: number
}

const isWeb = Platform.OS === 'web'

const getSelectedMonthInitialValue = (date?: string) => {
  const month = date && new Date(date).getMonth() + 1
  if (month) {
    return month < 10 ? `0${month}` : month.toString()
  }
  return ''
}

const DatePickerShort = ({
  date,
  disabled,
  errorMessage,
  isError,
  numberOfYearsInFuture,
  numberOfYearsInPast,
  onlyPastDateAllowed,
  required,
  setFieldTouched,
  setFieldValue,
  testID,
  variant,
  zIndex,
}: Props) => {
  const currentYear = new Date().getFullYear().toString()
  const currentMonth = new Date().getMonth() + 1

  const [selectedYear, setSelectedYear] = useState(date ? new Date(date).getFullYear().toString() : '')
  const [selectedMonth, setSelectedMonth] = useState(getSelectedMonthInitialValue(date))
  const [isYearTouched, setIsYearTouched] = useState(false)
  const [isMonthTouched, setIsMonthTouched] = useState(false)

  const isStartDate = variant === 'startDate'

  useEffect(() => {
    setSelectedYear(date ? new Date(date).getFullYear().toString() : '')
    setSelectedMonth(getSelectedMonthInitialValue(date))
  }, [date])

  const yearsOptions = useMemo(() => {
    const disableCurrentYear = onlyPastDateAllowed && selectedMonth && Number(selectedMonth) > currentMonth
    const maxYearsInFuture = disableCurrentYear ? numberOfYearsInFuture - 1 : numberOfYearsInFuture
    return getYearsOptions(numberOfYearsInPast, maxYearsInFuture)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedYear])

  const monthOptions = useMemo(() => {
    const disableMonths = onlyPastDateAllowed && numberOfYearsInFuture === 0 && selectedYear === currentYear
    const maxMonth = disableMonths ? currentMonth : 12
    return getMonthOptions(maxMonth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    if (!!selectedYear && !!selectedMonth) {
      const day = isStartDate ? '01' : '20'

      setFieldValue(`${selectedYear}-${selectedMonth}-${day}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedMonth])

  useEffect(() => {
    if (isYearTouched && isMonthTouched) {
      setFieldTouched()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYearTouched, isMonthTouched])

  return (
    <>
      <Text style={styles.title}>{translate(isStartDate ? translations.startDate : translations.endDate)}</Text>
      <View style={StyleSheet.flatten([styles.inputContainer, isWeb && { zIndex, ...styles.inputContainerWeb }])}>
        <View style={styles.input}>
          <InputSelectNew
            disabled={disabled}
            isError={isError}
            label={translate(translations.year)}
            onBlur={() => setIsYearTouched(true)}
            onValueChange={setSelectedYear}
            required={required}
            testID={`${testID}-year`}
            value={selectedYear}
            values={yearsOptions}
          />
        </View>
        <View style={styles.divider} />
        <View style={styles.input}>
          <InputSelectNew
            disabled={disabled}
            isError={isError}
            label={translate(translations.month)}
            onBlur={() => setIsMonthTouched(true)}
            onValueChange={setSelectedMonth}
            required={required}
            testID={`${testID}-month`}
            value={selectedMonth}
            values={monthOptions}
          />
        </View>
      </View>
      {isError ? (
        <Text style={styles.error} testID={`${testID}-error`}>
          {errorMessage}
        </Text>
      ) : null}
    </>
  )
}

export default DatePickerShort
