import React, { useState } from 'react'
import { View, StyleSheet, TextInput } from 'react-native'
import { ComposerProps } from 'react-native-gifted-chat'
import { MIN_COMPOSER_HEIGHT, DEFAULT_PLACEHOLDER } from 'react-native-gifted-chat/lib/Constant'
import Color from 'react-native-gifted-chat/lib/Color'

import styles, { TEXT_INPUT_PADDING, MAX_HEIGHT } from './styles'

const CustomComposer = (props: ComposerProps) => {
  const {
    composerHeight = MIN_COMPOSER_HEIGHT,
    disableComposer = false,
    keyboardAppearance = 'default',
    multiline = true,
    onInputSizeChanged = () => {},
    onTextChanged = () => {},
    placeholder = DEFAULT_PLACEHOLDER,
    placeholderTextColor = Color.defaultColor,
    text = '',
    textInputAutoFocus = false,
    textInputProps = null,
    textInputStyle = {},
  } = props

  const [contentHeight, setContentHeight] = useState(0)

  const onContentSizeChange = (e: any) => {
    const { contentSize } = e.nativeEvent
    if (!contentSize) {
      return
    }
    if (contentHeight !== contentSize.height && contentSize.height < MAX_HEIGHT) {
      setContentHeight(contentSize.height)
      onInputSizeChanged(contentSize)
    }
  }

  const onChangeText = (text: string) => onTextChanged(text)

  return (
    <View
      style={StyleSheet.flatten([
        styles.textInputContainer,
        {
          height: contentHeight + TEXT_INPUT_PADDING * 2,
        },
      ])}
    >
      <TextInput
        accessibilityLabel={placeholder}
        accessible
        autoFocus={textInputAutoFocus}
        editable={!disableComposer}
        enablesReturnKeyAutomatically
        keyboardAppearance={keyboardAppearance}
        multiline={multiline}
        onChangeText={onChangeText}
        onContentSizeChange={onContentSizeChange}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        style={styles.textInput}
        testID={placeholder}
        textBreakStrategy="highQuality"
        underlineColorAndroid="transparent"
        value={text}
        {...textInputProps}
      />
    </View>
  )
}

export default CustomComposer
