import { ScrollView, View } from 'react-native'
import React, { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@apollo/client'

import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import LoadingIndicator from 'src/components/LoadingIndicator'
import UserAddressesQuery from 'src/graphql/User/Addresses'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { colors } from 'src/styles'
import { OtherAddress, UserAddressRequestType } from 'src/graphql/User/types'
import AddressRow from './components/AddressRow'
import AddressMap from './components/AddressMap'
import { updateAddresses } from 'src/store/addresses/actions'
import { useAppSelector } from 'src/hooks/reduxHooks'
import globalStyles from 'src/global/globalStyles'

type Props = {
  navigation: StackNavigationProp<any>
}

export const Area = ({ navigation }: Props) => {
  const userId = useAppSelector(state => state?.user?.id) as string

  useBugsnagView('Area')

  const { loading, error, data, refetch } = useQuery<UserAddressRequestType>(UserAddressesQuery, {
    variables: {
      id: userId,
    },
    fetchPolicy: 'cache-and-network',
  })

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const user = data?.user
  const otherAddresses = user?.otherAddresses || []

  const navigateToEdit = (address: OtherAddress) => () => {
    bugsnagActionBreadcrumb('go to AddressForm in edit')
    navigation.navigate('AddressForm', { user, isEdit: true, address })
  }

  const updateGeoSearchRadius = async (
    geoSearchRadius: number,
    addressId: string,
    setIsLoadingNumberOfClients: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    let updatedOtherAddresses = []

    updatedOtherAddresses = otherAddresses.map(address =>
      address.id === addressId ? { ...address, geoSearchRadius } : address,
    )

    await updateAddresses({ otherAddresses: updatedOtherAddresses }, userId)
    refetch && refetch()
    setIsLoadingNumberOfClients(false)
  }

  if (loading) {
    return <LoadingIndicator style={{ backgroundColor: colors.white }} />
  }

  if (!data && error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  return (
    <ScrollView contentContainerStyle={globalStyles.containerSize} testID="scrollView">
      {otherAddresses.map(address => (
        <View key={address.id}>
          <AddressRow {...address} navigateToEdit={navigateToEdit(address)} />
          <AddressMap
            lng={address.coordinates[0]}
            lat={address.coordinates[1]}
            radius={address.geoSearchRadius}
            nearbySchoolCount={address.nearbySchoolCount}
            updateGeoSearchRadius={(geoSearchRadius, setIsLoadingNumberOfClients) =>
              updateGeoSearchRadius(geoSearchRadius, address.id, setIsLoadingNumberOfClients)
            }
          />
        </View>
      ))}
    </ScrollView>
  )
}

export default Area
