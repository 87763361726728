import { StyleSheet } from 'react-native'

const COLUMN_GAP = 17

export default StyleSheet.create({
  container: {
    columnGap: COLUMN_GAP,
    flexDirection: 'row',
  },
})
