import React, { ReactElement, useState } from 'react'
import { View, Text, Image, ActivityIndicator } from 'react-native'

import images from 'src/global/images'
import translations, { translate } from 'src/utils/translations/translations'
import { AssignmentIndIcon, CalendarIcon, CoffeIcon, PaymentsIcon, ScheduleIcon, SuitcaseIcon } from 'src/icons'

import styles from './styles'
import { colorsNew } from 'src/styles'
import { BookingTimesheetTypes } from 'src/graphql/Booking'

type Props = {
  bookingDate: string
  bookingSalary: string
  bookingTime: string
  breakTime?: number
  isBreakTimeModified: boolean
  isHourly: boolean
  isTimeModified: boolean
  pupil: BookingTimesheetTypes['pupil']
  school: BookingTimesheetTypes['school']
  teacherRole: string
}

const ReportInfo = ({
  bookingDate,
  bookingSalary,
  bookingTime,
  breakTime,
  isBreakTimeModified,
  isHourly,
  isTimeModified,
  pupil,
  school,
  teacherRole,
}: Props) => {
  const [showPictureLoader, setShowPictureLoader] = useState(true)
  const pupilInfo = pupil?.firstName ? `${pupil.firstName} ${pupil.lastName}` : null

  const InfoRows = [
    {
      icon: <SuitcaseIcon size={22} />,
      description: teacherRole,
    },
    pupilInfo && {
      icon: <AssignmentIndIcon size={23} />,
      description: pupilInfo,
    },
    {
      icon: <CalendarIcon size={22} />,
      description: bookingDate,
    },
    {
      icon: <ScheduleIcon size={22} />,
      description: bookingTime,
      isModified: isTimeModified,
    },
    {
      icon: <PaymentsIcon size={22} />,
      description: bookingSalary,
    },
    isHourly && {
      icon: <CoffeIcon size={22} />,
      description:
        breakTime && breakTime > 0
          ? translate(translations.breakTime, { breakTime: breakTime })
          : translate(translations.withoutBreakTime),
      isModified: isBreakTimeModified,
    },
  ].filter(Boolean) as { icon: ReactElement; description: string; isModified?: boolean }[]

  const schoolImageSource = school.profilePictureUrl ? { uri: school.profilePictureUrl } : images.schoolPlaceholder

  return (
    <>
      <Image onLoad={() => setShowPictureLoader(false)} source={schoolImageSource} style={styles.backgroundImage} />
      {showPictureLoader ? (
        <View style={[styles.backgroundImage, styles.loader]}>
          <ActivityIndicator color={colorsNew.brand.secondary} size="small" />
        </View>
      ) : null}

      <View style={styles.section}>
        <Text style={styles.title}>{school.name}</Text>
        {InfoRows.map((row, index) => (
          <View key={index} style={styles.infoRowContainer}>
            {row.icon}
            <View style={styles.infoRowBody}>
              <Text style={styles.infoRowDescription}>{row.description}</Text>
              {row.isModified ? (
                <Text style={styles.infoRowComment}>{translate(translations.reportTimeModified)}</Text>
              ) : null}
            </View>
          </View>
        ))}
      </View>
    </>
  )
}

export default ReportInfo
