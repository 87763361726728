import { StyleSheet } from 'react-native'

import { typography, colorsNew, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: paddingNew.sm,
  },
  info: {
    marginLeft: paddingNew.sm,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginVertical: paddingNew.xxs,
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  subTitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginVertical: paddingNew.xxs,
  },
  subTitleSpacing: {
    color: colorsNew.redesign.text.secondary,
    marginVertical: paddingNew.xs,
  },
  detailsContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default styles
