import moment from 'moment-timezone'
import { weekdays } from 'moment'

import { LocaleT } from 'src/utils/translations/i18nTypes'

const openingHours = {
  startTime: {
    se: 6,
    gb: 7,
    seFriday: 6,
  },
  endTime: {
    se: 18,
    gb: 18,
    seFriday: 17,
  },
}

const timezone = {
  se: 'Europe/Stockholm',
  gb: 'Europe/London',
}

const timeFormat = {
  short: {
    gb: 'h A',
    se: 'H',
  },
  standard: {
    gb: 'h:mm A',
    se: 'H:mm',
  },
}

const SE = 'se'
const GB = 'gb'

const isSE = (countryCode: LocaleT) => countryCode === SE

// 0-Sunday | 1-Monday | ... | 6-Saturday
const isNotWeekend = () => moment().day() !== 6 && moment().day() !== 0
const isFriday = () => moment().day() === 5

export const isSupportOfficeOpen = (countryCode: LocaleT) => {
  const isOpeningHour = () => {
    if (isFriday() && isSE(countryCode)) {
      return (
        moment().tz(timezone[countryCode]).hour() >= openingHours.startTime.seFriday &&
        moment().tz(timezone[countryCode]).hour() < openingHours.endTime.seFriday
      )
    }

    return (
      moment().tz(timezone[countryCode]).hour() >= openingHours.startTime[countryCode] &&
      moment().tz(timezone[countryCode]).hour() < openingHours.endTime[countryCode]
    )
  }

  return isNotWeekend() && isOpeningHour()
}

const displayHour = (hour: number, countryCode: LocaleT, isShort?: boolean) => {
  const formatType = isShort ? timeFormat.short[countryCode] : timeFormat.standard[countryCode]
  return moment().hour(hour).minute(0).format(formatType)
}

export const workingHoursShort = (countryCode: LocaleT, isShort?: boolean) => {
  const start = openingHours.startTime[countryCode]
  const end = isFriday() && isSE(countryCode) ? openingHours.endTime.seFriday : openingHours.endTime[countryCode]
  return `${displayHour(start, countryCode, isShort)} - ${displayHour(end, countryCode, isShort)}`
}

export const workingHours = {
  se: `${weekdays(1)}-${weekdays(4)} ${displayHour(openingHours.startTime.se, SE)} - ${displayHour(
    openingHours.endTime.se,
    SE,
  )}, ${weekdays(5)} ${displayHour(openingHours.startTime.seFriday, SE)} - ${displayHour(
    openingHours.endTime.seFriday,
    SE,
  )}`,
  gb: `${weekdays(1)}-${weekdays(5)} ${displayHour(openingHours.startTime.gb, GB)} - ${displayHour(
    openingHours.endTime.gb,
    GB,
  )}`,
}
