import background from 'src/assets/images/background.png'
import backgroundDark from 'src/assets/images/background-dark.png'
import referralAnnouncementGB from 'src/assets/images/referralAnnouncementGB.png'

import support from 'src/assets/images/support.jpg'
import step1 from 'src/assets/images/stepsGuideGB/step1.png'
import step2 from '../assets/images/stepsGuideGB/step2.png'
import step3 from '../assets/images/stepsGuideGB/step3.png'
import step4 from '../assets/images/stepsGuideGB/step4.png'
import step5 from '../assets/images/stepsGuideGB/step5.png'
import step6 from '../assets/images/stepsGuideGB/step6.png'
import step7 from '../assets/images/stepsGuideGB/step7.png'
import step8 from '../assets/images/stepsGuideGB/step8.png'
import step9 from '../assets/images/stepsGuideGB/step9.png'
import step10 from '../assets/images/stepsGuideGB/step10.png'
import step11 from '../assets/images/stepsGuideGB/step11.png'
import step12 from '../assets/images/stepsGuideGB/step12.png'
import step13 from '../assets/images/stepsGuideGB/step13.png'
import step14 from '../assets/images/stepsGuideGB/step14.png'
import step15 from '../assets/images/stepsGuideGB/step15.png'
import step16 from '../assets/images/stepsGuideGB/step16.png'
import step17 from '../assets/images/stepsGuideGB/step17.png'

export default {
  background,
  backgroundDark,
  referralAnnouncementGB,
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
  step12,
  step13,
  step14,
  step15,
  step16,
  step17,
  support,
}
