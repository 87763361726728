import { StyleSheet } from 'react-native'

import { typography, colorsNew, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    marginBottom: paddingNew.lg,
    marginHorizontal: 24,
    marginTop: paddingNew.base,
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.other.divider,
    borderRadius: 2,
    height: 4,
    marginBottom: paddingNew.md,
    width: 80,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: paddingNew.xs,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginLeft: paddingNew.xs,
  },
  subTitle: {
    ...typography.caption,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.base,
  },
  bookingRow: {
    alignItems: 'center',
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: paddingNew.xs,
    paddingVertical: paddingNew.xxs,
    marginHorizontal: paddingNew.sm,
  },
  dayText: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  timeText: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.secondary,
  },
  scroll: {
    marginBottom: paddingNew.base,
  },
  button: { alignItems: 'center' },
})

export default styles
