import React from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'

type Props = {
  isVisible: boolean
  closeWithdrawModal: () => void
  onWithdraw: () => void
}

const WithdrawModal = ({ isVisible, closeWithdrawModal, onWithdraw }: Props) => (
  <CustomModal
    buttons={[
      {
        onPress: closeWithdrawModal,
        title: translate(translations.cancel),
        variant: buttonVariants.outlinedDefault,
      },
      {
        onPress: onWithdraw,
        title: translate(translations.jobOfferWithdrawButton),
        testID: 'withdraw-confirmation-button',
        variant: buttonVariants.containedWarning,
      },
    ]}
    colorSchema={customModalColorSchema.warning}
    isVisible={isVisible}
    modalPlace="Main/Jobs/Details/components/WithdrawModal/WithdrawModal"
    onClose={closeWithdrawModal}
    title={translate(translations.jobOfferWithdrawConfirmation)}
    variant={customModalVariants.filled}
  />
)

export default WithdrawModal
