import { StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  reportText: {
    color: colorsNew.black,
    fontFamily: fonts.poppinsSemiBold,
    fontSize: fontSizesNew.md.size,
    lineHeight: fontSizesNew.md.lh,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.base,
    paddingHorizontal: paddingNew.base,
    textAlign: 'center',
  },
  reportSubtitle: {
    color: colorsNew.grey._600,
    fontFamily: fonts.poppinsRegular,
    fontSize: fontSizesNew.xxxs.size,
    lineHeight: fontSizesNew.xxxs.lh,
    paddingHorizontal: paddingNew.base,
    textAlign: 'center',
  },
  buttonContainer: {
    flex: 1,
    marginBottom: paddingNew.lg,
    marginTop: paddingNew.lg,
    paddingHorizontal: paddingNew.lg,
  },
})

export default styles
