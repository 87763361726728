import React from 'react'
import { ScrollView, Text, View } from 'react-native'

import { isUserInvitedToRefensa } from 'src/store/user/selectors'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import StatusInfo from 'src/scenes/Main/Profile/RequiredTasks/components/StatusInfo'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'References'>

const ReferencesSv = ({ route }: Props) => {
  const { status, timeToComplete } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - ReferencesSv')

  const isInvited = useAppSelector(isUserInvitedToRefensa)

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo status={status} timeToComplete={timeToComplete} />
      <View style={styles.wrapper}>
        {isCompleted ? (
          <>
            <Text style={styles.header}>{translate(translations.referencesCompletedHeader)}</Text>
            <Text style={styles.mainInfo}>{translate(translations.referencesCompleted1)}</Text>
            <Text style={styles.mainInfo}>{translate(translations.referencesCompleted2)}</Text>
          </>
        ) : (
          <>
            {isInvited ? <Text style={styles.header}>{translate(translations.referencesInvitedHeader)}</Text> : null}
            {isInvited ? (
              <Text style={styles.mainInfo}>{translate(translations.referencesUncompletedInvited)}</Text>
            ) : (
              <>
                <Text style={styles.mainInfo}>{translate(translations.referencesUncompleted1)}</Text>
                <Text style={styles.mainInfo}>{translate(translations.referencesUncompleted2)}</Text>
              </>
            )}
            {isInvited ? <Text style={styles.subInfo}>{translate(translations.referencesInvitedInfo)}</Text> : null}
            <View style={styles.alertWrapper}>
              <AlertCard
                colorSchema={alertColorScheme.info}
                description={translate(translations.referencesUncompletedAlert)}
                variant={alertVariants.outlined}
              />
            </View>
          </>
        )}
      </View>
    </ScrollView>
  )
}

export default ReferencesSv
