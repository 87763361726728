import React, { useEffect, useRef, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { countryCodeSelector } from 'src/store/app/selectors'
import { openURLIfCan } from 'src/global/utils'
import { policyURL } from 'src/global/constants'
import { removeAccount } from 'src/store/user/actions'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const DeleteAccount = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const timeout = useRef<NodeJS.Timeout>()

  const navigation = useNavigation()
  const countryCode = useAppSelector(countryCodeSelector)

  useBugsnagView('Profile - DeleteAccount')

  const waitFor = () =>
    new Promise(resolve => {
      timeout.current = setTimeout(resolve, 2000)
    })

  useEffect(() => {
    return () => timeout.current && clearTimeout(timeout.current)
  }, [])

  const handleOpenPolicy = () => openURLIfCan(policyURL[countryCode])

  const onRemoveAccount = async () => {
    bugsnagActionBreadcrumb('onRemoveAccount')
    setIsDeleting(true)

    await removeAccount()
    await waitFor()

    bugsnagActionBreadcrumb('go to ProfileDetails from DeletingAccount')
    navigation.navigate('ProfileDetails')
  }

  return (
    <ScrollView
      contentContainerStyle={globalStyles.containerSize}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <View style={styles.container}>
        <Text style={styles.text}>{translate(translations.deleteAccountDescription)}</Text>
        <ButtonNew
          containerStyle={styles.button}
          disabled={isDeleting}
          isShort
          onPress={handleOpenPolicy}
          RightIcon={ChevronRightIcon}
          size={buttonSize.sm}
          title={translate(translations.dataPolicy)}
          variant={buttonVariants.outlinedDefault}
        />
        <ButtonNew
          isShort
          containerStyle={styles.button}
          isSubmitting={isDeleting}
          onPress={onRemoveAccount}
          size={buttonSize.lg}
          title={translate(translations.deleteAccountButton)}
          variant={buttonVariants.outlinedError}
        />
      </View>
    </ScrollView>
  )
}

export default DeleteAccount
