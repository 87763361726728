import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Platform, View } from 'react-native'

import { BellOnIcon, LockIcon, DeleteFilledIcon } from 'src/icons'
import { MenuNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import RedirectRow from 'src/components/RedirectRow'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

const isWeb = Platform.OS === 'web'

const SettingsSection = () => {
  const navigation = useNavigation<MenuNavigatorScreensProps<'ProfileDetails'>['navigation']>()
  const settingOptions = [
    {
      hidden: false,
      Icon: LockIcon,
      onPress: () => navigation.navigate('ChangePassword'),
      testId: 'change-password',
      title: translate(translations.changePassword),
    },
    {
      hidden: isWeb,
      Icon: BellOnIcon,
      onPress: () => navigation.navigate('PushNotifications'),
      testId: 'push-notifications',
      title: translate(translations.pushNotificationsSettings),
    },
    {
      hidden: false,
      Icon: DeleteFilledIcon,
      onPress: () => navigation.navigate('DeleteAccount'),
      testId: 'delete-account',
      title: translate(translations.deleteAccount),
    },
  ]

  return (
    <View style={styles.container}>
      {settingOptions.map(({ hidden, Icon, onPress, testId, title }) =>
        !hidden ? <RedirectRow Icon={Icon} key={testId} onPress={onPress} testId={testId} title={title} /> : null,
      )}
    </View>
  )
}

export default SettingsSection
