import { StyleSheet } from 'react-native'
import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.sm,
  },
  button: { marginVertical: 0 },
  buttonWrapper: {
    alignItems: 'center',
  },
  checkboxWrapper: {
    marginBottom: paddingNew.md,
    marginTop: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.md,
    marginTop: paddingNew.sm,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  helperInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.xs,
  },
  info: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  sectionContainer: {
    marginBottom: paddingNew.sm,
  },
  spacing: {
    height: paddingNew.sm,
  },
  subHeader: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  subInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.sm,
  },
  textWithDot: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginBottom: 0,
  },
  textWithDotContainer: {
    padding: paddingNew.sm,
  },
  textWithDotColor: {
    backgroundColor: colorsNew.redesign.primary.shades12p,
  },
  question: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
