import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const FreeBreakfastRounded = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M20 3H6C4.9 3 4 3.9 4 5V13C4 15.21 5.79 17 8 17H14C16.21 17 18 15.21 18 13V10H20C21.11 10 22 9.1 22 8V5C22 3.9 21.1 3 20 3ZM20 8H18V5H20V8ZM5 19H19C19.55 19 20 19.45 20 20C20 20.55 19.55 21 19 21H5C4.45 21 4 20.55 4 20C4 19.45 4.45 19 5 19Z" />
  </Svg>
)

export default FreeBreakfastRounded
