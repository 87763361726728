import React, { useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { useQuery } from '@apollo/client'

import { capitalizeFirstLetter, groupBy, momentWithTimezone } from 'src/global/utils'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import EmptyView from 'src/components/EmptyView'
import formatter from 'src/utils/formatter'
import LoadingIndicator from 'src/components/LoadingIndicator'
import Monthly from 'src/scenes/Main/Salary/History/components/ListItem/Monthly'
import styles from './styles'
import SummaryRequest, { BookingsSummaryRequestTypes, BookingSummaryTypes } from 'src/graphql/Booking/Summary'
import translations, { translate } from 'src/utils/translations/translations'

const MonthStatsList = () => {
  const [minDate, setMinDate] = useState<string>(
    formatter.apiFormat(momentWithTimezone().subtract(10, 'months').startOf('month').toDate()),
  )
  const [bookingsGroupByMonths, setBookingsGroupByMonths] = useState<Map<string, BookingSummaryTypes[]>>()
  const [isEndOfList, setIsEndOfList] = useState(false)
  const [loadingMonths, setLoadingMonths] = useState(false)
  const [isError, setIsError] = useState(false)

  const createdAt = useAppSelector(state => state?.user?.attributes?.createdAt) || ''

  const { loading, data, refetch } = useQuery<BookingsSummaryRequestTypes>(SummaryRequest, {
    fetchPolicy: 'network-only',
    variables: {
      bookingType: 'appointed',
      maxDate: formatter.apiFormat(momentWithTimezone().toDate()),
      minDate,
      skipCancelled: true,
      sort: '-startTime',
    },
    onError: () => setIsError(true),
  })

  useEffect(() => {
    const newMinDate = formatter.apiFormat(momentWithTimezone().subtract(10, 'months').startOf('month').toDate())

    if (momentWithTimezone(newMinDate).isSameOrBefore(momentWithTimezone(createdAt))) {
      setMinDate(createdAt)
      setIsEndOfList(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeDuplicateIds = (nodes: BookingSummaryTypes[]) => {
    const uniqueNodes = new Map<string, BookingSummaryTypes>()
    nodes.forEach(node => {
      uniqueNodes.set(node.id, node)
    })
    return Array.from(uniqueNodes.values())
  }

  const updateBookingsGroupByMonths = (newData: BookingSummaryTypes[]) => {
    const newBookingsGroup = groupBy(newData, booking => formatter.monthWithYear(booking.startTime))

    const updatedBookingsGroup = new Map<string, BookingSummaryTypes[]>()

    newBookingsGroup.forEach((nodes, monthYear) => {
      const uniqueNodes = removeDuplicateIds(nodes)
      const sortedNodes = uniqueNodes.sort((a, b) =>
        momentWithTimezone(b.startTime).diff(momentWithTimezone(a.startTime)),
      )
      updatedBookingsGroup.set(monthYear, sortedNodes)
    })
    const allBookings = Array.from(updatedBookingsGroup.values()).reduce((acc, curr) => acc.concat(curr), [])
    const sortedAllBookings = allBookings.sort((a, b) =>
      momentWithTimezone(b.startTime).diff(momentWithTimezone(a.startTime)),
    )
    setBookingsGroupByMonths(groupBy(sortedAllBookings, booking => formatter.monthWithYear(booking.startTime)))
  }

  useEffect(() => {
    if (!data) return
    if (data.bookings.totalCount === 0) {
      setIsEndOfList(true)
      setMinDate(createdAt)
      return
    }

    updateBookingsGroupByMonths(data.bookings.nodes)
    setLoadingMonths(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const showMoreMonths = () => {
    setLoadingMonths(true)
    const newMinDate = formatter.apiFormat(momentWithTimezone(minDate).subtract(10, 'months').startOf('month').toDate())
    if (momentWithTimezone(newMinDate).isSameOrBefore(momentWithTimezone(createdAt))) {
      setIsEndOfList(true)
      setMinDate(createdAt)
    } else setMinDate(newMinDate)

    refetch()
  }

  const isCompletedJobs = data?.bookings?.totalCount && data.bookings.totalCount > 0

  return (
    <View style={styles.container}>
      {loading ? (
        <View style={styles.containerPadding}>
          <LoadingIndicator fullScreen={false} style={styles.loading} />
        </View>
      ) : (
        <>
          {isError ? (
            <View style={styles.containerPadding}>
              <View style={styles.alertWrapper}>
                <AlertCard
                  colorSchema={alertColorScheme.error}
                  description={translate(translations.serverError)}
                  onClose={() => setIsError(false)}
                  testId={'get-completed-jobs-error-alert'}
                  title={translate(translations.serverErrorTitle)}
                  variant={alertVariants.standard}
                />
              </View>
            </View>
          ) : null}
          {isCompletedJobs ? (
            <>
              <View style={styles.containerPadding}>
                <Text style={styles.title}>{translate(translations.allYourCompletedJobs)}</Text>
              </View>
              {bookingsGroupByMonths &&
                [...bookingsGroupByMonths].map(([key, value], index) => (
                  <Monthly title={capitalizeFirstLetter(key)} data={value} index={index} key={key} />
                ))}
              {isCompletedJobs && (
                <View style={styles.containerPadding}>
                  <Text style={styles.info}>
                    {translate(translations.completedJobsPerMonthSince, { date: formatter.monthWithYear(minDate) })}
                  </Text>
                </View>
              )}
              {!isEndOfList && (
                <View style={styles.containerPadding}>
                  <View style={styles.showMoreMonths}>
                    <ButtonNew
                      isShort
                      isSubmitting={loading || loadingMonths}
                      onPress={showMoreMonths}
                      size={buttonSize.lg}
                      title={translate(translations.showMoreMonths)}
                      variant={buttonVariants.outlinedDefault}
                    />
                  </View>
                </View>
              )}
            </>
          ) : (
            <View style={styles.containerPadding}>
              <EmptyView
                description={translate(translations.historyEmptyDescription)}
                title={translate(translations.historyEmpty)}
                withButton
              />
            </View>
          )}
        </>
      )}
    </View>
  )
}

export default MonthStatsList
