import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  actionInfo: {
    ...typography.tooltip,
    color: colorsNew.redesign.text.secondary,
    marginTop: paddingNew.xxs,
  },
  chipWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    width: '50%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 44,
    justifyContent: 'space-between',
  },
  info: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  subInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles
