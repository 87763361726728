import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const CoffeIcon = ({ fill = '#212121', size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M18.5 3.35864H6C4.9 3.35864 4 4.25864 4 5.35864V11.0686C4 14.8986 6.95 18.2486 10.78 18.3586C14.74 18.4786 18 15.2986 18 11.3586V10.3586H18.5C20.43 10.3586 22 8.78864 22 6.85864C22 4.92864 20.43 3.35864 18.5 3.35864ZM16 5.35864V8.35864H6V5.35864H16ZM18.5 8.35864H18V5.35864H18.5C19.33 5.35864 20 6.02864 20 6.85864C20 7.68864 19.33 8.35864 18.5 8.35864ZM5 19.3586H19C19.55 19.3586 20 19.8086 20 20.3586C20 20.9086 19.55 21.3586 19 21.3586H5C4.45 21.3586 4 20.9086 4 20.3586C4 19.8086 4.45 19.3586 5 19.3586Z"
      fill={fill}
    />
  </Svg>
)

export default CoffeIcon
