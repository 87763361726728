import { StyleSheet } from 'react-native'
import { common, fonts, padding, paddingNew } from 'src/styles'

export default StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginVertical: padding.xs,
  },
  bullet: {
    fontSize: 25,
    lineHeight: 25,
    marginTop: -4,
  },
  bulletText: {
    justifyContent: 'center',
    flex: 1,
  },
  index: {
    marginLeft: paddingNew.xs,
  },
  paragraph: StyleSheet.flatten([
    common.mediumText,
    {
      fontFamily: fonts.muktaRegular,
      marginBottom: padding.xs,
    },
  ]),
})
