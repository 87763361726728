import React, { useLayoutEffect, useState } from 'react'
import { Platform, ScrollView, View, Text } from 'react-native'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'

import LoadingIndicator from 'src/components/LoadingIndicator'
import ReportActions from './components/ReportActions'
import ReportInfo from './components/ReportInfo'
import ReportSummaryInfo from './components/ReportSummaryInfo'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import ReportModal from 'src/scenes/Main/Salary/ReportModal'
import useTimesheetReport, { reportTypes } from 'src/hooks/useTimesheetReport'
import translations, { translate } from 'src/utils/translations/translations'
import { reportWorkingTime } from 'src/store/timelog/actions'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type NavigationTypes = {
  SuccessAnimation: {
    id: string
    isFeedbackSubmitted: boolean
  }
}

const Report = () => {
  const { params } = useRoute<RouteProp<{ params: { id: string } }>>()
  const navigation = useNavigation<StackNavigationProp<NavigationTypes>>()

  const timesheetReport = useTimesheetReport(params?.id) as reportTypes

  const [reportModalOpen, setReportModalOpen] = useState(false)

  const getScreenHeader = () => {
    if (timesheetReport.isSummary === undefined) {
      return ''
    }

    return timesheetReport.isSummary
      ? translate(translations.completedTimesheet)
      : translate(translations.completeYourTimesheet)
  }

  useBugsnagView('Report')

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle:
        Platform.OS === 'web' ? getScreenHeader() : () => <Text style={styles.headerTitle}>{getScreenHeader()}</Text>,
    })
  })

  if (timesheetReport.loading) {
    return <LoadingIndicator />
  }

  if (timesheetReport.error) {
    return <FetchingDataErrorModal errorToReport={timesheetReport.error} />
  }

  const {
    bookingDate,
    bookingEndTime,
    bookingSalary,
    bookingScheduleType,
    bookingStartTime,
    bookingTime,
    breakTime,
    cancelled,
    feedbackSubmitted,
    isBreakTimeModified,
    isHourly,
    isSummary,
    isTimeModified,
    pupil,
    refetch,
    reportInitialValues,
    school,
    teacherRole,
    timelogEditable,
  } = timesheetReport as reportTypes

  const onReportSuccess = () => {
    bugsnagActionBreadcrumb('report success')
    setReportModalOpen(false)
    refetch()
    navigation.navigate('SuccessAnimation', {
      id: params?.id,
      isFeedbackSubmitted: feedbackSubmitted,
    })
  }

  const sendReport = () => {
    bugsnagActionBreadcrumb('send report')

    return reportWorkingTime(params?.id, reportInitialValues, onReportSuccess)
  }

  return (
    <View style={styles.mainContainer}>
      <ScrollView
        contentContainerStyle={{ ...styles.screenContainer, ...globalStyles.containerSize }}
        showsVerticalScrollIndicator={false}
        testID="scrollView"
      >
        <ReportInfo
          bookingDate={bookingDate}
          bookingSalary={bookingSalary}
          bookingTime={bookingTime}
          breakTime={breakTime}
          isBreakTimeModified={isBreakTimeModified}
          isHourly={isHourly}
          isTimeModified={isTimeModified}
          pupil={pupil}
          school={school}
          teacherRole={teacherRole}
        />
        {isSummary ? (
          <ReportSummaryInfo
            cancelled={cancelled}
            editable={timelogEditable}
            editWorkingTime={() => setReportModalOpen(true)}
            feedback={feedbackSubmitted}
            id={params.id}
            schoolType={school?.level?.group}
          />
        ) : (
          <ReportActions onEditPress={() => setReportModalOpen(true)} onReportPress={sendReport} />
        )}
      </ScrollView>
      <ReportModal
        bookingEndTime={bookingEndTime}
        bookingStartTime={bookingStartTime}
        bookingId={params?.id}
        bookingScheduleType={bookingScheduleType}
        closeModal={() => setReportModalOpen(false)}
        initialValues={reportInitialValues}
        isHourly={isHourly}
        showModal={reportModalOpen}
        onReportSuccess={onReportSuccess}
      />
    </View>
  )
}

export default Report
