import { StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, paddingNew, typography } from 'src/styles'

export const DELETE_BUTTON_WIDTH = 100

export default StyleSheet.create({
  row: {
    alignItems: 'center',
    backgroundColor: colorsNew.translucentHighlight,
    borderBottomColor: colorsNew.grey._200,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: paddingNew.sm,
  },
  middleSection: {
    flex: 5,
  },
  title: {
    color: colorsNew.black,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
    marginBottom: paddingNew.xs,
  },
  info: {
    color: colorsNew.black,
    fontSize: fontSizesNew.xxs.size,
    lineHeight: fontSizesNew.xxs.lh,
  },
  icon: {
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: paddingNew.xs,
  },
  readMore: {
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: paddingNew.xs,
  },
  time: {
    color: colorsNew.black,
    fontSize: fontSizesNew.xxxs.size,
    lineHeight: fontSizesNew.xxxs.lh,
  },
  isRead: {
    backgroundColor: colorsNew.white,
  },
  ellipsis: {
    paddingHorizontal: paddingNew.xs,
    paddingVertical: paddingNew.xs,
  },
  deleteButton: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.error.main,
    flex: 1,
    justifyContent: 'center',
  },
  deleteText: {
    ...typography.buttonLarge,
    color: colorsNew.redesign.secondary.contrast,
  },
  deleteButtonContainer: { width: DELETE_BUTTON_WIDTH },
})
