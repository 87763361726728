import gql from 'graphql-tag'

import HomeAddress from '../HomeAddress'

export default gql`
  fragment BookingFields on Booking {
    _durationMinutes
    appointedAt
    appointedById
    appointmentType
    appointmentUnconfirmed
    arrivalTime
    assignmentInfo
    cancelled
    competences {
      id
      name
      type
    }
    contactPeople {
      name
      phone
    }
    department
    departmentObject {
      id
      name
    }
    departureTime
    displayInfo
    endTime
    errors
    feedbackSubmitted
    flexibleStartTime
    files {
      name
      url
    }
    holidayPayBaseRate
    holidayPayRate
    hasHealthRisks
    healthRisksDescription
    id
    isMatching
    jobTitle
    klassName
    level {
      title
      group
      id
    }
    locked
    lunchPrice
    originalTeacher
    overtimeReason
    overtimeRequestedBy
    pinCodeRedeemedBy
    preparationTime
    pupil {
      description
      firstName
      lastName
      files {
        name
        url
      }
    }
    region {
      countryCode
    }
    rejected
    reportedTimeScheduleType
    reportedUnpaidTime
    requestedSubs {
      id
    }
    sickleaves {
      id
    }
    responsibleName
    salaryPayedAt
    salaryRate
    school {
      address
      bookingInfo
      description
      files {
        id
        name
        url
      }
      homeAddress {
        ...HomeAddressFields
      }
      id
      name
      processArrival
      processHandover
      processOther
      profilePictureUrl
      type
    }
    schoolJobSelectionExpiredAt
    startTime
    studentId
    studentApplications {
      candidateRejection {
        rejectedAt
        rejectedById
      }
      claimedAt
      id
      studentId
    }
    timeScheduleType
    topics {
      title
      id
    }
    totalPayRate
    type
    unpaidTime
  }

  ${HomeAddress}
`
