import React, { useEffect, useRef } from 'react'
import { Animated } from 'react-native'

import styles from './styles'

interface ISkeleton {
  height: number
  variant?: 'box' | 'circle'
  width?: string | number
}

const Skeleton = ({ height, variant = 'circle', width }: ISkeleton) => {
  const opacity = useRef(new Animated.Value(0.06))
  const borderRadius = variant === 'circle' ? height / 2 : 4

  useEffect(() => {
    const animation = Animated.loop(
      Animated.sequence([
        Animated.timing(opacity.current, {
          duration: 1000,
          toValue: 0.12,
          useNativeDriver: true,
        }),
        Animated.timing(opacity.current, {
          duration: 800,
          toValue: 0.06,
          useNativeDriver: true,
        }),
      ]),
    )
    animation.start()
    return () => animation.stop()
  }, [opacity])

  return (
    <Animated.View
      style={[
        styles.skeleton,
        // eslint-disable-next-line react-native/no-inline-styles
        { opacity: opacity.current, height: height ? height : '100%', width, borderRadius: borderRadius },
      ]}
    />
  )
}

export default Skeleton
