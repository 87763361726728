import { StyleSheet } from 'react-native'
import { colorsNew, fonts, fontSizesNew, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: paddingNew.base,
    paddingVertical: paddingNew.md,
  },
  hidden: {
    display: 'none',
  },
  title: {
    fontFamily: fonts.poppinsSemiBold,
    fontSize: fontSizesNew.md.size,
    marginBottom: paddingNew.sm,
  },
  divider: {
    borderBottomColor: colorsNew.grey._200,
    borderBottomWidth: 1,
  },
})

export default styles
