import gql from 'graphql-tag'

import { MessageTypes, UserActivityFieldTypes, NotificationQueryType } from './types'

export type { MessageTypes, UserActivityFieldTypes, NotificationQueryType }

export default gql`
  query Notifications($userId: String, $createdAtMin: DateTime) {
    messages(filter: { userId: $userId, type: "internal", latest: true }) {
      nodes {
        __typename
        content
        createdAt
        externalLink
        id
        openView
        read
        title
        type
      }
    }
    userActivities(
      filter: {
        createdAtMin: $createdAtMin
        userId: $userId
        ref: [
          "job_appointed"
          "interest_shown"
          "interest_accepted"
          "job_assigned_to_other_user"
          "interest_rejected"
          "proposal_candidate_rejected"
        ]
      }
    ) {
      nodes {
        __typename
        createdAt
        id
        read
        ref
        resourceAppointmentType
        resourceId
        resourceKlass
        schoolName
      }
    }
  }
`
