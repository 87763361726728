import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  section: {
    marginVertical: paddingNew.sm,
  },
  lastSection: {
    marginBottom: paddingNew.lg,
  },
  radioButtonsContainerInline: {
    flexDirection: 'row',
    height: 38,
    justifyContent: 'flex-start',
    marginHorizontal: paddingNew.xs,
  },
  checkboxContainerInline: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkboxItem: {
    flex: 1,
  },
  sectionTitle: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  errorText: {
    ...typography.chipTextSmall,
    color: colorsNew.redesign.error.main,
  },
  errorWithMargin: {
    marginHorizontal: paddingNew.xs,
  },
})
