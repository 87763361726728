import { FormikErrors } from 'formik/dist/types'

import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { uploadAvatarToS3 } from './uploadAvatarToS3'
import {
  fetchUserRequest,
  loginUserRequest,
  saveUserRequest,
  setUserState,
  updateWorkReferencesRequest,
} from './reusableActions'
import { LocaleT } from 'src/utils/translations/i18nTypes'
import { AppDispatchT } from '../index'
import { UserAttributesT } from './userTypes'
import { GetStateT, OnErrorT, OnSuccessT } from 'src/utils/types'
import { SaveUserT, UpdateWorkReferencesArgumentsT } from './actionTypes'
export * from './reusableActions'

export const setUser = (user: Partial<UserAttributesT>) => {
  bugsnagActionBreadcrumb('setUser')

  return setUserState(user)
}

export const fetchUser = (id: string) => async (dispatch: AppDispatchT, getState: GetStateT) =>
  await fetchUserRequest({ id, dispatch, getState, setUser })

export const loginUser =
  ({
    countryCode,
    email,
    password,
    setErrors,
  }: {
    countryCode: LocaleT
    email: string
    password: string
    setErrors: (errors: FormikErrors<any>) => void
  }) =>
  (dispatch: AppDispatchT) =>
    loginUserRequest({ countryCode, dispatch, email, password, setErrors, setUser })

export const saveUser =
  ({ id, user, uri, fileName, stringifyData = false, onSuccess, onError }: SaveUserT) =>
  async (dispatch: AppDispatchT, getState: GetStateT) =>
    await saveUserRequest({
      dispatch,
      fileName,
      getState,
      id,
      onError,
      onSuccess,
      setUser,
      stringifyData,
      uploadAvatarToS3,
      uri,
      user,
    })

export const updateWorkReferences =
  (
    references: UpdateWorkReferencesArgumentsT['references'],
    userId: string,
    onSuccess?: OnSuccessT,
    onError?: OnErrorT,
  ) =>
  async (dispatch: AppDispatchT) =>
    await updateWorkReferencesRequest({ fetchUser, references, userId, onSuccess, onError, dispatch })
