import React from 'react'

import { BellOnIcon, CheckFilledIcon, CloseFilledIcon, MessageIcon, WaitingIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { MessageTypes, UserActivityFieldTypes } from 'src/graphql/Notifications/types'
import translations, { translate } from 'src/utils/translations/translations'

export const getNotificationInfo = (notification: MessageTypes | UserActivityFieldTypes) => {
  if (notification.__typename === 'Message')
    return {
      icon: <MessageIcon fill={colorsNew.black} size={30} />,
      title: notification.title,
      subTitle: notification.content,
    }

  switch (notification.ref) {
    case 'job_appointed':
      return {
        icon: <CheckFilledIcon fill={colorsNew.black} size={30} />,
        title: translate(translations.notificationsAppointedTitle),
        subTitle: translate(translations.notificationsAppointedSubTitle, {
          school: notification.schoolName || 'school',
        }),
      }

    case 'interest_shown':
      return {
        icon: <WaitingIcon fill={colorsNew.black} size={30} />,
        title: translate(translations.notificationsAcceptedTitle),
        subTitle: translate(translations.notificationsAcceptedSubTitle, {
          school: notification.schoolName || 'school',
        }),
      }

    case 'interest_accepted':
      return {
        icon: <BellOnIcon fill={colorsNew.black} size={30} />,
        title: translate(translations.notificationsShownTitle),
        subTitle: translate(translations.notificationsShownSubTitle, {
          school: notification.schoolName || 'school',
        }),
      }
    case 'proposal_candidate_rejected':
      return {
        icon: <CloseFilledIcon fill={colorsNew.black} size={30} />,
        title: translate(translations.notificationsProposalClosedTitle),
        subTitle: translate(translations.notificationsProposalClosedSubTitle),
      }
    case 'interest_rejected':
    case 'job_assigned_to_other_user':
    default:
      return {
        icon: <CloseFilledIcon fill={colorsNew.black} size={30} />,
        title: translate(translations.notificationsRejectedTitle),
        subTitle: translate(translations.notificationsRejectedSubTitle, {
          school: notification.schoolName || 'school',
        }),
      }
  }
}

export const getOptionsList = (isRead: boolean) => [
  translate(translations.notificationsModalMore),
  isRead ? translate(translations.notificationsModalUnRead) : translate(translations.notificationsModalRead),
  translate(translations.notificationsModalDelete),
  translate(translations.notificationsModalCancel),
]
