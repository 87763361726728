export const buttonVariants = {
  containedDefault: 'contained-default',
  containedError: 'contained-error',
  containedInfo: 'contained-info',
  containedSuccess: 'contained-success',
  containedWarning: 'contained-warning',
  outlinedDefault: 'outlined-default',
  outlinedError: 'outlined-error',
  outlinedInfo: 'outlined-info',
  outlinedSuccess: 'outlined-success',
  outlinedWarning: 'outlined-warning',
  textDefault: 'text-default',
  textError: 'text-error',
  textInfo: 'text-info',
  textSuccess: 'text-success',
  textWarning: 'text-warning',
} as const

export const buttonSize = {
  sm: 'sm',
  lg: 'lg',
} as const
