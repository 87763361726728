import { StyleSheet } from 'react-native'
import { colorsNew, fontSizesNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalWeb: {
    overflow: 'hidden',
  },
  modalContent: {
    backgroundColor: colorsNew.white,
    marginBottom: 0,
    marginHorizontal: 0,
    padding: paddingNew.sm,
    width: '100%',
  },
  contactButton: {
    borderRadius: 15,
    backgroundColor: colorsNew.grey._800,
    padding: paddingNew.sm,
  },
  buttonText: {
    color: colorsNew.infoLight,
    fontSize: fontSizesNew.sm.size,
    fontWeight: '400',
    lineHeight: fontSizesNew.sm.lh,
    textAlign: 'center',
  },
})
