import React from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'

type Props = {
  isVisible: boolean
  closeIgnoreModal: () => void
  onIgnore: () => void
}

const IgnoreOfferModal = ({ isVisible, closeIgnoreModal, onIgnore }: Props) => (
  <CustomModal
    buttons={[
      {
        onPress: closeIgnoreModal,
        title: translate(translations.cancel),
        variant: buttonVariants.outlinedDefault,
      },
      {
        onPress: onIgnore,
        testID: 'ignore-confirmation-button',
        title: translate(translations.ignoreConfirmationButton),
        variant: buttonVariants.outlinedWarning,
      },
    ]}
    colorSchema={customModalColorSchema.warning}
    description={translate(translations.jobOfferIgnoreDescription)}
    isVisible={isVisible}
    modalPlace="Main/Jobs/Details/components/IgnoreOfferModal/IgnoreOfferModal"
    onClose={closeIgnoreModal}
    title={translate(translations.jobOfferIgnoreTitle)}
    variant={customModalVariants.standard}
  />
)

export default IgnoreOfferModal
