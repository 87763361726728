import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'

import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import FileUploader from 'src/components/FileUploader/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const DOCUMENT_TYPE = 'qualification_trn_letter'

type Props = ProfileAndRecruitmentScreensProps<'TeacherReferenceNumber'>

const TeacherReferenceNumber = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - TeacherReferenceNumber')

  const userId = useAppSelector(state => state.user.id) as string
  const certificates = useAppSelector(getUserDocumentUK(DOCUMENT_TYPE))

  const taskClosedWithoutDoc = isTaskCompleted && !certificates.length

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)
  const [skipUpload, setSkipUpload] = useState(taskClosedWithoutDoc)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked
    }
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => setIsError(true)
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const updateTaskStatus = () =>
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)

  const completeTask = () => {
    setIsSubmitting(true)
    setIsError(false)
    isFilePicked ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainText}>{translate(translations.teacherReferenceNumberInfo)}</Text>
      <View style={styles.checkboxContainer}>
        <Checkbox
          disabled={isFilePicked || isTaskCompleted}
          label={translate(translations.teacherReferenceNumberCheckbox)}
          onPress={() => setSkipUpload(prev => !prev)}
          selected={skipUpload}
        />
      </View>
      <Text style={StyleSheet.flatten([styles.mainText, skipUpload && styles.textDisabled])}>
        {translate(translations.teacherReferenceNumberUpload)}
      </Text>
      <FileUploader
        arrayDocuments={certificates}
        buttonDisabled={isTaskCompleted || skipUpload}
        deletingFilesDisabled={isTaskCompleted}
        documentType={DOCUMENT_TYPE}
        onFileSaveCallback={updateTaskStatus}
        onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
        onFilesErrorCallback={handleError}
        saveOnUpload={shouldSaveDocument}
      />
      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonContainer}>
          <ButtonNew
            disabled={isTaskCompleted || (!skipUpload && !isFilePicked && !certificates.length)}
            isSubmitting={isSubmitting}
            onPress={completeTask}
            title={translate(translations.closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>
      <Text style={styles.helper}>{translate(translations.teacherReferenceNumberTaskInfo)}</Text>
    </ScrollView>
  )
}

export default TeacherReferenceNumber
