import { I18n as i18n } from 'i18n-js'
import get from 'lodash/get'
import * as RNLocalize from 'react-native-localize'

import enGB from 'src/locales/en-GB'
import sv from 'src/locales/sv'
import countryLocalizationInfo from 'src/global/countryLocalizationInfo'
import storage from 'src/mmkvStorage'
import { LocaleT, LocationT } from './i18nTypes'

export const defaultLocale = 'en-GB'
export const enPossibleLocation = ['en-GB', 'en', 'gb']
export const svPossibleLocation = ['sv', 'se']

const I18n = new i18n({
  en: enGB,
  'en-GB': enGB,
  sv,
})

export const getLanguageByLocation = (locale: LocaleT): LocationT => {
  let newLanguage = ''

  if (!locale) {
    newLanguage = storage.getString('locale') as LocationT
  } else if (locale === 'se') newLanguage = countryLocalizationInfo.se.locale
  else newLanguage = countryLocalizationInfo.gb.locale

  return newLanguage as LocationT
}

export const setLocale = (lang: LocaleT) => (I18n.locale = getLanguageByLocation(lang))

export const getDeviceLocale = () => {
  const deviceCountryCode = get(RNLocalize?.getLocales(), '[0].countryCode')
  return deviceCountryCode === 'SE' ? 'se' : 'gb'
}

I18n.defaultLocale = defaultLocale
// @ts-ignore
I18n.fallbacks = true

export default I18n
