import React from 'react'
import { Bubble, BubbleProps } from 'react-native-gifted-chat'

import { colors, fontSizes, fonts } from 'src/styles'
import { ConvertedChatMessage } from '../Chat'

const CustomBubble = (props: BubbleProps<ConvertedChatMessage>) => {
  const isSMS = props.currentMessage && props.currentMessage.origin === 'sms'

  return (
    <Bubble
      {...props}
      textStyle={{
        right: {
          color: colors.white,
          fontFamily: fonts.poppinsRegular,
          fontSize: fontSizes.xs,
        },
        left: {
          color: colors.black,
          fontFamily: fonts.poppinsRegular,
          fontSize: fontSizes.xs,
        },
      }}
      wrapperStyle={{
        left: {
          backgroundColor: colors.grey._200,
        },
        right: {
          backgroundColor: isSMS ? colors.blue._500 : colors.primary,
        },
      }}
    />
  )
}

export default CustomBubble
