import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: paddingNew.sm,
  },
  infoTitle: {
    ...typography.subtitle2,
  },
  info: {
    ...typography.body2,
  },
  buttonPressed: {
    backgroundColor: colorsNew.redesign.action.pressed,
    borderRadius: 8,
  },
  iconContainer: {
    alignItems: 'center',
    borderColor: colorsNew.redesign.action.active,
    borderRadius: 24,
    borderWidth: 1,
    height: 48,
    justifyContent: 'center',
    width: 48,
  },
})

export default styles
