import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  backgroundFallback: {
    resizeMode: 'cover',
  },
  backgroundImage: {
    height: 200,
    resizeMode: 'contain',
    width: '100%',
  },
  loader: {
    height: 200,
    justifyContent: 'center',
  },
})

export default styles
