import { View, Text, StyleSheet } from 'react-native'
import React from 'react'

import styles from './styles'
import { CalendarCardIcon, CalendarCardMultidayIcon } from 'src/icons'

type Props = {
  day: string | number
  isMultiday?: boolean
  month: string
}

const DayFrame = ({ day, month, isMultiday }: Props) => (
  <View style={styles.container}>
    {isMultiday ? (
      <CalendarCardMultidayIcon size={72} testID="multiday-calendar" />
    ) : (
      <CalendarCardIcon size={72} testID="singleday-calendar" />
    )}
    <View style={StyleSheet.flatten([styles.body, isMultiday ? styles.bodyMultiDay : styles.bodySingleDay])}>
      <Text style={styles.day}>{day}</Text>
      <Text style={styles.month}>{month}</Text>
    </View>
  </View>
)

export default DayFrame
