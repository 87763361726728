import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const MultiDayIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 54 54" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.058 9.221h2.174a4.362 4.362 0 0 1 4.349 4.349v30.441a4.362 4.362 0 0 1-4.349 4.349h-30.44a4.348 4.348 0 0 1-4.35-4.349V13.57a4.348 4.348 0 0 1 4.35-4.349h2.174V7.047a2.18 2.18 0 0 1 2.174-2.174 2.18 2.18 0 0 1 2.174 2.174V9.22H35.71V7.047a2.18 2.18 0 0 1 2.174-2.174 2.18 2.18 0 0 1 2.175 2.174V9.22Zm-26.093 34.79h26.093a2.18 2.18 0 0 0 2.174-2.174V20.093h-30.44v21.744a2.18 2.18 0 0 0 2.174 2.174Z"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.965 26.616c0-1.2.974-2.174 2.175-2.174h21.743a2.174 2.174 0 1 1 0 4.349H16.14c-1.201 0-2.175-.974-2.175-2.175ZM13.965 35.313c0-1.2.974-2.174 2.175-2.174h10.872a2.174 2.174 0 1 1 0 4.349H16.14c-1.201 0-2.175-.974-2.175-2.175Z"
    />
  </Svg>
)

export default MultiDayIcon
