import React from 'react'
import { ActionSheetIOS, Platform } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'

import { openTelUrl } from 'src/global/utils'
import { setSnackbarProps } from 'src/store/app/actions'
import translations, { translate } from 'src/utils/translations/translations'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import ModalWrapper from './ModalWrapper'
import { useAppDispatch } from 'src/hooks/reduxHooks'

type Props = {
  isVisible: boolean
  onClose: () => void
}

const isIOS = Platform.OS === 'ios'

const ContactModal = ({ isVisible, onClose }: Props) => {
  const dispatch = useAppDispatch()

  const callSupport = () => openTelUrl(translate(translations.supportPhone))

  const copyText = (text: string) => {
    Clipboard.setString(text)
    dispatch(
      setSnackbarProps({
        message: translate(translations.emailCopied),
        visible: true,
      }),
    )
    onClose()
  }

  const showIOSMenu = () => {
    ActionSheetIOS.showActionSheetWithOptions(
      {
        cancelButtonIndex: 2,
        options: [
          translate(translations.callPhone, { phone: translate(translations.supportPhone) }),
          translate(translations.copyPhoneNumber),
          translate(translations.cancel),
        ],
        userInterfaceStyle: 'dark',
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          callSupport()
          onClose()
        } else if (buttonIndex === 1) {
          copyText(translate(translations.supportPhone))
        } else if (buttonIndex === 2) {
          onClose()
        }
      },
    )
  }

  const actions = () => [
    {
      onPress: callSupport,
      title: translate(translations.callPhone, { phone: translate(translations.supportPhone) }),
      variant: buttonVariants.outlinedDefault,
    },
    {
      onPress: () => copyText(translate(translations.supportPhone)),
      title: translate(translations.copyPhoneNumber),
      variant: buttonVariants.outlinedDefault,
    },
    {
      onPress: onClose,
      title: translate(translations.cancel),
      variant: buttonVariants.outlinedDefault,
    },
  ]

  if (isIOS && isVisible) {
    showIOSMenu()
    return null
  }

  return <ModalWrapper isVisible={isVisible} onClose={onClose} actions={actions} />
}

export default ContactModal
