import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { CardIcon, getCardInfo } from './utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { ProfileCategories, ProfileCategoryStatus, profileCategoriesList } from 'src/scenes/Main/Profile/types'
import ShadowWrapper from 'src/components/ShadowWrapper'
import translations, { translate } from 'src/utils/translations/translations'
import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  category: ProfileCategories
  numberOfCompletedTasks: number
  numberOfTasks: number
  status: ProfileCategoryStatus
}

const CategoryCard = ({ status, numberOfTasks, numberOfCompletedTasks, category }: Props) => {
  const navigation = useNavigation<ProfileAndRecruitmentScreensProps<'CategoriesList'>['navigation']>()

  const categoryInfo = useMemo(
    () => getCardInfo(status, numberOfTasks, numberOfCompletedTasks),
    [status, numberOfTasks, numberOfCompletedTasks],
  )
  const Icon = CardIcon[category]

  const navigateToCategoryTaskList = () => navigation.navigate('TasksList', { category })

  return (
    <View style={styles.cardContainer} testID={`categoryCard-${profileCategoriesList[category]}`}>
      <ShadowWrapper borderRadius={8} onPress={navigateToCategoryTaskList} withBaseShadow withVerticalSpacing>
        <View style={styles.card}>
          <View style={styles.iconContainer}>
            <Icon size={20} fill={colorsNew.redesign.secondary.contrast} />
          </View>
          <View style={styles.statusContainer}>
            <Text style={styles.statusInfo} testID="categoryStatus">
              {categoryInfo.text}
            </Text>
            {categoryInfo.StatusIcon}
          </View>
          <Text numberOfLines={2} style={styles.title}>
            {translate(translations[`categoryTitle_${category}`])}
          </Text>
        </View>
      </ShadowWrapper>
    </View>
  )
}

export default CategoryCard
