import {
  AxiosAdapter,
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  getAdapter,
} from 'axios'

import { pick } from './pick'
import { NonFunctionProperties } from './types'
import store from 'src/store'
import { getApiUrl } from 'src/utils/apiConfig'

type StorableAxiosRequestConfig = NonFunctionProperties<AxiosRequestConfig>

export interface StorageInstance {
  contains(key: string): boolean
  delete(key: string): any
  getAllKeys(): readonly string[]
  getString(key: string): string | null
  set(key: string, value: string): any
}

export interface AxiosOfflineOptions {
  axiosInstance: AxiosInstance
  storageInstance: StorageInstance
  getRequestToStore?: (request: AxiosRequestConfig) => StorableAxiosRequestConfig | undefined
  getResponsePlaceholder?: (request: AxiosRequestConfig, err: AxiosError) => AxiosResponse
  sendFromStorageFirst?: boolean
}

interface AxiosOfflineAdapter extends AxiosAdapter {
  (config: AxiosRequestConfig, fromStorage: boolean): AxiosPromise
}

// based on @appello/axios-offline, axios-offline, axios-offline-adapter
export class AxiosOffline {
  private readonly axiosInstance: AxiosInstance

  private readonly prefix: string
  private readonly storageInstance: Required<StorageInstance>

  private readonly options: Required<Pick<AxiosOfflineOptions, 'getRequestToStore'>> &
    Pick<AxiosOfflineOptions, 'getResponsePlaceholder' | 'sendFromStorageFirst'>

  private sendingPromise: Promise<void> | null = null

  constructor({
    axiosInstance,
    getRequestToStore = config => pick(config, ['method', 'url', 'headers', 'data', 'baseURL']),
    getResponsePlaceholder,
    sendFromStorageFirst,
    storageInstance,
  }: AxiosOfflineOptions) {
    this.storageInstance = storageInstance
    this.prefix = AxiosOffline.STORAGE_PREFIX
    this.options = {
      getRequestToStore,
      getResponsePlaceholder,
      sendFromStorageFirst,
    }

    this.axiosInstance = axiosInstance
    this.axiosInstance.defaults.adapter = this.adapter
  }

  private async storeRequest(request: StorableAxiosRequestConfig) {
    const newKey = `${new Date().getHours()}` + `${new Date().getMinutes()}` + `${new Date().getSeconds()}`
    const data = typeof request.data === 'string' ? JSON.parse(request.data) : request.data
    const key = `${this.prefix}_${data?.messageID || newKey}`
    if (this.storageInstance.contains(key)) {
      const fromStore = JSON.parse(this.storageInstance.getString(key) || '')
      if (fromStore?.data?.opened === true && data?.opened === false) {
        return ''
      } else {
        await this.storageInstance.set(key, JSON.stringify(request))
      }
    } else {
      await this.storageInstance.set(key, JSON.stringify(request))
    }
  }

  private removeRequest(key: string) {
    return this.storageInstance.delete(key)
  }

  private adapter: AxiosOfflineAdapter = async config => {
    const fromStorage = config.headers?.[AxiosOffline.STORAGE_HEADER] || false

    try {
      if (this.options.sendFromStorageFirst && !fromStorage) {
        await this.sendingPromise
      }
      return await getAdapter('xhr')(config)
    } catch (err) {
      const requestToStore = this.options.getRequestToStore(config)
      if (requestToStore) {
        await this.storeRequest(requestToStore)
        if (this.options.getResponsePlaceholder) {
          return this.options.getResponsePlaceholder(config, err as AxiosError)
        }
      }
    }
  }

  async sendRequestsFromStore() {
    if (this.sendingPromise) return

    try {
      this.sendingPromise = (async () => {
        const keys = this.storageInstance
          .getAllKeys()
          .filter(key => key.startsWith(this.prefix))
          .sort()
        for (const key of keys) {
          try {
            const request: AxiosRequestConfig | null = JSON.parse(this.storageInstance.getString(key) || '')
            if (request) {
              const token = store.getState().user?.authToken
              const countryCode = store.getState().app?.countryCode || 'gb'
              const url = getApiUrl(countryCode)

              await this.axiosInstance.request({
                ...request,
                baseURL: token ? url : request.baseURL,
                headers: {
                  ...request.headers,
                  [AxiosOffline.STORAGE_HEADER]: true,
                },
              })

              this.removeRequest(key)
            }
          } catch (err) {
            if (AxiosOffline.checkIfOfflineError(err as AxiosError)) {
              break
            }
          }
        }
      })()
      await this.sendingPromise
    } finally {
      this.sendingPromise = null
    }
  }

  static checkIfOfflineError(error: AxiosError): boolean {
    const { code, response } = error
    return response === undefined && (code === AxiosError.ERR_NETWORK || code === AxiosError.ECONNABORTED)
  }

  static STORAGE_HEADER = 'x-from-storage'

  static STORAGE_PREFIX = '@axios-offline'
}
