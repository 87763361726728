import { AxiosResponse } from 'axios'
import { BUILD_MODE } from 'src/utils/webAdapters/DotEnvAdapter'

import Api, { responseErrorCallback } from 'src/utils/api'

export type onSuccessType = (url: string) => void
export type onErrorType = () => void

const isStaging = ['staging', 'development'].includes(BUILD_MODE)

type ResolveRequestTypes = { resolve: (sth: string) => void; onSuccess: (sth: string) => void }

const resolveRequest = ({ resolve, onSuccess }: ResolveRequestTypes) =>
  setTimeout(() => {
    onSuccess('')
    resolve('done')
  }, 1000)

export const getYotiUrl = (onSuccess: onSuccessType, onError: onErrorType) => {
  if (isStaging) {
    return new Promise(resolve => resolveRequest({ resolve, onSuccess }))
  }

  return Api.get(`/integrations/yoti_verifications`, {
    onSuccess: (data: AxiosResponse) => {
      onSuccess(data.data?.url)
    },
    onError: (response: AxiosResponse) => {
      responseErrorCallback('put', `/integrations/yoti_verification`, response)
      onError()
    },
  })
}
