import translations, { translate } from 'src/utils/translations/translations'
import * as Yup from 'yup'

const getValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string().email(translate(translations.enterValidEmailLogin)).required(translate(translations.blank)),
    password: Yup.string().required('blank'),
  })

export default getValidationSchema
