import React from 'react'
import { View } from 'react-native'

import Skeleton from 'src/components/Skeleton'
import styles from './styles'

const LoadingSection = () => (
  <View style={styles.container}>
    <Skeleton height={12} width={135} />
    {Array(3)
      .fill(0)
      .map((_, index) => (
        <View key={index} style={styles.itemContainer}>
          <View style={styles.innerItemContainer}>
            <Skeleton height={12} width={80} />
            <Skeleton height={8} width={177} />
          </View>
          <Skeleton width={82} height={24.12} />
        </View>
      ))}
  </View>
)

export default LoadingSection
