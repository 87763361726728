import React from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import { DeleteOutlineIcon } from 'src/icons'
import CustomModalBase from 'src/components/Modal/CustomModal/CustomModalBase'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  description: string
  isDeleting: boolean
  onCancel: () => void
  onDelete: () => void
  title: string
}

const ConfirmationAlertModal = ({ title, description, onCancel, onDelete, isDeleting }: Props) => (
  <CustomModalBase
    buttons={[
      {
        disabled: isDeleting,
        isLong: true,
        onPress: onCancel,
        title: translate(translations.goBack),
        variant: buttonVariants.outlinedDefault,
      },
      {
        disabled: isDeleting,
        isLong: true,
        isSubmitting: isDeleting,
        LeftIcon: DeleteOutlineIcon,
        onPress: onDelete,
        title: translate(translations.delete),
        variant: buttonVariants.outlinedError,
      },
    ]}
    colorSchema={customModalColorSchema.error}
    description={description}
    title={title}
    variant={customModalVariants.standard}
  />
)

export default ConfirmationAlertModal
