import { StyleSheet } from 'react-native'

import { typography, colorsNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  workloadsContainer: {
    paddingTop: paddingNew.sm,
  },
  description: StyleSheet.flatten([
    typography.overline,
    {
      paddingLeft: paddingNew.sm,
      paddingTop: paddingNew.xxs * 10,
      paddingBottom: paddingNew.sm,
    },
  ]),
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.base / 2,
  },
  title: StyleSheet.flatten([
    typography.subtitle2,
    {
      color: colorsNew.redesign.text.primary,
    },
  ]),
  headerLoading: {
    bottom: -16,
    left: '50%',
    position: 'absolute',
  },
  switchContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 31,
    width: 50,
  },
})
