import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import { FieldInputProps, FormikProps } from 'formik/dist/types'

import { countryCodeSelector } from 'src/store/app/selectors'
import { Error, Label, TouchableContentNew } from 'src/components/Form/Common'
import { SvIcon, UKIcon } from 'src/icons'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import { isMaestro } from 'src/utils/isMaestro'

type Props = {
  containerStyles?: object
  disabled: boolean
  field: FieldInputProps<any>
  form: FormikProps<any>
  label?: string
  labelStyles?: object
  onChange?: () => void
  placeholder?: string
}

type CountryListType = Array<{
  value?: string
  label?: string
  icon?: Element
}>

const getSelectedField = (fieldValue: string, values: CountryListType) =>
  find(values, _value => _value?.value === fieldValue)

const CountryPicker = ({
  containerStyles,
  disabled,
  field: { name, value: fieldValue },
  form: { errors, touched, setFieldValue },
  label,
  labelStyles,
  onChange,
  placeholder,
}: Props) => {
  const DropdownButton = useRef<TouchableOpacity>(null)
  const [dropdownTop, setDropdownTop] = useState(0)
  const [visible, setVisible] = useState(false)

  const appCountryCode = useSelector(countryCodeSelector)

  const countryList = useMemo(
    () => [
      { value: 'se', label: translate(translations.sweden), icon: SvIcon },
      { value: 'gb', label: translate(translations.unitedKingdom), icon: UKIcon },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appCountryCode],
  )

  const values = useMemo(() => {
    const newList = [...countryList]
    return appCountryCode === 'se' ? newList : newList.reverse()
  }, [appCountryCode, countryList])

  const selectedField = getSelectedField(fieldValue, values)
  const fieldLabel = fieldValue ? selectedField?.label : placeholder
  const fieldIcon = fieldValue ? selectedField?.icon : null
  const isTouched = get(touched, name)
  const hasError = isTouched && get(errors, name)

  const renderItem = useCallback(
    item => {
      const Icon = item?.icon

      return (
        <TouchableOpacity
          key={item?.value}
          onPress={() => {
            setFieldValue(name, item?.value)
            setVisible(false)
            onChange(item?.value)
          }}
          style={StyleSheet.flatten([styles.modalRow, item?.value === fieldValue ? styles.modalRowSelected : null])}
          testID={`picker-item-${item?.value}`}
        >
          {Icon ? <Icon size={24} style={styles.icon} /> : null}
          <Text numberOfLines={1} style={styles.modalRowLabel}>
            {item?.label}
          </Text>
        </TouchableOpacity>
      )
    },
    [fieldValue, name, onChange, setFieldValue],
  )

  const toggleDropdown = () => {
    visible ? setVisible(false) : openDropdown()
  }

  const openDropdown = () => {
    DropdownButton.current?.measure((_fx, _fy, _w, h, _px, py) => {
      setDropdownTop(py + h)
    })
    setVisible(true)
  }

  return (
    <>
      <TouchableOpacity
        ref={DropdownButton}
        style={StyleSheet.flatten([styles.container, containerStyles])}
        testID={name}
      >
        <Label label={label} labelStyles={labelStyles} />
        <TouchableContentNew
          disabled={disabled}
          fieldIcon={fieldIcon}
          fieldLabel={fieldLabel}
          fieldValue={fieldValue}
          hasError={hasError}
          onPress={toggleDropdown}
        />
        <Error errors={isTouched ? errors : {}} name={name} />
      </TouchableOpacity>

      <Modal animationType="none" transparent visible={visible}>
        <TouchableOpacity accessible={!isMaestro()} onPress={() => setVisible(false)} style={styles.modalContainer}>
          <View style={[styles.modalListWrapper, { top: dropdownTop }]}>{values.map(value => renderItem(value))}</View>
        </TouchableOpacity>
      </Modal>
    </>
  )
}

export default CountryPicker
