import { View, Text, ScrollView, StyleSheet } from 'react-native'
import React, { useContext, useState } from 'react'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, ErrorType, errorTypes, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { createStudentEducation, deleteStudentEducation, updateStudentEducation } from './actions'
import { errorActions, errorRemoveActions, successActions, successRemoveActions } from '../../utils'
import { getQualificationLabel } from './utils'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { UserEducation, getUserEducation } from 'src/store/user/selectors'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox'
import CustomModalWrapper from 'src/components/Modal/CustomModal/CustomModalWrapper'
import Form from './Form'
import FormInfoCard from 'src/components/FormInfoCard'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'Qualifications'>

const Qualifications = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const taskStatusCompleted = status === profileTaskStatus.completed

  const dispatch = useAppDispatch()

  useBugsnagView('RequiredTask - Qualifications')

  const userEducation = useAppSelector(getUserEducation)
  const userId = useAppSelector(state => state.user.id) as string

  const [formError, setFormError] = useState<ErrorType | null>(null)
  const [isError, setIsError] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isTaskCompleted, setIsTaskCompleted] = useState(taskStatusCompleted)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [editedQualificationValues, setEditedQualificationValues] = useState<
    UserEducation | Record<string, never> | null
  >(null)

  const { mixpanel } = useContext(MixpanelContext)

  const openQualificationForm = (qualification?: UserEducation) => {
    setIsFormOpen(true)
    setEditedQualificationValues(qualification || {})
  }

  const closeFormWithoutSaving = () => {
    setIsFormOpen(false)
    setEditedQualificationValues(null)
  }

  const saveUpdatedQualification = (updatedQualification: any, editedQualificationId?: string) => {
    const isEdit = !!editedQualificationId
    const data = isEdit ? updatedQualification : { ...updatedQualification, userId }

    bugsnagActionBreadcrumb('submit qualification form', { id: userId, user: data })
    setIsSubmitting(true)
    setFormError(null)

    isEdit
      ? updateStudentEducation(
          editedQualificationId,
          data,
          () => successActions(userId, dispatch, setIsSubmitting, closeFormWithoutSaving),
          () => errorActions(errorTypes.save, setFormError, setIsSubmitting),
        )
      : createStudentEducation(
          data,
          () => successActions(userId, dispatch, setIsSubmitting, closeFormWithoutSaving),
          () => errorActions(errorTypes.save, setFormError, setIsSubmitting),
        )
  }

  const removeQualification = (deletedQualificationId?: UserEducation['id']) => {
    if (!deletedQualificationId) return closeFormWithoutSaving()

    setIsDeleting(true)
    setFormError(null)
    deleteStudentEducation(
      deletedQualificationId,
      () => successRemoveActions(userId, dispatch, setIsDeleting, closeFormWithoutSaving),
      () => errorRemoveActions(errorTypes.delete, setFormError, setIsDeleting),
    )
  }

  const completeTask = () => {
    setIsError(false)
    markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack, () => setIsError(true))
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.taskDescription}>{translate(translations.qualificationsDescription)}</Text>

      {userEducation.map(education => (
        <FormInfoCard
          key={education.id}
          onPress={() => openQualificationForm(education)}
          subtitle={education.name || ''}
          title={getQualificationLabel(education.qualificationType || '')}
        />
      ))}

      <View style={StyleSheet.flatten([styles.buttonContainer, styles.withDivider])}>
        <ButtonNew
          disabled={taskStatusCompleted}
          onPress={openQualificationForm}
          size={buttonSize.sm}
          testID="open-qualification-form"
          title={translate(translations.qualificationNewButton)}
          variant={buttonVariants.containedDefault}
        />
      </View>

      <Checkbox
        disabled={taskStatusCompleted}
        label={translate(translations.qualificationCheckbox)}
        onPress={() => setIsTaskCompleted(prev => !prev)}
        selected={isTaskCompleted}
      />
      {isError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsError(false)} />
        </View>
      ) : null}
      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={taskStatusCompleted || !isTaskCompleted}
          onPress={completeTask}
          size={buttonSize.lg}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.infoText}>{translate(translations.qualificationTaskInfo)}</Text>

      <CustomModalWrapper isVisible={isFormOpen} modalPlace="Main/Profile/RequiredTasks/Qualifications/Qualifications">
        {editedQualificationValues ? (
          <Form
            closeFormError={() => setFormError(null)}
            closeFormWithoutSaving={closeFormWithoutSaving}
            deleteQualification={removeQualification}
            formError={formError}
            isDeleting={isDeleting}
            isDisabled={taskStatusCompleted}
            isSubmitting={isSubmitting}
            qualification={editedQualificationValues}
            updateQualificationList={saveUpdatedQualification}
          />
        ) : (
          <></>
        )}
      </CustomModalWrapper>
    </ScrollView>
  )
}

export default Qualifications
