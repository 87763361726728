import { ReactElement } from 'react'
import { ModalProps } from 'react-native-modal'

import type { ButtonNewProps } from 'src/components/Buttons/ButtonNew/ButtonNew'

export const customModalColorSchema = {
  default: 'default',
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
} as const

export const customModalVariants = {
  filled: 'filled',
  standard: 'standard',
} as const

type ValueOf<T> = T[keyof T]

export type CustomModalVariants = ValueOf<typeof customModalVariants>
export type CustomModalColorSchema = ValueOf<typeof customModalColorSchema>

export type CustomModalBaseProps = {
  buttons: ButtonNewProps[]
  children?: ReactElement
  colorSchema: CustomModalColorSchema
  description?: string | null
  onClose?: () => void
  subtitle?: string
  testId?: string
  title: string
  variant: CustomModalVariants
}

export type CustomModalProps = Partial<ModalProps> & { isModalScreen?: boolean; modalPlace?: string }
