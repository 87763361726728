const countryLocalizationInfo = {
  gb: {
    currency: 'GPB',
    locale: 'en-GB',
    timezone: 'Europe/London',
  },
  se: {
    currency: 'SEK',
    locale: 'sv',
    timezone: 'Europe/Stockholm',
  },
} as const

export default countryLocalizationInfo
