import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { getTaskStatusInfo } from './utils'
import { ProfileTaskStatus } from 'src/scenes/Main/Profile/types'
import styles from './styles'

type Props = {
  status: ProfileTaskStatus
  timeToComplete: number | string
}

const StatusInfo = ({ status, timeToComplete }: Props) => {
  const statusSettings = useMemo(() => getTaskStatusInfo(status, timeToComplete), [status, timeToComplete])

  return (
    <View style={styles.infoContainer}>
      <View style={styles.infoBody}>
        <View style={StyleSheet.flatten([styles.badge, { backgroundColor: statusSettings?.badgeColor }])}>
          <Text style={StyleSheet.flatten([styles.badgeText, { color: statusSettings?.textColor }])}>
            {statusSettings?.title}
          </Text>
        </View>
        <Text style={styles.description}>{statusSettings?.description}</Text>
      </View>
    </View>
  )
}

export default StatusInfo
