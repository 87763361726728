import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const PhoneFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM14.6009 13.6545L16.012 13.8156C16.5731 13.8823 16.9953 14.3545 16.9898 14.9156V15.8767C16.9898 16.5045 16.4676 17.0267 15.8398 16.9878C11.1009 16.6934 7.30645 12.9045 7.01201 8.16007C6.97312 7.53229 7.49534 7.01007 8.12312 7.01007H9.08423C9.6509 7.01007 10.1231 7.43229 10.1898 7.9934L10.3509 9.3934C10.3898 9.73229 10.2731 10.0656 10.0342 10.3045L9.00645 11.3323C9.80645 12.9101 11.0953 14.1934 12.6676 14.9934L13.6898 13.9712C13.9287 13.7323 14.262 13.6156 14.6009 13.6545Z"
    />
  </Svg>
)

export default PhoneFilledIcon
