import React, { createContext, ReactNode, useMemo } from 'react'
import sumBy from 'lodash/sumBy'

import { compliancesStatus } from 'src/global/constants'
import { isUserApplicant } from 'src/store/user/selectors'
import { useAppSelector } from 'src/hooks/reduxHooks'
import type { CompliancesStatus } from 'src/global/types'
import useGetCompletedTasks from 'src/scenes/Main/Profile/CategoriesList/useGetCompletedTasks'

type ComplianceStatusContextType = {
  complianceStatus: CompliancesStatus | null
  isUserCompliant: boolean | undefined
  showWarning: boolean
}

type TProps = {
  children: ReactNode
}

export const ComplianceStatusContext = createContext<ComplianceStatusContextType>({
  complianceStatus: null,
  isUserCompliant: undefined,
  showWarning: false,
})

export const ComplianceStatusProvider = ({ children }: TProps) => {
  const { categoriesData, numberOfAllCompletedTasks, numberOfAllTasks } = useGetCompletedTasks()
  const isApplicant = useAppSelector(isUserApplicant)

  const complianceStatus = useMemo(() => {
    if (isApplicant) return compliancesStatus.notCompleted
    if (numberOfAllCompletedTasks === numberOfAllTasks) return compliancesStatus.completed

    const categoriesList = Object.values(categoriesData)
    const numberOfExpiredTaskCategories = sumBy(categoriesList, task => (task.categoryStatus === 'expired' ? 1 : 0))
    const numberOfSoonExpiringTaskCategories = sumBy(categoriesList, task =>
      task.categoryStatus === 'expiresSoon' ? 1 : 0,
    )
    if (numberOfExpiredTaskCategories >= numberOfSoonExpiringTaskCategories) return compliancesStatus.expired
    return compliancesStatus.warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesData, numberOfAllCompletedTasks, numberOfAllTasks])

  const showWarning =
    !isApplicant &&
    [compliancesStatus.expired, compliancesStatus.warning, compliancesStatus.notCompleted].includes(complianceStatus)

  const isUserCompliant = ![compliancesStatus?.expired, compliancesStatus?.notCompleted].includes(complianceStatus)

  return (
    <ComplianceStatusContext.Provider value={{ complianceStatus, isUserCompliant, showWarning }}>
      {children}
    </ComplianceStatusContext.Provider>
  )
}
