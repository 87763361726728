import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const CheckboxIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM10.71 16.29C10.32 16.68 9.69 16.68 9.3 16.29L5.71 12.7C5.32 12.31 5.32 11.68 5.71 11.29C6.1 10.9 6.73 10.9 7.12 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.71 16.29Z" />
  </Svg>
)

export default CheckboxIcon
