import React, { ElementType } from 'react'

import {
  AccountCircleIcon,
  AssignmentIcon,
  ContactMailIcon,
  FactCheckedIcon,
  HumlyIcon,
  SportsScoreIcon,
  VerifiedIcon,
  VerifiedUserIcon,
  WarningAmberIcon,
  WarningIcon,
  WorkIcon,
} from 'src/icons'
import translations, { translate } from 'src/utils/translations/translations'
import { IconTypes } from 'src/icons/iconTypes'
import { ProfileCategories, ProfileCategoryStatus } from 'src/scenes/Main/Profile/types'
import { colorsNew } from 'src/styles'

export const getCardInfo = (status: ProfileCategoryStatus, numberOfTasks: number, numberOfCompletedTasks: number) => {
  switch (status) {
    case 'completed':
      return {
        StatusIcon: <VerifiedIcon size={16} fill={colorsNew.redesign.success.shades50p} />,
        text: translate(translations.categoryStatusCompleted),
      }
    case 'expired':
      return {
        StatusIcon: <WarningIcon size={16} fill={colorsNew.redesign.warning.shades50p} />,
        text: translate(translations.categoryStatusExpired),
      }

    case 'expiresSoon':
      return {
        StatusIcon: <WarningAmberIcon size={16} fill={colorsNew.redesign.warning.shades50p} />,
        text: translate(translations.categoryStatusExpiresSoon),
      }

    case 'enabled':
      return {
        StatusIcon: null,
        text: translate(translations.categoryStatusEnabled, { numberOfTasks, numberOfCompletedTasks }),
      }
  }
}

type CardIconType = {
  [category in ProfileCategories]: ElementType<IconTypes>
}

export const CardIcon: CardIconType = {
  contract: AssignmentIcon,
  declarations: FactCheckedIcon,
  finalAgreements: SportsScoreIcon,
  getToKnowHumly: HumlyIcon,
  profileInfo: AccountCircleIcon,
  profileVerification: VerifiedUserIcon,
  qualifications: WorkIcon,
  references: ContactMailIcon,
}
