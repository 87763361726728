import { Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'
import { MAX_HEIGHT } from 'src/components/Modal/NewModal/styles'

const isWeb = Platform.OS === 'web'
const MAIN_CONTAINER_HEIGHT = MAX_HEIGHT * 0.9
const IMAGE_HEIGHT = 184

const styles = StyleSheet.create({
  buttonSpace: {
    width: paddingNew.xs,
  },
  buttonWrapper: {
    flexDirection: 'row',
    marginTop: paddingNew.md,
  },
  container: {
    height: MAIN_CONTAINER_HEIGHT,
    display: 'flex',
    marginBottom: paddingNew.lg,
    marginHorizontal: 24,
    marginTop: paddingNew.sm,
  },
  divider: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.other.divider,
    borderRadius: 2,
    height: 4,
    marginBottom: paddingNew.md,
    width: 48,
  },
  image: {
    alignSelf: 'center',
    height: IMAGE_HEIGHT,
    resizeMode: 'contain',
    width: '100%',
  },
  imageSpace: {
    height: paddingNew.xs,
  },
  mainContent: {
    flex: 1,
  },
  mainText: {
    ...typography.body2,
    color: colorsNew.black,
    marginBottom: paddingNew.xs,
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: !isWeb
    ? {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
      }
    : {},
  overline: {
    ...typography.overline,
    color: colorsNew.black,
    marginRight: 10,
  },
  overlineWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: paddingNew.xs,
  },
  title: {
    ...typography.h6,
    color: colorsNew.black,
    marginBottom: paddingNew.xs,
  },
})

export default styles
