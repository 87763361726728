import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const EditLocationIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M20.7101 1.99957L20.0001 1.28957C19.6101 0.89957 18.9801 0.89957 18.5901 1.28957L17.8701 2.00957L19.9901 4.12957L20.7101 3.40957C21.1001 3.01957 21.1001 2.38957 20.7101 1.99957Z" />
    <Path d="M11 10.9997H13.12L19.28 4.83973L17.16 2.71973L11 8.87973V10.9997Z" />
    <Path d="M13.54 13H10C9.45 13 9 12.55 9 12V8.46C9 8.2 9.11 7.94 9.29 7.76L14.61 2.44C13.78 2.16 12.9 2 12 2C7.8 2 4 5.22 4 10.2C4 13.38 6.44 17.12 11.33 21.42C11.71 21.75 12.29 21.75 12.67 21.42C17.56 17.12 20 13.37 20 10.2C20 9.19 19.84 8.26 19.55 7.4L14.24 12.71C14.06 12.89 13.8 13 13.54 13Z" />
  </Svg>
)

export default EditLocationIcon
