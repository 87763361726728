import { View, Text, StyleSheet } from 'react-native'
import React, { useCallback, useMemo, useState } from 'react'

import { CalendarMonthIcon, ExpandLessIcon, ExpandMoreIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { OrderBooking } from 'src/graphql/Order/types'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  bookingsList: OrderBooking[]
}

const BookingsList = ({ bookingsList }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const bookingsListToDisplay = useMemo(() => {
    const partialList = bookingsList.slice(0, 5)
    return isExpanded ? bookingsList : partialList
  }, [isExpanded, bookingsList])

  const title = useMemo(() => {
    const days = bookingsList?.length
    const startDate = formatter.shortDayAndMonth(bookingsList[0].startTime)
    const endDate = formatter.shortDayAndMonth(bookingsList[days - 1].endTime)
    return `${translate(translations.days, { days })}, ${startDate} - ${endDate}`
  }, [bookingsList])

  const renderBookingRow = useCallback(
    (booking: OrderBooking, index: number) => {
      const day = formatter.weekdayWithShortDate(booking.startTime)
      const startTime = formatter.time(booking.startTime)
      const endTime = formatter.time(booking.endTime)

      const isLastRow = index === bookingsListToDisplay?.length - 1

      return (
        <View key={booking.id} style={StyleSheet.flatten([styles.bookingRow, isLastRow && { borderBottomWidth: 0 }])}>
          <Text style={styles.dayText}>{day}</Text>
          <Text style={styles.timeText}>
            {startTime} - {endTime}
          </Text>
        </View>
      )
    },
    [bookingsListToDisplay],
  )

  return (
    <>
      <View style={styles.titleContainer} testID="bookings-list-section">
        <CalendarMonthIcon fill={colorsNew.redesign.text.primary} size={18} />
        <Text style={styles.title}>{title}</Text>
      </View>
      {bookingsListToDisplay.map(renderBookingRow)}
      {bookingsList.length > 5 ? (
        <View style={styles.button}>
          <ButtonNew
            onPress={() => setIsExpanded(prev => !prev)}
            title={
              isExpanded ? translate(translations.jobOfferHideBookings) : translate(translations.jobOfferShowBookings)
            }
            RightIcon={isExpanded ? ExpandLessIcon : ExpandMoreIcon}
            variant={buttonVariants.outlinedDefault}
          />
        </View>
      ) : null}
    </>
  )
}

export default BookingsList
