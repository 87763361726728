import React from 'react'
import { StyleSheet, Text } from 'react-native'
import Animated, { FadeInUp, FadeOutUp } from 'react-native-reanimated'

import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  showHeader: boolean
}

const LoginFormHeader = ({ showHeader }: Props) =>
  showHeader ? (
    <Animated.View entering={FadeInUp.delay(100)} exiting={FadeOutUp}>
      <Text
        style={StyleSheet.flatten([styles.headerInfoContainer, global.isSM && styles.headerInfoContainerSmall])}
        testID="login-header"
      >
        <Text>{translate(translations.loginHeader1)}</Text>
        <Text style={styles.headerInfoGreenText}>{translate(translations.loginHeader2)}</Text>
        <Text>{translate(translations.loginHeader3)}</Text>
      </Text>
    </Animated.View>
  ) : null

export default LoginFormHeader
