import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew } from 'src/styles'
import globalStyles from 'src/global/globalStyles'

const isWeb = Platform.OS === 'web'

const styles = StyleSheet.create({
  overlayContainer: {
    alignItems: 'flex-start',
    backgroundColor: colorsNew.redesign.background.paper,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    elevation: 8,
    flexDirection: 'row',
    height: 120,
    left: 0,
    paddingHorizontal: paddingNew.sm,
    shadowColor: colorsNew.black,
    shadowOpacity: 0.1,
    shadowRadius: 4.65,
    shadowOffset: {
      width: 0,
      height: -2,
    },
    width: isWeb ? '100%' : Dimensions.get('window').width,
    ...globalStyles.containerSize,
  },
  overlayContainerLarge: {
    height: 156,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: paddingNew.base,
  },
})

export default styles
