import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  fileCard: {
    alignItems: 'center',
    backgroundColor: colorsNew.white,
    borderRadius: 8,
    elevation: 6,
    flexDirection: 'row',
    marginBottom: paddingNew.sm,
    padding: paddingNew.sm - 4,
    paddingRight: paddingNew.xs,
    shadowColor: colorsNew.black,
    width: '100%',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.45,
    shadowRadius: 4.5,
  },
  fileName: {
    ...typography.caption,
    color: colorsNew.redesign.text.primary,
    flex: 1,
    paddingHorizontal: paddingNew.xs,
  },
  iconWrapper: {
    borderColor: colorsNew.redesign.action.active,
    borderRadius: 50,
    borderWidth: 1,
    padding: paddingNew.xxs,
  },
})
