import React from 'react'
import { ScrollView, View } from 'react-native'

import { useBugsnagView } from 'src/utils/bugsnag'
import MonthStatsList from './components/MonthStatsList'
import Summary from './components/Summary'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const History = () => {
  useBugsnagView('History')

  return (
    <ScrollView contentContainerStyle={globalStyles.containerSize} testID="scrollView">
      <View style={styles.container}>
        <View style={styles.containerPadding}>
          <Summary mainTitle={translate(translations.yourCompletedJobs)} />
        </View>
        <MonthStatsList />
      </View>
    </ScrollView>
  )
}

export default History
