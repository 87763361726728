import { StyleSheet } from 'react-native'

import { colors, paddingNew, typography, colorsNew } from 'src/styles'

const paddingTop = paddingNew.base

export default StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingHorizontal: paddingNew.sm,
    paddingTop: paddingTop,
  },
  emptyContainer: {
    paddingTop: paddingNew.lg - paddingTop,
  },
  emptyImage: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  emptyTitle: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
    textAlign: 'center',
  },
  emptyDescription: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    textAlign: 'center',
  },
})
