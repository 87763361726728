import React from 'react'
import { View, Text } from 'react-native'

import { DotTextRow } from 'src/components/DotTextRow'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

const SafeguardingDeclarationInfo = () => (
  <>
    <Text style={styles.mainText}>{translate(translations.safeguardingInfo1)}</Text>
    <View style={styles.backgroundContainer}>
      <View style={styles.backgroundContainerFill} />
      <Text style={styles.secondaryText}>{translate(translations.safeguardingInfo2)}</Text>
      <DotTextRow
        containerStyle={styles.pointsContainer}
        index={1}
        numeric
        text={translate(translations.safeguardingInfo2a)}
        textStyle={styles.points}
      />
      <DotTextRow
        containerStyle={styles.pointsContainer}
        index={2}
        numeric
        text={translate(translations.safeguardingInfo2b)}
        textStyle={styles.points}
      />
      <DotTextRow
        containerStyle={styles.pointsContainer}
        index={3}
        numeric
        text={translate(translations.safeguardingInfo2c)}
        textStyle={styles.points}
      />
    </View>

    <Text style={styles.secondaryText}>{translate(translations.safeguardingInfo3)}</Text>

    <View style={styles.alertContainer}>
      <AlertCard
        colorSchema={alertColorScheme.info}
        description={translate(translations.safeguardingInfoAlert)}
        variant={alertVariants.outlined}
      />
    </View>
  </>
)

export default SafeguardingDeclarationInfo
