import React from 'react'
import Svg, { Path, Circle, Rect } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ChatIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.0554 11.8532C17.0554 13.0053 16.6881 14.0716 16.0642 14.9413L16.6162 17.0015L16.6162 17.0015L16.6162 17.0015C16.6496 17.1261 16.6663 17.1884 16.6493 17.2313C16.6351 17.2671 16.6067 17.2955 16.5709 17.3097C16.528 17.3266 16.4657 17.3099 16.3411 17.2766L16.3411 17.2766L14.0122 16.6525C13.3264 16.9761 12.5601 17.157 11.7516 17.157C8.82242 17.157 6.44784 14.7824 6.44784 11.8532C6.44784 8.92404 8.82242 6.54946 11.7516 6.54946C14.6808 6.54946 17.0554 8.92404 17.0554 11.8532ZM10.1039 11.8588C10.1039 12.2633 9.77603 12.5911 9.37159 12.5911C8.96714 12.5911 8.63927 12.2633 8.63927 11.8588C8.63927 11.4544 8.96714 11.1265 9.37159 11.1265C9.77603 11.1265 10.1039 11.4544 10.1039 11.8588ZM11.8103 12.5911C12.2148 12.5911 12.5426 12.2633 12.5426 11.8588C12.5426 11.4544 12.2148 11.1265 11.8103 11.1265C11.4059 11.1265 11.078 11.4544 11.078 11.8588C11.078 12.2633 11.4059 12.5911 11.8103 12.5911ZM14.9814 11.8588C14.9814 12.2633 14.6535 12.5911 14.249 12.5911C13.8446 12.5911 13.5167 12.2633 13.5167 11.8588C13.5167 11.4544 13.8446 11.1265 14.249 11.1265C14.6535 11.1265 14.9814 11.4544 14.9814 11.8588Z"
    />
  </Svg>
)

export default ChatIcon
