import React, { useContext, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { useQuery } from '@apollo/client'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, ErrorAlert, errorTypes, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DotTextRow } from 'src/components/DotTextRow'
import { DownloadIcon } from 'src/icons'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import LoadingIndicator from 'src/components/LoadingIndicator'
import PolicyDocumentAcceptances, { PolicyDocumentAcceptancesRequestTypes } from 'src/graphql/DocumentAcceptances'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'PolicyAgreements'>

const DOCUMENT_TYPE = 'policy_agreements'
const policies = Array.from({ length: 4 }, (_, i) => i + 1)

const PolicyAgreements = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - PolicyAgreements')

  const userId = useAppSelector(state => state.user.id) as string
  const dispatch = useAppDispatch()

  const [isCompleteTaskError, setIsCompleteTaskError] = useState(false)
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(isCompleted)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isUrlError, setIsUrlError] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const { data, loading } = useQuery<PolicyDocumentAcceptancesRequestTypes>(PolicyDocumentAcceptances, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
      documentTypes: [DOCUMENT_TYPE],
    },
    onCompleted: res => {
      const isPolicyDoc = res.policyDocumentAcceptances.nodes.some(
        policy => policy.policyDocument.type === DOCUMENT_TYPE,
      )
      setIsPolicyAccepted(isPolicyDoc)
    },
    onError: () => setIsUrlError(true),
  })

  const policyDocument = data?.policyDocuments.nodes.find(item => item.type === DOCUMENT_TYPE)

  const handleError = () => {
    setIsCompleteTaskError(true)
    setIsSubmitting(false)
  }

  const closeUrlError = () => setIsUrlError(false)

  const completeTask = () => {
    setIsSubmitting(true)
    setIsCompleteTaskError(false)
    const newUser = {
      acceptedPolicyDocumentIds: [policyDocument?.id],
    }
    bugsnagActionBreadcrumb('submit on template', { id: userId, user: newUser })

    dispatch(
      saveUser({
        id: userId,
        onError: handleError,
        onSuccess: () => markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack, handleError),
        stringifyData: true,
        user: newUser,
      }),
    )
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <View style={styles.section}>
        <Text style={styles.mainText}>{translate(translations.companyPoliciesInfo)}</Text>
        {policies.map((policy, index) => (
          <DotTextRow
            containerStyle={styles.textWithDotContainer}
            key={index}
            text={translate(translations[`companyPoliciesInfoPoint${policy}`])}
            textStyle={styles.textWithDot}
          />
        ))}
        <View style={styles.documentSection}>
          {isUrlError ? <ErrorAlert hideError={closeUrlError} type={errorTypes.file} /> : null}
          <View style={styles.buttonWrapper}>
            <ButtonNew
              LeftIcon={DownloadIcon}
              onPress={() => openURLIfCan(policyDocument?.url)}
              title={translate(translations.companyPoliciesDownloadButton)}
              variant={buttonVariants.outlinedDefault}
            />
          </View>
        </View>
        <Text style={styles.title}>{translate(translations.companyPoliciesDownloadInfo)}</Text>
      </View>
      <Checkbox
        disabled={isCompleted}
        label={translate(translations.companyPoliciesCheckbox)}
        onPress={() => setIsPolicyAccepted(prev => !prev)}
        selected={isPolicyAccepted}
      />
      {isCompleteTaskError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsCompleteTaskError(false)} />
        </View>
      ) : null}
      <View style={StyleSheet.flatten([styles.buttonWrapper, styles.buttonExtraSpace])}>
        <ButtonNew
          disabled={isCompleted || !isPolicyAccepted}
          onPress={completeTask}
          isSubmitting={isSubmitting}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
    </ScrollView>
  )
}

export default PolicyAgreements
