import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { BottomTabBarButtonProps, BottomTabNavigationOptions } from '@react-navigation/bottom-tabs/src/types'

import { colors, colorsNew, fonts, fontSizes } from 'src/styles'
import globalStyles from 'src/global/globalStyles'

const tabBarButtonWrapperStyles = { width: '25%' }
const tabBarButtonIndicatorStyles = (props: BottomTabBarButtonProps): StyleProp<ViewStyle> => ({
  borderBottomColor: props?.accessibilityState?.selected ? colors.white : colors.transparent,
  borderBottomWidth: 2,
  bottom: 0,
  position: 'absolute',
  width: '100%',
})

export const webTabNavigatorConfig = {
  screenOptions: {
    headerShown: false,
    tabBarItemStyle: {
      width: '100%',
    },
    tabBarStyle: {
      ...globalStyles.containerSize,
      backgroundColor: 'none',
      borderWidth: 0,
      height: '100%',
    },
    tabBarIcon: () => null,
    tabBarIconStyle: { display: 'none' },
    tabBarContainerStyles: {
      height: '100%',
      justifyContent: 'center',
    },
    tabBarLabelStyle: {
      color: colorsNew.black,
      fontFamily: fonts.poppinsMedium,
      fontSize: fontSizes.xxs,
      marginLeft: 0,
      textTransform: 'uppercase',
    },
    containerStyles: {
      flexDirection: 'column-reverse',
    },
    tabContainerStyles: {
      backgroundColor: colorsNew.redesign.primary.main,
      height: 64,
      justifyContent: 'center',
    },
    tabBarButton: (props: BottomTabBarButtonProps) => (
      <View style={tabBarButtonWrapperStyles}>
        <TouchableOpacity {...props} />
        <View style={tabBarButtonIndicatorStyles(props)} />
      </View>
    ),
  },
} as BottomTabNavigationOptions
