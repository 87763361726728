import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  loading: {
    marginVertical: paddingNew.sm,
  },
})

export default styles
