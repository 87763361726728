import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  infoContainer: {
    marginBottom: paddingNew.base,
    marginHorizontal: paddingNew.sm,
  },
  infoBody: {
    alignItems: 'center',
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: paddingNew.xs,
  },
  badge: {
    alignItems: 'center',
    borderRadius: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: paddingNew.xs,
    marginVertical: paddingNew.xxs,
    paddingHorizontal: 14,
    paddingVertical: 3,
  },
  badgeText: {
    ...typography.chipTextSmall,
  },
  description: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles
