import moment, { Moment } from 'moment'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
require('number-to-locale-string-polyfill')

import { scheduleTypeText } from 'src/global/constants'
import translations, { translate } from 'src/utils/translations/translations'
import { momentWithTimezone } from 'src/global/utils'
import { LocaleT, SmallLocationT, TranslationGBT } from './translations/i18nTypes'
import { TimeScheduleTypes } from '../graphql/sharableTypes'

let locale = 'sv'
let currency = 'SEK'

const SE_SALARY_RATE = 13

export type CurrencyType = 'GBP' | 'SEK'

export const setFormatter = (newLocale: SmallLocationT, newCurrency: CurrencyType) => {
  locale = newLocale
  currency = newCurrency
  moment.locale(newLocale)
}

const formatPrice = (price: number | string, decimal = 2) =>
  typeof price === 'number'
    ? (0)
        .toLocaleString(locale === 'sv' ? 'sv-SE' : locale, {
          style: 'currency',
          currency,
          currencyDisplay: 'symbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace('0', price.toFixed(decimal))
    : ''

const getSESalary = (salary: number) => salary + (salary / 100) * SE_SALARY_RATE

type GetSalarySubtitleType = {
  countryCode?: LocaleT
  holidayRate?: number
  salary: number
  scheduleType?: keyof typeof scheduleTypeText
  isMultiday?: boolean
}
const getSalarySubtitle = (
  {
    countryCode = 'se',
    holidayRate = 0,
    salary,
    scheduleType = scheduleTypeText.hourly,
    isMultiday = false,
  } = {} as GetSalarySubtitleType,
) => {
  if (!salary) return '-'

  const isHolidayRateFalsy = !holidayRate
  const isHourly = scheduleType === 'hourly' ? translate(translations.perHour) : ''
  const isFixed = !isHourly && !isMultiday ? `${translate(translations.total)}: ` : ''

  if (countryCode === 'gb') {
    const paymentInfo = `${isFixed}${formatPrice(salary)} ${translate((translations as TranslationGBT).holidayPayment, {
      salary: !isHolidayRateFalsy ? formatPrice(holidayRate, 2) : `${translate(translations.currency)}0`,
    })} ${isHourly}`

    return paymentInfo
  }

  const salaryOnHands = formatPrice(getSESalary(salary))
  const salaryOnHandsInfo = `(${formatPrice(salary)} + ${SE_SALARY_RATE}%)`

  return `${salaryOnHands}/${translate(translations.hoursShort)} ${salaryOnHandsInfo}`
}

const getFormattedDuration = (duration: Moment) =>
  duration.minutes() ? duration.format('H[h] mm[min]').replace('s', '') : duration.format('H[h]')

const timeFormat = 'HH:mm'
const timeFormatGB = 'h:mm A'

const commonFormats = {
  apiFormat: 'YYYY-MM-DD',
  day: 'D',
  dayOfWeek: 'ddd',
  dayOfWeekLong: 'dddd',
  interval: 'H:mm',
  month: 'MMM',
  monthWithYear: 'MMMM YYYY',
} as const

const FORMATS = {
  sv: {
    ...commonFormats,
    longDate: 'DD MMM YYYY',
    longDateAndTime: `DD MMM YYYY ${timeFormat}`,
    time: timeFormat,
    shortDate: 'YYYY-MM-DD',
    shortDateAndTime: `YYYY-MM-DD ${timeFormat}`,
    dayAndMonth: 'DD MMM',
    dayAndShortMonth: 'D/MM',
    shortDayAndMonth: 'D MMM',
    weekDay: 'DD ddd',
    weekdayAndMonth: 'ddd DD MMM',
    weekdayAndFullMonth: 'ddd DD MMMM',
    shortWeekdayAndMonth: 'dddd, MMM-DD',
    longWeekdayAndMonth: 'dddd, DD MMMM',
    weekdayMonthYear: 'ddd D/M/YYYY',
    fullDayOfWeekFormat: 'dddd, D MMMM',
    veryShortDate: 'DD/MM',
    weekdayWithShortDate: 'ddd D/MM',
  },
  'en-gb': {
    ...commonFormats,
    longDate: 'DD MMM YYYY',
    longDateAndTime: `DD MMM YYYY ${timeFormat}`,
    time: timeFormatGB,
    shortDate: 'DD/MM/YYYY',
    shortDateAndTime: `DD/MM/YYYY ${timeFormat}`,
    dayAndMonth: 'DD MMM',
    dayAndShortMonth: 'D/MM',
    shortDayAndMonth: 'D MMM',
    weekDay: 'DD ddd',
    weekdayAndMonth: 'ddd DD MMM',
    weekdayAndFullMonth: 'ddd DD MMMM',
    shortWeekdayAndMonth: 'dddd, MMM-DD',
    longWeekdayAndMonth: 'dddd, DD MMMM',
    weekdayMonthYear: 'ddd DD/MM/YYYY',
    fullDayOfWeekFormat: 'dddd, D MMMM',
    veryShortDate: 'DD/MM',
    weekdayWithShortDate: 'ddd D/M',
  },
} as const

type dateT = string | Date
type formatLocaleT = 'sv' | 'en-gb'
type formatsT = typeof FORMATS['sv'] | typeof FORMATS['en-gb']
type formatKeys = keyof formatsT
type formatValues = formatsT[formatKeys]

const formats = {} as formatsT
// @ts-ignore
Object.keys(FORMATS[locale as formatLocaleT]).forEach((key: formatKeys) => (formats[key] = key))
// @ts-ignore
const getFormat = (format: formatValues) => FORMATS[locale as formatLocaleT][format]

export const formatDate = (format: formatValues) => (date?: dateT) =>
  date ? momentWithTimezone(date).format(format) : momentWithTimezone().format(format)
export const formatDateWithoutTimezone = (format: formatValues) => (date: dateT) =>
  date ? moment.utc(date).format(format) : moment.utc().format(format)

const apiFormat = (date?: dateT) => formatDate(getFormat(formats.apiFormat))(date)
const apiFormatWithoutTimezone = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.apiFormat))(date)
const day = (date: dateT) => formatDate(getFormat(formats.day))(date)
const dayAndMonth = (date: dateT) => formatDate(getFormat(formats.dayAndMonth))(date)
const dayOfWeek = (date: dateT) => formatDate(getFormat(formats.dayOfWeek))(date)
const dayOfWeekLong = (date: dateT) => formatDate(getFormat(formats.dayOfWeekLong))(date)
const dayOfWeekWithoutTimezone = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.dayOfWeek))(date)
const dayWithoutTimezone = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.day))(date)
const fullDay = (date: dateT) => formatDate(getFormat(formats.fullDayOfWeekFormat))(date)
const isInFuture = (dateTime: dateT) => moment(dateTime) > moment()
const isToday = (dateTime: dateT) => moment(dateTime).diff(moment(), 'days') === 0
const longDate = (date: dateT) => formatDate(getFormat(formats.longDate))(date)
const longDateAndTime = (date: dateT) => formatDate(getFormat(formats.longDateAndTime))(date)
const longWeekdayAndMonth = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.longWeekdayAndMonth))(date)
const longWeekdayAndMonthWithTimezone = (date: dateT) => formatDate(getFormat(formats.longWeekdayAndMonth))(date)
const month = (date: dateT) => formatDate(getFormat(formats.month))(date)
const monthWithDay = (date: dateT) => formatDate(getFormat(formats.veryShortDate))(date)
const monthWithDayWithoutTimezone = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.veryShortDate))(date)
const monthWithoutTimezone = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.month))(date)
const monthWithYear = (date: dateT) => formatDate(getFormat(formats.monthWithYear))(date)
const shortDate = (date: dateT) => formatDate(getFormat(formats.shortDate))(date)
const shortDateWithoutTimezone = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.shortDate))(date)
const shortDayAndMonth = (date: dateT) => formatDate(getFormat(formats.shortDayAndMonth))(date)
const shortWeekdayAndMonth = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.shortWeekdayAndMonth))(date)
const time = (date: dateT) => formatDate(getFormat(formats.time))(date)
const timeWithoutTimeZone = (date: dateT) => formatDateWithoutTimezone(getFormat(formats.time))(date)
const weekday = (date: dateT) => formatDate(getFormat(formats.weekDay))(date)
const weekdayAndMonth = (date: dateT) => formatDate(getFormat(formats.weekdayAndMonth))(date)
const weekdayAndFullMonth = (date: dateT) => formatDate(getFormat(formats.weekdayAndFullMonth))(date)
const weekdayAndMonthWithoutTimezone = (date: dateT) =>
  formatDateWithoutTimezone(getFormat(formats.weekdayAndMonth))(date)
const weekdayMonthYear = (date: dateT) => formatDate(getFormat(formats.weekdayMonthYear))(date)
const weekdayWithShortDate = (date: dateT) => formatDate(getFormat(formats.weekdayWithShortDate))(date)
const weekdayWithShortDateWithoutTimezone = (date: dateT) =>
  formatDateWithoutTimezone(getFormat(formats.weekdayWithShortDate))(date)

const getTimeIntervalString = (startDate: dateT, endDate: dateT) => {
  if (moment(startDate).isSame(moment(endDate), 'day')) {
    return `${momentWithTimezone(startDate).format('D MMM HH:mm')} - ${momentWithTimezone(endDate).format('HH:mm')}`
  }

  return `${momentWithTimezone(startDate).format('D MMM')} - ${momentWithTimezone(endDate).format('D MMM')}`
}

const getTimeIntervalStringWithShortFormat = (startDate: dateT, endDate: dateT, isGB: boolean) => {
  if (moment(startDate).isSame(moment(endDate), 'day')) {
    const newTimeFormat = isGB ? timeFormatGB : timeFormat

    return `${momentWithTimezone(startDate).format(`D/MM ${timeFormat}`)}-${momentWithTimezone(endDate).format(
      newTimeFormat,
    )}`
  }

  return `${momentWithTimezone(startDate).format('D/MM')}-${momentWithTimezone(endDate).format('D/MM')}`
}

const getTimeIntervalStringWithoutTimezone = (startDate: dateT, endDate: dateT) => {
  if (moment.utc(startDate).isSame(moment.utc(endDate), 'day')) {
    return `${formatDateWithoutTimezone(getFormat(formats.dayAndShortMonth))(startDate)}`
  } else {
    return `${formatDateWithoutTimezone(getFormat(formats.dayAndShortMonth))(startDate)}-${formatDateWithoutTimezone(
      getFormat(formats.dayAndShortMonth),
    )(endDate)}`
  }
}

const interval = (startDate: dateT, endDate: dateT) =>
  `${formatDate(getFormat(formats.interval))(startDate)}-${formatDate(getFormat(formats.interval))(endDate)}`

const getTimeAgo = (dateString: string) => {
  const date = moment(dateString)
  const diff = moment.duration(moment().diff(date))
  if (moment().isSame(date, 'day')) {
    if (moment().isSame(date, 'hour')) {
      return diff.asMinutes().toFixed(0) + 'min'
    } else {
      return diff.asHours().toFixed(0) + 'h'
    }
  } else {
    return diff.asDays().toFixed(0) + 'd'
  }
}

const getDifferenceInDays = (date: Moment) => date.diff(moment(), 'days')
const getDifferenceInMinutes = (startTime: dateT, endTime: dateT) => moment(endTime).diff(moment(startTime), 'minutes')
const getDatesDifferenceInDays = (endDay: dateT, startDay: dateT, shouldBeRounded = true) =>
  moment(endDay).diff(moment(startDay), 'days', !shouldBeRounded)
const getDatesDifferenceInHours = (startDay: dateT, endDay: dateT, shouldBeRounded = true) =>
  moment(endDay).diff(moment(startDay), 'hours', !shouldBeRounded)

const scheduleTimeText = (scheduleType: TimeScheduleTypes, scheduleTime: string) => {
  const isHourly = scheduleType === 'hourly' || !scheduleType
  return isHourly ? scheduleTime : scheduleTypeText[scheduleType]
}

const getFirstMonthDay = (date: dateT) => moment(date).startOf('month').format(commonFormats.apiFormat)
const getLastMonthDay = (date: dateT) => moment(date).endOf('month').format(commonFormats.apiFormat)

function convertMinutesToHoursAndMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = Math.floor(minutes % 60)

  let result = ''

  if (hours > 0) {
    result += `${hours}h`
  }

  if (remainingMinutes > 0) {
    if (result !== '') {
      result += ' '
    }

    result += `${remainingMinutes}min`
  }

  return result
}

export default {
  apiFormat,
  apiFormatWithoutTimezone,
  convertMinutesToHoursAndMinutes,
  day,
  dayAndMonth,
  dayOfWeek,
  dayOfWeekLong,
  dayOfWeekWithoutTimezone,
  dayWithoutTimezone,
  formatPrice,
  fullDay,
  getDatesDifferenceInDays,
  getDatesDifferenceInHours,
  getDifferenceInDays,
  getDifferenceInMinutes,
  getFirstMonthDay,
  getFormat,
  getFormattedDuration,
  getLastMonthDay,
  getSalarySubtitle,
  getSESalary,
  getTimeAgo,
  getTimeIntervalString,
  getTimeIntervalStringWithoutTimezone,
  getTimeIntervalStringWithShortFormat,
  interval,
  isInFuture,
  isToday,
  longDate,
  longDateAndTime,
  longWeekdayAndMonth,
  longWeekdayAndMonthWithTimezone,
  month,
  monthWithDay,
  monthWithDayWithoutTimezone,
  monthWithoutTimezone,
  monthWithYear,
  scheduleTimeText,
  shortDate,
  shortDateWithoutTimezone,
  shortDayAndMonth,
  shortWeekdayAndMonth,
  time,
  timeWithoutTimeZone,
  weekday,
  weekdayAndMonth,
  weekdayAndFullMonth,
  weekdayAndMonthWithoutTimezone,
  weekdayMonthYear,
  weekdayWithShortDate,
  weekdayWithShortDateWithoutTimezone,
}
