import React, { useCallback, useState } from 'react'

import { getNorthpassLoginUrl } from 'src/utils/northpassUrl'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import MenuItem from './MenuItem'
import translations, { translate } from 'src/utils/translations/translations'
import { SchoolIcon } from 'src/icons'
import { openURLIfCan } from 'src/global/utils'

const AcademyMenuItem = () => {
  const [isNorthpassUrlError, setIsNorthpassUrlError] = useState(false)
  const [isNorthpassUrlLoading, setIsNorthpassUrlLoading] = useState(false)

  const goToAcademy = (url: string) => url && openURLIfCan(url)

  const onSuccess = useCallback((url: string) => {
    setIsNorthpassUrlError(false)
    setIsNorthpassUrlLoading(false)
    goToAcademy(url)
  }, [])

  const onError = useCallback(() => {
    setIsNorthpassUrlError(true)
    setIsNorthpassUrlLoading(false)
  }, [])

  const onAcademyPress = () => {
    bugsnagActionBreadcrumb("Set user's Northpass academy training url on menu item")

    if (!isNorthpassUrlLoading) {
      setIsNorthpassUrlLoading(true)
      getNorthpassLoginUrl({ onError, onSuccess })
    }
  }

  return (
    <MenuItem
      text={translate(translations.academy)}
      Icon={SchoolIcon}
      isLoading={isNorthpassUrlLoading}
      isDisabled={isNorthpassUrlError || isNorthpassUrlLoading}
      onPress={onAcademyPress}
    />
  )
}

export default AcademyMenuItem
