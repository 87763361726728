export const drawCirclePath = (lat: number, lng: number, radius: number, detail = 8) => {
  let staticMapSrc = ''
  const r = 6371

  const pi = Math.PI

  const _lat = (lat * pi) / 180
  const _lng = (lng * pi) / 180
  const d = radius / r

  let i = 0

  for (i = 0; i <= 360; i += detail) {
    const brng = (i * pi) / 180

    let pLat = Math.asin(Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng))
    const pLng =
      ((_lng +
        Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(_lat), Math.cos(d) - Math.sin(_lat) * Math.sin(pLat))) *
        180) /
      pi
    pLat = (pLat * 180) / pi

    staticMapSrc += '|' + pLat + ',' + pLng
  }

  return staticMapSrc
}
