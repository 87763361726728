import { createSelector } from 'reselect'
import isBoolean from 'lodash/isBoolean'

import { countryCodeSelector } from 'src/store/app/selectors'
import { StoreType } from '../index'
import { isUserAccepted } from 'src/utils/helpers'
import {
  InvoicingConfigAttributesT,
  StudentEducationAttributesT,
  UserAttributesT,
  UserDocumentAttributesT,
  WorkExperienceAttributesT,
  WorkReferenceAttributesT,
} from './userTypes'

export type UserExperience = WorkExperienceAttributesT & { id: string }
export type UserEducation = StudentEducationAttributesT & { id: string }
export type UserDocuments = UserDocumentAttributesT & { id: string }
export type UserReferences = WorkReferenceAttributesT & { id: string }

const userStore = (state: StoreType) => state.user

export const getUserAttributesState = createSelector([userStore], user => user?.attributes?.state)

export const getUserCompetences = createSelector(
  [userStore],
  user =>
    user?.included
      ?.filter(item => item.type === 'competences')
      ?.map(competence => ({ id: competence.id, ...competence.attributes })) || [],
)

export const getUserExperience = createSelector([userStore], user => {
  const experience = user?.included?.filter(e => e.type === 'work_experiences') || []
  return experience.map(e => ({ ...e?.attributes, id: e.id })) as UserExperience[]
})

export const getUserWorkPermitInfo = createSelector([userStore], user => ({
  workPermitExpiresOn: user?.attributes?.workPermitExpiresOn,
  workPermitRequired: user?.attributes?.workPermitRequired,
}))

export const isUserApplicant = createSelector(
  getUserAttributesState,
  countryCodeSelector,
  (userState, countryCode) => !isUserAccepted(userState as UserAttributesT['state'], countryCode),
)

export const isUserInvitedToRefensa = createSelector(
  [userStore],
  user => user?.attributes?.referencesProcessBy === 'refensa',
)

export const getUserDocumentUK = (documentType: string | string[]) =>
  createSelector([userStore], user => {
    const documentTypes = typeof documentType === 'string' ? [documentType] : documentType
    const userDocuments = user?.included?.filter(item => item.type === 'user_documents') || []
    const pickedDocument = userDocuments
      ?.map(document => {
        if (documentTypes.includes((document.attributes as UserDocumentAttributesT)?.documentType)) {
          return { id: document.id, ...document?.attributes }
        }
      })
      .filter(document => document)
    return pickedDocument as UserDocuments[]
  })

export const getUserEducation = createSelector([userStore], user => {
  const userEducations = user?.included?.filter(item => item.type === 'student_educations') || []
  return userEducations.map((item: { attributes: object; id: string }) => ({
    ...item?.attributes,
    id: item?.id,
  })) as UserEducation[]
})

export const getIsM2Educator = createSelector([userStore], user => {
  const externalRefId = user?.attributes?.externalRefId || ''
  return externalRefId.startsWith('m2/')
})

const invoicingAttributeState = (type: keyof InvoicingConfigAttributesT, attributes: InvoicingConfigAttributesT) =>
  type in attributes && isBoolean(attributes[type]) ? !attributes[type] : null

const invoicingAttributeStateSubTask = (
  type: keyof InvoicingConfigAttributesT,
  attributes: InvoicingConfigAttributesT,
) => (type in attributes && isBoolean(attributes[type]) ? attributes[type] : null)

export const getUserPayrollDetails = createSelector([userStore], user => {
  const userPayrollInfo = {
    bankAccountHolderName: user.attributes?.bankAccountHolderName || '',
    bankAccountNumber: user.attributes?.bankAccountNumber || '',
    bankName: user.attributes?.bankName || '',
    bankSortCode: user.attributes?.bankSortCode || '',
    loanPlanType: user.attributes?.loanPlanType || null,
    nationalInsuranceNumber: user.attributes?.nationalInsuranceNumber || '',
  }
  const invoicingData = user?.included?.filter(included => included.type === 'invoicing_configs') || []
  const attributes = (invoicingData[0]?.attributes || {}) as InvoicingConfigAttributesT

  const invoicingConfig = {
    ukTaxCode: ('ukTaxCode' in attributes && attributes.ukTaxCode) || '',
    studentLoanFullyRepaid: invoicingAttributeState('studentLoanFullyRepaid', attributes),
    studentLoanLeftBeforeApril: invoicingAttributeStateSubTask('studentLoanLeftBeforeApril', attributes),
    postgraduateLoanFullyRepaid: invoicingAttributeState('postgraduateLoanFullyRepaid', attributes),
    postgraduateLoanLeftBeforeApril: invoicingAttributeStateSubTask('postgraduateLoanLeftBeforeApril', attributes),
  }

  return {
    ...userPayrollInfo,
    invoicingConfig,
  }
})

export const getUserReferences = createSelector([userStore], user => {
  const references = user?.included?.filter(item => item.type === 'work_references') || []
  return references.map((item: { attributes: object; id: string }) => ({
    ...item?.attributes,
    id: item?.id,
  })) as UserReferences[]
})
