import gql from 'graphql-tag'

import Region from '../Region'
import Level from '../Level/Fields'
import Subject from '../Subject/Fields'
import Interval from '../Interval/Fields'
import HomeAddress from '../HomeAddress'
import Relative from '../Relative'
import School from '../School/Fields'

export default gql`
  fragment UserFields on User {
    accountDeletionRequestSentAt
    activeInstallation
    email
    contractUrl
    homeAddress {
      ...HomeAddressFields
    }
    id
    intervals {
      ...IntervalFields
    }
    otherAddresses {
      title
      id
      street
      city
      zip
      addressLine2
      county
      geoSearchRadius
      isSearchLocation
      nearbySchoolCount
    }
    preferredKlassNames
    mayExpressBookings
    mySchools {
      ...SchoolFields
    }
    dislikedSchools {
      ...SchoolFields
    }
    name
    phone
    safeguardingTrainingOn
    salutation
    landPhone
    preferredLevels {
      ...LevelFields
    }
    permittedLevels {
      ...LevelFields
    }
    preferredSubjects {
      ...SubjectFields
    }
    profileDescription
    profilePictureUrl
    publicMedicalNotes
    relative {
      ...RelativeFields
    }
    state
    region {
      ...RegionFields
    }
  }

  ${School}
  ${HomeAddress}
  ${Interval}

  ${Level}
  ${Subject}
  ${Relative}
  ${Region}
`
