import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginTop: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
    paddingBottom: paddingNew.md,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  mainInfo: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  subInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginBottom: paddingNew.sm,
  },
  wrapper: {
    marginTop: paddingNew.xs,
  },
})

export default styles
