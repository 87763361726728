import React from 'react'
import { Text, View } from 'react-native'

import ExpandableText from 'src/components/ExpandableText'
import styles from '../styles'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  content?: string | null
  title: string
}

const ExpandableSection = ({ content, title }: Props) => {
  const normalizedContent = !content ? translate(translations.informationMissing) : content

  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>{title}</Text>
      <ExpandableText fullText={normalizedContent} maxLines={4} />
    </View>
  )
}

export default ExpandableSection
