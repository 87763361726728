import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const starFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.2483 16.9546L11.9984 15.043L8.75476 16.9546C8.51589 17.0955 8.22044 16.8872 8.2833 16.6237L9.1445 13.0211L6.27803 10.601C6.0643 10.4233 6.17745 10.0863 6.45404 10.0618L10.232 9.74936L11.7092 6.35507C11.8161 6.10387 12.1807 6.10387 12.2876 6.35507L13.7648 9.74936L17.5427 10.0618C17.8256 10.0863 17.9325 10.4233 17.725 10.601L14.8586 13.0211L15.7198 16.6237C15.7826 16.8872 15.4872 17.0955 15.2483 16.9546Z"
    />
  </Svg>
)

export default starFilledIcon
