import { StyleSheet } from 'react-native'

import { colors, colorsNew, padding, fontSizes, fonts } from 'src/styles'

export default StyleSheet.create({
  radioButtonContainer: {
    flexDirection: 'row',
    paddingHorizontal: padding.sm,
    paddingVertical: padding.sm,
  },
  radioButtonContainerSelected: {
    backgroundColor: colors.green._100,
  },
  radioButtonText: {
    color: colorsNew.redesign.text.primary,
    flex: 1,
    fontFamily: fonts.muktaRegular,
  },
  radioButton: {
    alignItems: 'center',
    borderColor: colors.grey._500,
    borderRadius: fontSizes.md,
    borderWidth: 1,
    height: fontSizes.md,
    justifyContent: 'center',
    marginRight: padding.sm,
    width: fontSizes.md,
  },
  radioButtonSelected: {
    borderColor: colorsNew.redesign.text.primary,
  },
  radioButtonIcon: {
    backgroundColor: colors.primary,
    borderRadius: fontSizes.xxs,
    height: fontSizes.xxs,
    width: fontSizes.xxs,
  },
})
