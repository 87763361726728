import { ExpressBookingsTypes } from 'src/graphql/ExpressBookings'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

import translations, { translate } from 'src/utils/translations/translations'
import { JobListSectionType, JobItem, AllJobItems } from 'src/components/List/JobsSectionList'
import { sortingOfferCriteria } from '../utils'
import { BookingListItemType } from 'src/graphql/Booking/types'
import { OrderListItemType } from 'src/graphql/Order/types'

type SectionItem = {
  [date: string]: JobListSectionType
}

type GroupingWithSectionTitleT = {
  group: SectionItem
  isFirstSection: boolean
  title: string
  withEmptyMessage?: boolean
}

const setGroupingWithSectionTitle = ({ group, isFirstSection, title, withEmptyMessage }: GroupingWithSectionTitleT) => {
  const sections = Object.values(group)
  const sectionSize = sections.length

  if (sectionSize > 0) {
    sections[0] = {
      ...sections[0],
      isFirstSection: isFirstSection,
      showTitle: true,
      title,
    }

    sections[sectionSize - 1] = {
      ...sections[sectionSize - 1],
      withDivider: false,
    }
  } else if (withEmptyMessage) {
    sections.push({
      data: [
        {
          id: 'empty',
          text: translate(translations.jobsListMatchingEmpty),
        },
      ],
      isFirstSection: isFirstSection,
      showTitle: true,
      title,
    })
  }

  return sections
}

const getDateText = (item: AllJobItems) =>
  ('startDate' in item && item.startDate) ||
  ('date' in item && item.date) ||
  ('startTime' in item && moment(item.startTime).format('YYYY-MM-DD')) ||
  (null as never)

const setNewGroup = (date: string) => ({
  data: [],
  showTitle: false,
  title: date,
  withDivider: true,
})

const getSortedGroups = (list: (BookingListItemType | OrderListItemType | ExpressBookingsTypes)[], userId: string) => {
  const sortedList = sortBy(list, sortingOfferCriteria)

  const requestedGroup: JobItem[] = []
  const groupMatching: SectionItem = {}
  const groupNonMatching: SectionItem = {}

  sortedList.forEach(item => {
    const isRequestedItems =
      item.__typename !== 'ExpressBooking' && item.requestedSubs?.some(request => request?.id === userId)

    if (isRequestedItems) {
      return requestedGroup.push(item)
    }

    const date = getDateText(item) as string

    if (item.isMatching) {
      if (!groupMatching[date]) {
        groupMatching[date] = setNewGroup(date)
      }
      groupMatching[date].data.push(item)
    } else {
      if (!groupNonMatching[date]) {
        groupNonMatching[date] = setNewGroup(date)
      }
      groupNonMatching[date].data.push(item)
    }
  })

  return { requestedGroup, groupMatching, groupNonMatching }
}

export const getJobsOffersSections = (
  list: (BookingListItemType | OrderListItemType | ExpressBookingsTypes)[],
  userId: string,
) => {
  const { requestedGroup, groupMatching, groupNonMatching } = getSortedGroups(list, userId)
  const requestedGroupSize = requestedGroup.length

  const matchingSections = setGroupingWithSectionTitle({
    group: groupMatching,
    isFirstSection: !requestedGroupSize,
    title: translate(translations.jobsListMatching),
    withEmptyMessage: Object.keys(groupNonMatching).length > 0,
  })

  const unappointmentSections = setGroupingWithSectionTitle({
    group: groupNonMatching,
    isFirstSection: !requestedGroupSize,
    title: translate(translations.jobsListNotMatching),
  })

  const requestSection =
    requestedGroupSize > 0
      ? [
          {
            data: requestedGroup,
            showTitle: true,
            title: translate(translations.jobsListRequestedTitle),
            withDivider: false,
          } as JobListSectionType,
        ]
      : []

  const allJobs = [...requestSection, ...matchingSections, ...unappointmentSections]

  if (allJobs.length === 1 && allJobs[0]?.data?.[0]?.id === 'empty') {
    return []
  }

  if (allJobs.length) {
    allJobs[0].withSmallTopSpacing = true
  }

  return allJobs
}
