import React from 'react'
import { View, Text, TextInput } from 'react-native'

import translations, { translate } from 'src/utils/translations/translations'
import RowTitle from './RowTitle'
import styles from './styles'

type Props = {
  description?: string
  error?: string
  isExpanded: boolean
  label: string
  setDescription: (type: string) => void
  toggleSection: () => void
}

const OvertimeRow = ({ isExpanded, toggleSection, setDescription, label, description, error }: Props) => (
  <View style={styles.rowContainer}>
    <RowTitle
      buttonText={isExpanded ? '' : translate(translations.edit)}
      error={error}
      isRequired={isExpanded}
      label={label}
      onToggle={toggleSection}
    />

    {isExpanded ? (
      <>
        <TextInput
          multiline
          onChangeText={setDescription}
          style={styles.input}
          testID="overtime-input"
          underlineColorAndroid="transparent"
          value={description}
        />
      </>
    ) : (
      <Text testID="overtime-description" style={styles.overtimeDescription}>
        {description}
      </Text>
    )}
  </View>
)

export default OvertimeRow
