import React from 'react'
import { Platform } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import Login from 'src/scenes/Welcome/Login'
import ResetPassword from 'src/scenes/Welcome/ResetPassword'
import WebWrapper from 'src/components/WebWrapper'
import { LoginRoutesParamList } from 'src/utils/types/navigationTypes'

const Stack = createNativeStackNavigator<LoginRoutesParamList>()

const LoginRoutes = () => {
  const routes = (
    <Stack.Navigator>
      <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
      <Stack.Screen name="ResetPassword" component={ResetPassword} options={{ headerShown: false }} />
    </Stack.Navigator>
  )

  return Platform.OS === 'web' ? <WebWrapper>{routes}</WebWrapper> : routes
}

export default LoginRoutes
