import gql from 'graphql-tag'

export default gql`
  query BookingsList(
    $bookingType: String
    $limit: Int
    $maxDate: String
    $minDate: String
    $offset: Int
    $region: String
    $skipCancelled: Boolean
    $state: String
    $sort: String
    $appliedByMe: Boolean
    $ids: [String]
  ) {
    bookings(
      limit: $limit
      offset: $offset
      sort: $sort
      filter: {
        bookingType: $bookingType
        maxDate: $maxDate
        minDate: $minDate
        region: $region
        state: $state
        appliedByMe: $appliedByMe
        ids: $ids
        skipCancelled: $skipCancelled
      }
    ) {
      nodes {
        appointedAt
        appointmentType
        appointmentUnconfirmed
        arrivalTime
        cancelled
        competences {
          id
          name
          ref
        }
        departureTime
        endTime
        id
        isMatching
        jobTitle
        region {
          countryCode
        }
        rejected
        reportedTimeScheduleType
        requestedSubs {
          id
        }
        school {
          name
        }
        startTime
        studentApplications {
          claimedAt
          studentId
        }
        studentId
        topics {
          title
        }
        type
      }
    }
  }
`
