import React, { useEffect, useCallback, useMemo } from 'react'
import { Platform, Text, View } from 'react-native'
import { GiftedChat as Gifted, IMessage, GiftedChatProps, ComposerProps, BubbleProps } from 'react-native-gifted-chat'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import CustomComposer from './components/CustomComposer'
import CustomBubble from './components/CustomBubble'
import CustomDay from './components/CustomDay'
import CustomSendButton from './components/CustomSendButton'
import CustomToolbar from './components/CustomToolbar'
import EmptyScreen from './components/EmptyScreen'
import LoadingIndicator from 'src/components/LoadingIndicator'
import MessageTextInfo from './components/MessageTextInfo'
import WarningInfo from './components/WarningInfo'

import { ChatMessages } from 'src/graphql/Chat'
import translations, { translate } from 'src/utils/translations/translations'
import i18n from 'src/utils/translations/i18n'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { countryCodeSelector, isGBSelector } from 'src/store/app/selectors'
import { isSupportOfficeOpen } from '../utils'
import { sendMessage, setMessagesRead } from 'src/store/inbox/actions'
import { useAppSelector } from 'src/hooks/reduxHooks'
import styles from './styles'

export interface ConvertedChatMessage extends IMessage {
  origin?: string
}

type Props = {
  data?: ChatMessages
  hookRefetch?: () => any
  isLoading: boolean
  isLoadingIndicator: boolean
  messages: ConvertedChatMessage[]
  refetch: () => Promise<unknown>
  setMessages: (messages: ConvertedChatMessage[]) => any
  setSending: (isSending: boolean) => any
  shouldShowLoadingOnRefetch: boolean
}

const isWeb = Platform.OS === 'web'

const GiftedChat = ({
  data,
  hookRefetch,
  isLoading,
  isLoadingIndicator,
  messages,
  refetch,
  setMessages,
  setSending,
  shouldShowLoadingOnRefetch,
}: Props) => {
  const isGB = useSelector(isGBSelector)
  const countryCode = useSelector(countryCodeSelector)
  const navigation = useNavigation()
  const id = useAppSelector(state => state?.user?.id) as string
  const messagesList = useMemo(() => data?.messages?.nodes || [], [data])

  const markAllMessagesAsRead = useCallback(() => {
    const newMessages = messagesList
      .filter(message => message?.senderId !== id && message?.read === false)
      .map(message => ({ id: message.id, attributes: { read: true } }))

    if (newMessages.length > 0) setMessagesRead(newMessages)
  }, [messagesList, id])

  useEffect(() => {
    navigation.addListener('beforeRemove', _ => {
      markAllMessagesAsRead()
      if (isWeb && hookRefetch) {
        hookRefetch()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesList])

  const onSend = async (newMsg: IMessage[]) => {
    bugsnagActionBreadcrumb('send message on chat')
    setSending(true)
    await sendMessage(newMsg[0].text, refetch)
    setMessages([...newMsg, ...messages])
  }

  const user = { _id: id }

  const renderBubble = (props: BubbleProps<ConvertedChatMessage>) => <CustomBubble {...props} />
  const renderChatEmpty = () => <>{!isLoading ? <EmptyScreen /> : null}</>
  const renderChatFooter = () => <>{!isSupportOfficeOpen(countryCode) ? <WarningInfo /> : null}</>
  const renderSend = (props: GiftedChatProps) => <CustomSendButton {...props} />
  const renderTicks = (message: ConvertedChatMessage) => (message.origin === 'sms' ? <MessageTextInfo /> : null)
  const renderLoading = () => <LoadingIndicator />
  const renderInputToolbar = (props: GiftedChatProps) => <CustomToolbar {...props} />
  const renderComposer = (props: ComposerProps) => <CustomComposer {...props} />
  const renderDay = (props: GiftedChatProps) => <CustomDay {...props} />
  const renderFooter = () =>
    shouldShowLoadingOnRefetch ? (
      <View style={styles.smallLoadingSpinner}>
        <LoadingIndicator size="small" />
      </View>
    ) : null
  const renderScrollToBottomComponent = () => <Text style={styles.scrollToBottomComponent}>V</Text>

  if (isLoadingIndicator) return <LoadingIndicator />

  return (
    <View style={styles.chatContainer}>
      <Gifted
        {...{
          renderFooter,
          inverted: true,
          isKeyboardInternallyHandled: false,
          locale: i18n.locale,
          messages,
          messagesContainerStyle: styles.messagesContainerStyle,
          onSend,
          placeholder: translate(translations.typeMessage),
          renderBubble,
          renderChatEmpty,
          isLoadingEarlier: true,
          renderChatFooter,
          renderComposer,
          renderDay,
          renderInputToolbar,
          renderLoading,
          renderSend,
          renderTicks,
          renderUsernameOnMessage: true,
          scrollToBottom: true,
          containerExtraStyles: styles.containerExtraStyles,
          scrollToBottomComponent: renderScrollToBottomComponent,
          scrollToBottomStyle: styles.scrollToBottomStyle,
          timeFormat: isGB ? 'hh:mm A' : 'HH:mm',
          user,
          wrapInSafeArea: false,
        }}
      />
    </View>
  )
}

export default GiftedChat
