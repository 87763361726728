import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  disabled: { color: colorsNew.redesign.text.disabled },
  fileDescription: { ...typography.body1, marginBottom: paddingNew.md },
  fileName: { ...typography.caption, flex: 1, paddingHorizontal: paddingNew.sm },
  fileTitle: {
    ...typography.h7,
    marginBottom: paddingNew.md - paddingNew.xxs,
    marginTop: paddingNew.md,
  },
  hyperlink: {
    color: colorsNew.brand.primary,
    textDecorationLine: 'underline',
  },
  loader: {
    backgroundColor: colorsNew.translucentWhite,
    position: 'absolute',
    zIndex: 5,
  },
  modalContainer: { paddingVertical: paddingNew.sm },
  removeIcon: { color: colorsNew.redesign.text.primary },
  spinner: {
    height: 48,
  },
  uploadButtonWrapper: { alignItems: 'center' },
  uploadIcon: { position: 'relative', left: -10 },
})
