import gql from 'graphql-tag'

import SchoolFields from './Fields'
import { SchoolTypes } from './types'

export type { SchoolTypes }

export default gql`
  query Index(
    $name: String
    $limit: Int
    $active: Boolean
    $offset: Int
    $region: String
    $student: String
    $studentVisited: Boolean
  ) {
    schools(
      limit: $limit
      offset: $offset
      filter: { studentVisited: $studentVisited, name: $name, student: $student, region: $region, active: $active }
    ) {
      nodes {
        ...SchoolFields
      }
    }
  }

  ${SchoolFields}
`
