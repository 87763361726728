import React from 'react'

import { CustomModalBaseProps, CustomModalProps } from './types'
import CustomModalBase from './CustomModalBase'
import CustomModalWrapper from './CustomModalWrapper'
export { MODAL_ANIMATION_TIME } from './utils'

export type CustomModalTProps = CustomModalBaseProps & CustomModalProps

const CustomModal = (props: CustomModalTProps) => (
  <CustomModalWrapper {...props}>
    <CustomModalBase {...props} />
  </CustomModalWrapper>
)

export default CustomModal
