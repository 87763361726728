import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  intervalInput: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.secondary.contrast,
    borderColor: colorsNew.redesign.other.outlinedBorder,
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: 'row',
    height: 40,
    justifyContent: 'space-around',
    marginTop: paddingNew.xs,
  },
  intervalInputError: {
    borderColor: colorsNew.redesign.error.main,
  },
  dateText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  dateTextGrey: {
    ...typography.body1,
    color: colorsNew.redesign.text.secondary,
  },
  datePickerContainer: {
    alignItems: 'center',
    flex: 1,
    marginVertical: paddingNew.sm,
  },
  errorText: {
    ...typography.body2,
    color: colorsNew.redesign.error.main,
    marginHorizontal: paddingNew.sm,
    marginTop: paddingNew.xs,
  },
})

export default styles
