import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const { width } = Dimensions.get('window')
const isWeb = Platform.OS === 'web'

export default StyleSheet.create({
  addressRowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.base,
    width: isWeb ? '100%' : width - paddingNew.sm,
  },
  addressInfo: StyleSheet.flatten([
    typography.subtitle2,
    { color: colorsNew.redesign.text.primary, flexWrap: 'wrap', paddingRight: paddingNew.sm },
  ]),
})
