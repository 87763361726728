import React, { useCallback, FunctionComponent, SVGAttributes } from 'react'
import { Dimensions, Platform, StyleProp } from 'react-native'

export type Props = {
  emptyImageStyles?: StyleProp<any>
  Image: FunctionComponent<SVGAttributes<SVGElement>>
  maxWidthPercentage?: number
  originalImageHeight: number
  originalImageWidth: number
}

const isWeb = Platform.OS === 'web'

// @ts-ignore
const round = (value: number, decimals: number) => Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)

const ResponsibleSVG = ({
  Image,
  maxWidthPercentage = 0.45,
  originalImageHeight,
  originalImageWidth,
  emptyImageStyles,
}: Props) => {
  const viewWidth = Dimensions.get('window').width

  const size = useCallback(() => {
    const aspectRatio = originalImageHeight / originalImageWidth

    const width = Math.round((isWeb ? 500 : viewWidth) * round(maxWidthPercentage, 2))
    const height = Math.round(width * aspectRatio)

    return { width, height }
  }, [maxWidthPercentage, originalImageWidth, originalImageHeight, viewWidth])

  return <Image style={{ ...(emptyImageStyles || {}), width: size()?.width, height: size()?.height }} />
}

export default ResponsibleSVG
