import { StyleSheet } from 'react-native'

import { colorsNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    backgroundColor: colorsNew.redesign.background.paper,
    flexDirection: 'row',
  },
})
