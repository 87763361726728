import { resetNavigation } from 'src/utils/navigationService'
import { navigateToAppScreenWithoutHistory } from 'src/utils/navigateToScreen'
import translations, { translate } from 'src/utils/translations/translations'

export const campaignRedirects = {
  preferences: {
    action: () => {
      resetNavigation()
      return navigateToAppScreenWithoutHistory(['MainTabNavigator', 'MenuNavigation', 'Preferences'])
    },
    text: translate(translations.adjustMyPreferences),
  },
} as const

export type CampaignRedirectsKeys = keyof typeof campaignRedirects
