import React from 'react'
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native'

import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'

const isWeb = Platform.OS === 'web'

const EmptyScreen = () => {
  const content = (
    <EmptyPlaceholder
      containerStyles={styles.emptyChatContainer}
      description={translate(translations.chatEmptyDescription)}
      title={translate(translations.chatEmpty)}
      type={placeholderTypes.kite}
    />
  )

  if (isWeb) {
    return content
  }

  return <TouchableWithoutFeedback onPress={Keyboard.dismiss}>{content}</TouchableWithoutFeedback>
}

export default EmptyScreen
