import { AxiosResponse } from 'axios'

import Api from 'src/utils/api'
import { bugsnagNotifyWithData, bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { OnErrorT, OnSuccessT } from 'src/utils/types'

export const deleteActivity = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('delete UserActivity', { id })

  return Api.delete(`student/activities/${id}`, {
    onSuccess: () => onSuccess(),
    onError: (error: AxiosResponse) => {
      bugsnagNotifyWithData('delete UserActivity failed', { id, error })
      onError()
    },
  })
}

export const deleteMessage = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('delete Internal Message', { id })

  return Api.delete(`student/messages/${id}`, {
    onSuccess: () => onSuccess(),
    onError: (error: AxiosResponse) => {
      bugsnagNotifyWithData('delete Internal Message - failed', { id, error })
      onError()
    },
  })
}

export const sendActivityReadStatus = (id: string, isRead: boolean, onError?: OnErrorT) => {
  bugsnagActionBreadcrumb('toggle UserActivity status', { id, isRead })

  return Api.post(`student/bulk_user_activities`, {
    data: JSON.stringify({
      user_activities: [
        {
          id,
          attributes: {
            read: isRead,
          },
        },
      ],
    }),

    onError: (error: AxiosResponse) => {
      bugsnagNotifyWithData('UserActivity read status change failed', { id, error })
      onError && onError()
    },
  })
}

export const sendActivitiesReadStatus = (ids: string[], onError?: OnErrorT) => {
  bugsnagActionBreadcrumb('toggle UserActivities status', { ids })

  const userActivities = ids.map(id => ({
    id,
    attributes: {
      read: true,
    },
  }))

  return Api.post(`student/bulk_user_activities`, {
    data: JSON.stringify({
      user_activities: userActivities,
    }),
    onError: (error: AxiosResponse) => {
      bugsnagNotifyWithData('UserActivity read status change failed', { ids, error })
      onError && onError()
    },
  })
}

export const sendMessageReadStatus = (id: string, isRead: boolean, onError?: OnErrorT) => {
  bugsnagActionBreadcrumb('toggle internal message status', { id, isRead })

  return Api.post(`student/bulk_chat_messages`, {
    data: JSON.stringify({
      messages: [
        {
          id,
          attributes: {
            read: isRead,
          },
        },
      ],
    }),

    onError: (error: AxiosResponse) => {
      bugsnagNotifyWithData('Message read status change failed', { id, error })
      onError && onError()
    },
  })
}

export const sendMessagesReadStatus = (ids: string[], onError?: OnErrorT) => {
  bugsnagActionBreadcrumb('toggle internal messages status', { ids })

  const messages = ids.map(id => ({
    id,
    attributes: {
      read: true,
    },
  }))

  return Api.post(`student/bulk_chat_messages`, {
    data: JSON.stringify({
      messages,
    }),

    onError: (error: AxiosResponse) => {
      bugsnagNotifyWithData('Message read status change failed', { ids, error })
      onError && onError()
    },
  })
}
