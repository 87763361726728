import gql from 'graphql-tag'

export default gql`
  query Show($id: ID!) {
    booking(id: $id) {
      arrivalTime
      cancelled
      competences {
        id
        name
      }
      departureTime
      endTime
      feedbackSubmitted
      holidayPayBaseRate
      id
      jobTitle
      lunchPrice
      overtimeReason
      overtimeRequestedBy
      pupil {
        firstName
        lastName
      }
      reportedTimeScheduleType
      reportedUnpaidTime
      responsibleName
      salaryRate
      school {
        id
        level {
          group
          id
        }
        name
        profilePictureUrl
      }
      startTime
      timelogEditable
      timeScheduleType
      topics {
        title
        id
      }
      type
      unpaidTime
    }
  }
`
