import get from 'lodash/get'

import Api, { responseErrorCallback } from 'src/utils/api'
import { setSnackbarProps } from 'src/store/app/actions'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import translations, { translate } from 'src/utils/translations/translations'
import type { OnSuccessT, OnValidationErrorT, OnErrorT } from 'src/utils/types'
import { AppDispatchT } from 'src/store/index'

export const acceptOrder =
  (id: string, onSuccess: OnSuccessT, onValidationError: OnValidationErrorT, onError?: OnErrorT) =>
  (dispatch: AppDispatchT) => {
    bugsnagActionBreadcrumb('acceptOrder')

    return Api.put(`/student/orders/${id}/appoint`, {
      onSuccess: () => {
        dispatch(
          setSnackbarProps({
            visible: true,
            message: translate(translations.snackbarAppointedMessage),
          }),
        )
        onSuccess && onSuccess()
      },
      onError: ({ response }) => {
        onError && onError()
        if (response.status === 422) {
          onValidationError(get(response, 'data.errors[0].detail'))
        } else {
          bugsnagNotifyWithData('acceptOrder', response)
          responseErrorCallback('put', `student/orders/${id}/appoint`, response)
        }
      },
    })
  }

export const ignoreOrder = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) => () => {
  bugsnagActionBreadcrumb('ignoreOrder')

  return Api.put(`/student/orders/${id}/ignore`, {
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: ({ response }) => {
      onError()
      bugsnagNotifyWithData('ignoreOrder', response)
      responseErrorCallback('put', `/student/orders/${id}/ignore`, response)
    },
  })
}

export const acceptProposal = (id: string, content: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('acceptProposal')

  return Api.put(`/student/proposals/${id}/accept`, {
    data: {
      note: {
        content,
      },
    },
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: ({ response }) => {
      bugsnagNotifyWithData('acceptProposal', response)
      onError(`put with url /student/proposals/${id}/accept failed with response: ${JSON.stringify(response)}`)
    },
  })
}

export const ignoreProposal = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('ignoreProposal')

  return Api.put(`/student/proposals/${id}/ignore`, {
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: ({ response }) => {
      bugsnagNotifyWithData('ignoreProposal', response)
      onError(`put with url /student/proposals/${id}/reject failed with response: ${JSON.stringify(response)}`)
    },
  })
}

export const lockOrder = (id: string) => {
  bugsnagActionBreadcrumb('lockOrder')

  return Api.put(`/student/order_locks/${id}`, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: () => {},
    onError: ({ response }) => bugsnagNotifyWithData('lockOrder', response),
  })
}
