import React from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const UKIcon = ({ size, ...props }: IconTypes) => (
  <Svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G clipPath="url(#a)">
      <Path d="M3 3h18v18H3V3Z" fill="#012169" />
      <Path
        d="M21 3v2.25L14.32 12 21 18.574V21h-2.355l-6.715-6.61L5.39 21H3v-2.39l6.54-6.575L3 5.602V3h2.18l6.75 6.61L18.469 3H21Z"
        fill="#fff"
      />
      <Path
        d="m9.469 14.39.386 1.196L4.477 21H3v-.105l6.469-6.504Zm4.36-.421 1.898.281L21 19.418V21l-7.172-7.031ZM21 3l-6.75 6.89-.14-1.546L19.382 3H21ZM3 3.035 9.785 9.68l-2.074-.282L3 4.723V3.035Z"
        fill="#C8102E"
      />
      <Path d="M9.188 3v18h5.624V3H9.189ZM3 9.188v5.624h18V9.189H3Z" fill="#fff" />
      <Path d="M3 10.313v3.374h18v-3.374H3ZM10.313 3v18h3.374V3h-3.374Z" fill="#C8102E" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Rect x={3} y={3} width={size - 6} height={size - 6} rx={9} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default UKIcon
