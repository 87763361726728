import React from 'react'
import { View, Text, Pressable, StyleSheet } from 'react-native'

import { colorsNew } from 'src/styles'
import { DescriptionIcon, DownloadIcon } from 'src/icons'
import { openURLIfCan } from 'src/global/utils'
import styles from './styles'

type Props = {
  name: string
  url: string
  withIcon?: boolean
}

const DownloadDocument = ({ name, url, withIcon = true }: Props) => (
  <Pressable onPress={() => openURLIfCan(url)} style={({ pressed }) => (pressed ? styles.rowPressed : styles.row)}>
    <View style={styles.documentContainer}>
      <DescriptionIcon
        size={20}
        fill={withIcon ? colorsNew.redesign.secondary.main : colorsNew.redesign.action.active}
      />
      <Text style={StyleSheet.flatten([styles.documentName, !withIcon && styles.documentNameLight])} numberOfLines={1}>
        {name}
      </Text>
    </View>
    {withIcon ? (
      <View style={styles.iconButton}>
        <DownloadIcon size={20} fill={colorsNew.redesign.text.secondary} />
      </View>
    ) : null}
  </Pressable>
)

export default DownloadDocument
