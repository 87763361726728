import React from 'react'
import { Text, View } from 'react-native'
import { Formik, Field } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { deliveryMethodOptions, initialValues, safeguardingConcernsOptions, studentEngagementOptions } from './utils'
import { feedbackTutorValidation } from './feedbackValidation'
import { InputRadioGroupNew } from 'src/components/Form/InputRadioGroup'
import { sendFeedback } from 'src/store/booking/actions'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import TextInputNew from 'src/components/Form/TextInputNew'
import styles from '../styles'
import translations, { translate } from 'src/utils/translations/translations'
import { TextInputSizeVariants } from 'src/components/Form/TextInputNew/variants'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'
import { BookingTimesheetTypes } from 'src/graphql/Booking'

type Props = {
  bookingId: string
  onSubmitSuccess: () => void
  pupil?: BookingTimesheetTypes['pupil']
}

const renderPupil = (pupilInfo: string | null) => {
  if (!pupilInfo) return null

  return (
    <View>
      <Text style={styles.pupilTitle}>{translate((translations as TranslationGBT).pupilHeader)}:</Text>
      <Text style={styles.pupilDescription}>{pupilInfo}</Text>
    </View>
  )
}

const FeedbackTutor = ({ onSubmitSuccess, bookingId, pupil }: Props) => {
  const handleSubmit = async (values: {
    attendance: string
    comments: string
    deliveryMethod: string
    email: string
    engagementMethod: string
    resourcesUsed: string
    safeguardingConcerns: boolean
    subjects: string
  }) => {
    bugsnagActionBreadcrumb('submit feedback tutor', { values })

    const feedback = {
      ...values,
      booking: bookingId,
    }

    await sendFeedback(feedback, onSubmitSuccess)
  }

  const pupilInfo = pupil?.firstName ? `${pupil.firstName} ${pupil.lastName}` : null

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={feedbackTutorValidation}
      validateOnBlur={false}
    >
      {({ values, setFieldValue, submitForm, errors, touched, isValid, setFieldTouched }) => (
        <>
          {renderPupil(pupilInfo)}
          <TextInputNew
            errorMessage={errors.attendance}
            isError={!isEmpty(errors.attendance) && touched.attendance}
            label={translate((translations as TranslationGBT).feedbackTutorQuestion1Label)}
            onBlur={() => setFieldTouched('attendance', true)}
            onChangeText={text => setFieldValue('attendance', text)}
            required
            title={translate((translations as TranslationGBT).feedbackTutorQuestion1Title)}
            titleStyles={pupilInfo ? styles.firstTextInputWithPupil : styles.firstTextInput}
            value={values.attendance}
            withBottomMargin={false}
          />
          <TextInputNew
            errorMessage={errors.subjects}
            isError={!isEmpty(errors.subjects) && touched.subjects}
            label={translate((translations as TranslationGBT).feedbackTutorQuestion2Label)}
            onBlur={() => setFieldTouched('subjects', true)}
            onChangeText={text => setFieldValue('subjects', text)}
            required
            title={translate((translations as TranslationGBT).feedbackTutorQuestion2Title)}
            value={values.subjects}
            withBottomMargin={false}
          />
          <TextInputNew
            errorMessage={errors.resourcesUsed}
            isError={!isEmpty(errors.resourcesUsed) && touched.resourcesUsed}
            label={translate((translations as TranslationGBT).feedbackTutorQuestion3Label)}
            onBlur={() => setFieldTouched('resourcesUsed', true)}
            onChangeText={text => setFieldValue('resourcesUsed', text)}
            required
            title={translate((translations as TranslationGBT).feedbackTutorQuestion3Title)}
            value={values.resourcesUsed}
            withBottomMargin={false}
          />
          <Field
            component={InputRadioGroupNew}
            isLarge
            label={translate((translations as TranslationGBT).feedbackTutorQuestion4Label)}
            name="deliveryMethod"
            options={deliveryMethodOptions}
          />
          <Field
            component={InputRadioGroupNew}
            isLarge
            label={translate((translations as TranslationGBT).feedbackTutorQuestion5Label)}
            name="engagementMethod"
            options={studentEngagementOptions}
          />
          <Field
            component={InputRadioGroupNew}
            isHorizontal
            isLarge
            label={translate((translations as TranslationGBT).feedbackTutorQuestion6Label)}
            name="safeguardingConcerns"
            options={safeguardingConcernsOptions}
          />
          {values.safeguardingConcerns && (
            <TextInputNew
              errorMessage={errors.email}
              isError={!isEmpty(errors.email) && touched.email}
              label={translate((translations as TranslationGBT).feedbackTutorQuestion7Label)}
              multiline
              onBlur={() => setFieldTouched('email', true)}
              onChangeText={text => setFieldValue('email', text)}
              size={TextInputSizeVariants.big}
              value={values.email}
              withBottomMargin={false}
            />
          )}
          <TextInputNew
            label={translate((translations as TranslationGBT).feedbackTutorQuestion8Label)}
            onBlur={() => setFieldTouched('comments', true)}
            onChangeText={text => setFieldValue('comments', text)}
            title={translate((translations as TranslationGBT).feedbackTutorQuestion8Title)}
            value={values.comments}
            withBottomMargin={false}
          />
          <View style={styles.buttonContainer}>
            <ButtonNew
              disabled={!isValid}
              isLong
              onPress={submitForm}
              RightIcon={ChevronRightIcon}
              title={translate((translations as TranslationGBT).submitReport)}
              variant={buttonVariants.containedDefault}
            />
          </View>
          {!isValid && (
            <Text style={styles.infoText}>
              {translate((translations as TranslationGBT).tutorFeedbackFormHelperText)}
            </Text>
          )}
        </>
      )}
    </Formik>
  )
}

export default FeedbackTutor
