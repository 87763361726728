import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const TimeExpressIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 477 476" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M301.444 333.862c60.871 0 110.218-49.243 110.218-109.986 0-60.744-49.347-109.987-110.218-109.987-60.872 0-110.218 49.243-110.218 109.987 0 60.743 49.346 109.986 110.218 109.986Z"
      fill="#224D3D"
    />
    <Path
      d="M306.086 317.708c53.341 0 96.582-43.151 96.582-96.38 0-53.229-43.241-96.379-96.582-96.379-53.341 0-96.583 43.15-96.583 96.379 0 53.229 43.242 96.38 96.583 96.38Z"
      fill="#F7FCFB"
    />
    <Path
      d="m295.782 140.761-.161.02a.826.826 0 0 1-.927-.717l-1.014-7.863a.826.826 0 0 1 .719-.925l.161-.02a.827.827 0 0 1 .927.718l1.014 7.862a.82.82 0 0 1-.719.925ZM317.485 309.189l-.161.02a.826.826 0 0 1-.927-.717l-1.014-7.863a.824.824 0 0 1 .718-.925l.161-.02a.826.826 0 0 1 .927.717l1.015 7.863a.834.834 0 0 1-.719.925ZM226.303 230.37l.02.161a.825.825 0 0 1-.719.925l-7.879 1.012a.826.826 0 0 1-.927-.717l-.02-.161a.825.825 0 0 1 .719-.925l7.879-1.012a.835.835 0 0 1 .927.717ZM395.085 208.72l.02.161a.824.824 0 0 1-.719.925l-7.878 1.012a.825.825 0 0 1-.927-.717l-.02-.161a.824.824 0 0 1 .718-.925l7.879-1.012a.83.83 0 0 1 .927.717ZM256.811 283.597l.128.101c.362.281.43.798.148 1.159l-4.857 6.274a.827.827 0 0 1-1.162.148l-.127-.101a.822.822 0 0 1-.148-1.159l4.856-6.274a.84.84 0 0 1 1.162-.148ZM360.816 149.193l.127.101c.363.281.43.797.148 1.159l-4.856 6.274a.826.826 0 0 1-1.162.147l-.128-.1a.823.823 0 0 1-.148-1.16l4.857-6.273a.826.826 0 0 1 1.162-.148ZM369.44 269.26l.1-.127a.829.829 0 0 1 1.163-.148l6.287 4.846c.362.282.43.798.147 1.16l-.1.127a.827.827 0 0 1-1.162.148l-6.288-4.846a.834.834 0 0 1-.147-1.16ZM234.752 165.481l.101-.128a.826.826 0 0 1 1.162-.147l6.287 4.846c.363.282.43.798.148 1.16l-.101.127a.827.827 0 0 1-1.162.148l-6.287-4.847a.823.823 0 0 1-.148-1.159Z"
      fill="#1C1C1C"
    />
    <Path
      d="M318.761 222.22c4.83 0 8.745-3.907 8.745-8.727s-3.915-8.727-8.745-8.727c-4.83 0-8.746 3.907-8.746 8.727s3.916 8.727 8.746 8.727Z"
      fill="#00B58B"
    />
    <Path
      d="m388.731 247.536-94.568-50.016-2.31 4.471 95.608 47.999c2.036.965 3.238-1.354 1.27-2.454Z"
      fill="#00B58B"
    />
    <Path
      d="m185.665 198.177-110.023 3.868c-.12 0-.12.181 0 .181l106.107 5.255a.802.802 0 0 0 .746-.436l3.909-7.708c.275-.536-.134-1.173-.739-1.16ZM177.235 220.089l-128.044 3.868c-.121 0-.121.18 0 .18l124.128 5.255a.8.8 0 0 0 .746-.435l3.909-7.708a.804.804 0 0 0-.739-1.16ZM169.363 244.105l-137.475 3.867c-.12 0-.12.181 0 .181l133.559 5.255a.8.8 0 0 0 .746-.435l3.909-7.708c.275-.537-.134-1.173-.739-1.16Z"
      fill="#224D3D"
    />
  </Svg>
)

export default TimeExpressIcon
