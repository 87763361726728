import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

export default StyleSheet.create({
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: paddingNew.sm,
    justifyContent: 'center',
    textAlign: 'center',
  },
  infoTitle: {
    ...typography.subtitle1,
    textAlign: 'center',
  },
  infoText: {
    ...typography.helperText,
    color: colorsNew.translucentBlack,
    textAlign: 'center',
    paddingHorizontal: paddingNew.md,
  },
})
