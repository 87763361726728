import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'

import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import HyperLink from 'react-native-hyperlink'
import MultipleFileUploader from './MultipleFileUploader/MultipleFileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

export const DOCUMENT_TYPES = [
  'qualification_teaching_qualificaion',
  'qualification_qts_qtls_certificate',
  'qualification_induction_certificate',
]

type Props = ProfileAndRecruitmentScreensProps<'TeachingCertificates'>

const TeachingCertificates = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - TeachingCertificates')

  const userId = useAppSelector(state => state.user.id) as string
  const certificates = useAppSelector(getUserDocumentUK(DOCUMENT_TYPES))

  const taskClosedWithoutDoc = isTaskCompleted && !certificates.length

  const [allDocumentsUploaded, setAllDocumentsUploaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)
  const [skipUpload, setSkipUpload] = useState(taskClosedWithoutDoc)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked
    }
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  useEffect(() => {
    if (allDocumentsUploaded) updateTaskStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocumentsUploaded])

  const updateTaskStatus = () =>
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)

  const completeTask = () => {
    setIsSubmitting(true)
    setIsError(false)
    isFilePicked ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainText}>{translate(translations.teachingCertificatesInfo)}</Text>
      <HyperLink linkDefault linkStyle={styles.hyperlink}>
        <Text style={styles.subInfo}>{translate(translations.teachingCertificatesTip)}</Text>
      </HyperLink>
      <View style={styles.checkboxContainer}>
        <Checkbox
          disabled={isFilePicked || isTaskCompleted || !!certificates.length}
          label={translate(translations.teachingCertificatesCheckbox)}
          onPress={() => setSkipUpload(prev => !prev)}
          selected={skipUpload}
        />
      </View>
      <Text style={StyleSheet.flatten([styles.mainText, skipUpload && styles.textDisabled])}>
        {translate(translations.teachingCertificatesUpload)}
      </Text>
      <MultipleFileUploader
        certificates={certificates}
        disabled={isTaskCompleted || skipUpload}
        onFilesErrorCallback={handleError}
        setAllDocumentsUploaded={() => setAllDocumentsUploaded(true)}
        setIsFilePicked={setIsFilePicked}
        shouldSaveDocument={shouldSaveDocument}
      />
      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonContainer}>
          <ButtonNew
            disabled={isTaskCompleted || (!skipUpload && !isFilePicked && !certificates.length)}
            isSubmitting={isSubmitting}
            onPress={completeTask}
            title={translate(translations.closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>
      <Text style={styles.helper}>{translate(translations.teachingCertificatesTaskInfo)}</Text>
    </ScrollView>
  )
}

export default TeachingCertificates
