import { StackNavigationProp } from '@react-navigation/stack'
import { Text, View } from 'react-native'
import React from 'react'
import { HeaderOptions } from '@react-navigation/elements/src/types'

import { ArrowBackIosIcon } from 'src/icons'
import I18n from 'src/utils/translations/i18n'
import { colorsNew } from 'src/styles'
import HeaderButton from 'src/components/HeaderButton'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type NavigationT = {
  navigation: StackNavigationProp<any>
}

export default (key: string | null, { navigation }: NavigationT): HeaderOptions => {
  const title = (key ? I18n.t(key) : '') as string

  return {
    headerTitle: () => (
      <Text numberOfLines={2} style={styles.onboardingHeaderTitle}>
        {title}
      </Text>
    ),
    headerTitleContainerStyle: {
      justifyContent: 'center',
    },
    headerComponentStyle: {
      ...globalStyles.containerSize,
    },
    headerTitleAlign: 'center',
    headerBackground: () => <View style={styles.headerBackground} />,
    headerStyle: {
      elevation: 0,
      height: 64,
      shadowOpacity: 0,
    },
    cardStyle: {
      backgroundColor: 'white',
      flex: 1,
    },
    headerLeft: () => (
      <HeaderButton
        iconColor={colorsNew.redesign.action.active}
        iconName={ArrowBackIosIcon}
        onPress={navigation.goBack}
        size={24}
        style={styles.onboardingHeaderButtonLeft}
      />
    ),
    gestureEnabled: false,
    headerRight: () => <View />,
  }
}
