import { StyleSheet } from 'react-native'

import { paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    paddingVertical: paddingNew.md,
  },
  containerPadding: {
    paddingHorizontal: paddingNew.sm,
  },
  title: {
    ...typography.overline,
    textAlign: 'center',
  },
})
