import gql from 'graphql-tag'

import { RegionTypes } from './types'

export type { RegionTypes }

export default gql`
  fragment RegionFields on Region {
    id
    name
  }
`
