import React, { ElementType } from 'react'
import { ActivityIndicatorProps, Pressable, StyleSheet, Text, View } from 'react-native'

import LoadingIndicator from 'src/components/LoadingIndicator'
import { IconTypes } from 'src/icons/iconTypes'
import styles, { iconFill, subTextButton, textButton, textHeight } from './styles'

type Props = {
  badgeSize?: number
  Icon: ElementType<IconTypes> | ElementType<ActivityIndicatorProps>
  isDisabled?: boolean
  isLoading?: boolean
  onPress: () => void
  subText?: string
  testID?: string
  text: string
  underText?: string
}

const ICON_SIZE = 24

const MenuItem = ({
  badgeSize = 0,
  Icon,
  isDisabled = false,
  isLoading = false,
  onPress,
  subText,
  testID,
  text,
}: Props) => {
  const loadingIndicatorSizeStyles = { height: ICON_SIZE, width: ICON_SIZE }

  return (
    <Pressable
      disabled={isDisabled}
      onPress={onPress}
      style={({ pressed }) =>
        StyleSheet.flatten([pressed ? styles.buttonPressed : styles.button, subText ? styles.menuItemWithSubText : {}])
      }
      testID={testID}
    >
      {isLoading ? (
        <View
          style={{
            ...styles.menuItemLoadingContainer,
            ...loadingIndicatorSizeStyles,
          }}
        >
          <LoadingIndicator style={{ ...styles.menuItemLoading, ...loadingIndicatorSizeStyles }} size="small" />
        </View>
      ) : (
        <Icon size={ICON_SIZE} style={styles.icon} fill={isDisabled ? iconFill.inActive : iconFill.active} />
      )}
      <View>
        <Text style={StyleSheet.flatten([textButton(isDisabled), !subText ? textHeight : {}])}>{text}</Text>
        {subText && <Text style={subTextButton(isDisabled)}>{subText}</Text>}
      </View>
      {badgeSize && badgeSize > 0 ? <View style={styles.leftBadge} /> : <Text />}
    </Pressable>
  )
}

export default MenuItem
