import get from 'lodash/get'
import { AxiosError } from 'axios'

import Api, { responseErrorCallback } from 'src/utils/api'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { ErrorResponseT, OnErrorT, OnSuccessT } from 'src/utils/types'

export const lockBooking = (id: string) => {
  bugsnagActionBreadcrumb('lockBooking', { id })

  return Api.put(`/student/bookings/${id}/lock`, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: () => {},
    onError: ({ response }) => bugsnagNotifyWithData('lockBooking', response),
  })
}

export const ignoreBooking =
  (id: string, reason: string, onSuccess?: OnSuccessT, onError?: (error?: AxiosError<ErrorResponseT>) => void) =>
  () => {
    bugsnagActionBreadcrumb('ignoreBooking', { id })

    return Api.put(`student/bookings/${id}/ignore`, {
      data: { reason },
      onSuccess: () => {
        onSuccess && onSuccess()
      },
      onError: e => {
        bugsnagNotifyWithData('ignoreBooking', e?.response)
        if (onError) {
          onError(e)
        }
      },
    })
  }

export const appointBooking =
  (
    id: string,
    params = {},
    onSuccess: OnSuccessT,
    onValidationError: (details: string, code?: string) => void,
    onError?: OnErrorT,
  ) =>
  () => {
    bugsnagActionBreadcrumb('appointBooking', { id })

    return Api.put(`student/bookings/${id}/appoint`, {
      data: { ...params },
      onSuccess: () => {
        onSuccess && onSuccess()
      },
      onError: ({ response }) => {
        onError && onError()
        if (response.status === 422) {
          onValidationError(get(response, 'data.errors[0].detail'), get(response, 'data.errors[0].code'))
        } else {
          bugsnagNotifyWithData('appointBooking', response)
          responseErrorCallback('put', `student/bookings/${id}/appoint`, response)
        }
      },
    })
  }

export const sendFeedback = (feedback: object, onSuccess: OnSuccessT) => {
  bugsnagActionBreadcrumb('sendFeedback')

  return Api.post('/student/feedbacks', {
    data: { feedback },
    onSuccess: onSuccess,
    onError: ({ response }) => bugsnagNotifyWithData('sendFeedback', response),
  })
}
