import { referAnimationVariants } from './ReferAnimation'
import { referralsStates } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/constants'
import translations, { translate } from 'src/utils/translations/translations'

export const getReferralSectionInfo = () => ({
  [referralsStates.educatorAccepted]: {
    title: translate(translations.referralSectionEducatorAccepted),
  },
  [referralsStates.educatorInRecruitment]: {
    info: translate(translations.referralSectionEducatorInRecruitmentInfo),
    title: translate(translations.referralSectionEducatorInRecruitment),
  },
  [referralsStates.educatorLeftHumly]: {
    title: translate(translations.referralSectionEducatorLeftHumly),
  },
  [referralsStates.paidOut]: {
    title: translate(translations.referralSectionPaidOut),
  },
})

type TGetReferAnimationVariantArguments = {
  earnedMoney: number
  givenMoney: number
}

export const getReferAnimationVariant = ({ earnedMoney, givenMoney }: TGetReferAnimationVariantArguments) =>
  givenMoney > earnedMoney
    ? referAnimationVariants.give
    : givenMoney < earnedMoney
    ? referAnimationVariants.take
    : referAnimationVariants.split
