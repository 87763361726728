import { View, Text } from 'react-native'
import React, { ElementType } from 'react'

import { colorsNew } from 'src/styles'
import { IconTypes } from 'src/icons/iconTypes'
import styles from './styles'
import { File } from 'src/graphql/sharableTypes'
import DownloadDocument from 'src/components/DownloadDocument'

type Props = {
  description?: string
  Icon: ElementType<IconTypes>
  title: string
  files?: File[]
}

const InfoRow = ({ Icon, title, description, files }: Props) => (
  <View style={styles.button}>
    <Icon fill={colorsNew.redesign.text.primary} size={24} />
    <View style={styles.container}>
      <Text style={styles.title} numberOfLines={1}>
        {title}
      </Text>
      {description ? <Text style={styles.subtitle}>{description}</Text> : null}

      {files
        ? files?.map(file => <DownloadDocument name={file.name} key={file.id} url={file.url} withIcon={false} />)
        : null}
    </View>
  </View>
)

export default InfoRow
