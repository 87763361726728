import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import { countryCodeSelector } from 'src/store/app/selectors'
import ExpressTimesheetQuery from 'src/graphql/ExpressBookings/Timesheet'
import formatter from 'src/utils/formatter'
import { scheduleTypeText } from 'src/global/constants'

import { ExpressBookingsTimesheetRequestTypes, ExpressBookingsTimesheetTypes } from '../graphql/ExpressBookings'

const useExpressSummary = (id: string) => {
  const countryCode = useSelector(countryCodeSelector)

  const { loading, data, error } = useQuery<ExpressBookingsTimesheetRequestTypes>(ExpressTimesheetQuery, {
    variables: {
      id,
    },
  })

  if (error || loading) {
    return { error, loading }
  }

  const { date, holidayPayRate, salaryRate } = data?.expressBooking as ExpressBookingsTimesheetTypes

  const expressSalary = formatter.getSalarySubtitle({
    countryCode,
    holidayRate: holidayPayRate,
    salary: salaryRate,
    scheduleType: scheduleTypeText.full_day,
  })
  const expressDate = formatter.longWeekdayAndMonth(date)

  return {
    error,
    expressDate,
    expressSalary,
    loading,
  }
}

export default useExpressSummary
