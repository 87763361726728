import { StyleSheet } from 'react-native'
import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: paddingNew.sm,
  },
  buttonSpace: {
    width: paddingNew.sm,
  },
})

export default styles
