import * as React from 'react'
import Svg, { Path, Rect, Circle } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ExpressIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 23 23" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.869 8.08h1.92a5.011 5.011 0 0 1 3.352-1.283 5.037 5.037 0 0 1 5.032 5.032 5.037 5.037 0 0 1-5.032 5.03 5.011 5.011 0 0 1-3.353-1.282h-1.92a6.47 6.47 0 1 0 0-7.497Z"
    />
    <Rect x={4.188} y={8.864} width={5.802} height={1.438} rx={0.719} />
    <Rect x={2.143} y={11.11} width={5.802} height={1.438} rx={0.719} />
    <Rect x={4.188} y={13.356} width={5.802} height={1.438} rx={0.719} />
    <Path d="M15.28 9.24c.621-.523 1.098-.02.542.573l-2.084 2.222c-.273.29-.65.395-.811.225l-.248-.261c-.161-.17-.036-.541.269-.798L15.28 9.24Z" />
    <Circle cx={13.141} cy={11.829} r={1.268} />
  </Svg>
)

export default ExpressIcon
