import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const calendarDateIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 65 65" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M28.672 42.456V27.787h-1.74a34.66 34.66 0 0 0-3.609 2.216v1.912a50.082 50.082 0 0 1 3.46-2.137h.033v12.678h1.856Zm3.267-3.59c.13 1.76 1.633 3.867 4.683 3.867 3.46 0 5.5-2.931 5.5-7.895 0-5.319-2.148-7.337-5.37-7.337-2.547 0-4.942 1.848-4.942 4.975 0 3.19 2.266 4.867 4.609 4.867 2.051 0 3.382-1.034 3.803-2.172h.074c-.01 3.619-1.267 5.95-3.588 5.95-1.826 0-2.773-1.237-2.888-2.254h-1.881Zm8.12-6.371c0 1.914-1.536 3.245-3.255 3.245-1.653 0-3.146-1.053-3.146-3.3 0-2.263 1.6-3.327 3.212-3.327 1.74 0 3.19 1.094 3.19 3.382Z" />
    <Path d="M20.573 10.313a1.375 1.375 0 0 1 1.375 1.375v1.375h22v-1.375a1.375 1.375 0 0 1 2.75 0v1.375h2.75a5.5 5.5 0 0 1 5.5 5.5v30.25a5.5 5.5 0 0 1-5.5 5.5h-33a5.5 5.5 0 0 1-5.5-5.5v-30.25a5.5 5.5 0 0 1 5.5-5.5h2.75v-1.375a1.375 1.375 0 0 1 1.375-1.375Zm-6.875 11v27.5a2.75 2.75 0 0 0 2.75 2.75h33a2.75 2.75 0 0 0 2.75-2.75v-27.5h-38.5Z" />
  </Svg>
)

export default calendarDateIcon
