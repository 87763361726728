import React from 'react'

import HeaderButton from 'src/components/HeaderButton'
import ArrowBackIosIcon from 'src/icons/redesign/arrowBackIosRounded'
import { colorsNew } from 'src/styles'
import styles from './styles'

export const renderHeaderButton = (type: string, onPress: () => void) => {
  if (type === 'inactive') {
    return null
  }

  return () => (
    <HeaderButton
      iconColor={colorsNew.redesign.action.active}
      iconName={ArrowBackIosIcon}
      size={24}
      style={styles.onboardingHeaderButtonLeft}
      onPress={onPress}
    />
  )
}
