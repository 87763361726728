import gql from 'graphql-tag'

import HomeAddress from '../HomeAddress'

export default gql`
  fragment OrderFields on Order {
    adminInfo
    appointed
    appointedById
    appointmentType
    appointmentUnconfirmed
    assignmentInfo
    bookings {
      _durationMinutes
      unpaidTime
      cancelled
      displayInfo
      endTime
      id
      holidayPayBaseRate
      rejected
      startTime
      type
      salaryRate
      timeScheduleType
    }
    cancelled
    competences {
      id
      name
      type
    }
    confirmed
    department
    displayInfo
    endDate
    files {
      id
      name
      url
    }
    hasHealthRisks
    healthRisksDescription
    id
    isMatching
    jobTitle
    klassName
    level {
      group
      id
      title
    }
    locked
    multiDay
    originalTeacher
    preparationTime
    pupil {
      description
      files {
        name
        url
      }
      firstName
      lastName
    }
    region {
      countryCode
      id
      name
    }
    rejected
    requestedSubs {
      id
    }
    responsibleName
    salaryRate
    school {
      address
      bookingInfo
      description
      files {
        id
        name
        url
      }
      homeAddress {
        ...HomeAddressFields
      }
      id
      name
      processArrival
      processHandover
      processOther
      profilePictureUrl
      type
    }
    schoolInfo
    schoolJobSelectionExpiredAt
    startDate
    student {
      id
      name
    }
    studentApplications {
      candidateRejection {
        rejectedAt
        rejectedById
      }
      id
      claimedAt
      studentId
    }
    topics {
      title
      id
    }
  }

  ${HomeAddress}
`
