import { GiftIcon, NewReleaseRoundedIcon } from 'src/icons'
import images from 'src/global/images'
import imagesGB from 'src/global/imagesGB'
import translations, { translate } from 'src/utils/translations/translations'

const getAnnouncementModalContent = (isGB: boolean, primaryAction?: () => void) => ({
  bodyPartOne: translate(translations.referralAnnouncementBodyPartOne),
  bodyPartTwo: translate(translations.referralAnnouncementBodyPartTwo),
  icon: isGB ? NewReleaseRoundedIcon : GiftIcon,
  images: [isGB ? imagesGB.referralAnnouncementGB : images.referallAnnouncementSE], //To display images in modal get image url or import it from project's assets and add it to the array
  overline: translate(translations.referralAnnouncementOverline),
  primaryAction: {
    onPress: primaryAction, //Set as null if primary action should be closing the modal
    title: translate(translations.referralAnnouncementPrimaryActionTitle),
  },
  title: translate(translations.referralAnnouncementTitle),
})

export default getAnnouncementModalContent
