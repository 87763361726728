import Api, { responseErrorCallback } from 'src/utils/api'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { reportInitialValuesTypes } from 'src/hooks/useTimesheetReport'
import { OnErrorT, OnSuccessT } from 'src/utils/types'

export const reportWorkingTime = (
  id: string,
  report: reportInitialValuesTypes,
  onSuccess?: OnSuccessT,
  onError?: OnErrorT,
) => {
  bugsnagActionBreadcrumb('reportWorkingTime', { id })

  Api.put(`student/bookings/${id}`, {
    data: JSON.stringify({ booking: report }),
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: response => {
      const error =
        response?.data?.errors[0]?.detail ||
        `put with url student/bookings/${id} failed with response: ${JSON.stringify(response)}`

      if (onError) {
        onError(error)
      } else {
        bugsnagNotifyWithData('reportWorkingTime', response)
        responseErrorCallback('put', `student/bookings/${id}`, response)
      }
    },
  })
}
