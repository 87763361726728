import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const BellOnIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 40 40" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.111 5C18.8224 5 17.7777 6.04467 17.7777 7.33333V8.47995C14.1867 9.49561 11.5555 12.7973 11.5555 16.7135V27.0175H9.99992C9.07944 27.0175 8.33325 27.7637 8.33325 28.6841C8.33325 29.6046 9.07944 30.3508 9.99992 30.3508H30.2221C31.1426 30.3508 31.8888 29.6046 31.8888 28.6841C31.8888 27.7637 31.1426 27.0175 30.2221 27.0175H28.6666V16.7135C28.6666 12.7973 26.0354 9.49561 22.4444 8.47995V7.33333C22.4444 6.04467 21.3997 5 20.111 5Z"
    />
    <Path d="M17.7778 31.842H22.4445V32.6666C22.4445 33.9553 21.3998 34.9999 20.1112 34.9999C18.8225 34.9999 17.7778 33.9553 17.7778 32.6666V31.842Z" />
    <Path d="M11.2898 7.46659C11.9898 6.83326 12.0065 5.74993 11.3398 5.08326C10.7065 4.44993 9.67314 4.43326 9.02314 5.04993C6.17314 7.64993 4.20647 11.1833 3.57314 15.1499C3.42314 16.1666 4.20647 17.0833 5.23981 17.0833C6.03981 17.0833 6.73981 16.4999 6.87314 15.6999C7.37314 12.4666 8.97314 9.58326 11.2898 7.46659Z" />
    <Path d="M31.0065 5.04993C30.3398 4.43326 29.3065 4.44993 28.6731 5.08326C28.0065 5.74993 28.0398 6.81659 28.7231 7.44993C31.0231 9.56659 32.6398 12.4499 33.1398 15.6833C33.2565 16.4833 33.9565 17.0666 34.7731 17.0666C35.7898 17.0666 36.5898 16.1499 36.4231 15.1333C35.7898 11.1833 33.8398 7.66659 31.0065 5.04993Z" />
  </Svg>
)

export default BellOnIcon
