import React from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { jobOfferItemData } from '../utils'
import { MenuParamList, RouteNavigatorStackComponent } from 'src/utils/types/navigationTypes'
import { setApplicationNotificationsRead } from 'src/store/inbox/actions'
import { useAppDispatch } from 'src/hooks/reduxHooks'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useAppState } from 'src/utils/AppStateEvent'
import { useBugsnagView } from 'src/utils/bugsnag'
import JobsSectionList, { RenderListItemInfo } from 'src/components/List/JobsSectionList'
import LoadingIndicator from 'src/components/LoadingIndicator'
import ShortTermOfferItem from 'src/scenes/Main/Jobs/Rows/ShortTermOfferItem'
import translations, { translate } from 'src/utils/translations/translations'
import useApplicationData from './useApplicationData'
import LongTermOfferItem from 'src/scenes/Main/Jobs/Rows/LongTermOfferItem'
import { Competence } from 'src/graphql/sharableTypes'
import { getUserCompetences } from 'src/store/user/selectors'
import { isGBSelector } from 'src/store/app/selectors'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'
import listStyles from 'src/components/List/styles'

export const Applications: RouteNavigatorStackComponent<MenuParamList, 'Applications'> = ({ navigation }) => {
  useBugsnagView('Pending Interests')
  const educatorCompetences = useAppSelector(getUserCompetences) as Competence[] | []
  const isGB = useAppSelector(isGBSelector)
  const { refreshFunction, applicationsList, isLoading } = useApplicationData()

  const dispatch = useAppDispatch()

  useAppState({
    onForeground: refreshFunction,
  })

  useFocusEffect(
    React.useCallback(() => {
      refreshFunction()
      dispatch(setApplicationNotificationsRead())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const renderJobListItem = ({ item }: RenderListItemInfo) => {
    switch (item.__typename) {
      case 'Proposal':
        return (
          <LongTermOfferItem
            hours={item.hours || item.hoursPerWeek}
            location={item.location || item.region?.name}
            onPress={() => navigation.navigate('ProposalMenu', { id: item.id })}
            salary={item.payRate}
            title={item.jobTitle || item.title}
          />
        )
      case 'Booking':
      case 'Order':
        return (
          <ShortTermOfferItem
            {...jobOfferItemData(item, isGB, false, educatorCompetences)}
            onPress={() => navigation.navigate(`${item.__typename}Menu`, { id: item.id })}
          />
        )
    }
  }

  if (isLoading && applicationsList.length === 0) return <LoadingIndicator />

  const emptyView = () => (
    <EmptyPlaceholder
      containerStyles={listStyles.emptyContainer}
      description={translate(translations.noAvailableAssignmentsDescription)}
      title={translate(translations.noAvailableAssignments)}
      type={placeholderTypes.cactus}
      withButton
    />
  )

  return (
    <JobsSectionList
      ListEmptyComponent={emptyView()}
      refreshFunction={refreshFunction}
      renderJobListItem={renderJobListItem}
      sections={applicationsList}
    />
  )
}

export default Applications
