import { StyleSheet } from 'react-native'
import { colorsNew, paddingNew, typography } from 'src/styles'
import { AlertColorScheme, AlertVariants } from './types'

export const iconColor = {
  error: {
    filled: colorsNew.white,
    outlined: colorsNew.redesign.error.main,
    standard: colorsNew.redesign.error.dark,
  },
  success: {
    filled: colorsNew.white,
    outlined: colorsNew.redesign.success.main,
    standard: colorsNew.redesign.success.dark,
  },
  warning: {
    filled: colorsNew.white,
    outlined: colorsNew.redesign.warning.main,
    standard: colorsNew.redesign.warning.dark,
  },
  info: {
    filled: colorsNew.white,
    outlined: colorsNew.redesign.info.main,
    standard: colorsNew.redesign.info.dark,
  },
}

export const cardColor = {
  error: {
    filled: colorsNew.redesign.error.main,
    outlined: colorsNew.redesign.background.paper,
    standard: colorsNew.redesign.error.shades12p,
  },
  success: {
    filled: colorsNew.redesign.success.main,
    outlined: colorsNew.redesign.background.paper,
    standard: colorsNew.redesign.success.shades12p,
  },
  warning: {
    filled: colorsNew.redesign.warning.main,
    outlined: colorsNew.redesign.background.paper,
    standard: colorsNew.redesign.warning.shades12p,
  },
  info: {
    filled: colorsNew.redesign.info.main,
    outlined: colorsNew.redesign.background.paper,
    standard: colorsNew.redesign.info.shades12p,
  },
}

export const textColor = {
  error: colorsNew.redesign.error.text,
  info: colorsNew.redesign.info.text,
  success: colorsNew.redesign.success.text,
  warning: colorsNew.redesign.warning.text,
}

export const getCardColor = (variant: AlertVariants, colorSchema: AlertColorScheme) => ({
  backgroundColor: cardColor[colorSchema][variant],
  borderColor: cardColor[colorSchema].filled,
  borderWidth: variant === 'standard' ? 0 : 1,
})

export const getTextColor = (variant: AlertVariants, colorSchema: AlertColorScheme) => ({
  color: variant === 'filled' ? colorsNew.white : textColor[colorSchema],
})

const styles = StyleSheet.create({
  button: {
    marginBottom: paddingNew.xs - 2,
    marginTop: paddingNew.xs,
  },
  description: {
    ...typography.body2,
    color: colorsNew.white,
  },
  content: {
    marginLeft: paddingNew.sm,
    flex: 1,
  },
  title: {
    ...typography.body1,
    color: colorsNew.white,
    marginBottom: paddingNew.xxs,
  },
  card: {
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: 12,
  },
  closeButton: {
    marginLeft: paddingNew.sm,
  },
})

export default styles
