import React from 'react'
import { useSelector } from 'react-redux'
import { View, Text } from 'react-native'

import { EnvelopeIcon, PhoneRoundedIcon } from 'src/icons'
import { isGBSelector } from 'src/store/app/selectors'
import { useBugsnagView } from 'src/utils/bugsnag'
import AlertCard from 'src/components/AlertCard'
import ContactDetailsRow from './components/ContactDetailsRow/ContactDetailsRow'
import ContactModal from '../ContactModal/ContactModal'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useContactActions from './useContactActions'
import useSupportContact from 'src/hooks/useSupportContact'

const ContactDetails = () => {
  const contact = useSupportContact()
  const isGB = useSelector(isGBSelector)
  const { showMailActionSheet, showCallActionSheet, actions, closeActionsModal } = useContactActions()

  const phone = contact?.phone || ''
  const email = contact?.email || ''
  const onboardingEmail = contact?.onboardingEmail || ''

  useBugsnagView('Support - ContactDetails')

  return (
    <>
      <View style={styles.page}>
        <Text style={styles.header}>{translate(translations.contactDetailsInfo)}</Text>
        <ContactDetailsRow
          Icon={PhoneRoundedIcon}
          info={phone}
          onPress={() => showCallActionSheet(phone)}
          title={translate(translations.callUs)}
        />
        <ContactDetailsRow
          Icon={EnvelopeIcon}
          info={email}
          onPress={() => showMailActionSheet(email)}
          title={translate(translations.emailJobs)}
        />
        <ContactDetailsRow
          Icon={EnvelopeIcon}
          info={onboardingEmail}
          onPress={() => showMailActionSheet(onboardingEmail)}
          title={translate(translations.emailEmployments)}
        />
        {isGB && (
          <View style={styles.alertContainer}>
            <AlertCard
              colorSchema="info"
              description={translate(translations.contactDetailsAlertBody)}
              title={translate(translations.contactDetailsAlertTitle)}
              variant="outlined"
            />
          </View>
        )}
      </View>

      <ContactModal onClose={closeActionsModal} actions={actions} />
    </>
  )
}

export default ContactDetails
