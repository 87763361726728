import orderBy from 'lodash/orderBy'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import moment from 'moment'

import NotificationQuery, { NotificationQueryType } from 'src/graphql/Notifications/Notifications'
import formatter from 'src/utils/formatter'

const useNotificationData = () => {
  //@ts-ignore
  const userId = useSelector(state => state.user?.id)
  const minDate = formatter.apiFormat(moment().subtract(7, 'days'))

  const { data, loading, error, refetch } = useQuery<NotificationQueryType>(NotificationQuery, {
    variables: {
      createdAtMin: minDate,
      userId,
    },
    skip: !userId,
    fetchPolicy: 'network-only',
  })

  const userMessagesList = data?.messages?.nodes || []
  const userActivitiesList = data?.userActivities?.nodes || []

  const notificationsList = orderBy([...userMessagesList, ...userActivitiesList], ['createdAt'], ['desc'])
  return { notificationsList, loading, error, refetch }
}

export default useNotificationData
