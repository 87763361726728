import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  pageContent: {
    padding: paddingNew.sm,
    paddingBottom: paddingNew.xl,
  },
  backgroundImage: {
    borderRadius: 8,
    height: 190,
    marginTop: paddingNew.xs,
    resizeMode: 'cover',
    width: '100%',
  },
  badgeContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: paddingNew.sm,
  },
  section: {
    paddingVertical: paddingNew.xs,
  },
  sectionTitle: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  schoolName: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.sm,
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginVertical: paddingNew.sm,
  },
  loader: {
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
})

export default styles
