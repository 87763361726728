import React, { useContext, useRef, useState } from 'react'
import { ScrollView, View, Text } from 'react-native'
import { Formik, FormikProps } from 'formik'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { isGBSelector } from 'src/store/app/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import getValidationSchema from './validation'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'EmergencyContactDetails'>
type InitialValuesType = {
  relative: {
    name: string
    phone: string
    relationship: string
  }
}

const EmergencyContactDetails = ({ navigation, route }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - EmergencyContactDetails')

  const formikRef = useRef<FormikProps<InitialValuesType>>(null)
  const dispatch = useAppDispatch()

  const userId = useAppSelector(state => state.user.id) as string
  const relativeName = useAppSelector(state => state.user.attributes?.relative.name)
  const relativePhone = useAppSelector(state => state.user.attributes?.relative.phone)
  const relativeRelationship = useAppSelector(state => state.user.attributes?.relative.relationship)
  const isGB = useSelector(isGBSelector)

  const { mixpanel } = useContext(MixpanelContext)

  const [isError, setIsError] = useState(false)

  const closeScreen = useBackModal(navigation, formikRef)
  const handleError = () => setIsError(true)
  const updateTaskStatus = () => markTaskAsCompleted(userId, task, false, mixpanel, closeScreen, handleError)

  const submitForm = (values: InitialValuesType) => {
    bugsnagActionBreadcrumb('submit', { id: userId, user: values })

    setIsError(false)
    dispatch(
      saveUser({
        onError: handleError,
        onSuccess: isTaskCompleted ? closeScreen : updateTaskStatus,
        id: userId,
        user: values,
      }),
    )
  }

  const initialValues: InitialValuesType = {
    relative: {
      name: relativeName || '',
      phone: relativePhone || '',
      relationship: relativeRelationship || '',
    },
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        innerRef={formikRef}
        validationSchema={getValidationSchema(isGB)}
      >
        {({ handleSubmit, setFieldTouched, setFieldValue, dirty, errors, isSubmitting, isValid, touched, values }) => (
          <>
            <TextInputNew
              errorMessage={errors.relative?.name}
              isError={!isEmpty(errors.relative?.name) && touched.relative?.name}
              label={translate(translations.emergencyContactName)}
              onBlur={() => setFieldTouched('relative.name', true)}
              onChangeText={text => setFieldValue('relative.name', text)}
              size={TextInputSizeVariants.medium}
              testID="emergency-contact-name-input"
              value={values.relative.name}
            />

            <TextInputNew
              errorMessage={errors.relative?.phone}
              isError={!isEmpty(errors.relative?.phone) && touched.relative?.phone}
              keyboardType="numeric"
              label={translate(translations.emergencyContactPhone)}
              onBlur={() => setFieldTouched('relative.phone', true)}
              onChangeText={text => setFieldValue('relative.phone', text)}
              size={TextInputSizeVariants.medium}
              testID="emergency-contact-phone-input"
              value={values.relative.phone}
            />

            <TextInputNew
              errorMessage={errors.relative?.relationship}
              isError={!isEmpty(errors.relative?.relationship) && touched.relative?.relationship}
              label={translate(translations.emergencyContactRelationship)}
              onBlur={() => setFieldTouched('relative.relationship', true)}
              onChangeText={text => setFieldValue('relative.relationship', text)}
              size={TextInputSizeVariants.medium}
              testID="emergency-contact-relationship-input"
              value={values.relative.relationship}
            />
            {isError ? <CompleteTaskError hideError={() => setIsError(false)} /> : null}
            <View style={styles.buttonContainer}>
              <ButtonNew
                disabled={!isValid || (isTaskCompleted && !dirty)}
                isSubmitting={isSubmitting}
                isShort
                onPress={handleSubmit}
                testID="submit-emergency-contact-details"
                title={translate(translations.saveChanges)}
                variant={buttonVariants.containedDefault}
              />
            </View>
          </>
        )}
      </Formik>
      {!isGB ? <Text style={styles.infoText}>{translate(translations.emergencyContactDetailsInfo)}</Text> : null}
    </ScrollView>
  )
}

export default EmergencyContactDetails
