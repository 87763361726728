import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, fonts, fontSizesNew, paddingNew } from 'src/styles'
import globalStyles from 'src/global/globalStyles'

const isWeb = Platform.OS === 'web'

const { height, width } = Dimensions.get('window')

export default StyleSheet.create({
  applyButtonWrapper: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  applyButtonText: {
    color: colorsNew.brand.primary,
    fontFamily: fonts.muktaSemiBold,
    fontSize: fontSizesNew.sm.size,
    fontWeight: '600',
    lineHeight: fontSizesNew.sm.lh,
  },
  applyInfoText: {
    color: colorsNew.brand.secondaryDark,
    fontFamily: fonts.muktaSemiBold,
    fontSize: fontSizesNew.xxs.size,
    fontWeight: '600',
    lineHeight: fontSizesNew.xxs.lh,
    marginBottom: paddingNew.xxs,
  },
  backgroundImage: {
    alignSelf: 'flex-end',
    resizeMode: 'cover',
  },
  backgroundImageContainer: {
    ...(isWeb
      ? {
          paddingBottom: paddingNew.xl,
          paddingHorizontal: paddingNew.base,
          paddingTop: paddingNew.lg,
        }
      : {
          bottom: 0,
          height,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          width,
          zIndex: -1,
        }),
  },
  content: {
    height: '110%',
    maxWidth: 800,
    ...(isWeb ? { justifyContent: 'flex-start' } : {}),
  },
  contentContainerStyle: {
    justifyContent: 'flex-start',
    paddingBottom: 30,
  },
  emailInput: { flex: 0 },
  errorInput: { borderColor: colorsNew.error },
  errorMessage: { color: colorsNew.error },
  errorInfo: {
    color: colorsNew.error,
    marginBottom: paddingNew.md,
    marginTop: paddingNew.base,
  },
  errorInfoSmall: {
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.xs,
  },
  errorStyles: {
    ...Platform.select({
      android: {
        color: colorsNew.redesign.text.primary,
      },
    }),
  },
  headerContainer: {
    marginBottom: 40,
  },
  headerInfoContainer: {
    color: colorsNew.black,
    fontSize: fontSizesNew.lg.size,
    fontWeight: '700',
    lineHeight: fontSizesNew.lg.lh,
    marginBottom: paddingNew.md,
  },
  headerInfoContainerSmall: {
    fontSize: fontSizesNew.md.size,
    lineHeight: fontSizesNew.md.lh,
    marginBottom: paddingNew.base,
  },
  headerInfoGreenText: {
    color: colorsNew.redesign.primary.main,
  },
  icon: {
    color: colorsNew.brand.primary,
    marginLeft: paddingNew.base / 2,
    transform: [{ rotateY: '180deg' }],
  },
  input: {
    backgroundColor: colorsNew.white,
    color: colorsNew.brand.secondaryDark,
    fontFamily: fonts.muktaSemiBold,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
  },
  inputContainer: {
    marginTop: paddingNew.xs,
  },
  linkContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: paddingNew.base,
    ...(isWeb ? { marginTop: paddingNew.base } : {}),
  },
  linkContainerSmall: {
    marginBottom: 0,
  },
  loginButton: {
    height: 48,
  },
  loginButtonContainer: {
    ...(isWeb ? {} : { flex: 1 }),
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: paddingNew.md * 2,
  },
  logoContainerSmall: {
    marginVertical: paddingNew.lg,
  },
  mainContainer: {
    alignContent: 'flex-start',
    flex: 1,
    justifyContent: 'flex-start',
  },
  passwordButtonSpace: {
    alignItems: 'center',
    marginBottom: paddingNew.md,
  },
  passwordButtonSpaceSmall: {
    marginBottom: paddingNew.xs,
  },
  passwordLinkText: {
    color: colorsNew.grey._600,
    fontFamily: fonts.muktaSemiBold,
    fontWeight: '600',
  },
  wrapperViewSpacing: {
    flex: 1,
    ...(isWeb ? { backgroundColor: colorsNew.loginBackground } : { padding: paddingNew.base }),
  },
  page: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: paddingNew.base,
    ...globalStyles.containerSize,
  },
})
