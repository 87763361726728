import { View } from 'react-native'
import React from 'react'

import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  closeModal: () => void
  disabled: boolean
  submitForm: () => void
}

const Buttons = ({ closeModal, submitForm, disabled }: Props) => (
  <View style={styles.buttonsContainer}>
    <ButtonNew
      disabled={disabled}
      isLong
      onPress={closeModal}
      title={translate(translations.cancel)}
      variant={buttonVariants.outlinedDefault}
    />
    <View style={styles.buttonSpace} />
    <ButtonNew
      disabled={disabled}
      isLong
      onPress={submitForm}
      testID="timesheet-submit-button"
      title={translate(translations.saveChanges)}
      variant={buttonVariants.containedDefault}
    />
  </View>
)

export default Buttons
