import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const PersonSearchIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M17.43 14.02c.47-.8.7-1.77.48-2.82-.34-1.64-1.72-2.95-3.38-3.16-2.63-.34-4.85 1.87-4.5 4.5.22 1.66 1.52 3.04 3.16 3.38 1.05.22 2.02-.01 2.82-.48l1.86 1.86a.996.996 0 101.41-1.41l-1.85-1.87zM14 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM8.35 10.009c-2.73-.1-8.35 1.26-8.35 3.99v1c0 .55.45 1 1 1h8.54c-2.47-2.76-1.23-5.89-1.19-5.99zM8 8a4 4 0 100-8 4 4 0 000 8z"
      fill={fill}
    />
  </Svg>
)

export default PersonSearchIcon
