import React from 'react'
import { View, Text } from 'react-native'

import translations, { translate } from 'src/utils/translations/translations'
import { QuestionIcon } from 'src/icons'

import { colors } from 'src/styles'
import styles from './styles'

const WarningInfo = () => (
  <View style={styles.warningContainer}>
    <Text style={styles.warningText}>{translate(translations.chatWarningInfo)}</Text>
    <View style={styles.warningIconContainer}>
      <QuestionIcon fill={colors.warning._300} size={20} />
    </View>
  </View>
)

export default WarningInfo
