import gql from 'graphql-tag'

import SubjectFields from './Fields'
import { SubjectTypes } from './types'

export type { SubjectTypes }

export default gql`
  query Subjects($limit: Int, $offset: Int) {
    subjects(limit: $limit, offset: $offset) {
      nodes {
        ...SubjectFields
      }
    }
  }

  ${SubjectFields}
`
