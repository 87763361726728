import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginVertical: paddingNew.md,
  },
  buttonContainer: {
    alignItems: 'center',
    marginVertical: paddingNew.md,
  },
  container: {
    marginHorizontal: paddingNew.sm,
    paddingBottom: paddingNew.md,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  mainInfo: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  subInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
})

export default styles
