import gql from 'graphql-tag'

export default gql`
  mutation RefreshAccessToken {
    refreshAccessToken {
      accessToken
      refreshToken
    }
  }
`

export type TRefreshAccessToken = {
  refreshAccessToken: {
    accessToken: string
    refreshToken: string
  }
}
