import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'

import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import FileUploader from 'src/components/FileUploader/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const DOCUMENT_TYPE = 'cv'

type Props = ProfileAndRecruitmentScreensProps<'CvDocument'>

const CvDocument = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask -  CvDocument')

  const userId = useAppSelector(state => state.user.id) as string
  const cvDocument = useAppSelector(getUserDocumentUK(DOCUMENT_TYPE))

  const taskClosedWithoutCv = isTaskCompleted && !cvDocument.length

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)
  const [skipCvUpload, setSkipCvUpload] = useState(taskClosedWithoutCv)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked
    }
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => setIsError(true)
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const updateTaskStatus = () =>
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)

  const completeTask = () => {
    setIsSubmitting(true)
    setIsError(false)
    isFilePicked ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainText}>{translate(translations.cvInfo)}</Text>
      <View style={styles.checkboxContainer}>
        <Checkbox
          disabled={isFilePicked || isTaskCompleted}
          label={translate(translations.cvCheckbox)}
          onPress={() => setSkipCvUpload(prev => !prev)}
          selected={skipCvUpload}
        />
        {skipCvUpload ? (
          <View style={styles.alert}>
            <AlertCard
              colorSchema={alertColorScheme.info}
              description={translate(translations.cvAlertInfo)}
              title={translate(translations.cvAlertTitle)}
              variant={alertVariants.outlined}
            />
          </View>
        ) : null}
      </View>
      <Text style={StyleSheet.flatten([styles.mainText, skipCvUpload && styles.textDisabled])}>
        {translate(translations.cvInfoFileTitle)}
      </Text>
      <FileUploader
        arrayDocuments={cvDocument}
        buttonDisabled={isTaskCompleted || skipCvUpload}
        deletingFilesDisabled={isTaskCompleted}
        documentType={DOCUMENT_TYPE}
        onFileSaveCallback={!isTaskCompleted ? updateTaskStatus : handleUploadFinish}
        onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
        onFilesErrorCallback={handleError}
        saveOnUpload={shouldSaveDocument}
      />
      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonContainer}>
          <ButtonNew
            disabled={isTaskCompleted || (!skipCvUpload && !isFilePicked)}
            isSubmitting={isSubmitting}
            onPress={completeTask}
            title={translate(translations.closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      </View>
      <Text style={styles.helper}>{translate(translations.cvTaskInfo)}</Text>
    </ScrollView>
  )
}

export default CvDocument
