import Api from 'src/utils/api'

import { OnErrorT, OnSuccessT } from 'src/utils/types'
import { WorkExperiencesT } from '../../types'

export const deleteWorkExperiences = (id: string, onSuccess: OnSuccessT, onError: OnErrorT) =>
  Api.delete(`/student/work_experiences/${id}`, {
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })

export const updateWorkExperiences = (id: string, params: WorkExperiencesT, onSuccess: OnSuccessT, onError: OnErrorT) =>
  Api.put(`/student/work_experiences/${id}`, {
    data: { work_experience: params },
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })

export const createWorkExperiences = (params: WorkExperiencesT, onSuccess: OnSuccessT, onError: OnErrorT) =>
  Api.post('/student/work_experiences', {
    data: { work_experience: params },
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })
