import { StyleSheet } from 'react-native'

import { padding, colors, colorsNew, fontSizes, fonts } from 'src/styles'

const styles = StyleSheet.create({
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  labelText: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizes.sm,
    marginBottom: padding.sm,
  },
  required: {
    color: colors.primary,
    fontSize: fontSizes.xs,
  },
})

export default styles
