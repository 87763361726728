import gql from 'graphql-tag'

export default gql`
  query Addresses($id: ID!) {
    user(id: $id) {
      id
      region {
        id
        name
      }
      otherAddresses {
        addressLine2
        city
        coordinates
        county
        geoSearchRadius
        id
        isSearchLocation
        nearbySchoolCount
        street
        title
        zip
      }
    }
  }
`
