import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import get from 'lodash/get'
import omit from 'lodash/omit'

// --- Jobs
import Applications from 'src/scenes/Main/Jobs/Lists/Applications'
import AppointedBookings from 'src/scenes/Main/Jobs/Lists/AppointedBookings/AppointedBookings'
import Booking from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Booking'
import ExpressSub from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Express'
import Order from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Order'
// --- Matching
import AddressForm from 'src/scenes/Main/Matching/Area/AddressForm'
import Area from 'src/scenes/Main/Matching/Area'
import Availability from 'src/scenes/Main/Matching/Availability'
import Preferences from 'src/scenes/Main/Matching/Preferences'
import Schools from 'src/scenes/Main/Matching/Schools'
// --- Profile
import ChangePassword from 'src/scenes/Main/Profile/ChangePassword'
import DeleteAccount from 'src/scenes/Main/Profile/DeleteAccount'
import ProfileAndRecruitment from 'src/scenes/Main/Profile/ProfileAndRecruitment'
import PushNotifications from 'src/scenes/Main/Profile/PushNotifications'
// --- Referrals
import Referrals from 'src/scenes/Main/ReferAFriendOrFoe/Referrals/Referrals'
import HowItWorks from 'src/scenes/Main/ReferAFriendOrFoe/HowItWorks/HowItWorks'
import ReferralsList from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/ReferralsList'
// --- Support
import Support from 'src/scenes/Main/Support/Support'
import FAQ from 'src/scenes/Main/Support/FAQ/Faq'
import ContactDetails from 'src/scenes/Main/Support/ContactDetails'
import Chat from 'src/scenes/Main/Support/Chat'
// --- Salary
import Timesheet from 'src/scenes/Main/Salary/Timesheet'
import History from 'src/scenes/Main/Salary/History'
import JobsCompletedInMonth from 'src/scenes/Main/Salary/History/JobsCompletedInMonth'
// -- Menu
import Menu from './Menu'
import stackNavigatorConfig from 'src/utils/headerConfig/stackNavigatorConfig'
import translations from 'src/utils/translations/translations'
import { MenuParamList } from 'src/utils/types/navigationTypes'
import mainHeaderConfig from 'src/utils/headerConfig/mainHeaderConfig'
import { useBugsnagView } from 'src/utils/bugsnag'
import LongTermOffer from '../Jobs/Details/LongTermOffer'
import LegalNotices from 'src/scenes/Main/Support/LegalNotices'

const Stack = createStackNavigator<MenuParamList>()

const StackMenuContainer = () => {
  useBugsnagView('My Jobs')

  return (
    <Stack.Navigator {...stackNavigatorConfig()}>
      <Stack.Screen
        name="Menu"
        component={Menu}
        options={params => ({
          ...omit(mainHeaderConfig(translations.menu, params), 'headerLeft'),
          headerShown: false,
        })}
      />
      <Stack.Screen
        name="ProfileAndRecruitment"
        component={ProfileAndRecruitment}
        options={params => mainHeaderConfig(translations.profile, params)}
      />
      <Stack.Screen
        name="ChangePassword"
        options={params => mainHeaderConfig(translations.changePassword, params)}
        component={ChangePassword}
      />
      <Stack.Screen
        name="DeleteAccount"
        options={params => mainHeaderConfig(translations.deleteAccount, params)}
        component={DeleteAccount}
      />
      <Stack.Screen
        name="PushNotifications"
        options={params => mainHeaderConfig(translations.pushNotificationsSettings, params)}
        component={PushNotifications}
      />
      <Stack.Screen
        name="AppointedBookings"
        options={params => mainHeaderConfig(translations.confirmedJobs, params)}
        component={AppointedBookings}
      />
      <Stack.Screen
        name="Applications"
        options={params => mainHeaderConfig(translations.pendingInterests, params)}
        component={Applications}
      />
      <Stack.Screen
        name="Availability"
        options={params => mainHeaderConfig(translations.availability, params)}
        component={Availability}
      />
      <Stack.Screen
        name="Preferences"
        options={params => mainHeaderConfig(translations.workPreferences, params)}
        component={Preferences}
      />
      <Stack.Screen
        name="Area"
        options={params => mainHeaderConfig(translations.areaPreferences, params)}
        component={Area}
      />
      <Stack.Screen
        name="Schools"
        options={params => mainHeaderConfig(translations.clientPreferences, params)}
        component={Schools}
      />
      <Stack.Screen
        name="AddressForm"
        options={params =>
          mainHeaderConfig(get(params, 'route.params.isEdit', false) ? 'editAddress' : 'addNewAddress', params)
        }
        component={AddressForm}
      />
      <Stack.Screen
        name="Timesheet"
        options={params => mainHeaderConfig(translations.timesheetMenu, params)}
        component={Timesheet}
      />
      <Stack.Screen
        name="JobsCompletedInMonth"
        options={params => mainHeaderConfig(translations.completedJobs, params)}
        component={JobsCompletedInMonth}
      />
      <Stack.Screen
        name="History"
        options={params => mainHeaderConfig(translations.history, params)}
        component={History}
      />
      <Stack.Screen name="Chat" component={Chat} options={params => mainHeaderConfig(translations.messages, params)} />
      <Stack.Screen
        name="Support"
        options={params => mainHeaderConfig(translations.support, params) as object}
        component={Support}
      />
      <Stack.Screen
        name="Referrals"
        options={params => mainHeaderConfig(translations.referrals, params) as object}
        component={Referrals}
      />
      <Stack.Screen
        name="ReferralsList"
        options={params => mainHeaderConfig(translations.yourReferrals, params) as object}
        component={ReferralsList}
      />
      <Stack.Screen
        name="HowItWorks"
        options={params => mainHeaderConfig(translations.howItWorks, params) as object}
        component={HowItWorks}
      />
      <Stack.Screen
        name="ContactDetails"
        options={params => mainHeaderConfig(translations.contactDetails, params) as object}
        component={ContactDetails}
      />
      <Stack.Screen
        name="FAQ"
        options={params => mainHeaderConfig(translations.faq, params) as object}
        component={FAQ}
      />
      <Stack.Screen
        name="LegalNotices"
        options={params => mainHeaderConfig(translations.legalNotices, params) as object}
        component={LegalNotices}
      />
      <Stack.Screen component={Booking} name="BookingMenu" options={params => mainHeaderConfig('', params)} />
      <Stack.Screen component={ExpressSub} name="ExpressSubMenu" options={params => mainHeaderConfig('', params)} />
      <Stack.Screen component={Order} name="OrderMenu" options={params => mainHeaderConfig('', params)} />
      <Stack.Screen
        component={LongTermOffer}
        name="ProposalMenu"
        options={params => mainHeaderConfig(translations.longTermOfferPendingHeader, params)}
      />
    </Stack.Navigator>
  )
}
export default StackMenuContainer
