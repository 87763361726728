import React, { useCallback, useMemo } from 'react'
import { View, Text, StyleSheet, ViewStyle } from 'react-native'

import { getCardInfo, statuses } from './utils'
import ShadowWrapper from 'src/components/ShadowWrapper'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  extraStyles?: ViewStyle
  jobsNumber: number
  onPress: () => any
  status: keyof typeof statuses
}

const ListCard = ({ status, jobsNumber = 0, onPress, extraStyles = {} }: Props) => {
  const isZero = jobsNumber === 0
  const cardIcon = useMemo(() => getCardInfo({ status, isDisabled: isZero }), [status, isZero])
  const isExpressIcon = status === 'express'

  const title = useCallback(() => {
    switch (status) {
      case statuses.longTerm:
        return translate(translations.longTermPerm)
      case statuses.express:
        return translate(translations.express)
    }
  }, [status])

  return (
    <View style={StyleSheet.flatten([styles.cardContainer, extraStyles])} testID={`ListCard-${title()}`}>
      <ShadowWrapper borderRadius={10} onPress={onPress} withBaseShadow withVerticalSpacing={false}>
        <View style={styles.card}>
          <View style={styles.statusContainer}>
            <Text numberOfLines={1} style={styles.title}>
              {title()}
            </Text>
            <Text
              style={StyleSheet.flatten([styles.statusInfo, isZero ? styles.statusInfoDisabled : {}])}
              testID="categoryStatus"
            >
              {translate(translations.availableJobs, { count: jobsNumber })}
            </Text>
          </View>

          <View style={StyleSheet.flatten([styles.iconContainer, isExpressIcon ? styles.iconExpress : {}])}>
            {cardIcon}
          </View>
        </View>
      </ShadowWrapper>
    </View>
  )
}

export default ListCard
