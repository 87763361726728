import React from 'react'
import { View, Text, TouchableWithoutFeedback } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { AppScreenProps } from 'src/utils/types/navigationTypes'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { momentWithTimezone } from 'src/global/utils'
import { totalSize, totalVariant } from 'src/scenes/Main/Salary/History/components/Total/variants'
import formatter from 'src/utils/formatter'
import Total from 'src/scenes/Main/Salary/History/components/Total'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import ShadowWrapper from 'src/components/ShadowWrapper'

type Props = {
  _durationMinutes: number
  endTime: string
  id: string
  index: number
  startTime: string
  title: string
}

const JobDetails = ({ _durationMinutes, endTime, id, index, startTime, title }: Props) => {
  const hours = Math.floor(_durationMinutes / 60)
  const minutes = _durationMinutes - hours * 60

  const navigation = useNavigation<AppScreenProps<'ReportTime'>['navigation']>()

  const navigateToJobsCompletedInMonth = () => {
    bugsnagActionBreadcrumb('go to reportTime', { id })
    navigation.navigate('ReportTime', { id })
  }

  return (
    <ShadowWrapper
      borderRadius={4}
      key={index}
      onPress={navigateToJobsCompletedInMonth}
      testID={`history-job-details-${index}`}
    >
      <View style={styles.mainContainer} key={index}>
        <View style={styles.leftContainer}>
          <Text numberOfLines={1} style={styles.title}>
            {title}
          </Text>
          <View style={styles.subTitleContainer}>
            <Text style={styles.subTitle}>{formatter.weekdayAndFullMonth(momentWithTimezone(startTime))}</Text>
            <Text style={styles.subTitle}>•</Text>
            <Text style={styles.subTitle}>
              {formatter.time(momentWithTimezone(startTime))} - {formatter.time(momentWithTimezone(endTime))}
            </Text>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <Total
            size={totalSize.small}
            text={translate(translations.totalTimeCommon, { hours, minutes })}
            variant={totalVariant.outline}
          />
          <ChevronRightIcon size={24} fill={colorsNew.redesign.secondary.main} />
        </View>
      </View>
    </ShadowWrapper>
  )
}

export default JobDetails
