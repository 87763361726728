import gql from 'graphql-tag'

export default gql`
  query ExpressBooking($id: ID!) {
    expressBooking(id: $id) {
      date
      holidayPayRate
      id
      salaryRate
    }
  }
`
