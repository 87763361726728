import { Platform, StyleProp, StyleSheet } from 'react-native'

import { fonts } from 'src/styles'

const TOP_BAR_HEIGHT = 53
const isWeb = Platform.OS === 'web'

const absoluteContainer = {
  flexDirection: 'row',
  height: TOP_BAR_HEIGHT,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 10,
}

const navigationStyles = {
  color: '#1C1C1C',
  fontFamily: fonts.poppinsMedium,
  fontSize: 14,
  fontWeight: '600',
  lineHeight: 18,
}

export default StyleSheet.create({
  wrapper: {
    height: '100%',
    width: '100%',
  },
  pageContainer: {
    flex: 1,
  },
  page: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
  },
  buttonContainer: {
    alignItems: 'flex-end',
    height: TOP_BAR_HEIGHT,
    justifyContent: 'center',
    width: 90,
    zIndex: 11,
  },
  image: {
    height: '100%',
  },
  container: {
    ...(!isWeb ? { alignItems: 'center', justifyContent: 'space-between' } : {}),
    flex: 1,
    marginTop: TOP_BAR_HEIGHT,
  },
  absoluteContainerButtons: {
    ...absoluteContainer,
    alignContent: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  } as StyleProp<any>,
  skipButton: {
    alignItems: 'flex-end',
    height: 58,
    justifyContent: 'center',
    width: 90,
    zIndex: 11,
  },
  skipText: {
    ...navigationStyles,
    alignSelf: 'center',
  },
  footerButtonContainer: {
    alignItems: 'center',
    height: 80,
    justifyContent: 'center',
    zIndex: 12,
  },
  headerButtonStyles: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonStyles: {
    width: global.isSmall ? 250 : 300,
  },
})
