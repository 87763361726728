import gql from 'graphql-tag'

import { HomeAddressTypes } from './types'

export type { HomeAddressTypes }

export default gql`
  fragment HomeAddressFields on HomeAddress {
    city
    coordinates
    geoSearchRadius
    id
    isSearchLocation
    nearbySchoolCount
    street
    title
    zip
  }
`
