import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import FileUploader from 'src/components/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'DbsCertificate'>

const DOCUMENT_TYPE = 'background_check_dbs_certificate'

const DbsCertificate = ({ navigation, route }: Props) => {
  const { status, timeToComplete, task } = route.params
  const taskStatus = {
    isCompleted: status === profileTaskStatus.completed,
    isEnabled: status === profileTaskStatus.enabled,
    isExpired: [profileTaskStatus.expired, profileTaskStatus.expiresSoon].includes(status),
  }

  useBugsnagView('RequiredTask - DbsCertificate')

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isTaskInCompletedState, setIsTaskInCompletedState] = useState(taskStatus.isExpired)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const userId = useAppSelector(state => state.user.id) as string
  const dbsCertificateDocument = useAppSelector(getUserDocumentUK(DOCUMENT_TYPE))

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) documentRef.current.dirty = isFilePicked
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsError(true)
    setIsSubmitting(false)
  }
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const updateTaskStatus = () =>
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)

  const redoTheTask = () => setIsTaskInCompletedState(false)

  const completeTask = () => {
    bugsnagActionBreadcrumb('Complete DBS Certificate task', { id: userId })
    setIsSubmitting(true)
    setIsError(false)
    isFilePicked ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      {taskStatus.isExpired ? (
        <View style={styles.redoTaskButtonWrapper}>
          <ButtonNew
            disabled={!isTaskInCompletedState}
            isShort
            onPress={redoTheTask}
            title={translate(translations.redoTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      ) : null}
      <View style={styles.uploaderWrapper}>
        <FileUploader
          arrayDocuments={dbsCertificateDocument}
          buttonDisabled={taskStatus.isCompleted || isTaskInCompletedState}
          deletingFilesDisabled={!taskStatus.isEnabled}
          description={translate(translations.dbsCertificate)}
          documentType={DOCUMENT_TYPE}
          key={DOCUMENT_TYPE}
          onFileSaveCallback={!taskStatus.isCompleted ? updateTaskStatus : handleUploadFinish}
          onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
          onFilesErrorCallback={handleError}
          saveOnUpload={shouldSaveDocument}
        />
      </View>
      {isError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsError(false)} />
        </View>
      ) : null}
      <View style={styles.buttonWrapper}>
        <ButtonNew
          containerStyle={styles.button}
          disabled={(!isFilePicked && !dbsCertificateDocument.length) || taskStatus.isCompleted}
          isLong
          isSubmitting={isSubmitting}
          onPress={completeTask}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.subInfo}>{translate(translations.taskInfoDeclaraction)}</Text>
    </ScrollView>
  )
}

export default DbsCertificate
