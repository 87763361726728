import Api, { responseErrorCallback } from 'src/utils/api'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import translations, { translate } from 'src/utils/translations/translations'
import navigationService from 'src/utils/navigationService'
import { OnSuccessT } from 'src/utils/types'
import { UserT } from '../user/userTypes'

export const updateAddresses = (data: Partial<UserT>, userId: string, onSuccess?: OnSuccessT) =>
  Api.put(`users/${userId}`, {
    data: JSON.stringify({
      user: data,
    }),
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: ({ response }) => {
      if (response.status === 422) {
        bugsnagActionBreadcrumb('go to modal from error on updateAddresses')

        navigationService.navigate('Modal', {
          title: translate(translations.somethingWentWrong),
          description: translate(translations.zipCodeError),
        })
      } else {
        bugsnagNotifyWithData('updateAddresses', response)
        responseErrorCallback('put', `users/${userId}`, response)
      }
    },
  })
