import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const emptyDescription = {
  ...typography.body2,
  color: colorsNew.redesign.text.secondary,
  textAlign: 'center',
}

export default StyleSheet.create({
  container: {
    marginBottom: paddingNew.sm,
    paddingHorizontal: paddingNew.sm,
  },
  imageWrapper: {
    justifyContent: 'center',
  },
  emptyImageAlign: {
    alignSelf: 'center',
  },
  emptyImage: {
    marginBottom: paddingNew.sm,
  },
  emptyTitle: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
    textAlign: 'center',
  },
  emptyDescription: {
    ...emptyDescription,
  },
  emptyDescriptionWithButton: {
    ...emptyDescription,
    marginBottom: paddingNew.md + 8,
  },
  emptyButton: {
    justifyContent: 'center',
  },
})
