import translations from 'src/utils/translations/translations'

export const getTimeFrameOptions = () => [
  {
    key: 'this_year',
    value: translations.thisYear,
  },
  {
    key: 'last_year',
    value: translations.lastYear,
  },
  {
    key: 'since_starting',
    value: translations.sinceStarting,
  },
]
