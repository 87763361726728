import { Platform, TouchableWithoutFeedback, View } from 'react-native'
import React, { useState } from 'react'

import SelectableText from 'src/components/SelectableText'
import SwitchElement from 'src/components/SwitchElement/SwitchElement'
import styles from '../../styles'
import LoadingIndicator from 'src/components/LoadingIndicator'

type Props = {
  disabled?: boolean
  isChecked: boolean
  isSending?: boolean
  onValueChange: (isChecked: boolean, onSuccess: () => any) => any
  style?: object | []
  testID?: string
  title: string
}

const isNotWeb = Platform.OS !== 'web'

const SwitchRow = ({ isChecked, disabled, title, onValueChange, style, testID = 'switchRow' }: Props) => {
  const [isSending, setIsSending] = useState(false)

  const onPress = () => {
    setIsSending(true)
    return onValueChange(!isChecked, () => setIsSending(false))
  }

  const onPressSwitch = (value: boolean) => {
    setIsSending(true)
    return onValueChange(value, () => setIsSending(false))
  }

  return (
    <TouchableWithoutFeedback disabled={disabled || isSending} onPress={onPress} testID={testID}>
      <View style={[styles.row, style]}>
        <SelectableText flexible style={styles.title}>
          {title}
        </SelectableText>
        <View style={styles.switchContainer}>
          {isSending ? (
            <LoadingIndicator size="small" />
          ) : (
            <SwitchElement
              disabled={disabled || isSending}
              isSwitchOn={isChecked}
              onToggle={(value: boolean) => (isNotWeb ? onPressSwitch(value) : () => null)}
              testID={`switch-${testID}`}
            />
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default SwitchRow
