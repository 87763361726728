import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useQuery } from '@apollo/client'

import { countHours } from 'src/scenes/Main/Salary/History/utils'
import { getTimeFrameOptions } from '../TimeFrameSelector/options'
import { momentWithTimezone } from 'src/global/utils'
import { paddingNew } from 'src/styles'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import formatter from 'src/utils/formatter'
import LoadingIndicator from 'src/components/LoadingIndicator'
import SummaryRequest, { BookingsSummaryRequestTypes } from 'src/graphql/Booking/Summary'
import TimeFrameSelector from '../TimeFrameSelector'
import Totals from 'src/scenes/Main/Salary/History/components/Totals'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  isMonthlySummary?: boolean
  mainTitle: string
  maxDateValue?: string
  minDateValue?: string
}

const Summary = ({ isMonthlySummary = false, mainTitle, maxDateValue, minDateValue }: Props) => {
  const timeFrameOptions = getTimeFrameOptions()

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(timeFrameOptions[0])
  const [maxDate, setMaxDate] = useState(formatter.apiFormat(momentWithTimezone()))
  const [minDate, setMinDate] = useState(formatter.apiFormat(momentWithTimezone().startOf('year')))
  const [countOfBookings, setCountOfBookings] = useState(0)
  const [countOfClients, setCountOfClients] = useState(0)
  const [countOfHours, setCountOfHours] = useState(0)
  const [isError, setIsError] = useState(false)

  const createdAt = useAppSelector(state => state?.user?.attributes?.createdAt) || ''

  const { loading, data, refetch } = useQuery<BookingsSummaryRequestTypes>(SummaryRequest, {
    variables: {
      bookingType: 'appointed',
      maxDate: isMonthlySummary ? maxDateValue : maxDate,
      minDate: isMonthlySummary ? minDateValue : minDate,
      skipCancelled: true,
    },
    onError: () => setIsError(true),
  })

  useFocusEffect(
    useCallback(() => {
      if (!loading) {
        refetch()
      }
    }, [loading, refetch]),
  )

  useEffect(() => {
    if (!data) return
    setCountOfBookings(data.bookings.nodes.length)
    setCountOfHours(countHours(data.bookings.nodes))
    setCountOfClients(new Set(data.bookings.nodes.map(booking => booking.school.id)).size)
  }, [data])

  useEffect(() => {
    switch (selectedTimeFrame.key) {
      case timeFrameOptions[0].key:
        setMaxDate(formatter.apiFormat(momentWithTimezone()))
        setMinDate(formatter.apiFormat(momentWithTimezone().startOf('year')))
        break
      case timeFrameOptions[1].key:
        setMaxDate(formatter.apiFormat(momentWithTimezone().subtract(1, 'years').endOf('year')))
        setMinDate(formatter.apiFormat(momentWithTimezone().subtract(1, 'years').startOf('year')))
        break
      case timeFrameOptions[2].key:
        setMaxDate(formatter.apiFormat(momentWithTimezone()))
        setMinDate(createdAt)
        break

      default:
        break
    }
  }, [createdAt, selectedTimeFrame, timeFrameOptions])

  if (loading) {
    return <LoadingIndicator fullScreen={false} style={styles.loading} />
  }

  return (
    <View style={styles.container}>
      <Text style={styles.componentTitle}>{translate(translations.summary)}</Text>
      <Text style={styles.mainTitle}>{mainTitle}</Text>
      <Totals
        countOfBookings={countOfBookings}
        countOfClients={countOfClients}
        countOfHours={countOfHours}
        style={StyleSheet.flatten([!isMonthlySummary && { paddingBottom: paddingNew.sm }])}
      />
      {isError ? (
        <View style={styles.alertWrapper}>
          <AlertCard
            colorSchema={alertColorScheme.error}
            description={translate(translations.serverError)}
            onClose={() => setIsError(false)}
            testId={'get-summary-error-alert'}
            title={translate(translations.serverErrorTitle)}
            variant={alertVariants.standard}
          />
        </View>
      ) : null}
      {!isMonthlySummary && <TimeFrameSelector onPress={setSelectedTimeFrame} value={selectedTimeFrame.value} />}
    </View>
  )
}

export default Summary
