import gql from 'graphql-tag'

import { OtherDocumentsRequestTypes } from './types'

export type { OtherDocumentsRequestTypes }

export default gql`
  query OtherDocuments($id: ID!) {
    user(id: $id) {
      applyAssetId
      id
      uploadedFiles
    }
  }
`
