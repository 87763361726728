import { StyleSheet, Platform } from 'react-native'

import { colors, colorsNew, fonts, padding, paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
  },
  listContainer: {
    paddingTop: Platform.OS === 'web' ? 0 : 250,
    paddingBottom: global.isIOS ? 100 : 250,
  },
  loading: { backgroundColor: colors.white },
  salaryInfo: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsMedium,
    margin: padding.sm,
    marginBottom: 0,
  },
  emptyContainer: {
    paddingTop: paddingNew.lg,
  },
})
