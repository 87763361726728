import React, { useEffect, useRef, useState } from 'react'
import { View, Text, Switch } from 'react-native'

import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  isChecked: boolean
  label: string
  onValueChange: (value: boolean) => void
  testID?: string
}

const ShiftRow = ({ label, isChecked, onValueChange, testID = 'switch' }: Props) => {
  const [error, setError] = useState(false)
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      if (timer?.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  const toggleStatus = (value: boolean) => {
    if (isChecked) {
      setError(true)

      if (timer?.current) {
        clearTimeout(timer.current)
      }

      if (!error) {
        timer.current = setTimeout(() => {
          setError(false)
        }, 500)
      }
    }
    onValueChange(value)
  }
  const switchBackground = error ? colorsNew.error : colorsNew.grey._300

  return (
    <View style={styles.labelRow} testID={`switch-${label}`}>
      <Text style={styles.shiftLabel}>{label}</Text>
      <Switch
        ios_backgroundColor={switchBackground}
        onValueChange={toggleStatus}
        style={styles.switch}
        testID={testID}
        thumbColor={colorsNew.white}
        trackColor={{
          false: switchBackground,
          true: colorsNew.brand.primary,
        }}
        value={error ? false : isChecked}
      />
    </View>
  )
}

export default ShiftRow
