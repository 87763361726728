import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SportsScoreIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M11 6H9V4H11V6ZM15 4H13V6H15V4ZM9 14H11V12H9V14ZM19 10V8H17V10H19ZM19 14V12H17V14H19ZM13 14H15V12H13V14ZM19 4H17V6H19V4ZM13 8V6H11V8H13ZM7 10V8H9V6H7V5C7 4.45 6.55 4 6 4C5.45 4 5 4.45 5 5V19C5 19.55 5.45 20 6 20C6.55 20 7 19.55 7 19V12H9V10H7ZM15 12H17V10H15V12ZM11 10V12H13V10H11ZM9 8V10H11V8H9ZM13 10H15V8H13V10ZM15 6V8H17V6H15Z" />
  </Svg>
)

export default SportsScoreIcon
