import React from 'react'
import { ActivityIndicator, View, ViewStyle } from 'react-native'

import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  fullScreen?: boolean
  size?: 'small' | 'large'
  spinnerColor?: string
  style?: ViewStyle
  withMask?: boolean
  testID?: string
}

const LoadingIndicator = ({
  fullScreen = true,
  size = 'large',
  spinnerColor,
  style: customStyles,
  testID = 'loading-indicator',
  withMask,
}: Props) => (
  <View style={[fullScreen && styles.container, withMask && styles.mask, customStyles && customStyles]} testID={testID}>
    <ActivityIndicator color={spinnerColor || colorsNew.redesign.primary.main} size={size} />
  </View>
)

export default LoadingIndicator
