import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alert: {
    marginVertical: paddingNew.base,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  infoText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.base,
  },
  textWithDot: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
  textWithDotContainer: {
    marginVertical: 0,
  },
})

export default styles
