import React, { useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableHighlight, View } from 'react-native'

import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import translations, { translate } from 'src/utils/translations/translations'
import { InfoOutlinedIcon } from 'src/icons'
import styles from './style'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { isGBSelector } from 'src/store/app/selectors'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'

const ExpressInfoModal = () => {
  const isGB = useAppSelector(isGBSelector)

  const [showModal, setShowModal] = useState(false)

  const closeModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  const list = (text: string) => (
    <View style={styles.listTextContainer}>
      <Text style={StyleSheet.flatten([styles.dot, styles.listText])}>{`\u2022`}</Text>
      <Text style={styles.listText}>{text}</Text>
    </View>
  )

  return (
    <>
      <ButtonNew
        containerStyle={styles.buttonContainer}
        onPress={openModal}
        RightIcon={InfoOutlinedIcon}
        size={buttonSize.sm}
        style={styles.button}
        testID="express-info-modal-show-button"
        title={translate(translations.furtherInfoAboutExpressSupply)}
        variant={buttonVariants.outlinedDefault}
      />

      <NewModalWrapper
        backdropOpacity={0.5}
        containerStyle={styles.modalContent}
        isVisible={showModal}
        onBackdropPress={closeModal}
        onSwipeComplete={closeModal}
        propagateSwipe
        style={styles.modal}
        swipeDirection={['down']}
      >
        <View style={styles.container}>
          <View style={styles.divider} />
          <Text style={styles.title}>{translate(translations.expressDialogTitle)}</Text>
          <ScrollView scrollEnabled showsVerticalScrollIndicator>
            <TouchableHighlight>
              <>
                <Text style={styles.mainText}>{translate(translations.expressDialogText1)}</Text>
                <Text style={styles.subTitle}>{translate(translations.expressDialogSubtitle1)}</Text>
                <Text style={styles.mainText}>{translate(translations.expressDialogText2)}</Text>
                <View style={StyleSheet.flatten([styles.list, styles.smallList])}>
                  <Text style={styles.listTitle}>{translate(translations.expressDialogListTitle1)}</Text>
                  {list(translate(translations.expressDialogList1Option1))}
                  {list(translate(translations.expressDialogList1Option2))}
                  {list(translate(translations.expressDialogList1Option3))}
                  {isGB && list(translate((translations as TranslationGBT).expressDialogList1Option4))}
                </View>
                <View style={styles.list}>
                  <Text style={styles.listTitle}>{translate(translations.expressDialogListTitle2)}</Text>
                  {list(translate(translations.expressDialogList2Option1))}
                  {list(translate(translations.expressDialogList2Option2))}
                  {list(translate(translations.expressDialogList2Option3))}
                </View>
                <Text style={styles.infoText}>{translate(translations.expressDialogInfoText)}</Text>
              </>
            </TouchableHighlight>
          </ScrollView>
          <View style={styles.buttonWrapper}>
            <ButtonNew
              isLong
              onPress={closeModal}
              title={translate(translations.close)}
              variant={buttonVariants.outlinedDefault}
            />
          </View>
        </View>
      </NewModalWrapper>
    </>
  )
}

export default ExpressInfoModal
