import { StyleSheet, Dimensions } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'
import { CustomModalVariants, CustomModalColorSchema } from './types'

export const MAX_HEIGHT = Dimensions.get('window').height * 0.8

export const iconColor = {
  default: {
    filled: colorsNew.black,
    standard: colorsNew.black,
  },
  error: {
    filled: colorsNew.white,
    standard: colorsNew.redesign.error.dark,
  },
  info: {
    filled: colorsNew.white,
    standard: colorsNew.redesign.info.dark,
  },
  success: {
    filled: colorsNew.white,
    standard: colorsNew.redesign.success.dark,
  },
  warning: {
    filled: colorsNew.white,
    standard: colorsNew.redesign.warning.dark,
  },
}

export const cardColor = {
  default: {
    filled: colorsNew.redesign.primary.main,
    standard: colorsNew.redesign.primary.main,
  },
  error: {
    filled: colorsNew.redesign.error.main,
    standard: colorsNew.redesign.error.shades12p,
  },
  info: {
    filled: colorsNew.redesign.info.main,
    standard: colorsNew.redesign.info.shades12p,
  },
  success: {
    filled: colorsNew.redesign.success.main,
    standard: colorsNew.redesign.success.shades12p,
  },
  warning: {
    filled: colorsNew.redesign.warning.main,
    standard: colorsNew.redesign.warning.shades12p,
  },
}

export const getCardColor = (variant: CustomModalVariants, colorSchema: CustomModalColorSchema) => ({
  backgroundColor: cardColor[colorSchema][variant],
  borderColor: cardColor[colorSchema].filled,
  borderWidth: variant === 'standard' ? 0 : 1,
})

export const getTextColor = (variant: CustomModalVariants, colorSchema: CustomModalColorSchema) => ({
  color: variant === 'filled' && colorSchema !== 'default' ? colorsNew.white : textColor[colorSchema],
})

export const textColor = {
  default: colorsNew.redesign.text.primary,
  error: colorsNew.redesign.error.text,
  info: colorsNew.redesign.info.text,
  success: colorsNew.redesign.success.text,
  warning: colorsNew.redesign.warning.text,
}

export default StyleSheet.create({
  buttonContainer: {
    marginVertical: paddingNew.xxs,
  },
  buttonsContainer: {
    paddingBottom: paddingNew.xs,
    paddingHorizontal: paddingNew.sm,
  },
  children: {
    paddingBottom: paddingNew.base,
    paddingHorizontal: paddingNew.sm,
    paddingTop: paddingNew.xs,
  },
  description: {
    ...typography.body1,
    paddingBottom: paddingNew.base,
    paddingHorizontal: paddingNew.sm,
    paddingTop: paddingNew.sm,
    color: colorsNew.redesign.text.primary,
  },
  horizontalButtonContainer: {
    width: '48%',
  },
  noInfo: {
    paddingTop: paddingNew.lg,
  },
  modalWrapper: {
    backgroundColor: colorsNew.white,
    borderRadius: 8,
    maxHeight: MAX_HEIGHT,
    minHeight: 200,
  },
  modalWrapperWeb: {
    width: '90%',
    maxWidth: 500,
  },
  subtitle: {
    ...typography.subtitle2,
    paddingHorizontal: paddingNew.sm,
    paddingTop: paddingNew.sm,
  },
  icon: {
    marginRight: paddingNew.xs,
  },
  title: {
    ...typography.h7,
    flexShrink: 1,
  },
  titleGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 1,
  },
  titleContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.sm - 3,
  },
  twoButtonsContainer: {
    columnGap: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: paddingNew.xs,
    paddingHorizontal: paddingNew.sm,
  },
  webContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.translucentBlack,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 5,
  },
})
