import React, { useContext, useCallback, useState } from 'react'
import { Text, View } from 'react-native'

import { MixpanelContext } from 'src/context/MixpanelContext'
import { removeApplication } from '../../actions'
import ActionsOverlay from 'src/scenes/Main/Jobs/Details/components/ActionsOverlay'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import WithdrawModal from 'src/scenes/Main/Jobs/Details/components/WithdrawModal'

type Props = {
  applicationId?: string
  bookingId: string
  goBack: () => void
  isUpdating: boolean
  setIsUpdating: (value: boolean) => void
}

const WithdrawInterestOverlay = ({ applicationId, bookingId, isUpdating, goBack, setIsUpdating }: Props) => {
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const { mixpanel } = useContext(MixpanelContext)

  const onRequestSuccess = useCallback(() => {
    goBack()
    setIsUpdating(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRequestError = useCallback(() => {
    setIsUpdating(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onWithdraw = () => {
    setIsUpdating(true)
    setShowWithdrawModal(false)
    mixpanel?.track('Withdraw Booking', { bookingId })
    applicationId && removeApplication(bookingId, applicationId, onRequestSuccess, onRequestError)
  }

  return (
    <>
      <ActionsOverlay withText>
        <View style={styles.container}>
          <Text style={styles.title}>{translate(translations.jobOfferWithdrawTitle)}</Text>
          <ButtonNew
            disabled={isUpdating}
            isLong
            onPress={() => setShowWithdrawModal(true)}
            testID="withdraw-button"
            title={translate(translations.deleteMyApplication)}
            variant={buttonVariants.outlinedError}
          />
        </View>
      </ActionsOverlay>

      <WithdrawModal
        isVisible={showWithdrawModal}
        closeWithdrawModal={() => setShowWithdrawModal(false)}
        onWithdraw={onWithdraw}
      />
    </>
  )
}

export default WithdrawInterestOverlay
