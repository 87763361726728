import { ChatMessage } from 'src/graphql/Chat'

export const convertMessage = (message: ChatMessage) => ({
  _id: message.id,
  text: message.content,
  user: {
    _id: message.senderId,
    avatar: require('src/assets/images/logoSymbolBlack.png'),
    name: `${message.sender?.firstName} ${message.sender?.lastName}`,
  },
  createdAt: message.createdAt,
  origin: message.origin,
})
