import React, { useContext, useState } from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import { useQuery } from '@apollo/client'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, ErrorAlert, errorTypes, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DownloadIcon } from 'src/icons'
import { getIsM2Educator } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import formatter from 'src/utils/formatter'
import LoadingIndicator from 'src/components/LoadingIndicator'
import PolicyDocumentAcceptances, { PolicyDocumentAcceptancesRequestTypes } from 'src/graphql/DocumentAcceptances'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'ContractAgreement'>

const DOCUMENT_TYPES = ['contract_agreement', 'key_information_document']
const DOWNLOAD_MAIL = 'mailto:quality@humly.co.uk'

const ContractAgreement = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - ContractAgreement')

  const userId = useAppSelector(state => state.user.id) as string
  const isM2Educator = useAppSelector(getIsM2Educator)
  const dispatch = useAppDispatch()

  const [isCompleteTaskError, setIsCompleteTaskError] = useState(false)
  const [isContractAccepted, setIsContractAccepted] = useState(isCompleted)
  const [isKidAccepted, setIsKidAccepted] = useState(isCompleted)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isUrlError, setIsUrlError] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const { data, loading } = useQuery<PolicyDocumentAcceptancesRequestTypes>(PolicyDocumentAcceptances, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
      documentTypes: DOCUMENT_TYPES,
    },
    onCompleted: res => {
      const isKidDoc = res.policyDocumentAcceptances.nodes.some(
        policy => policy.policyDocument.type === DOCUMENT_TYPES[1],
      )
      const isContractDoc = res.policyDocumentAcceptances.nodes.some(
        policy => policy.policyDocument.type === DOCUMENT_TYPES[0],
      )
      setIsKidAccepted(isKidDoc)
      setIsContractAccepted(isContractDoc)
    },
    onError: () => setIsUrlError(true),
  })

  const contractPolicy = data?.policyDocuments.nodes.find(item => item.type === DOCUMENT_TYPES[0])
  const kidPolicy = data?.policyDocuments.nodes.find(item => item.type === DOCUMENT_TYPES[1])

  const handleError = () => {
    setIsCompleteTaskError(true)
    setIsSubmitting(false)
  }

  const closeUrlError = () => setIsUrlError(false)

  const completeTask = () => {
    setIsSubmitting(true)
    setIsCompleteTaskError(false)
    const now = new Date()
    const newUser = {
      acceptedPolicyDocumentIds: [contractPolicy?.id, kidPolicy?.id],
      keyInformationDocumentAgreedOn: formatter.apiFormat(now),
    }
    bugsnagActionBreadcrumb('submit on template', { id: userId, user: newUser })

    dispatch(
      saveUser({
        id: userId,
        onError: handleError,
        onSuccess: () => markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack, handleError),
        stringifyData: true,
        user: newUser,
      }),
    )
  }

  if (loading) {
    return <LoadingIndicator />
  }

  const contactQualityButton = () => (
    <ButtonNew
      onPress={() => openURLIfCan(DOWNLOAD_MAIL)}
      title={translate(translations.contractAgreementKidM2Button)}
      variant={buttonVariants.textDefault}
    />
  )

  const downloadButton = () => (
    <ButtonNew
      disabled={isUrlError}
      LeftIcon={DownloadIcon}
      onPress={() => openURLIfCan(kidPolicy?.url)}
      title={translate(translations.contractAgreementKidButton)}
      variant={buttonVariants.outlinedDefault}
    />
  )

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <View style={styles.section}>
        <Text style={styles.mainText}>
          {!isM2Educator
            ? translate(translations.contractAgreementKidInfo)
            : translate(translations.contractAgreementKidInfoM2Contract)}
        </Text>
        <View style={styles.buttonSection}>
          {isUrlError ? <ErrorAlert hideError={closeUrlError} type={errorTypes.file} /> : null}
          <View style={styles.buttonWrapper}>{isM2Educator ? contactQualityButton() : downloadButton()}</View>
        </View>
        {!isM2Educator && (
          <Checkbox
            disabled={isCompleted}
            label={translate(translations.contractAgreementKidCheckbox)}
            onPress={() => setIsKidAccepted(prev => !prev)}
            selected={isKidAccepted}
          />
        )}
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>
          {!isM2Educator
            ? translate(translations.contractAgreementContractTitle)
            : translate(translations.contractAgreementM2ContractTitle)}
        </Text>
        <Text style={styles.mainText}>
          {!isM2Educator
            ? translate(translations.contractAgreementContractInfo)
            : translate(translations.contractAgreementM2ContractInfo)}
        </Text>
        {!isM2Educator && (
          <>
            <View style={styles.buttonSection}>
              {isUrlError ? <ErrorAlert hideError={closeUrlError} type={errorTypes.file} /> : null}
              <View style={styles.buttonWrapper}>
                <ButtonNew
                  disabled={isUrlError}
                  LeftIcon={DownloadIcon}
                  onPress={() => openURLIfCan(contractPolicy?.url)}
                  title={translate(translations.contractAgreementContractButton)}
                  variant={buttonVariants.outlinedDefault}
                />
              </View>
            </View>
            <Checkbox
              disabled={isCompleted}
              label={translate(translations.contractAgreementContractCheckbox)}
              onPress={() => setIsContractAccepted(prev => !prev)}
              selected={isContractAccepted}
            />
          </>
        )}
      </View>
      <Text style={styles.infoText}>{translate(translations.contractAgreementTaskInfo)}</Text>
      {isCompleteTaskError ? <CompleteTaskError hideError={() => setIsCompleteTaskError(false)} /> : null}
      <View style={StyleSheet.flatten([styles.buttonWrapper, styles.buttonExtraSpace])}>
        <ButtonNew
          disabled={isCompleted || ((!isKidAccepted || !isContractAccepted) && !isM2Educator)}
          onPress={completeTask}
          isSubmitting={isSubmitting}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
    </ScrollView>
  )
}

export default ContractAgreement
