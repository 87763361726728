import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SnapIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 202 202" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M100.935 169.441c38.7 0 70.072-31.488 70.072-70.33s-31.372-70.33-70.072-70.33-70.072 31.488-70.072 70.33 31.372 70.33 70.072 70.33Z"
      fill="#224D3D"
    />
    <Path
      d="M72.258 128.163c-.202-.352-.202-.789.006-1.179.108-.197 5.79-3.677 5.928-3.947.547-1.052 1.286-2.545 1.73-3.762.778-2.118 2.796-6.619 4.227-8.892 1.43-2.272.947-8.114 1.026-9.496.079-1.382.884-5.636 6.323-4.27 3.91.983 3.537 8.089 3.432 11.544-.028.97 1.052 1.512 1.965.986l24.994-14.834c2.334-1.344 5.722-.85 7.207 1.746 1.484 2.596.344 5.814-1.987 7.379-1.809 1.218-9.272 5.601-12.519 7.503-.777.454-.966 1.439-.388 2.007 1.32 1.303 1.936 3.16 1.993 5.046l-.029.9c-.035 1.043.161 1.252.891 1.997.694.707 1.124 1.947 1.133 2.606.029 2.073-.707 3.357-.198 3.867 2.374 2.384.249 5.249-1.769 6.413l-9.989 5.959c-1.358.783-3.711 1.67-5.227 1.955a1.792 1.792 0 0 1-.385.032c-.777-.032-4.99-.168-8.468.225-1.146.13-1.973.263-2.567.384-.237.047-5.991 3.338-6.162 3.382-.584.155-1.162-.07-1.43-.542l-9.737-17.009Z"
      fill="#F7FCFB"
    />
    <Path
      d="m106.657 51.485 1.22-2.479a.984.984 0 0 1 1.767 0l1.221 2.48a.985.985 0 0 0 .743.54l2.728.399c.809.117 1.133 1.115.547 1.69l-1.975 1.93a.992.992 0 0 0-.284.877l.466 2.724c.139.809-.706 1.426-1.431 1.042l-2.437-1.287a.995.995 0 0 0-.919 0l-2.437 1.287a.986.986 0 0 1-1.431-1.042l.466-2.724a.995.995 0 0 0-.284-.877l-1.975-1.93a.992.992 0 0 1 .547-1.69l2.728-.399c.317-.047.594-.252.74-.54Z"
      fill="#00B58B"
    />
    <Path
      d="M108.712 91.176a.716.716 0 0 1-.714-.717V65.42c0-.395.32-.717.714-.717.393 0 .714.322.714.717v25.04a.717.717 0 0 1-.714.716Z"
      fill="#F7FCFB"
    />
    <Path
      d="m88.464 60.798.167-2.76c.051-.82 1.013-1.226 1.629-.688l2.083 1.81c.245.212.58.292.893.208l2.667-.695a.987.987 0 0 1 1.154 1.342l-1.074 2.549a.989.989 0 0 0 .076.918l1.483 2.332c.44.692-.102 1.587-.914 1.518l-2.747-.238a.975.975 0 0 0-.845.359l-1.753 2.135a.986.986 0 0 1-1.723-.406l-.623-2.695a.992.992 0 0 0-.6-.698l-2.566-1.013c-.761-.3-.849-1.346-.149-1.77l2.36-1.426a.972.972 0 0 0 .482-.782Z"
      fill="#00B58B"
    />
    <Path
      d="M101.863 88.13a.711.711 0 0 1-.62-.439l-6.55-15.631a.711.711 0 1 1 1.312-.556l6.55 15.631a.716.716 0 0 1-.379.936.63.63 0 0 1-.313.059Z"
      fill="#F7FCFB"
    />
    <Path
      d="m129.055 60.798-.168-2.76c-.051-.82-1.013-1.226-1.628-.688l-2.084 1.81a.978.978 0 0 1-.893.208l-2.666-.695a.987.987 0 0 0-1.155 1.342l1.074 2.549c.128.3.099.643-.076.918l-1.483 2.332a.989.989 0 0 0 .915 1.518l2.746-.238a.975.975 0 0 1 .846.359l1.752 2.135a.986.986 0 0 0 1.723-.406l.623-2.695a.993.993 0 0 1 .601-.698l2.565-1.013a.992.992 0 0 0 .149-1.77l-2.36-1.426a.96.96 0 0 1-.481-.782Z"
      fill="#00B58B"
    />
    <Path
      d="M115.656 88.13a.708.708 0 0 0 .619-.44l6.55-15.63a.716.716 0 0 0-.379-.936.71.71 0 0 0-.932.38l-6.55 15.631a.716.716 0 0 0 .378.936.633.633 0 0 0 .314.059Z"
      fill="#F7FCFB"
    />
  </Svg>
)

export default SnapIcon
