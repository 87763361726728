import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const PsychologyRoundedIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M13.2098 2.99904C9.36982 2.88904 6.20982 5.86904 6.01982 9.63904L4.09982 12.199C3.84982 12.529 4.08982 12.999 4.49982 12.999H5.99982V15.999C5.99982 17.099 6.89982 17.999 7.99982 17.999H8.99982V19.999C8.99982 20.549 9.44982 20.999 9.99982 20.999H14.9998C15.5498 20.999 15.9998 20.549 15.9998 19.999V16.319C18.4398 15.159 20.0998 12.639 19.9998 9.73904C19.8598 6.11904 16.8198 3.10904 13.2098 2.99904ZM15.9998 9.99904C15.9998 10.129 15.9898 10.259 15.9798 10.389L16.8098 11.049C16.8898 11.109 16.9098 11.209 16.8598 11.299L16.0598 12.689C16.0098 12.779 15.8998 12.809 15.8198 12.779L14.8298 12.379C14.6198 12.539 14.3998 12.669 14.1598 12.769L13.9998 13.829C13.9898 13.929 13.8998 13.999 13.7998 13.999H12.1998C12.0998 13.999 12.0198 13.929 11.9998 13.829L11.8498 12.769C11.5998 12.669 11.3798 12.539 11.1698 12.379L10.1798 12.779C10.0898 12.809 9.97983 12.779 9.92982 12.689L9.12982 11.299C9.07982 11.219 9.09982 11.109 9.17982 11.049L10.0198 10.389C10.0098 10.259 9.99982 10.129 9.99982 9.99904C9.99982 9.86904 10.0198 9.72904 10.0398 9.60904L9.18982 8.94904C9.10982 8.88904 9.08982 8.78904 9.13982 8.68904L9.93982 7.30904C9.98982 7.21904 10.0898 7.18904 10.1798 7.21904L11.1798 7.61904C11.3798 7.46904 11.6098 7.32904 11.8498 7.22904L11.9998 6.16904C12.0198 6.06904 12.0998 5.99904 12.1998 5.99904H13.7998C13.8998 5.99904 13.9798 6.06904 13.9998 6.16904L14.1498 7.22904C14.3898 7.32904 14.6098 7.45904 14.8198 7.61904L15.8198 7.21904C15.9098 7.18904 16.0198 7.21904 16.0598 7.30904L16.8598 8.68904C16.9098 8.77904 16.8898 8.88904 16.8098 8.94904L15.9598 9.60904C15.9898 9.72904 15.9998 9.85904 15.9998 9.99904Z" />
    <Path d="M12.9998 8.57031C12.2098 8.57031 11.5698 9.21031 11.5698 10.0003C11.5698 10.7903 12.2098 11.4303 12.9998 11.4303C13.7898 11.4303 14.4298 10.7903 14.4298 10.0003C14.4298 9.21031 13.7898 8.57031 12.9998 8.57031Z" />
  </Svg>
)

export default PsychologyRoundedIcon
