import { ActivityIndicator, Image, View } from 'react-native'
import React, { useEffect, useState } from 'react'

import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  uri: number | string
}

const AnnouncementModalImage = ({ uri }: Props) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

  const isImgExternal = typeof uri === 'string'
  const imgSource = isImgExternal ? { uri } : uri

  useEffect(() => {
    if (isImgExternal) {
      Image.getSize(uri, (width, height) => {
        setImageSize({ width, height })
      })
    } else {
      const { width, height } = Image.resolveAssetSource(uri)
      setImageSize({ width, height })
    }
  }, [isImgExternal, uri])

  const isHorizontal = imageSize?.width > imageSize?.height

  return (
    <>
      {!imageSize.width ? (
        <ActivityIndicator color={colorsNew.redesign.primary.main} size="small" />
      ) : (
        <>
          <View style={styles.imageSpace} />
          <Image source={imgSource} style={{ ...styles.image }} />
        </>
      )}
    </>
  )
}

export default AnnouncementModalImage
