import React from 'react'
import { Text, View } from 'react-native'
import LinearGradient from 'src/utils/webAdapters/LinearGradient'

import { gradients } from 'src/styles'
import { isGBSelector } from 'src/store/app/selectors'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ReferAnimation, {
  referAnimationVariants,
  TReferAnimationVariant,
} from 'src/scenes/Main/ReferAFriendOrFoe/ReferAnimation'
import { moneyInfoVariant } from '.'
import Skeleton from 'src/components/Skeleton'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import type { MoneyInfoVariant } from './types'

type Props = {
  amountOfMoney: number
  animationVariant: TReferAnimationVariant
  isLoading?: boolean
  moneyRise: number
  playAnimation: boolean
  variant: MoneyInfoVariant
}

const mappedGradients = {
  earned: gradients.shade2,
  given: gradients.shade1,
}

const MoneyInfo = ({ amountOfMoney, animationVariant, isLoading, moneyRise, playAnimation, variant }: Props) => {
  const isGB = useAppSelector(isGBSelector)

  const mappedTitle = {
    earned: translate(translations.earned),
    given: translate(translations.given),
  }

  const showAnimation =
    playAnimation &&
    (variant === moneyInfoVariant.given
      ? animationVariant === referAnimationVariants.give || animationVariant === referAnimationVariants.split
      : animationVariant === referAnimationVariants.take)

  const formatMoneyWithCurrency = (money: number) =>
    isGB ? `${translate(translations.currency)}${money}` : `${money} ${translate(translations.currency)}`

  return (
    <View style={styles.container}>
      {isLoading ? (
        <LinearGradient colors={mappedGradients[variant]} style={styles.gradientContainer}>
          <Text style={styles.title}>{mappedTitle[variant]}</Text>
          <Skeleton width={85} height={32} />
          <View style={styles.skeletonContainer}>
            <Skeleton width={111} height={7} />
          </View>
        </LinearGradient>
      ) : (
        <>
          <ReferAnimation showAnimation={showAnimation} variant={animationVariant} />
          <LinearGradient colors={mappedGradients[variant]} style={styles.gradientContainer}>
            <Text style={styles.title}>{mappedTitle[variant]}</Text>
            <Text style={styles.mainInfo}>{formatMoneyWithCurrency(amountOfMoney)}</Text>
            <Text style={styles.subInfo}>
              {translate(translations.moneyRiseInfo, { money: formatMoneyWithCurrency(moneyRise) })}
            </Text>
          </LinearGradient>
        </>
      )}
    </View>
  )
}

export default MoneyInfo
