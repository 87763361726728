import { Platform, StyleSheet, ViewStyle } from 'react-native'

import { common, paddingNew } from 'src/styles'

const isWeb = Platform.OS === 'web'

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingTop: paddingNew.base / 2,
  },
  button: StyleSheet.flatten([common.centerSelf, { width: 150 }]),
  buttonContainer: { position: 'relative', paddingTop: paddingNew.md },
  loading: { position: 'absolute' },
  inputRow: {
    ...(!isWeb ? {} : { zIndex: 'auto' }),
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.base / 2,
    width: '100%',
  } as ViewStyle,
})
