import { useEffect, useState, useRef } from 'react'
import { AppState, AppStateStatus } from 'react-native'

const isValidFunction = (func?: (status?: AppStateStatus) => void) => func && typeof func === 'function'

type Props = {
  onChange?: (status?: AppStateStatus) => void
  onForeground?: () => void
  onBackground?: () => void
}

export const useAppState = ({ onChange, onForeground, onBackground }: Props = {}) => {
  const [appState, setAppState] = useState(AppState.currentState)

  const appStateRef = useRef(AppState.currentState)

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (nextAppState === 'active' && appStateRef.current.match(/inactive|background/)) {
      isValidFunction(onForeground) && onForeground && onForeground()
    } else if (appStateRef.current === 'active' && nextAppState.match(/inactive|background/)) {
      isValidFunction(onBackground) && onBackground && onBackground()
    }
    appStateRef.current = nextAppState
    setAppState(appStateRef.current)
    isValidFunction(onChange) && onChange && onChange(nextAppState)
  }

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handleAppStateChange)

    return () => subscription && subscription.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { appState }
}
