import React, { useContext } from 'react'
import { RouteProp, useRoute } from '@react-navigation/native'
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack'

import { isGBSelector } from 'src/store/app/selectors'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { OtherDocumentsSv, OtherDocumentsGB } from './RequiredTasks/OtherDocuments'
import { ProfileAndRecruitmentParamList } from 'src/utils/types/navigationTypes'
import { ReferencesGB, ReferencesSv } from './RequiredTasks/References'
import { useBugsnagView } from 'src/utils/bugsnag'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AcademyIntroduction from './RequiredTasks/AcademyIntroduction/AcademyIntroduction'
import AddressVerification from './RequiredTasks/AddressVerification/AddressVerification'
import BookInterview from './RequiredTasks/BookInterview'
import ChildCareCertificate from './RequiredTasks/ChildCareCertificate/ChildCareCertificate'
import Contract from './RequiredTasks/Contract/Contract'
import ContractAgreement from './RequiredTasks/ContractAgreement'
import CvDocument from './RequiredTasks/CvDocument/CvDocument'
import DbsCertificate from './RequiredTasks/DbsCertificate/DbsCertificate'
import DbsDeclaration from './RequiredTasks/DbsDeclaration/DbsDeclaration'
import DisqualificationDeclaration from './RequiredTasks/DisqualificationDeclaration/DisqualificationDeclaration'
import EmergencyContactDetails from './RequiredTasks/EmergencyContactDetails/EmergencyContactDetails'
import EmploymentHistory from './RequiredTasks/EmploymentHistory/EmploymentHistory'
import HighSchoolEducation from './RequiredTasks/HighSchoolEducation'
import IdVerification from './RequiredTasks/IdVerification/IdVerification'
import Interview from './RequiredTasks/Interview'
import LoadingIndicator from 'src/components/LoadingIndicator'
import MedicalQuestionnaire from './RequiredTasks/MedicalQuestionnaire/MedicalQuestionnaire'
import NiNumber from './RequiredTasks/NiNumber/NINumber'
import PayrollDetails from './RequiredTasks/PayrollDetails'
import PersonalDetails from './RequiredTasks/PersonalDetails'
import PersonalProfile from './RequiredTasks/PersonalProfile/PersonalProfile'
import Polices from './RequiredTasks/Polices/Polices'
import PolicyAgreements from './RequiredTasks/PolicyAgreements/PolicyAgreements'
import profileAndRecruitmentHeader from 'src/utils/headerConfig/profileAndRecruitmentHeaderConfig'
import ProfileDetails from 'src/scenes/Main/Profile/ProfileDetails/ProfileDetails'
import ProofOfEducations from './RequiredTasks/ProofOfEducations/ProofOfEducations'
import ProofOfPoliceCheck from './RequiredTasks/ProofOfPoliceCheck/ProofOfPoliceCheck'
import ProofOfPoliceReport from './RequiredTasks/ProofOfPoliceReport/ProofOfPoliceReport'
import ProofOfRightToWork from './RequiredTasks/ProofOfRightToWork/ProofOfRightToWork'
import Qualifications from './RequiredTasks/Qualifications'
import SafeguardingDeclaration from './RequiredTasks/SafeguardingDeclaration'
import TaskList from './TasksList'
import TeacherReferenceNumber from './RequiredTasks/TeacherReferenceNumber/TeacherReferenceNumber'
import TeachingCertificates from './RequiredTasks/TeachingCertificates/TeachingCertificates'
import UniversityEducation from './RequiredTasks/UniversityEducation'
import useInitUrlApp from 'src/hooks/useInitUrlApp'
import YotiStatus from './RequiredTasks/YotiStatus'

const ProfileAndRecruitmentStack = createStackNavigator<ProfileAndRecruitmentParamList>()

const ProfileAndRecruitment = () => {
  const isGB = useAppSelector(isGBSelector)
  const { isLoading } = useInitUrlApp()
  const { mixpanel } = useContext(MixpanelContext)

  useBugsnagView('Profile - ProfileAndRecruitment')

  const profileAndRecruitmentHeaderConfig = (props: {
    route: RouteProp<ProfileAndRecruitmentParamList, keyof ProfileAndRecruitmentParamList>
    navigation: StackNavigationProp<ProfileAndRecruitmentParamList>
  }) => profileAndRecruitmentHeader(props, mixpanel)

  const { params } = useRoute<RouteProp<{ params: { isMenuTab?: boolean } }>>()

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <ProfileAndRecruitmentStack.Navigator screenOptions={{ headerMode: 'screen' }}>
      <ProfileAndRecruitmentStack.Screen
        component={ProfileDetails}
        name="ProfileDetails"
        options={{ headerShown: false }}
        initialParams={{ isMenuTab: params?.isMenuTab || false }}
      />
      <ProfileAndRecruitmentStack.Screen
        component={TaskList}
        name="TasksList"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={AddressVerification}
        name="AddressVerification"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={ChildCareCertificate}
        name="ChildCareCertificate"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={EmergencyContactDetails}
        name="EmergencyContactDetails"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={HighSchoolEducation}
        name="HighSchoolEducation"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={ContractAgreement}
        name="ContractAgreement"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={EmploymentHistory}
        name="EmploymentHistory"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={UniversityEducation}
        name="UniversityEducation"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={PayrollDetails}
        name="PayrollDetails"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={PersonalDetails}
        name="PersonalDetails"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={ProofOfEducations}
        name="ProofOfEducations"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={BookInterview}
        name="BookInterview"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={isGB ? ReferencesGB : ReferencesSv}
        name="References"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={AcademyIntroduction}
        name="AcademyIntroduction"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={Interview}
        name="Interview"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={NiNumber}
        name="NiNumber"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={MedicalQuestionnaire}
        name="MedicalQuestionnaire"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={Contract}
        name="Contract"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={DbsCertificate}
        name="DbsCertificate"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={DbsDeclaration}
        name="DbsDeclaration"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={DisqualificationDeclaration}
        name="DisqualificationDeclaration"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={IdVerification}
        name="IdVerification"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={isGB ? OtherDocumentsGB : OtherDocumentsSv}
        name="OtherDocuments"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={Qualifications}
        name="Qualifications"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={CvDocument}
        name="CvDocument"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={Polices}
        name="Polices"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={PolicyAgreements}
        name="PolicyAgreements"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={ProofOfPoliceCheck}
        name="ProofOfPoliceCheck"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={ProofOfPoliceReport}
        name="ProofOfPoliceReport"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={PersonalProfile}
        name="PersonalProfile"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={SafeguardingDeclaration}
        name="Safeguarding"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={ProofOfRightToWork}
        name="ProofOfRightToWork"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={TeacherReferenceNumber}
        name="TeacherReferenceNumber"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={TeachingCertificates}
        name="TeachingCertificates"
        options={profileAndRecruitmentHeaderConfig}
      />
      <ProfileAndRecruitmentStack.Screen
        component={YotiStatus}
        name="DigitalRightToWork"
        options={profileAndRecruitmentHeaderConfig}
      />
    </ProfileAndRecruitmentStack.Navigator>
  )
}

export default ProfileAndRecruitment
