import React, { useCallback, useRef, useState } from 'react'
import { DEV_USER_EMAIL, DEV_USER_PWD } from 'src/utils/webAdapters/DotEnvAdapter'
import { Field, Formik, FormikHelpers, FormikProps } from 'formik'
import { Keyboard, Platform, StyleSheet, Text, TextInput, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import Animated, { CurvedTransition } from 'react-native-reanimated'
import cloneDeep from 'lodash/cloneDeep'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'

import { ArrowRightIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { countryCodeSelector } from 'src/store/app/selectors'
import { CountryPicker } from 'src/components/Form'
import { getApiUrl } from 'src/utils/apiConfig'
import { InputTextPassword } from 'src/components/Form'
import { LoginRoutesScreensProps } from 'src/utils/types/navigationTypes'
import { openInterestForm } from 'src/global/utils'
import { setCountryCode } from 'src/store/app/actions'
import Api from 'src/utils/api'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import getValidationSchema from './validation'
import LoginFormHeader from './LoginFormHeader'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import { LocaleT } from 'src/utils/translations/i18nTypes'
import { useAppDispatch } from 'src/hooks/reduxHooks'

const isWeb = Platform.OS === 'web'

interface InitialValuesTypes {
  countryCode: LocaleT
  email: string
  errorMessage?: string
  password: string
}

type FormInputRefType = TextInput | null

type Props = {
  emailCopy?: string
  onSubmit: (values: InitialValuesTypes, form: FormikHelpers<InitialValuesTypes>) => void
  passwordCopy?: string
  formRef: FormikProps<any>
}

const LoginForm = ({ emailCopy = '', onSubmit, passwordCopy = '', formRef }: Props) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const appCountryCode = useSelector(countryCodeSelector)
  const dispatch = useAppDispatch()
  const navigation = useNavigation<LoginRoutesScreensProps<'Login'>['navigation']>()
  const passwordInput = useRef<FormInputRefType>(null)

  // @ts-ignore
  const isConnected = useSelector(state => state.app.isConnected)

  const onResetPasswordPress = (pickedCountryCode: LocaleT) => {
    setIsHeaderVisible(true)
    const url = getApiUrl(pickedCountryCode)
    Api.changeUrl(url)
    navigation.navigate('ResetPassword')
  }

  const getValue = (type: string, extra: string) => (type?.length ? cloneDeep(type) : extra)

  const hideHeader = () => !isWeb && setIsHeaderVisible(false)
  const showHeader = () => setIsHeaderVisible(true)

  useFocusEffect(
    useCallback(() => {
      const loginkeyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
        hideHeader()
      })

      const loginkeyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
        showHeader()
      })

      return () => {
        loginkeyboardDidHideListener.remove()
        loginkeyboardDidShowListener.remove()
      }
    }, []),
  )

  const validationSchema = getValidationSchema()

  const initialValues: InitialValuesTypes = {
    countryCode: appCountryCode,
    email: getValue(emailCopy, __DEV__ ? DEV_USER_EMAIL : ''),
    password: getValue(passwordCopy, __DEV__ ? DEV_USER_PWD : ''),
  }

  return (
    <>
      <LoginFormHeader showHeader={isHeaderVisible} />
      <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => (
          <Animated.View layout={CurvedTransition.delay(100)} style={styles.mainContainer}>
            <View>
              <Field
                component={CountryPicker}
                disabled={!isHeaderVisible}
                isSearchable={false}
                name="countryCode"
                onChange={(value: LocaleT) => dispatch(setCountryCode(value))}
              />

              <TextInputNew
                autoCapitalize="none"
                blurOnSubmit={false}
                errorMessage={errors.email}
                isError={!isEmpty(errors.email) && touched.email}
                keyboardType="email-address"
                label={translate(translations.emailAddress)}
                onBlur={() => setFieldTouched('email', true)}
                onChangeText={text => setFieldValue('email', text)}
                onSubmitEditing={() => {
                  passwordInput?.current?.focus()
                }}
                required
                returnKeyType="next"
                size={TextInputSizeVariants.small}
                testID="email"
                value={values.email}
              />

              <Field
                autoCapitalize="none"
                component={InputTextPassword}
                containerStyles={styles.inputContainer}
                errorInputStyle={styles.errorInput}
                errorMessageStyle={styles.errorMessage}
                forwardRef={(ref: FormInputRefType) => (passwordInput.current = ref)}
                inputStyle={styles.input}
                loginView
                name="password"
                onSubmitEditing={handleSubmit}
                placeholder={translate(translations.password)}
                placeholderTextColor={colorsNew.grey._500}
                required
                returnKeyType="send"
                secureTextEntry
                testID="password"
              />

              {isString(errors.errorMessage) ? (
                <Text style={StyleSheet.flatten([styles.errorInfo, global.isSM && styles.errorInfoSmall])}>
                  {errors.errorMessage}
                </Text>
              ) : null}
              <View
                style={StyleSheet.flatten([styles.passwordButtonSpace, global.isSM && styles.passwordButtonSpaceSmall])}
              >
                <ButtonNew
                  onPress={() => onResetPasswordPress(values.countryCode)}
                  variant={buttonVariants.textDefault}
                  title={translate(translations.forgotPassword)}
                  testID="resetPasswordButton"
                />
              </View>
            </View>

            {!isString(errors.errorMessage) ? (
              <View style={styles.loginButtonContainer}>
                <ButtonNew
                  disabled={!isValid || isSubmitting || !isConnected}
                  onPress={handleSubmit}
                  testID="loginButton"
                  title={translate(translations.login)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            ) : null}

            {isHeaderVisible ? (
              <Animated.View
                layout={CurvedTransition.delay(100)}
                style={StyleSheet.flatten([styles.linkContainer, global.isSM && styles.linkContainerSmall])}
                testID="applySection"
              >
                <Text style={styles.applyInfoText}>{translate(translations.noAccount)}</Text>
                <ButtonNew
                  onPress={() => openInterestForm(values.countryCode)}
                  RightIcon={ArrowRightIcon}
                  title={translate(translations.applySubstituteTeacher)}
                  variant={buttonVariants.textDefault}
                />
              </Animated.View>
            ) : null}
          </Animated.View>
        )}
      </Formik>
    </>
  )
}

export default LoginForm
