import React, { useCallback } from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { containerStyles, inputStyles } from './styles'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'

type TProps = {
  imagePickerCallback: () => void
  onClose: () => void
  onPermissionDenied?: () => void
  showModal: boolean
}

export const ProfilePictureModal = ({ onClose, showModal, imagePickerCallback }: TProps) => {
  const handleFileChange = useCallback(
    async event => {
      try {
        const response = event.target.files
        const file = response[0]
        const { name } = file
        imagePickerCallback({ uri: file, fileName: name })
        onClose()
      } catch (e) {}
    },
    [imagePickerCallback, onClose],
  )

  const closeModal = () => onClose && onClose()

  const avatarInput = (
    <div style={containerStyles}>
      {translate(translations.selectAvatar)}
      <input onChange={handleFileChange} style={inputStyles} type="file" />
    </div>
  )

  return (
    <CustomModal
      buttons={[
        {
          onPress: closeModal,
          title: translate(translations.cancel),
          variant: buttonVariants.outlinedDefault,
        },
      ]}
      children={avatarInput}
      colorSchema={customModalColorSchema.default}
      description={translate(translations.profileImageWarning)}
      isModalScreen={false}
      isVisible={showModal}
      onClose={onClose}
      modalPlace="Main/Profile/ProfilePictureUploader/ProfilePictureModal/ProfilePictureModal.web"
      title={translate(translations.selectAvatar)}
      variant={customModalVariants.filled}
    />
  )
}

export default ProfilePictureModal
