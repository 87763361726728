import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SnapTransparent = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 117 117" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M31.3564 86.9271C31.1703 86.6038 31.1703 86.2019 31.3622 85.8436C31.4611 85.6631 36.6927 82.465 36.8207 82.2175C37.3238 81.2505 38.0043 79.8787 38.4143 78.7602C39.1297 76.8146 40.9879 72.6788 42.3053 70.5905C43.6227 68.5021 43.1777 63.1342 43.2504 61.8644C43.3231 60.5945 44.0647 56.6858 49.0724 57.9411C52.6726 58.844 52.3294 65.374 52.2335 68.5487C52.2073 69.44 53.2019 69.938 54.0423 69.4546L77.0568 55.8237C79.2058 54.5887 82.3262 55.0431 83.693 57.4285C85.0598 59.8139 84.01 62.7702 81.8638 64.209C80.1975 65.3274 73.3257 69.3555 70.3362 71.1031C69.6208 71.5196 69.4464 72.4254 69.9785 72.9467C71.1941 74.1438 71.7612 75.8506 71.8135 77.5836L71.7874 78.4107C71.7554 79.369 71.9357 79.5612 72.6074 80.2457C73.2472 80.8952 73.6427 82.034 73.6514 82.6398C73.6776 84.5446 73 85.7242 73.4682 86.1931C75.6551 88.3834 73.698 91.0164 71.8397 92.0853L62.6415 97.5609C61.391 98.2804 59.2245 99.0959 57.8286 99.358C57.6977 99.3813 57.593 99.39 57.4738 99.3871C56.7584 99.358 52.8791 99.2328 49.6773 99.5939C48.6216 99.7133 47.8597 99.8357 47.313 99.9463C47.0949 99.99 41.7964 103.013 41.6393 103.054C41.1014 103.197 40.5692 102.99 40.322 102.556L31.3564 86.9271Z"
      fill="#63D3BF"
    />
    <Path
      d="M63.031 16.4714L64.1548 14.1935C64.4869 13.5182 65.4496 13.5182 65.7817 14.1935L66.9055 16.4714C67.0363 16.7402 67.2946 16.925 67.5898 16.9686L70.1024 17.3348C70.8471 17.4424 71.1457 18.3596 70.6056 18.8871L68.7874 20.661C68.5727 20.8693 68.4754 21.1717 68.5258 21.4673L68.9551 23.9703C69.0826 24.7128 68.3044 25.2806 67.6368 24.9279L65.3926 23.7452C65.1276 23.6075 64.8123 23.6075 64.5473 23.7452L62.3031 24.9279C61.6355 25.2773 60.8573 24.7128 60.9847 23.9703L61.4141 21.4673C61.4644 21.1717 61.3672 20.8727 61.1525 20.661L59.3343 18.8871C58.7942 18.3596 59.0928 17.4457 59.8375 17.3348L62.3501 16.9686C62.6419 16.925 62.8968 16.7368 63.031 16.4714Z"
      fill="#63D3BF"
    />
    <Path
      d="M64.9236 52.9413C64.5613 52.9413 64.2661 52.6456 64.2661 52.2828V29.2753C64.2661 28.9125 64.5613 28.6168 64.9236 28.6168C65.2859 28.6168 65.5811 28.9125 65.5811 29.2753V52.2828C65.5777 52.649 65.2859 52.9413 64.9236 52.9413Z"
      fill="#000000"
    />
    <Path
      d="M46.2784 25.0283L46.4327 22.4917C46.4797 21.7391 47.3653 21.3662 47.9322 21.86L49.851 23.5231C50.0758 23.718 50.3844 23.7919 50.6729 23.7146L53.1284 23.0763C53.8563 22.8881 54.487 23.6172 54.1918 24.3093L53.2022 26.651C53.0848 26.9265 53.1116 27.2423 53.2727 27.4943L54.638 29.6378C55.0439 30.2728 54.544 31.096 53.796 31.0321L51.2666 30.8137C50.9681 30.7869 50.6762 30.9112 50.4884 31.143L48.8748 33.1051C48.3951 33.6863 47.4592 33.4679 47.2881 32.7322L46.7145 30.256C46.6474 29.9637 46.4394 29.7252 46.161 29.6143L43.7994 28.6837C43.0983 28.4082 43.0178 27.4473 43.6619 27.0576L45.8356 25.7473C46.0973 25.5961 46.2583 25.3273 46.2784 25.0283Z"
      fill="#63D3BF"
    />
    <Path
      d="M58.6168 50.1424C58.3719 50.129 58.1471 49.9812 58.0465 49.7393L52.015 35.3764C51.8741 35.0404 52.0318 34.6574 52.3639 34.5163C52.6993 34.3752 53.0818 34.5331 53.2226 34.8657L59.2541 49.2286C59.395 49.5646 59.2374 49.9476 58.9053 50.0887C58.8113 50.1324 58.7141 50.1491 58.6168 50.1424Z"
      fill="#000000"
    />
    <Path
      d="M83.6551 25.0283L83.5007 22.4917C83.4538 21.7391 82.5682 21.3662 82.0013 21.86L80.0825 23.5231C79.8577 23.718 79.5491 23.7919 79.2606 23.7146L76.8051 23.0763C76.0772 22.8881 75.4465 23.6172 75.7417 24.3093L76.7313 26.651C76.8487 26.9265 76.8219 27.2423 76.6608 27.4943L75.2955 29.6378C74.8896 30.2728 75.3895 31.096 76.1375 31.0321L78.6669 30.8137C78.9654 30.7869 79.2573 30.9112 79.4451 31.143L81.0586 33.1051C81.5383 33.6863 82.4743 33.4679 82.6453 32.7322L83.219 30.256C83.2861 29.9637 83.494 29.7252 83.7725 29.6143L86.1341 28.6837C86.8352 28.4082 86.9157 27.4473 86.2716 27.0576L84.0979 25.7473C83.8362 25.5961 83.6718 25.3273 83.6551 25.0283Z"
      fill="#63D3BF"
    />
    <Path
      d="M71.3172 50.1424C71.562 50.129 71.7868 49.9811 71.8874 49.7392L77.9189 35.3764C78.0598 35.0404 77.9021 34.6574 77.57 34.5163C77.2345 34.3752 76.8521 34.5331 76.7112 34.8657L70.6798 49.2285C70.5389 49.5645 70.6966 49.9475 71.0287 50.0886C71.1226 50.1323 71.2199 50.1491 71.3172 50.1424Z"
      fill="#000000"
    />
  </Svg>
)

export default SnapTransparent
