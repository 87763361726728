import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const Trashcan = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 40 40" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M10 13.3334C10 12.4129 10.7462 11.6667 11.6667 11.6667H28.3333C29.2538 11.6667 30 12.4129 30 13.3334V33.3334C30 34.2539 29.2538 35.0001 28.3333 35.0001H11.6667C10.7462 35.0001 10 34.2539 10 33.3334V13.3334Z" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 15.0001V31.6667H26.6667V15.0001H13.3333ZM11.6667 11.6667C10.7462 11.6667 10 12.4129 10 13.3334V33.3334C10 34.2539 10.7462 35.0001 11.6667 35.0001H28.3333C29.2538 35.0001 30 34.2539 30 33.3334V13.3334C30 12.4129 29.2538 11.6667 28.3333 11.6667H11.6667Z"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1666 6.66667L15.3452 5.48815C15.6577 5.17559 16.0816 5 16.5237 5H23.4763C23.9183 5 24.3422 5.17559 24.6548 5.48816L25.8333 6.66667H30C30.9205 6.66667 31.6666 7.41286 31.6666 8.33333C31.6666 9.25381 30.9205 10 30 10H9.99998C9.07951 10 8.33331 9.25381 8.33331 8.33333C8.33331 7.41286 9.07951 6.66667 9.99998 6.66667H14.1666Z"
    />
  </Svg>
)

export default Trashcan
