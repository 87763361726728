import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const MessageIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 54 38" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M5.059 7.973c0-2.434 1.964-4.41 4.382-4.41h35.075c2.422 0 4.382 1.976 4.382 4.41v22.054c0 2.434-1.96 4.41-4.382 4.41H9.44c-2.418 0-4.382-1.976-4.382-4.41Zm4.382 2.515V27.82c0 1.22.985 2.207 2.196 2.207h30.687c1.211 0 2.192-.988 2.192-2.207V10.488L28.313 22.957a2.18 2.18 0 0 1-2.665 0Zm31.13-2.515H13.39L26.98 18.43Zm0 0" />
  </Svg>
)

export default MessageIcon
