import React, { useContext, useMemo, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import groupBy from 'lodash/groupBy'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, ErrorType, errorTypes, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { createWorkExperiences, deleteWorkExperiences, updateWorkExperiences } from './actions'
import { employmentHistoryTemplate, sectionsGB, sectionsSV } from './utils'
import { errorActions, errorRemoveActions, successActions, successRemoveActions } from '../../utils'
import { isSESelector } from 'src/store/app/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { UserExperience, getUserExperience } from 'src/store/user/selectors'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import CustomModalWrapper from 'src/components/Modal/CustomModal/CustomModalWrapper'
import Form from './Form/Form'
import FormInfoCard from 'src/components/FormInfoCard/FormInfoCard'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'EmploymentHistory'>

const EmploymentHistory = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const taskStatusCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - EmploymentHistory')

  const [editedExperienceValues, setEditedExperienceValues] = useState<UserExperience | null>()
  const [formError, setFormError] = useState<ErrorType | null>(null)
  const [isCompleteTaskError, setIsCompleteTaskError] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isTaskCompleted, setIsTaskCompleted] = useState(taskStatusCompleted)

  const workExperiences = useAppSelector(getUserExperience)
  const userId = useAppSelector(state => state.user.id) as string
  const isSE = useAppSelector(isSESelector)

  const { mixpanel } = useContext(MixpanelContext)

  const dispatch = useAppDispatch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sections = useMemo(() => (isSE ? sectionsSV : sectionsGB), [])

  const sectionInfo = useMemo(
    () => ({
      working_with_children_outside_school: {
        label: translate(translations.experienceOutsideSchoolLabel),
        buttonText: translate(translations.experienceOutsideSchoolButton),
      },
      substitute_teacher: {
        label: translate(translations.experienceSchoolLabel),
        buttonText: translate(translations.experienceSchoolButton),
      },
      other_professions: {
        label: translate(translations.experienceOtherLabel),
        buttonText: translate(translations.experienceOtherButton),
      },
    }),
    [],
  )

  const employmentData = useMemo(
    () => groupBy(workExperiences, experience => experience?.expCategory) || [],
    [workExperiences],
  )

  const openExperienceForm = (experience: UserExperience | null, section: UserExperience['expCategory']) => {
    const initialValues = experience || { ...employmentHistoryTemplate, expCategory: section }

    setIsFormOpen(true)
    setEditedExperienceValues(initialValues)
  }

  const closeFormWithoutSaving = () => {
    setIsFormOpen(false)
    setEditedExperienceValues(null)
  }

  const closeFormError = () => setFormError(null)

  const saveUpdatedExperience = (updatedExperience: UserExperience, editedExperienceId?: string) => {
    const isEdit = !!editedExperienceId
    const data = isEdit ? updatedExperience : { ...updatedExperience, userId }

    bugsnagActionBreadcrumb('submit experience form', { id: userId, user: data })
    setIsSubmitting(true)
    setFormError(null)

    isEdit
      ? updateWorkExperiences(
          editedExperienceId,
          data,
          () => successActions(userId, dispatch, setIsSubmitting, closeFormWithoutSaving),
          () => errorActions(errorTypes.save, setFormError, setIsSubmitting),
        )
      : createWorkExperiences(
          data,
          () => successActions(userId, dispatch, setIsSubmitting, closeFormWithoutSaving),
          () => errorActions(errorTypes.save, setFormError, setIsSubmitting),
        )
  }

  const removeExperience = (deletedExperienceId?: UserExperience['id']) => {
    if (!deletedExperienceId) return closeFormWithoutSaving()
    setIsDeleting(true)
    setFormError(null)
    deleteWorkExperiences(
      deletedExperienceId,
      () => successRemoveActions(userId, dispatch, setIsDeleting, closeFormWithoutSaving),
      () => errorRemoveActions(errorTypes.delete, setFormError, setIsDeleting),
    )
  }

  const completeTask = () => {
    setIsCompleteTaskError(false)
    markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack, () => setIsCompleteTaskError(true))
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      {isSE ? <Text style={styles.taskHeader}>{translate(translations.employmentHistoryTitle)}</Text> : null}
      {sections.map(
        (section, index) =>
          section && (
            <View key={`section-${index}`} style={styles.sectionContainer}>
              <Text style={isSE ? styles.sectionTitleSv : styles.sectionTitleGB}>{sectionInfo[section].label}</Text>

              {employmentData[section]?.map(experience => (
                <FormInfoCard
                  key={experience.id}
                  onPress={() => openExperienceForm(experience, section)}
                  subtitle={experience.employer || ''}
                  title={experience.role || ''}
                />
              ))}

              <View style={styles.buttonContainer}>
                <ButtonNew
                  disabled={taskStatusCompleted}
                  onPress={() => openExperienceForm(null, section)}
                  size={buttonSize.sm}
                  testID="open-employment-form"
                  title={sectionInfo[section].buttonText}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </View>
          ),
      )}
      <Checkbox
        disabled={taskStatusCompleted || workExperiences.length === 0}
        label={translate(translations.taskConfirmationExperience)}
        onPress={() => setIsTaskCompleted(prev => !prev)}
        selected={isTaskCompleted}
      />
      {isCompleteTaskError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsCompleteTaskError(false)} />
        </View>
      ) : null}
      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={taskStatusCompleted || !isTaskCompleted}
          onPress={completeTask}
          size={buttonSize.lg}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.infoText}>{translate(translations.taskInfoDeclaraction)}</Text>
      <CustomModalWrapper
        isVisible={isFormOpen}
        modalPlace="Main/Profile/RequiredTasks/EmploymentHistory/EmploymentHistory"
      >
        {editedExperienceValues ? (
          <Form
            closeFormError={closeFormError}
            closeFormWithoutSaving={closeFormWithoutSaving}
            deleteExperience={removeExperience}
            experience={editedExperienceValues}
            formError={formError}
            isDeleting={isDeleting}
            isDisabled={taskStatusCompleted}
            isSubmitting={isSubmitting}
            updateExperienceList={saveUpdatedExperience}
          />
        ) : (
          <></>
        )}
      </CustomModalWrapper>
    </ScrollView>
  )
}

export default EmploymentHistory
