import gql from 'graphql-tag'

import { BookingsSummaryRequestTypes, BookingSummaryTypes } from './types'

export type { BookingsSummaryRequestTypes, BookingSummaryTypes }

export default gql`
  query Index(
    $bookingType: String
    $limit: Int
    $maxDate: String
    $minDate: String
    $offset: Int
    $sort: String
    $skipCancelled: Boolean
  ) {
    bookings(
      limit: $limit
      offset: $offset
      sort: $sort
      filter: { bookingType: $bookingType, maxDate: $maxDate, minDate: $minDate, skipCancelled: $skipCancelled }
    ) {
      totalCount
      nodes {
        _durationMinutes
        endTime
        id
        startTime
        school {
          id
          name
        }
      }
    }
  }
`
