import { ProfileTaskStatus } from 'src/scenes/Main/Profile/types'
import { colorsNew } from 'src/styles'
import translations, { translate } from 'src/utils/translations/translations'

export const getTaskStatusInfo = (status: ProfileTaskStatus, timeToComplete: number | string) => {
  switch (status) {
    case 'completed':
    case 'expiresSoon':
      return {
        badgeColor: colorsNew.redesign.success.shades12p,
        description: translate(translations.profileTaskStatusCompletedDescription),
        textColor: colorsNew.redesign.success.text,
        title: translate(translations.profileTaskStatusCompleted),
      }
    case 'expired':
      return {
        badgeColor: colorsNew.redesign.warning.shades12p,
        description: translate(translations.profileTaskStatusExpiredDescription),
        textColor: colorsNew.redesign.warning.text,
        title: translate(translations.profileTaskStatusExpiredShort),
      }
    case 'enabled':
    default:
      return {
        badgeColor: colorsNew.redesign.info.shades12p,
        description: translate(translations.profileTaskStatusToDoDescription, { timeToComplete }),
        textColor: colorsNew.redesign.info.text,
        title: translate(translations.profileTaskStatusToDo),
      }
  }
}
