import { View, Text, StyleSheet } from 'react-native'
import React from 'react'

import ShadowWrapper from 'src/components/ShadowWrapper'
import translations, { translate } from 'src/utils/translations/translations'
import { LocationOnIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  hours?: string | null
  isActive?: boolean
  location: string
  onPress: () => void
  salary?: string | null
  title: string
}

const LongTermOfferItem = ({ hours, location, salary, title, onPress, isActive }: Props) => (
  <ShadowWrapper borderRadius={8} onPress={onPress} withBaseShadow={!isActive} withVerticalSpacing>
    <View testID="longTermOfferCard" style={StyleSheet.flatten([styles.container, isActive && styles.containerActive])}>
      <View>
        <Text style={styles.timeText} numberOfLines={1}>
          {translate(translations.hoursWithValue, { hours: hours || translate(translations.toBeDecided) })}
        </Text>
        <Text style={styles.title} numberOfLines={2}>
          {title}
        </Text>
      </View>
      <View style={styles.bottomRow}>
        <Text style={styles.salaryText} numberOfLines={1}>
          {translate(translations.currency)} {salary || translate(translations.negotiable)}
        </Text>
        <View style={styles.locationRow}>
          <LocationOnIcon size={18} fill={colorsNew.redesign.text.secondary} />
          <Text style={styles.locationText}>{location}</Text>
        </View>
      </View>
    </View>
  </ShadowWrapper>
)

export default LongTermOfferItem
