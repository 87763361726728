import React, { useEffect } from 'react'
import { StatusBar, View } from 'react-native'
// @ts-ignore
import HttpsRedirect from 'react-https-redirect'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer, DefaultTheme } from '@react-navigation/native'
import { PersistGate } from 'redux-persist/integration/react'
import { getCountryCode } from 'src/store/app/selectors'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

import Snackbar from 'src/components/Snackbar'
import { useBugsnagView } from 'src/utils/bugsnag'
import Loading from 'src/utils/Loading'
import ModalScreen from 'src/scenes/ModalScreen'
import Routes from 'src/Routes'
import { persistor } from 'src/store'
import { colors } from 'src/styles'
import { MixpanelProvider } from 'src/context/MixpanelContext'
import { navigationRef } from 'src/utils/navigationService'
import { setLocale, getDeviceLocale } from 'src/utils/translations/i18n'
import { setCountryCode } from './store/app/actions'
import { RootStackParamList } from './utils/types/navigationTypes'
import { DisplayModalProvider } from 'src/context/DispalyModalContext'
import styles from './style'
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks'
import NetInfoListener from 'src/utils/NetInfo'
import { FeatureFlagsProvider } from './context/featureFlags'

const Stack = createStackNavigator<RootStackParamList>()

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: colors.white,
  },
}

const AppNavigationContainer = () => (
  // @ts-ignore
  <NavigationContainer theme={MyTheme} ref={navigationRef}>
    <NetInfoListener />
    <DisplayModalProvider>
      <Stack.Navigator>
        <Stack.Screen name="Main" options={{ headerShown: false }} component={Routes} />
        <Stack.Screen
          name="Modal"
          options={{
            headerShown: false,
          }}
          component={ModalScreen}
        />
      </Stack.Navigator>
    </DisplayModalProvider>
  </NavigationContainer>
)

const App = () => {
  const isLoading = useAppSelector(state => state.app.isLoading)
  const appPersisted = useAppSelector(state => state.app?._persist?.rehydrated) || true
  const userPersisted = useAppSelector(state => state.user?._persist?.rehydrated) || true
  const countryCode = useSelector(getCountryCode)

  const dispatch = useAppDispatch()

  useBugsnagView('app index')

  useEffect(() => {
    const defaultCountryCode = getDeviceLocale()

    if (!countryCode) {
      dispatch(setCountryCode(defaultCountryCode))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (countryCode) {
      setLocale(countryCode)
    }
  }, [appPersisted, countryCode])

  if (!appPersisted || !userPersisted) {
    return <Loading />
  }

  return (
    <HttpsRedirect>
      <GestureHandlerRootView style={styles.wrapper}>
        <MixpanelProvider>
          <PersistGate loading={null} persistor={persistor}>
            <FeatureFlagsProvider>
              <View style={styles.viewWrapper}>
                <StatusBar backgroundColor={colors.primary} barStyle="dark-content" />
                <AppNavigationContainer />
                <Snackbar />
                {isLoading ? <Loading /> : null}
              </View>
            </FeatureFlagsProvider>
          </PersistGate>
        </MixpanelProvider>
      </GestureHandlerRootView>
    </HttpsRedirect>
  )
}

export default App
