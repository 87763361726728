import { StyleProp } from 'react-native'

import { fonts } from './fonts'

type FontKeysT = keyof typeof fonts
type FontValuesT = typeof fonts[FontKeysT]

const fontConfig = (
  fontFamily: FontValuesT,
  fontSize: number,
  lineHeight: number,
  letterSpacing = 1,
  uppercase = false,
): StyleProp<any> => {
  const config = {
    fontFamily,
    fontSize,
    letterSpacing,
  } as { fontFamily: FontValuesT; fontSize: number; letterSpacing: number; textTransform?: string; lineHeight?: number }

  if (uppercase) {
    config.textTransform = 'uppercase'
  }
  if (lineHeight) {
    config.lineHeight = lineHeight
  }
  return config as StyleProp<any>
}

export const typography = {
  h1: fontConfig(fonts.poppinsMedium, 96, 112, -1.5),
  h2: fontConfig(fonts.poppinsMedium, 60, 72, -0.5),
  h3: fontConfig(fonts.poppinsMedium, 48, 56),
  h4: fontConfig(fonts.poppinsBold, 34, 42, 0.25),
  h5: fontConfig(fonts.poppinsMedium, 24, 32),
  h6: fontConfig(fonts.poppinsMedium, 20, 32, 0.15),
  h7: fontConfig(fonts.poppinsMedium, 16, 25.6, 0.15),
  subtitle1: fontConfig(fonts.muktaRegular, 16, 28),
  subtitle2: fontConfig(fonts.muktaSemiBold, 14, 22, 0.1),
  body1: fontConfig(fonts.muktaRegular, 16, 24, 0.15),
  body2: fontConfig(fonts.muktaRegular, 14, 20, 0.17),
  caption: fontConfig(fonts.muktaRegular, 12, 20, 0.4),
  overline: fontConfig(fonts.poppinsRegular, 12, 32, 1, true),
  buttonLarge: fontConfig(fonts.muktaSemiBold, 15, 25, 0.46),
  buttonSmall: fontConfig(fonts.muktaSemiBold, 13, 22, 0.46),
  chipTextLarge: fontConfig(fonts.muktaRegular, 15, 20, 0.15),
  chipTextSmall: fontConfig(fonts.muktaRegular, 13, 20, 0.15),
  avatarInitials: fontConfig(fonts.muktaRegular, 20, 20, 0.14, true),
  inputLabel: fontConfig(fonts.muktaRegular, 12, 12, 0.15),
  helperText: fontConfig(fonts.muktaRegular, 12, 20, 0.4),
  inputText: fontConfig(fonts.muktaRegular, 16, 24, 0.15),
  tooltip: fontConfig(fonts.muktaMedium, 10, 14),
}
