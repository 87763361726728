import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  list: {
    backgroundColor: colorsNew.white,
    flex: 1,
  },
  container: {
    paddingBottom: paddingNew.xl,
  },
  emptyContainer: {
    marginTop: paddingNew.lg,
  },
})
