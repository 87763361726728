import moment from 'moment'

import { AllJobItems } from 'src/components/List/JobsSectionList'
import { BookingListItemType, BookingTypes } from 'src/graphql/Booking/types'
import { Competence } from 'src/graphql/sharableTypes'
import { ExpressBookingsTypes } from 'src/graphql/ExpressBookings'
import { OrderListItemType } from 'src/graphql/Order/types'
import { parseWithTimezone } from 'src/global/utils'
import { timeSheetFields } from 'src/scenes/Main/Jobs/Rows/utils'
import formatter from 'src/utils/formatter'

export const sortingOfferCriteria = (item: AllJobItems) => {
  switch (item.__typename) {
    case 'ExpressBooking':
      return parseWithTimezone(`${item.date} 06:15`).toISOString()
    case 'Booking':
      return item.startTime
    case 'Order':
      return item.bookings[0]?.startTime || item.startDate
  }
}

const getSubtitle = (
  item: BookingListItemType | OrderListItemType | BookingTypes,
  isGB: boolean,
  educatorCompetences?: Competence[] | [],
) =>
  isGB
    ? educatorCompetences?.find(educatorCompetence =>
        item.competences.some(jobCompetence => jobCompetence.ref === educatorCompetence.ref),
      )?.name || item.competences[0]?.name
    : item.topics.map(topic => topic.title).join(', ')

const getExpressRowData = (item: ExpressBookingsTypes, isGB: boolean, isTimesheet?: boolean) => ({
  startDate: item.date,
  subtitle: item.expressBookingPool?.levels?.map(level => level.title).join(', '),
  title: item.expressBookingPool.title,
  ...timeSheetFields({ item, isTimesheet }),
})

const getBookingRowData = (
  item: BookingListItemType | BookingTypes,
  isGB: boolean,
  isTimesheet?: boolean,
  educatorCompetences?: Competence[] | [],
) => ({
  startDate: moment(item.startTime).format('YYYY-MM-DD'),
  subtitle: getSubtitle(item, isGB, educatorCompetences),
  title: item.school.name,
  ...timeSheetFields({ item, isTimesheet }),
})

const getOrderRowData = (item: OrderListItemType, isGB: boolean, educatorCompetences?: Competence[] | []) => {
  const duration = formatter.convertMinutesToHoursAndMinutes(
    item.bookings.reduce((acc, curr) => (acc = acc + curr._durationMinutes), 0),
  )

  return {
    numberOfDays: item.bookings.length,
    startDate: item.startDate,
    subtitle: getSubtitle(item, isGB, educatorCompetences),
    title: item.school.name,
    workingTime: `${duration}`,
  }
}

export const jobOfferItemData = (
  item: AllJobItems | BookingTypes | ExpressBookingsTypes,
  isGB: boolean,
  isTimesheet?: boolean,
  educatorCompetences?: Competence[] | [],
): // @ts-ignore
| {
      subtitle: string
      isTimesheet: boolean
      workingTime: string
      title: string
      startDate: string
      isReported: boolean
    }
  | {
      numberOfDays: number
      startDate: string
      subtitle: string
      title: string
      workingTime: string
    } => {
  switch (item.__typename) {
    case 'ExpressBooking':
      return getExpressRowData(item, isGB, isTimesheet)
    case 'Booking':
      return getBookingRowData(item, isGB, isTimesheet, educatorCompetences)
    case 'Order':
      return getOrderRowData(item, isGB, educatorCompetences)
    case 'Proposal':
      return undefined as never
  }
}
