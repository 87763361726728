import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { LockIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { ProfileTaskType } from 'src/scenes/Main/Profile/types'
import translations, { translate } from 'src/utils/translations/translations'
import styles from '../styles'

type Props = {
  lockInfo: string
  task: ProfileTaskType
}

const LockedTaskBody = ({ lockInfo, task }: Props) => (
  <View style={styles.lockContainer}>
    <Text style={StyleSheet.flatten([styles.title, styles.textDisabled])}>
      {translate(translations[`taskTitle_${task}`])}
      <View style={styles.lockIcon}>
        <LockIcon size={16} fill={colorsNew.redesign.action.disabled} />
      </View>
    </Text>
    <Text style={StyleSheet.flatten([styles.lockedDescription])}>{lockInfo}</Text>
  </View>
)
export default LockedTaskBody
