import React from 'react'
import Svg, { Ellipse, Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const TimeUnEditableIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 200 201" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M100 163.805c35.198 0 63.732-28.534 63.732-63.732S135.198 36.342 100 36.342s-63.731 28.533-63.731 63.731S64.802 163.805 100 163.805Z"
      fill="#B9B9BB"
    />
    <Path
      d="M102.681 154.446c30.844 0 55.848-25.004 55.848-55.848 0-30.844-25.004-55.848-55.848-55.848-30.844 0-55.848 25.004-55.848 55.848 0 30.844 25.004 55.848 55.848 55.848Z"
      fill="#EEEEEF"
    />
    <Path
      d="m96.793 51.908-.224.03a.411.411 0 0 1-.462-.358l-.601-4.687a.411.411 0 0 1 .357-.462l.224-.03c.228-.03.432.13.462.358l.602 4.688a.418.418 0 0 1-.358.461ZM109.34 149.501l-.224.03a.41.41 0 0 1-.461-.357l-.602-4.688a.411.411 0 0 1 .358-.461l.224-.03a.41.41 0 0 1 .461.358l.602 4.687a.416.416 0 0 1-.358.461ZM56.542 103.774l.03.224a.41.41 0 0 1-.358.461l-4.687.602a.41.41 0 0 1-.462-.358l-.03-.224a.41.41 0 0 1 .358-.461l4.688-.602a.416.416 0 0 1 .46.358ZM154.135 91.226l.03.224a.41.41 0 0 1-.357.462l-4.688.601a.41.41 0 0 1-.461-.357l-.03-.224a.41.41 0 0 1 .357-.462l4.688-.602a.415.415 0 0 1 .461.358ZM74.138 134.643l.177.138a.41.41 0 0 1 .074.578L71.5 139.1a.411.411 0 0 1-.578.074l-.177-.137a.412.412 0 0 1-.074-.579l2.889-3.741a.41.41 0 0 1 .578-.074ZM134.276 56.76l.177.136a.41.41 0 0 1 .073.579l-2.888 3.741a.412.412 0 0 1-.579.074l-.177-.137a.41.41 0 0 1-.073-.579l2.888-3.741a.411.411 0 0 1 .579-.074ZM139.274 126.429l.137-.177a.41.41 0 0 1 .578-.074l3.742 2.889a.41.41 0 0 1 .073.578l-.137.177a.41.41 0 0 1-.578.074l-3.742-2.889a.417.417 0 0 1-.073-.578ZM61.393 66.291l.137-.177a.411.411 0 0 1 .579-.074l3.741 2.89c.18.14.214.397.074.578l-.138.177a.41.41 0 0 1-.578.073l-3.741-2.888a.411.411 0 0 1-.074-.579ZM110.01 99.116A5.059 5.059 0 1 0 110.009 89a5.059 5.059 0 0 0 .001 10.117Z"
      fill="#B9B9BB"
    />
    <Path d="m150.471 113.784-54.684-28.98-1.337 2.59 55.283 27.814c1.18.558 1.875-.786.738-1.424Z" fill="#B9B9BB" />
    <Ellipse cx={153.448} cy={140.045} rx={31.035} ry={31.897} fill="#224D3D" />
    <Path
      d="M167.816 126.538a5.4 5.4 0 0 0-7.634 0l-18.031 18.033a4.69 4.69 0 0 0-1.245 2.228l-1.652 6.94a1.135 1.135 0 0 0 .794 1.351c.186.052.382.056.57.011l6.941-1.652a4.687 4.687 0 0 0 2.229-1.246l1.736-1.735a9.803 9.803 0 0 1 6.829-11.542 9.808 9.808 0 0 1 4.869-.159l4.594-4.593a5.4 5.4 0 0 0 0-7.634v-.002Zm1.581 21.805a8.3 8.3 0 0 0-14.165-5.868 8.299 8.299 0 1 0 14.165 5.868Zm-14.332 0a6.033 6.033 0 0 1 9.426-4.992l-8.384 8.383a6.007 6.007 0 0 1-1.042-3.391Zm6.034 6.034a6.007 6.007 0 0 1-3.391-1.042l8.383-8.384a6.038 6.038 0 0 1-1.879 8.562 6.043 6.043 0 0 1-3.113.864Z"
      fill="#F7FCFB"
    />
  </Svg>
)

export default TimeUnEditableIcon
