import React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const EmailIcon = ({ size, ...props }: IconTypes) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 116 117" xmlns="http://www.w3.org/2000/svg" {...props}>
    <G clipPath="url(#a)">
      <Path
        d="M57.636 113.715c31.12 0 56.348-25.267 56.348-56.435 0-31.168-25.228-56.435-56.348-56.435-31.12 0-56.348 25.267-56.348 56.435 0 31.168 25.228 56.435 56.348 56.435Z"
        fill="#C3DDD5"
      />
      <Path
        d="M30.039 78.823V52.785a5.518 5.518 0 0 1 3.44-5.112l22.218-9.026a5.518 5.518 0 0 1 4.173.007l21.942 9.012a5.518 5.518 0 0 1 3.421 5.104v26.053a5.518 5.518 0 0 1-5.518 5.517H35.556a5.518 5.518 0 0 1-5.517-5.517Z"
        fill="#224D3D"
      />
      <Path
        d="M34.387 51.801v2.859c0 .302.173.578.445.71l21.483 10.39a3.941 3.941 0 0 0 3.43 0l20.352-9.835a.788.788 0 0 0 .445-.71v-3.417a.788.788 0 0 0-.506-.736l-21-8.047a3.94 3.94 0 0 0-2.803-.007l-21.336 8.056a.788.788 0 0 0-.51.737Z"
        fill="#F7FCFB"
      />
      <Path
        d="M37.74 34.565v22.204l18.68 9.143c.471.238 2.177.445 3.295-.133l17.818-8.553V34.565a3.941 3.941 0 0 0-3.941-3.941H41.68a3.941 3.941 0 0 0-3.941 3.94Z"
        fill="#00B58B"
      />
      <Path
        d="M41.418 35.692V58.55l15.002 7.363c.906.458 2.36.35 3.261-.117l14.173-6.823v-23.28c0-.87-.706-1.576-1.576-1.576H42.995c-.871 0-1.577.706-1.577 1.576Z"
        fill="#F7FCFB"
      />
      <Path
        d="M44.809 51.23c0-1.3 1.054-2.354 2.353-2.354h18.83a2.354 2.354 0 0 1 0 4.707h-18.83a2.354 2.354 0 0 1-2.353-2.353ZM44.809 41.793c0-1.3 1.054-2.353 2.353-2.353h9.784a2.354 2.354 0 0 1 0 4.707h-9.784a2.354 2.354 0 0 1-2.353-2.354Z"
        fill="#224D3D"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(.136 .845)" d="M0 0h115v115.178H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default EmailIcon
