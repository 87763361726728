import { colorsNew, fonts } from 'src/styles'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    height: 72,
    width: 72,
  },
  body: {
    alignItems: 'center',
    height: 72,
    position: 'absolute',
    width: 72,
  },
  bodySingleDay: {
    top: 24,
  },
  bodyMultiDay: {
    top: 20.4,
    left: -3,
  },
  day: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsSemiBold,
    fontSize: 24,
    lineHeight: 26,
    position: 'absolute',
    textAlign: 'center',
    top: 0,
  },
  month: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsMedium,
    fontSize: 16,
    lineHeight: 16,
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'capitalize',
    top: 22,
  },
})
export default styles
