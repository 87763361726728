import { Platform, StyleSheet } from 'react-native'

import { colorsNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    flex: 1,
  },
  mask: {
    alignItems: 'center',
    backgroundColor: colorsNew.translucentWhite,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    ...Platform.select({
      web: {
        top: 0,
        left: 0,
      },
    }),
  },
})
