import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const UploadIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg height={size} width={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M10 16.0007H14C14.55 16.0007 15 15.5507 15 15.0007V10.0007H16.59C17.48 10.0007 17.93 8.9207 17.3 8.2907L12.71 3.7007C12.32 3.3107 11.69 3.3107 11.3 3.7007L6.71 8.2907C6.08 8.9207 6.52 10.0007 7.41 10.0007H9V15.0007C9 15.5507 9.45 16.0007 10 16.0007ZM6 18.0007H18C18.55 18.0007 19 18.4507 19 19.0007C19 19.5507 18.55 20.0007 18 20.0007H6C5.45 20.0007 5 19.5507 5 19.0007C5 18.4507 5.45 18.0007 6 18.0007Z"
      fill={fill}
    />
  </Svg>
)

export default UploadIcon
