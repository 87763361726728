import moment from 'moment'

export const getYearsOptions = (numberOfYearsInPast: number, numberOfYearsInFuture: number) => {
  const endDate = new Date().getFullYear() + numberOfYearsInFuture
  const numberOfYears = numberOfYearsInPast + numberOfYearsInFuture

  return Array.from({ length: numberOfYears }, (_, i) => endDate - i).map(value => ({
    label: value.toString(),
    value: value.toString(),
  }))
}

export const getMonthOptions = (numberOfMonths: number) => {
  return Array.from({ length: numberOfMonths }, (_, i) => i + 1).map(value => ({
    label: moment(value, 'M').format('MMMM'),
    value: value < 10 ? `0${value}` : value.toString(),
  }))
}
