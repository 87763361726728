import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colorsNew.redesign.primary.shades50p,
    borderRadius: paddingNew.xs,
    flex: 1,
    justifyContent: 'center',
    padding: paddingNew.xs,
  },
  subTitle: {
    ...typography.subtitle1,
  },
  title: {
    ...typography.h5,
  },
})
