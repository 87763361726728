import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const EventAvailableIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M13 9.53a.754.754 0 00-1.06 0l-4.35 4.35L6 12.29a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06l1.94 1.94c.39.39 1.02.39 1.41 0l4.7-4.7c.3-.29.3-.77.01-1.06zM16 2h-1V1c0-.55-.45-1-1-1s-1 .45-1 1v1H5V1c0-.55-.45-1-1-1S3 .45 3 1v1H2C.89 2 .01 2.9.01 4L0 18a2 2 0 002 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 16H3c-.55 0-1-.45-1-1V7h14v10c0 .55-.45 1-1 1z"
      fill={fill}
    />
  </Svg>
)

export default EventAvailableIcon
