import { StyleSheet } from 'react-native'

import { padding } from './spacing'
import colors from './colors'
import common from './common'

export const details = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  image: {
    position: 'absolute',
    top: 1,
    width: '100%',
  },
  infoContainer: {
    borderRadius: 10,
  },
  longText: StyleSheet.flatten([{}, common.longText]),
})

export const actions = StyleSheet.create({
  buttonsAbsoluteContainer: {
    alignSelf: 'center',
    zIndex: 5,
    paddingVertical: padding.md,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  buttonLeft: {
    marginRight: padding.sm,
  },
  buttonRight: {
    marginLeft: padding.sm,
  },
})
