const nationalities = [
  {
    value: 'af',
    label: 'Afghanistan (افغانستان)',
  },
  {
    value: 'al',
    label: 'Albania (Shqipëri)',
  },
  {
    value: 'dz',
    label: 'Algeria (الجزائر)',
  },
  {
    value: 'as',
    label: 'American Samoa',
  },
  {
    value: 'ad',
    label: 'Andorra',
  },
  {
    value: 'ao',
    label: 'Angola',
  },
  {
    value: 'ai',
    label: 'Anguilla',
  },
  {
    value: 'ag',
    label: 'Antigua and Barbuda',
  },
  {
    value: 'ar',
    label: 'Argentina',
  },
  {
    value: 'am',
    label: 'Armenia (Հայաստան)',
  },
  {
    value: 'aw',
    label: 'Aruba',
  },
  {
    value: 'au',
    label: 'Australia',
  },
  {
    value: 'at',
    label: 'Austria (Österreich)',
  },
  {
    value: 'az',
    label: 'Azerbaijan (Azərbaycan)',
  },
  {
    value: 'bs',
    label: 'Bahamas',
  },
  {
    value: 'bh',
    label: 'Bahrain (البحرين)',
  },
  {
    value: 'bd',
    label: 'Bangladesh (বাংলাদেশ)',
  },
  {
    value: 'bb',
    label: 'Barbados',
  },
  {
    value: 'by',
    label: 'Belarus (Беларусь)',
  },
  {
    value: 'be',
    label: 'Belgium (België)',
  },
  {
    value: 'bz',
    label: 'Belize',
  },
  {
    value: 'bj',
    label: 'Benin (Bénin)',
  },
  {
    value: 'bm',
    label: 'Bermuda',
  },
  {
    value: 'bt',
    label: 'Bhutan (འབྲུག)',
  },
  {
    value: 'bo',
    label: 'Bolivia',
  },
  {
    value: 'ba',
    label: 'Bosnia and Herzegovina (Босна и Херцеговина)',
  },
  {
    value: 'bw',
    label: 'Botswana',
  },
  {
    value: 'br',
    label: 'Brazil (Brasil)',
  },
  {
    value: 'io',
    label: 'British Indian Ocean Territory',
  },
  {
    value: 'vg',
    label: 'British Virgin Islands',
  },
  {
    value: 'bn',
    label: 'Brunei',
  },
  {
    value: 'bg',
    label: 'Bulgaria (България)',
  },
  {
    value: 'bf',
    label: 'Burkina Faso',
  },
  {
    value: 'bi',
    label: 'Burundi (Uburundi)',
  },
  {
    value: 'kh',
    label: 'Cambodia (កម្ពុជា)',
  },
  {
    value: 'cm',
    label: 'Cameroon (Cameroun)',
  },
  {
    value: 'ca',
    label: 'Canada',
  },
  {
    value: 'cv',
    label: 'Cape Verde (Kabu Verdi)',
  },
  {
    value: 'bq',
    label: 'Caribbean Netherlands',
  },
  {
    value: 'ky',
    label: 'Cayman Islands',
  },
  {
    value: 'cf',
    label: 'Central African Republic (République centrafricaine)',
  },
  {
    value: 'td',
    label: 'Chad (Tchad)',
  },
  {
    value: 'cl',
    label: 'Chile',
  },
  {
    value: 'cn',
    label: 'China (中国)',
  },
  {
    value: 'cx',
    label: 'Christmas Island',
  },
  {
    value: 'cc',
    label: 'Cocos (Keeling) Islands',
  },
  {
    value: 'co',
    label: 'Colombia',
  },
  {
    value: 'km',
    label: 'Comoros (جزر القمر)',
  },
  {
    value: 'cd',
    label: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
  },
  {
    value: 'cg',
    label: 'Congo (Republic) (Congo-Brazzaville)',
  },
  {
    value: 'ck',
    label: 'Cook Islands',
  },
  {
    value: 'cr',
    label: 'Costa Rica',
  },
  {
    value: 'hr',
    label: 'Croatia (Hrvatska)',
  },
  {
    value: 'cu',
    label: 'Cuba',
  },
  {
    value: 'cw',
    label: 'Curaçao',
  },
  {
    value: 'cy',
    label: 'Cyprus (Κύπρος)',
  },
  {
    value: 'cz',
    label: 'Czech Republic (Česká republika)',
  },
  {
    value: 'ci',
    label: 'Côte d’Ivoire',
  },
  {
    value: 'dk',
    label: 'Denmark (Danmark)',
  },
  {
    value: 'dj',
    label: 'Djibouti',
  },
  {
    value: 'dm',
    label: 'Dominica',
  },
  {
    value: 'do',
    label: 'Dominican Republic (República Dominicana)',
  },
  {
    value: 'ec',
    label: 'Ecuador',
  },
  {
    value: 'eg',
    label: 'Egypt (مصر)',
  },
  {
    value: 'sv',
    label: 'El Salvador',
  },
  {
    value: 'gq',
    label: 'Equatorial Guinea (Guinea Ecuatorial)',
  },
  {
    value: 'er',
    label: 'Eritrea',
  },
  {
    value: 'ee',
    label: 'Estonia (Eesti)',
  },
  {
    value: 'et',
    label: 'Ethiopia',
  },
  {
    value: 'fk',
    label: 'Falkland Islands (Islas Malvinas)',
  },
  {
    value: 'fo',
    label: 'Faroe Islands (Føroyar)',
  },
  {
    value: 'fj',
    label: 'Fiji',
  },
  {
    value: 'fi',
    label: 'Finland (Suomi)',
  },
  {
    value: 'fr',
    label: 'France',
  },
  {
    value: 'gf',
    label: 'French Guiana (Guyane française)',
  },
  {
    value: 'pf',
    label: 'French Polynesia (Polynésie française)',
  },
  {
    value: 'ga',
    label: 'Gabon',
  },
  {
    value: 'gm',
    label: 'Gambia',
  },
  {
    value: 'ge',
    label: 'Georgia (საქართველო)',
  },
  {
    value: 'de',
    label: 'Germany (Deutschland)',
  },
  {
    value: 'gh',
    label: 'Ghana (Gaana)',
  },
  {
    value: 'gi',
    label: 'Gibraltar',
  },
  {
    value: 'gr',
    label: 'Greece (Ελλάδα)',
  },
  {
    value: 'gl',
    label: 'Greenland (Kalaallit Nunaat)',
  },
  {
    value: 'gd',
    label: 'Grenada',
  },
  {
    value: 'gp',
    label: 'Guadeloupe',
  },
  {
    value: 'gu',
    label: 'Guam',
  },
  {
    value: 'gt',
    label: 'Guatemala',
  },
  {
    value: 'gg',
    label: 'Guernsey',
  },
  {
    value: 'gn',
    label: 'Guinea (Guinée)',
  },
  {
    value: 'gw',
    label: 'Guinea-Bissau (Guiné Bissau)',
  },
  {
    value: 'gy',
    label: 'Guyana',
  },
  {
    value: 'ht',
    label: 'Haiti',
  },
  {
    value: 'hn',
    label: 'Honduras',
  },
  {
    value: 'hk',
    label: 'Hong Kong (香港)',
  },
  {
    value: 'hu',
    label: 'Hungary (Magyarország)',
  },
  {
    value: 'is',
    label: 'Iceland (Ísland)',
  },
  {
    value: 'in',
    label: 'India (भारत)',
  },
  {
    value: 'id',
    label: 'Indonesia',
  },
  {
    value: 'ir',
    label: 'Iran (ایران)',
  },
  {
    value: 'iq',
    label: 'Iraq (العراق)',
  },
  {
    value: 'ie',
    label: 'Ireland',
  },
  {
    value: 'im',
    label: 'Isle of Man',
  },
  {
    value: 'il',
    label: 'Israel (ישראל)',
  },
  {
    value: 'it',
    label: 'Italy (Italia)',
  },
  {
    value: 'jm',
    label: 'Jamaica',
  },
  {
    value: 'jp',
    label: 'Japan (日本)',
  },
  {
    value: 'je',
    label: 'Jersey',
  },
  {
    value: 'jo',
    label: 'Jordan (الأردن)',
  },
  {
    value: 'kz',
    label: 'Kazakhstan (Казахстан)',
  },
  {
    value: 'ke',
    label: 'Kenya',
  },
  {
    value: 'ki',
    label: 'Kiribati',
  },
  {
    value: 'kw',
    label: 'Kuwait (الكويت)',
  },
  {
    value: 'kg',
    label: 'Kyrgyzstan (Кыргызстан)',
  },
  {
    value: 'la',
    label: 'Laos (ລາວ)',
  },
  {
    value: 'lv',
    label: 'Latvia (Latvija)',
  },
  {
    value: 'lb',
    label: 'Lebanon (لبنان)',
  },
  {
    value: 'ls',
    label: 'Lesotho',
  },
  {
    value: 'lr',
    label: 'Liberia',
  },
  {
    value: 'ly',
    label: 'Libya (ليبيا)',
  },
  {
    value: 'li',
    label: 'Liechtenstein',
  },
  {
    value: 'lt',
    label: 'Lithuania (Lietuva)',
  },
  {
    value: 'lu',
    label: 'Luxembourg',
  },
  {
    value: 'mo',
    label: 'Macau (澳門)',
  },
  {
    value: 'mk',
    label: 'Macedonia (FYROM) (Македонија)',
  },
  {
    value: 'mg',
    label: 'Madagascar (Madagasikara)',
  },
  {
    value: 'mw',
    label: 'Malawi',
  },
  {
    value: 'my',
    label: 'Malaysia',
  },
  {
    value: 'mv',
    label: 'Maldives',
  },
  {
    value: 'ml',
    label: 'Mali',
  },
  {
    value: 'mt',
    label: 'Malta',
  },
  {
    value: 'mh',
    label: 'Marshall Islands',
  },
  {
    value: 'mq',
    label: 'Martinique',
  },
  {
    value: 'mr',
    label: 'Mauritania (موريتانيا)',
  },
  {
    value: 'mu',
    label: 'Mauritius (Moris)',
  },
  {
    value: 'yt',
    label: 'Mayotte',
  },
  {
    value: 'mx',
    label: 'Mexico (México)',
  },
  {
    value: 'fm',
    label: 'Micronesia',
  },
  {
    value: 'md',
    label: 'Moldova (Republica Moldova)',
  },
  {
    value: 'mc',
    label: 'Monaco',
  },
  {
    value: 'mn',
    label: 'Mongolia (Монгол)',
  },
  {
    value: 'me',
    label: 'Montenegro (Crna Gora)',
  },
  {
    value: 'ms',
    label: 'Montserrat',
  },
  {
    value: 'ma',
    label: 'Morocco (المغرب)',
  },
  {
    value: 'mz',
    label: 'Mozambique (Moçambique)',
  },
  {
    value: 'mm',
    label: 'Myanmar (Burma)',
  },
  {
    value: 'na',
    label: 'Namibia (Namibië)',
  },
  {
    value: 'nr',
    label: 'Nauru',
  },
  {
    value: 'np',
    label: 'Nepal (नेपाल)',
  },
  {
    value: 'nl',
    label: 'Netherlands (Nederland)',
  },
  {
    value: 'nc',
    label: 'New Caledonia (Nouvelle-Calédonie)',
  },
  {
    value: 'nz',
    label: 'New Zealand',
  },
  {
    value: 'ni',
    label: 'Nicaragua',
  },
  {
    value: 'ne',
    label: 'Niger (Nijar)',
  },
  {
    value: 'ng',
    label: 'Nigeria',
  },
  {
    value: 'nu',
    label: 'Niue',
  },
  {
    value: 'nf',
    label: 'Norfolk Island',
  },
  {
    value: 'kp',
    label: 'North Korea (조선 민주주의 인민 공화국)',
  },
  {
    value: 'mp',
    label: 'Northern Mariana Islands',
  },
  {
    value: 'no',
    label: 'Norway (Norge)',
  },
  {
    value: 'om',
    label: 'Oman (عُمان)',
  },
  {
    value: 'pk',
    label: 'Pakistan (پاکستان)',
  },
  {
    value: 'pw',
    label: 'Palau',
  },
  {
    value: 'ps',
    label: 'Palestine (فلسطين)',
  },
  {
    value: 'pa',
    label: 'Panama (Panamá)',
  },
  {
    value: 'pg',
    label: 'Papua New Guinea',
  },
  {
    value: 'py',
    label: 'Paraguay',
  },
  {
    value: 'pe',
    label: 'Peru (Perú)',
  },
  {
    value: 'ph',
    label: 'Philippines',
  },
  {
    value: 'pl',
    label: 'Poland (Polska)',
  },
  {
    value: 'pt',
    label: 'Portugal',
  },
  {
    value: 'pr',
    label: 'Puerto Rico',
  },
  {
    value: 'qa',
    label: 'Qatar (قطر)',
  },
  {
    value: 'ro',
    label: 'Romania (România)',
  },
  {
    value: 'ru',
    label: 'Russia (Россия)',
  },
  {
    value: 'rw',
    label: 'Rwanda',
  },
  {
    value: 're',
    label: 'Réunion (La Réunion)',
  },
  {
    value: 'bl',
    label: 'Saint Barthélemy (Saint-Barthélemy)',
  },
  {
    value: 'sh',
    label: 'Saint Helena',
  },
  {
    value: 'kn',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: 'lc',
    label: 'Saint Lucia',
  },
  {
    value: 'mf',
    label: 'Saint Martin (Saint-Martin (partie française))',
  },
  {
    value: 'pm',
    label: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
  },
  {
    value: 'vc',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: 'ws',
    label: 'Samoa',
  },
  {
    value: 'sm',
    label: 'San Marino',
  },
  {
    value: 'sa',
    label: 'Saudi Arabia (المملكة العربية السعودية)',
  },
  {
    value: 'sn',
    label: 'Senegal (Sénégal)',
  },
  {
    value: 'rs',
    label: 'Serbia (Србија)',
  },
  {
    value: 'sc',
    label: 'Seychelles',
  },
  {
    value: 'sl',
    label: 'Sierra Leone',
  },
  {
    value: 'sg',
    label: 'Singapore',
  },
  {
    value: 'sx',
    label: 'Sint Maarten',
  },
  {
    value: 'sk',
    label: 'Slovakia (Slovensko)',
  },
  {
    value: 'si',
    label: 'Slovenia (Slovenija)',
  },
  {
    value: 'sb',
    label: 'Solomon Islands',
  },
  {
    value: 'so',
    label: 'Somalia (Soomaaliya)',
  },
  {
    value: 'za',
    label: 'South Africa',
  },
  {
    value: 'kr',
    label: 'South Korea (대한민국)',
  },
  {
    value: 'ss',
    label: 'South Sudan (جنوب السودان)',
  },
  {
    value: 'es',
    label: 'Spain (España)',
  },
  {
    value: 'lk',
    label: 'Sri Lanka (ශ්‍රී ලංකාව)',
  },
  {
    value: 'sd',
    label: 'Sudan (السودان)',
  },
  {
    value: 'sr',
    label: 'Suriname',
  },
  {
    value: 'sj',
    label: 'Svalbard and Jan Mayen',
  },
  {
    value: 'sz',
    label: 'Swaziland',
  },
  {
    value: 'se',
    label: 'Sweden (Sverige)',
  },
  {
    value: 'ch',
    label: 'Switzerland (Schweiz)',
  },
  {
    value: 'sy',
    label: 'Syria (سوريا)',
  },
  {
    value: 'st',
    label: 'São Tomé and Príncipe (São Tomé e Príncipe)',
  },
  {
    value: 'tw',
    label: 'Taiwan (台灣)',
  },
  {
    value: 'tj',
    label: 'Tajikistan',
  },
  {
    value: 'tz',
    label: 'Tanzania',
  },
  {
    value: 'th',
    label: 'Thailand (ไทย)',
  },
  {
    value: 'tl',
    label: 'Timor-Leste',
  },
  {
    value: 'tg',
    label: 'Togo',
  },
  {
    value: 'tk',
    label: 'Tokelau',
  },
  {
    value: 'to',
    label: 'Tonga',
  },
  {
    value: 'tt',
    label: 'Trinidad and Tobago',
  },
  {
    value: 'tn',
    label: 'Tunisia (تونس)',
  },
  {
    value: 'tr',
    label: 'Turkey (Türkiye)',
  },
  {
    value: 'tm',
    label: 'Turkmenistan',
  },
  {
    value: 'tc',
    label: 'Turks and Caicos Islands',
  },
  {
    value: 'tv',
    label: 'Tuvalu',
  },
  {
    value: 'vi',
    label: 'U.S. Virgin Islands',
  },
  {
    value: 'ug',
    label: 'Uganda',
  },
  {
    value: 'ua',
    label: 'Ukraine (Україна)',
  },
  {
    value: 'ae',
    label: 'United Arab Emirates (الإمارات العربية المتحدة)',
  },
  {
    value: 'gb',
    label: 'United Kingdom',
  },
  {
    value: 'us',
    label: 'United States',
  },
  {
    value: 'uy',
    label: 'Uruguay',
  },
  {
    value: 'uz',
    label: 'Uzbekistan (Oʻzbekiston)',
  },
  {
    value: 'vu',
    label: 'Vanuatu',
  },
  {
    value: 'va',
    label: 'Vatican City (Città del Vaticano)',
  },
  {
    value: 've',
    label: 'Venezuela',
  },
  {
    value: 'vn',
    label: 'Vietnam (Việt Nam)',
  },
  {
    value: 'wf',
    label: 'Wallis and Futuna',
  },
  {
    value: 'eh',
    label: 'Western Sahara (الصحراء الغربية)',
  },
  {
    value: 'ye',
    label: 'Yemen (اليمن)',
  },
  {
    value: 'zm',
    label: 'Zambia',
  },
  {
    value: 'zw',
    label: 'Zimbabwe',
  },
  {
    value: 'ax',
    label: 'Åland Islands',
  },
]

export default nationalities
