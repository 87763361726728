import React, { useState } from 'react'
import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { CloseIcon } from 'src/icons'
import { ErrorAlert, ErrorType } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { getYearsOptions } from 'src/components/Form/utils'
import { InputSelectNew } from 'src/components/Form'
import { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import { universitiesList } from './universitiesList'
import { UserEducation } from 'src/store/user/selectors'
import AlertCard from 'src/components/AlertCard/AlertCard'
import ButtonNew from 'src/components/Buttons/ButtonNew'
import ConfirmationAlertModal from 'src/scenes/Main/Profile/RequiredTasks/components/ConfirmationAlertModal'
import TextInputNew from 'src/components/Form/TextInputNew/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import validationSchema from './validation'
import { colorsNew } from 'src/styles'
import styles from './styles'

type RecursiveNonNullable<T> = { [K in keyof T]-?: RecursiveNonNullable<NonNullable<T[K]>> }

export type UniversityEducationFormValues = Pick<
  RecursiveNonNullable<UserEducation>,
  'level' | 'name' | 'graduationYear' | 'subject' | 'id'
> & {
  credits: number | string
  namePreselect: string
}

type Props = {
  closeFormError: () => void
  closeFormWithoutSaving: () => void
  deleteEducation: (experienceId?: string) => void
  education: Partial<UserEducation>
  formError: ErrorType | null
  isDeleting: boolean
  isDisabled: boolean
  isSubmitting: boolean
  updateEducationList: (experience: UniversityEducationFormValues, editedExperienceId?: string) => void
}

const UniversityForm = ({
  closeFormError,
  closeFormWithoutSaving,
  deleteEducation,
  education,
  formError,
  isDeleting,
  isDisabled,
  isSubmitting,
  updateEducationList,
}: Props) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const getPreSelectedUniversity = () => {
    const universitySelectedFromList = universitiesList.some(item => item.value === education.name)
    if (universitySelectedFromList) {
      return education.name as string
    }
    return education.name ? 'other' : ''
  }

  const initialValues: UniversityEducationFormValues = {
    credits: education.credits?.toString() || '',
    graduationYear: education.graduationYear?.toString() || '',
    id: education.id || '',
    level: education.level || 'university',
    name: education.name || '',
    namePreselect: getPreSelectedUniversity(),
    subject: education.subject || '',
  }

  if (showDeleteConfirmation) {
    return (
      <ConfirmationAlertModal
        description={translate(translations.educationFormDeleteConfirmationDetails)}
        isDeleting={isDeleting}
        onCancel={() => setShowDeleteConfirmation(false)}
        onDelete={() => deleteEducation(education.id)}
        title={translate(translations.educationFormDeleteConfirmationTitle)}
      />
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => updateEducationList(values, education.id)}
      validationSchema={validationSchema}
    >
      {({ setFieldTouched, setFieldValue, dirty, errors, touched, values, isValid, handleSubmit }) => {
        if (showDeleteConfirmation) {
          return (
            <ConfirmationAlertModal
              description={translate(translations.educationFormDeleteConfirmationDetails)}
              isDeleting={isDeleting}
              onCancel={() => setShowDeleteConfirmation(false)}
              onDelete={() => deleteEducation(education.id)}
              title={translate(translations.educationFormDeleteConfirmationTitle)}
            />
          )
        }
        return (
          <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.formHeader}>
              <View style={styles.formHeaderInfo}>
                {education.subject ? (
                  <>
                    <Text style={styles.formTitle}>{education.subject}</Text>
                    <Text style={styles.formSubtitle}>{education.name}</Text>
                  </>
                ) : (
                  <Text style={styles.formTitle}>{translate(translations.educationFormTitle)}</Text>
                )}
              </View>
              <TouchableOpacity onPress={closeFormWithoutSaving}>
                <CloseIcon fill={colorsNew.redesign.text.primary} size={24} />
              </TouchableOpacity>
            </View>

            {isDisabled ? (
              <View style={styles.alertContainer}>
                <AlertCard
                  colorSchema={alertColorScheme.info}
                  description={translate(translations.educationFormAlertInfo)}
                  title={translate(translations.educationFormAlertTitle)}
                  variant={alertVariants.standard}
                />
              </View>
            ) : null}

            <TextInputNew
              disabled={isDisabled}
              errorMessage={errors.subject}
              isError={!isEmpty(errors.subject) && touched.subject}
              label={translate(translations.educationFormSubjectLabel)}
              onBlur={() => setFieldTouched('subject', true)}
              onChangeText={text => setFieldValue('subject', text)}
              required
              size={TextInputSizeVariants.medium}
              value={values.subject}
            />

            <InputSelectNew
              disabled={isDisabled}
              errorMessage={errors.namePreselect}
              isError={!isEmpty(errors.namePreselect) && touched.namePreselect}
              label={translate(translations.educationFormUniversityLabel)}
              onBlur={() => setFieldTouched('namePreselect', true)}
              onValueChange={(value: string) => setFieldValue('namePreselect', value)}
              required
              value={values.namePreselect}
              values={universitiesList}
            />

            {values.namePreselect === 'other' ? (
              <TextInputNew
                disabled={isDisabled}
                errorMessage={errors.name}
                isError={!isEmpty(errors.name) && touched.name}
                label={translate(translations.educationFormSchoolLabel)}
                onBlur={() => setFieldTouched('name', true)}
                onChangeText={text => setFieldValue('name', text)}
                required
                size={TextInputSizeVariants.medium}
                value={values.name}
              />
            ) : null}

            <TextInputNew
              disabled={isDisabled}
              errorMessage={errors.credits}
              isError={!isEmpty(errors.credits) && touched.credits}
              keyboardType="numeric"
              label={translate(translations.educationFormCreditsLabel)}
              onChangeText={text => setFieldValue('credits', text)}
              onBlur={() => setFieldTouched('credits', true)}
              size={TextInputSizeVariants.medium}
              required
              value={values.credits}
            />
            <InputSelectNew
              disabled={isDisabled}
              errorMessage={errors.graduationYear}
              isError={!isEmpty(errors.graduationYear) && touched.graduationYear}
              label={translate(translations.educationFormYearLabel)}
              onBlur={() => setFieldTouched('graduationYear', true)}
              onValueChange={(value: string) => setFieldValue('graduationYear', value)}
              required
              value={values.graduationYear}
              values={getYearsOptions(51, 10)}
            />

            <View style={styles.buttons}>
              {formError ? <ErrorAlert hideError={closeFormError} type={formError} /> : null}
              <ButtonNew
                disabled={!isValid || !dirty || isDeleting || isDisabled}
                isSubmitting={isSubmitting}
                onPress={handleSubmit}
                size={buttonSize.lg}
                title={translate(translations.saveChanges)}
                variant={buttonVariants.containedDefault}
              />
              <View style={styles.buttonsDivider} />
              <ButtonNew
                disabled={isSubmitting || isDisabled}
                isSubmitting={isDeleting}
                onPress={() => setShowDeleteConfirmation(true)}
                size={buttonSize.lg}
                title={translate(translations.experienceDelete)}
                variant={buttonVariants.outlinedError}
              />
            </View>
          </ScrollView>
        )
      }}
    </Formik>
  )
}

export default UniversityForm
