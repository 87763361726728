import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginBottom: paddingNew.md,
    marginTop: paddingNew.md - paddingNew.xxs,
    paddingTop: paddingNew.base,
  },
})
