import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'
import { regexes } from 'src/global/utils'
import formatter from 'src/utils/formatter'

const getValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string().email(translate(translations.enterValidEmail)).required(translate(translations.blank)),
    employer: Yup.string().required(translate(translations.blank)),
    name: Yup.string().min(3, translate(translations.nameError)).required(translate(translations.blank)),
    occupation: Yup.string().required(translate(translations.blank)),
    phone: Yup.string().matches(regexes.gbPhoneNumberAllowEmpty, translate(translations.formNumber)),
    position: Yup.string().required(translate(translations.blank)),
    reasonForLeaving: Yup.string().when('isOngoing', {
      is: isOngoing => isOngoing === false,
      then: Yup.string().required(translate(translations.blank)),
    }),
    endedOn: Yup.string()
      .when('isOngoing', {
        is: isOngoing => isOngoing === false,
        then: Yup.string().required(translate(translations.blank)),
      })
      .test({
        name: 'startedOn vs endedOn',
        test: function test() {
          if (this.parent.isOngoing) {
            return true
          }
          return this.parent.startedOn && this.parent.endedOn && !this.parent.isOngoing
            ? formatter.getDatesDifferenceInDays(this.parent.endedOn, this.parent.startedOn) >= 0
            : true
        },
        message: () => translate(translations.errorDateAvailability),
      }),
    startedOn: Yup.string()
      .required(translate(translations.blank))
      .test({
        name: 'startedOn vs endedOn',
        test: function test() {
          if (this.parent.isOngoing) {
            return true
          }
          return this.parent.startedOn && this.parent.endedOn && !this.parent.isOngoing
            ? formatter.getDatesDifferenceInDays(this.parent.endedOn, this.parent.startedOn) >= 0
            : true
        },
        message: () => translate(translations.errorDateAvailability),
      }),
  })

export default getValidationSchema
