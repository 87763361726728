import { StyleSheet, Dimensions, Platform } from 'react-native'
import { colorsNew, fonts, fontSizesNew } from 'src/styles'

const isWeb = Platform.OS === 'web'

const MAX_SIZE = Dimensions.get('window').width * 0.8
const ANIMATION_SIZE = isWeb ? 300 : MAX_SIZE

const styles = StyleSheet.create({
  animationContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.white,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  animation: {
    height: ANIMATION_SIZE,
    width: ANIMATION_SIZE,
  },
  text: {
    color: colorsNew.brand.primary,
    fontFamily: fonts.poppinsSemiBold,
    fontSize: fontSizesNew.md.size,
    maxWidth: 250,
    textAlign: 'center',
  },
  animationHidden: {
    display: 'none',
    position: 'relative',
  },
})

export default styles
