import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  info: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginRight: paddingNew.xxs,
  },
})

export default styles
