import { StyleSheet } from 'react-native'

import { colorsNew, typography } from 'src/styles'

const schoolColor = {
  active: { color: colorsNew.redesign.text.primary },
  disabled: { color: colorsNew.redesign.text.disabled },
}

const schoolName = {
  ...typography.subtitle2,
  ...schoolColor.active,
  paddingTop: 2,
}

const schoolCity = {
  ...typography.caption,
  ...schoolColor.active,
  flex: 1,
}

export default StyleSheet.create({
  rowContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    height: 50,
  },
  nameContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  schoolNameExcluded: {
    ...schoolName,
    ...schoolColor.disabled,
  },
  schoolName: {
    ...schoolName,
  },
  schoolCity: {
    ...schoolCity,
  },
  schoolCityExcluded: {
    ...schoolCity,
    ...schoolColor.disabled,
  },
  switchContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 45,
    width: 50,
  },
})
