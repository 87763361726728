import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

export default StyleSheet.create({
  button: { marginVertical: 0 },
  buttonWrapper: {
    alignItems: 'center',
  },
  container: {
    paddingHorizontal: paddingNew.sm,
    paddingVertical: paddingNew.md,
  },
  inputsWrapper: {
    marginBottom: paddingNew.sm,
  },
})
