import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#00A033'

const CheckCircleFilledIcon = ({ fill = defaultFill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M6.49996 0.537354C3.27996 0.537354 0.666626 3.15069 0.666626 6.37069C0.666626 9.59069 3.27996 12.204 6.49996 12.204C9.71996 12.204 12.3333 9.59069 12.3333 6.37069C12.3333 3.15069 9.71996 0.537354 6.49996 0.537354ZM4.91913 8.87319L2.82496 6.77902C2.59746 6.55152 2.59746 6.18402 2.82496 5.95652C3.05246 5.72902 3.41996 5.72902 3.64746 5.95652L5.33329 7.63652L9.34663 3.62319C9.57413 3.39569 9.94163 3.39569 10.1691 3.62319C10.3966 3.85069 10.3966 4.21819 10.1691 4.44569L5.74163 8.87319C5.51996 9.10069 5.14663 9.10069 4.91913 8.87319Z"
      fill={fill}
    />
  </Svg>
)

export default CheckCircleFilledIcon
