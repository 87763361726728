import * as configCat from 'configcat-js'

import { bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { FeatureFlagsT, flagKeys, initialFeatureFlagState } from 'src/context/featureFlags'

type FetchFeatureFlagsT = {
  regionId: string
  sdkKey: string
}

const getConfigcatClient = (sdkKey: string) => {
  const options = {
    dataGovernance: configCat.DataGovernance.EuOnly,
  }
  const configCatClient = configCat.getClient(sdkKey, 0, options)

  return configCatClient
}

export const getFeatureFlags = async ({ regionId, sdkKey }: FetchFeatureFlagsT): Promise<FeatureFlagsT> => {
  if (!sdkKey) {
    bugsnagNotifyWithData('Missing SDK key for ConfigCat. Feature flags cannot be fetched.')
    return initialFeatureFlagState.featureFlags
  }

  const configCatClient = getConfigcatClient(sdkKey)

  const fetchedFeatureFlags: FeatureFlagsT = initialFeatureFlagState.featureFlags

  await Promise.all(
    flagKeys.map(async key => {
      try {
        const value = await configCatClient.getValueAsync(key, false, {
          identifier: '',
          custom: { RegionId: regionId },
        })
        fetchedFeatureFlags[key] = value
      } catch (error) {
        bugsnagNotifyWithData(`Error fetching feature flag ${key}: `, { error })
      }
    }),
  )
  configCatClient.dispose()
  return fetchedFeatureFlags
}
