import images from 'src/global/images'

export const getSchoolPlaceholder = (level: string) => {
  switch (level) {
    case 'preschool':
      return images.preschool
    case 'sen':
    case 'special_primary':
      return images.sen
    case 'komvux':
    case 'secondary':
    case 'special_secondary':
      return images.secondary
    case 'primary':
    default:
      return images.primary
  }
}
