import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'

const validationSchema = () =>
  Yup.object().shape({
    profileDescription: Yup.string()
      .min(10)
      .required(() => translate(translations.blank)),
  })

export default validationSchema
