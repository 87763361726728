import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    paddingVertical: paddingNew.md,
  },
  headerContainer: {
    marginBottom: 20,
    marginHorizontal: paddingNew.sm,
  },
  headerText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
})
