import { StyleSheet } from 'react-native'

import { colors, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  arrow: {
    color: colorsNew.redesign.text.primary,
  },
  errorInput: {
    borderColor: colors.danger,
  },
  fieldValue: {
    color: colorsNew.redesign.text.primary,
    fontSize: 14,
  },
  placeholder: {
    color: colors.grey._500,
  },
  textInput: {
    alignItems: 'center',
    borderColor: colors.grey._300,
    borderRadius: 6,
    borderWidth: 1,
    color: colorsNew.redesign.text.primary,
    flexDirection: 'row',
    fontSize: 14,
    height: 42,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: '100%',
  },
})

export default styles
