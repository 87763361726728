import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const SlideShowIcon = ({ fill = '#212121', size, ...props }: IconTypes) => (
  <Svg fill="none" height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M10 9.04v5.92c0 .42.48.65.81.39l3.7-2.96a.5.5 0 000-.78l-3.7-2.96a.498.498 0 00-.81.39zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1z"
      fill={fill}
    />
  </Svg>
)

export default SlideShowIcon
