import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const NewReleaseRounded = ({ fill, size, ...props }: IconTypes) => (
  <Svg height={size} width={size} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M21.42 10.3406L19.56 8.22056L19.82 5.41055C19.87 4.91055 19.53 4.45056 19.05 4.34056L16.29 3.71056L14.85 1.28056C14.59 0.850555 14.06 0.670555 13.6 0.870555L11 2.00056L8.41002 0.890555C7.95002 0.690555 7.41002 0.870555 7.16002 1.30056L5.71002 3.72055L2.96002 4.34056C2.47002 4.45056 2.13002 4.90055 2.18002 5.41055L2.44002 8.21056L0.58002 10.3406C0.25002 10.7206 0.25002 11.2806 0.58002 11.6606L2.44002 13.7806L2.18002 16.6006C2.13002 17.1006 2.47002 17.5606 2.95002 17.6706L5.71002 18.3006L7.15002 20.7206C7.41002 21.1506 7.94002 21.3306 8.41002 21.1306L11 20.0006L13.59 21.1106C14.05 21.3106 14.59 21.1306 14.84 20.7006L16.28 18.2706L19.04 17.6406C19.53 17.5306 19.86 17.0706 19.81 16.5706L19.55 13.7606L21.41 11.6406C21.75 11.2806 21.75 10.7206 21.42 10.3406ZM12 16.0006H10V14.0006H12V16.0006ZM11 12.0006C10.45 12.0006 10 11.5506 10 11.0006V7.00056C10 6.45055 10.45 6.00056 11 6.00056C11.55 6.00056 12 6.45055 12 7.00056V11.0006C12 11.5506 11.55 12.0006 11 12.0006Z"
      fill={fill}
    />
  </Svg>
)

export default NewReleaseRounded
