import React from 'react'
import Svg, { Rect, Path, Mask } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const CalendarCardMultidayIcon = ({ size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/Svg" {...props}>
    <Rect width={size} height={size} fill="white" />

    <Mask id="path-1-inside-1_2288_16491" fill="white">
      <Path d="M6 10C6 7.79086 7.79086 6 10 6H34C36.2091 6 38 7.79086 38 10V11H6V10Z" />
    </Mask>
    <Path d="M6 10C6 7.79086 7.79086 6 10 6H34C36.2091 6 38 7.79086 38 10V11H6V10Z" fill="#E99797" />
    <Path
      d="M5.86485 10C5.86485 7.71622 7.71622 5.86485 10 5.86485H34C36.2838 5.86485 38.1352 7.71622 38.1352 10H37.8648C37.8648 7.8655 36.1345 6.13515 34 6.13515H10C7.8655 6.13515 6.13515 7.8655 6.13515 10H5.86485ZM38 11H6H38ZM5.86485 11V10C5.86485 7.71622 7.71622 5.86485 10 5.86485V6.13515C7.8655 6.13515 6.13515 7.8655 6.13515 10V11H5.86485ZM34 5.86485C36.2838 5.86485 38.1352 7.71622 38.1352 10V11H37.8648V10C37.8648 7.8655 36.1345 6.13515 34 6.13515V5.86485Z"
      fill="black"
      fill-opacity="0.38"
      mask="url(#path-1-inside-1_2288_16491)"
    />
    <Mask id="path-3-inside-2_2288_16491" fill="white">
      <Path d="M6 11H38V34C38 36.2091 36.2091 38 34 38H10C7.79086 38 6 36.2091 6 34V11Z" />
    </Mask>
    <Path d="M6 11H38V34C38 36.2091 36.2091 38 34 38H10C7.79086 38 6 36.2091 6 34V11Z" fill="#F4F4F4" />
    <Path
      d="M6 11H38H6ZM38.3 34C38.3 36.3748 36.3748 38.3 34 38.3H10C7.62518 38.3 5.7 36.3748 5.7 34H6.3C6.3 36.0435 7.95655 37.7 10 37.7H34C36.0435 37.7 37.7 36.0435 37.7 34H38.3ZM10 38.3C7.62518 38.3 5.7 36.3748 5.7 34V11H6.3V34C6.3 36.0435 7.95655 37.7 10 37.7V38.3ZM38.3 11V34C38.3 36.3748 36.3748 38.3 34 38.3V37.7C36.0435 37.7 37.7 36.0435 37.7 34V11H38.3Z"
      fill="black"
      fill-opacity="0.38"
      mask="url(#path-3-inside-2_2288_16491)"
    />
    <Mask id="path-5-inside-3_2288_16491" fill="white">
      <Path d="M2 6C2 3.79086 3.79086 2 6 2H30C32.2091 2 34 3.79086 34 6V7H2V6Z" />
    </Mask>
    <Path d="M2 6C2 3.79086 3.79086 2 6 2H30C32.2091 2 34 3.79086 34 6V7H2V6Z" fill="#E99797" />
    <Path
      d="M1.86485 6C1.86485 3.71622 3.71622 1.86485 6 1.86485H30C32.2838 1.86485 34.1352 3.71622 34.1352 6H33.8648C33.8648 3.8655 32.1345 2.13515 30 2.13515H6C3.8655 2.13515 2.13515 3.8655 2.13515 6H1.86485ZM34 7H2H34ZM1.86485 7V6C1.86485 3.71622 3.71622 1.86485 6 1.86485V2.13515C3.8655 2.13515 2.13515 3.8655 2.13515 6V7H1.86485ZM30 1.86485C32.2838 1.86485 34.1352 3.71622 34.1352 6V7H33.8648V6C33.8648 3.8655 32.1345 2.13515 30 2.13515V1.86485Z"
      fill="black"
      fill-opacity="0.38"
      mask="url(#path-5-inside-3_2288_16491)"
    />
    <Mask id="path-7-inside-4_2288_16491" fill="white">
      <Path d="M2 7H34V30C34 32.2091 32.2091 34 30 34H6C3.79086 34 2 32.2091 2 30V7Z" />
    </Mask>
    <Path d="M2 7H34V30C34 32.2091 32.2091 34 30 34H6C3.79086 34 2 32.2091 2 30V7Z" fill="white" />
    <Path
      d="M2 7H34H2ZM34.3 30C34.3 32.3748 32.3748 34.3 30 34.3H6C3.62518 34.3 1.7 32.3748 1.7 30H2.3C2.3 32.0435 3.95655 33.7 6 33.7H30C32.0435 33.7 33.7 32.0435 33.7 30H34.3ZM6 34.3C3.62518 34.3 1.7 32.3748 1.7 30V7H2.3V30C2.3 32.0435 3.95655 33.7 6 33.7V34.3ZM34.3 7V30C34.3 32.3748 32.3748 34.3 30 34.3V33.7C32.0435 33.7 33.7 32.0435 33.7 30V7H34.3Z"
      fill="black"
      fill-opacity="0.38"
      mask="url(#path-7-inside-4_2288_16491)"
    />
  </Svg>
)

export default CalendarCardMultidayIcon
