import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import {
  acceptedDocumentsList,
  PROOF_OF_IDENTITY_URL,
  proofOfAddressDocumentType,
  registeredAddressFields,
} from './constants'
import { AddressT } from 'src/store/user/userTypes'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DotTextRow } from 'src/components/DotTextRow'
import { getUserDocumentUK } from 'src/store/user/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import FileUploader from 'src/components/FileUploader/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'AddressVerification'>

const AddressVerification = ({ navigation, route }: Props) => {
  const { status, task, timeToComplete } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - AddressVerification')

  const userAddress = useAppSelector(state => state.user.attributes?.address) as AddressT
  const userId = useAppSelector(state => state.user.id) as string
  const proofOfAddressDocument = useAppSelector(getUserDocumentUK(proofOfAddressDocumentType))

  const [isError, setIsError] = useState(false)
  const [numberOfPickedFiles, setNumberOfPickedFiles] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) documentRef.current.dirty = !!numberOfPickedFiles
  }, [numberOfPickedFiles])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const updateTaskStatus = () =>
    markTaskAsCompleted(userId, task, !!numberOfPickedFiles, mixpanel, handleUploadFinish, handleError)

  const completeTask = () => {
    bugsnagActionBreadcrumb('Complete verify address task', { id: userId })
    setIsSubmitting(true)
    setIsError(false)
    numberOfPickedFiles ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  const handleLinkButton = () => openURLIfCan(PROOF_OF_IDENTITY_URL)

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <View style={styles.sectionContainer}>
        <Text style={styles.info}>{translate(translations.verifyAddressUploadPicture)}</Text>
        <Text style={styles.helperText}>{translate(translations.verifyAddressUploadPictureAdditionalInfo)}</Text>
        <Text style={styles.headerText}>{translate(translations.verifyAddressRegisteredAddress)}</Text>
        {registeredAddressFields.map(key => (userAddress[key] ? <Text>{userAddress[key]}</Text> : null))}
        <FileUploader
          arrayDocuments={proofOfAddressDocument}
          buttonDisabled={isTaskCompleted}
          deletingFilesDisabled={isTaskCompleted}
          documentName={translate(translations.verifyAddressUploadPictureHeader)}
          documentType={proofOfAddressDocumentType}
          key={proofOfAddressDocumentType}
          onFileSaveCallback={!isTaskCompleted ? updateTaskStatus : handleUploadFinish}
          onFilesChangeCallback={({ uploadedFiles }) => setNumberOfPickedFiles(uploadedFiles?.length || 0)}
          onFilesErrorCallback={handleError}
          saveOnUpload={shouldSaveDocument}
        />
      </View>
      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alertWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonWrapper}>
          <ButtonNew
            containerStyle={styles.button}
            disabled={(!(numberOfPickedFiles > 1) && !proofOfAddressDocument.length) || isTaskCompleted}
            isLong
            isSubmitting={isSubmitting}
            onPress={completeTask}
            title={translate(translations.closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
        <Text style={styles.helperText}>{translate(translations.taskInfoDeclaraction)}</Text>
      </View>
      <Text style={styles.headerText}>{translate(translations.verifyAddressAcceptedDocumentsHeader)}</Text>
      <View style={styles.listWrapper}>
        {acceptedDocumentsList.map(doc => (
          <DotTextRow
            containerStyle={styles.listItemContainer}
            key={doc}
            text={translate(translations[`verifyAddressAcceptedDocuments${doc}`])}
            textStyle={styles.listText}
          />
        ))}
      </View>
      <View style={styles.buttonWrapper}>
        <ButtonNew
          containerStyle={styles.button}
          isLong
          onPress={handleLinkButton}
          RightIcon={ChevronRightIcon}
          title={translate(translations.verifyAddressMoreInfo)}
          variant={buttonVariants.outlinedDefault}
        />
      </View>
    </ScrollView>
  )
}

export default AddressVerification
