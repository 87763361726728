import React from 'react'
import { ClipPath, Defs, G, LinearGradient, Path, Rect, Stop, Svg } from 'react-native-svg'

interface IGreenStroke {
  width: number
}

const GreenStroke = ({ width }: IGreenStroke) => {
  const aspectRatio = 375 / 175

  return (
    <Svg
      fill="none"
      height={width / aspectRatio}
      viewBox="0 0 375 175"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <G clipPath="url(#clip0_667_10068)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151.171 43.6238C70.8336 94.1365 5.90456 47.3784 -16.3968 17.7815L-82.4892 83.5677C-45.4946 132.665 60.5942 210.33 189.961 128.99C230.403 103.562 270.238 102.968 305.361 113.557C341.386 124.419 370.497 146.63 385.095 162.312L445.344 89.5501C422.078 64.5551 381.289 33.9909 330.666 18.7281C279.141 3.19335 215.58 3.126 151.171 43.6238Z"
          fill="url(#paint0_linear_667_10068)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_667_10068"
          x1="-49.443"
          y1="50.6746"
          x2="415.219"
          y2="125.931"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DDF2C6" />
          <Stop offset="1" stopColor="#97E1C3" />
        </LinearGradient>
        <ClipPath id="clip0_667_10068">
          <Rect width="375" height="175" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default GreenStroke
