import gql from 'graphql-tag'

export default gql`
  query ($userRegion: String) {
    expressBookingPools(filter: { region: $userRegion }) {
      nodes {
        id
      }
    }
  }
`
