import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const FactCheckedIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3ZM9 17H6C5.45 17 5 16.55 5 16C5 15.45 5.45 15 6 15H9C9.55 15 10 15.45 10 16C10 16.55 9.55 17 9 17ZM9 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H9C9.55 11 10 11.45 10 12C10 12.55 9.55 13 9 13ZM9 9H6C5.45 9 5 8.55 5 8C5 7.45 5.45 7 6 7H9C9.55 7 10 7.45 10 8C10 8.55 9.55 9 9 9ZM18.7 11.12L15.53 14.29C15.14 14.68 14.5 14.68 14.11 14.29L12.7 12.87C12.31 12.48 12.31 11.85 12.7 11.46C13.09 11.07 13.72 11.07 14.11 11.46L14.82 12.17L17.29 9.7C17.68 9.31 18.31 9.31 18.7 9.7L18.71 9.71C19.09 10.1 19.09 10.74 18.7 11.12Z"
    />
  </Svg>
)

export default FactCheckedIcon
