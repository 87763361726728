import React, { useState, useMemo, useRef, useEffect } from 'react'
import { ActionSheetIOS, findNodeHandle, Platform, TouchableOpacity, UIManager } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { bugsnagNotify, bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { deleteActivity, deleteMessage, sendActivityReadStatus, sendMessageReadStatus } from './actions'
import { getNotificationInfo, getOptionsList } from './utils'
import { MessageTypes, UserActivityFieldTypes } from 'src/graphql/Notifications/types'
import { setSnackbarProps } from 'src/store/app/actions'
import ActionsModal from 'src/components/ActionsModal'
import NotificationCard from './NotificationCard'
import translations, { translate } from 'src/utils/translations/translations'
import { setUnreadNotifications } from 'src/store/inbox/actions'
import { NotificationsScreensProps } from 'src/utils/types/navigationTypes'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'

type Props = {
  notification: MessageTypes | UserActivityFieldTypes
  refetch: () => void
  testID: string
}

const NotificationItem = ({ notification, testID, refetch }: Props) => {
  const navigation = useNavigation<NotificationsScreensProps<'NotificationsList'>['navigation']>()
  const dispatch = useAppDispatch()

  const moreIcon = useRef<TouchableOpacity>()

  const isMessage = notification.__typename === 'Message'
  const isIOS = Platform.OS === 'ios'
  const isAndroid = Platform.OS === 'android'

  const [showModal, setShowModal] = useState(false)
  const [isRead, setIsRead] = useState(notification.read)

  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)

  const unreadNotificationsCount = useAppSelector(state => state?.inbox?.unreadNotificationsCount)

  useEffect(() => setIsRead(notification.read), [notification.read])

  const { icon, title, subTitle } = useMemo(() => getNotificationInfo(notification), [notification])

  const navigateToDetails = () => {
    if (isMessage) {
      if (!isRead) {
        sendMessageReadStatus(notification.id, true).then(() => refetch())
      }
      setIsRead(true)
      navigation.navigate('CampaignDetails', {
        ...notification,
      })
    } else {
      if (!isRead) {
        dispatch(setUnreadNotifications(unreadNotificationsCount - 1)) // navigation to jobs are too quick
        sendActivityReadStatus(notification.id, true).then(() => refetch())
      }
      setIsRead(true)
      navigation.navigate(`${notification.resourceKlass}Notification`, { id: notification.resourceId })
    }
  }

  const deleteNotification = () => {
    setIsDeleting(true)

    const onSuccess = () => {
      setIsDeleting(false)
      setIsDeleted(true)
      refetch()
    }

    const onError = () => {
      setIsDeleting(false)
      dispatch(
        setSnackbarProps({
          visible: true,
          message: translate(translations.notificationDeletingError),
          type: 'danger',
        }),
      )
    }

    isMessage ? deleteMessage(notification.id, onSuccess, onError) : deleteActivity(notification.id, onSuccess, onError)
  }

  const toggleNotificationStatus = () => {
    setIsRead(prev => !prev)

    const onError = () =>
      dispatch(
        setSnackbarProps({
          visible: true,
          message: translate(translations.notificationsStatusChangeError),
          type: 'danger',
        }),
      )

    isMessage
      ? sendMessageReadStatus(notification.id, !isRead, onError).then(() => refetch())
      : sendActivityReadStatus(notification.id, !isRead, onError).then(() => refetch())
  }

  const showIOSMenu = () => {
    ActionSheetIOS.showActionSheetWithOptions(
      {
        cancelButtonIndex: 3,
        options: getOptionsList(isRead),
        userInterfaceStyle: 'dark',
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          navigateToDetails()
        } else if (buttonIndex === 1) {
          toggleNotificationStatus()
        } else if (buttonIndex === 2) {
          deleteNotification()
        }
      },
    )
  }

  const showAndroidMenu = () => {
    UIManager.showPopupMenu(
      //@ts-ignore
      findNodeHandle(moreIcon.current),
      getOptionsList(isRead),
      () => {
        console.error('something went wrong with the popup menu')
        bugsnagNotify('something went wrong with the android popup menu')
      },
      (e, buttonIndex) => {
        switch (buttonIndex) {
          case 0: {
            navigateToDetails()
            break
          }
          case 1: {
            toggleNotificationStatus()
            break
          }
          case 2: {
            deleteNotification()
            break
          }
          case 3:
            break
        }
      },
    )
  }

  const modalActions = useMemo(
    () => [
      {
        label: translate(translations.notificationsModalMore),
        onPress: () => {
          navigateToDetails()
          setShowModal(false)
        },
      },
      {
        label: isRead
          ? translate(translations.notificationsModalUnRead)
          : translate(translations.notificationsModalRead),
        onPress: () => {
          toggleNotificationStatus()
          setShowModal(false)
        },
      },
      {
        label: translate(translations.notificationsModalDelete),
        onPress: () => {
          deleteNotification()
          setShowModal(false)
        },
      },
      {
        label: translate(translations.notificationsModalCancel),
        onPress: () => setShowModal(false),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRead],
  )

  const showActionsModal = () => {
    bugsnagActionBreadcrumb(`show notification actions list`)
    if (isAndroid) {
      showAndroidMenu()
      return
    } else if (isIOS) {
      showIOSMenu()
      return
    } else {
      setShowModal(true)
    }
  }

  return (
    <>
      <NotificationCard
        createdAt={notification.createdAt}
        deleteNotification={deleteNotification}
        icon={icon}
        isDeleting={isDeleting}
        ref={moreIcon}
        isDeleted={isDeleted}
        isRead={isRead}
        navigateToDetails={navigateToDetails}
        setShowModal={showActionsModal}
        subTitle={subTitle}
        testID={testID}
        title={title || ''}
      />
      <ActionsModal onClose={() => setShowModal(false)} actions={modalActions} isVisible={showModal} />
    </>
  )
}

export default NotificationItem
