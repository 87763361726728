import gql from 'graphql-tag'

import Region from '../Region'
import Level from '../Level/Fields'
import HomeAddress from '../HomeAddress'

export default gql`
  fragment SchoolFields on School {
    address
    description
    files {
      name
      url
    }
    homeAddress {
      ...HomeAddressFields
    }
    id
    level {
      ...LevelFields
    }
    name
    processArrival
    processHandover
    processOther
    profilePictureUrl
    region {
      ...RegionFields
    }
    type
  }

  ${HomeAddress}
  ${Level}
  ${Region}
`
