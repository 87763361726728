import { LocaleT } from 'src/utils/translations/i18nTypes'
import { UserExperience } from 'src/store/user/selectors'

export const profileCategoriesList = {
  contract: 'contract',
  declarations: 'declarations',
  finalAgreements: 'finalAgreements',
  getToKnowHumly: 'getToKnowHumly',
  profileInfo: 'profileInfo',
  profileVerification: 'profileVerification',
  qualifications: 'qualifications',
  references: 'references',
} as const

export const profileCategoryStatus = {
  completed: 'completed',
  enabled: 'enabled',
  expired: 'expired',
  expiresSoon: 'expiresSoon',
} as const

export const profileTask = {
  addressVerification: 'addressVerification',
  bookInterview: 'bookInterview',
  childCareCertificate: 'childCareCertificate',
  contract: 'contract',
  contractAgreement: 'contractAgreement',
  cvDocument: 'cvDocument',
  dbsCertificate: 'dbsCertificate',
  dbsDeclaration: 'dbsDeclaration',
  disqualificationDeclaration: 'disqualificationDeclaration',
  emergencyContact: 'emergencyContact',
  employmentHistory: 'employmentHistory',
  highSchoolEducation: 'highSchoolEducation',
  humlyAcademy: 'humlyAcademy',
  idVerification: 'idVerification',
  interview: 'interview',
  medicalQuestionnaire: 'medicalQuestionnaire',
  niNumber: 'niNumber',
  otherDocuments: 'otherDocuments',
  payrollDetails: 'payrollDetails',
  personalDetails: 'personalDetails',
  personalProfile: 'personalProfile',
  policies: 'policies',
  policyAgreements: 'policyAgreements',
  proofOfEducations: 'proofOfEducations',
  proofOfPoliceCheck: 'proofOfPoliceCheck',
  proofOfPoliceReport: 'proofOfPoliceReport',
  proofOfRightToWork: 'proofOfRightToWork',
  qualifications: 'qualifications',
  references: 'references',
  safeguardingDeclarations: 'safeguardingDeclarations',
  teacherReferenceNumber: 'teacherReferenceNumber',
  teachingCertificates: 'teachingCertificates',
  universityEducation: 'universityEducation',
  yotiStatus: 'yotiStatus',
} as const

export const profileTaskStatus = {
  completed: 'completed',
  enabled: 'enabled',
  expired: 'expired',
  expiresSoon: 'expiresSoon',
  locked: 'locked',
} as const

type ValueOf<T> = T[keyof T]

export type ProfileTaskType = ValueOf<typeof profileTask>
export type ProfileTaskStatus = ValueOf<typeof profileTaskStatus>

export type ProfileCategories = ValueOf<typeof profileCategoriesList>
export type ProfileCategoryStatus = ValueOf<typeof profileCategoryStatus>

export type CategoriesConfig = {
  [countryCode in LocaleT]: ProfileCategories[]
}

export type UKQualificationT = {
  endDate?: string
  id?: string
  isOngoing: boolean
  name: string
  qualificationType: string
  startDate: string
  subject: string
  userId?: string
}

export type SEQualificationT = {
  credits: string | number
  graduationYear: string
  id?: string
  level: string
  name: string
  subject: string
  userId?: string
}

export type WorkExperiencesT = UserExperience & {
  userId?: string
}
