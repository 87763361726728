import { ActionSheetIOS, Platform } from 'react-native'
import { useState } from 'react'
import Clipboard from '@react-native-clipboard/clipboard'

import { Actions } from '../ContactModal/ContactModal'
import { openTelUrl, openURLIfCan } from 'src/global/utils'
import { setSnackbarProps } from 'src/store/app/actions'
import translations, { translate } from 'src/utils/translations/translations'
import { useAppDispatch } from 'src/hooks/reduxHooks'

const useContactActions = () => {
  const dispatch = useAppDispatch()

  const [actions, setActions] = useState<Actions[] | null>(null)
  const closeActionsModal = () => setActions(null)

  const isIOS = Platform.OS === 'ios'

  const copyText = (text: string) => {
    Clipboard.setString(text)
    dispatch(
      setSnackbarProps({
        message: translate(translations.emailCopied),
        visible: true,
      }),
    )
  }

  const mailOptions = [
    translate(translations.emailSend),
    translate(translations.emailCopy),
    translate(translations.cancel),
  ]

  const mailActions = async (buttonIndex: number, mail: string) => {
    switch (buttonIndex) {
      case 0: {
        await openURLIfCan(`mailto:${mail}`)
        break
      }
      case 1: {
        copyText(mail)
        break
      }
      default:
        break
    }
  }

  const getCallActions = (phone: string) => [
    {
      label: translate(translations.callPhone, { phone }),
      onPress: () => openURLIfCan(phone),
    },
    {
      label: translate(translations.cancel),
      onPress: () => setActions(null),
    },
  ]

  const getMailActions = (mail: string) => [
    { label: translate(translations.emailSend), onPress: () => openURLIfCan(`mailto:${mail}`) },
    {
      label: translate(translations.emailCopy),
      onPress: () => {
        copyText(mail)
        closeActionsModal()
      },
    },
    { label: translate(translations.cancel), onPress: () => closeActionsModal() },
  ]

  const showCallActionSheet = (phone: string) => {
    const actionsList = getCallActions(phone)
    isIOS ? openTelUrl(phone) : setActions(actionsList)
  }

  const showMailActionSheet = (mail: string) => {
    if (isIOS) {
      const options = { options: mailOptions, cancelButtonIndex: 2 }
      ActionSheetIOS.showActionSheetWithOptions(options, index => mailActions(index, mail))
    } else {
      const actionsList = getMailActions(mail)
      setActions(actionsList)
    }
  }

  return { showCallActionSheet, showMailActionSheet, actions, closeActionsModal }
}

export default useContactActions
