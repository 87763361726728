import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import { momentWithTimezone } from 'src/global/utils'
import Bookings, { BookingsRequestTypes } from 'src/graphql/Booking'
import ExpressBookings from 'src/graphql/ExpressBookings'
import formatter from 'src/utils/formatter'
import { flattenSectionList, sections, itemSections } from 'src/scenes/Main/Salary/Timesheet/utils'
import { ExpressBookingsRequestTypes } from '../graphql/ExpressBookings'
import { ReportedItemT, UnreportedItemT } from 'src/scenes/Main/Salary/Timesheet/types'

const useTimesheetData = () => {
  // @ts-ignore
  const id = useSelector(state => state?.user?.id)

  const bookings = useQuery<BookingsRequestTypes>(Bookings, {
    variables: {
      bookingType: 'appointed',
      maxDate: formatter.apiFormat(),
      minDate: formatter.apiFormat(momentWithTimezone().subtract(30, 'days')),
    },
  })

  const expressBookings = useQuery<ExpressBookingsRequestTypes>(ExpressBookings, {
    variables: {
      student: id,
      include_appointed: true,
      include_available: false,
      includeAppointedBookingExpress: true,
      maxDate: formatter.apiFormat(momentWithTimezone().subtract(1, 'days')),
      minDate: formatter.apiFormat(momentWithTimezone().subtract(30, 'days')),
    },
  })

  const loading = bookings.loading || expressBookings.loading
  const error = bookings.error || expressBookings.error

  const isEmpty =
    bookings.data?.bookings?.nodes.length === 0 && expressBookings.data?.expressBookings?.nodes.length === 0

  const refetch = () => {
    bookings.refetch && bookings.refetch()
    expressBookings.refetch && expressBookings.refetch()
  }

  const items = useMemo(
    () => itemSections(flattenSectionList(sections(bookings?.data, expressBookings?.data))),
    [bookings?.data, expressBookings?.data],
  ) as {
    reported?: ReportedItemT[]
    unreported?: UnreportedItemT[]
  }

  return { items, loading, error, isEmpty, refetch }
}

export default useTimesheetData
