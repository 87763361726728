import React from 'react'
import { Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import { MenuNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import ButtonNew from 'src/components/Buttons/ButtonNew'

type Props = {
  cancelled?: boolean
  editable?: boolean
  editWorkingTime: () => void
  feedback?: boolean
  id: string
  schoolType: string
}

const ReportSummaryInfo = ({ editable, cancelled, feedback, id, editWorkingTime, schoolType }: Props) => {
  const navigation = useNavigation<MenuNavigatorScreensProps<'Timesheet'>['navigation']>()

  const feedbackStatus = feedback
    ? {
        title: translate(translations.youLeftFeedback),
      }
    : {
        title: translate(translations.howWasYourDay),
        onPress: () => navigation.navigate('Feedback', { bookingId: id, schoolType }),
      }

  const timesheetStatus = cancelled
    ? {
        title: translate(translations.editTimeSheet),
        subTitle: translate(translations.bookingWasCancelled),
      }
    : editable
    ? {
        title: translate(translations.editTimeSheet),
        onPress: editWorkingTime,
      }
    : {
        title: translate(translations.editTimeSheet),
        subTitle: translate(translations.cantEditTimeSheet),
      }

  return (
    <View style={styles.container}>
      <ButtonNew
        disabled={!feedbackStatus.onPress}
        onPress={feedbackStatus.onPress}
        testID="feedback-button"
        title={feedbackStatus.title}
        variant="contained-default"
      />
      <ButtonNew
        disabled={!timesheetStatus.onPress}
        onPress={timesheetStatus.onPress}
        testID="timesheet-button"
        title={timesheetStatus.title}
        variant="outlined-default"
        style={styles.timesheetButton}
      />
      {timesheetStatus.subTitle ? <Text style={styles.helpText}>{timesheetStatus.subTitle}</Text> : null}
    </View>
  )
}

export default ReportSummaryInfo
