import { Platform, StyleSheet } from 'react-native'

import { colorsNew, typography } from 'src/styles'

export default StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    ...Platform.select({
      web: {
        cursor: 'pointer',
        position: 'relative',
      },
    }),
  },
  label: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    flexShrink: 1,
    marginTop: 3,
  },
  labelDisabled: {
    color: colorsNew.redesign.text.disabled,
  },
  icon: {
    color: colorsNew.redesign.background.paper,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadow: {
    position: 'absolute',
    backgroundColor: colorsNew.redesign.primary.shades12p,
    borderRadius: 50,
  },
})
