import React, { useContext, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'

import { markTaskAsCompleted } from 'src/scenes/Main/Profile/RequiredTasks/actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import { UserReferences, getUserReferences } from 'src/store/user/selectors'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import FormInfoCard from 'src/components/FormInfoCard/FormInfoCard'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import ReferenceForm from './ReferenceForm/ReferenceForm'
import StatusInfo from 'src/scenes/Main/Profile/RequiredTasks/components/StatusInfo'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'References'>

const ReferencesGB = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const taskStatusCompleted = status === profileTaskStatus.completed

  const [isFormOpen, setIsFormOpen] = useState(false)
  const [editedReferenceValues, setEditedReferenceValues] = useState<UserReferences | null>(null)

  const userId = useAppSelector(state => state.user.id) as string
  const referencesList = useAppSelector(getUserReferences)

  const { mixpanel } = useContext(MixpanelContext)

  useBugsnagView('RequiredTask - References')

  const openExperienceForm = (reference: UserReferences | null) => {
    const initialValues = reference
      ? {
          ...reference,
          isOngoing: !reference.endedOn,
        }
      : reference

    setIsFormOpen(true)
    setEditedReferenceValues(initialValues)
  }

  const completeTask = () => markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack)

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainText}>{translate(translations.referencesInfo)}</Text>
      <Text style={styles.secondaryText}>{translate(translations.referencesInfo2)}</Text>

      {referencesList.map(reference => (
        <FormInfoCard
          key={reference.id}
          onPress={() => openExperienceForm(reference)}
          subtitle={reference.employer || ''}
          title={reference.name || ''}
        />
      ))}

      <View style={StyleSheet.flatten([styles.buttonContainer, styles.withDivider])}>
        <ButtonNew
          disabled={taskStatusCompleted}
          onPress={() => openExperienceForm(null)}
          size={buttonSize.sm}
          testID="open-reference-form"
          title={translate(translations.referenceAddButton)}
          variant={buttonVariants.containedDefault}
        />
      </View>

      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={taskStatusCompleted || referencesList.length < 2}
          onPress={completeTask}
          size={buttonSize.lg}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.infoText}>{translate(translations.taskInfoDeclaraction)}</Text>
      <NewModalWrapper containerStyle={styles.formContainer} isVisible={isFormOpen}>
        <ReferenceForm
          reference={editedReferenceValues}
          isDisabled={taskStatusCompleted}
          closeForm={() => setIsFormOpen(false)}
        />
      </NewModalWrapper>
    </ScrollView>
  )
}

export default ReferencesGB
