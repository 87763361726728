import { StyleSheet } from 'react-native'

import { colorsNew, utils } from 'src/styles'

export default StyleSheet.create({
  button: {
    minHeight: 0,
    overflow: 'visible',
  },
  buttonMargin: {
    marginVertical: 5,
  },

  shadowActive: utils.shadow('selected', colorsNew.black),
  shadowBase: utils.shadow('default', colorsNew.black),
  pressButton: {
    width: '100%',
  },
})
