import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: paddingNew.sm,
  },
  container: {
    paddingHorizontal: paddingNew.sm,
  },
  description: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.md,
  },
  errorText: {
    ...typography.caption,
    color: colorsNew.redesign.error.text,
  },
  infoContainer: {
    borderTopColor: colorsNew.redesign.other.divider,
    borderTopWidth: 1,
    marginVertical: paddingNew.md,
    paddingTop: paddingNew.sm,
  },
  infoText: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    paddingVertical: paddingNew.xs,
  },
})

export default styles
