import { Platform, StyleSheet } from 'react-native'

import { colors, colorsNew, fonts, fontSizes, padding, paddingNew } from 'src/styles'

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    ...Platform.select({
      web: {
        alignSelf: 'center',
        marginBottom: paddingNew.xll,
        maxWidth: 500,
      },
    }),
  },
  textWrapper: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
  success: {
    backgroundColor: colors.secondary,
  },
  warning: {
    backgroundColor: colors.pink._400,
  },
  message: {
    color: colorsNew.white,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizes.sm,
    marginRight: padding.lg,
    textAlign: 'center',
  },
})
