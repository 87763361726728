import { StyleSheet } from 'react-native'

import { paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.xs,
  },
})

export default styles
