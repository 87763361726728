import React from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  isModalOpen: boolean
}

const BookInterviewConfirmation = ({ onCancel, onConfirm, isModalOpen }: Props) => (
  <CustomModal
    isVisible={isModalOpen}
    buttons={[
      {
        onPress: onCancel,
        title: translate(translations.cancel),
        variant: buttonVariants.outlinedDefault,
      },
      {
        onPress: onConfirm,
        title: translate(translations.confirm),
        variant: buttonVariants.containedDefault,
      },
    ]}
    colorSchema={customModalColorSchema.default}
    description={translate(translations.bookInterviewConfirmationModalDescription)}
    modalPlace="Main/Profile/RequiredTasks/BookInterview/BookInterviewConfirmation/BookInterviewConfirmation"
    title={translate(translations.bookInterviewConfirmationModalTitle)}
    variant={customModalVariants.filled}
  />
)

export default BookInterviewConfirmation
