import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertContainer: {
    marginVertical: paddingNew.sm,
  },
  backgroundContainer: {
    marginVertical: paddingNew.sm,
    paddingVertical: paddingNew.sm,
    position: 'relative',
  },
  backgroundContainerFill: {
    backgroundColor: colorsNew.redesign.primary.shades12p,
    bottom: 0,
    left: -16,
    position: 'absolute',
    right: -16,
    top: 0,
  },
  mainText: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  secondaryText: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  points: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginLeft: paddingNew.xs,
  },
  pointsContainer: {
    marginBottom: 0,
  },
})

export default styles
