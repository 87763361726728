import React, { useContext, useRef, useState } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { ScrollView, Text, View } from 'react-native'
import isEmpty from 'lodash/isEmpty'

import { bankDetailsFields, studentLoanPlanOptions, studentLoanPlans, ukTaxCodeOptions } from './constants'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, ErrorAlert, StatusInfo, errorTypes } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { DotTextRow } from 'src/components/DotTextRow'
import { getUserPayrollDetails } from 'src/store/user/selectors'
import { InitialValuesType } from './types'
import { InputRadioGroupNew } from 'src/components/Form/InputRadioGroup'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import RadioButton from 'src/components/Form/InputRadioGroup/RadioButton/RadioButton'
import styles from './styles'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal from 'src/hooks/useBackModal'
import validationSchema, { validationSchemaCompletedUser } from './validationSchema'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'PayrollDetails'>

const PayrollDetails = ({ navigation, route }: Props) => {
  const [isError, setIsError] = useState(false)
  const userId = useAppSelector(state => state.user.id) as string
  const userPayrollDetails = useAppSelector(getUserPayrollDetails)

  useBugsnagView('RequiredTasks - PayrollDetails')

  const dispatch = useAppDispatch()
  const formikRef = useRef<FormikProps<InitialValuesType>>(null)

  const { mixpanel } = useContext(MixpanelContext)

  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  const initialValues: InitialValuesType = userPayrollDetails

  const closeScreen = useBackModal(navigation, formikRef)

  const handleError = () => setIsError(true)

  const closeTask = () => markTaskAsCompleted(userId, task, false, mixpanel, closeScreen, handleError)

  const submitForm = (values: InitialValuesType, form: FormikHelpers<InitialValuesType>) => {
    bugsnagActionBreadcrumb('submit', { id: userId, values })

    const userValues = {
      ...values,
      invoicingConfig: {
        ...values.invoicingConfig,
        postgraduateLoanFullyRepaid: !values.invoicingConfig.postgraduateLoanFullyRepaid,
        studentLoanFullyRepaid: !values.invoicingConfig.studentLoanFullyRepaid,
      },
    }

    form.setSubmitting(true)
    setIsError(false)
    dispatch(
      saveUser({
        id: userId,
        onError: () => {
          handleError()
          form.setSubmitting(false)
        },
        onSuccess: () => {
          if (isTaskCompleted) return form.setSubmitting(false)
          closeTask()
        },
        stringifyData: true,
        user: userValues,
      }),
    )
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={submitForm}
        validationSchema={isTaskCompleted ? validationSchemaCompletedUser : validationSchema}
      >
        {({ dirty, errors, handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, touched, values }) => (
          <>
            <DotTextRow index={1} numeric text={translate(translations.bankDetails)} textStyle={styles.header} />
            {Object.values(bankDetailsFields).map(field => (
              <TextInputNew
                disabled={isTaskCompleted && field === bankDetailsFields.nationalInsuranceNumber}
                errorMessage={errors[field]}
                isError={!isEmpty(errors[field]) && touched[field]}
                label={translate(translations[field])}
                onChangeText={text => setFieldValue(field, text)}
                onBlur={() => setFieldTouched(field, true)}
                key={field}
                required
                size={TextInputSizeVariants.medium}
                testID={`${field}-input`}
                value={values[field]}
              />
            ))}
            {isTaskCompleted && (
              <>
                {isError && <ErrorAlert hideError={() => setIsError(false)} type={errorTypes.save} />}
                <View style={styles.buttonContainer}>
                  <ButtonNew
                    disabled={!dirty || !isValid}
                    isSubmitting={isSubmitting}
                    onPress={handleSubmit}
                    size={buttonSize.sm}
                    style={styles.submitBankDetailsButton}
                    testID="submit-bank-details"
                    title={translate(translations.saveChanges)}
                    variant={buttonVariants.containedDefault}
                  />
                </View>
              </>
            )}
            <View style={styles.employeeStatementSection}>
              <DotTextRow
                index={2}
                numeric
                text={translate(translations.employeeStatement)}
                textStyle={styles.header}
              />
              <Text style={styles.info}>{translate(translations.employeeStatementDescription)}</Text>
              <View style={styles.radioButtonList}>
                {ukTaxCodeOptions.map(option => (
                  <View style={styles.radioButtonListItemLarge} key={option.value}>
                    <RadioButton
                      disabled={isTaskCompleted}
                      isLarge
                      label={option.label}
                      onPress={() => setFieldValue('invoicingConfig.ukTaxCode', option.value)}
                      selected={values.invoicingConfig.ukTaxCode === option.value}
                    />
                  </View>
                ))}
              </View>
              {values.invoicingConfig.ukTaxCode ? (
                <Text style={styles.additionalInfo}>{translate(translations.employeeStatementInfo)}</Text>
              ) : null}
            </View>
            <View style={styles.section}>
              <DotTextRow index={3} numeric text={translate(translations.studentLoan)} textStyle={styles.header} />
              <Text style={styles.info}>{translate(translations.studentLoanDescription)}</Text>
              <Text style={styles.question}>{translate(translations.studentLoanUnpaid)}</Text>
              <InputRadioGroupNew
                containerStyle={styles.radioButtonWrapper}
                disabled={isTaskCompleted}
                extraVerticalSpacing={false}
                field={{
                  name: 'invoicingConfig.studentLoanFullyRepaid',
                  value: values.invoicingConfig.studentLoanFullyRepaid,
                }}
                form={{ errors, touched, setFieldValue }}
                isHorizontal
                isLarge
              />
              {values.invoicingConfig.studentLoanFullyRepaid ? (
                <>
                  <Text style={styles.question}>{translate(translations.ukUniversityLeft)}</Text>
                  <InputRadioGroupNew
                    containerStyle={styles.radioButtonWrapper}
                    disabled={isTaskCompleted}
                    extraVerticalSpacing={false}
                    field={{
                      name: 'invoicingConfig.studentLoanLeftBeforeApril',
                      value: values.invoicingConfig.studentLoanLeftBeforeApril,
                    }}
                    form={{ errors, touched, setFieldValue }}
                    isHorizontal
                    isLarge
                  />
                  <Text style={styles.helperInfo}>{translate(translations.studentLoanInfo)}</Text>
                  <View style={styles.backgroundContainer}>
                    <View style={styles.backgroundContainerFill} />
                    {studentLoanPlans.map(plan => (
                      <View key={plan.header}>
                        <Text style={styles.subHeader} key={plan.header}>
                          {plan.header}
                        </Text>
                        {plan.options.map(option => (
                          <DotTextRow
                            bulletStyle={styles.listItemBullet}
                            containerStyle={styles.listItemContainer}
                            key={`${plan.header}-${option}`}
                            text={option}
                            textStyle={styles.listItemInfo}
                          />
                        ))}
                      </View>
                    ))}
                  </View>
                  <Text style={styles.question}>{translate(translations.studentLoanPlanLabel)}</Text>
                  {studentLoanPlanOptions.map(option => (
                    <View style={styles.radioButtonListItemSmall} key={option.value}>
                      <RadioButton
                        disabled={isTaskCompleted}
                        isLarge
                        label={option.label}
                        onPress={() => setFieldValue('loanPlanType', option.value)}
                        selected={values.loanPlanType === option.value}
                      />
                    </View>
                  ))}
                  <View style={styles.spacing} />
                </>
              ) : null}
            </View>
            <View style={styles.section}>
              <DotTextRow index={4} numeric text={translate(translations.postgraduateLoan)} textStyle={styles.header} />
              <Text style={styles.info}>{translate(translations.postgraduateLoanDescription)}</Text>
              <Text style={styles.question}>{translate(translations.postgraduateLoanUnpaid)}</Text>
              <InputRadioGroupNew
                containerStyle={styles.radioButtonWrapper}
                disabled={isTaskCompleted}
                extraVerticalSpacing={false}
                field={{
                  name: 'invoicingConfig.postgraduateLoanFullyRepaid',
                  value: values.invoicingConfig.postgraduateLoanFullyRepaid,
                }}
                form={{ errors, touched, setFieldValue }}
                isHorizontal
                isLarge
              />
              {values.invoicingConfig.postgraduateLoanFullyRepaid ? (
                <>
                  <Text style={styles.question}>{translate(translations.ukPostgraduateLeft)}</Text>
                  <InputRadioGroupNew
                    containerStyle={styles.radioButtonWrapper}
                    disabled={isTaskCompleted}
                    extraVerticalSpacing={false}
                    field={{
                      name: 'invoicingConfig.postgraduateLoanLeftBeforeApril',
                      value: values.invoicingConfig.postgraduateLoanLeftBeforeApril,
                    }}
                    form={{ errors, touched, setFieldValue }}
                    isHorizontal
                    isLarge
                  />
                </>
              ) : null}
            </View>
            <DotTextRow index={5} numeric text={translate(translations.submitDetails)} textStyle={styles.header} />
            <Text style={styles.subInfo}>{translate(translations.submitDetailsDescription)}</Text>
            <View style={styles.submitSection}>
              {isError && !isTaskCompleted ? (
                <View style={styles.alertWrapper}>
                  <CompleteTaskError hideError={() => setIsError(false)} />
                </View>
              ) : null}
              <View style={styles.buttonContainer}>
                <ButtonNew
                  disabled={isTaskCompleted || !dirty || !isValid}
                  isLong
                  isSubmitting={isSubmitting}
                  onPress={handleSubmit}
                  testID="submit-payroll-details"
                  title={translate(translations.closeTask)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </View>
          </>
        )}
      </Formik>
    </ScrollView>
  )
}

export default PayrollDetails
