import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#004889'

const InfoIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg height={size} viewBox="0 0 53 53" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.485 27.337c0 11.207-9.084 20.291-20.29 20.291-11.207 0-20.291-9.084-20.291-20.29 0-11.207 9.084-20.291 20.29-20.291 11.207 0 20.291 9.084 20.291 20.29Zm-21.914-1.623a1.623 1.623 0 1 1 3.247 0v8.116a1.623 1.623 0 0 1-3.247 0v-8.116Zm1.623-3.246a1.623 1.623 0 1 0 0-3.247 1.623 1.623 0 0 0 0 3.247Z"
      fill={fill?.length ? fill : defaultFill}
    />
  </Svg>
)

export default InfoIcon
