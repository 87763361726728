import EmptyCactusImage from './images/emptyCactus.svg'
import EmptyArrowImage from './images/emptyArrow.svg'
import EmptyLoupeImage from './images/emptyLoupe.svg'
import EmptyHandsImage from './images/emptyHands.svg'
import EmptyKiteImage from './images/emptyKite.svg'
import EmptyCalendarImage from './images/emptyCalendar.svg'
import EmptyCupImage from './images/emptyCup.svg'
import EmptyStarsImage from './images/emptyStars.svg'

export const placeholderTypes = {
  arrow: 'arrow',
  cactus: 'cactus',
  calendar: 'calendar',
  cup: 'cup',
  hands: 'hands',
  kite: 'kite',
  loupe: 'loupe',
  stars: 'stars',
} as const

export const placeholderImageProps = (placeholder: keyof typeof placeholderTypes) => {
  switch (placeholder) {
    case placeholderTypes.cactus: {
      const imageWidth = 167
      const imageHeight = 141
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyCactusImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }

    case placeholderTypes.loupe: {
      const imageWidth = 119
      const imageHeight = 101
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyLoupeImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }

    case placeholderTypes.arrow: {
      const imageWidth = 137
      const imageHeight = 98
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyArrowImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }

    case placeholderTypes.hands: {
      const imageWidth = 112
      const imageHeight = 149
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyHandsImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }

    case placeholderTypes.kite: {
      const imageWidth = 153
      const imageHeight = 189
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyKiteImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }

    case placeholderTypes.calendar: {
      const imageWidth = 125
      const imageHeight = 108
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyCalendarImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }

    case placeholderTypes.cup: {
      const imageWidth = 81
      const imageHeight = 141
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyCupImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }

    case placeholderTypes.stars: {
      const imageWidth = 139
      const imageHeight = 89
      const maxWidthPercentage = imageWidth / 375

      return {
        Image: EmptyStarsImage,
        maxWidthPercentage: maxWidthPercentage,
        originalImageHeight: imageHeight,
        originalImageWidth: imageWidth,
      }
    }
  }
}
