import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  alert: {
    marginHorizontal: paddingNew.sm,
    marginTop: paddingNew.lg,
  },
  categoryHeader: {
    marginVertical: paddingNew.sm,
    paddingHorizontal: paddingNew.sm,
  },
  page: {
    paddingBottom: paddingNew.md,
  },
  sectionHeader: {
    ...typography.overline,
    color: colorsNew.redesign.text.secondary,
    marginTop: paddingNew.base,
    textAlign: 'center',
  },
})
