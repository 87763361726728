import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    backgroundColor: colorsNew.redesign.background.paper,
    flex: 1,
    paddingVertical: paddingNew.sm,
  },
  containerPadding: {
    paddingHorizontal: paddingNew.sm,
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.xs,
    marginTop: paddingNew.md,
  },
  info: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
  },
  totalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: paddingNew.xs,
  },
  totalTitle: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  totalSubtitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
})
