import gql from 'graphql-tag'

export default gql`
  mutation Login($loginInput: LoginInputDto!) {
    login(loginInput: $loginInput) {
      accessToken
      refreshToken
    }
  }
`

export type LoginInputDto = {
  loginInput: {
    email: string
    password: string
  }
}

export type TLogin = {
  login: {
    accessToken: string
    refreshToken: string
  }
}
