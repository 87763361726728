import React from 'react'

import useNotificationData from 'src/hooks/useNotificationData'
import LoadingIndicator from 'src/components/LoadingIndicator'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import NotificationListRender from './NotificationListRender'

const NotificationsList = () => {
  const { notificationsList, loading, error, refetch } = useNotificationData()

  if (!notificationsList.length && loading) {
    return <LoadingIndicator />
  }

  if (error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  return <NotificationListRender notificationsList={notificationsList} refetch={refetch} isLoading={loading} />
}

export default NotificationsList
