import gql from 'graphql-tag'

import ExpressBookingFields from './Fields'

export default gql`
  query ExpressBooking($id: ID!) {
    expressBooking(id: $id) {
      ...ExpressBookingFields
    }
  }
  ${ExpressBookingFields}
`
