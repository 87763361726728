import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { StyleSheet, Pressable, View, Platform } from 'react-native'
import { TouchableWithoutFeedback } from 'react-native-gesture-handler'
import { colorsNew } from 'src/styles'

import styles from './styles'

const isWeb = Platform.OS === 'web'

interface Props {
  borderRadius?: number
  cardColor?: string
  children: ReactNode
  isPressed?: boolean
  onPress?: () => void
  testID?: string
  withBaseShadow?: boolean
  withVerticalSpacing?: boolean
}
export type ShadowWrapperTypes = Omit<Props, 'children'>

const ShadowWrapper = ({
  borderRadius = 0,
  cardColor = colorsNew.white,
  children,
  isPressed = false,
  onPress,
  testID,
  withBaseShadow = false,
  withVerticalSpacing = true,
}: Props) => {
  const [pressed, setPressed] = useState(isPressed)

  const Component = useMemo(() => {
    if (onPress) {
      if (isWeb) {
        return TouchableWithoutFeedback
      }
      return Pressable
    }
    return View
  }, [onPress])

  useEffect(() => setPressed(isPressed), [isPressed])

  return (
    <View
      style={StyleSheet.flatten([
        withVerticalSpacing && styles.buttonMargin,
        styles.button,
        { borderRadius },
        withBaseShadow && styles.shadowBase,
        pressed && styles.shadowActive,
      ])}
      testID="shadow-wrapper"
    >
      <Component
        onPress={onPress}
        onPressIn={() => onPress && setPressed(true)}
        onPressOut={() => onPress && setPressed(false)}
        style={
          StyleSheet.flatten([
            styles.pressButton,
            onPress &&
              isWeb && {
                cursor: 'pointer',
              },
          ]) as object
        }
        testID={`${testID}-button`}
      >
        <View style={StyleSheet.flatten([{ backgroundColor: cardColor, borderRadius }])} testID={`${testID}-card`}>
          {children}
        </View>
      </Component>
    </View>
  )
}

export default ShadowWrapper
