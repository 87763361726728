import { StyleSheet } from 'react-native'

import { colorsNew, fonts, typography, paddingNew } from 'src/styles'

const title = {
  ...typography.subtitle2,
  color: colorsNew.redesign.text.primary,
  marginRight: paddingNew.sm,
}

export default StyleSheet.create({
  card: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: paddingNew.xxs,
  },
  cardContent: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: paddingNew.sm,
  },
  calendarCard: {
    height: 40,
    width: 40,
  },
  body: {
    alignItems: 'center',
    height: 40,
    position: 'absolute',
    width: 40,
  },
  bodySingleDay: {
    top: 12.5,
  },
  bodyMultiDay: {
    left: -1.6,
    top: 11.5,
  },
  day: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsSemiBold,
    fontSize: 12,
    lineHeight: 14,
    position: 'absolute',
    textAlign: 'center',
    top: 0,
  },
  month: {
    color: colorsNew.redesign.text.primary,
    fontFamily: fonts.poppinsMedium,
    fontSize: 8,
    lineHeight: 8,
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'capitalize',
    top: 12,
  },
  numberOfDays: {
    ...typography.tooltip,
    color: colorsNew.redesign.text.primary,
    letterSpacing: 0.5,
    textAlign: 'center',
  },
  title: title,
  timesheetTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timesheetTitle: {
    ...title,
    flex: 1,
  },
  timesheetStatusContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  timesheetStatus: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    marginRight: 2,
  },
  timesheetStatusIcon: {
    color: colorsNew.redesign.warning.shades50p,
  },
  timesheetStatusIconReported: {
    color: colorsNew.redesign.success.shades50p,
  },
  subtitle: {
    ...typography.body2,
    color: colorsNew.redesign.text.disabled,
    flex: 1,
    marginRight: paddingNew.sm,
  },
  workingTime: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
  detailsContainer: {
    flexDirection: 'row',
  },
})
