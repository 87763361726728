import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import ExpressBookings, { ExpressBookingsRequestTypes } from 'src/graphql/ExpressBookings'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { getJobsOffersSections } from '../Requests/utils'

const useExpressOffersList = () => {
  const userId = useAppSelector(state => state.user.id) as string
  const isConnected = useAppSelector(state => state.app.isConnected)

  const refreshFunction = () => {
    bugsnagActionBreadcrumb('refresh expresses list', { isConnected })

    if (!isConnected) {
      return Promise.resolve()
    }
    return Promise.all([expressBookings.refetch()])
  }

  const expressBookings = useQuery<ExpressBookingsRequestTypes>(ExpressBookings, {
    fetchPolicy: 'cache-and-network',
    variables: {
      include_appointed: false,
      include_available: true,
      only_matching: false,
      student: userId,
    },
  })

  const isLoading = expressBookings.loading

  const expressesList = useMemo(() => expressBookings?.data?.expressBookings?.nodes || [], [expressBookings.data])

  const shortTermOffers = useMemo(() => getJobsOffersSections([...expressesList], userId), [expressesList, userId])

  return { refreshFunction, isLoading, shortTermOffers }
}

export default useExpressOffersList
