import React from 'react'

import ErrorModalScreen from 'src/components/ModalTypes/ErrorModalScreen/ErrorModalScreen'
import PreferencesModalScreen from 'src/components/ModalTypes/PreferencesModalScreen/PreferencesModalScreen'
import WarningServerMaintenanceScreen from 'src/components/ModalTypes/WarningServerMaintenanceScreen/WarningServerMaintenanceScreen'
import WebWrapper from 'src/components/WebWrapper'

const ModalScreen = ({ route }) => {
  const isPreferencesModal = route?.params?.preferencesModal
  const isServerMaintenanceModal = route?.params?.serverMaintenanceModal

  return (
    <WebWrapper>
      {isPreferencesModal ? (
        <PreferencesModalScreen showModal={true} {...route.params} />
      ) : isServerMaintenanceModal ? (
        <WarningServerMaintenanceScreen {...route.params} />
      ) : (
        <ErrorModalScreen showModal={true} {...route?.params} />
      )}
    </WebWrapper>
  )
}

export default ModalScreen
