import React, { useEffect, useState } from 'react'
import { BUILD_MODE, CONFIGCAT_SDK_DEV_KEY, CONFIGCAT_SDK_PROD_KEY } from 'src/utils/webAdapters/DotEnvAdapter'

import { FeatureFlagsContext } from './context'
import { getFeatureFlags } from 'src/utils/featureFlags/getFeatureFlags'
import { initialFeatureFlagState } from './state'
import { type FeatureFlagsT } from './types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useAppState } from 'src/utils/AppStateEvent'

type FeatureFlagsProviderT = {
  children: React.ReactNode
}

const isStaging = BUILD_MODE === 'staging'

const CONFIGCAT_SDK_KEY = __DEV__ || isStaging ? CONFIGCAT_SDK_DEV_KEY : CONFIGCAT_SDK_PROD_KEY

export const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderT) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsT>(initialFeatureFlagState.featureFlags)

  const state = useAppState()

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const userRegionId = useAppSelector(state => state?.user?.region?.id)

  const fetchFeatureFlags = async (regionId: string) => {
    const fetchedFeatureFlags = await getFeatureFlags({
      sdkKey: CONFIGCAT_SDK_KEY,
      regionId: regionId || '',
    })

    setFeatureFlags(fetchedFeatureFlags)
  }

  useEffect(() => {
    if (state.appState === 'active' && userRegionId) {
      fetchFeatureFlags(userRegionId)
    }
  }, [state.appState, userRegionId])

  const config = {
    featureFlags,
    fetchFeatureFlags,
  }

  return <FeatureFlagsContext.Provider value={config}>{children}</FeatureFlagsContext.Provider>
}
