import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { countryCodeSelector, isSESelector } from 'src/store/app/selectors'
import { scheduleTypesList } from 'src/global/constants'
import IntervalInput from '../IntervalInput'
import RadioButton from 'src/components/Form/InputRadioGroup/RadioButton'
import styles from './styles'
import { useAvailabilitySchedule } from '../../availabilityContext/availabilityContext'

const AvailabilityForm = () => {
  const countryCode = useSelector(countryCodeSelector)
  const isSE = useSelector(isSESelector)
  const { state, selectedDay, updateDayScheduleType, isSubmitting } = useAvailabilitySchedule()

  const scheduleInfo = state.availabilityList[selectedDay]?.scheduleType
  const isHourly = scheduleInfo?.timeScheduleType === 'hourly'

  return (
    <>
      <View style={StyleSheet.flatten([styles.radioButtonsContainer, isSE && styles.radioButtonsContainerSE])}>
        {scheduleTypesList[countryCode].map(type => (
          <RadioButton
            disabled={isSubmitting}
            key={type.label}
            label={type.label}
            onPress={() => updateDayScheduleType(type.value)}
            selected={scheduleInfo?.timeScheduleType === type.value}
            testID={type.value}
          />
        ))}
      </View>

      {isHourly ? <IntervalInput /> : null}
    </>
  )
}

export default AvailabilityForm
