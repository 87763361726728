import React from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import CustomModal from 'src/components/Modal/CustomModal'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  closeWarningModal: () => void
  isVisible: boolean
  workPermitExpiresOn: string
}

const WorkPermitWarningModal = ({ closeWarningModal, isVisible, workPermitExpiresOn }: Props) => (
  <CustomModal
    buttons={[
      {
        onPress: closeWarningModal,
        testID: 'close-work-permit-warning-modal-button',
        title: translate(translations.close),
        variant: buttonVariants.outlinedDefault,
      },
    ]}
    colorSchema={customModalColorSchema.warning}
    description={translate(translations.alertWorkPermitInvalid, { workPermitExpiresOn })}
    isVisible={isVisible}
    modalPlace="Main/Jobs/Details/components/WorkPermitWarningModal/WorkPermitWarningModal"
    onClose={closeWarningModal}
    testID="work-permit-warning-modal-button"
    title={translate(translations.alertWorkPermitInvalidTitle)}
    variant={customModalVariants.standard}
  />
)

export default WorkPermitWarningModal
