import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'

const rate = Yup.number().min(1, translate(translations.blank))

const tutorFreetext = Yup.string().required(translate(translations.required))

export const feedbackValidation = (isPreschool: boolean) =>
  Yup.object().shape({
    comment: Yup.string(),
    continueWorking: Yup.mixed().oneOf(['Yes', 'No'], translate(translations.blank)),
    introduction: rate,
    lesson: rate,
    structure: rate,
    treatment: isPreschool ? Yup.number() : rate,
  })

export const feedbackTutorValidation = () =>
  Yup.object().shape({
    attendance: tutorFreetext,
    comments: Yup.string(),
    deliveryMethod: tutorFreetext,
    email: Yup.string().when('safeguardingConcerns', { is: true, then: tutorFreetext }),
    engagementMethod: tutorFreetext,
    resourcesUsed: tutorFreetext,
    safeguardingConcerns: Yup.boolean(),
    subjects: tutorFreetext,
  })
