import React from 'react'
import { FormikProps } from 'formik'
import { StyleSheet, Text, View } from 'react-native'
import isEmpty from 'lodash/isEmpty'

import { ErrorAlert, ErrorType } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { FormValues } from './ReferenceForm'
import { shortDatePickerVariants } from 'src/components/Form/DatePickerShort/types'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import DatePickerShort from 'src/components/Form/DatePickerShort/DatePickerShort'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'

type Props = {
  closeFormError: () => void
  form: FormikProps<FormValues>
  formError: ErrorType | null
  isDeleting: boolean
  isDisabled: boolean
  onDelete: () => void
}

const FormBody = ({ closeFormError, form, formError, isDisabled, isDeleting, onDelete }: Props) => {
  const {
    dirty,
    errors,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    setValues,
    touched,
    values,
  } = form

  const handleIsOngoingPress = () => {
    setValues(
      {
        ...values,
        endedOn: '',
        isOngoing: !values.isOngoing,
        reasonForLeaving: '',
      },
      true,
    )
  }

  return (
    <>
      <Text style={styles.label}>{translate(translations.refereeDetails)} </Text>
      <TextInputNew
        disabled={isDisabled}
        errorMessage={errors.name}
        isError={!isEmpty(errors.name) && touched.name}
        label={translate(translations.refereeName)}
        onBlur={() => setFieldTouched(`name`, true)}
        onChangeText={text => setFieldValue(`name`, text)}
        required
        size={TextInputSizeVariants.medium}
        testID="name-input"
        value={values.name}
      />
      <TextInputNew
        disabled={isDisabled}
        errorMessage={errors.email}
        isError={!isEmpty(errors.email) && touched.email}
        keyboardType="email-address"
        label={translate(translations.refereeEmail)}
        onBlur={() => setFieldTouched(`email`, true)}
        onChangeText={text => setFieldValue(`email`, text)}
        required
        size={TextInputSizeVariants.medium}
        testID="email-input"
        value={values.email}
      />
      <TextInputNew
        disabled={isDisabled}
        errorMessage={errors.phone}
        isError={!isEmpty(errors.phone) && touched.phone}
        keyboardType="numeric"
        label={translate(translations.refereePhone)}
        onBlur={() => setFieldTouched(`phone`, true)}
        onChangeText={text => setFieldValue(`phone`, text)}
        size={TextInputSizeVariants.medium}
        testID="phone-input"
        value={values.phone}
      />
      <TextInputNew
        disabled={isDisabled}
        errorMessage={errors.occupation}
        isError={!isEmpty(errors.occupation) && touched.occupation}
        label={translate(translations.referenceOccupation)}
        onBlur={() => setFieldTouched(`occupation`, true)}
        onChangeText={text => setFieldValue(`occupation`, text)}
        required
        size={TextInputSizeVariants.medium}
        testID="occupation-input"
        value={values.occupation}
      />
      <TextInputNew
        disabled={isDisabled}
        errorMessage={errors.employer}
        isError={!isEmpty(errors.employer) && touched.employer}
        label={translate(translations.referenceEmployer)}
        onBlur={() => setFieldTouched(`employer`, true)}
        onChangeText={text => setFieldValue(`employer`, text)}
        required
        size={TextInputSizeVariants.medium}
        testID="employer-input"
        value={values.employer}
      />
      <Text style={StyleSheet.flatten([styles.label, styles.labelWithSpacing])}>
        {translate(translations.referenceJobTitleLabel)}
      </Text>
      <TextInputNew
        disabled={isDisabled}
        errorMessage={errors.position}
        isError={!isEmpty(errors.position) && touched.position}
        label={translate(translations.referenceJobTitle)}
        onBlur={() => setFieldTouched(`position`, true)}
        onChangeText={text => setFieldValue(`position`, text)}
        required
        size={TextInputSizeVariants.medium}
        testID="position-input"
        value={values.position}
      />
      <DatePickerShort
        date={values.startedOn}
        disabled={isDisabled}
        errorMessage={errors.startedOn}
        isError={!isEmpty(errors.startedOn) && touched.startedOn}
        numberOfYearsInFuture={0}
        numberOfYearsInPast={51}
        onlyPastDateAllowed
        required
        setFieldTouched={() => setFieldTouched('startedOn', true)}
        setFieldValue={date => {
          setFieldValue('startedOn', date)
          setFieldTouched('startedOn', true, false)
        }}
        testID="startedOn-input"
        variant={shortDatePickerVariants.startDate}
        zIndex={2}
      />
      <DatePickerShort
        date={values.endedOn}
        disabled={isDisabled || !!values.isOngoing}
        errorMessage={errors.endedOn}
        isError={!isEmpty(errors.endedOn) && touched.endedOn}
        numberOfYearsInFuture={0}
        numberOfYearsInPast={51}
        onlyPastDateAllowed
        required
        setFieldTouched={() => setFieldTouched('endedOn', true)}
        setFieldValue={date => {
          setFieldValue('endedOn', date)
          setFieldTouched('endedOn', true, false)
        }}
        testID="endedOn-input"
        variant={shortDatePickerVariants.endDate}
        zIndex={1}
      />
      <View style={styles.checkboxContainer}>
        <Checkbox
          disabled={isDisabled}
          label={translate(translations.endDateOngoing)}
          onPress={handleIsOngoingPress}
          selected={!!values.isOngoing}
          testID="isOngoing-checkbox"
        />
      </View>
      <TextInputNew
        disabled={isDisabled || !!values.isOngoing}
        errorMessage={errors.reasonForLeaving}
        isError={!isEmpty(errors.reasonForLeaving) && touched.reasonForLeaving}
        label={translate(translations.enterLeavingReason)}
        onBlur={() => setFieldTouched(`reasonForLeaving`, true)}
        onChangeText={text => setFieldValue(`reasonForLeaving`, text)}
        required
        size={TextInputSizeVariants.medium}
        testID="reasonForLeaving-input"
        value={values.reasonForLeaving}
      />
      <View style={styles.buttonsContainer}>
        {formError ? <ErrorAlert hideError={closeFormError} type={formError} /> : null}
        <ButtonNew
          disabled={!isValid || !dirty || isDeleting || isDisabled}
          isSubmitting={isSubmitting}
          onPress={handleSubmit}
          size={buttonSize.lg}
          testID="submit-reference-form"
          title={translate(translations.saveChanges)}
          variant={buttonVariants.containedDefault}
        />
        <View style={styles.buttonsDivider} />
        <ButtonNew
          disabled={isSubmitting || isDisabled}
          isSubmitting={isDeleting}
          onPress={onDelete}
          size={buttonSize.lg}
          title={translate(translations.experienceDelete)}
          variant={buttonVariants.outlinedError}
        />
      </View>
    </>
  )
}

export default FormBody
