import React, { ReactElement } from 'react'
import { Platform, TouchableOpacity, ViewStyle } from 'react-native'

import { AnimatedIcon } from 'src/components/Icon'
import styles from './styles'
import IconComponent from 'src/components/Icon'

type Props = {
  backButton?: boolean
  iconColor: string
  iconName: (({ size, fill }: { size: number; fill: string }) => Element | ReactElement) | string
  iconStyle?: object
  onPress: () => void
  size?: number
  style?: ViewStyle
  testID?: string
  title?: string
}

type GetStylesType = {
  backButton?: boolean
  iconColor: string
  iconStyle?: object
  isOldIcon: boolean
  style?: ViewStyle
  title?: string
}

const DEFAULT_ICON_SIZE = 18
const isWeb = Platform.OS === 'web'
const isAndroid = Platform.OS === 'android'

const getStyles = ({ isOldIcon, backButton, iconColor, style, iconStyle, title }: GetStylesType) => {
  const componentsStyles = { wrapper: {}, icon: {} }
  if (isOldIcon) {
    if (isAndroid) {
      componentsStyles.wrapper = style || styles.container
      componentsStyles.icon = [styles.spacing, backButton && styles.androidIcon, { color: iconColor }]
    } else {
      componentsStyles.wrapper = style || [!title && styles.container, !backButton && styles.spacing]
      componentsStyles.icon = [backButton && styles.icon, { color: iconColor }]
    }
  } else {
    componentsStyles.wrapper = style || {}
    componentsStyles.icon = iconStyle || {}
  }

  return componentsStyles
}

const HeaderButton = ({
  backButton,
  onPress,
  iconName: Icon,
  iconColor,
  style,
  size,
  iconStyle = {},
  title,
}: Props) => {
  const isOldIcon = typeof Icon === 'string'
  let defaultSize = size || DEFAULT_ICON_SIZE
  const componentStyles = getStyles({ isOldIcon, backButton, iconColor, style, iconStyle, title })

  if (isWeb && isOldIcon) {
    defaultSize = Icon === 'check-mark' ? 20 : 34
  }

  const RedesignedIcon = () => {
    const iconProps = { size: defaultSize, style: componentStyles.icon }

    if (isOldIcon) {
      const oldIconProps = { ...iconProps, name: Icon }

      if (isWeb) {
        return <IconComponent {...oldIconProps} />
      }
      return <AnimatedIcon {...oldIconProps} />
    }

    return <Icon fill={iconColor} {...iconProps} />
  }

  return (
    <TouchableOpacity onPress={onPress} style={componentStyles.wrapper} testID="headerButton">
      <RedesignedIcon />
    </TouchableOpacity>
  )
}

export default React.memo(HeaderButton)
