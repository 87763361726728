import React from 'react'
import { View, Text } from 'react-native'

import ModalWrapper from './ModalWrapper'
import ShadowWrapper from 'src/components/ShadowWrapper'
import styles from './styles'

export type Actions = {
  label: string
  onPress: () => void
}

type Props = {
  actions: Actions[] | null
  isVisible: boolean
  onClose: () => void
}

const ActionsModal = ({ actions, isVisible, onClose }: Props) => (
  <ModalWrapper isVisible={isVisible} onClose={onClose}>
    <View style={styles.modalContent}>
      {actions?.map((action, index) => (
        <ShadowWrapper onPress={action.onPress} borderRadius={15} key={index} testID={`action-${index}`}>
          <View style={styles.contactButton}>
            <Text style={styles.buttonText}>{action.label}</Text>
          </View>
        </ShadowWrapper>
      ))}
    </View>
  </ModalWrapper>
)

export default ActionsModal
