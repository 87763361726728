import translations, { translate } from 'src/utils/translations/translations'

export const getDesiredWorkloadItemsGb = () => [
  {
    id: 'long_term',
    title: translate(translations.noSchedule),
  },
  {
    id: 'permanent',
    title: translate(translations.longAssignment),
  },
]

export const getDesiredWorkloadItemsSv = () => [
  {
    id: 'long_term',
    title: translate(translations.longAssignment),
  },
]
