import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import AsyncStorage from '@react-native-async-storage/async-storage'
import omit from 'lodash/omit'

import app, { initialState as appInitialState } from './app'
import user from './user'
import inbox from './inbox'

const persistConfig = {
  storage: AsyncStorage,
  stateReconciler: hardSet,
}

const appReducer = combineReducers({
  user: persistReducer({ ...persistConfig, key: 'user' }, user),
  app: persistReducer({ ...persistConfig, key: 'app' }, app),
  inbox: persistReducer({ ...persistConfig, key: 'inbox', whitelist: ['readNotifications'] }, inbox),
})

export default (state: any, action: any) => {
  if (action.type === 'LOGOUT_USER') {
    state = {
      user: { authToken: null },
      app: {
        ...state.app,
        ...omit(appInitialState, ['isConnected', 'countryCode', 'hiddenBookingAlertIds']),
        dbUrl: undefined,
      },
    }
  }

  return appReducer(state, action)
}
