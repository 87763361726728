import { Platform } from 'react-native'
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache } from '@apollo/client/cache'
import { RefObject } from 'react'

import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import translations, { translate } from 'src/utils/translations/translations'
import navigationService from 'src/utils/navigationService'
import { API_URL } from 'src/utils/ApolloClient'
import { appVersion } from 'src/utils/apiConfig'

let httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
})

export const loginMutation = class {
  private formRef: RefObject<any>

  constructor(formRef: RefObject<any>) {
    this.formRef = formRef
  }

  createClientWithoutToken(url = API_URL) {
    const errorLinkWithoutToken = onError(({ graphQLErrors, networkError }) => {
      if (networkError && networkError?.message === 'Network request failed') {
        bugsnagActionBreadcrumb('errorLink on ApolloClient - no network (1)')
        return
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(error => {
          const { message, locations, path } = error

          if (!message) {
            return
          }

          if (message === 'Password is incorrect' && this?.formRef?.current) {
            this.formRef.current.setErrors({ errorMessage: translate(translations.loginPasswordError) })
          } else if (message.includes('No user found') && this?.formRef?.current) {
            this.formRef.current.setErrors({ errorMessage: translate(translations.loginEmailError) })
          } else {
            navigationService.navigate('Modal', {
              error: `Login failed with ${message}`,
              description: translate(translations.reportError),
              title: translate(translations.somethingWentWrong),
            })
            if (__DEV__) {
              console.error(
                `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(
                  locations,
                )}, Path: ${path}`,
              )
            }
          }
        })
      }
    })

    const authLink = () =>
      setContext((operation, { headers }) => {
        return {
          headers: {
            ...headers,
            'Student-Origin': `MOBILE_APP_${Platform.OS.toUpperCase()}`,
          },
        }
      })

    httpLink = createHttpLink({
      uri: `${url}/graphql?appVersion=${appVersion}`,
    })

    const createLink = () => ApolloLink.from([authLink(), errorLinkWithoutToken, httpLink])

    return new ApolloClient({
      connectToDevTools: true,
      link: createLink(),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all',
        },
      },
    })
  }
}
