import React from 'react'

import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import translations, { translate } from 'src/utils/translations/translations'
import CustomModal from 'src/components/Modal/CustomModal'
import { ButtonNewProps } from 'src/components/Buttons/ButtonNew/ButtonNew'

type Props = {
  actions: () => ButtonNewProps[]
  isVisible: boolean
  onClose?: () => void
}

const ModalWrapper = ({ isVisible, actions, onClose }: Props) => (
  <CustomModal
    buttons={actions?.()}
    colorSchema={customModalColorSchema.default}
    isVisible={isVisible}
    onClose={onClose}
    title={translate(translations.contactUs)}
    variant={customModalVariants.standard}
  />
)

export default ModalWrapper
