import React, { useMemo, useRef, useState, useCallback } from 'react'
import moment from 'moment'
import { Calendar } from 'react-native-calendars'
import { Direction } from 'react-native-calendars/src/types'
import { TextInput } from 'react-native-paper'
import { View, Text, TouchableOpacity, StyleSheet, TextInput as TextInputRef } from 'react-native'

import { ChevronLeftIcon, ChevronRightIcon, CalendarMonthIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import Day from './Day'
import formatter from 'src/utils/formatter'
import styles, { themeStyle } from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import { useFocusEffect } from '@react-navigation/native'

type Props = {
  disabled?: boolean
  isError: boolean
  label: string
  maxDate?: string
  minDate?: string
  setValue: (date: string) => void
  testID?: string
  value?: string
  formattedValue?: string
}

const InputDateNew = ({
  disabled,
  formattedValue,
  isError,
  label,
  maxDate,
  minDate,
  setValue,
  testID,
  value,
}: Props) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [pickerDate, setPickerDate] = useState(value || formatter.apiFormat())
  const [selectedMonthDay, setSelectedMonthDay] = useState(value || formatter.apiFormat())

  useFocusEffect(
    useCallback(() => {
      const currentDate = formatter.apiFormat()
      setPickerDate(value || currentDate)
      setSelectedMonthDay(value || currentDate)
    }, [value]),
  )

  const isCurrentMonth = useMemo(() => moment().isSame(selectedMonthDay, 'month'), [selectedMonthDay])

  const inputRef = useRef<TextInputRef>(null)

  const renderArrow = (direction: Direction) =>
    direction === 'left' ? (
      <ChevronLeftIcon
        fill={isCurrentMonth ? colorsNew.redesign.action.disabled : colorsNew.redesign.action.active}
        size={24}
      />
    ) : (
      <ChevronRightIcon size={24} fill={colorsNew.redesign.action.active} />
    )

  const dateElement = (
    // eslint-disable-next-line react/no-unstable-nested-components
    <TextInput.Icon icon={() => <CalendarMonthIcon size={24} fill={colorsNew.redesign.text.secondary} />} />
  )

  return (
    <>
      <TextInput
        activeOutlineColor={colorsNew.redesign.primary.dark}
        caretHidden={true}
        dense={true}
        editable={!disabled}
        label={label}
        left={value ? dateElement : null}
        mode="outlined"
        onFocus={() => setShowDatePicker(true)}
        outlineColor={isError ? colorsNew.redesign.error.main : colorsNew.redesign.other.outlinedBorder}
        ref={inputRef}
        showSoftInputOnFocus={false}
        style={styles.textInput}
        testID={testID}
        value={formattedValue || value}
      />
      <NewModalWrapper
        backdropColor="white"
        backdropOpacity={0.2}
        isVisible={showDatePicker}
        onBackButtonPress={() => setShowDatePicker(false)}
        onBackdropPress={() => setShowDatePicker(false)}
        onModalHide={() => inputRef.current?.blur()}
        onModalWillShow={() => {
          setSelectedMonthDay(pickerDate)
          inputRef.current?.blur()
        }}
      >
        <View style={styles.modalContainer}>
          <Calendar
            current={pickerDate}
            dayComponent={Day}
            disableArrowLeft={isCurrentMonth}
            firstDay={1}
            hideExtraDays={true}
            markedDates={{ [pickerDate]: { selected: true } }}
            minDate={minDate || formatter.apiFormat()}
            maxDate={maxDate}
            numberOfDays={5}
            onDayPress={day => setPickerDate(day.dateString)}
            onMonthChange={monthDate => setSelectedMonthDay(monthDate.dateString)}
            renderArrow={renderArrow}
            testID="availability"
            theme={themeStyle}
          />
          <View style={styles.modalButton}>
            <TouchableOpacity onPress={() => setShowDatePicker(false)}>
              <Text style={styles.buttonLabel}>{translate(translations.cancel)}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setShowDatePicker(false)
                setValue(pickerDate)
              }}
              style={styles.buttonConfirm}
            >
              <Text style={StyleSheet.flatten([styles.buttonLabel, styles.buttonLabelConfirm])}>
                {translate(translations.OK)}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </NewModalWrapper>
    </>
  )
}

export default InputDateNew
