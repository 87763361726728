import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography, utils } from 'src/styles'

export const ICON_SMALL = 16
export const ICON_LARGE = 20
const CONTAINER_BORDER_RADIUS = 50

const styles = (disabled: boolean | undefined, isEnabled: boolean, isSecondary: boolean, isSmall: boolean) => {
  const filterChipsStyles = StyleSheet.create({
    container: {
      alignItems: 'center',
      backgroundColor: colorsNew.redesign.primary.main,
      borderRadius: CONTAINER_BORDER_RADIUS,
      justifyContent: 'center',
      margin: paddingNew.xxs,
      padding: paddingNew.xs,
    },
    disabled: {
      borderColor: colorsNew.redesign.action.disabled,
    },
    disabledText: {
      color: colorsNew.redesign.text.disabled,
    },
    outlineStyle: {
      backgroundColor: colorsNew.redesign.background.paper,
      borderColor: colorsNew.redesign.primary.contrast,
      borderWidth: 1,
    },
    secondaryColor: {
      backgroundColor: colorsNew.redesign.secondary.main,
    },
    smallChip: {
      padding: paddingNew.xxs,
    },
    secondaryText: {
      color: colorsNew.redesign.secondary.contrast,
    },
    smallText: {
      ...typography.chipTextSmall,
    },
    text: {
      ...typography.chipTextLarge,
      color: colorsNew.redesign.primary.contrast,
      paddingHorizontal: paddingNew.base / 4,
    },
  })
  return StyleSheet.create({
    container: StyleSheet.flatten([
      filterChipsStyles.container,
      !isEnabled && utils.shadow('button', colorsNew.black),
      isSmall && filterChipsStyles.smallChip,
      isSecondary && filterChipsStyles.secondaryColor,
      (isEnabled || disabled) && filterChipsStyles.outlineStyle,
      disabled && filterChipsStyles.disabled,
    ]),
    text: StyleSheet.flatten([
      filterChipsStyles.text,
      isSecondary && !isEnabled && filterChipsStyles.secondaryText,
      disabled && filterChipsStyles.disabledText,
      isSmall && filterChipsStyles.smallText,
    ]),
  })
}

export default styles
