import { MutableRefObject, useContext, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import { CustomModalTProps } from 'src/components/Modal/CustomModal'
import { DisplayModalContext } from 'src/context/DispalyModalContext'
import translations, { translate } from 'src/utils/translations/translations'

export type RefType = { dirty: boolean }

const isWeb = Platform.OS === 'web'

const useBackModal = (navigation: StackNavigationProp<any>, isFormModifiedRef: MutableRefObject<RefType>) => {
  const { goBack } = navigation
  const [forceClose, setForceClose] = useState(false)
  const modalContext = useContext(DisplayModalContext)

  useEffect(() => {
    navigation.addListener('beforeRemove', e => {
      if (!isFormModifiedRef?.current?.dirty || forceClose) {
        modalContext.setDisplay(false)
        if (navigation.canGoBack()) {
          navigation.dispatch(e.data.action)
        }
        return
      }

      e.preventDefault()

      const params: CustomModalTProps = {
        buttons: [
          {
            onPress: () => modalContext.setDisplay(false),
            title: translate(translations.quitFormNO),
            variant: buttonVariants.outlinedDefault,
          },
          {
            onPress: () => {
              modalContext.setDisplay(false)
              navigation.dispatch(e.data.action)
            },
            title: translate(translations.quitFormOK),
            variant: buttonVariants.outlinedError,
          },
        ],
        colorSchema: customModalColorSchema.error,
        description: translate(translations.quitFormTitleDescription),
        title: translate(translations.quitFormTitle),
        variant: customModalVariants.standard,
      }

      modalContext.setParams({
        ...params,
        onClose: isWeb ? () => modalContext.setDisplay(false) : undefined,
      })
      modalContext.setDisplay(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormModifiedRef, forceClose, navigation])

  useEffect(() => {
    if (forceClose) {
      goBack()
    }
  }, [goBack, forceClose])

  return () => {
    setForceClose(true)
    modalContext.setDisplay(false)
  }
}

export default useBackModal
