import { StyleSheet, Platform } from 'react-native'

import { fonts, fontSizesNew, colorsNew, paddingNew } from 'src/styles'

const LINE_HEIGHT = 20
export const TEXT_INPUT_PADDING = 7
export const MAX_HEIGHT = 6 * LINE_HEIGHT + 2 * TEXT_INPUT_PADDING
export const BASE_HEIGHT = LINE_HEIGHT + 2 * TEXT_INPUT_PADDING

const styles = StyleSheet.create({
  warningContainer: {
    backgroundColor: colorsNew.warningLight,
    borderColor: colorsNew.warning,
    borderWidth: 2,
    borderRadius: 4,
    flexDirection: 'row',
    margin: paddingNew.xs,
    padding: paddingNew.xs,
  },
  warningText: {
    color: colorsNew.warning,
    flex: 1,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizesNew.xxs.size,
  },
  warningIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: paddingNew.xxs,
  },
  smsInfo: {
    color: colorsNew.white,
    fontSize: fontSizesNew.xxxxs.size,
    paddingBottom: paddingNew.xs,
    paddingRight: paddingNew.xs,
    textAlign: 'right',
    fontFamily: fonts.poppinsRegular,
  },
  textInputContainer: {
    backgroundColor: colorsNew.background._200,
    borderRadius: 20,
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: paddingNew.xs,
    marginRight: paddingNew.xs,
    marginTop: paddingNew.xxs,
    maxHeight: MAX_HEIGHT,
    minHeight: BASE_HEIGHT,
    overflow: 'hidden',
    paddingBottom: TEXT_INPUT_PADDING,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: TEXT_INPUT_PADDING,
    position: 'relative',
  },
  textInput: {
    alignItems: 'flex-start',
    backgroundColor: colorsNew.transparent,
    color: colorsNew.black,
    borderColor: colorsNew.transparent,
    borderRadius: 0,
    borderWidth: 0,
    fontSize: fontSizesNew.xxs.size,
    fontFamily: fonts.poppinsRegular,
    justifyContent: 'flex-start',
    lineHeight: LINE_HEIGHT,
    margin: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    textAlignVertical: 'top',
    width: '100%',
    ...Platform.select({
      web: {
        outlineWidth: 0,
        outlineColor: colorsNew.transparent,
        outlineOffset: 0,
        height: MAX_HEIGHT,
      },
    }),
  },
  sendIconContainer: {
    alignItems: 'center',
    backgroundColor: colorsNew.brand.secondary,
    borderRadius: BASE_HEIGHT / 2,
    height: BASE_HEIGHT,
    justifyContent: 'center',
    marginRight: paddingNew.xs,
    width: BASE_HEIGHT,
  },
  sendIcon: {
    transform: [{ rotate: '90deg' }],
    color: colorsNew.white,
  },
  inputToolbarStyles: {
    marginTop: paddingNew.xxs,
    paddingTop: TEXT_INPUT_PADDING,
    position: 'relative',
  },
  dayWrapper: {
    marginVertical: paddingNew.xs,
  },
  dayText: {
    color: colorsNew.grey._700,
    fontFamily: fonts.poppinsRegular,
    fontSize: fontSizesNew.xxxs.size,
    fontWeight: '300',
  },
  placeholderText: {
    color: colorsNew.brand.secondaryDark,
    fontFamily: fonts.poppinsRegular,
    fontSize: fontSizesNew.sm.size,
    marginVertical: paddingNew.xs,
    maxWidth: 260,
    textAlign: 'center',
  },
  emptyChatContainer: {
    flex: 1,
    marginTop: 0,
    paddingTop: paddingNew.lg,
    transform: [
      {
        scaleY: -1,
      },
    ],
  },
})

export default styles
