import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { applicationStatus } from '../applicationTypes'
import {
  formatWorkPermitExpiredDate,
  getJobAlertDataBasedOnCompliance,
  getScreenTitle,
  isUserWorkPermitValid,
  lockedOffers,
  pendingApplications,
} from '../utils'
import {
  JobsNavigatorScreensProps,
  MenuNavigatorScreensProps,
  NotificationsScreensProps,
} from 'src/utils/types/navigationTypes'
import { ComplianceStatusContext } from 'src/context/ComplianceStatusContext'
import { MixpanelContext } from 'src/context/MixpanelContext'
import AcceptOverlay from './AcceptOverlay'
import AlertMessage from '../../components/AlertMessage'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import JobDetails from 'src/scenes/Main/Jobs/Details/components/JobDetails'
import Loader from 'src/scenes/Main/Jobs/Details/components/Loader'
import LoadingIndicator from 'src/components/LoadingIndicator'
import useOrderDetails from 'src/hooks/useOrderData'
import useTabBarOverlay from 'src/hooks/useTabBarOverlay'
import WithdrawInterestOverlay from './WithdrawInterestOverlay'
import { getMixpanelOrderDay } from './utils'
import { getUserWorkPermitInfo } from 'src/store/user/selectors'
import { isSESelector } from 'src/store/app/selectors'
import { useAppSelector } from 'src/hooks/reduxHooks'

type OrderNavigationType =
  | JobsNavigatorScreensProps<'Order'>
  | MenuNavigatorScreensProps<'OrderMenu'>
  | NotificationsScreensProps<'OrderNotification'>

const Order = ({ route }: OrderNavigationType) => {
  const id = route?.params?.id as string

  const {
    appointedBy,
    applicationId,
    bookingCalendarData,
    bookingsList,
    endDate,
    error,
    jobConfirmationData,
    loading,
    orderAlertData,
    orderDetails,
    orderStatus,
    refetch,
  } = useOrderDetails(id)

  const { isUserCompliant } = useContext(ComplianceStatusContext)
  const { mixpanel } = useContext(MixpanelContext)
  const isSE = useAppSelector(isSESelector)
  const { workPermitExpiresOn, workPermitRequired } = useAppSelector(getUserWorkPermitInfo)

  const showAcceptOverlay = orderStatus && pendingApplications.includes(orderStatus)
  const showWithdrawInterestOverlay = orderStatus === applicationStatus.userApplied
  const showOverlay = showAcceptOverlay || showWithdrawInterestOverlay
  const isNavigationBlocked = orderStatus && lockedOffers.includes(orderStatus)
  const showMask =
    orderStatus === applicationStatus.offerCancelled || orderStatus === applicationStatus.offerAppointToOtherTeacher

  const screenTitle = orderStatus ? getScreenTitle(orderStatus) : ''

  const { isCustomOverlay, goBack } = useTabBarOverlay(showOverlay, screenTitle, isNavigationBlocked)

  const [isUpdating, setIsUpdating] = useState(false)
  const [showJobConfirmation, setShowJobConfirmation] = useState(false)

  const workPermitExpiresOnFormatted = formatWorkPermitExpiredDate(workPermitExpiresOn)

  const isUserWorkPermitInvalid = isSE
    ? false
    : !isUserWorkPermitValid({
        jobLatestDate: endDate,
        workPermitExpiresOn,
        workPermitRequired,
      })

  const userComplianceAlertData = useMemo(
    () =>
      getJobAlertDataBasedOnCompliance({
        isUserCompliant,
        isUserWorkPermitInvalid,
        workPermitExpiresOn: workPermitExpiresOnFormatted,
      }),
    [isUserCompliant, isUserWorkPermitInvalid, workPermitExpiresOnFormatted],
  )

  const onOpen = () =>
    mixpanel?.track('Order Details', {
      appointedBy,
      day: getMixpanelOrderDay(bookingsList),
      orderId: id,
      status: orderStatus,
    })

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  if (error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <>
      {isUpdating ? <Loader /> : null}

      <JobDetails
        {...orderDetails}
        onOpen={onOpen}
        bookingsList={bookingsList}
        showMask={showMask}
        refetch={refetch}
        loading={loading}
      >
        {!isUpdating ? (
          <AlertMessage
            alertData={orderAlertData}
            offerStatus={orderStatus}
            showJobConfirmation={showJobConfirmation}
            userComplianceAlertData={userComplianceAlertData}
          />
        ) : null}
      </JobDetails>

      {isCustomOverlay && showAcceptOverlay ? (
        <AcceptOverlay
          bookingCalendarData={bookingCalendarData}
          goBack={goBack}
          isUpdating={isUpdating}
          isUserWorkPermitInvalid={isUserWorkPermitInvalid}
          jobConfirmationData={jobConfirmationData}
          orderId={id}
          orderStatus={orderStatus}
          refetch={refetch}
          setIsUpdating={setIsUpdating}
          setShowJobConfirmation={() => setShowJobConfirmation(true)}
          workPermitExpiresOn={workPermitExpiresOnFormatted}
        />
      ) : null}

      {isCustomOverlay && showWithdrawInterestOverlay ? (
        <WithdrawInterestOverlay
          applicationId={applicationId}
          goBack={goBack}
          isUpdating={isUpdating}
          orderId={id}
          setIsUpdating={setIsUpdating}
        />
      ) : null}
    </>
  )
}

export default Order
