import { StyleSheet, View } from 'react-native'
import React from 'react'

import { isMaestro } from 'src/utils/isMaestro'
import Logo from 'src/icons/logo'
import styles from './styles'

const LogoHeader = () => (
  <View
    accessible={!isMaestro()}
    testID="logoHeader"
    style={StyleSheet.flatten([styles.logoContainer, global.isSM && styles.logoContainerSmall])}
  >
    <Logo />
  </View>
)

export default LogoHeader
