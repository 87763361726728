import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'

export const getValidationSchema = () =>
  Yup.object().shape({
    kcsPolicyStatus: Yup.bool().oneOf([true]).required(translate(translations.blank)),
    safeguardingPolicyStatus: Yup.bool().oneOf([true]).required(translate(translations.blank)),
    trainingStatus: Yup.bool().oneOf([true]).required(translate(translations.blank)),
    trainingDate: Yup.string()
      .nullable()
      .when('trainingStatus', {
        is: true,
        then: Yup.string().required(translate(translations.blank)),
      }),
  })

export default getValidationSchema
