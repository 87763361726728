import React from 'react'
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native'

import { colors, colorsNew } from 'src/styles'

const Loading = () => (
  <View style={styles.activityIndicator}>
    <ActivityIndicator color={colorsNew.redesign.text.primary} size="large" />
  </View>
)

const styles = StyleSheet.create({
  activityIndicator:
    Platform.OS === 'web'
      ? {
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          position: 'absolute',
          width: '100%',
        }
      : {
          ...StyleSheet.absoluteFillObject,
          backgroundColor: colors.white,
          justifyContent: 'center',
        },
})

export default Loading
