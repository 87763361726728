import { StyleSheet } from 'react-native'
import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.sm,
  },
  backgroundContainer: {
    marginBottom: paddingNew.sm,
    paddingBottom: paddingNew.sm,
    position: 'relative',
  },
  backgroundContainerFill: {
    backgroundColor: colorsNew.redesign.primary.shades12p,
    bottom: 0,
    left: -16,
    position: 'absolute',
    right: -16,
    top: 0,
  },
  button: { marginVertical: 0 },
  buttonWrapper: {
    alignItems: 'center',
  },
  container: {
    marginBottom: paddingNew.sm,
    paddingHorizontal: paddingNew.sm,
  },
  formInfo: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
    marginTop: -paddingNew.xs,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
  },
  helperInfo: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    paddingBottom: paddingNew.sm,
  },
  info: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
  },
  inputWrapper: {
    marginVertical: paddingNew.sm,
  },
  linkButton: {
    alignItems: 'center',
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
  },
  radioButtons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: paddingNew.md,
  },
  redoTaskButtonWrapper: {
    alignItems: 'center',
    marginBottom: paddingNew.md,
  },
  subHeader: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.md,
  },
  sectionContainer: {
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.md,
  },
  subInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginTop: paddingNew.sm,
  },
})

export default styles
