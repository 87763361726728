import React, { useContext, useState } from 'react'
import { ActivityIndicator, Image, Text, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'

import { ChevronRightIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { MenuParamList } from 'src/utils/types/navigationTypes'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { useAppSelector } from 'src/hooks/reduxHooks'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useWarningMenu from 'src/hooks/useWarningMenu'
import EmptyProfilePicturePlaceholder from 'src/components/EmptyProfilePicturePlaceholder'

const MenuProfile = () => {
  const [showPictureLoader, setShowPictureLoader] = useState(true)

  const toggleShowPictureLoader = () => setShowPictureLoader(prevState => !prevState)

  const user = useAppSelector(state => state.user?.attributes)
  const { showWarning } = useWarningMenu()
  const navigation = useNavigation<StackNavigationProp<MenuParamList>>()
  const { mixpanel } = useContext(MixpanelContext)

  const navigateToProfile = () => {
    mixpanel?.track('User Profile', { action: 'open' })
    navigation.navigate('ProfileAndRecruitment', { isMenuTab: true })
  }

  const imageSource = { uri: user?.profilePictureUrl || '' }
  const fullName = `${user?.firstName} ${user?.lastName}`

  return (
    <View style={styles.profileContainer}>
      <TouchableOpacity onPress={navigateToProfile} style={styles.profileButton}>
        <View style={styles.profileImageContainer}>
          {imageSource.uri ? (
            <Image onLoad={() => setShowPictureLoader(false)} source={imageSource} style={styles.profileImage} />
          ) : (
            <EmptyProfilePicturePlaceholder
              onMountCallback={toggleShowPictureLoader}
              style={styles.profileImagePlaceholder}
              wrapperSize={72}
            />
          )}
          {showPictureLoader ? (
            <View style={styles.profileImageLoader}>
              <ActivityIndicator color={colorsNew.brand.secondary} size="small" />
            </View>
          ) : null}
        </View>

        <View style={styles.profileAside}>
          <View style={styles.profileFullNameContainer}>
            <Text style={styles.profileFullName}>{fullName}</Text>
          </View>
          <View style={styles.profileTextContainer}>
            <Text style={styles.text}>{translate(translations.viewProfile)}</Text>
            <View>{showWarning && <View style={styles.rightBadge} />}</View>
            <ChevronRightIcon size={24} fill={colorsNew.redesign.text.secondary} />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default MenuProfile
