import { colorWithOpacity } from './utils'

const black = '#000000'
const white = '#FFFFFF'
const highlight = '#63D3BF'

const loginBackground = '#F3FAF6'

const secondary = '#212121'
const error = '#D32F2F'
const warning = '#F08200'
const info = '#0288D1'
const success = '#00A033'

const colorsNew = {
  black,
  white,

  loginBackground,

  brand: {
    primary: '#00B58B',
    secondary: '#224D3D',
    secondaryDark: '#1C1C1C',
    secondaryLight: '#F7FCFB',
    secondaryMain: '#212121',
  },

  grey: {
    _100: '#F9F9F9',
    _200: '#EEEEEF',
    _300: '#DADADC',
    _400: '#BEBFC0',
    _500: '#9C9C9F',
    _600: '#7F7F83',
    _700: '#5C5C61',
    _800: '#33343A',
    _900: '#1C1C1C',
  },
  background: {
    _100: '#FDFDFD',
    _200: '#EAEAEA',
  },

  error: '#CB1928',
  errorLight: '#FFEEF0',

  info: '#004889',
  infoLight: '#E0EFFF',

  success: '#41B039',
  successLight: '#E6F8E9',

  warning,
  warningLight: '#FFEEDB',

  translucentBlack: colorWithOpacity(black, 0.54),
  translucentWhite: colorWithOpacity(white, 0.8),

  translucentHighlightLight: colorWithOpacity(highlight, 0.12),
  translucentHighlight: colorWithOpacity(highlight, 0.3),

  transparent: 'transparent',

  redesign: {
    text: {
      primary: colorWithOpacity(black, 0.87),
      secondary: colorWithOpacity(black, 0.6),
      disabled: colorWithOpacity(black, 0.38),
    },
    primary: {
      main: highlight,
      dark: '#28A18F',
      light: '#98FFF2',
      contrast: black,
      shades8p: colorWithOpacity(highlight, 0.08),
      shades12p: colorWithOpacity(highlight, 0.12),
      shades30p: colorWithOpacity(highlight, 0.3),
      shades50p: colorWithOpacity(highlight, 0.5),
    },
    secondary: {
      main: secondary,
      dark: black,
      light: '#494949',
      contrast: white,
      shades8p: colorWithOpacity(secondary, 0.08),
      shades12p: colorWithOpacity(secondary, 0.12),
      shades30p: colorWithOpacity(secondary, 0.3),
      shades50p: colorWithOpacity(secondary, 0.5),
    },
    error: {
      main: error,
      dark: '#C62828',
      light: '#EF5350',
      contrast: white,
      shades12p: colorWithOpacity(error, 0.12),
      shades30p: colorWithOpacity(error, 0.3),
      shades50p: colorWithOpacity(error, 0.5),
      text: '#541313',
    },
    warning: {
      main: warning,
      dark: '#E65100',
      light: '#FF9800',
      contrast: white,
      shades12p: colorWithOpacity(warning, 0.12),
      shades30p: colorWithOpacity(warning, 0.3),
      shades50p: colorWithOpacity(warning, 0.5),
      text: '#603400',
    },
    info: {
      main: info,
      dark: '#01579B',
      light: '#03A9F4',
      contrast: white,
      shades12p: colorWithOpacity(info, 0.12),
      shades30p: colorWithOpacity(info, 0.3),
      shades50p: colorWithOpacity(info, 0.5),
      text: '#013654',
    },
    success: {
      main: success,
      dark: '#1B5E20',
      light: '#4CAF50',
      contrast: white,
      shades12p: colorWithOpacity(success, 0.12),
      shades30p: colorWithOpacity(success, 0.3),
      shades50p: colorWithOpacity(success, 0.5),
      text: '#004014',
    },
    pastel: {
      blue: {
        main: '#B0D9EF',
        dark: '#7CBFE4',
        light: '#D8ECF7',
      },
      pink: {
        main: '#FEBFD8',
        dark: '#FE94BE',
        light: '#FFDFEC',
      },
      purple: {
        main: '#E0C7FA',
        dark: '#CBA1F6',
        light: '#EFE3FC',
      },
    },
    action: {
      active: colorWithOpacity(black, 0.54),
      disabled: colorWithOpacity(black, 0.26),
      disabledBackground: colorWithOpacity(black, 0.12),
      pressed: colorWithOpacity(black, 0.08),
    },
    background: {
      paper: white,
    },
    other: {
      backdropOverlay: colorWithOpacity(black, 0.5),
      divider: colorWithOpacity(black, 0.12),
      outlinedBorder: colorWithOpacity(black, 0.23),
      snackbar: '#323232',
      standardInputLine: colorWithOpacity(black, 0.42),
      trafficLight: '#F0C800',
    },
  },
}

export default colorsNew
