import React, { useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { TouchableWithoutFeedback } from 'react-native-gesture-handler'
import { useNavigation } from '@react-navigation/native'

import { BookingSummaryTypes } from 'src/graphql/Booking/types'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { ChevronRightIcon } from 'src/icons'
import { colorsNew } from 'src/styles'
import { countHours, countMinutesLeft } from 'src/scenes/Main/Salary/History/utils'
import { MenuNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { momentWithTimezone } from 'src/global/utils'
import { totalSize, totalVariant } from 'src/scenes/Main/Salary/History/components/Total/variants'
import formatter from 'src/utils/formatter'
import Total from 'src/scenes/Main/Salary/History/components/Total'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import ShadowWrapper from 'src/components/ShadowWrapper'

type Props = {
  data: BookingSummaryTypes[]
  index: number
  title: string
}

const Monthly = ({ data, index, title }: Props) => {
  const [countOfBookings, setCountOfBookings] = useState(0)
  const [totalTime, setTotalTime] = useState('')
  const [totalTimeShort, setTotalTimeShort] = useState('')

  const navigation = useNavigation<MenuNavigatorScreensProps<'JobsCompletedInMonth'>['navigation']>()

  const navigateToJobsCompletedInMonth = () => {
    bugsnagActionBreadcrumb('go to jobs completed in month')
    navigation.navigate('JobsCompletedInMonth', {
      data,
      maxDate: formatter.apiFormat(momentWithTimezone(data[0].startTime).endOf('month')),
      minDate: formatter.apiFormat(momentWithTimezone(data[0].startTime).startOf('month')),
      title,
      totalTime,
    })
  }

  useEffect(() => {
    if (!data) return
    setCountOfBookings(data.length)
    const hours = countHours(data)
    setTotalTimeShort(translate(translations.totalTimeShort, { hours: hours, minutes: countMinutesLeft(data, hours) }))
    setTotalTime(translate(translations.totalTime, { hours: hours, minutes: countMinutesLeft(data, hours) }))
  }, [data])

  return (
    <ShadowWrapper
      borderRadius={4}
      key={index}
      onPress={navigateToJobsCompletedInMonth}
      testID={`history-month-${index}`}
    >
      <View style={styles.mainContainer} key={index}>
        <View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subTitle}>
            {countOfBookings} {translate(translations.bookings)}
          </Text>
        </View>
        <View style={styles.rightContainer}>
          <Total text={totalTimeShort} size={totalSize.large} variant={totalVariant.main} />
          <ChevronRightIcon size={24} fill={colorsNew.redesign.secondary.main} />
        </View>
      </View>
    </ShadowWrapper>
  )
}

export default Monthly
