import { Platform, StyleSheet } from 'react-native'

import { paddingNew, colorsNew, typography } from 'src/styles'

const isNotWeb = Platform.OS !== 'web'

export const styles = StyleSheet.create({
  container: {
    marginBottom: paddingNew.sm,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
    marginTop: paddingNew.md,
  },
  label: {
    backgroundColor: colorsNew.redesign.background.paper,
    color: colorsNew.redesign.text.secondary,
  },
  error: {
    ...typography.helperText,
    color: colorsNew.redesign.error.main,
    marginLeft: paddingNew.xs,
  },
  helper: {
    ...typography.helperText,
    color: colorsNew.redesign.text.secondary,
    marginLeft: paddingNew.xs,
  },
  inputText: {
    ...typography.inputText,
    backgroundColor: colorsNew.redesign.background.paper,
    height: 56,
    ...((isNotWeb && { lineHeight: 18 }) || {}),
  },
  inputSmall: {
    height: 40,
  },
  inputBig: {
    height: 104,
  },
  inputVBig: {
    height: 180,
  },
})
