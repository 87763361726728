export const EMAIL_ERROR = {
  gb: 'Email address not found',
  sv: 'E-post kunde inte hittas',
}

export const PASSWORD_ERROR = {
  gb: 'Wrong password',
  sv: 'Fel lösenord',
}

export const MIGRATION_ERROR_TITLE = 'migration_mode_enabled'
