import React, { ElementType } from 'react'
import { Text, View } from 'react-native'

import styles from './styles'
import InfoTile from 'src/components/InfoTile'
import { IconTypes } from 'src/icons/iconTypes'

type Props = {
  tiles: { title: string; Icon: ElementType<IconTypes>; subHeader: string }[]
  title: string
}

const InfoTileHeader = ({ title, tiles }: Props) => (
  <View style={styles.container} testID="info-tile-header">
    <Text style={styles.title}>{title}</Text>

    {tiles.map((tile, index) => (
      <InfoTile
        title={tile.title}
        subHeader={tile.subHeader}
        Icon={tile.Icon}
        isLast={tiles.length - 1 === index}
        key={index}
      />
    ))}
  </View>
)

export default InfoTileHeader
