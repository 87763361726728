import React from 'react'

import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  hideError: () => void
}

const CompleteTaskError = ({ hideError }: Props) => (
  <AlertCard
    colorSchema={alertColorScheme.error}
    description={translate(translations.serverError)}
    onClose={hideError}
    testId="complete-task-error-alert"
    title={translate(translations.serverErrorTitle)}
    variant={alertVariants.standard}
  />
)

export default CompleteTaskError
