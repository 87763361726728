import React from 'react'

import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import styles from './styles'

type Props = {
  children: Element
  isVisible: boolean
  onClose?: () => void
}

const ModalWrapper = ({ isVisible, children }: Props) => (
  <NewModalWrapper containerStyle={styles.modalWeb} isVisible={isVisible}>
    {children}
  </NewModalWrapper>
)

export default ModalWrapper
