import { Dimensions, Platform, StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const HEIGHT = Dimensions.get('window').height
const isWeb = Platform.OS === 'web'

const MODAL_HEIGHT = HEIGHT * 0.9

const styles = StyleSheet.create({
  base: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.primary.contrast,
    borderRadius: 10,
    height: 5,
    marginBottom: paddingNew.xs,
    marginTop: paddingNew.sm,
    width: 123,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: paddingNew.xs,
    marginBottom: paddingNew.base,
    marginTop: paddingNew.lg - paddingNew.xxs,
  },
  container: {
    display: 'flex',
    padding: paddingNew.sm,
  },
  drawer: {
    alignSelf: 'center',
    backgroundColor: colorsNew.redesign.other.divider,
    borderRadius: 2,
    height: 4,
    width: 48,
  },
  modalContent: isWeb
    ? {
        maxHeight: MODAL_HEIGHT,
      }
    : {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        maxHeight: MODAL_HEIGHT,
      },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  radioInputOption: {
    marginVertical: 0,
    paddingLeft: paddingNew.sm,
    paddingVertical: paddingNew.xs + paddingNew.xxs,
  },
  title: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    paddingVertical: paddingNew.md,
  },
})

export default styles
