import gql from 'graphql-tag'

import { ProposalDetailsRequestTypes } from './types'

export type { ProposalDetailsRequestTypes }

export default gql`
  query ProposalDetails($id: ID!) {
    proposal(id: $id) {
      closed
      contactPerson
      competences {
        name
        id
      }
      description
      duration
      endDate
      hours
      hoursPerWeek
      id
      interestedStudentIds
      jobTitle
      location
      payRate
      region {
        id
        name
      }
      startDate
      studentId
      title
      topics {
        id
        title
      }
      type
    }
  }
`
