import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginBottom: paddingNew.xl,
  },
  title: {
    ...typography.overline,
    color: colorsNew.redesign.text.primary,
    lineHeight: 16,
    marginBottom: paddingNew.sm,
  },
  emptyDescription: {
    ...typography.caption,
    color: colorsNew.redesign.text.disabled,
  },
  activeSchool: {
    marginTop: paddingNew.lg,
  },
})
