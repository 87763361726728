import React, { ComponentType, useCallback } from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import get from 'lodash/get'

import { Error, Label } from 'src/components/Form/Common'
import RadioButton from './RadioButton'
import styles from './stylesNew'
import translations, { translate } from 'src/utils/translations/translations'

type Options = {
  label: string
  LabelComponent?: ComponentType<any>
  labelComponentProps?: object
  value: boolean | string
}[]

type Props = {
  containerStyle?: ViewStyle
  disabled?: boolean
  extraVerticalSpacing?: boolean
  field: {
    name: string
    value: string | boolean | null
  }
  form: {
    errors: object
    setFieldValue: (name: string, value: boolean | string) => void
    touched: object
  }
  highlightSelectedRow?: boolean
  isHorizontal?: boolean
  isLarge?: boolean
  isRowPressable?: boolean
  label?: string
  options?: Options
  required?: boolean
  rowStyle?: StyleProp<any>
}

const defaultOptions: Options = [
  {
    value: true,
    label: translate(translations.yes),
  },
  {
    value: false,
    label: translate(translations.no),
  },
]

export const InputRadioGroup = (props: Props) => {
  const {
    containerStyle,
    disabled = false,
    extraVerticalSpacing = true,
    field: { name, value },
    form: { errors, touched, setFieldValue },
    highlightSelectedRow,
    isHorizontal,
    isLarge,
    isRowPressable,
    label,
    options = defaultOptions,
    required,
    rowStyle,
  } = props

  const isTouched = get(touched, name)

  const renderOptions = useCallback(
    () =>
      options.map(option => {
        const handleChange = (newValue: boolean | string) => setFieldValue(name, newValue)
        const handleRowOnPress = () => isRowPressable && handleChange(option.value)

        const isSelected = option.value === value

        return (
          <Pressable
            key={option.label}
            onPress={handleRowOnPress}
            style={StyleSheet.flatten([
              styles.spacing,
              rowStyle,
              highlightSelectedRow && isSelected ? styles.rowSelected : {},
            ])}
          >
            <RadioButton
              disabled={disabled}
              isLarge={isLarge}
              isSingleLine={!isHorizontal}
              label={option.label}
              LabelComponent={option.LabelComponent}
              labelComponentProps={option.labelComponentProps}
              onPress={() => handleChange(option.value)}
              selected={isSelected}
              testID={`${name}-option-${option.label}`}
            />
          </Pressable>
        )
      }),
    [
      disabled,
      highlightSelectedRow,
      isHorizontal,
      isLarge,
      isRowPressable,
      name,
      options,
      rowStyle,
      setFieldValue,
      value,
    ],
  )

  return (
    <View style={[styles.radioGroupContainer, extraVerticalSpacing && styles.extraContainerSpace]}>
      {label ? (
        <Label
          labelStyles={[styles.label, extraVerticalSpacing && styles.extraLabelSpace]}
          label={label}
          required={required}
        />
      ) : null}
      <View style={isHorizontal ? StyleSheet.flatten([styles.horizontal, containerStyle]) : containerStyle}>
        {renderOptions()}
      </View>
      <Error errors={isTouched ? errors : {}} errorMessageStyle={styles.error} name={name} />
    </View>
  )
}

export default InputRadioGroup
