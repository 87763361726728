import React, { useMemo } from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'

import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { ThumbsDownIcon, ThumbsUpIcon } from 'src/icons'
import { TVariants, variants } from './variants'

const ICON_SIZE = 20

type Props = {
  disabled?: boolean
  onPress: () => void
  variant?: typeof variants[TVariants]
}

const RatingThumb = ({ disabled, variant, onPress }: Props) => {
  const settings = useMemo(() => {
    if (variant === variants.positive && !disabled) {
      return {
        frameColor: styles.framePositive,
        icon: <ThumbsUpIcon fill={styles.textPositive.color} size={ICON_SIZE} />,
        text: translate(translations.yes),
        textColor: styles.textPositive,
      }
    } else if (variant === variants.negative && !disabled) {
      return {
        frameColor: styles.frameNegative,
        icon: <ThumbsDownIcon fill={styles.textNegative.color} size={ICON_SIZE} />,
        text: translate(translations.no),
        textColor: styles.textNegative,
      }
    } else if (variant === variants.positive && disabled) {
      return {
        frameColor: styles.frameDisabled,
        icon: <ThumbsUpIcon fill={styles.textDisabled.color} size={ICON_SIZE} />,
        text: translate(translations.yes),
        textColor: styles.textDisabled,
      }
    }

    return {
      frameColor: styles.frameDisabled,
      icon: <ThumbsDownIcon fill={styles.textDisabled.color} size={ICON_SIZE} />,
      text: translate(translations.no),
      textColor: styles.textDisabled,
    }
  }, [variant, disabled])

  return (
    <TouchableOpacity
      onPress={onPress}
      style={StyleSheet.flatten([styles.frame, settings.frameColor])}
      testID="RatingThumb-container"
    >
      <Text testID="RatingThumb-text" style={StyleSheet.flatten([styles.text, settings.textColor])}>
        {settings.text}
      </Text>
      {settings.icon}
    </TouchableOpacity>
  )
}

export default RatingThumb
