export const filterChipSize = {
  small: 'small',
  large: 'large',
} as const

export const filterChipColor = {
  primary: 'primary',
  secondary: 'secondary',
} as const

export const filterChipState = {
  active: 'active',
  enabled: 'enabled',
} as const
