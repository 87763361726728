import React, { useCallback, useEffect, useState } from 'react'
import { FlatList, Text, View } from 'react-native'

import navigationService from 'src/utils/navigationService'
import translations, { translate } from 'src/utils/translations/translations'
import LoadingIndicator from 'src/components/LoadingIndicator'
import CollapsibleItem from './CollapsibleItem'
import { LegalNoticeModifiedT, LegalNoticesModifiedT, LegalNoticesT } from './types'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const rebuildList = (data: LegalNoticesT): LegalNoticesModifiedT =>
  Object.entries(data).map(([key, value], index) => ({
    id: index,
    licenseText: value.licenseText || '',
    title: key,
  }))

const removeDefaultKey = (data: LegalNoticesModifiedT) => {
  if (data[data.length - 1].title === 'default') {
    return data.slice(0, data.length - 1)
  }
  return data
}

// INFO - More info in Readme #Generate legal notices in the app
const LegalNotices = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [allData, setAllData] = useState<LegalNoticesModifiedT>([])

  const loadLicenseInfo = async () => {
    setIsLoading(true)
    try {
      // @ts-ignore
      const licenseInfos: LegalNoticesT = await import('../../../../../oss-attribution/licenseInfos.json')
      setAllData(removeDefaultKey(rebuildList(licenseInfos)))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      navigationService.navigate('Modal', {
        danger: true,
        description: translate(translations.errorReportTitle),
        title: translate(translations.somethingWentWrong),
      })
    }
  }

  useEffect(() => {
    loadLicenseInfo()
  }, [])

  const renderItem = useCallback(({ item }: { item: LegalNoticeModifiedT }) => <CollapsibleItem item={item} />, [])

  const renderHeader = () => (
    <View style={styles.headerContainer}>
      <Text style={styles.headerText}>{translate(translations.legalNoticesDescription)}</Text>
    </View>
  )

  if (isLoading) {
    return <LoadingIndicator size="large" />
  }

  return (
    <FlatList
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      data={allData}
      initialNumToRender={20}
      ListHeaderComponent={renderHeader}
      renderItem={renderItem}
      windowSize={5}
      testID="scrollView"
    />
  )
}

export default LegalNotices
