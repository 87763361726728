import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const DoubleArrowIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg height={size} width={size} viewBox="0 0 73 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M36.9 32.1L16.75 3.85C15 1.45 12.25 0 9.3 0 1.85 0-2.5 8.4 1.85 14.45L16.5 35 1.85 55.55C-2.5 61.6 1.85 70 9.3 70a9.17 9.17 0 007.45-3.85l20.2-28.25c1.2-1.75 1.2-4.05-.05-5.8z"
      fill={fill}
    />
    <Path
      d="M71.9 32.1L51.7 3.85A9.079 9.079 0 0044.3 0c-7.45 0-11.75 8.4-7.45 14.45L51.5 35 36.85 55.55C32.5 61.6 36.85 70 44.3 70a9.17 9.17 0 007.45-3.85l20.2-28.25c1.2-1.75 1.2-4.05-.05-5.8z"
      fill={fill}
    />
  </Svg>
)

export default DoubleArrowIcon
