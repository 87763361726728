import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import isEmpty from 'lodash/isEmpty'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { trainingTypes } from 'src/global/constants'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import FileUploader from 'src/components/FileUploader'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import { TranslationSVT } from 'src/utils/translations/i18nTypes'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'ProofOfEducations'>

const ProofOfEducations = ({ navigation, route }: Props) => {
  const { status, task, timeToComplete } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - ProofOfEducations')

  const dispatch = useAppDispatch()

  const educationalTrainings = useAppSelector(state => state.user?.attributes?.educationalTrainingTypes || [])
  const proofOfEducationUrls = useAppSelector(state => state.user?.attributes?.proofOfEducationUrls || [])
  const userId = useAppSelector(state => state.user.id) as string

  const isProofOfEducationUploaded = !isEmpty(proofOfEducationUrls)

  const [isProofOfEducationMissing, setIsProofOfEducationMissing] = useState(
    isTaskCompleted && !isProofOfEducationUploaded,
  )
  const [isCertifiedTeacher, setIsCertifiedTeacher] = useState(
    educationalTrainings?.includes(trainingTypes.licensedTeacher),
  )
  const [isCertifiedChildtaker, setIsCertifiedChildtaker] = useState(
    educationalTrainings?.includes(trainingTypes.nurseryWorker),
  )
  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked
    }
  }, [isFilePicked])

  const textStyles = StyleSheet.flatten([styles.info, isProofOfEducationMissing ? styles.disabled : {}])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }
  const handleUploadFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }

  const updateTaskStatusWithFile = () =>
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleUploadFinish, handleError)

  const updateTaskStatusWithoutFile = () => markTaskAsCompleted(userId, task, false, mixpanel, closeScreen, handleError)

  const awaitingDocumentUpload = isProofOfEducationMissing || isTaskCompleted
  const isSubmitButtonDisabled = isTaskCompleted || (!isProofOfEducationMissing && !isFilePicked)

  const handleIsProofOfEducationMissingChange = () => {
    setIsProofOfEducationMissing((prevState: boolean) => !prevState)
    if (!isProofOfEducationMissing) {
      setIsCertifiedChildtaker(false)
      setIsCertifiedTeacher(false)
    }
  }

  const educationalTrainingTypes = () => {
    const selectedTypes = []

    if (isCertifiedTeacher) selectedTypes.push(trainingTypes.licensedTeacher)
    if (isCertifiedChildtaker) selectedTypes.push(trainingTypes.nurseryWorker)

    return selectedTypes
  }

  const onSuccessCompleteTask = (educationalTrainingTypesLength: number) => {
    if (isFilePicked) setShouldSaveDocument(true)
    if (educationalTrainingTypesLength) updateTaskStatusWithFile()
    if (isProofOfEducationMissing) updateTaskStatusWithoutFile()
    setIsSubmitting(false)
  }

  const completeTask = () => {
    bugsnagActionBreadcrumb('Complete proof of education task', { id: userId })
    setIsSubmitting(true)
    setIsError(false)

    const selectedTypes = educationalTrainingTypes()

    dispatch(
      saveUser({
        id: userId,
        onSuccess: () => onSuccessCompleteTask(selectedTypes.length),
        onError: handleError,
        stringifyData: true,
        user: { educationalTrainingTypes: selectedTypes },
      }),
    )
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.info}>{translate((translations as TranslationSVT).proofOfEducationMainTitle)}</Text>
      <Text style={styles.subInfo}>{translate((translations as TranslationSVT).proofOfEducationMainInfo)}</Text>
      <Text style={textStyles}>
        {translate((translations as TranslationSVT).proofOfEducationForDig)}
        {translate((translations as TranslationSVT).proofOfEducationUploader)}
      </Text>
      <View style={styles.fileUploaderWrapper}>
        <FileUploader
          arrayDocuments={proofOfEducationUrls}
          buttonDisabled={awaitingDocumentUpload}
          deletingFilesDisabled={awaitingDocumentUpload}
          field="proofOfEducationUrls"
          onFileSaveCallback={!isTaskCompleted ? updateTaskStatusWithFile : handleUploadFinish}
          onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
          onFilesErrorCallback={handleError}
          saveOnUpload={shouldSaveDocument}
        />
      </View>
      <Text style={StyleSheet.flatten([styles.info, isProofOfEducationMissing ? styles.disabled : {}])}>
        {translate((translations as TranslationSVT).proofOfEducationTeacherEducation)}
      </Text>
      <Checkbox
        customLabelStyles={styles.checkboxLabel}
        disabled={awaitingDocumentUpload}
        label={translate((translations as TranslationSVT).proofOfEducationIsQualifiedTeacher)}
        onPress={() => setIsCertifiedTeacher((prevState: boolean) => !prevState)}
        selected={isCertifiedTeacher}
        testID="proof-education-qualified-teacher-checkbox"
      />
      <Checkbox
        customLabelStyles={styles.checkboxLabel}
        disabled={awaitingDocumentUpload}
        label={translate((translations as TranslationSVT).proofOfEducationIsQualifiedChildtaker)}
        onPress={() => setIsCertifiedChildtaker((prevState: boolean) => !prevState)}
        selected={isCertifiedChildtaker}
        testID="proof-education-qualified-child-checkbox"
      />
      <View style={styles.sectionContainer}>
        <Text style={textStyles}>
          {translate((translations as TranslationSVT).proofOfEducationForDig)}
          {translate((translations as TranslationSVT).proofOfEducationMainCheckboxText)}
        </Text>
        <View style={styles.checkboxLabel}>
          <Checkbox
            disabled={isTaskCompleted || isFilePicked}
            label={translate((translations as TranslationSVT).proofOfEducationMainCheckbox)}
            onPress={handleIsProofOfEducationMissingChange}
            selected={isProofOfEducationMissing}
            testID="proof-education-without-file-checkbox"
          />
        </View>
      </View>
      <View style={styles.sectionContainer}>
        {isError ? (
          <View style={styles.alerWrapper}>
            <CompleteTaskError hideError={() => setIsError(false)} />
          </View>
        ) : null}
        <View style={styles.buttonContainer}>
          <ButtonNew
            disabled={isSubmitButtonDisabled}
            isSubmitting={isSubmitting}
            onPress={!isTaskCompleted ? completeTask : () => setShouldSaveDocument(true)}
            size={buttonSize.lg}
            title={translate((translations as TranslationSVT).closeTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
        <Text style={styles.subInfo}>{translate((translations as TranslationSVT).proofOfEducationInfo)}</Text>
      </View>
    </ScrollView>
  )
}

export default ProofOfEducations
