import { StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  text: {
    ...typography.body1,
    color: colorsNew.redesign.text.primary,
  },
  hideTextContainer: {
    alignItems: 'flex-start',
  },
  expandText: {
    ...typography.body1,
    color: colorsNew.redesign.action.active,
    paddingLeft: paddingNew.xs,
    width: 50,
  },
  lastRowContainer: { flexDirection: 'row' },
  lastRowText: { flex: 1 },
  link: {
    color: colorsNew.redesign.info.main,
  },
})

export default styles
