import { StyleSheet } from 'react-native'
import { colors, colorsNew, fontSizes, fontSizesNew, paddingNew } from 'src/styles'

export default StyleSheet.create({
  modalContainer: {
    padding: paddingNew.base,
  },
  title: {
    color: colorsNew.brand.secondaryDark,
    fontSize: fontSizesNew.sm.size,
    fontWeight: '700',
    lineHeight: fontSizesNew.sm.lh,
    marginBottom: paddingNew.xs,
  },
  subTitle: {
    color: colorsNew.brand.secondaryDark,
    fontSize: fontSizesNew.xxs.size,
    fontWeight: '500',
    lineHeight: fontSizesNew.xxs.lh,
    marginBottom: paddingNew.sm,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: paddingNew.base,
  },
  buttonSpace: {
    width: paddingNew.sm,
  },
  input: {
    borderColor: colorsNew.grey._400,
    borderRadius: 6,
    borderWidth: 1,
    color: colorsNew.black,
    fontSize: fontSizesNew.xxs.size,
    height: 120,
    padding: paddingNew.xs,
    textAlignVertical: 'top',
  },
})
