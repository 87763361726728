import React from 'react'
import { View, Text } from 'react-native'

import expressStyles, { iconStyle } from './styles'
import styles from 'src/scenes/Main/Salary/Report/components/ReportInfo/styles'
import translations, { translate } from 'src/utils/translations/translations'
import { CalendarDateIcon, SalaryIcon } from 'src/icons'
import { TimeExpressIcon } from 'src/icons'

type Props = {
  expressSalary: string
  expressDate: string
}

const ExpressSummaryInfo = ({ expressSalary, expressDate }: Props) => {
  const InfoRows = [
    {
      description: expressDate,
      icon: <CalendarDateIcon {...iconStyle} />,
    },

    {
      comment: translate(translations.expressSummaryPayment),
      description: expressSalary,
      icon: <SalaryIcon {...iconStyle} />,
    },
  ]

  return (
    <View style={styles.section}>
      <View style={expressStyles.iconContainer}>
        <TimeExpressIcon size={180} />
      </View>
      <Text style={expressStyles.title}>{translate(translations.expressSummaryTitle)}</Text>
      <Text style={expressStyles.subtitle}>{translate(translations.expressSummarySubtitle)}</Text>

      {InfoRows.map((row, index) => (
        <View key={index}>
          <View style={styles.infoRowContainer}>
            {row.icon}

            <Text style={styles.infoRowDescription}>{row.description}</Text>
          </View>
          {row.comment ? <Text style={expressStyles.infoRowComment}>{row.comment}</Text> : null}
        </View>
      ))}
    </View>
  )
}

export default ExpressSummaryInfo
