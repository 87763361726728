import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const ClockIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} viewBox="0 0 54 54" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.442 26.616c0-10.806 8.763-19.57 19.57-19.57 10.806 0 19.568 8.764 19.568 19.57 0 10.807-8.762 19.57-19.569 19.57-10.806 0-19.57-8.763-19.57-19.57Zm4.349 0c0 8.393 6.827 15.22 15.22 15.22s15.22-6.827 15.22-15.22-6.827-15.22-15.22-15.22-15.22 6.827-15.22 15.22Z"
    />
    <Path d="m35.426 31.508-7.328-4.348v-9.85a1.56 1.56 0 0 0-1.565-1.566h-.13a1.56 1.56 0 0 0-1.566 1.566v10.263c0 .76.391 1.478 1.065 1.87l7.937 4.762c.739.434 1.696.217 2.13-.522.457-.761.218-1.74-.543-2.175Z" />
  </Svg>
)

export default ClockIcon
