import React, { ElementType } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { IconTypes } from 'src/icons/iconTypes'
import styles from './styles'

const InfoTile = ({
  Icon,
  isLast = false,
  subHeader,
  title,
}: {
  Icon: ElementType<IconTypes>
  isLast?: boolean
  subHeader: string
  title: string
}) => (
  <View style={StyleSheet.flatten([isLast ? styles.lastContainer : styles.container])} testID="info-tile">
    <View style={styles.iconContainer}>
      <Icon size={24} fill={styles.icon.color as string} />
    </View>
    <View style={styles.textContainer}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subTitle}>{subHeader}</Text>
    </View>
  </View>
)

export default InfoTile
