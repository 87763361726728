import React, { useContext, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { ScrollView, StyleSheet, View } from 'react-native'
import moment from 'moment'

import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { isSafeguardingTrainingDateValid } from './utils'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { momentWithTimezone } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { saveUser } from 'src/store/user/actions'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import formatter from 'src/utils/formatter'
import getValidationSchema from './validation'
import SafeguardingDeclarationInfo from './SafeguardingDeclarationInfo/SafeguardingDeclarationInfo'
import SafeguardingPolicy from './SafeguardingPolicy/SafeguardingPolicy'
import SafeguardingTraining from './SafeguardingTraining/SafeguardingTraining'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'Safeguarding'>

export type SafeguardingInitialValuesType = {
  kcsPolicyStatus: boolean
  safeguardingPolicyStatus: boolean
  trainingDate: null | string
  trainingStatus: null | boolean
}

const SafeguardingDeclaration = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isTaskCompleted = status === profileTaskStatus.completed
  const isTaskExpired = [profileTaskStatus.expired, profileTaskStatus.expiresSoon].includes(status)

  useBugsnagView('RequiredTask - SafeguardingDeclaration')

  const userId = useAppSelector(state => state.user?.id) as string
  const dispatch = useAppDispatch()

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isTaskInCompletedState, setIsTaskInCompletedState] = useState(isTaskExpired || isTaskCompleted)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const kcsieReadDeclarationOn = useAppSelector(state => state.user.attributes?.kcsieReadDeclarationOn)
  const safeguardingPolicyAgreedOn = useAppSelector(state => state.user.attributes?.safeguardingPolicyAgreedOn)
  const safeguardingTrainingOn = useAppSelector(state => state.user.attributes?.safeguardingTrainingOn)
  const safeguardingTrainingDeclaration = useAppSelector(
    state => state.user.attributes?.safeguardingTrainingDeclaration,
  )
  const safeguardingTrainingDeclarationDeclaredOn = useAppSelector(
    state => state.user.attributes?.safeguardingTrainingDeclarationDeclaredOn,
  )

  const { mixpanel } = useContext(MixpanelContext)

  const formikRef = useRef<FormikProps<SafeguardingInitialValuesType>>(null)

  const closeScreen = useBackModal(navigation, formikRef)
  const handleError = () => {
    setIsError(true)
    setIsSubmitting(false)
  }

  const redoTheTask = () => {
    setIsTaskInCompletedState(false)
    formikRef.current?.setValues({
      kcsPolicyStatus: false,
      safeguardingPolicyStatus: false,
      trainingDate: formatter.apiFormat(momentWithTimezone()),
      trainingStatus: null,
    })
  }

  const updateTaskStatus = () => {
    markTaskAsCompleted(
      userId,
      task,
      isFilePicked,
      mixpanel,
      () => {
        setIsSubmitting(false)
        closeScreen()
      },
      handleError,
    )
  }

  const onSubmitForm = (values: SafeguardingInitialValuesType, isTrainingDateEdited: boolean) => {
    const mappedValues = {
      acceptedPolicyDocumentTypes: ['keeping_children_safe_policy', 'safeguarding_policy'],
      kcsieReadDeclarationOn: formatter.apiFormatWithoutTimezone(moment()),
      safeguardingPolicyAgreedOn: formatter.apiFormatWithoutTimezone(moment()),
      safeguardingTrainingDeclaration: isTrainingDateEdited ? values.trainingStatus : safeguardingTrainingDeclaration,
      safeguardingTrainingDeclarationDeclaredOn: isTrainingDateEdited
        ? formatter.apiFormatWithoutTimezone(moment())
        : safeguardingTrainingDeclarationDeclaredOn,
      safeguardingTrainingOn: values.trainingStatus === true ? values.trainingDate : null,
    }

    bugsnagActionBreadcrumb('submit', { id: userId, user: mappedValues })
    setIsSubmitting(true)
    setIsError(false)

    dispatch(
      saveUser({
        id: userId,
        onError: handleError,
        onSuccess: isFilePicked ? () => setShouldSaveDocument(true) : updateTaskStatus,
        stringifyData: true,
        user: mappedValues,
      }),
    )
  }

  const initialValues: SafeguardingInitialValuesType = {
    kcsPolicyStatus: !!kcsieReadDeclarationOn,
    safeguardingPolicyStatus: !!safeguardingPolicyAgreedOn,
    trainingDate: safeguardingTrainingOn || formatter.apiFormat(momentWithTimezone()),
    trainingStatus: safeguardingTrainingDeclaration || null,
  }

  const isSafeguardingTrainingValid = isSafeguardingTrainingDateValid(safeguardingTrainingOn)

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      {isTaskExpired ? (
        <View style={StyleSheet.flatten([styles.buttonContainer, styles.container])}>
          <ButtonNew
            disabled={!isTaskInCompletedState}
            isShort
            onPress={redoTheTask}
            title={translate(translations.redoTask)}
            variant={buttonVariants.containedDefault}
          />
        </View>
      ) : null}
      <SafeguardingDeclarationInfo />
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validateOnMount
        validationSchema={getValidationSchema()}
      >
        {({ errors, setFieldTouched, setFieldValue, touched, values, isValid }) => {
          const isTrainingDateEdited = values.trainingDate !== safeguardingTrainingOn

          return (
            <>
              <SafeguardingPolicy
                disabled={isTaskInCompletedState}
                selected={values.safeguardingPolicyStatus}
                toggleStatus={() => setFieldValue('safeguardingPolicyStatus', !values.safeguardingPolicyStatus)}
                type="safeguarding_policy"
              />
              <SafeguardingPolicy
                disabled={isTaskInCompletedState}
                selected={values.kcsPolicyStatus}
                toggleStatus={() => setFieldValue('kcsPolicyStatus', !values.kcsPolicyStatus)}
                type="keeping_children_safe_policy"
              />
              <SafeguardingTraining
                form={{ errors, setFieldTouched, setFieldValue, touched, values }}
                isEditState={!isTaskInCompletedState}
                isTaskCompleted={isTaskInCompletedState}
                onFileSaveCallback={updateTaskStatus}
                onFilesErrorCallback={handleError}
                setIsFilePicked={setIsFilePicked}
                shouldSaveDocument={shouldSaveDocument}
              />
              <View style={styles.sectionContainer}>
                {isError ? (
                  <View style={styles.alertWrapper}>
                    <CompleteTaskError hideError={() => setIsError(false)} />
                  </View>
                ) : null}
                <View style={styles.buttonContainer}>
                  <ButtonNew
                    disabled={
                      isTaskInCompletedState ||
                      !isValid ||
                      (!isFilePicked && !isSafeguardingTrainingValid) ||
                      isSubmitting
                    }
                    isShort
                    isSubmitting={isSubmitting}
                    onPress={() => onSubmitForm(values, isTrainingDateEdited)}
                    title={translate(translations.closeTask)}
                    variant={buttonVariants.containedDefault}
                  />
                </View>
              </View>
            </>
          )
        }}
      </Formik>
    </ScrollView>
  )
}
export default SafeguardingDeclaration
