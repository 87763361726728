import {
  SET_APPLICATION_NOTIFICATIONS,
  SET_APPLICATION_NOTIFICATIONS_READ,
  SET_MESSAGES_REFETCHING,
  SET_NEW_MESSAGE,
  SET_NOTIFICATIONS_COUNT,
  SET_READ_NOTIFICATIONS,
} from './types'
import { ActionTypes } from './actions'

export type InboxStateType = {
  applicationNotifications: string[]
  applicationNotificationsCount: number
  newMessage: null | string
  readNotifications: string[]
  shouldPullMessages: boolean
  unreadNotificationsCount: number
}

const initialState: InboxStateType = {
  applicationNotifications: [],
  applicationNotificationsCount: 0,
  newMessage: null,
  readNotifications: [],
  shouldPullMessages: false,
  unreadNotificationsCount: 0,
}

export default (state = initialState, { type, payload }: ActionTypes) => {
  switch (type) {
    case SET_NOTIFICATIONS_COUNT:
      return { ...state, unreadNotificationsCount: payload }
    case SET_APPLICATION_NOTIFICATIONS:
      return {
        ...state,
        applicationNotifications: payload,
        applicationNotificationsCount: (payload || []).length,
      }
    case SET_APPLICATION_NOTIFICATIONS_READ:
      return {
        ...state,
        applicationNotifications: [],
        applicationNotificationsCount: 0,
        readNotifications: [...state.readNotifications, ...state.applicationNotifications],
        unreadNotificationsCount:
          state.unreadNotificationsCount - state.applicationNotificationsCount > 0
            ? state.unreadNotificationsCount - state.applicationNotificationsCount
            : 0,
      }
    case SET_READ_NOTIFICATIONS:
      return { ...state, readNotifications: payload }
    case SET_NEW_MESSAGE:
      return { ...state, newMessage: payload }
    case SET_MESSAGES_REFETCHING:
      return { ...state, shouldPullMessages: payload }
    default:
      return state
  }
}
