import { EncryptStorage } from 'encrypt-storage'
import { ENCRYPTED_TOKEN_KEY } from 'src/utils/webAdapters/DotEnvAdapter'

export const apolloStorage = new EncryptStorage(ENCRYPTED_TOKEN_KEY, {
  prefix: 'apollo-storage',
})

export const setToken = (token: string) => apolloStorage?.setItem?.('token', token)

export const setRefreshToken = (token: string) => apolloStorage?.setItem?.('refreshToken', token)

export const getToken = () => {
  try {
    return apolloStorage?.getItem?.('token') || ''
  } catch (e) {
    clearTokens()
  }
}
export const getRefreshToken = () => apolloStorage?.getItem?.('refreshToken') || ''

export const clearTokens = () => apolloStorage?.removeMultipleItems?.(['token', 'refreshToken'])
