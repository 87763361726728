import React from 'react'
import { Text } from 'react-native'

import translations, { translate } from 'src/utils/translations/translations'

import styles from './styles'

const MessageTextInfo = () => <Text style={styles.smsInfo}>{translate(translations.smsMessage)}</Text>

export default MessageTextInfo
