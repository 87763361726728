import React from 'react'

import { colorsNew } from 'src/styles'
import PersonSearchIcon from 'src/icons/redesign/personSearch'
import DoubleArrowIcon from 'src/icons/redesign/doubleArrow'

export const statuses = {
  express: 'express',
  longTerm: 'longTerm',
} as const

export const getCardInfo = ({ status, isDisabled }: { status: keyof typeof statuses; isDisabled: boolean }) => {
  const fillColor = isDisabled ? colorsNew.redesign.secondary.shades12p : colorsNew.redesign.primary.shades12p

  switch (status) {
    case statuses.longTerm:
      return <PersonSearchIcon size={98} fill={fillColor} />
    case statuses.express:
      return <DoubleArrowIcon size={72} fill={fillColor} />
  }
}
