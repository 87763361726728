import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { LocaleT } from 'src/utils/translations/i18nTypes'
import { countryCodeSelector, isGBSelector } from 'src/store/app/selectors'

import Api from 'src/utils/api'
import { useAppSelector } from 'src/hooks/reduxHooks'

type ContactType = {
  email: string
  onboardingEmail: string
  phone: string
  shortName?: string
}

type SupportContactResponseType = {
  data: {
    email: string
    phone: string
    onboardingEmail: string
  }
}

export const useSupportContact = () => {
  const [contact, setContact] = useState<ContactType | null>()
  const countryCode = useSelector(countryCodeSelector)
  const isGB = useSelector(isGBSelector)
  const company = useAppSelector(state => state.user?.included?.find(x => x.type === 'companies')?.attributes)

  useEffect(() => {
    if (isGB) {
      setContact({
        email: company?.email,
        onboardingEmail: 'hello@humly.co.uk',
        phone: company?.phoneNumber,
        shortName: company?.shortName,
      })
    } else {
      getContact(setContact, countryCode)
    }
  }, [company, isGB, countryCode])

  return contact
}

export const getContact = async (onSuccess: (contact: ContactType) => void, countryCode: LocaleT) => {
  setTimeout(
    () =>
      Api.get(`support_contacts?countryCode=${countryCode}`, {
        onSuccess: ({ data: { email, phone, onboardingEmail } }: SupportContactResponseType) => {
          onSuccess({ email, phone, onboardingEmail })
        },
      }),
    500,
  )
}

export default useSupportContact
