import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'
import formatter from 'src/utils/formatter'

export const validationSchema = Yup.object().shape({
  startTime: Yup.string()
    .nullable()
    .test({
      name: 'startTime',
      test: function test() {
        return this.parent.timeScheduleType === 'hourly'
          ? formatter.getDifferenceInMinutes(this.parent.startTime, this.parent.endTime) > 0
          : true
      },
      message: () => translate(translations.errorTimeAvailability),
    }),
  endTime: Yup.string()
    .nullable()
    .test({
      name: 'endTime',
      test: function test() {
        return this.parent.timeScheduleType === 'hourly'
          ? formatter.getDifferenceInMinutes(this.parent.startTime, this.parent.endTime) > 0
          : true
      },
      message: () => translate(translations.errorTimeAvailability),
    }),
  startDate: Yup.string().test({
    name: 'startDate',
    test: function test() {
      return (
        this.parent.startDate !== undefined &&
        formatter.getDatesDifferenceInDays(this.parent.endDate, this.parent.startDate) >= 0
      )
    },
    message: () => translate(translations.errorDateAvailability),
  }),
  endDate: Yup.string().test({
    name: 'endDate',
    test: function test() {
      return (
        this.parent.endDate !== undefined &&
        formatter.getDatesDifferenceInDays(this.parent.endDate, this.parent.startDate) >= 0
      )
    },
    message: () => translate(translations.errorDateAvailability),
  }),
  selectedDays: Yup.array().min(1, () => translate(translations.errorSelectedDays)),
})
