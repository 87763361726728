import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: paddingNew.lg,
    position: 'relative',
  },
  gradientContainer: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 22,
    paddingBottom: paddingNew.sm + paddingNew.xxs,
    paddingLeft: paddingNew.xs,
    paddingRight: paddingNew.xs,
    paddingTop: paddingNew.sm + paddingNew.xxs,
  },
  mainInfo: {
    ...typography.h5,
    color: colorsNew.black,
  },
  skeletonContainer: {
    paddingTop: 7,
  },
  subInfo: {
    ...typography.tooltip,
    color: colorsNew.redesign.text.secondary,
  },
  title: {
    ...typography.subtitle2,
    color: colorsNew.black,
    textTransform: 'uppercase',
  },
})

export default styles
