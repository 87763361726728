import { FormValues } from './ReferenceForm/ReferenceForm'

export const newReferenceTemplate: FormValues = {
  email: '',
  employer: '',
  endedOn: '',
  isOngoing: false,
  name: '',
  occupation: '',
  phone: '',
  position: '',
  reasonForLeaving: '',
  startedOn: '',
}
