import { StyleSheet } from 'react-native'

import { colorsNew, utils, paddingNew } from 'src/styles'
import { IconButtonSize } from './types'

type GetColorsArguments = {
  disabled: undefined | boolean
  isPressed: boolean
  isFilled: boolean
}
export const getColorsSettings = ({ disabled, isPressed, isFilled }: GetColorsArguments) => {
  if (disabled) {
    return {
      backgroundColor: isFilled ? colorsNew.redesign.action.disabled : colorsNew.transparent,
      borderColor: isFilled ? colorsNew.transparent : colorsNew.redesign.action.disabledBackground,
      iconColor: isFilled ? colorsNew.redesign.action.disabled : colorsNew.redesign.action.disabledBackground,
    }
  }
  if (isFilled) {
    return {
      backgroundColor: colorsNew.redesign.background.paper,
      borderColor: colorsNew.redesign.background.paper,
      iconColor: isPressed ? colorsNew.redesign.action.disabled : colorsNew.redesign.action.active,
    }
  }
  return {
    backgroundColor: colorsNew.transparent,
    borderColor: isPressed ? colorsNew.redesign.action.pressed : colorsNew.redesign.action.active,
    iconColor: isPressed ? colorsNew.redesign.action.pressed : colorsNew.redesign.action.active,
  }
}

export const getButtonSize = (size: IconButtonSize) => {
  switch (size) {
    case 'sm':
      return 28
    case 'md':
      return 32
    case 'lg':
      return 48
  }
}

export default StyleSheet.create({
  buttonContainer: {
    margin: paddingNew.xs,
  },
  button: {
    alignItems: 'center',
    borderRadius: 50,
    borderWidth: 1,
    justifyContent: 'center',
  },
  buttonShadow: {
    ...utils.shadow('selected', colorsNew.black),
  },
  buttonShadowPressed: {
    elevation: 1,
    shadowColor: colorsNew.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
  },
  buttonEmpty: {
    borderWidth: 0,
    borderStyle: undefined,
  },
})
