import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const HouseRounded = ({ fill, size, ...props }: IconTypes) => (
  <Svg fill={fill} height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path d="M19.0001 9.29973V4.99973C19.0001 4.44973 18.5501 3.99973 18.0001 3.99973H17.0001C16.4501 3.99973 16.0001 4.44973 16.0001 4.99973V6.59973L12.6701 3.59973C12.2901 3.25973 11.7101 3.25973 11.3301 3.59973L2.9701 11.1297C2.6301 11.4297 2.8401 11.9997 3.3001 11.9997H5.0001V18.9997C5.0001 19.5497 5.4501 19.9997 6.0001 19.9997H9.0001C9.5501 19.9997 10.0001 19.5497 10.0001 18.9997V13.9997H14.0001V18.9997C14.0001 19.5497 14.4501 19.9997 15.0001 19.9997H18.0001C18.5501 19.9997 19.0001 19.5497 19.0001 18.9997V11.9997H20.7001C21.1601 11.9997 21.3801 11.4297 21.0301 11.1297L19.0001 9.29973ZM10.0001 9.99973C10.0001 8.89973 10.9001 7.99973 12.0001 7.99973C13.1001 7.99973 14.0001 8.89973 14.0001 9.99973H10.0001Z" />
  </Svg>
)

export default HouseRounded
