import { StyleSheet, Text, View } from 'react-native'
import React, { ComponentType } from 'react'
import { RadioButton as PaperRadioButton } from 'react-native-paper'

import { colorsNew } from 'src/styles'
import styles from './styles'

type Props = {
  disabled?: boolean
  isLarge?: boolean
  isSingleLine?: boolean
  label: string
  LabelComponent?: ComponentType<any>
  labelComponentProps?: object
  onPress: () => void
  selected: boolean
  testID?: string
}

const RadioButton = ({
  disabled,
  isLarge,
  isSingleLine,
  label,
  LabelComponent,
  labelComponentProps,
  onPress,
  selected,
  testID,
}: Props) => (
  <View style={styles.radioButtonContainer}>
    <View style={!isLarge && { transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}>
      <PaperRadioButton.Android
        color={!disabled ? colorsNew.redesign.text.primary : colorsNew.redesign.action.disabled}
        disabled={disabled}
        onPress={onPress}
        status={selected ? 'checked' : 'unchecked'}
        testID={`${testID}-button`}
        uncheckedColor={colorsNew.redesign.text.secondary}
        value={label}
      />
    </View>

    {!LabelComponent ? (
      <Text
        testID={`${testID}-label`}
        style={StyleSheet.flatten([
          styles.radioButtonText,
          disabled && styles.radioButtonDisabledText,
          isSingleLine && styles.radioButtonFullText,
        ])}
      >
        {label}
      </Text>
    ) : (
      <View>
        <LabelComponent {...labelComponentProps} onPress={onPress} />
      </View>
    )}
  </View>
)

export default RadioButton
