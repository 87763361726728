import { BookingTypes } from 'src/graphql/Booking/types'
import { applicationStatus, ApplicationStatus } from '../applicationTypes'
import formatter from 'src/utils/formatter'
import { mixpanelBookingDays } from 'src/scenes/Main/Jobs/Details/helpers'

export type OfferStatusData = Pick<
  BookingTypes,
  | 'appointedAt'
  | 'appointmentType'
  | 'appointmentUnconfirmed'
  | 'cancelled'
  | 'flexibleStartTime'
  | 'rejected'
  | 'requestedSubs'
  | 'studentApplications'
  | 'studentId'
>

export const getBookingStatus = (booking: OfferStatusData, userId?: string): ApplicationStatus => {
  const {
    appointedAt,
    appointmentType,
    appointmentUnconfirmed,
    cancelled,
    flexibleStartTime,
    rejected,
    requestedSubs,
    studentApplications,
    studentId,
  } = booking

  const isAppointed = !!appointedAt
  const appointedByCurrentUser = isAppointed && studentId === userId
  const appointedByOtherUser = isAppointed && studentId !== userId
  const isDirect = appointmentType === 'direct'
  const isApplication = appointmentType === 'application'

  const isUserApplyforJob = studentApplications.some(application => application.studentId === userId)
  const isRequestedBySchool = requestedSubs.some(item => item.id === userId)
  if (rejected || cancelled) {
    return applicationStatus.offerCancelled
  }
  if (appointedByOtherUser) {
    return applicationStatus.offerAppointToOtherTeacher
  }
  if (appointedByCurrentUser && appointmentUnconfirmed && isDirect) {
    return applicationStatus.unconfirmedExpress
  }
  if (appointedByCurrentUser && appointmentUnconfirmed && isApplication) {
    return applicationStatus.userAssigned
  }
  if (appointedByCurrentUser) {
    return applicationStatus.userAppointed
  }
  if (isUserApplyforJob) {
    return applicationStatus.userApplied
  }
  if (isRequestedBySchool) {
    return applicationStatus.userRequested
  }
  if (flexibleStartTime && isDirect) {
    return applicationStatus.flexibleOffer
  }

  return appointmentType === 'direct' ? applicationStatus.directOffer : applicationStatus.applicationOffer
}

export const getMixpanelBookingDay = (bookingDate?: string) => {
  if (!bookingDate) {
    return ''
  }

  if (formatter.apiFormat(bookingDate) === formatter.apiFormat()) {
    return mixpanelBookingDays.today
  } else if (formatter.apiFormat(bookingDate) < formatter.apiFormat()) {
    return mixpanelBookingDays.past
  }
  return mixpanelBookingDays.future
}
