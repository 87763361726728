import { useContext } from 'react'

import { ComplianceStatusContext } from 'src/context/ComplianceStatusContext'
import { useAppSelector } from 'src/hooks/reduxHooks'
import useTimesheetData from 'src/hooks/useTimesheetData'

const useWarningMenu = () => {
  const { showWarning } = useContext(ComplianceStatusContext)
  const applicationNotificationsCount = useAppSelector(state => state?.inbox?.applicationNotificationsCount)
  const newMessage = useAppSelector(state => state?.inbox?.newMessage) || ''
  const {
    items: { unreported = [] },
  } = useTimesheetData()
  const filteredUnreported = unreported.filter(item => item.__typename !== 'empty')

  const isWarningInMenu =
    [applicationNotificationsCount, newMessage.length, filteredUnreported.length].some(item => !!item) || showWarning

  return { applicationNotificationsCount, newMessage, unreported: filteredUnreported, showWarning, isWarningInMenu }
}

export default useWarningMenu
