import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  copyConfirmationText: {
    ...typography.caption,
    color: colorsNew.redesign.text.primary,
  },
  copyConfirmationWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: paddingNew.xs,
    height: 20,
    justifyContent: 'center',
    marginTop: paddingNew.xxs,
  },
})

export default styles
