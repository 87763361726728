import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const defaultFill = '#41B039'

const CheckFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg height={size} viewBox="0 0 54 54" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.012 46.185c10.807 0 19.569-8.761 19.569-19.57 0-10.807-8.762-19.568-19.57-19.568-10.807 0-19.569 8.761-19.569 19.569s8.762 19.57 19.57 19.57Zm12.547-24.091a1.228 1.228 0 0 0 .026-1.794 1.374 1.374 0 0 0-1.879-.025l-12.833 11.91-6.679-5.021a1.372 1.372 0 0 0-1.866.212 1.23 1.23 0 0 0 .222 1.78l7.593 5.709c.523.393 1.27.356 1.748-.087L39.56 22.094Z"
      fill={fill?.length ? fill : defaultFill}
    />
  </Svg>
)

export default CheckFilledIcon
