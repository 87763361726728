import { StyleSheet } from 'react-native'

import { colorsNew } from 'src/styles'

export default ({ size }: { size: number }) =>
  StyleSheet.create({
    profilePlaceholder: {
      alignItems: 'center',
      backgroundColor: colorsNew.redesign.primary.shades12p,
      borderRadius: 100,
      height: size,
      justifyContent: 'center',
      width: size,
    },
  })
