import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { CheckCircleOutlinedIcon, ErrorOutlinedIcon, InfoOutlinedIcon, WarningAmberIcon, CloseIcon } from 'src/icons'
import { CustomModalBaseProps, customModalColorSchema } from './types'
import ButtonNew, { buttonSize } from 'src/components/Buttons/ButtonNew'
import styles, { getCardColor, getTextColor, iconColor } from './styles'
import { iconButtonSize, iconButtonVariant } from 'src/components/Buttons/IconButton/variants'
import IconButton from 'src/components/Buttons/IconButton'
import { capitalizeFirstLetter } from 'src/global/utils'

const getIcon = (colorSchema: CustomModalBaseProps['colorSchema']) => {
  switch (colorSchema) {
    case 'error':
      return ErrorOutlinedIcon
    case 'info':
      return InfoOutlinedIcon
    case 'success':
      return CheckCircleOutlinedIcon
    case 'warning':
      return WarningAmberIcon
    case 'default':
      return null as never
  }
}

const CustomModalBase = ({
  buttons,
  children,
  colorSchema,
  description,
  onClose,
  subtitle,
  testId,
  title,
  variant,
}: CustomModalBaseProps) => {
  const areTwoButtons = buttons.length === 2
  const isDefaultModalColor = colorSchema === customModalColorSchema.default

  const Icon = useMemo(() => getIcon(colorSchema), [colorSchema])

  return (
    <View testID={testId}>
      <View style={StyleSheet.flatten([styles.titleContainer, getCardColor(variant, colorSchema)])}>
        <View style={styles.titleGroup}>
          {!isDefaultModalColor && <Icon fill={iconColor[colorSchema][variant]} size={22} style={styles.icon} />}
          <Text style={StyleSheet.flatten([styles.title, getTextColor(variant, colorSchema)])}>{title}</Text>
        </View>
        {onClose && (
          <IconButton Icon={CloseIcon} onPress={onClose} size={iconButtonSize.sm} variant={iconButtonVariant.empty} />
        )}
      </View>
      <View style={!subtitle && !description ? styles.noInfo : null}>
        {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
        {description && <Text style={styles.description}>{description}</Text>}
        {children && <View style={styles.children}>{children}</View>}
      </View>
      <View style={areTwoButtons ? styles.twoButtonsContainer : styles.buttonsContainer}>
        {buttons.map(button => (
          <View key={button.title} style={areTwoButtons ? styles.horizontalButtonContainer : styles.buttonContainer}>
            <ButtonNew
              isSubmitting={button.isSubmitting}
              LeftIcon={button.LeftIcon}
              onPress={button.onPress}
              RightIcon={button.RightIcon}
              size={buttonSize.lg}
              testID={button.testID}
              title={capitalizeFirstLetter(button.title)}
              variant={button.variant}
            />
          </View>
        ))}
      </View>
    </View>
  )
}

export default CustomModalBase
