import React, { useEffect, useRef } from 'react'
import { View } from 'react-native'
import LottieView from 'lottie-react-native'

import { TReferAnimationVariant } from './types'
import ReferAnimationRender from './ReferAnimationRender'

type Props = {
  showAnimation: boolean
  variant: TReferAnimationVariant
}

const ReferAnimation = ({ showAnimation, variant }: Props) => {
  const animationRef = useRef<LottieView | null>(null)

  useEffect(() => {
    if (showAnimation && animationRef) {
      animationRef?.current?.play()
    }

    return () => {
      animationRef?.current?.reset()
    }
  }, [showAnimation])

  return showAnimation ? (
    <View>
      <ReferAnimationRender animationRef={animationRef} variant={variant} />
    </View>
  ) : null
}

export default ReferAnimation
