import React, { useCallback, useMemo, useState } from 'react'
import { RefreshControl, ScrollView, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import some from 'lodash/some'

import { colors, colorsNew } from 'src/styles'
import { updateUserSchools } from 'src/store/user/actions'
import LoadingIndicator from 'src/components/LoadingIndicator'
import SchoolQuery, { SchoolTypes } from 'src/graphql/School'
import SchoolsHeader from './components/SchoolsHeader'
import SchoolsList from './components/SchoolsList'
import translations, { translate } from 'src/utils/translations/translations'
import UserQuery from 'src/graphql/User/Show'
import { UserTypes } from 'src/graphql/User/types'
import { bugsnagActionBreadcrumb, useBugsnagView } from 'src/utils/bugsnag'
import styles from './styles'
// @ts-ignore
import EmptyImage from './emptyClientPreferences.svg'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import { DislikedSchoolsIdsT } from 'src/store/user/actionTypes'
import EmptyView from 'src/components/EmptyView'
import globalStyles from 'src/global/globalStyles'

const originalEmptyImageWidth = 175
const originalEmptyImageHeight = 132
const maxWidthPercentage = originalEmptyImageWidth / 375

export const Schools = () => {
  const dispatch = useAppDispatch()
  const userId = useAppSelector(state => state.user.id)
  const [isSending, setIsSending] = useState(false)

  useBugsnagView('Matching - Schools')

  const schoolResponse = useQuery(SchoolQuery, {
    variables: {
      id: userId,
    },
  })

  const userResponse = useQuery<UserTypes>(UserQuery, {
    variables: {
      id: userId,
    },
  })

  const schools = useMemo(() => get(schoolResponse, 'data.schools.nodes', []), [schoolResponse]) as SchoolTypes[]

  const userLoading = get(userResponse, 'loading')
  const schoolsLoading = get(schoolResponse, 'loading')

  const dislikedSchoolIds = useMemo(
    () => (userLoading ? [] : get(userResponse, 'data.user.dislikedSchools', []).map(({ id }) => id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userResponse?.data, userLoading],
  )

  const refetch = useCallback(() => {
    bugsnagActionBreadcrumb('refetch')
    if (userResponse && schoolResponse && !userLoading && !schoolsLoading) {
      return Promise.all([userResponse.refetch(), schoolResponse.refetch()]).then(() => setIsSending(false))
    }
    return () => null
  }, [schoolResponse, userResponse, userLoading, schoolsLoading])

  useFocusEffect(
    useCallback(() => {
      refetch()
    }, [refetch]),
  )

  if (userLoading || schoolsLoading) {
    return <LoadingIndicator style={{ backgroundColor: colors.white }} />
  }

  return (
    <ScrollView
      refreshControl={
        <RefreshControl
          colors={[colors.blue._400, colorsNew.redesign.text.primary]}
          onRefresh={refetch}
          refreshing={userLoading || schoolsLoading}
          tintColor={colorsNew.redesign.text.primary}
          titleColor={colors.blue._400}
        />
      }
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      testID="scrollView"
    >
      {some(schools) ? (
        <>
          <SchoolsHeader />
          <SchoolsList
            isSending={isSending}
            refetch={userResponse.refetch}
            schools={schools}
            setIsSending={setIsSending}
            updateUserSchools={(updateSchools: DislikedSchoolsIdsT) => dispatch(updateUserSchools(updateSchools))}
            userDislikedSchoolsIds={dislikedSchoolIds}
          />
        </>
      ) : (
        <View style={styles.emptyContainer}>
          <EmptyView
            description={translate(translations.emptySchoolsDescription)}
            Image={EmptyImage}
            maxWidthPercentage={maxWidthPercentage}
            originalImageHeight={originalEmptyImageHeight}
            originalImageWidth={originalEmptyImageWidth}
            title={translate(translations.emptySchoolsTitle)}
          />
        </View>
      )}
    </ScrollView>
  )
}

export default Schools
