import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { IconTypes } from 'src/icons/iconTypes'

const LoopsFilledIcon = ({ fill, size, ...props }: IconTypes) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM7.18753 14.2847V12.6742L11.0394 14.5656C11.4221 14.7547 11.8812 14.7547 12.2639 14.5656L16.1157 12.6742V14.2847C16.1157 14.7031 15.8607 15.0928 15.4525 15.2935L12.2639 16.8581C11.8812 17.0473 11.4221 17.0473 11.0394 16.8581L7.85077 15.2935C7.44262 15.0928 7.18753 14.7031 7.18753 14.2847ZM5.66336 9.77404L11.0394 7.13756C11.2278 7.04724 11.4381 7 11.6516 7C11.8652 7 12.0754 7.04724 12.2639 7.13756L18.335 10.1122C18.5391 10.2154 18.6667 10.4045 18.6667 10.6166V14.2904C18.6667 14.6057 18.3797 14.8636 18.0289 14.8636C17.6782 14.8636 17.3912 14.6057 17.3912 14.2904V10.9031L12.2639 13.4193C11.8812 13.6084 11.4221 13.6084 11.0394 13.4193L5.66336 10.7828C5.22332 10.565 5.22332 9.99183 5.66336 9.77404Z"
    />
  </Svg>
)

export default LoopsFilledIcon
