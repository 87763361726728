import { StyleSheet } from 'react-native'

import { colorsNew, fontSizesNew, paddingNew, typography } from 'src/styles'

const screenContainer = {
  paddingBottom: paddingNew.lg,
  paddingHorizontal: paddingNew.sm,
  paddingTop: paddingNew.sm,
}

const styles = StyleSheet.create({
  screenContainer: screenContainer,
  screenTutorContainer: {
    ...screenContainer,
    paddingTop: paddingNew.md,
  },
  description: {
    ...typography.helperText,
    marginBottom: paddingNew.sm,
    textAlign: 'center',
  },
  input: {
    borderColor: colorsNew.grey._400,
    borderRadius: 6,
    borderWidth: 1,
    color: colorsNew.black,
    fontSize: fontSizesNew.xxs.size,
    height: 120,
    padding: paddingNew.xs,
    textAlignVertical: 'top',
  },
  buttonContainer: {
    marginTop: paddingNew.md,
  },
  buttonText: {
    marginVertical: paddingNew.md + 4,
  },
  feedbackThumbs: {
    marginBottom: paddingNew.lg - 4,
  },
  feedbackThumbsButtons: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  infoText: {
    ...typography.helperText,
    marginVertical: paddingNew.xs,
  },
  firstTextInput: {
    marginTop: 0,
  },
  firstTextInputWithPupil: {
    marginTop: paddingNew.sm,
  },
  pupilTitle: {
    ...typography.subtitle2,
    color: colorsNew.redesign.text.primary,
  },
  pupilDescription: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
  },
})

export default styles
