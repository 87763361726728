import React from 'react'
import { View } from 'react-native'
import { InputToolbarProps, InputToolbar } from 'react-native-gifted-chat'

import styles from './styles'
import globalStyles from 'src/global/globalStyles'

const CustomToolbarMobile = (props: InputToolbarProps) => (
  <View style={globalStyles.containerSize} testID="scrollView">
    <InputToolbar {...props} containerStyle={styles.inputToolbarStyles} />
  </View>
)

export default CustomToolbarMobile
