import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography, utils } from 'src/styles'

export default StyleSheet.create({
  alertWrapper: {
    marginBottom: paddingNew.xs,
  },
  componentTitle: {
    ...typography.overline,
    lineHeight: paddingNew.sm,
  },
  container: {
    ...utils.shadow('button', colorsNew.black),
    backgroundColor: colorsNew.redesign.background.paper,
    borderRadius: paddingNew.xs,
    padding: paddingNew.xs,
  },
  loading: {
    backgroundColor: colorsNew.redesign.background.paper,
  },
  mainTitle: {
    ...typography.h6,
    paddingBottom: paddingNew.sm,
  },
})
