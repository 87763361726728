export const applicationStatus = {
  applicationOffer: 'application-offer',
  directOffer: 'direct-offer',
  flexibleOffer: 'flexible-stat-time-offer',
  offerAppointToOtherTeacher: 'offer-appoint-to-other-teacher',
  offerCancelled: 'offer-cancelled',
  unconfirmedExpress: 'unconfirmed-express-offer',
  userApplied: 'user-applied',
  userAppointed: 'user-appointed',
  userAssigned: 'user-assigned',
  userRequested: 'user-requested',
} as const

type ValueOf<T> = T[keyof T]
export type ApplicationStatus = ValueOf<typeof applicationStatus>
