import { BookingTypes } from 'src/graphql/Booking/types'
import formatter from 'src/utils/formatter'

export const timesheetWorkingTime = (item: BookingTypes) => {
  const isHourly = item.timeScheduleType === 'hourly'
  const originalBookingTime = formatter.scheduleTimeText(
    item.timeScheduleType,
    `${formatter.time(item.startTime)} - ${formatter.time(item.endTime)}`,
  )
  const reportedHours =
    isHourly && item.arrivalTime && item.departureTime
      ? `${formatter.time(item.arrivalTime)} - ${formatter.time(item.departureTime)}`
      : ''
  const reportedBookingTime = formatter.scheduleTimeText(item.reportedTimeScheduleType, reportedHours)

  const bookingTime = reportedBookingTime || originalBookingTime

  return { isHourly, bookingTime, originalBookingTime, reportedBookingTime }
}
