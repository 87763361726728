import { StyleSheet } from 'react-native'

import { CATEGORIES_SCREEN_PADDING } from 'src/scenes/Main/Profile/CategoriesList/CategoryCard/styles'
import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  name: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginVertical: paddingNew.xs,
  },
  profileInfo: {
    alignItems: 'center',
    marginTop: paddingNew.sm,
  },
  page: {
    paddingHorizontal: CATEGORIES_SCREEN_PADDING,
  },
})
