import { StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

const row = {
  alignItems: 'center',
  borderRadius: 4,
  flexDirection: 'row',
  paddingVertical: paddingNew.xs,
  paddingHorizontal: paddingNew.sm,
  minHeight: 64,
} as ViewStyle

const styles = StyleSheet.create({
  row: {
    ...row,
  },
  rowPressed: {
    ...row,
    backgroundColor: colorsNew.redesign.action.pressed,
  },
  rowBody: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    ...typography.subtitle1,
    color: colorsNew.redesign.text.primary,
  },
  textDisabled: {
    color: colorsNew.redesign.text.disabled,
    marginRight: paddingNew.xxs,
  },
  textEnabled: {
    flex: 1,
  },
  badge: {
    borderRadius: 50,
    paddingHorizontal: 14,
    paddingVertical: 3,
    marginRight: paddingNew.xs,
  },
  status: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  statusText: {
    ...typography.chipTextSmall,
  },
  enabledContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lockContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },

  lockIcon: {
    marginBottom: 3,
    paddingLeft: paddingNew.xs,
  },
  lockedDescription: {
    ...typography.helperText,
    color: colorsNew.redesign.text.disabled,
  },
})

export default styles
