import React, { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { View } from 'react-native'
import some from 'lodash/some'

import { renderSectionsWithTitles } from './utils'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import List from 'src/components/List'
import LoadingIndicator from 'src/components/LoadingIndicator'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useTimesheetData from 'src/hooks/useTimesheetData'
import { useBugsnagView, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import ShortBookingRow from 'src/scenes/Main/Jobs/Rows/ShortBookingRow'
import ExpressRow from 'src/scenes/Main/Jobs/Rows/ExpressRow'
import EmptyPlaceholder, { placeholderTypes } from 'src/components/EmptyPlaceholder'
import { UnreportedItemT } from 'src/scenes/Main/Salary/Timesheet/types'

const Timesheet = () => {
  const { refetch, loading, error, items, isEmpty } = useTimesheetData()

  useBugsnagView('Timesheet')

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  if (loading) {
    return <LoadingIndicator style={styles.loading} />
  }

  if (error) {
    bugsnagNotifyWithData('Timesheet error on fetching', error)
    return <FetchingDataErrorModal errorToReport={error} />
  }

  if (isEmpty) {
    return (
      <EmptyPlaceholder
        containerStyles={styles.emptyContainer}
        description={translate(translations.emptyHistoryDescription)}
        title={translate(translations.emptyHistory)}
        type={placeholderTypes.cup}
      />
    )
  }

  const noUnreportedItems = () => (
    <EmptyPlaceholder
      description={translate(translations.emptyHistoryUnreportedDescription)}
      title={translate(translations.emptyHistoryUnreported)}
      type={placeholderTypes.stars}
    />
  )

  const renderJobListItem = ({ item }: { item: UnreportedItemT }) => {
    if (item.__typename === 'Booking') {
      return <ShortBookingRow item={item} />
    } else if (item.__typename === 'empty') {
      return noUnreportedItems()
    }
    return <ExpressRow item={item} />
  }

  return (
    <View style={styles.container}>
      {some(items.reported) || some(items.unreported) ? (
        <List
          sections={renderSectionsWithTitles(items)}
          testID="timesheet-list"
          // @ts-ignore
          renderJobListItem={renderJobListItem}
        />
      ) : null}
    </View>
  )
}

export default Timesheet
