import { StyleSheet } from 'react-native'

import { paddingNew, typography, colorsNew } from 'src/styles'

const styles = StyleSheet.create({
  title: {
    ...typography.body2,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.sm,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingBottom: paddingNew.sm,
  },
})

export default styles
