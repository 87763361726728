import { StyleSheet, Platform } from 'react-native'
import { colorsNew, fonts, fontSizesNew, paddingNew } from 'src/styles'

const styles = StyleSheet.create({
  label: {
    color: colorsNew.brand.secondaryDark,
    flex: 1,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizesNew.xxs.size,
  },
  labelRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowContainer: {
    marginVertical: paddingNew.sm,
  },
  toggleButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    borderRadius: 15,
    height: 30,
    paddingHorizontal: paddingNew.sm,
  },
  toggleText: {
    color: colorsNew.brand.secondaryDark,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizesNew.xxs.size,
  },
  toggleTextError: {
    color: colorsNew.error,
  },
  input: {
    borderColor: colorsNew.grey._400,
    borderRadius: 6,
    borderWidth: 1,
    color: colorsNew.black,
    fontSize: fontSizesNew.xxs.size,
    height: 120,
    padding: paddingNew.xs,
    textAlignVertical: 'top',
    marginTop: paddingNew.xs,
  },
  buttonContainer: {
    marginTop: paddingNew.base,
    paddingHorizontal: paddingNew.lg,
  },
  buttonText: {
    color: colorsNew.grey._400,
    fontSize: fontSizesNew.xs.size,
    fontWeight: '600',
    marginVertical: paddingNew.base,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  picker: {
    ...Platform.select({
      android: {
        alignItems: 'center',
        color: colorsNew.black,
        marginLeft: 23,
        marginRight: 15,
      },
    }),
  },
  container: {
    paddingHorizontal: 30,
  },
  required: {
    color: colorsNew.brand.primary,
  },
  overtimeDescription: {
    color: colorsNew.grey._400,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizesNew.xxxs.size,
    marginTop: paddingNew.xs,
  },
  shiftLabel: {
    color: colorsNew.brand.secondaryDark,
    fontFamily: fonts.poppinsMedium,
    fontSize: fontSizesNew.xs.size,
  },
  switch: {
    marginVertical: paddingNew.sm,
  },
  errorText: {
    color: colorsNew.error,
    fontSize: fontSizesNew.xxxs.size,
    marginTop: paddingNew.xxs,
  },
})

export default styles
